import { HStack, useMediaQuery } from 'native-base';
import React, { useEffect } from 'react';
import { Outlet, Route, useNavigate } from 'react-router-dom';

import { useScreenSize, useWalletSummary } from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { TableType } from '@cryptowallet/web/components/Table';

import AssetTransactionHistory from './components/AssetTransactionHistory';
import BalanceList from './components/BalanceList';
import DetailsPanel from './components/DetailsPanel';

const Widgets = () => {
  const { isDesktop } = useScreenSize();
  const { selectedWalletAccountId, selectedWalletAssetId } = useAccountsStore();
  const { invalidate } = useWalletSummary();

  useEffect(() => {
    invalidate();
  }, []);

  return (
    <>
      {Boolean(isDesktop || (!selectedWalletAccountId && !selectedWalletAssetId)) && <BalanceList />}
      {Boolean(isDesktop || !!selectedWalletAccountId || !!selectedWalletAssetId) && <DetailsPanel />}
    </>
  );
};

const RecentActivity = () => {
  const { isBiggerThanTablet } = useScreenSize();
  const navigate = useNavigate();
  const [isSmallLaptop] = useMediaQuery({ minWidth: 992, maxWidth: 1200 });

  return (
    <AssetTransactionHistory
      tableType={isBiggerThanTablet && !isSmallLaptop ? TableType.FULL : TableType.SMALL}
      onGoBack={() => navigate(-1)}
    />
  );
};

export const renderRoutes = () => (
  <>
    <Route path="widgets" element={<Widgets />} />
    <Route path="recent-activity" element={<RecentActivity />} />
  </>
);

const Accounts = () => {
  const [isLargeScreen] = useMediaQuery({
    minWidth: 1668,
  });

  return (
    <HStack
      position="relative"
      maxW="100vw"
      flexWrap="wrap"
      justifyContent={isLargeScreen ? 'flex-start' : 'justify-content'}>
      <Outlet />
    </HStack>
  );
};

export default Accounts;
