import { useMutation } from '@tanstack/react-query';
import { Box, HStack, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { walletsApi } from '@cryptowallet/frontend/api';
import {
  CreateSwapTransactionPayloadWalletsDto,
  EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum,
} from '@cryptowallet/frontend/client';
import { useSelectedWalletAccount, useSwapEstimate, useWalletSummary } from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import {
  BottomPurpleButton,
  CryptocurrencyInputsForm,
  ICryptocurrencyInputItem,
  useAlerts,
} from '@cryptowallet/frontend/ui';
import { trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import DetailsBox from '@cryptowallet/web/components/DetailsBox';

const errorMessagesMap = {
  [EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum.CouldNotReceiveNetworkFee]: () =>
    `Something went wrong. Please try again`,
  [EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum.InsufficientBalanceForCoinTransaction]:
    ({ max, selectedCurrencyTicker }) =>
      `Not enough balance to perform transaction. You can swap no more than %${max}% ${selectedCurrencyTicker}`,
  [EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum.InsufficientCoinBalanceForTokenTransaction]:
    ({ feeAssetLabel }) => `Please top up your ${feeAssetLabel} wallet to cover network fees for this transaction.`,
  [EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum.InsufficientTokenBalanceForTokenTransaction]:
    ({ max }) => `Not enough balance. You can swap %${max}%`,
  [EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum.Unknown]: () => 'Unknown error',
};

const AssetSwapAmount = () => {
  const { showErrorToaster } = useAlerts();
  const { swapWalletResources } = useWalletSummary();
  const {
    isFetching: isEstimateFetching,
    networkFee,
    equivalentAmount,
    rate,
    validationValues,
    pairIsInactive,
    feeAsset,
  } = useSwapEstimate();
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const { setSelectedWalletAccountId, selectedWalletAccountId, swapData, setSwapData } = useAccountsStore(
    state => ({
      setSelectedWalletAccountId: state.setSelectedWalletAccountId,
      selectedWalletAccountId: state.selectedWalletAccountId,
      swapData: state.swapData,
      setSwapData: state.setSwapData,
    }),
    shallow,
  );

  const { isLoading, mutateAsync: createSwapTransactionMutateAsync } = useMutation(
    ({ fromWalletAssetId, toWalletAssetId, amount }: CreateSwapTransactionPayloadWalletsDto) =>
      walletsApi.walletsControllerCreateSwapTransaction({
        fromWalletAssetId,
        toWalletAssetId,
        amount,
      }),
  );

  const cryptocurrencyInputs: ICryptocurrencyInputItem[] = useMemo(() => {
    const swapCurrencies = swapWalletResources.map(item => ({
      ...item.asset.currency,
      walletAssetId: item.walletAsset.id,
      id: item.walletAccount?.id || item.walletAsset.id,
      isCreated: item.isCreated,
      image: item.asset.image,
      network: item.asset.network,
      type: item.asset.type,
    }));

    const selectedCurrency = swapCurrencies.find(item => selectedWalletAccountId === item.id);
    const toCurrency = swapCurrencies.find(item => swapData.toSelectedWalletAssetId === item.walletAssetId);

    return [
      {
        name: 'fromAmount',
        label: 'You send',
        value: swapData.amount,
        currencies: swapCurrencies.filter(item => item.isCreated),
        selectedCurrency,
        onCurrencyChange: newCurrency => setSelectedWalletAccountId(newCurrency.id),
        setValue: amount => setSwapData({ amount }),
        validate: {
          moreThan: {
            rule: value => {
              const numberValue = Number(value);

              if (numberValue <= 0) {
                return `Please enter the amount of ${selectedWalletAccount.asset.currency.ticker} you’d like to swap.`;
              }

              if (pairIsInactive) {
                return `Pair is inactive`;
              }

              if (validationValues.minAmount && numberValue < Number(validationValues.minAmount)) {
                return `The swap amount is too small. Please enter an amount greater than %${validationValues?.minAmount}%`;
              }

              if (validationValues.depositTooSmall) {
                return 'The swap amount is too small. Please enter greater amount.';
              }

              return true;
            },
            fixValue: validationValues?.minAmount?.toString() || '0',
          },
          lessThan: {
            rule: () => {
              if (validationValues.walletBalanceInsufficientionReason) {
                const feeAssetLabel = feeAsset?.currency?.label;
                return errorMessagesMap[validationValues.walletBalanceInsufficientionReason]({
                  max: validationValues?.maxAmount,
                  feeAssetLabel,
                  selectedCurrencyTicker: selectedWalletAccount.asset.currency.ticker,
                });
              }

              return true;
            },
            fixValue: validationValues?.maxAmount?.toString() || '0',
          },
        },
        showNetwork: true,
      },
      {
        name: 'toAmount',
        label: 'You get',
        value: equivalentAmount,
        currencies: swapCurrencies,
        selectedCurrency: toCurrency,
        onCurrencyChange: newCurrency => setSwapData({ toSelectedWalletAssetId: newCurrency.walletAssetId }),
        showNetwork: true,
        isLoading: isEstimateFetching,
      },
    ];
  }, [
    swapWalletResources,
    selectedWalletAccountId,
    setSelectedWalletAccountId,
    swapData,
    setSwapData,
    equivalentAmount,
    isEstimateFetching,
    validationValues,
  ]);

  const { trigger, control, handleSubmit } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async () => {
    try {
      const isValidForm = await trigger();

      if (isValidForm) {
        const { data } = await createSwapTransactionMutateAsync({
          fromWalletAssetId: selectedWalletAccount?.walletAsset?.id || '',
          toWalletAssetId: swapData.toSelectedWalletAssetId,
          amount: swapData.amount,
        });

        setSwapData({ swapId: data.id });
      }
    } catch (err) {
      showErrorToaster();
    }
  };

  useEffect(() => {
    if (swapWalletResources && !swapData.toSelectedWalletAssetId) {
      const defaultToWalletResource = swapWalletResources.find(
        item => item.walletAccount?.id !== selectedWalletAccountId,
      );

      setSwapData({ toSelectedWalletAssetId: defaultToWalletResource?.walletAsset.id || '' });
    }
  }, [swapWalletResources, swapData.toSelectedWalletAssetId, selectedWalletAccountId, setSwapData]);

  useEffect(() => {
    trigger(); // trigger validation in case if user blured input before estimate happened
  }, [validationValues, trigger]);

  return (
    <Box pt="26px">
      <VStack px="24px" zIndex="1">
        <CryptocurrencyInputsForm control={control} inputs={cryptocurrencyInputs} />
      </VStack>
      <HStack pb="24px" px="24px" alignItems="center" flexWrap="wrap" mt="12px">
        <DetailsBox
          title="Network fee"
          value={`${cryptocurrencyInputs[0].selectedCurrency?.network.ticker} ${trimCryptoAmountLength(networkFee, 9)}`}
          isReady={!isEstimateFetching}
          containerProps={{ w: { base: '50%', md: '33%' }, pr: '8px' }}
        />
        <DetailsBox
          title="Estimated rate"
          value={`${cryptocurrencyInputs[0].selectedCurrency?.ticker || ''}/${
            cryptocurrencyInputs[1].selectedCurrency?.ticker || ''
          } ${trimCryptoAmountLength(rate, 9)}`}
          isReady={!isEstimateFetching}
          containerProps={{ w: { base: '50%', md: '33%' }, pr: '8px' }}
        />
        <DetailsBox
          title="Estimated time"
          value="15 minutes"
          isReady={!isEstimateFetching}
          containerProps={{ w: { base: '50%', md: '33%' }, pr: '8px' }}
        />
      </HStack>
      <BottomPurpleButton isDisabled={isLoading || pairIsInactive} onPress={handleSubmit(onSubmit)}>
        Start new exchange
      </BottomPurpleButton>
    </Box>
  );
};

export default AssetSwapAmount;
