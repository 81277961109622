/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import { FlatList, HStack, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useMemo, useState } from 'react';

import {
  useAddRecommendedWalletAssetMutation,
  useHideWalletAssetMutation,
  useWalletSummary,
} from '@cryptowallet/frontend/hooks';
import { BalanceListView, DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { Input, OverlaySpinner, Switch, TabButton, useUserSettings } from '@cryptowallet/frontend/ui';
import DraggableFlatList from '@cryptowallet/web/components/DraggableFlatList';

import AddableAssetItem from './AddableAssetItem';
import DraggableAssetItem from './DraggableAssetItem';

const filterableColumns = ['label', 'ticker', 'networkTicker'];

const filterWalletList = (item, searchValue) => {
  const {
    asset: { currency, network },
  } = item;

  const data = {
    ..._.pick(currency, ['label', 'ticker']),
    networkTicker: network.ticker,
  };

  return filterableColumns.some(column => data[column].toLowerCase().includes(searchValue.toLowerCase()));
};

const ManageAssets = () => {
  const [searchValue, setSearchValue] = useState('');
  const {
    isFetching: isWalletSummaryFetching,
    existingWalletResources,
    walletResourcesToAdd,
    recommendedWalletResources,
  } = useWalletSummary();
  const { hiddenWalletAssets } = useUserSettings();
  const { view, setBalanceListView, setSelectedWalletAssetId, setDetailsPanelView } = useAccountsStore(
    state => ({
      view: state.balanceListView,
      setBalanceListView: state.setBalanceListView,
      setSelectedWalletAssetId: state.setSelectedWalletAssetId,
      setDetailsPanelView: state.setDetailsPanelView,
    }),
    shallow,
  );
  const { mutate } = useHideWalletAssetMutation();

  const { draggableList, zeroBalanceAssets, hideZeroBalancesActive } = useMemo(() => {
    const list = [
      ...existingWalletResources.map(item => ({
        id: item.walletAccount.id,
        ...item,
      })),
      ...recommendedWalletResources.map(item => ({
        id: item.walletAsset.id,
        ...item,
      })),
    ];

    const zeroBalanceAssets = list.filter(item => item.cryptoBalance === '0');
    const hideZeroBalancesActive = zeroBalanceAssets.every(item => hiddenWalletAssets.includes(item.asset.id));

    return {
      draggableList: list.filter(item => (searchValue ? filterWalletList(item, searchValue) : true)),
      zeroBalanceAssets,
      hideZeroBalancesActive,
    };
  }, [searchValue, existingWalletResources, recommendedWalletResources, hiddenWalletAssets]);

  const addableList = useMemo(
    () =>
      walletResourcesToAdd
        .map(item => ({
          id: item.asset.id,
          ...item,
        }))
        .filter(item => {
          const assetToAddInRecommendations = recommendedWalletResources.find(rec => item.id === rec.asset.id);

          return !assetToAddInRecommendations;
        })
        .filter(item => (searchValue ? filterWalletList(item, searchValue) : true)),
    [walletResourcesToAdd, searchValue, recommendedWalletResources],
  );

  const { mutate: addRecommendedWalletAssetMutation } = useAddRecommendedWalletAssetMutation();

  const hideWalletAsset = assetId => mutate({ assetIds: [assetId], visibility: hiddenWalletAssets.includes(assetId) });

  const onToggleHideZeroBalances = () =>
    mutate({ assetIds: zeroBalanceAssets.map(item => item.asset.id), visibility: hideZeroBalancesActive });

  const onAddRecommendedWalletAsset = id => {
    const resource = walletResourcesToAdd.find(item => item.walletAsset.id === id);

    addRecommendedWalletAssetMutation({ walletResources: [resource] });
    setBalanceListView(BalanceListView.Balance);
    setDetailsPanelView(DetailsPanelView.GenerateAddress);
    setSelectedWalletAssetId(resource.walletAsset.id);
  };

  const isReady = !isWalletSummaryFetching;

  if (!isReady) {
    return <OverlaySpinner />;
  }

  return (
    <VStack py="40px" px="12px">
      <VStack p="12px" pt="0">
        <Input placeholder="Search" fontSize="lg" value={searchValue} onChangeText={v => setSearchValue(v)} />
        <HStack my="32px" px="12px">
          <TabButton
            onPress={() => setBalanceListView(BalanceListView.Manage)}
            active={view === BalanceListView.Manage}
            mr="24px">
            Manage Accounts
          </TabButton>
          <TabButton onPress={() => setBalanceListView(BalanceListView.Add)} active={view === BalanceListView.Add}>
            Add Account
          </TabButton>
        </HStack>
      </VStack>
      {view === BalanceListView.Manage && (
        <Switch
          mb="22px"
          px="10px"
          onToggle={onToggleHideZeroBalances}
          isChecked={hideZeroBalancesActive}
          label="Hide zero balances"
        />
      )}
      {view === BalanceListView.Manage && (
        <DraggableFlatList
          data={draggableList}
          onRowDragEnd={newList => console.log('todo save new order')}
          renderItem={props => {
            const hidden = hiddenWalletAssets.includes(props.item.asset.id);

            return <DraggableAssetItem hidden={hidden} hideWalletAsset={hideWalletAsset} {...props} />;
          }}
          rowHeight={56}
        />
      )}
      {view === BalanceListView.Add && (
        <FlatList
          data={addableList}
          renderItem={props => <AddableAssetItem {...props} onPress={onAddRecommendedWalletAsset} />}
        />
      )}
    </VStack>
  );
};

export default ManageAssets;
