import { Text, VStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPurple, Icon } from '@cryptowallet/frontend/ui';

const RewardsPlaceholder = () => {
  const navigate = useNavigate();

  return (
    <VStack flexGrow="1" justifyContent="center" alignItems="center">
      <>
        <Icon name="RewardsPlacholderIcon" h="142px" w="84px" />
        <Text lineHeight="18px" mt="16px" mb="40px" color="white" fontSize="xl">
          No rewards
        </Text>
        <ButtonPurple h="50" px="32px" onPress={() => navigate('/referral/overview')}>
          Invite friends
        </ButtonPurple>
      </>
    </VStack>
  );
};

export default RewardsPlaceholder;
