import { Box } from 'native-base';
import React from 'react';

import CopyBox from '../../CopyBox';
import { ColumnType } from '../Row';

export interface ICopyTextColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: ColumnType.COPY_TEXT;
}

interface ICopyTextProps {
  data: any;
  columnDef: ICopyTextColumnDef;
}

export const CopyText = ({ data }: ICopyTextProps) => (
  <Box>
    <CopyBox
      ellipsis="middle"
      value={data}
      pressableProps={{ top: '-2px' }}
      valueStyles={{ fontSize: 'lg', fontWeight: '500' }}
    />
  </Box>
);
