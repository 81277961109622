import qs from 'qs';
import { Navigate, useLocation } from 'react-router-dom';

import { useUserStatus } from '@cryptowallet/frontend/hooks';

const PublicRoute = ({ children, loggedUserVisible = false }) => {
  const userStatus = useUserStatus();
  const location = useLocation();

  if (!loggedUserVisible && userStatus.isLoggedIn) {
    const { search = {}, redirectUrl = '/dashboard/widgets' } = (location.state || {}) as never;
    const to = `${redirectUrl}?${qs.stringify(search)}`;

    return <Navigate to={to} replace />;
  }

  return children;
};

export default PublicRoute;
