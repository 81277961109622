import React from 'react';
import { Path, Svg } from 'react-native-svg';

export const CardIcon = ({ fill = '#BDB8CC' }) => (
  <Svg width="30" height="20" viewBox="0 0 30 20" fill="none">
    <Path
      d="M0.5 2.5C0.5 1.11929 1.58198 0 2.91667 0H27.0833C28.418 0 29.5 1.11929 29.5 2.5V4.375H0.5V2.5Z"
      fill={fill}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 8.125H29.5V17.5C29.5 18.8807 28.418 20 27.0833 20H2.91667C1.58198 20 0.5 18.8807 0.5 17.5V8.125ZM24.0625 17.0226C23.7418 17.3195 23.3184 17.5 22.8542 17.5C21.8532 17.5 21.0417 16.6605 21.0417 15.625C21.0417 14.5895 21.8532 13.75 22.8542 13.75C23.3184 13.75 23.7418 13.9305 24.0625 14.2274C24.3832 13.9305 24.8066 13.75 25.2708 13.75C26.2718 13.75 27.0833 14.5895 27.0833 15.625C27.0833 16.6605 26.2718 17.5 25.2708 17.5C24.8066 17.5 24.3832 17.3195 24.0625 17.0226Z"
      fill={fill}
    />
  </Svg>
);
