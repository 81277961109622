import { IColumnDef, TableType } from '@cryptowallet/web/components/Table';
import { ColumnType } from '@cryptowallet/web/components/Table/Row';

import ReferralCustomSimpleText from '../components/ReferralCustomSimpleText';

export const columnDefs: IColumnDef[] = [
  {
    id: 'email',
    headerName: 'User ID',
    field: 'referralUserEmail',
    type: ColumnType.CUSTOM,
    cellRenderer: ReferralCustomSimpleText,
    containerStyles: {
      [TableType.FULL]: {
        w: '33%',
      },
      [TableType.SMALL]: {
        w: '50%',
      },
    },
  },
  {
    id: 'register_date',
    headerName: 'Registration date',
    field: 'createdAt',
    type: ColumnType.DATE,
    hidden: {
      type: [TableType.SMALL],
    },
    containerStyles: {
      [TableType.FULL]: {
        w: '33%',
      },
    },
  },
  {
    id: 'task_completed_date',
    headerName: 'Task completed',
    field: 'assignedRewardsAt',
    type: ColumnType.DATE,
    hidden: {
      type: [TableType.SMALL],
    },
    containerStyles: {
      [TableType.FULL]: {
        w: '33%',
      },
    },
  },
];
