import { IFormControlProps, IInputProps } from 'native-base';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { ScrollView } from 'react-native';

import { ExchangerAssetEntityDto } from '@cryptowallet/frontend/client';

import LabeledMultilineInput from './LabeledMultilineInput';

interface IWalletAddressInputProps extends IInputProps {
  control: Control;
  walletAddress: string;
  setWalletAddress: (walletAddress: string) => void;
  toCurrency: ExchangerAssetEntityDto;
  label?: string;
  formControlProps?: IFormControlProps;
  scrollRef?: React.MutableRefObject<ScrollView>;
}

const WalletAddressInput = ({
  control,
  walletAddress,
  setWalletAddress,
  toCurrency,
  label = 'Enter wallet address',
  formControlProps = {},
  scrollRef,
  ...rest
}: IWalletAddressInputProps) => {
  const { field, fieldState } = useController({
    name: 'walletAddress',
    control,
    defaultValue: walletAddress,
  });

  const handleScroll = () => {
    setTimeout(() => scrollRef?.current?.scrollToEnd({ animated: true }), 100);
  };

  return (
    <LabeledMultilineInput
      formControlProps={{ mt: '0', ...formControlProps }}
      onChangeText={(value: string) => {
        field.onChange(value);
        setWalletAddress(value);
      }}
      onFocus={handleScroll}
      onBlur={field.onBlur}
      value={walletAddress}
      error={fieldState.error}
      _ios={{
        pt: '21px',
      }}
      _android={{
        textAlignVertical: 'center',
      }}
      label={label}
      labelTextProps={{
        color: 'textLabel',
        fontSize: 'lg',
      }}
      color="textRegular"
      placeholder="Address"
      fontSize="lg"
      {...rest}
    />
  );
};

export default WalletAddressInput;
