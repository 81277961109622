import { HStack, Link, Text } from 'native-base';

import { BottomPurpleButton, Icon } from '@cryptowallet/frontend/ui';

import { StepComponentProps } from './IStepProps';
import StepLayout from './Layout';

const DownloadApp = ({ onStepCompleted }: StepComponentProps) => (
  <StepLayout
    body={
      <>
        <Text fontSize="lg">Download and install the Google Authenticator app</Text>
        <HStack h="40px" mt="24px" flexGrow="1">
          <Link isExternal href="https://apps.apple.com/us/app/google-authenticator/id388497605">
            <Icon cursor="pointer" name="app-store" w="150px" h="50px" />
          </Link>
          <Link
            isExternal
            ml="16px"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
            <Icon cursor="pointer" name="play-market" w="169px" h="50px" />
          </Link>
        </HStack>
      </>
    }
    stepButton={
      <BottomPurpleButton size="lg" onPress={onStepCompleted}>
        Next
      </BottomPurpleButton>
    }
  />
);

export default DownloadApp;
