import { Box, Flex } from 'native-base';
import React from 'react';

import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/frontend/client';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { Pressable } from '@cryptowallet/frontend/ui';
import SectionTitle from '@cryptowallet/web/components/SectionTitle';

import PspMethodItem from './PspMethodItem';

export interface IPaymentMethodButton {
  title?: string;
  description: string;
  type: ExchangerCreateExchangeRequestDtoPspTypeEnum;
  icon: {
    active: string;
    default: string;
  };
}

const PaymentMethods = ({
  title = 'Payment method',
  setPaymentMethod,
  paymentMethod = ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa,
  list,
  containerProps = {},
  pressableProps = {},
}) => {
  const { isMobile } = useScreenSize();

  return (
    <Box mt="10px" {...containerProps}>
      <SectionTitle>{title}</SectionTitle>
      <Flex mt="24px" gap="10px" direction={isMobile ? 'column' : 'row'} wrap="wrap">
        {list.map(({ description, type, icon, estimateValue, isHidden }) => {
          const active = paymentMethod === type;

          return (
            !isHidden && (
              <Pressable
                key={type}
                onPress={() => setPaymentMethod(type)}
                flexGrow="1"
                minW="40%"
                maxW={{ base: 'unset', lg: '264px' }}
                borderRadius="10px"
                overflow="hidden"
                outlineWidth="0"
                borderColor="transparent"
                borderWidth="1px"
                userSelect="none"
                _focus={{
                  borderColor: 'secondary.400',
                }}
                {...pressableProps}>
                {({ isHovered, isFocused }) => {
                  const inactiveBg = isHovered ? 'primary.400' : 'gray.200';
                  const bg = active ? 'primary.500' : inactiveBg;

                  return (
                    <PspMethodItem
                      active={active}
                      bg={bg}
                      estimateValue={estimateValue}
                      icon={icon}
                      description={description}
                      isHovered={isHovered}
                      isFocused={isFocused}
                    />
                  );
                }}
              </Pressable>
            )
          );
        })}
      </Flex>
    </Box>
  );
};

export default PaymentMethods;
