import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, FormControl } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { paymentMethodsInfo } from '@cryptowallet/frontend/constants';
import { useGA } from '@cryptowallet/frontend/ga';
import { useActiveBuyPaymentMethods, useAssets, useUserStatus } from '@cryptowallet/frontend/hooks';
import { useBuyEstimate, useBuyFlowStore } from '@cryptowallet/frontend/stores';
import {
  ActionGuard,
  BottomPurpleButton,
  CryptocurrencyInputsForm,
  ErrorMessage,
  ErrorMessageType,
  IValidate,
  LabeledMultilineInput,
  Switch,
  useAlerts,
  useUser,
  WalletAddressInput,
} from '@cryptowallet/frontend/ui';
import { getClientError, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import {
  AssetEntityDtoTypeEnum,
  CreateExchangeBadRequestDtoErrorEnum,
  EventRequestDtoEventNameEnum,
  exchangerApi,
  ExchangerAssetEntityDto,
  ExchangerCreateExchangeRequestDto,
  ExchangerCreateExchangeRequestDtoPspTypeEnum,
  ExchangerCreateExchangeRequestDtoTypeEnum,
  ExchangeType,
} from '@cryptowallet/web/api-client';
import WarningText from '@cryptowallet/web/components/WarningText';

import ExchangeLimits from '../ExchangeLimits';
import PaymentMethods from '../PaymentMethods';

import BuyTransactionDetails from './BuyTransactionDetails';

const CreateExchange = (): JSX.Element => {
  const { kycPoaHighRiskVerified } = useUserStatus();
  const { isLoading: estimateIsLoading, data: estimateData, validationValues } = useBuyEstimate();
  const { buyFromCurrencies, buyToCurrencies } = useAssets();
  const { showErrorToaster } = useAlerts();
  const queryClient = useQueryClient();
  const ga = useGA();
  const { user } = useUser();

  const [useMyWalletDisabled, setUseMyWalletDisabled] = useState(false);
  const [errorUseMyWallet, setErrorUseMyWallet] = useState('');

  const [
    paymentMethod,
    setPaymentMethod,
    toCurrency,
    fromCurrency,
    amount,
    setAmount,
    setToCurrency,
    setFromCurrency,
    walletAddress,
    setWalletAddress,
    useMyWalletAddress,
    setUseMyWalletAddress,
    destinationTag,
    setDestinationTag,
  ] = useBuyFlowStore(
    state => [
      state.paymentMethod,
      state.setPaymentMethod,
      state.toCurrency,
      state.fromCurrency,
      state.amount,
      state.setAmount,
      state.setToCurrency,
      state.setFromCurrency,
      state.walletAddress,
      state.setWalletAddress,
      state.useMyWalletAddress,
      state.setUseMyWalletAddress,
      state.destinationTag,
      state.setDestinationTag,
    ],
    shallow,
  );

  const useMyWalletAddressEnabled = kycPoaHighRiskVerified && useMyWalletAddress;
  const activePaymentMethods = useActiveBuyPaymentMethods();
  const activeEstimate = estimateData?.[paymentMethod];
  const allowedSepaTicker = 'eur';

  const validate: IValidate = useMemo(
    () => ({
      moreThan: {
        rule: value =>
          +value >= validationValues?.min ||
          `The deposit amount is too small. Please enter an amount greater than %${validationValues?.min}%`,
        fixValue: validationValues?.min,
      },
      lessThan: {
        rule: value =>
          +value <= validationValues?.max ||
          `The deposit amount is too big. Please enter an amount less than %${validationValues?.max}%`,
        fixValue: validationValues?.max,
      },
    }),
    [validationValues?.min, validationValues?.max],
  );

  const cryptocurrencyInputs = useMemo(
    () => [
      {
        name: 'fromAmount',
        label: 'You send',
        value: amount,
        currencies: buyFromCurrencies,
        selectedCurrency: fromCurrency,
        paymentMethod,
        setValue: setAmount,
        validate,
        onCurrencyChange: (newCurrency: ExchangerAssetEntityDto) => {
          ga.sendEvent(EventRequestDtoEventNameEnum.CurrencyChange, {
            email: user.email,
            custom_id: newCurrency.id,
            currency_choosen: newCurrency.ticker,
            custom_type: newCurrency.type,
            customer_source: 'eur',
          });
          setFromCurrency(newCurrency);
        },
      },
      {
        name: 'toAmount',
        label: 'You get',
        value: trimCryptoAmountLength(activeEstimate?.estimatedAmount?.toString() || ''),
        currencies: buyToCurrencies.filter(item =>
          useMyWalletAddress ? item.walletAccountId || item.walletAssetId : true,
        ),
        selectedCurrency: toCurrency,
        onCurrencyChange: (newCurrency: ExchangerAssetEntityDto) => {
          ga.sendEvent(EventRequestDtoEventNameEnum.CurrencyChange, {
            email: user.email,
            custom_id: newCurrency.id,
            custom_network: newCurrency.network?.label,
            currency_choosen: newCurrency.ticker,
            custom_type: newCurrency.type,
            customer_source: 'btc',
          });
          setToCurrency(newCurrency);
        },
        isLoading: estimateIsLoading,
        showNetwork: true,
      },
    ],
    [
      amount,
      buyFromCurrencies,
      fromCurrency,
      setAmount,
      activeEstimate?.estimatedAmount,
      buyToCurrencies,
      toCurrency,
      setToCurrency,
      estimateIsLoading,
      validate,
      useMyWalletAddress,
    ],
  );

  const mutation = useMutation((exchangeData: ExchangerCreateExchangeRequestDto) =>
    exchangerApi.exchangerControllerCreateExchange(exchangeData),
  );

  const { trigger, setError, control } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    // Need to change payment method if it appeared to be disabled
    // There is still logically impossible but technically possible case
    // when user does not have enabled payment methods.
    // In this case we would set "undefined"...
    if (
      !activePaymentMethods.find(method => method === paymentMethod) ||
      fromCurrency.ticker.toLowerCase() !== allowedSepaTicker
    ) {
      setPaymentMethod(activePaymentMethods[0]);
    }
  }, []);

  useEffect(() => {
    // different payment methods have different min/max amounts.
    // We need to trigger validation again if payment method changed
    trigger();
  }, [paymentMethod, validate, toCurrency]);

  const hasDestinationTag = toCurrency?.network?.hasDestinationTag;
  const showDestinationTagInput = !useMyWalletAddress && hasDestinationTag;

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    try {
      const state = useBuyFlowStore.getState();
      const { data } = await mutation.mutateAsync({
        fromExchangerAssetId: fromCurrency.id,
        toExchangerAssetId: toCurrency.id,
        type: ExchangerCreateExchangeRequestDtoTypeEnum.Buy,
        amount: Number(amount),
        pspType: paymentMethod,
        recipientDetails: {
          ...(showDestinationTagInput ? { destinationTag } : {}),
          ...(useMyWalletAddressEnabled ? { useMyWalletAddress } : { toAddress: walletAddress }),
        },
      });
      state.setExchangeId(data.exchangeId);
      queryClient.invalidateQueries(['getExchanges']);
      queryClient.refetchQueries(['limits']);
    } catch (err) {
      switch (err.response?.data?.error) {
        case CreateExchangeBadRequestDtoErrorEnum.WalletAddressIncorrect: {
          setError('walletAddress', { type: 'custom', message: 'Incorrect wallet address. Please try again' });
          break;
        }
        case CreateExchangeBadRequestDtoErrorEnum.CantFindOrCreateWalletAccount: {
          setUseMyWalletAddress(false);
          setError('walletAddress', {
            type: 'custom',
            message: 'Cannot use internal wallet. Please enter an external address instead',
          });
          break;
        }
        default: {
          const clientError = getClientError(new Error(), { title: `Can't create exchange, please try again later` });

          showErrorToaster(clientError);
        }
      }
    }
  };

  const checkUseMyWalletAvailable = newUseMyWallet => {
    if (newUseMyWallet && toCurrency && !toCurrency.walletAssetId) {
      setUseMyWalletDisabled(true);

      setTimeout(() => {
        setUseMyWalletDisabled(false);
        setUseMyWalletAddress(false);
        setErrorUseMyWallet('Sorry this asset is unavailable to buy using internal wallet.');
      }, 600);
    } else {
      setErrorUseMyWallet('');
    }
  };

  const onToggleUseMyWalletAddress = () => {
    if (useMyWalletDisabled) return;

    const newUseMyWallet = !useMyWalletAddressEnabled;
    setUseMyWalletAddress(newUseMyWallet);

    checkUseMyWalletAvailable(newUseMyWallet);
  };

  useEffect(() => {
    checkUseMyWalletAvailable(useMyWalletAddress);
  }, [toCurrency]);

  return (
    <Box mt="30px">
      <Box px="24px" zIndex="1">
        <CryptocurrencyInputsForm control={control} inputs={cryptocurrencyInputs} />
        <FormControl isInvalid={!!errorUseMyWallet}>
          <Switch
            px="10px"
            onToggle={onToggleUseMyWalletAddress}
            isChecked={useMyWalletAddressEnabled}
            isDisabled={!kycPoaHighRiskVerified}
            label={
              useMyWalletAddress
                ? 'Turn off to use your external wallet address'
                : 'Turn on to use your CryptoWallet.com wallet address'
            }
          />
          <div className={errorUseMyWallet ? 'shake-error' : ''}>
            <ErrorMessage
              error={{
                type: 'custom',
                message: errorUseMyWallet,
              }}
              type={ErrorMessageType.ERROR}
            />
          </div>
          {useMyWalletAddressEnabled && toCurrency?.type === AssetEntityDtoTypeEnum.CryptoToken && (
            <WarningText
              text={`To withdraw funds from your CW wallet, you will need to deposit ${toCurrency?.network?.ticker} to cover network fees`}
            />
          )}
        </FormControl>
        {!useMyWalletAddressEnabled && (
          <WalletAddressInput
            control={control}
            walletAddress={walletAddress}
            setWalletAddress={setWalletAddress}
            toCurrency={toCurrency}
          />
        )}
        {showDestinationTagInput && (
          <Controller
            control={control}
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <LabeledMultilineInput
                onChangeText={(value: string) => {
                  onChange(value);
                  setDestinationTag(value);
                }}
                onBlur={field.onBlur}
                {...field}
                error={error}
                label="Destination tag(memo)"
                labelTextProps={{
                  color: 'textLabel',
                  fontSize: 'md',
                }}
                color="textRegular"
                placeholder="Destination tag/memo"
                fontSize="lg"
              />
            )}
            name="memo"
          />
        )}
        <PaymentMethods
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          list={activePaymentMethods.map(method => ({
            ...paymentMethodsInfo[method],
            estimateValue: `You get ${trimCryptoAmountLength(
              estimateData?.[method]?.estimatedAmount?.toString() || '',
            )} ${toCurrency.ticker}`,
            isHidden: fromCurrency
              ? fromCurrency.ticker.toLowerCase() !== allowedSepaTicker &&
                method === ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa
              : false,
          }))}
        />
        <ExchangeLimits
          currencyTicker={fromCurrency.ticker}
          exchangeType={ExchangeType.Buy}
          paymentMethod={paymentMethod}
        />
        <BuyTransactionDetails />
      </Box>
      <ActionGuard>
        {({ openAlert }) => (
          <BottomPurpleButton size="lg" isDisabled={mutation.isLoading} onPress={openAlert || handleSubmit}>
            Continue
          </BottomPurpleButton>
        )}
      </ActionGuard>
    </Box>
  );
};

export default CreateExchange;
