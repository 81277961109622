import { HStack, VStack } from 'native-base';
import React, { useMemo } from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { Icon } from '@cryptowallet/frontend/ui';
import { trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import TrxDetailsItem from '@cryptowallet/web/components/TrxDetailsItem';

const ExchangerTrxDetails = ({ data }) => {
  const items = useMemo(() => {
    const { fromAsset, toAsset } = data;

    if (fromAsset && toAsset) {
      return [
        {
          id: 'from',
          image: fromAsset.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
          label: fromAsset.currency.label,
          amount: trimCryptoAmountLength(data.fromAmount || data.expectedFromAmount),
          ticker: fromAsset.currency.internalTicker,
        },
        {
          id: 'to',
          image: toAsset.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
          label: toAsset.currency.label,
          amount: trimCryptoAmountLength(data.toAmount) || '',
          ticker: toAsset.currency.internalTicker,
        },
      ];
    }

    return [];
  }, [
    data?.fromAsset,
    data?.toAsset,
    data?.fromAmount,
    data?.toAmount,
    data?.fromExchangerAssetId,
    data?.toExchangerAssetId,
    data?.expectedFromAmount,
  ]);

  return (
    <HStack alignItems="center">
      <Icon w="9px" mr="6px" h="26px" name="recent-activity/exchange-arrow" />
      <VStack justifyContent="space-between">
        {items.map(item => (
          <TrxDetailsItem key={item.id} {...item} />
        ))}
      </VStack>
    </HStack>
  );
};

export default ExchangerTrxDetails;
