import { FormControl, IFormControlProps, ITextAreaProps, ITextProps, Text, TextArea } from 'native-base';
import React from 'react';
import { FieldError } from 'react-hook-form/dist/types/errors';

import ErrorMessage, { ErrorMessageType } from '../ErrorMessage';

const LabeledMultilineInput = ({
  label,
  formControlProps = {},
  error,
  labelTextProps = {},
  onChangeText,
  ...props
}: {
  label: string;
  formControlProps?: IFormControlProps;
  error?: FieldError;
  labelTextProps?: ITextProps;
  onSubmit?: () => void;
} & ITextAreaProps): JSX.Element => (
  <FormControl isInvalid={!!error} {...formControlProps}>
    <FormControl.Label>
      <Text {...labelTextProps}>{label}</Text>
    </FormControl.Label>
    <textarea
      className="wallet-address-text-area"
      autoComplete="off"
      placeholder={props.placeholder}
      spellCheck={false}
      value={props.value}
      onChange={e => {
        if (onChangeText) {
          onChangeText(e.target.value);
        }
      }}
    />
    <ErrorMessage type={ErrorMessageType.ERROR} error={error} />
  </FormControl>
);

export default LabeledMultilineInput;
