import React from 'react';

import { Button } from './Button';

const ghostButtonStyles = {
  p: 0,
  outlineWidth: 0,
  _text: {
    color: 'white',
    lineHeight: '18px',
  },
  _hover: {
    _text: {
      color: 'primary.300',
    },
  },
  _focus: {
    _text: {
      color: 'secondary.400',
    },
    _hover: {
      _text: {
        color: 'secondary.400',
      },
    },
  },
};

export const GhostButton = ({ children, ...props }) => (
  <Button variant="unstyled" {...ghostButtonStyles} {...props}>
    {children}
  </Button>
);
