import { Box, Skeleton, Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { useAssets, useBuyExchange, useScreenSize } from '@cryptowallet/frontend/hooks';
import { buyEstimatePlaceholder, useBuyEstimate, useBuyFlowStore } from '@cryptowallet/frontend/stores';
import { SecondsCountdown, useWidget } from '@cryptowallet/frontend/ui';
import { getCurrencySymbol, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import TransactionDetailsCollapseBox from '@cryptowallet/web/components/TransactionDetailsCollapseBox';

const BuyTransactionDetails = ({ fullData = false }): JSX.Element => {
  const { isMobile } = useScreenSize();

  const { isWidget } = useWidget();

  const { buyFromCurrencies, buyToCurrencies } = useAssets();

  const { estimateResult, data: exchangeResponse, completed } = useBuyExchange();
  const { data } = useBuyEstimate(!completed);
  const [storeToCurrency, storeFromCurrency, nextRateUpdateTime, paymentMethod] = useBuyFlowStore(
    state => [state.toCurrency, state.fromCurrency, state.nextRateUpdateTime, state.paymentMethod],
    shallow,
  );

  const { fromExchangerAssetId: fromCurrencyId, toExchangerAssetId: toCurrencyId } = exchangeResponse?.data || {};

  const exchangeFromCurrency = buyFromCurrencies.find(item => item.id === fromCurrencyId);
  const exchangeToCurrency = buyToCurrencies.find(item => item.id === toCurrencyId);

  const fromCurrency = exchangeFromCurrency || storeFromCurrency;
  const toCurrency = exchangeToCurrency || storeToCurrency;

  const fromCurrencyLabel = getCurrencySymbol(fromCurrency?.ticker);
  const toCurrencyLabel = getCurrencySymbol(toCurrency?.ticker);
  const estimateData = (data || buyEstimatePlaceholder)[paymentMethod];

  const estimate = fullData ? estimateResult : estimateData;
  const isReady = !!toCurrency && !!fromCurrency;

  return (
    <TransactionDetailsCollapseBox hideHeading={isWidget} mb="32px" mt={isWidget ? '0' : '32px'}>
      <Box mt="16px" flexDirection="row" flexWrap="wrap">
        <Box w={isMobile ? '100%' : '50%'} mb="16px" pr={isMobile ? '0' : '24px'}>
          <Text color="textLabel" fontSize="lg">
            Bank Fee
          </Text>
          <Skeleton h="21px" isLoaded={isReady}>
            <Text color="textRegular" fontWeight="500" fontSize="lg">
              {fromCurrencyLabel} {estimate?.bankFeeAmount}
            </Text>
          </Skeleton>
        </Box>
        <Box w={isMobile ? '100%' : '50%'} mb="16px">
          <Text color="textLabel" fontSize="lg">
            Network fee (included)
          </Text>
          <Skeleton h="21px" isLoaded={isReady}>
            <Text color="textRegular" fontWeight="500" fontSize="lg">
              {estimate?.networkFeeAmount} {toCurrency?.ticker.toUpperCase()}
            </Text>
          </Skeleton>
        </Box>
        <Box w={isMobile ? '100%' : '50%'} pr={isMobile ? '0' : '24px'} mb={isMobile ? '16px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Service fee
          </Text>
          <Skeleton h="21px" isLoaded={isReady}>
            <Text color="textRegular" fontWeight="500" fontSize="lg">
              {fromCurrencyLabel} {estimate?.serviceFeeAmount}
            </Text>
          </Skeleton>
        </Box>
        <Box w={isMobile ? '100%' : '50%'}>
          <Text color="textLabel" fontSize="lg">
            {!fullData && (
              <>
                Rate updates in{' '}
                <SecondsCountdown expireTime={nextRateUpdateTime} renderer={({ timer }) => <>{timer}</>} />
              </>
            )}
            {fullData && 'Rate'}
          </Text>
          <Skeleton h="21px" isLoaded={isReady}>
            <Text color="textRegular" fontWeight="500" fontSize="lg">
              {fromCurrencyLabel}/{toCurrencyLabel} {trimCryptoAmountLength(estimate?.estimatedRate?.toString() || '')}
            </Text>
          </Skeleton>
        </Box>
      </Box>
    </TransactionDetailsCollapseBox>
  );
};

export default BuyTransactionDetails;
