/* eslint-disable sonarjs/no-duplicate-string */
import { WalletTransactionEntityDtoStatusEnum, WalletTransactionEntityDtoTypeEnum } from '@cryptowallet/web/api-client';
import { IColumnDef, TableType } from '@cryptowallet/web/components/Table';
import { SingleSelectOptionType } from '@cryptowallet/web/components/Table/CellRenderers';
import { ColumnType } from '@cryptowallet/web/components/Table/Row';

import WalletTrxDetails from './WalletTrxDetails';

export const columnDefs: IColumnDef[] = [
  {
    id: 'type',
    headerName: 'Type',
    field: 'type',
    type: ColumnType.COLORED_TEXT,
    getValue: data => data.walletTransaction.type,
    hideHeader: true,
    options: [
      {
        id: 'deposit',
        value: WalletTransactionEntityDtoTypeEnum.Deposit,
        title: 'Deposit',
        color: 'secondary.400',
      },
      {
        id: 'withdraw',
        value: WalletTransactionEntityDtoTypeEnum.Withdrawal,
        title: 'Withdraw',
        color: 'deepBlue',
      },
    ],
    containerStyles: {
      [TableType.FULL]: {
        w: '80px',
      },
      [TableType.SMALL]: {
        w: '50%',
        mb: '24px',
        h: '21px',
      },
    },
  },
  {
    id: 'trx_details',
    headerName: 'Transaction details',
    field: 'details',
    type: ColumnType.CUSTOM,
    cellRenderer: WalletTrxDetails,
    getValue: data => data,
    sortIndex: {
      [TableType.SMALL]: 2,
    },
    headerContainerStyles: {
      [TableType.FULL]: {
        w: {
          base: 'calc(30% + 100px)',
          lg: 'calc(22% + 100px)',
          xl: 'calc(20% + 100px)',
        },
      },
    },
    containerStyles: {
      [TableType.FULL]: {
        w: {
          base: 'calc(30% + 20px)',
          lg: 'calc(22% + 20px)',
          xl: 'calc(20% + 20px)',
        },
      },
      [TableType.SMALL]: {
        w: { base: '60%', md: '66%' },
      },
    },
  },
  {
    id: 'date',
    headerName: 'Date',
    field: 'createdAt',
    type: ColumnType.DATE,
    getValue: data => data.walletTransaction.createdAt,
    sortIndex: {
      [TableType.SMALL]: 1,
    },
    containerStyles: {
      [TableType.FULL]: {
        w: '15%',
      },
      [TableType.SMALL]: {
        w: '50%',
        h: '21px',
        alignItems: 'flex-end',
        mb: '24px',
      },
    },
  },
  {
    id: 'payout_details',
    headerName: 'Payout details',
    field: 'fromAddress',
    type: ColumnType.COPY_TEXT,
    getValue: data => data.walletTransaction.fromAddress,
    hidden: {
      type: [TableType.SMALL],
    },
    containerStyles: {
      [TableType.FULL]: {
        w: {
          base: '40%',
          lg: '35%',
          xl: '41%',
        },
        maxW: {
          xl: '340px',
        },
      },
    },
  },
  {
    id: 'status',
    headerName: 'Status',
    field: 'status',
    type: ColumnType.SINGLE_SELECT,
    getValue: data => data.walletTransaction.status,
    options: [
      // todo other statuses when backend will be done
      {
        id: 'completed',
        value: WalletTransactionEntityDtoStatusEnum.Completed,
        title: 'Completed',
        color: 'textSuccess',
        type: SingleSelectOptionType.Circle,
      },
      {
        id: 'pending',
        value: WalletTransactionEntityDtoStatusEnum.PendingAmlScreening,
        title: 'Pending',
        color: 'secondary.400',
        type: SingleSelectOptionType.Circle,
      },
      {
        id: 'pending',
        value: WalletTransactionEntityDtoStatusEnum.Broadcasting,
        title: 'Pending',
        color: 'secondary.400',
        type: SingleSelectOptionType.Circle,
      },
      {
        id: 'failed',
        value: WalletTransactionEntityDtoStatusEnum.Failed,
        title: 'Failed',
        color: 'error.400',
        type: SingleSelectOptionType.Circle,
      },
      // {
      //   id: 'in_progress',
      //   value: 'in_progress',
      //   title: 'Resume',
      //   color: 'primary.400',
      //   type: SingleSelectOptionType.Button,
      // },
    ],
    containerStyles: {
      [TableType.FULL]: {
        w: {
          base: '7%',
          lg: '10%',
          xl: '7%',
        },
        flexGrow: '1',
        alignItems: 'flex-end',
      },
      [TableType.SMALL]: {
        ml: 'auto',
      },
    },
  },
];
