import { useMutation } from '@tanstack/react-query';
import { HStack, Text, VStack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { ClientErrors } from '@cryptowallet/frontend/client-errors';
import { useGA } from '@cryptowallet/frontend/ga';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { BottomPurpleButton, LabeledInput, useAlerts } from '@cryptowallet/frontend/ui';
import { getDeviceId } from '@cryptowallet/frontend/utils';
import {
  MfaLoginBadRequestResponseUsersDtoErrorEnum,
  userApi,
  VerifyOtpResponseUsersDtoRequiredMfaMethodsEnum,
} from '@cryptowallet/web/api-client';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import LinkPurple from '@cryptowallet/web/components/Links/LinkPurple';
import useInitialScrollTop from '@cryptowallet/web/hooks/useInitialScrollTop';

import { OnVerifyAuthStepFn } from '..';

import FormHeading from './FormHeading';

interface IMultifactorVerificationFormProps {
  onVerify: OnVerifyAuthStepFn;
  onEmailChangeRequest: () => void;
  requiredMfaMethods?: VerifyOtpResponseUsersDtoRequiredMfaMethodsEnum[];
  temporaryToken: string;
}

interface IFormPros {
  totp: string;
}

const otpInputName = 'totp';

const MultifactorVerificationForm = ({
  onVerify,
  onEmailChangeRequest,
  temporaryToken,
}: IMultifactorVerificationFormProps) => {
  useInitialScrollTop();
  const { isBiggerThanTablet } = useScreenSize();
  const { showErrorToaster } = useAlerts();
  const { control, handleSubmit, setError, setValue } = useForm<IFormPros>({
    defaultValues: {
      [otpInputName]: '',
    },
  });

  const totpValue = useWatch({
    control,
    name: otpInputName,
  });

  const ga = useGA();

  const { mutateAsync: mfaLoginMutateAsync, isLoading } = useMutation(async (totp: string) =>
    userApi.usersControllerMfaLogin(
      {
        deviceUniqueId: getDeviceId(),
        totp,
        gaClientId: await ga.getClientID(),
      },
      {
        headers: {
          Authorization: `Bearer ${temporaryToken}`,
        },
      },
    ),
  );

  const verify = useCallback(
    async ({ totp }: IFormPros) => {
      try {
        const { data } = await mfaLoginMutateAsync(totp);

        onVerify(data.refreshToken, data.accessToken);
        return;
      } catch (error) {
        if (error?.response?.data?.error === MfaLoginBadRequestResponseUsersDtoErrorEnum.TotpVerifyInvalid) {
          setError(otpInputName, {
            type: 'custom',
            message: ClientErrors.invalidTotp.title,
          });
        } else {
          showErrorToaster();
        }
      }
    },
    [setError, mfaLoginMutateAsync],
  );

  const onPaste = async () => {
    const clipboardVal = await navigator.clipboard.readText();
    const numericVal = clipboardVal.replace(/\D/g, '').substr(0, 6);
    if (numericVal) {
      setValue(otpInputName, numericVal);
    }
  };

  useEffect(() => {
    if (totpValue.length === 6) {
      handleSubmit(verify)();
    }
  }, [totpValue, handleSubmit, verify]);

  return (
    <VStack>
      <AuthHeader display="flex" flexDirection="row" alignItems="center">
        <FormHeading text="Two-Factor Authentication" onPressBack={onEmailChangeRequest} />
      </AuthHeader>
      <VStack py="32px" px="32px" pt="24px" pb="10px">
        <Text mb="32px" fontSize="lg">
          Please enter the 6-digit code from your Google Authenticator app.{' '}
        </Text>
        <Controller
          name={otpInputName}
          control={control}
          rules={{
            minLength: {
              value: 6,
              message: 'Verification code must contain 6 digits',
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <LabeledInput
              autoFocus={isBiggerThanTablet}
              label="2FA code"
              labelTextProps={{
                fontSize: 'lg',
                color: 'textLabel',
              }}
              placeholder="Paste code here"
              onChangeText={val => onChange(val.replace(/\D/g, '').substring(0, 6))}
              value={value}
              error={error}
              isDisabled={isLoading}
              fontSize="lg"
            />
          )}
        />
      </VStack>
      <BottomPurpleButton _text={{ fontSize: 'lg', color: 'white' }} size="lg" isDisabled={isLoading} onPress={onPaste}>
        Paste Code
      </BottomPurpleButton>
    </VStack>
  );
};

export default MultifactorVerificationForm;
