import { Box } from 'native-base';
import React from 'react';

const SecurityBlockContainer = ({ children, ...rest }) => (
  <Box p="32px" bg="gray.100" borderWidth="1px" borderRadius="10px" borderColor="transparent" {...rest}>
    {children}
  </Box>
);

export default SecurityBlockContainer;
