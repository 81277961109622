import { Box, HStack, Modal, Text, VStack } from 'native-base';
import React from 'react';

import { CloseButton, Icon, ModalBase } from '../../../index';

const data = [
  {
    title: 'Why CryptoWallet.com?',
    list: [
      'CryptoWallet.com is the only platform offering a crypto card with direct spending of over 800 cryptocurrencies',
      'The lowest fees on the market',
      'Simply tap the card to spend crypto simply, anywhere you go.',
    ],
  },
  {
    title: 'Why do I need to open an account at CryptoWallet.com?',
    list: [
      'To comply with international regulation on AML and KYC, it’s necessary to make an account and verify your identity in order to use CryptoWallet.com',
      'This helps protect all our users and ensures the best possible service for everyone.',
    ],
  },
  {
    title: 'What are the steps required to open an account ?',
    list: [
      'Confirm your email',
      'Turn on 2-Step Verification',
      'Pass a quick KYC procedure',
      'Receive 50 SPEND tokens on your balance',
    ],
  },
];

export const WhyINeedCwAccountAlert = ({ onClose }: { onClose: () => void }) => (
  <ModalBase blurredBg={true} onClose={onClose}>
    <Modal.Content w="996px" maxW="100vw" overflowY="auto">
      <VStack p="40px" bg="background.400">
        <Modal.Header p="0" borderColor="gray.200Gray" borderBottomWidth="0">
          <HStack justifyContent="space-between" alignItems="center" pr="26px">
            <Text color="white" fontSize="3xl">
              Why do I need to open an account at CryptoWallet.com?
            </Text>
            <CloseButton
              position="absolute"
              top="4px"
              right="0"
              iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }}
              onPress={onClose}
            />
          </HStack>
        </Modal.Header>
        <Modal.Body p="0">
          {data.map(({ title, list }, index) => (
            <Box key={index}>
              <Text mt="20px" mb="19px" color="white" fontSize="20px">
                {title}
              </Text>
              <VStack>
                {list.map((text, subIndex) => (
                  <VStack key={subIndex} mb="6px" pl="18px">
                    <Icon position="absolute" top="4px" left="0" h="10px" w="12px" name="check-icon-small" />
                    <Text color="textRegular" fontSize="lg">
                      {text}
                    </Text>
                  </VStack>
                ))}
              </VStack>
            </Box>
          ))}
        </Modal.Body>
      </VStack>
    </Modal.Content>
  </ModalBase>
);
