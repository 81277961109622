import { Box } from 'native-base';
import React from 'react';

import { useScreenSize, useWalletConfig } from '@cryptowallet/frontend/hooks';
import { Spinner, useUserSettings } from '@cryptowallet/frontend/ui';
import { WalletTransactionEntityDtoTypeEnum } from '@cryptowallet/web/api-client';
import { IMasterDetailsComponentProps, TableType } from '@cryptowallet/web/components/Table';

import DepositDetailsContent from './DepositDetailsContent';
import WithdrawDetailsContent from './WithdrawDetailsContent';

const content = {
  [WalletTransactionEntityDtoTypeEnum.Withdrawal]: {
    Component: WithdrawDetailsContent,
  },
  [WalletTransactionEntityDtoTypeEnum.Deposit]: {
    Component: DepositDetailsContent,
  },
};

const AssetTransactionHistoryDetails = ({ tableType, data }: IMasterDetailsComponentProps) => {
  const { hideBalance } = useUserSettings();
  const { isBiggerThanTablet } = useScreenSize();
  const { fiatCurrency, isLoading: isWalletConfigLoading } = useWalletConfig();
  const {
    walletTransaction: { type },
  } = data;

  const { Component } = content[type];

  const isReady = !isWalletConfigLoading;

  const isWithdraw = WalletTransactionEntityDtoTypeEnum.Withdrawal === type;

  return (
    <Box py="24px" px={tableType === TableType.SMALL && !isBiggerThanTablet ? '24px' : '0'}>
      {isReady && <Component tableType={tableType} data={data} fiatCurrency={fiatCurrency} hideBalance={hideBalance} />}
      {!isReady && <Spinner h={isWithdraw ? '272px' : '204px'} />}
    </Box>
  );
};

export default AssetTransactionHistoryDetails;
