import { HStack, Text, VStack } from 'native-base';
import React from 'react';

import { Icon, usePartnerStyles } from '@cryptowallet/frontend/ui';

const PspMethodItem = ({ bg, icon, active, estimateValue, description, isHovered, isFocused }) => {
  const partnerStyles = usePartnerStyles('PspMethodItem');

  return (
    <HStack
      h="62px"
      px="14px"
      bg={bg}
      alignItems="center"
      {...partnerStyles}
      {...(partnerStyles.borderColor && !isFocused
        ? {
            borderColor: active || isHovered ? partnerStyles.borderColor.active : partnerStyles.borderColor.normal,
          }
        : {})}>
      <HStack w="100%" overflow="hidden" alignItems="center">
        <Icon name={active || isFocused || isHovered ? icon.active : icon.default} w="40px" h="30px" mr="10px" />
        <VStack>
          <Text fontSize="sm" color={active || isFocused || isHovered ? 'white' : 'textRegular'}>
            {estimateValue}
          </Text>
          <Text fontSize="sm" color={active || isFocused || isHovered ? 'white' : 'textRegular'}>
            {description}
          </Text>
        </VStack>
      </HStack>
    </HStack>
  );
};

export default PspMethodItem;
