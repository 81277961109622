import { useMutation } from '@tanstack/react-query';
import { Text } from 'native-base';
import React from 'react';
import { useForm } from 'react-hook-form';

import { BottomPurpleButton, FormGuards, IFormGuardType, useAlerts, useUser } from '@cryptowallet/frontend/ui';
import { processFormGuardsApiErrors } from '@cryptowallet/frontend/utils';
import { userApi } from '@cryptowallet/web/api-client';

import StepLayout from './SetupTotp/Layout';

interface IFormProps {
  totpToken: string;
  emailOtpToken: string;
}

interface IToggleTotpProps {
  onStepCompleted: () => void;
}

const ToggleTotp = ({ onStepCompleted }: IToggleTotpProps) => {
  const { user } = useUser();
  const { showErrorToaster } = useAlerts();

  const { control, handleSubmit, setError } = useForm<IFormProps>({
    defaultValues: {
      totpToken: '',
      emailOtpToken: '',
    },
  });

  const toggleTotpMutation = useMutation(({ totpToken, emailOtpToken }: IFormProps) =>
    userApi.usersControllerToggleTotp2Fa({
      totpToken,
      emailOtpToken,
    }),
  );

  const verify = async (formData: IFormProps) => {
    try {
      await toggleTotpMutation.mutateAsync(formData);

      onStepCompleted();
      return;
    } catch (error) {
      processFormGuardsApiErrors(error, setError, showErrorToaster);
    }
  };

  const { totpEnabled } = user;

  return (
    <StepLayout
      body={
        <>
          {!totpEnabled && (
            <Text mb="20px" color="white" fontSize="2xl">
              Enable Authenticator
            </Text>
          )}
          <FormGuards
            activeGuards={[{ type: IFormGuardType.OTP }, { type: IFormGuardType.TOTP }]}
            control={control}
            onSubmitInput={handleSubmit(verify)}
          />
        </>
      }
      stepButton={
        <BottomPurpleButton isDisabled={toggleTotpMutation.isLoading} size="lg" onPress={handleSubmit(verify)}>
          {totpEnabled && 'Confirm'}
          {!totpEnabled && 'Submit'}
        </BottomPurpleButton>
      }
    />
  );
};

export default ToggleTotp;
