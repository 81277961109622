import { useEffect, useState } from 'react';

export interface CountdownRendererProps {
  timer: string;
  isCompleted: boolean;
  expiresIn: number;
}

const calculateExpireTime = expireTime => Math.max(Math.floor((expireTime - Date.now()) / 1000), 0);
const SecondsCountdown = ({
  expireTime,
  renderer,
}: {
  expireTime: number;
  renderer?: (renderArgs: CountdownRendererProps) => JSX.Element;
}): JSX.Element => {
  const [expiresIn, setExpiresIn] = useState<number>(calculateExpireTime(expireTime));

  useEffect(() => {
    setExpiresIn(calculateExpireTime(expireTime));
    const timeoutId = setInterval(() => {
      setExpiresIn(prevVal => {
        const newVal = prevVal - 1;
        if (newVal <= 0) {
          clearTimeout(timeoutId);
          return 0;
        }

        return newVal;
      });
    }, 1000);

    return () => clearInterval(timeoutId);
  }, [expireTime]);

  const seconds = expiresIn < 10 ? `0${expiresIn}` : expiresIn;

  return renderer({ timer: `00:${seconds}`, isCompleted: expiresIn <= 0, expiresIn });
};

export default SecondsCountdown;
