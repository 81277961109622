import { Box, Text, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { useWalletSummary } from '@cryptowallet/frontend/hooks';
import { DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { ActionGuard, ButtonPurple, Icon, OverlaySpinner } from '@cryptowallet/frontend/ui';

const DepositPromo = ({ spinnerProps = {} }) => {
  const { selectedWalletAccountId, selectedWalletAssetId, setDetailsPanelView } = useAccountsStore(
    state => ({
      selectedWalletAccountId: state.selectedWalletAccountId,
      selectedWalletAssetId: state.selectedWalletAssetId,
      setDetailsPanelView: state.setDetailsPanelView,
    }),
    shallow,
  );
  const { isLoading, existingWalletResources } = useWalletSummary();

  const firstWalletResource = existingWalletResources?.length ? existingWalletResources[0] : null;

  const onDepositPress = () => {
    if (firstWalletResource) {
      setDetailsPanelView(
        DetailsPanelView.Deposit,
        selectedWalletAssetId ? undefined : selectedWalletAccountId || firstWalletResource?.walletAccount?.id,
      );
    }
  };

  return (
    <Box
      px="24px"
      py={{ base: '24px', md: 'unset' }}
      w="100%"
      h="100%"
      flexGrow="1"
      alignItems="center"
      justifyContent="center">
      {isLoading && <OverlaySpinner {...spinnerProps} />}
      {!isLoading && (
        <VStack alignItems="center">
          <Icon name="wallet/sending-to-payout" w="50px" h="80px" mb="32px" />
          <Text textAlign="center" fontSize="2xl" color="white" mb="16px">
            Your recent activity is empty... for now
          </Text>
          <Text textAlign="center" fontSize="lg" color="textRegular" mb="32px">
            Top up your account to get started with the CryptoWallet experience!
          </Text>
          <ActionGuard>
            {({ openAlert }) => (
              <ButtonPurple size="lg" py="16px" px="32px" onPress={openAlert || onDepositPress}>
                Deposit
              </ButtonPurple>
            )}
          </ActionGuard>
        </VStack>
      )}
    </Box>
  );
};

export default DepositPromo;
