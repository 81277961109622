import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types';

import { Button } from '@cryptowallet/frontend/ui';

export const TextButton = ({ children, ...props }: IButtonProps) => (
  <Button
    py="6px"
    px="13px"
    _text={{
      color: 'primary.400',
      fontSize: 'sm',
    }}
    borderRadius="100px"
    variant="unstyled"
    outlineWidth="0"
    borderWidth="1px"
    borderColor="transparent"
    _hover={{
      bg: 'gray.200',
    }}
    _focus={{
      borderColor: 'secondary.400',
    }}
    size="xs"
    {...props}>
    {children}
  </Button>
);
