import { useQuery } from '@tanstack/react-query';
import { createContext, ReactNode, useContext } from 'react';

import { settingsApi } from '@cryptowallet/frontend/api';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';

import OverlaySpinner from '../../components/OverlaySpinner';

interface IConfigProvider {
  exchangerDisabled: boolean;
  sellFlowDisabled: boolean;
  swapDisabled: boolean;
  systemIsUnderMaintenance: boolean;
}

const SettingContext = createContext<IConfigProvider | null>(null);

interface IConfigProviderProps {
  children: ReactNode;
}

const settingsPlaceholder = {
  exchangerDisabled: true,
  sellFlowDisabled: true,
  swapDisabled: true,
  systemIsUnderMaintenance: true,
};

export const AppConfigProvider = ({ children }: IConfigProviderProps) => {
  const { data: response, isLoading } = useQuery(
    ['configSettings'],
    () => settingsApi.settingsControllerGetSettings(),
    {
      ...cacheRequestOption,
      enabled: true,
    },
  );

  return (
    <SettingContext.Provider value={response?.data || settingsPlaceholder}>
      {isLoading ? <OverlaySpinner /> : children}
    </SettingContext.Provider>
  );
};

export const useConfig = () => useContext(SettingContext);
