/* eslint-disable @typescript-eslint/no-shadow */
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { walletsApi } from '@cryptowallet/frontend/api';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';

import useDebounce from './useDebounce';
import useUserStatus from './useUserStatus';

const useRecentActivityHistory = (walletAccountId?: string, subtypes = [], outputCount = 5) => {
  const debouncedSubtypes = useDebounce(subtypes, 1000);
  const { kycPoaHighRiskVerified } = useUserStatus();

  const {
    data: response,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['walletHistory', walletAccountId, debouncedSubtypes],
    ({ pageParam }) =>
      walletsApi.walletsControllerGetWalletHistory(
        outputCount,
        pageParam?.offset || 0,
        walletAccountId,
        debouncedSubtypes.length ? debouncedSubtypes.map(item => item.toUpperCase()) : undefined,
      ),
    {
      getNextPageParam: lastPage => {
        if (!lastPage) {
          return { offset: 0 };
        }
        const { outputCount, outputOffset, outputTotalCount } = lastPage.data.walletHistory as never;
        if (outputCount + outputOffset >= outputTotalCount) {
          return undefined;
        }

        return { offset: outputCount + outputOffset };
      },
      ...cacheRequestOption,
      enabled: kycPoaHighRiskVerified,
    },
  );

  const transactions = useMemo(() => {
    if (response) {
      const pages = response.pages.reduce((prev, page) => [...prev, page.data], []); // group all responses data
      return pages
        .reduce((prev, page) => [...prev, ...[...(page?.walletHistory?.walletOperations || [])]], [])
        .map(trx => {
          // group all transactions and reverse so they sorted from newest to oldests
          // eslint-disable-next-line no-param-reassign
          trx.id = trx.walletTransaction.id;

          return trx;
        });
    }

    return [];
  }, [response]);

  return {
    transactions,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};

export default useRecentActivityHistory;
