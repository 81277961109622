import { Navigate } from 'react-router-dom';

import { useConfig } from '@cryptowallet/frontend/ui';

const ExchangerRoute = ({ children }) => {
  const { exchangerDisabled } = useConfig();
  if (exchangerDisabled) {
    return <Navigate to="/wallet/widgets" replace />;
  }

  return children;
};

export default ExchangerRoute;
