import { Flex, Text } from 'native-base';
import { useNavigate } from 'react-router-dom';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { ButtonPurple, Spinner, Switch, useAuth } from '@cryptowallet/frontend/ui';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';

interface IMethodsListProps {
  onTotpEnableClick: () => void;
  onTotpDisableClick: () => void;
}

const MethodsList = ({ onTotpEnableClick, onTotpDisableClick }: IMethodsListProps) => {
  const {
    user: { user },
    isFetching,
  } = useAuth();
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();

  return (
    <>
      <TabHeader onGoBack={isDesktop ? null : () => navigate('/account')}>2FA Methods</TabHeader>
      <TabContentLayout>
        {!user.totpEnabled && (
          <Text fontSize="lg">
            The security level of your account is low. Please enable 2-Factor Authentication to make your account more
            secure.
          </Text>
        )}
        <Flex
          mt="32px"
          py="20px"
          px="24px"
          borderRadius="10px"
          borderWidth="1px"
          borderColor="gray.100"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <Text color="white" fontSize="lg">
            Google authenticator / Authy
          </Text>
          {isFetching && <Spinner />}
          {!isFetching && (
            <>
              {user.totpEnabled && <Switch onToggle={onTotpDisableClick} isChecked={true} />}
              {!user.totpEnabled && (
                <ButtonPurple _text={{ fontSize: 'md' }} py="5px" px="12px" size="md" onPress={onTotpEnableClick}>
                  Enable
                </ButtonPurple>
              )}
            </>
          )}
        </Flex>
      </TabContentLayout>
    </>
  );
};

export default MethodsList;
