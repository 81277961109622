import { IPressableProps, StyledProps, Text } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Pressable } from '@cryptowallet/frontend/ui';

export interface ILinkBaseProps extends IPressableProps {
  href?: string;
  text: string;
  isExternal?: boolean;
  _text?: StyledProps;
  textColor: {
    default: string;
    hoverFocus: string;
  };
}

const LinkBase = ({ text, href, isExternal, _text = {}, onPress, textColor, ...props }: ILinkBaseProps) => {
  // used pressable as native-base link doesn't support focus styling
  const navigate = useNavigate();

  return (
    <Pressable
      outlineWidth="0"
      textDecorationColor={textColor.default}
      textDecoration="underline"
      color={textColor.default}
      _hover={{ textDecorationColor: textColor.hoverFocus }}
      _focus={{ textDecorationColor: textColor.hoverFocus }}
      onPress={event => {
        if (href) {
          if (isExternal) {
            window.open(href, '_blank');
          } else {
            navigate(href);
          }
        } else if (onPress) {
          onPress(event);
        }
      }}
      {...props}>
      {({ isFocused, isHovered }) => (
        <Text color={isFocused || isHovered ? textColor.hoverFocus : textColor.default} fontSize="sm" {..._text}>
          {text}
        </Text>
      )}
    </Pressable>
  );
};

export default LinkBase;
