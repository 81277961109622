import dayjs from 'dayjs';
import { Box, Text } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { CDate } from '@cryptowallet/web/components/Table/CellRenderers';
import { ColumnType } from '@cryptowallet/web/components/Table/Row';

export interface IDateColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: ColumnType.DATE;
}

interface IDateProps {
  data: any;
  columnDef: IDateColumnDef;
}

const ReferralCustomCDate = ({ data, columnDef }: IDateProps) => {
  const { isMobile } = useScreenSize();

  const value = data ? dayjs(data).format('DD.MM.YYYY') : 'Pending';

  return (
    <Box py={{ base: '8px', md: '0' }}>
      {isMobile && (
        <Text mb="8px" fontSize="lg" lineHeight="16px" color="textLabel">
          {columnDef.headerName}
        </Text>
      )}
      <Text fontSize="lg" fontWeight="500" color="textRegular">
        {value}
      </Text>
    </Box>
  );
};

export default ReferralCustomCDate;
