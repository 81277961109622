import { HStack, Image, Text, useToken } from 'native-base';
import React, { CSSProperties, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { CurrencyNetwork, Pressable } from '@cryptowallet/frontend/ui';
import { getNetworkData } from '@cryptowallet/frontend/utils';
import { AssetEntityDtoTypeEnum, WalletResourceEntityDto } from '@cryptowallet/web/api-client';
import ClosedEyeIcon from '@cryptowallet/web/icons/ClosedEyeIcon';
import EyeIcon from '@cryptowallet/web/icons/EyeIcon';

interface IDraggableAssetItemProps {
  provided: DraggableProvided;
  style: CSSProperties;
  item: WalletResourceEntityDto & { id: string };
  isDragging: boolean;
  index: number;
  hideWalletAsset: (id: string) => void;
  hidden: boolean;
}

const DraggableAssetItem = ({
  provided,
  style = {},
  item,
  isDragging,
  hideWalletAsset,
  hidden,
}: IDraggableAssetItemProps) => {
  // web version
  const [hovered, setHovered] = useState(false);
  const [secondary400, textLabel] = useToken('colors', ['secondary.400', 'textLabel']);

  const { id, currency, image, network, type } = item.asset;

  const { color: networkColor } = getNetworkData(network?.ticker);
  const isCryptoCoin = type === AssetEntityDtoTypeEnum.CryptoCoin;

  return (
    <div
      // uncomment when we come back to dnd
      // ref={provided.innerRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      // {...provided.draggableProps}
      // style={{
      //   ...provided.draggableProps.style,
      //   ...style,
      // }}
      style={{ height: '56px' }}>
      <HStack
        position="relative"
        w="100%"
        h="100%"
        p="10px"
        alignItems="center"
        bg={hovered ? 'gray.300' : 'background.400'}
        borderRadius="10px"
        borderWidth="1px"
        borderColor={isDragging ? 'gray.200' : 'transparent'}
        borderStyle="dashed">
        {/* <div {...provided.dragHandleProps} tabIndex={-1} style={{ display: 'flex' }}>
          <DragHandleIcon color={isDragging ? white : textDisabled} />
        </div> */}
        <Image
          source={{
            uri: image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
          }}
          alt={currency.label}
          h="36px"
          w="36px"
          mr="16px"
          borderRadius="36px"
        />
        <HStack
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent={{ base: 'unset', md: 'space-between' }}
          flexGrow={{ base: 'unset', md: 1 }}
          pr={{ base: 'unset', md: '34px' }}>
          <Text fontSize="lg" color={isDragging ? 'white' : 'textLabel'}>
            {currency.label}
          </Text>
          {!!network?.label && !isCryptoCoin && <CurrencyNetwork color={networkColor} label={network?.label} />}
        </HStack>
        <Pressable
          onPress={() => hideWalletAsset(id)}
          position="absolute"
          right="9px"
          top="18px"
          outlineWidth="0"
          w="16px"
          h="16px">
          {({ isFocused }) => (
            <>
              {hidden && <ClosedEyeIcon color={isFocused ? secondary400 : textLabel} />}
              {!hidden && <EyeIcon color={isFocused ? secondary400 : textLabel} />}
            </>
          )}
        </Pressable>
      </HStack>
    </div>
  );
};

export default DraggableAssetItem;
