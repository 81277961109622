import { ExchangerErrors } from './exchanger-errors';
import { UsersErrors } from './users-errors';
import { WalletErrors } from './wallet-errors';
import { WhitelistErrors } from './whitelist-errors';
import { GuardsErrors } from './guards-errors';

export const ClientErrors = {
  ...ExchangerErrors,
  ...UsersErrors,
  ...WhitelistErrors,
  ...WalletErrors,
  ...GuardsErrors,
};
