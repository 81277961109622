import { Divider, Flex, Input } from 'native-base';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
} from 'react-native';

import { KEY_MAP } from '@cryptowallet/frontend/constants';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { usePartnerStyles } from '@cryptowallet/frontend/ui';

interface SingleInputProps {
  last: boolean;
  onFocus: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onBlur: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onChange: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  onKeyPress: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  anyInputFocused: boolean;
  value: string;
  focus: boolean;
  disabled: boolean;
  isMobile: boolean;
  autoFocus: boolean;
}

const SingleInput: React.FC<SingleInputProps> = ({
  last,
  onFocus,
  onBlur,
  onChange,
  onKeyPress,
  anyInputFocused,
  value,
  focus,
  disabled,
  isMobile,
  autoFocus,
}) => {
  const dividerPartnerStyles = usePartnerStyles('SingleInputDivider');

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus && ref && ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }, [focus, ref]);

  return (
    <Flex alignItems="center" direction="row" h="72px">
      <Input
        ref={ref}
        h="full"
        w={isMobile ? 54 : 62}
        borderColor="transparent"
        borderWidth="0"
        borderRadius="10px"
        fontSize="2xl"
        textAlign="center"
        backgroundColor="transparent"
        autoComplete="off"
        type="number"
        keyboardType="numeric"
        autoFocus={autoFocus}
        isDisabled={disabled}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      {!last && <Divider orientation="vertical" bg="gray.100" h="2/5" {...dividerPartnerStyles} />}
    </Flex>
  );
};

interface OtpInputProps {
  value: string;
  onChange: (value: string) => void;
  inputsCount?: number;
  disabled?: boolean;
  invalid: boolean;
  autoFocus?: boolean;
}

const OtpInput: React.FC<OtpInputProps> = ({
  value,
  onChange,
  inputsCount = 6,
  disabled,
  invalid = false,
  autoFocus = false,
}) => {
  const partnerStyles = usePartnerStyles('OtpInput');
  const { isMobile } = useScreenSize();
  const [activeInput, setActiveInput] = useState<number>(-1);
  const wrapperRef = useRef<HTMLElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const otpValue = useMemo<string[]>(
    () => (value ? value.toString().split('') : new Array(inputsCount).fill('')),
    [value, inputsCount],
  );

  const anyInputFocused = activeInput !== -1;

  const focusInput = index => {
    const newActiveInput = Math.max(Math.min(inputsCount - 1, index), 0);

    setActiveInput(newActiveInput);
  };

  const handleInputFocus = (e, index) => {
    if (anyInputFocused) {
      e.target.select();
      setActiveInput(index);
    } else {
      focusInput(value.length);
    }
  };

  const handleInputBlur = e => {
    if (e.relatedTarget && wrapperRef && wrapperRef.current && wrapperRef.current.contains(e.relatedTarget)) return;
    setActiveInput(-1);
  };

  const changeOtpValue = (newValue, isPaste?) => {
    const newOtpValue = isPaste ? newValue.split('') : [...otpValue];
    if (!isPaste) {
      newOtpValue[activeInput] = newValue;
    }
    const result = newOtpValue.join('').slice(0, inputsCount);
    onChange(result);

    return result;
  };

  const handleChangeSingleInput = e => {
    if (disabled) return;

    const inputValue = e.target.value;
    const valid = !Number.isNaN(parseInt(inputValue, 10));
    if (valid) {
      const isPaste = inputValue.length > 2;
      if (!isPaste) {
        changeOtpValue(inputValue);
        focusInput(activeInput + 1);
      } else {
        const result = changeOtpValue(inputValue, isPaste);
        focusInput(result.length);
      }
    }
  };

  const handleKeyPressSingleInput = e => {
    const { keyCode } = e;

    if (KEY_MAP[keyCode]) {
      e.preventDefault();
    }

    switch (keyCode) {
      case KEY_MAP.BACKSPACE: {
        changeOtpValue('');
        focusInput(activeInput - 1);
        break;
      }
      case KEY_MAP.DELETE: {
        changeOtpValue('');
        break;
      }
      case KEY_MAP.LEFT_ARROW: {
        focusInput(activeInput - 1);
        break;
      }
      case KEY_MAP.RIGHT_ARROW: {
        focusInput(activeInput + 1);
        break;
      }
    }
  };

  const onMouseOver = () => setIsHovered(true);

  const onMouseOut = () => setIsHovered(false);

  useEffect(() => {
    wrapperRef?.current?.addEventListener('mouseover', onMouseOver);
    wrapperRef?.current?.addEventListener('mouseout', onMouseOut);

    return () => {
      wrapperRef?.current?.removeEventListener('mouseover', onMouseOver);
      wrapperRef?.current?.removeEventListener('mouseout', onMouseOut);
    };
  }, [wrapperRef?.current]);

  let borderColor = anyInputFocused ? 'secondary.400' : 'transparent';

  if (partnerStyles?.borderColorStateColors) {
    borderColor = anyInputFocused
      ? partnerStyles.borderColorStateColors.active
      : partnerStyles.borderColorStateColors.normal;
  }

  return (
    <Flex
      ref={wrapperRef}
      direction="row"
      backgroundColor={isHovered ? 'gray.300' : 'gray.200'}
      borderWidth="1px"
      borderColor={invalid ? 'error.400' : borderColor}
      rounded="10px"
      {...partnerStyles}>
      {new Array(inputsCount).fill('').map((_, i) => (
        <SingleInput
          key={i}
          last={i === inputsCount - 1}
          onFocus={e => handleInputFocus(e, i)}
          onBlur={handleInputBlur}
          onChange={handleChangeSingleInput}
          onKeyPress={handleKeyPressSingleInput}
          anyInputFocused={anyInputFocused}
          value={(otpValue && otpValue[i]) || ''}
          focus={activeInput === i}
          disabled={disabled}
          isMobile={isMobile}
          autoFocus={autoFocus}
        />
      ))}
    </Flex>
  );
};

export default OtpInput;
