import { Box, HStack, Modal, Text, VStack } from 'native-base';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPurple } from '../Buttons/ButtonPurple';
import CloseButton from '../Buttons/CloseButton';
import ModalBase from '../ModalBase';

const items = [
  `First, you’ll need a government-issued ID like a passport or driver's license`,
  `Then, take a high-resolution photo of the necessary documents with all information readable`,
  `When prompted, allow CryptoWallet.com camera access on your device and take a photo of your face`,
  `You may need to provide proof of address, like a utility bill`,
  `That’s it! If we need anything more, we’ll ask, otherwise you’ll hear from us directly about passing your KYC!`,
];

export const WhyNeedKycAlert = ({ onClose }: { onClose: () => void }) => {
  const ref = useRef();
  const navigate = useNavigate();

  return (
    <ModalBase blurredBg={true} initialFocusRef={ref} onClose={onClose}>
      <Modal.Content overflowY="auto" borderRadius="10px" w="384px" maxW="100vw" border="none">
        <VStack bg="background.400">
          <Modal.Header borderColor="transparent" p="24px">
            <HStack justifyContent="space-between" alignItems="center">
              <HStack alignItems="center">
                <Text color="white" fontSize="2xl">
                  How to Pass KYC
                </Text>
              </HStack>
              <CloseButton iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }} onPress={onClose} />
            </HStack>
          </Modal.Header>
          <Modal.Body p="24px" pt="10px">
            <Box>
              <Text fontSize="lg" color="textRegular" mb="22px">
                KYC helps us comply with international regulations and keep our customers safe. Passing KYC is easy!
              </Text>
              {items.map((item, index) => (
                <HStack pl="6px" key={index}>
                  <Text mr="6px" fontSize="lg" color="textRegular">
                    {index + 1}.
                  </Text>
                  <Text fontSize="lg" color="textRegular">
                    {item}
                  </Text>
                </HStack>
              ))}
            </Box>
            <ButtonPurple
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={ref}
              onPress={() => {
                onClose();
                navigate('/account/security/verification');
              }}
              mt="20px"
              h="34px"
              _text={{ fontSize: 'md', color: 'white' }}>
              Start KYC
            </ButtonPurple>
          </Modal.Body>
        </VStack>
      </Modal.Content>
    </ModalBase>
  );
};
