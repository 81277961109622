import { Box, FlatList, IBoxProps, Pressable, ScrollView, Text } from 'native-base';
import React, { forwardRef, Ref, useEffect, useRef, useState } from 'react';

import { KEY_MAP } from '@cryptowallet/frontend/constants';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { getPlatform } from '@cryptowallet/frontend/utils';

import { Icon, Input, usePartnerStyles } from '../../../index';

const isWeb = getPlatform() === 'web';

export interface ISelectOption {
  label: string;
  id: string;
}

interface IDropdownItemProps {
  data: ISelectOption;
  onPress: () => void;
  focused: boolean;
  selected: boolean;
}

const dropdownItemHoverFocusStyles = {
  bg: 'gray.300',
};

const DropdownItem = forwardRef(
  ({ data: { label }, onPress, focused, selected }: IDropdownItemProps, ref: Ref<HTMLElement>) => {
    const partnerStyles = usePartnerStyles('RegularDropdownItem');
    return (
      <Pressable
        ref={ref}
        h="56px"
        justifyContent="center"
        px={isWeb ? '16px' : '24px'}
        borderRadius="10px"
        outlineWidth="0"
        _hover={dropdownItemHoverFocusStyles}
        _focus={dropdownItemHoverFocusStyles}
        {...(focused ? dropdownItemHoverFocusStyles : {})}
        focusable={false}
        onPress={onPress}
        {...partnerStyles}>
        <Text fontSize="lg">{label}</Text>
        {selected && isWeb && <Icon name="check" position="absolute" right="13px" h="8px" w="12px" />}
      </Pressable>
    );
  },
);

interface IDropdownProps {
  options: ISelectOption[];
  onSelect: (id: string) => void;
  containerProps?: IBoxProps;
  value?: ISelectOption | null;
  onClose: () => void;
  filterableColumns?: string[];
}

const Dropdown = ({
  options,
  onSelect,
  value,
  onClose,
  containerProps = {},
  filterableColumns = ['label'],
}: IDropdownProps) => {
  const itemsRef = useRef([]);
  const ref = useRef();
  const [focusItemIndex, setFocusItemIndex] = useState(-1);
  const [filterValue, setFilterValue] = useState('');
  const partnerStyles = usePartnerStyles('RegularDropdown');
  const { isMobile } = useScreenSize();

  const filteredOptions = filterValue
    ? options.filter(option =>
        filterableColumns.some(column => option[column].toLowerCase().includes(filterValue.toLowerCase())),
      )
    : options;

  const handleKeyDown = e => {
    switch (e.keyCode) {
      case KEY_MAP.ENTER: {
        e.preventDefault();
        const option = filteredOptions[focusItemIndex];
        if (option) {
          onSelect(option.id);
          onClose();
        }
        break;
      }
      case KEY_MAP.TAB: {
        onClose();
        break;
      }
      case KEY_MAP.ESCAPE: {
        e.preventDefault();
        onClose();
        break;
      }
      case KEY_MAP.ARROW_DOWN: {
        e.preventDefault();
        setFocusItemIndex(prev => {
          const newIndex = prev + 1 > filteredOptions.length - 1 ? 0 : prev + 1;
          const itemRef = itemsRef.current[newIndex];
          if (itemRef?.scrollIntoViewIfNeeded) {
            itemRef.scrollIntoViewIfNeeded();
          }

          return newIndex;
        });
        break;
      }
      case KEY_MAP.ARROW_UP: {
        e.preventDefault();
        setFocusItemIndex(prev => {
          const newIndex = prev - 1 < 0 ? filteredOptions.length - 1 : prev - 1;
          const itemRef = itemsRef.current[newIndex];
          if (itemRef?.scrollIntoViewIfNeeded) {
            itemRef.scrollIntoViewIfNeeded();
          }

          return newIndex;
        });
        break;
      }
    }
  };

  const handleClickOutsideList = e => {
    if (!isMobile && ref && !ref.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isWeb) {
      document.addEventListener('click', handleClickOutsideList);
    }
    return () => {
      if (isWeb) {
        document.removeEventListener('click', handleClickOutsideList);
      }
    };
  }, []);

  return (
    <Box
      ref={ref}
      position="absolute"
      bg={isWeb ? 'black' : 'background.600'}
      w="100%"
      h={isWeb ? undefined : '60%'}
      top={isWeb ? { base: '0', md: '58px' } : undefined}
      bottom={isWeb ? undefined : '0'}
      pt={{ base: '106px', md: '95px' }}
      pb="10px"
      px={isWeb ? '26px' : undefined}
      zIndex="1"
      borderBottomLeftRadius="10px"
      borderBottomRightRadius="10px"
      borderWidth="1px"
      borderColor="gray.100"
      {...(isWeb
        ? {
            borderRadius: '10px',
          }
        : {})}
      {...partnerStyles}>
      <Box position="absolute" top={{ base: '35px', md: '27px' }} left="0" w="100%" px={isWeb ? '26px' : '24px'}>
        <Input
          h="56px"
          onChangeText={v => setFilterValue(v)}
          value={filterValue}
          placeholder="Search"
          onKeyPress={handleKeyDown}
          _invalid={{
            // need to ignore parent invalid state
            borderColor: 'transparent',
          }}
          size="xl"
          autoFocus={isWeb}
        />
      </Box>
      {isWeb && (
        <Box overflowY="auto" {...containerProps}>
          {filteredOptions.map((item, index) => (
            <DropdownItem
              key={item.id}
              ref={el => {
                itemsRef.current[index] = el;
              }}
              data={item}
              onPress={() => onSelect(item.id)}
              focused={focusItemIndex === index}
              selected={value?.id === item.id}
            />
          ))}
        </Box>
      )}
      {!isWeb && (
        <FlatList
          data={filteredOptions}
          renderItem={({ item, index }) => (
            <DropdownItem
              key={item.id}
              data={item}
              onPress={() => onSelect(item.id)}
              focused={focusItemIndex === index}
              selected={value?.id === item.id}
            />
          )}
          keyExtractor={item => item.id.toString()}
        />
      )}
    </Box>
  );
};

export default Dropdown;
