import { useUser } from '../ui/src';
import { UserPermissionCaseEntityDto, RiskCheckType } from '../client/src';

export enum RiskCheckStatus {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  RETRY = 'RETRY',
  REJECTED = 'REJECTED',
  RESOLVED = 'RESOLVED',
}

export type RiskCheckMap = {
  [key in RiskCheckType]?: {
    status: RiskCheckStatus;
    message: string | null;
  };
};

export type UserStatus = {
  isLoggedIn: boolean;
  kycPoaHighRiskVerified: boolean;
  awaitingKycReward: boolean;
  hasAcceptedNewTerms: boolean;
  userIsReferrer: boolean;
  riskChecks: RiskCheckMap;
} & UserPermissionCaseEntityDto;

const defaultStatus: UserStatus = {
  isLoggedIn: false,
  VERIFIED_KYC: false,
  VERIFIED_POA: false,
  VERIFIED_POA_FOR_HIGH_RISK: false,
  kycPoaHighRiskVerified: false,
  userIsReferrer: false,
  awaitingKycReward: false,
  hasAcceptedNewTerms: false,
  riskChecks: {},
};

const useUserStatus = (): UserStatus => {
  const user = useUser();

  const status = { ...defaultStatus, ...user?.permissions?.permissionCases };
  status.isLoggedIn = !!user;
  status.kycPoaHighRiskVerified = status.VERIFIED_KYC && status.VERIFIED_POA_FOR_HIGH_RISK;
  status.awaitingKycReward = user?.permissions?.awaitingKycReward;
  status.hasAcceptedNewTerms = user?.permissions?.hasAcceptedNewTerms;
  status.riskChecks = (user?.riskChecks as never) || {};
  status.userIsReferrer = user?.referralCode?.length ? true : false;

  return status;
};

export default useUserStatus;
