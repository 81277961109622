import React, { useState } from 'react';

import { DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import AssetWithdrawAmount from './AssetWithdrawAmount';
import AssetWithdrawConfirm from './AssetWithdrawConfirm';
import AssetWithdrawResult from './AssetWithdrawResult';

const steps: IStep[] = [
  {
    label: 'Amount',
    Component: AssetWithdrawAmount,
  },
  {
    label: 'Confirmation',
    Component: AssetWithdrawConfirm,
  },
];

const AssetWithdraw = () => {
  const [flowIsCompleted, setFlowIsCompleted] = useState(false);
  const setDetailsPanelView = useAccountsStore(state => state.setDetailsPanelView);

  const onClose = () => setDetailsPanelView(DetailsPanelView.Overview);

  return (
    <>
      {!flowIsCompleted && (
        <Stepper header="Withdraw" steps={steps} onClose={onClose} onFlowComplete={() => setFlowIsCompleted(true)} />
      )}
      {flowIsCompleted && <AssetWithdrawResult onClose={onClose} />}
    </>
  );
};

export default AssetWithdraw;
