import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { referralProgramApi } from '@cryptowallet/frontend/api';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import Faq from '@cryptowallet/web/components/Faq';

const ReferralRules = () => {
  const { data } = useQuery(
    ['referral-rules'],
    referralProgramApi.referralProgramControllerGetReferralProgramRules,
    cacheRequestOption,
  );

  return <Faq title="Do you have any questions?" list={data?.data.value ? JSON.parse(data?.data.value) : []} />;
};

export default ReferralRules;
