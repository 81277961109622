import { useQueryClient } from '@tanstack/react-query';
import { Flex } from 'native-base';
import { useState } from 'react';

import { ToasterType, useAlerts, useUser } from '@cryptowallet/frontend/ui';

import DisableTotp from './components/DisableTotp';
import MethodsList from './components/MethodsList';
import SetupTotp from './components/SetupTotp';

enum STATES {
  METHODS_LIST,
  SETUP_TOTP,
  DISABLE_TOTP,
}

const TwoFactorMethods = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { showToaster } = useAlerts();

  const [pageState, setPageState] = useState<STATES>(STATES.METHODS_LIST);

  const openMethodsList = () => setPageState(STATES.METHODS_LIST);

  const onTotpEnableClick = () => setPageState(STATES.SETUP_TOTP);

  return (
    <Flex flexGrow="1">
      {pageState === STATES.METHODS_LIST && (
        <MethodsList
          onTotpEnableClick={onTotpEnableClick}
          onTotpDisableClick={() => setPageState(STATES.DISABLE_TOTP)}
        />
      )}
      {pageState === STATES.SETUP_TOTP && (
        <SetupTotp
          onFlowCompleted={() => {
            queryClient.invalidateQueries(['user']);
            openMethodsList();
            showToaster(ToasterType.SUCCESS, 'Congratulations, your account is secured!');
          }}
          totpWasDisabled={user.totpWasDisabled}
          openMethodsList={openMethodsList}
        />
      )}
      {pageState === STATES.DISABLE_TOTP && (
        <DisableTotp
          onGoBack={openMethodsList}
          onFlowCompleted={() => {
            queryClient.invalidateQueries(['user']);
          }}
          onTotpEnableClick={onTotpEnableClick}
        />
      )}
    </Flex>
  );
};

export default TwoFactorMethods;
