import { VStack } from 'native-base';

import TabContentLayout from '@cryptowallet/web/components/layout/TabContentLayout';

interface IStepLayoutProps {
  body: JSX.Element;
  stepButton: JSX.Element;
}

const StepLayout = ({ body, stepButton }: IStepLayoutProps) => (
  <VStack flexGrow="1">
    <TabContentLayout>{body}</TabContentLayout>

    {stepButton}
  </VStack>
);

export default StepLayout;
