import React from 'react';

const NavigationSettingsLimitsIcon = ({ color = '#7252C7' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.732233 0.732233C0 1.46447 0 2.64298 0 5V15C0 17.357 0 18.5355 0.732233 19.2678C1.46447 20 2.64298 20 5 20H15C17.357 20 18.5355 20 19.2678 19.2678C20 18.5355 20 17.357 20 15V5C20 2.64298 20 1.46447 19.2678 0.732233C18.5355 0 17.357 0 15 0H5C2.64298 0 1.46447 0 0.732233 0.732233ZM5 6.25C4.30964 6.25 3.75 5.69036 3.75 5C3.75 4.30964 4.30964 3.75 5 3.75H15C15.6904 3.75 16.25 4.30964 16.25 5C16.25 5.69036 15.6904 6.25 15 6.25H5ZM3.75 10C3.75 10.6904 4.30964 11.25 5 11.25H11.25C11.9404 11.25 12.5 10.6904 12.5 10C12.5 9.30964 11.9404 8.75 11.25 8.75H5C4.30964 8.75 3.75 9.30964 3.75 10ZM3.75 15C3.75 15.6904 4.30964 16.25 5 16.25H8.75C9.44036 16.25 10 15.6904 10 15C10 14.3096 9.44036 13.75 8.75 13.75H5C4.30964 13.75 3.75 14.3096 3.75 15Z"
      fill={color}
    />
  </svg>
);

export default NavigationSettingsLimitsIcon;
