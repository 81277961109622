import React from 'react';

const NavigationSupportIcon = ({ color }) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5979 1.69298C14.0395 0.776561 12.1837 0.312498 10.3791 0.312498C8.57417 0.312499 6.71874 0.776562 5.16031 1.69298C4.49222 2.08908 4.19925 2.82468 4.41039 3.56062C4.71514 4.59053 6.00418 5.15638 7.03527 4.56777C8.03169 3.99053 9.23081 3.70761 10.3791 3.70761C11.5274 3.70761 12.6874 3.99053 13.6839 4.56777C14.6917 5.15638 16.0198 4.6585 16.3478 3.56061C16.5589 2.82468 16.266 2.0894 15.5979 1.69298Z"
      fill={color}
    />
    <path
      d="M4.48039 8.24977C3.7692 8.24977 3.15566 8.66018 2.8792 9.24814C3.0341 7.65693 3.71532 6.17564 4.82084 5.01239C4.41204 4.75483 4.09045 4.38475 3.90726 3.95451C2.42123 5.45174 1.5447 7.41758 1.43997 9.52099C0.816333 9.78213 0.378908 10.3821 0.378908 11.0794L0.378909 13.3805C0.378909 14.3167 1.16755 15.078 2.13669 15.078L2.8233 15.078C3.06508 15.7366 3.71633 16.2097 4.4804 16.2097C5.44987 16.2097 6.23818 15.4481 6.23818 14.5122L6.23818 9.94765C6.23818 9.0114 5.44987 8.24977 4.48039 8.24977Z"
      fill={color}
    />
    <path
      d="M19.3178 9.52098C19.2131 7.4179 18.3366 5.45205 16.8506 3.95482C16.6664 4.39417 16.3515 4.76262 15.944 5.02018C17.0452 6.18213 17.7237 7.6605 17.8783 9.24813C17.6018 8.66017 16.9883 8.24976 16.2771 8.24977C15.3076 8.24977 14.5193 9.01139 14.5193 9.94732L14.5193 14.5122C14.5193 15.4484 15.3079 16.2097 16.2771 16.2097C17.0196 16.2097 17.6557 15.7626 17.913 15.1327L17.913 17.6247C17.913 17.8718 17.7049 18.0728 17.4489 18.0728L13.2168 18.0728C12.9831 17.4003 12.3268 16.9174 11.5506 16.9174L9.2069 16.9174C8.23406 16.9174 7.44912 17.6754 7.44912 18.6149C7.44912 19.5541 8.23406 20.3125 9.2069 20.3125L11.5506 20.3125C12.213 20.3125 12.7878 19.9609 13.0875 19.44L17.4489 19.44C18.4854 19.44 19.329 18.6257 19.329 17.6244L19.329 14.9336C19.9465 14.6702 20.3789 14.0732 20.3789 13.3801L20.3789 11.079C20.3789 10.3821 19.9415 9.78212 19.3178 9.52098Z"
      fill={color}
    />
  </svg>
);

export default NavigationSupportIcon;
