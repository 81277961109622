import { Flex } from 'native-base';
import { Outlet, Route, useLocation } from 'react-router-dom';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import RoundedBox from '@cryptowallet/web/components/layout/RoundedBox';

import Navigation from './components/Navigation';
import ChangeEmail from './pages/ChangeEmail';
import Deactivate from './pages/Deactivate';
import Limits from './pages/Limits';
import Security from './pages/Security';
import SecurityVerification from './pages/Security/SecurityVerification';
import SecurityVerificationPoa from './pages/Security/SecurityVerificationPoa';
import SecurityVerificationWealth from './pages/Security/SecurityVerificationWealth';
import TwoFactorMethods from './pages/TwoFactorMethods';

export const renderRoutes = () => (
  <>
    <Route path="2fa" element={<TwoFactorMethods />} />
    <Route path="change-email" element={<ChangeEmail />} />
    <Route path="deactivate" element={<Deactivate />} />
    <Route path="security" element={<Security />} />
    <Route path="security/verification" element={<SecurityVerification />} />
    <Route path="security/verification-poa" element={<SecurityVerificationPoa />} />
    <Route path="security/verification-pow" element={<SecurityVerificationWealth />} />
    <Route path="limits" element={<Limits />} />
  </>
);

export default function Settings(): JSX.Element {
  const { isDesktop } = useScreenSize();
  const location = useLocation();
  const pageId = location.pathname.split('/')[2];

  const showMobileNav = !pageId;

  return (
    <Flex
      position="relative"
      flexDirection="row"
      flexGrow="1"
      maxW="1196px"
      minH="500px"
      pl={{ base: '0', xl: '404px' }}>
      {(isDesktop || showMobileNav) && <Navigation />}
      {(isDesktop || !showMobileNav) && (
        <RoundedBox display="flex" flexGrow="1" maxW="100%">
          <Outlet />
        </RoundedBox>
      )}
    </Flex>
  );
}
