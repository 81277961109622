import { Box, Image, Spinner, StyledProps, Text, VStack } from 'native-base';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { SvgUri } from 'react-native-svg';

import { ExchangerAssetEntityDto, ExchangerAssetEntityDtoTypeEnum } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { getNetworkData, getPlatform } from '@cryptowallet/frontend/utils';

import { ChevronDownIcon, Icon, Pressable, usePartnerStyles } from '../../../index';

interface ICurrencyDropdownButtonProps {
  showNetwork: boolean;
  withIcon: boolean;
  selectedCurrency?: ExchangerAssetEntityDto;
  isOpen: boolean;
  toggleOpen: () => void;
  containerStyles?: StyledProps;
  setFocused?: Dispatch<SetStateAction<boolean>>;
  noInput?: boolean;
  bg: {
    default: string;
    active: string;
  };
  isDisabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  showBorder?: boolean;
}

interface ICurrencyDropdownButtonContentProps {
  isFocused: boolean;
  selectedCurrency: ExchangerAssetEntityDto;
  isOpen: boolean;
  withIcon: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  placeholder: string;
  noInput: boolean;
  currencyLabel?: string;
}

const isWeb = getPlatform() === 'web';

const CurrencyDropdownButtonContent = ({
  isFocused,
  selectedCurrency,
  isOpen,
  withIcon,
  isDisabled,
  placeholder,
  noInput,
  currencyLabel,
}: ICurrencyDropdownButtonContentProps) => {
  const chevronUpIconName = isFocused ? 'chevron-up--gold' : 'chevron-up';

  const isPngPic = selectedCurrency?.image?.endsWith('png');

  const isImageExist = withIcon && selectedCurrency;

  const renderIcon = useCallback(() => {
    if (!isImageExist || isWeb) return null;
    return isPngPic ? (
      <Image
        source={{
          uri: selectedCurrency?.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
        }}
        alt={selectedCurrency?.label}
        h="24px"
        w="24px"
        mr="8px"
      />
    ) : (
      <Box mr="8px">
        <SvgUri width="24px" height="24px" uri={selectedCurrency?.image} />
      </Box>
    );
  }, [isImageExist, isPngPic, selectedCurrency?.image, selectedCurrency?.label]);

  return (
    <>
      {/* Todo: Refactor this part */}
      {isImageExist && isWeb && (
        <Image
          source={{
            uri: selectedCurrency.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
          }}
          alt={selectedCurrency.label}
          h="24px"
          w="24px"
          mr="8px"
        />
      )}
      {renderIcon()}
      <Text
        position="relative"
        bottom={currencyLabel && selectedCurrency?.network ? '4px' : undefined}
        fontFamily="body"
        fontSize="lg"
        color={isWeb ? 'textRegular' : 'white'}
        fontWeight="100">
        {selectedCurrency ? selectedCurrency.ticker.toUpperCase() : placeholder}
      </Text>
      {!isDisabled && isWeb && (
        <Icon
          name={isOpen || isFocused ? chevronUpIconName : 'chevron-down'}
          w="8px"
          h="5px"
          position="absolute"
          right={isWeb ? '24px' : '16px'}
          top={isWeb ? '50%' : undefined}
        />
      )}
      {!isDisabled && !isWeb && (
        <Box position={noInput ? 'absolute' : 'static'} ml={noInput ? '0' : '18%'} right={noInput ? '24px' : '0'}>
          <ChevronDownIcon />
        </Box>
      )}
    </>
  );
};

const CurrencyDropdownButton = ({
  showNetwork,
  withIcon,
  selectedCurrency,
  isOpen,
  toggleOpen,
  containerStyles = {},
  setFocused = () => {},
  noInput = false,
  bg,
  isDisabled = false,
  isLoading = false,
  placeholder = '',
  showBorder = false,
}: ICurrencyDropdownButtonProps) => {
  const partnerStyles = usePartnerStyles('CurrencyDropdownButton');

  const { color: networkColor } = getNetworkData(selectedCurrency?.network?.ticker);
  const isCryptoCoin = selectedCurrency?.type === ExchangerAssetEntityDtoTypeEnum.CryptoCoin;

  const getCurrencyNetworkLabel = () => {
    if (isWeb) {
      return selectedCurrency?.network?.label;
    }
    return isCryptoCoin ? selectedCurrency?.label?.split(' ')[0] : selectedCurrency?.network?.label.split(' ')[0];
  };

  const getBottomPadding = () => {
    if (isWeb) {
      return showBorder ? '-2px' : '-1px';
    }
    return '6px';
  };

  return (
    <Box
      position="absolute"
      top="0px"
      right="0px"
      h="100%"
      w={noInput ? '100%' : 'auto'}
      zIndex="1"
      {...containerStyles}>
      <Pressable
        display="flex"
        flexDirection="row"
        alignItems="center"
        width={noInput ? '100%' : '170px'}
        height="100%"
        px="24px"
        pl={isWeb ? '16px' : '24px'}
        bg={noInput ? `${isOpen ? bg.active : bg.default}` : 'transparent'}
        outlineWidth="0"
        borderWidth={noInput ? '1px' : '2px'}
        borderColor={noInput && isOpen ? 'secondary.400' : 'transparent'}
        borderLeftColor={noInput ? `${isOpen ? 'secondary.400' : 'transparent'}` : 'transparent'}
        borderTopRightRadius="10px"
        borderBottomRightRadius="10px"
        borderTopLeftRadius={noInput ? '10px' : '0'}
        borderBottomLeftRadius={noInput ? '10px' : '0'}
        _hover={{
          bg: 'gray.300',
        }}
        onPress={toggleOpen}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        isDisabled={isDisabled}
        {...partnerStyles}>
        {({ isFocused }) => (
          <>
            {isWeb && !noInput && (
              <VStack
                w="1px"
                h="30px"
                bg="rgba(255, 255, 255, 0.12)" // to not do partner migration for 1 line of style
                position="absolute"
                left="-2px"
                top="13px"
              />
            )}
            {!isWeb && <VStack w="1px" bg="background.200" h="30px" position="absolute" />}
            {isLoading && <Spinner color="secondary.400" />}
            {!isLoading && (
              <>
                <CurrencyDropdownButtonContent
                  isFocused={isFocused}
                  withIcon={withIcon}
                  isOpen={isOpen}
                  selectedCurrency={selectedCurrency}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  placeholder={placeholder}
                  noInput={noInput}
                  currencyLabel={getCurrencyNetworkLabel()}
                />
                {showNetwork && selectedCurrency && selectedCurrency.network && (
                  <Text
                    position="absolute"
                    bottom={getBottomPadding()}
                    top="28px"
                    left="43px"
                    width="100%"
                    maxW={isWeb && !noInput ? '94px' : undefined}
                    textTransform="uppercase"
                    h="15px"
                    color={networkColor}
                    borderBottomRightRadius="10px"
                    paddingLeft={isWeb ? '6px' : '13px'}
                    lineHeight="16px"
                    fontSize="xs"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden">
                    {getCurrencyNetworkLabel()}
                  </Text>
                )}
              </>
            )}
          </>
        )}
      </Pressable>
    </Box>
  );
};
export default CurrencyDropdownButton;
