import { ClientError } from './client-error.interface';
import { CreateExchangeBadRequestDtoErrorEnum } from '../client/src';

export const ExchangerErrors: ClientError = {
  [CreateExchangeBadRequestDtoErrorEnum.IbanNameMismatch]: {
    title: 'Incorrect payment data!',
    description: 'Please check your payment details and try again.',
  },
  [CreateExchangeBadRequestDtoErrorEnum.LimitExceeded]: {
    title: 'You have exceeded your daily limit. Please try again in 24h.',
  },
  [CreateExchangeBadRequestDtoErrorEnum.WalletAddressIncorrect]: {
    title: 'Wallet address is incorrect',
  },
  [CreateExchangeBadRequestDtoErrorEnum.IbanNameMismatch]: {
    title: 'Your legal name does not match your IBAN holder name. Transaction cancelled.',
  },
  [CreateExchangeBadRequestDtoErrorEnum.IbanInvalid]: {
    title: 'The provided IBAN is not valid.',
  },
  [CreateExchangeBadRequestDtoErrorEnum.IbanSepaUnreachable]: {
    title: 'The provided IBAN is not SEPA compatible. Transaction cancelled.',
  },
};
