import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types';

import { Icon, Pressable, usePartnerStyles } from '@cryptowallet/frontend/ui';

export const BackButton = (props: IButtonProps) => {
  const partnerStyles = usePartnerStyles('BackButton');

  return (
    <Pressable
      h="30px"
      w="30px"
      borderRadius="50%"
      p="0"
      bg="gray.200"
      alignItems="center"
      justifyContent="center"
      borderWidth="1px"
      borderColor="transparent"
      _hover={{
        bg: 'primary.300',
      }}
      _focus={{
        borderColor: 'secondary.400',
      }}
      outlineWidth="0"
      {...props}
      {...partnerStyles}>
      {({ isHovered }) => <Icon name={isHovered ? 'back-button-icon' : 'back-button-icon--gray'} w="6px" h="12px" />}
    </Pressable>
  );
};
