import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import {
  ExchangerAssetEntityDtoTypeEnum,
  ExchangerCreateExchangeRequestDtoPspTypeEnum,
} from '@cryptowallet/frontend/client';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import { useUser } from '@cryptowallet/frontend/ui';

const sortCb = (a, b) => {
  if (a.sortOrder === undefined) return 1;
  if (b.sortOrder === undefined) return -1;

  return a.sortOrder - b.sortOrder;
};

const useAssets = () => {
  const { data: response, ...rest } = useQuery(
    ['assets'],
    () => exchangerApi.exchangerControllerGetAssets(),
    cacheRequestOption,
  );
  const user = useUser();
  const { buyFromCurrencies, buyToCurrencies, sellFromCurrencies, sellToCurrencies } = useMemo(() => {
    const assets = response?.data?.assets || [];

    const fiatAssets = [...assets].filter(item => item.type === ExchangerAssetEntityDtoTypeEnum.Fiat).sort(sortCb);
    const cryptoAssets = [...assets]
      .filter(item =>
        [ExchangerAssetEntityDtoTypeEnum.CryptoCoin, ExchangerAssetEntityDtoTypeEnum.CryptoToken].includes(
          item.type as never,
        ),
      )
      .sort(sortCb);

    const isCardPaymentsAllowed = user?.settings
      ? user.settings.allowedPaymentMethods.includes(ExchangerCreateExchangeRequestDtoPspTypeEnum.Card)
      : true;

    return {
      buyFromCurrencies: fiatAssets.filter(item => item.isBuy && (isCardPaymentsAllowed || item.ticker !== 'USD')),
      buyToCurrencies: cryptoAssets.filter(item => item.isBuy),
      sellFromCurrencies: cryptoAssets.filter(item => item.isSell),
      sellToCurrencies: fiatAssets.filter(item => item.isSell),
    };
  }, [response]);

  return {
    buyFromCurrencies,
    buyToCurrencies,
    sellFromCurrencies,
    sellToCurrencies,
    allAssets: response?.data?.assets || [],
    ...rest,
  };
};

export default useAssets;
