// Docs: https://docs.geetest.com/captcha/apirefer/api/web
import { GeetestClientValidationResponseDto } from '../services';

export const captchaClosed = 'captcha_closed';

export enum CaptchaResult {
  Closed = 'closed',
}

export const showCaptcha = (): Promise<GeetestClientValidationResponseDto | CaptchaResult> =>
  new Promise((res, rej) => {
    globalThis.initGeetest4(
      {
        captchaId: (() => {
          const geetestId = process.env.NX_GEETEST_ID;
          if (typeof geetestId !== 'string') {
            const msg = 'Misconfiguration detected. GEETEST ID is not defined.';
            console.warn(msg);
            throw new Error(msg);
          }
          return geetestId;
        })(),
        product: 'bind',
        hideSuccess: true,
      },
      captchaObj => {
        captchaObj
          .onReady(() => captchaObj.showBox())
          .onSuccess(() => res(captchaObj.getValidate()))
          .onError(rej)
          .onClose(() => res(CaptchaResult.Closed));
      },
    );
  });
