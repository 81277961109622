import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

const isWeb = getPlatform() === 'web';

const storage = isWeb ? localStorage : AsyncStorage;

/**
 * Same as "useState" but saves the value to local storage each time it changes
 */
const usePersistState = (initialValue: any, key: string) => {
  const [value, setValue] = useState(() => {
    try {
      const valueFromStorage = storage.getItem(key);

      return valueFromStorage ? JSON.parse(valueFromStorage) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default usePersistState;
