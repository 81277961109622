import { useEffect } from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

const isWeb = getPlatform() === 'web';

const useBlockWindowScroll = (enabled: boolean) => {
  useEffect(() => {
    if (!isWeb) return;

    setTimeout(() => {
      if (enabled) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    });

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [enabled]);
};

export default useBlockWindowScroll;
