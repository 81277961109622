import { useQuery } from '@tanstack/react-query';
import { Box, HStack, Image, Text, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { walletsApi } from '@cryptowallet/frontend/api';
import { GetSwapTransactionResponseWalletsDtoStatusEnum } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { useWalletSummary } from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, Icon, OverlaySpinner, Spinner } from '@cryptowallet/frontend/ui';
import { cacheRequestOption, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import CopyBox from '@cryptowallet/web/components/CopyBox';
import DetailsBox from '@cryptowallet/web/components/DetailsBox';
import TransactionDetailsCollapseBox from '@cryptowallet/web/components/TransactionDetailsCollapseBox';

import useSwap from './useSwap';

const swapStatusData = {
  [GetSwapTransactionResponseWalletsDtoStatusEnum.Created]: {
    message: 'Exchanging',
    icon: 'sending-exchange--purple',
  },
  [GetSwapTransactionResponseWalletsDtoStatusEnum.Pending]: {
    message: 'Processing Payment',
    icon: 'sending-exchange',
  },
  [GetSwapTransactionResponseWalletsDtoStatusEnum.Done]: {
    message: 'Done',
    icon: 'success',
  },
  [GetSwapTransactionResponseWalletsDtoStatusEnum.Cancelled]: {
    message: 'Transaction failed',
    icon: 'error',
  },
};

const AssetSwapProcessing = () => {
  const { swapWalletResources } = useWalletSummary();
  const { isLoading, swap } = useSwap();
  const [resetSwapData, fromWalletAccountId] = useAccountsStore(
    state => [state.resetSwapData, state.selectedWalletAccountId],
    shallow,
  );

  const toResource = swapWalletResources.find(item => swap?.toWalletAssetId === item.walletAsset.id);

  const { data: toWalletAccountResponse } = useQuery(
    ['assetDetails', toResource?.walletAccount?.id],
    () => walletsApi.walletsControllerGetWalletDetails(toResource?.walletAccount?.id),
    { ...cacheRequestOption, enabled: !!toResource?.walletAccount?.id },
  );

  const { data: fromWalletAccount } = useQuery(
    ['assetDetails', fromWalletAccountId],
    () => walletsApi.walletsControllerGetWalletDetails(fromWalletAccountId),
    { ...cacheRequestOption, enabled: !!fromWalletAccountId },
  );

  if (!swap) {
    return null;
  }

  const toWalletAccount = toWalletAccountResponse?.data?.walletDetails || null;

  if (!toResource || !toWalletAccount || !fromWalletAccount) {
    return <OverlaySpinner />;
  }

  const completed = (
    [
      GetSwapTransactionResponseWalletsDtoStatusEnum.Cancelled,
      GetSwapTransactionResponseWalletsDtoStatusEnum.Done,
    ] as GetSwapTransactionResponseWalletsDtoStatusEnum[]
  ).includes(swap?.status);
  const { message, icon } = swapStatusData[swap.status];

  return (
    <>
      <Box px="24px">
        <Box order={completed ? 2 : 1}>
          <HStack mt="24px" flexWrap="wrap">
            <VStack
              w={{ base: '100%', md: '50%' }}
              borderColor="gray.100"
              borderWidth="1px"
              borderRadius="10px"
              borderTopRightRadius={{ base: '10px', md: '0' }}
              borderBottomRightRadius="0"
              borderBottomRadius="0"
              p="16px">
              <Text fontFamily="mono" fontSize="xl" lineHeight="20px" color="textLabel" mb="5px">
                You{completed ? 'got' : ' will get'}
              </Text>
              <HStack alignItems="center">
                <Image
                  source={{
                    uri: toResource?.asset.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
                  }}
                  alt={toResource?.asset.currency.label}
                  h="20px"
                  w="20px"
                  mr="10px"
                />
                <Text fontSize="xl" lineHeight="20px">
                  {trimCryptoAmountLength(swap.toAmount, 9)} {toResource?.asset.currency.ticker}
                </Text>
              </HStack>
            </VStack>
            <VStack
              w={{ base: '100%', md: '50%' }}
              borderColor="gray.100"
              borderWidth="1px"
              borderRadius="10px"
              borderLeftRadius="0"
              borderRightRadius={{ base: '0', md: '10px' }}
              borderBottomRadius="0"
              borderLeftWidth={{ base: '1px', md: '0' }}
              borderTopWidth={{ base: '0', md: '1px' }}
              p="16px">
              <CopyBox
                title={
                  <Text color="textLabel" fontSize="xl">
                    Transaction ID
                  </Text>
                }
                valueStyles={{ fontSize: 'xl' }}
                value={swap?.id || ''}
              />
            </VStack>
          </HStack>
          <DetailsBox
            title={`We ${completed ? 'sent' : 'will send'} ${toResource.asset.currency.ticker} to this wallet address`}
            value={toWalletAccount.address || '-'}
            isReady={!isLoading}
            containerProps={{
              w: '100%',
              borderColor: 'gray.100',
              borderWidth: '1px',
              borderTopWidth: '0',
              borderRadius: '10px',
              borderTopRadius: '0',
              borderBottomRadius: completed ? '0' : '10px',
              p: '16px',
            }}
            textsProps={{
              fontSize: 'xl',
            }}
          />
          {completed && (
            <>
              <VStack
                w="100%"
                borderColor="gray.100"
                borderWidth="1px"
                borderRadius="10px"
                borderTopRadius={0}
                borderTopWidth={0}
                p="16px"
                mb="20px">
                <CopyBox
                  title={
                    <Text color="textLabel" fontSize="xl">
                      Transaction hash
                    </Text>
                  }
                  valueStyles={{ fontSize: 'xl' }}
                  value={swap?.payOutTxHash || ''}
                />
              </VStack>
              <TransactionDetailsCollapseBox mb="20px">
                <HStack mt="20px" flexWrap="wrap">
                  <DetailsBox
                    title="You sent"
                    value={`${trimCryptoAmountLength(swap.fromAmount, 9)} ${fromWalletAccount.asset.currency.ticker}`}
                    containerProps={{ w: { base: '100%', md: '50%' }, pr: '8px' }}
                  />
                  <DetailsBox
                    title="Network fee"
                    value={`${trimCryptoAmountLength(swap.networkFee, 9)} ${
                      fromWalletAccount.asset.network?.ticker || fromWalletAccount.asset.currency.ticker
                    }`}
                    containerProps={{ w: { base: '100%', md: '50%' }, pr: '8px' }}
                  />
                </HStack>
                <DetailsBox
                  title="Rate"
                  value={`${fromWalletAccount.asset.currency.ticker || ''}/${
                    toWalletAccount.asset.currency.ticker || ''
                  } ${trimCryptoAmountLength(swap.rate, 9)}`}
                  containerProps={{ w: '100%', pr: '8px' }}
                />
              </TransactionDetailsCollapseBox>
            </>
          )}
        </Box>
        <VStack
          order={completed ? 1 : 2}
          mt={completed ? '10px' : '40px'}
          mb={completed ? '0' : '40px'}
          alignItems="center">
          <Icon name={icon} w={completed ? '84px' : '80px'} h="84px" />
          <VStack>
            <Text userSelect="none" color="white" mt="8px" mb="8px" fontSize="2xl">
              {message}
            </Text>
            {!completed && <Spinner color="secondary.400" />}
          </VStack>
        </VStack>
      </Box>
      <BottomPurpleButton onPress={resetSwapData}>Start new exchange</BottomPurpleButton>
    </>
  );
};

export default AssetSwapProcessing;
