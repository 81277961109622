import { IButtonProps } from 'native-base';

import { usePartnerStyles } from '../../context/PartnersSettingsContext';

import { Button } from './Button';

export const ButtonSecondary = ({ children, _text = {}, ...props }: IButtonProps) => {
  const partnerStyles = usePartnerStyles('ButtonSecondary');

  return (
    <Button
      outlineWidth="0"
      bg="gray.200"
      borderWidth="1px"
      borderColor="transparent"
      _text={{
        color: 'white',
        ..._text,
      }}
      _hover={{
        bg: 'gray.300',
        _text: {
          color: 'white',
        },
      }}
      _focus={{
        borderColor: 'secondary.400',
      }}
      _disabled={{
        _text: {
          color: 'textDisabled',
        },
        bg: 'gray.100',
        opacity: 1,
      }}
      variant="unstyled"
      {...props}
      {...partnerStyles}>
      {children}
    </Button>
  );
};
