import React from 'react';

const NavigationSettingsChangeEmailIcon = ({ color }) => (
  <svg width="20" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.747891 5.0625L2.07273 6L3.24798 4.375L4.44461 6L5.74807 5.0625L4.53008 3.45833L6.47459 2.85417L5.96176 1.375L4.03861 2V0H2.43599L2.45735 2L0.512839 1.375L0 2.85417L1.96588 3.45833L0.747891 5.0625Z"
      fill={color}
    />
    <path
      d="M8.51059 5.0625L9.83543 6L11.0107 4.375L12.2073 6L13.5108 5.0625L12.2928 3.45833L14.2373 2.85417L13.7245 1.375L11.8013 2V0H10.1987L10.2201 2L8.27554 1.375L7.7627 2.85417L9.72859 3.45833L8.51059 5.0625Z"
      fill={color}
    />
    <path
      d="M16.2733 5.0625L17.5981 6L18.7734 4.375L19.97 6L21.2735 5.0625L20.0555 3.45833L22 2.85417L21.4872 1.375L19.564 2V0H17.9614L17.9828 2L16.0382 1.375L15.5254 2.85417L17.4913 3.45833L16.2733 5.0625Z"
      fill={color}
    />
  </svg>
);

export default NavigationSettingsChangeEmailIcon;
