/* eslint-disable @typescript-eslint/no-shadow */
import { analyticsApi, tokenService } from '@cryptowallet/frontend/api';
import { EventRequestDtoEventNameEnum } from '@cryptowallet/frontend/client';

import { GAEventParams } from '../gaFacade.types';

import { GAFunction } from './ga.web';
import { IGAFacade } from './gaFacade.abstract';

type GaEvents = typeof EventRequestDtoEventNameEnum[keyof typeof EventRequestDtoEventNameEnum];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const measurementId = process.env.NX_GOOGLE_ANALYTICS_MEASUREMENT_ID;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const trackId = process.env.NX_GOOGLE_ANALYTICS_TRACK_ID;

export class GAFacade implements IGAFacade<GaEvents> {
  private readonly ga: GAFunction;

  private readonly clientId: Promise<string>;

  private gaIsBlocked = false;

  constructor() {
    this.ga = window.ga;
    this.clientId = this.init();
  }

  public async getParams(): Promise<{ gaClientId: string; gaSessionId: string | undefined }> {
    const [gaClientId, gaSessionId] = await Promise.all([this.clientId, this.getSessionId()]);

    return { gaClientId, gaSessionId };
  }

  public getClientID(): Promise<string> {
    return this.clientId;
  }

  public async sendEvent(event: GaEvents, params?: GAEventParams): Promise<void> {
    try {
      const clientId = await this.clientId;
      // If a browser/extension blocks GA, send event via our API
      // but only authorised users, all the rest event will fails - no user id
      // decision provided with Andrew + Anton
      if (this.gaIsBlocked && tokenService.hasToken()) {
        await analyticsApi
          .analyticsControllerLogEvent({
            eventName: event,
            clientId,
            metaData: {
              ...params,
              is_blocked_analytics: true,
            },
          })
          .catch(err => console.warn('Could not send an analytics event to the server', err));
      } else {
        // IF no blockers use the `ga.js` library to send the event
        this.ga('event', event, params);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  public getSessionId() {
    return new Promise(resolve => {
      // get user id from GA cookie
      const regex = new RegExp(`_ga_${trackId}=GS\\d\\.\\d\\.(.+?)(?:;|$)`);
      const match = document.cookie.match(regex);
      const parts = match?.[1].split('.');

      if (parts) {
        resolve(parts.shift());
        return;
      }

      let tries = 0;
      const timer = setInterval(() => {
        const match = document.cookie.match(regex);
        const parts = match?.[1].split('.');
        if (parts) {
          clearInterval(timer);
          resolve(parts.shift());
        } else {
          tries += 1;
          if (tries >= 4) {
            clearInterval(timer);
            resolve('');
          }
        }
      }, 200);
    });
  }

  private init(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (typeof measurementId !== 'string') {
        const msg = 'Misconfiguration detected. GOOGLE ANALYTICS MEASUREMENT ID is not defined.';
        console.warn(msg);
        reject(new Error(msg));
        return;
      }

      const useFakeClientId = (): string => {
        console.warn(new Error('No GA client ID. Something blocks Google Analytics.'));
        const clientId = this.generateClientId();
        this.gaIsBlocked = true;
        // set client id to GA data layer
        this.ga('config', measurementId, {
          client_id: clientId,
        });
        return clientId;
      };
      // fallback for blocking GA browsers/extensions
      const fallbackTimer = setTimeout(() => {
        resolve(useFakeClientId());
      }, 1000);

      this.ga('get', measurementId, 'client_id', (clientId: unknown) => {
        if (typeof clientId === 'string' && clientId.length > 0) {
          clearTimeout(fallbackTimer);
          resolve(clientId);
        } else {
          resolve(useFakeClientId());
        }
      });
    });
  }

  private generateClientId() {
    // Generate GA4 client ID - a timestamp and a random unique number
    const timestamp = Math.floor(Date.now() / 1000);
    const uniqueNumber = Math.floor(Math.random() * 1e10);

    return `${timestamp}.${uniqueNumber}`;
  }
}
