import { Box } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';

import { useAlerts } from '../../../../index';

export const layoutBodyWrapperStyles = {
  px: {
    base: '10px',
    md: '24px',
    lg: '54px',
  },
};

const LayoutBodyWrapper = ({ children }) => {
  const { isBiggerThanTablet } = useScreenSize();
  const { relativeAlertsLength, alertsRect } = useAlerts();

  const defaultPy = isBiggerThanTablet ? '56px' : '24px';

  return (
    <Box
      position="relative"
      flexGrow="1"
      pt={relativeAlertsLength ? alertsRect.height : defaultPy}
      pb={defaultPy}
      {...layoutBodyWrapperStyles}>
      {children}
    </Box>
  );
};

export default LayoutBodyWrapper;
