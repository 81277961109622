/* eslint-disable no-return-assign */
import { Box, FlatList, StyledProps } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';

import { KEY_MAP } from '@cryptowallet/frontend/constants';
import { useScreenSize } from '@cryptowallet/frontend/hooks';

import { Input, useCheckout, usePartnerStyles } from '../../../../index';
import CurrencyDropdownItem from '../CurrencyDropdownItem';
import { IInputCurrency } from '../Inputs/CurrencySelect';

interface ICurrencyDropdownListProps {
  currencies: Array<IInputCurrency>;
  onSelectCurrency: (currency: IInputCurrency) => void;
  selectedCurrency: IInputCurrency;
  containerStyles?: StyledProps;
  onClose?: () => void;
  withIcon?: boolean;
  showNetwork?: boolean;
}

const filterableColumns = ['label', 'ticker'];

const CurrencyDropdownList = ({
  currencies,
  onSelectCurrency,
  selectedCurrency,
  containerStyles = {},
  onClose = () => {},
  withIcon = true,
  showNetwork = false,
}: ICurrencyDropdownListProps) => {
  const partnerStyles = usePartnerStyles('CurrencyDropdownList');
  const boxPartnerStyles = usePartnerStyles('CurrencyDropdownListBox');
  const ref = useRef();
  const itemsRef = useRef([]);
  const [filterValue, setFilterValue] = useState('');
  const [focusItemIndex, setFocusItemIndex] = useState(-1);
  const { isMobile, isBiggerThanTablet } = useScreenSize();
  const { isCheckout } = useCheckout();
  const [mdDropdownMaxHeight, setMdDropdownMaxHeight] = useState(200);

  const filteredCurrencies = filterValue
    ? currencies.filter(currency =>
        filterableColumns.some(column => currency[column].toLowerCase().includes(filterValue.toLowerCase())),
      )
    : currencies;

  const handleClickOutsideList = e => {
    if (!isMobile && ref && !ref.current.contains(e.target)) {
      onClose();
    }
  };

  const handleKeyDown = e => {
    switch (e.keyCode) {
      case KEY_MAP.ENTER: {
        e.preventDefault();
        const currency = filteredCurrencies[focusItemIndex];
        if (currency) {
          onSelectCurrency(currency);
          onClose();
        }
        break;
      }
      case KEY_MAP.TAB: {
        onClose();
        break;
      }
      case KEY_MAP.ESCAPE: {
        e.preventDefault();
        onClose();
        break;
      }
      case KEY_MAP.ARROW_DOWN: {
        e.preventDefault();
        setFocusItemIndex(prev => {
          const newIndex = prev + 1 > filteredCurrencies.length - 1 ? 0 : prev + 1;
          const itemRef = itemsRef.current[newIndex];
          if (itemRef?.scrollIntoViewIfNeeded) {
            itemRef.scrollIntoViewIfNeeded();
          }

          return newIndex;
        });
        break;
      }
      case KEY_MAP.ARROW_UP: {
        e.preventDefault();
        setFocusItemIndex(prev => {
          const newIndex = prev - 1 < 0 ? filteredCurrencies.length - 1 : prev - 1;
          const itemRef = itemsRef.current[newIndex];
          if (itemRef?.scrollIntoViewIfNeeded) {
            itemRef.scrollIntoViewIfNeeded();
          }

          return newIndex;
        });
        break;
      }
    }
  };

  useEffect(() => {
    if (!isMobile && ref?.current) {
      const { top: containerTopY } = (ref.current as HTMLElement).getBoundingClientRect();

      if (isCheckout) {
        let maxH = window.innerHeight - 84 - containerTopY;
        maxH = maxH < 190 ? 190 : maxH;
        setMdDropdownMaxHeight(maxH > 400 ? 400 : maxH);
      } else {
        setMdDropdownMaxHeight(400);
      }
    }
  }, [ref, isMobile, isCheckout]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideList);
    return () => {
      document.removeEventListener('click', handleClickOutsideList);
    };
  }, []);

  useEffect(() => {
    setFocusItemIndex(-1);
  }, [filterValue]);

  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="column"
      bg="black"
      width="100%"
      position="absolute"
      top="60px"
      maxHeight={{ md: mdDropdownMaxHeight }}
      borderWidth="1px"
      borderColor="gray.100"
      py="16px"
      px="24px"
      pb={{ base: '100px', lg: '16px' }}
      borderRadius="10px"
      {...containerStyles}
      {...partnerStyles}>
      {currencies.length > 4 && (
        <Box {...boxPartnerStyles}>
          <Input
            onChangeText={v => setFilterValue(v)}
            value={filterValue}
            placeholder="Search"
            my="24px"
            mt="20px"
            onKeyPress={handleKeyDown}
            _invalid={{
              // need to ignore parent invalid state
              borderColor: 'transparent',
            }}
            h="44px"
            size="xl"
            autoFocus={isBiggerThanTablet}
          />
        </Box>
      )}
      <FlatList
        data={filteredCurrencies}
        renderItem={({ item: currency, index }) => (
          <CurrencyDropdownItem
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={el => (itemsRef.current[index] = el)}
            currency={currency}
            onPress={() => onSelectCurrency(currency)}
            selected={selectedCurrency && selectedCurrency.id === currency.id}
            focused={focusItemIndex === index}
            withIcon={withIcon}
            showNetwork={showNetwork}
            last={index === filteredCurrencies.length - 1}
          />
        )}
      />
    </Box>
  );
};

export default CurrencyDropdownList;
