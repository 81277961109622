import React from 'react';

const ClosedEyeIcon = ({ color = '#8E8A99' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1302 1.18639C15.3904 1.4349 15.3904 1.83783 15.1302 2.08634L1.80524 14.8136C1.54505 15.0621 1.1232 15.0621 0.863017 14.8136C0.60283 14.5651 0.60283 14.1622 0.863017 13.9137L14.188 1.18639C14.4482 0.937871 14.8701 0.937871 15.1302 1.18639Z"
      fill="#8E8A99"
    />
    <path
      d="M15.8384 7.5991C15.2455 6.92455 13.9263 5.72819 12.2807 4.81182L15.1322 2.08819C15.392 1.84 15.392 1.4391 15.1322 1.19091C14.8724 0.942732 14.4526 0.942732 14.1928 1.19091L11.0281 4.21364C10.0687 3.8191 9.03602 3.55182 7.99667 3.55182C4.56548 3.54546 1.20758 6.40273 0.154903 7.5991C-0.0516344 7.83455 -0.0516344 8.16546 0.154903 8.40091C0.747866 9.07546 2.06704 10.2718 3.71268 11.1882L0.861128 13.9118C0.601291 14.16 0.601291 14.5609 0.861128 14.8091C0.994379 14.9364 1.16094 14.9936 1.33417 14.9936C1.50739 14.9936 1.67395 14.93 1.8072 14.8091L4.97189 11.7864C5.93129 12.1809 6.96398 12.4482 8.00333 12.4482C11.4345 12.4482 14.7924 9.59091 15.8451 8.39455C16.0516 8.1591 16.0516 7.82819 15.8451 7.59273L15.8384 7.5991ZM5.36498 8C5.36498 6.61273 6.54424 5.48637 7.99667 5.48637C8.48303 5.48637 8.92942 5.62 9.31584 5.83637L5.73142 9.26C5.50489 8.89091 5.36498 8.45819 5.36498 8ZM7.99667 10.5136C7.51031 10.5136 7.06392 10.38 6.67749 10.1636L10.2619 6.74001C10.4884 7.1091 10.6284 7.54182 10.6284 8C10.6284 9.38728 9.44909 10.5136 7.99667 10.5136Z"
      fill={color}
    />
  </svg>
);

export default ClosedEyeIcon;
