import { useMutation } from '@tanstack/react-query';
import { Box, Center, Flex, Text } from 'native-base';
import { useEffect, useState } from 'react';

import { useGA } from '@cryptowallet/frontend/ga';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { BottomPurpleButton, GhostButton, SecondsCountdown, useAlerts } from '@cryptowallet/frontend/ui';
import { getDeviceId } from '@cryptowallet/frontend/utils';
import { LoginOrRegisterBadRequestResponseDtoErrorEnum, userApi } from '@cryptowallet/web/api-client';
import useInitialScrollTop from '@cryptowallet/web/hooks/useInitialScrollTop';
import { CaptchaResult, showCaptcha } from '@cryptowallet/web/utils/captcha';

import AuthHeader from '../../../components/AuthHeader';
import OtpInput from '../../../components/OtpInput';
import { OnVerifyAuthStepFn } from '..';

import FormHeading from './FormHeading';

const resendTimeoutMS = 60000;

const EmailVerificationForm = ({
  email,
  onVerify,
  onEmailChangeRequest,
  country,
  referralCode,
}: WithNativeBase<{
  email: string;
  onVerify: OnVerifyAuthStepFn;
  onEmailChangeRequest: () => void;
  country: string;
  referralCode: string;
}>): JSX.Element => {
  useInitialScrollTop();
  const { showErrorToaster } = useAlerts();
  const [otp, _setOtp] = useState(''); // eslint-disable-line
  const [resendTime, setResendTime] = useState(Date.now() + resendTimeoutMS);
  const [error, setError] = useState(null);
  const { isBiggerThanTablet } = useScreenSize();
  const ga = useGA();

  const setOtp = val => {
    if (error) {
      setError(null);
    }
    _setOtp(val.replace(/\D/g, '').substring(0, 6));
  };

  const handleResendClick = async () => {
    let captchaRes;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.activeElement?.blur();
      captchaRes = await showCaptcha();

      if (captchaRes === CaptchaResult.Closed) {
        return;
      }
    } catch (e) {
      return;
    }

    try {
      setResendTime(Date.now() + resendTimeoutMS);
      userApi.usersControllerResendOtp({ email, captcha: captchaRes });
    } catch (err) {
      showErrorToaster({ title: `Can't resend code, please try again later` });
    }
  };

  const verifyOtpMutation = useMutation(async () => {
    const { gaClientId, gaSessionId } = await ga.getParams();

    return userApi.usersControllerLoginOrRegister({
      deviceUniqueId: getDeviceId(),
      email,
      otp,
      gaClientId,
      gaSessionId,
      country,
      referralCode,
    });
  });

  const verify = async () => {
    if (!email) {
      throw new Error('No email provided');
    }

    try {
      const { data } = await verifyOtpMutation.mutateAsync();

      onVerify(data.refreshToken, data.accessToken, data.requiredMfaMethods, data.blockedCountryRegistration);
    } catch (err) {
      if (err?.response?.data?.error === LoginOrRegisterBadRequestResponseDtoErrorEnum.OtpVerifyInvalid) {
        setError('Wrong OTP email code. Please try again');
      } else {
        showErrorToaster({ title: `Can't verify code, please try again later` });
      }
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      verify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handlePaste = async () => {
    const clipboardVal = await navigator.clipboard.readText();
    const numericVal = clipboardVal.replace(/\D/g, '');
    if (numericVal) {
      setOtp(numericVal);
    }
  };

  return (
    <Box>
      <AuthHeader display="flex" flexDirection="row" alignItems="center">
        <FormHeading text="Email Verification" onPressBack={onEmailChangeRequest} />
      </AuthHeader>
      <Box p="32px" pt="24px" pb="16px">
        <Text fontSize="lg" mb="42px">
          Please check your email. The 6-digit verification code was sent to {email}. Pay attention that the code is
          valid for 10 minutes.
        </Text>
        <Center>
          <Box position="relative">
            <OtpInput
              autoFocus={isBiggerThanTablet}
              invalid={!!error}
              value={otp}
              onChange={setOtp}
              disabled={verifyOtpMutation.isLoading}
            />
            {error && (
              <Text position="absolute" bottom="-20px" left="0" color="error.400">
                {error}
              </Text>
            )}
          </Box>
          <Flex h="54px" justifyContent="center">
            <SecondsCountdown
              expireTime={resendTime}
              renderer={({ timer, isCompleted }) => {
                if (isCompleted) {
                  return (
                    <GhostButton _text={{ fontSize: 'lg' }} onPress={handleResendClick}>
                      Resend
                    </GhostButton>
                  );
                }

                return (
                  <Text fontSize="lg" mt="0">
                    Resend in {timer}
                  </Text>
                );
              }}
            />
          </Flex>
        </Center>
      </Box>
      <BottomPurpleButton
        _text={{ fontSize: 'lg', color: 'white' }}
        isDisabled={verifyOtpMutation.isLoading}
        onPress={handlePaste}
        size="lg"
        isLoadingText="Paste Code">
        Paste Code
      </BottomPurpleButton>
    </Box>
  );
};

export default EmailVerificationForm;
