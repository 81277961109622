import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { Kyc, Poa } from '@cryptowallet/web/components/PermissionCases';

const SecurityVerification = () => {
  const userStatus = useUserStatus();

  if (!userStatus.VERIFIED_KYC) {
    return <Kyc />;
  }

  if (!userStatus.VERIFIED_POA_FOR_HIGH_RISK) {
    return <Poa />;
  }

  return <Navigate to="/account/security" replace />;
};

export default SecurityVerification;
