import React, { useState } from 'react';
import { Control } from 'react-hook-form';

import { useScreenSize } from '@cryptowallet/frontend/hooks';

import { useWidget } from '../../../index';

import CryptocurrencyFormContent, { ICryptocurrencyInputItem } from './CryptocurrencyFormContent';
import CryptocurrencyFormModalMobile from './CryptocurrencyFormModalMobile';

interface ICryptocurrencyInputsFormProps {
  control: Control;
  inputs: ICryptocurrencyInputItem[];
}

const CryptocurrencyInputsForm = ({ control, inputs }: ICryptocurrencyInputsFormProps) => {
  const { isMobile } = useScreenSize();
  const { isWidget } = useWidget();

  const [selectedInputName, setSelectedInputName] = useState(null);

  const openCurrencyList = inputName => setSelectedInputName(prev => (inputName === prev ? null : inputName));

  return (
    <>
      <CryptocurrencyFormContent
        control={control}
        openCurrencyList={openCurrencyList}
        selectedInputName={selectedInputName}
        inputs={inputs}
      />
      {(isMobile || isWidget) && selectedInputName && (
        <CryptocurrencyFormModalMobile
          control={control}
          onClose={() => setSelectedInputName(null)}
          selectedInputName={selectedInputName}
          openCurrencyList={openCurrencyList}
          inputs={inputs}
        />
      )}
    </>
  );
};

export default CryptocurrencyInputsForm;
