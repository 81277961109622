/* eslint-disable @typescript-eslint/no-shadow */
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { referralProgramApi } from '@cryptowallet/frontend/api';
import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';

const useRewards = (outputCount = 10) => {
  const { kycPoaHighRiskVerified } = useUserStatus();
  const {
    data: response,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['rewards-paginated'],
    ({ pageParam }) =>
      referralProgramApi.referralProgramControllerGetUserReferralRewards(outputCount, pageParam?.offset || 0),
    {
      getNextPageParam: lastPage => {
        if (!lastPage) {
          return { offset: 0 };
        }
        const { outputCount, outputOffset, outputTotalCount } = lastPage.data as never;
        if (outputCount + outputOffset >= outputTotalCount) {
          return undefined;
        }

        return { offset: outputCount + outputOffset };
      },
      ...cacheRequestOption,
      enabled: kycPoaHighRiskVerified,
    },
  );

  const { referralRewards, totalRewardsValue } = useMemo(() => {
    if (response) {
      return {
        referralRewards: response.pages.reduce((prev, page) => [...prev, page?.data.referralRewards], []).flat(),
        totalRewardsValue: response.pages[0]?.data.totalRewardsValue,
      };
    }

    return {
      referralRewards: [],
      totalRewardsValue: 0,
    };
  }, [response]);

  return {
    referralRewards,
    totalRewardsValue,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};

export default useRewards;
