import { HStack, Text } from 'native-base';
import React from 'react';

import { Icon } from '@cryptowallet/frontend/ui';

const WarningText = ({ text, textProps = {} }) => (
  <HStack pl="26px" alignItems="center">
    <Icon name="alerts/alert-warning--orange" position="absolute" top="3px" left="3px" h="16px" w="16px" />
    <Text color="white" fontWeight="100" fontSize="lg" {...textProps}>
      {text}
    </Text>
  </HStack>
);

export default WarningText;
