/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Text, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useSellFlowStore } from '@cryptowallet/frontend/stores';
import { ButtonPurple, LabeledInput, PressableCheckbox, useAlerts, useUser } from '@cryptowallet/frontend/ui';
import {
  exchangerApi,
  ExchangerCreateExchangeRequestDto,
  ExchangerCreateExchangeRequestDtoTypeEnum,
  ValidateIbanResponseDtoResultEnum,
} from '@cryptowallet/web/api-client';

import SellPaymentInfo from './SellPaymentInfo';
import SellTransactionDetails from './SellTransactionDetails';

interface IFormProps {
  iban: string;
  agree: boolean;
}

const SellPayoutDetails = () => {
  const queryClient = useQueryClient();
  const { onApiError } = useAlerts();
  const user = useUser();
  const {
    amount,
    paymentMethod,
    fromCurrency,
    toCurrency,
    setExchangeId,
    iban,
    setIban,
    aggreeNameMatches,
    setAgreeNameMatches,
    useMyWallet,
  } = useSellFlowStore(
    state => ({
      amount: state.amount,
      paymentMethod: state.paymentMethod,
      fromCurrency: state.fromCurrency,
      toCurrency: state.toCurrency,
      setExchangeId: state.setExchangeId,
      iban: state.iban,
      setIban: state.setIban,
      aggreeNameMatches: state.aggreeNameMatches,
      setAgreeNameMatches: state.setAgreeNameMatches,
      useMyWallet: state.useMyWallet,
    }),
    shallow,
  );
  const { control, handleSubmit, trigger } = useForm<IFormProps>({
    defaultValues: {
      iban,
      agree: aggreeNameMatches,
    },
  });

  const [payeeFirstName, payeeLastName] = useMemo(() => Object.values(user.profileData), [user]);

  const { isLoading, mutateAsync } = useMutation((exchangeData: ExchangerCreateExchangeRequestDto) =>
    exchangerApi.exchangerControllerCreateExchange(exchangeData),
  );

  const onSubmit = async ({ iban }) => {
    try {
      const isValid = await trigger();
      if (!isValid) {
        return;
      }
      const {
        data: { exchangeId },
      } = await mutateAsync({
        fromExchangerAssetId: fromCurrency.id,
        toExchangerAssetId: toCurrency.id,
        type: ExchangerCreateExchangeRequestDtoTypeEnum.Sell,
        amount: Number(amount),
        pspType: paymentMethod,
        recipientDetails: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          payeeFirstName,
          payeeLastName,
          payeeIban: iban,
        },
        useMyWallet,
      });

      setExchangeId(exchangeId);
      queryClient.invalidateQueries(['getExchanges']);
    } catch (err) {
      onApiError(err, { title: `Can't create exchange, please try again later` });
    }
  };

  async function isValidIban(iban: string): Promise<true | string> {
    const response = await exchangerApi.exchangerControllerValidateIban(iban);
    if (response.data.result === ValidateIbanResponseDtoResultEnum.ValidIban) return true;
    if (response.data.result === ValidateIbanResponseDtoResultEnum.InvalidIban)
      return 'Incorrect IBAN account. Please try again';
    if (response.data.result === ValidateIbanResponseDtoResultEnum.SepaUnreachableIban)
      return 'Entered IBAN account is not SEPA compatible.';
    return 'Something went wrong. Please contact support.';
  }

  return (
    <Box mt="30px">
      <Box px="19px">
        <SellPaymentInfo showFullData={false} />
      </Box>
      <Box px={{ base: '0', md: '19px' }}>
        <VStack bg="black" borderRadius={{ base: '0', md: '8px' }} py="26px" px="19px">
          <LabeledInput
            value={`${payeeFirstName} ${payeeLastName}`}
            onSubmit={handleSubmit(onSubmit)}
            label="SEPA Receiver name"
            labelTextProps={{
              color: 'textLabel',
              fontSize: 'lg',
            }}
            h="56px"
            fontSize="lg"
            color="textRegular"
            isDisabled={true}
          />
          <Controller
            control={control}
            rules={{
              required: 'IBAN is required',
              validate: async (val): Promise<true | string> => isValidIban(val),
            }}
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <LabeledInput
                error={error}
                onChangeText={newValue => {
                  onChange(newValue);
                  setIban(newValue);
                }}
                onSubmit={handleSubmit(onSubmit)}
                {...field}
                label="IBAN account"
                labelTextProps={{
                  color: 'textLabel',
                  fontSize: 'lg',
                }}
                h="56px"
                fontSize="lg"
                color="textRegular"
              />
            )}
            name="iban"
          />
          <Controller
            control={control}
            rules={{
              validate: (val): true | string => val || 'Required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PressableCheckbox
                error={error}
                isChecked={value}
                onChange={() => {
                  const newValue = !value;
                  onChange(newValue);
                  setAgreeNameMatches(newValue);
                }}>
                <Text fontSize="lg" color="textRegular" lineHeight="16px" userSelect="none" ml="13px">
                  I confirm that the name I’ve entered matches the one in my documents and the one in the bank account
                  I’m using
                </Text>
              </PressableCheckbox>
            )}
            name="agree"
          />
          <ButtonPurple isDisabled={isLoading} onPress={handleSubmit(onSubmit)} mt="3px" w="100%" h="50px" size="lg">
            Confirm
          </ButtonPurple>
        </VStack>
      </Box>
      <Box px="19px">
        <SellTransactionDetails />
      </Box>
    </Box>
  );
};

export default SellPayoutDetails;
