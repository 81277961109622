/* eslint-disable @typescript-eslint/no-shadow */
import { HStack, IButtonProps, Text, VStack } from 'native-base';
import React from 'react';

import { ButtonPurple, ButtonSecondary, CloseButton, CloseButtonType, Icon } from '../../../index';

export enum NotificationAlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export enum NotificationAlertButtonType {
  ButtonSecondary,
  ButtonPurple,
}

export interface NotificationAlertData {
  type: NotificationAlertType;
  headerMessage: string;
  text?: string;
  buttons?: {
    title: string;
    onPress: (id?: string | number) => void;
    buttonProps?: IButtonProps;
    type: NotificationAlertButtonType;
  }[];
}

interface INotificationAlertProps {
  onClose: () => void;
  data: NotificationAlertData;
  id: number | string;
  isLastRelativeAlert?: boolean;
}

const alertElements = {
  [NotificationAlertType.SUCCESS]: {
    iconName: 'alerts/alert-success--green',
  },
  [NotificationAlertType.WARNING]: {
    iconName: 'alerts/alert-warning--orange',
  },
  [NotificationAlertType.INFO]: {
    iconName: 'alerts/alert-info--blue',
  },
  [NotificationAlertType.ERROR]: {
    iconName: 'alerts/alert-error--red',
  },
};

export const NotificationAlert = ({ onClose, data, id, isLastRelativeAlert = false }: INotificationAlertProps) => {
  const { buttons = [], type } = data;
  const { iconName } = alertElements[type];

  return (
    <VStack
      bg="background.400"
      borderColor="gray.100"
      borderRadius="5px"
      px={{ base: '0', lg: '24px' }}
      pr={{ base: '16px', lg: '24px' }}
      py="25px"
      mb={isLastRelativeAlert ? '0' : '12px'}>
      <HStack px="16px" alignItems="center" position="relative">
        <VStack w="100%">
          <HStack alignItems="center">
            <Icon name={iconName} h="16px" w="16px" mr="8px" />
            <Text color="white" fontWeight="500" fontSize="lg">
              {data.headerMessage}
            </Text>
          </HStack>
          {data.text && (
            <Text mt="4px" color="white" fontSize="md">
              {data.text}
            </Text>
          )}
        </VStack>
        <CloseButton
          type={CloseButtonType.Filled}
          position="absolute"
          top="2px"
          right="0"
          onPress={onClose}
          iconProps={{
            h: '16px',
            w: '16px',
          }}
        />
      </HStack>
      {!!buttons.length && (
        <HStack px="16px" mt="16px">
          {buttons.map(({ title, onPress, type, buttonProps = {} }) => {
            switch (type) {
              case NotificationAlertButtonType.ButtonSecondary: {
                return (
                  <ButtonSecondary key={title} h="28px" px="16px" onPress={onPress as never} {...buttonProps}>
                    {title}
                  </ButtonSecondary>
                );
              }
              case NotificationAlertButtonType.ButtonPurple: {
                return (
                  <ButtonPurple key={title} h="28px" px="16px" onPress={onPress as never} {...buttonProps}>
                    {title}
                  </ButtonPurple>
                );
              }

              default:
                return null;
            }
          })}
        </HStack>
      )}
    </VStack>
  );
};
