import { Text, VStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPurple } from '@cryptowallet/frontend/ui';

const BlockedLimitsPlaceholder = () => {
  const navigate = useNavigate();

  return (
    <VStack px="24px" flexGrow="1" justifyContent="flex-start" alignItems="center">
      <>
        <img src="/assets/images/blocked-referral-placeholder--dark.png" alt="Pass kyc to change and access limits" />
        <Text textAlign="center" my="24px" fontSize="lg" color="textRegular">
          You need to pass KYC to change and access limits.
        </Text>
        <ButtonPurple h="50" px="32px" onPress={() => navigate('/account/security/verification')}>
          Start KYC
        </ButtonPurple>
      </>
    </VStack>
  );
};

export default BlockedLimitsPlaceholder;
