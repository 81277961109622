/* eslint-disable no-nested-ternary */
import { Box } from 'native-base';
import qs from 'qs';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WalletDetailsEntityDto, WalletResourceEntityDto } from '@cryptowallet/frontend/client';
import { useScreenSize, useSelectedWalletAccount, useSelectedWalletResource } from '@cryptowallet/frontend/hooks';
import { DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { OverlaySpinner, useConfig } from '@cryptowallet/frontend/ui';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import useInitialScrollTop from '@cryptowallet/web/hooks/useInitialScrollTop';
import useWindowSize from '@cryptowallet/web/hooks/useWindowSize';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

import AssetTransactionHistory from '../AssetTransactionHistory';

import AssetDeposit from './AssetDeposit';
import AssetDetailsOverview from './AssetDetailsOverview';
import AssetGenerateAddress from './AssetGenerateAddress';
import AssetSwap from './AssetSwap';
import AssetWithdraw from './AssetWithdraw';

interface DetailsPanelComponent {
  onGoBack: () => void;
}

type ViewData = {
  [key in DetailsPanelView]?: {
    label?: string;
    Component: React.FC<DetailsPanelComponent>;
    getCurrencyLabel?: (
      selectedWalletAccount: WalletDetailsEntityDto,
      selectedWalletResource: WalletResourceEntityDto,
    ) => string;
  };
};

const views: ViewData = {
  [DetailsPanelView.Overview]: {
    label: 'Balance',
    Component: AssetDetailsOverview,
    getCurrencyLabel: selectedWalletAccount => selectedWalletAccount?.asset?.currency?.label || '',
  },
  [DetailsPanelView.Deposit]: {
    label: 'Deposit',
    Component: AssetDeposit,
  },
  [DetailsPanelView.Withdraw]: {
    Component: AssetWithdraw,
  },
  [DetailsPanelView.GenerateAddress]: {
    label: 'Balance',
    Component: AssetGenerateAddress,
    getCurrencyLabel: (_, selectedWalletResource) => selectedWalletResource?.asset?.currency?.label || '',
  },
  [DetailsPanelView.Swap]: {
    Component: AssetSwap,
  },
};

const overviewHeaderStyles = (alertsRect, containerRect) => ({
  position: 'fixed',
  top: `${alertsRect.y + alertsRect.height}px`,
  left: `${containerRect.x}px`,
  w: `${containerRect.width}px`,
});

const DetailsPanel = () => {
  const ref = useRef();
  const { swapDisabled } = useConfig();
  const { isDesktop, isMobile } = useScreenSize();
  useInitialScrollTop(!isMobile);
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { isFetchingSelectedWalletAccount, selectedWalletAccount } = useSelectedWalletAccount();
  const selectedWalletResource = useSelectedWalletResource();
  const {
    detailsPanelView,
    setDetailsPanelView,
    setSelectedWalletAccountId,
    setSelectedWalletAssetId,
    selectedWalletAssetId,
    reset,
  } = useAccountsStore(
    state => ({
      detailsPanelView: state.detailsPanelView,
      setDetailsPanelView: state.setDetailsPanelView,
      setSelectedWalletAccountId: state.setSelectedWalletAccountId,
      setSelectedWalletAssetId: state.setSelectedWalletAssetId,
      selectedWalletAssetId: state.selectedWalletAssetId,
      reset: state.reset,
    }),
    shallow,
  );
  const [containerRect, setContainerRect] = useState({ width: '0', x: '0' });

  const onOpenRecentActivity = (_, txId = '') => {
    const params = txId ? `?${qs.stringify({ txId })}` : '';
    navigate(`/wallet/recent-activity${params}`);
  };

  const openOverviewView = () => {
    if (selectedWalletAssetId) {
      setDetailsPanelView(DetailsPanelView.GenerateAddress);
    } else {
      setDetailsPanelView(DetailsPanelView.Overview);
    }
  };

  const onGoBack = () => {
    if (detailsPanelView === DetailsPanelView.Overview) {
      setSelectedWalletAccountId('');
    } else if (detailsPanelView === DetailsPanelView.GenerateAddress) {
      setSelectedWalletAssetId('');
      setDetailsPanelView(DetailsPanelView.Overview);
    } else {
      openOverviewView();
    }
  };

  useLayoutEffect(() => {
    if (ref && ref.current) {
      setContainerRect(ref.current.getBoundingClientRect());
    }
  }, [windowSize, ref]);

  useEffect(() => {
    if (swapDisabled && detailsPanelView === DetailsPanelView.Swap) {
      reset();
    }
  }, [detailsPanelView, swapDisabled, reset]);

  const { label, getCurrencyLabel, Component } = views[detailsPanelView];

  const isOverview = detailsPanelView === DetailsPanelView.Overview;
  const isGenerateAddressView = detailsPanelView === DetailsPanelView.GenerateAddress;
  const isReady = !isFetchingSelectedWalletAccount;

  const containerMinHeight =
    detailsPanelView === DetailsPanelView.Deposit && selectedWalletResource
      ? '209px'
      : (isOverview || isGenerateAddressView) && !selectedWalletAccount
      ? '328px'
      : '509px';

  return (
    <Box
      ref={ref}
      maxW={{ base: '100%', xl: '690px' }}
      w={{ base: '100%', xl: '56%' }}
      h="fit-content"
      minH={containerMinHeight}
      pt="0"
      background="background.400"
      borderWidth="1px"
      borderColor="gray.100"
      borderRadius="10px"
      zIndex="1">
      {!isReady && <OverlaySpinner />}
      {isReady && (
        <>
          {(selectedWalletAccount || selectedWalletResource) && (
            <>
              {!!label && (
                <Box zIndex="1" bg="black">
                  <AuthHeader
                    borderWidth="1px"
                    borderColor="gray.100"
                    borderBottomWidth="0"
                    borderTopLeftRadius="10px"
                    borderTopRightRadius="10px"
                    bg="background.400"
                    mb="0">
                    <FormHeading
                      onPressBack={(!isOverview || !isDesktop) && onGoBack}
                      // eslint-disable-next-line sonarjs/no-nested-template-literals
                      text={`${
                        getCurrencyLabel ? `${getCurrencyLabel(selectedWalletAccount, selectedWalletResource)} ` : ''
                      }${label}`}
                    />
                  </AuthHeader>
                </Box>
              )}
              <Component onGoBack={onGoBack} />
            </>
          )}
          {isOverview && <AssetTransactionHistory isWidget={true} onOpenRecentActivity={onOpenRecentActivity} />}
        </>
      )}
    </Box>
  );
};

export default DetailsPanel;
