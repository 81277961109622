import { create } from 'zustand';

export interface IChangeEmailFlow {
  newEmail: string;
  setNewEmail: (newEmail: string) => void;
  oldEmailOtpToken: string;
  setOldEmailOtpToken: (oldEmailOtpToken: string) => void;
  verifyCurrentEmailIsInvalid: boolean;
  setVerifyCurrentEmailIsInvalid: (verifyCurrentEmailInvalid: boolean) => void;
}

export const useStore = create<IChangeEmailFlow>()(set => ({
  newEmail: '',
  setNewEmail: newEmail => set({ newEmail }),
  oldEmailOtpToken: '',
  setOldEmailOtpToken: oldEmailOtpToken => set({ oldEmailOtpToken }),
  verifyCurrentEmailIsInvalid: false,
  setVerifyCurrentEmailIsInvalid: verifyCurrentEmailIsInvalid => set({ verifyCurrentEmailIsInvalid }),
}));
