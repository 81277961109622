import { IButtonProps } from 'native-base';

import { Button } from '@cryptowallet/frontend/ui';

const ButtonBlurredLightFocusedStyle = { bg: 'white:alpha.30' };
export const ButtonBlurredLight = ({ children, ...props }: IButtonProps) => (
  <Button
    bg="white:alpha.10"
    _hover={ButtonBlurredLightFocusedStyle}
    _pressed={{ bg: 'gray.100' }}
    _focus={ButtonBlurredLightFocusedStyle}
    {...props}>
    {children}
  </Button>
);
