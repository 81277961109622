/* eslint-disable no-nested-ternary */
import { Box, FormControl, IBoxProps, IFormControlProps, Modal, Text } from 'native-base';
import React, { forwardRef, Ref, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { getPlatform } from '@cryptowallet/frontend/utils';

import {
  ChevronDownIcon,
  CloseButton,
  ErrorMessage,
  ErrorMessageType,
  Icon,
  ModalBase,
  Pressable,
  usePartnerStyles,
} from '../../../index';
import Spinner from '../Spinner';

import Dropdown, { ISelectOption } from './Dropdown';

const isWeb = getPlatform() === 'web';

interface ISelectProps {
  label: string;
  placeholder?: string;
  error?: FieldError | undefined;
  options?: ISelectOption[];
  isLoading?: boolean;
  onSelect: (id: string) => void;
  dropdownContainerProps?: IBoxProps;
  selected?: string;
  formControlProps?: IFormControlProps;
}

interface ISelectButtonProps {
  placeholder?: string;
  isOpen: boolean;
  onPress: () => void;
  isLoading?: boolean;
  value: ISelectOption | null;
}

const SelectButton = forwardRef(
  ({ placeholder, isOpen, onPress, isLoading, value }: ISelectButtonProps, ref: Ref<HTMLElement>) => {
    const iconName = isOpen ? 'chevron-up' : 'chevron-down';
    const partnerStyles = usePartnerStyles('SelectButton');

    return (
      <Pressable
        ref={ref}
        h="56px"
        px={isWeb ? '24px' : '19px'}
        onPress={onPress}
        bg="gray.200"
        borderRadius="10px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        zIndex="2"
        outlineWidth="0"
        borderColor="transparent"
        borderWidth="1px"
        _hover={
          isWeb
            ? {
                bg: 'gray.300',
              }
            : {}
        }
        _focus={{
          borderColor: 'secondary.400',
        }}
        {...partnerStyles}>
        {({ isFocused }) => (
          <>
            {isLoading && <Spinner color="secondary.400" />}
            {!isLoading && (
              <>
                <Text fontSize="lg">{value ? value.label : placeholder}</Text>
                {isWeb ? (
                  <Icon name={`${iconName}${isFocused ? '--gold' : ''}`} w="8px" h="5px" />
                ) : (
                  <ChevronDownIcon />
                )}
              </>
            )}
          </>
        )}
      </Pressable>
    );
  },
);

const Select = ({
  label,
  placeholder = 'Select',
  options = [],
  onSelect,
  isLoading = false,
  error,
  selected = null,
  formControlProps = {},
  dropdownContainerProps = {
    maxH: {
      base: '100%',
      md: '300px',
    },
  },
}: ISelectProps) => {
  const { isMobile } = useScreenSize();
  const buttonRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const value = options.find(item => item.id === selected);

  const onClose = () => {
    setIsOpen(false);
    if (isWeb) {
      buttonRef?.current?.focus();
    }
  };

  const onOpen = () => {
    setIsOpen(true);
    if (isWeb) {
      buttonRef?.current?.blur();
    }
  };

  return (
    <FormControl isInvalid={!!error} {...formControlProps}>
      <FormControl.Label mb="8px">
        <Text fontSize="lg" color="textLabel">
          {label}
        </Text>
      </FormControl.Label>
      <Box position="relative" zIndex="1">
        <SelectButton
          ref={buttonRef}
          placeholder={placeholder}
          isOpen={isOpen}
          onPress={() => {
            if (isOpen) {
              onClose();
            } else {
              onOpen();
            }
          }}
          isLoading={isLoading}
          value={value}
        />
        <ErrorMessage type={ErrorMessageType.ERROR} error={error} />
        {isOpen && !isMobile && (
          <Dropdown
            options={options}
            onSelect={id => {
              onSelect(id);
              onClose();
            }}
            containerProps={dropdownContainerProps}
            value={value}
            onClose={onClose}
          />
        )}
        {isOpen && isMobile && (
          <ModalBase onClose={onClose}>
            <Modal.Content maxW="100%" maxH="100%" h="100%" w="100%" bg={isWeb ? 'background.600' : 'transparent'}>
              <CloseButton
                iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }}
                onPress={onClose}
                top={isWeb ? '8px' : '23%'}
                right="19px"
                position="absolute"
                zIndex="2"
              />
              <Dropdown
                options={options}
                onSelect={id => {
                  onSelect(id);
                  onClose();
                }}
                containerProps={dropdownContainerProps}
                value={value}
                onClose={onClose}
              />
            </Modal.Content>
          </ModalBase>
        )}
      </Box>
    </FormControl>
  );
};

export default Select;
