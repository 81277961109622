import { Text } from 'native-base';
import React, { useMemo } from 'react';

import { ColumnType } from '../Row';

interface IColoredTextOption {
  id: string;
  value: string;
  title: string;
  color: string;
}

export interface IColoredTextColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: ColumnType.COLORED_TEXT;
  options: IColoredTextOption[];
}

interface IColoredTextProps {
  data: any;
  columnDef: IColoredTextColumnDef;
}

export const ColoredText = ({ data, columnDef }: IColoredTextProps) => {
  const option = useMemo<IColoredTextOption>(
    () => columnDef.options.find(item => item.value && item.value === data),
    [data, columnDef],
  );

  if (!option) {
    return null;
  }

  return (
    <Text fontSize="lg" fontWeight="500" color={option.color}>
      {option.title}
    </Text>
  );
};
