import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../ui/src';
import { userSettingsApi } from '../api/src';
import { GetUserResponseUsersDto } from '../client/src';

export const useToggleHideBalanceMutation = () => {
  const { queryKey } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => userSettingsApi.userSettingsControllerToggleHideBalance(),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
      const prevData = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old: GetUserResponseUsersDto) => ({
        ...old,
        settings: {
          ...old.settings,
          hideBalance: !old.settings.hideBalance,
        },
      }));

      return { prevData };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context.prevData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
