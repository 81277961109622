import { Box, HStack, Image, Text, VStack } from 'native-base';
import React from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import {
  useScreenSize,
  useSelectedWalletAccount,
  useWalletConfig,
  useWithdrawEstimate,
} from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { trimCryptoAmountLength } from '@cryptowallet/frontend/utils';

const PaymentInfoBox = ({ label, children, ...rest }) => (
  <Box p="16px" pt={{ base: '4px', lg: '16px' }} {...rest}>
    <Text color="textLabel" fontSize="xl">
      {label}
    </Text>
    <Text color="textRegular" fontSize="xl">
      {children}
    </Text>
  </Box>
);

const WithdrawPaymentInfo = () => {
  const { isMobile } = useScreenSize();
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const { fiatAmount } = useWithdrawEstimate();
  const { isLoading: walletConfigLoading, fiatCurrency } = useWalletConfig();
  const withdrawData = useAccountsStore(state => state.withdrawData);
  const { amount, destinationAddress, destinationTag } = withdrawData;

  const isReady = !walletConfigLoading;

  if (!isReady) return null;

  return (
    <Box borderWidth="1px" borderColor="gray.100" borderRadius="10px">
      <Box flexDirection={destinationTag && !isMobile ? 'row' : 'column'}>
        <PaymentInfoBox
          label="You send"
          borderRightWidth={destinationTag ? '1px' : '0'}
          borderRightColor="gray.100"
          w={destinationTag && !isMobile ? '50%' : '100%'}
          pt="16px">
          <HStack alignItems="center">
            <Image
              source={{
                uri: selectedWalletAccount.asset.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
              }}
              alt={selectedWalletAccount.asset.currency.label}
              h="20px"
              w="20px"
              mr="10px"
            />
            <Text fontSize="xl">
              {amount} {selectedWalletAccount.asset.currency.ticker} ≈ {fiatCurrency.symbol}
              {trimCryptoAmountLength(fiatAmount.toString(), 2)}
            </Text>
          </HStack>
        </PaymentInfoBox>
        {!!destinationTag && (
          <PaymentInfoBox label="Nonce/memo" w={{ base: '100%', lg: '50%' }} pt={{ base: '0', md: '13px' }}>
            <Text fontSize="xl">{destinationTag}</Text>
          </PaymentInfoBox>
        )}
      </Box>
      <VStack borderTopWidth={{ base: '0', lg: '1px' }} borderTopColor="gray.100">
        <PaymentInfoBox label="From your address:" w="100%">
          {selectedWalletAccount.address}
        </PaymentInfoBox>
        <PaymentInfoBox label="To address:" w="100%" pt="3px">
          {destinationAddress}
        </PaymentInfoBox>
      </VStack>
    </Box>
  );
};

export default WithdrawPaymentInfo;
