import React from 'react';

const NavigationReferralInvitedUsersIcon = ({ color, size = '20' }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 4.99999C15 7.76141 12.7614 9.99999 10 9.99999C7.23858 9.99999 5 7.76142 5 4.99999C5 2.23857 7.23858 -1.26563e-06 10 -1.74846e-06C12.7614 -2.23128e-06 15 2.23857 15 4.99999Z"
      fill={color}
    />
    <path
      d="M7.00772 11.25C2.94663 11.9007 2.20149e-06 12.5911 2.60231e-06 14.8835C3.09638e-06 17.7092 3.49691e-06 20 10 20C20 20 20 17.7092 20 14.8835C20 12.591 17.0534 11.9007 12.9923 11.25C12.1579 11.8177 11.1221 12.154 10 12.154C8.87786 12.154 7.84206 11.8177 7.00772 11.25Z"
      fill={color}
    />
  </svg>
);

export default NavigationReferralInvitedUsersIcon;
