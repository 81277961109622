import { Box, Text, VStack } from 'native-base';
import React from 'react';

import { Icon } from '@cryptowallet/frontend/ui';

const BuySellPromo = () => (
  <Box w="100%" h="100%" py="100px" px="24px" flexGrow="1" alignItems="center" justifyContent="center">
    <VStack alignItems="center">
      <Icon name="wallet/sending-to-payout" w="50px" h="80px" mb="32px" />
      <Text fontSize="2xl" color="white" mb="16px" textAlign="center">
        Your recent activity is empty... for now
      </Text>
      <Text fontSize="lg" color="textRegular" mb="32px" textAlign="center">
        Let’s make some transactions to unlock your crypto’s full potential!
      </Text>
    </VStack>
  </Box>
);

export default BuySellPromo;
