import { Box, HStack, Image, Text, VStack } from 'native-base';
import React from 'react';

import { AssetEntityDtoTypeEnum, WalletResourceEntityDto } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { CurrencyNetwork, Pressable, WalletConfigCurrency } from '@cryptowallet/frontend/ui';
import { getNetworkData, maskNumber, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';

interface IAssetListItemProps {
  item: WalletResourceEntityDto;
  fiatCurrency: WalletConfigCurrency;
  onSelectAsset: () => void;
  hideBalance: boolean;
  selected: boolean;
}

const AssetListItem = ({ item, fiatCurrency, onSelectAsset, hideBalance, selected }: IAssetListItemProps) => {
  const {
    asset: { currency, image, network, type },
    cryptoBalance,
    fiatBalance,
    isCreated,
  } = item;

  const { color: networkColor } = getNetworkData(network?.ticker);

  const isCryptoCoin = type === AssetEntityDtoTypeEnum.CryptoCoin;

  return (
    <Pressable
      p="16px"
      outlineWidth="0"
      borderRadius="10px"
      borderWidth="1px"
      borderColor="transparent"
      justifyContent="flex-start"
      alignItems="flex-start"
      _hover={{ bg: { base: 'transparent', xl: 'gray.300' } }}
      _focus={{ borderColor: { base: 'transparent', xl: 'secondary.400' } }}
      onPress={onSelectAsset}>
      {() => (
        <>
          {selected && (
            <Box position="absolute" top="34px" left="3px" h="6px" w="6px" borderRadius="50%" bg="primary.400" />
          )}
          <HStack>
            <Image
              source={{
                uri: image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
              }}
              alt={currency.label}
              w="40px"
              h="40px"
              mr="16px"
              borderRadius="40px"
            />
            <VStack>
              <Box>
                <Text fontSize="xl" lineHeight="18px" color="white">
                  {hideBalance ? maskNumber(8) : trimCryptoAmountLength(isCreated ? cryptoBalance : '0', 6)}{' '}
                  {currency.ticker}
                </Text>
                <Text fontSize="md" lineHeight="14px" fontWeight="500" color="textRegular">
                  {fiatCurrency.symbol} {hideBalance ? maskNumber(2) : trimCryptoAmountLength(fiatBalance, 2)}
                </Text>
                <Text mt="4px" fontSize="md" lineHeight="14px" color="textLabel">
                  {currency.label}
                </Text>
              </Box>
            </VStack>
          </HStack>
          {!!network?.label && !isCryptoCoin && (
            <CurrencyNetwork position="absolute" right="16px" top="31px" color={networkColor} label={network?.label} />
          )}
        </>
      )}
    </Pressable>
  );
};

export default AssetListItem;
