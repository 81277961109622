/* tslint:disable */
/* eslint-disable */
/**
 * api app
 * api app
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptNewTermsPayloadUsersDto
 */
export interface AcceptNewTermsPayloadUsersDto {
    /**
     * 
     * @type {boolean}
     * @memberof AcceptNewTermsPayloadUsersDto
     */
    'acceptNewTerms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptNewTermsPayloadUsersDto
     */
    'acceptNewPrivacyPolicy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptNewTermsPayloadUsersDto
     */
    'acceptTransferOfPersonalData': boolean;
}
/**
 * 
 * @export
 * @interface AccountDetailsAssetCurrencyDto
 */
export interface AccountDetailsAssetCurrencyDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetCurrencyDto
     */
    'id': string;
    /**
     * 
     * @type {string | Null}
     * @memberof AccountDetailsAssetCurrencyDto
     */
    'label': string | Null;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetCurrencyDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetCurrencyDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetCurrencyDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AccountDetailsAssetDto
 */
export interface AccountDetailsAssetDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetDto
     */
    'type': string;
    /**
     * 
     * @type {string | Null}
     * @memberof AccountDetailsAssetDto
     */
    'image': string | Null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsAssetDto
     */
    'decimals': number;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {AccountDetailsAssetCurrencyDto}
     * @memberof AccountDetailsAssetDto
     */
    'currency': AccountDetailsAssetCurrencyDto;
    /**
     * 
     * @type {AccountDetailsAssetNetworkDto}
     * @memberof AccountDetailsAssetDto
     */
    'network'?: AccountDetailsAssetNetworkDto;
}
/**
 * 
 * @export
 * @interface AccountDetailsAssetNetworkDto
 */
export interface AccountDetailsAssetNetworkDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetNetworkDto
     */
    'id': string;
    /**
     * 
     * @type {string | Null}
     * @memberof AccountDetailsAssetNetworkDto
     */
    'label': string | Null;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetNetworkDto
     */
    'ticker': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsAssetNetworkDto
     */
    'hasDestinationTag': boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetNetworkDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsAssetNetworkDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AccountDetailsExchangerAssetDto
 */
export interface AccountDetailsExchangerAssetDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'assetId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'isBuy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'isSell': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'isSwap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {number | Null}
     * @memberof AccountDetailsExchangerAssetDto
     */
    'sortOrder': number | Null;
}
/**
 * 
 * @export
 * @interface AccountDetailsWalletAccountDto
 */
export interface AccountDetailsWalletAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletAccountDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletAccountDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletAccountDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AccountDetailsWalletAssetDto
 */
export interface AccountDetailsWalletAssetDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletAssetDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsWalletAssetDto
     */
    'isDepositEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDetailsWalletAssetDto
     */
    'isWithdrawalEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletAssetDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletAssetDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AccountDetailsWalletDto
 */
export interface AccountDetailsWalletDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletDto
     */
    'cryptoBalance': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsWalletDto
     */
    'fiatBalance': string;
    /**
     * 
     * @type {string | Null}
     * @memberof AccountDetailsWalletDto
     */
    'address': string | Null;
    /**
     * 
     * @type {string | Null}
     * @memberof AccountDetailsWalletDto
     */
    'tag': string | Null;
    /**
     * 
     * @type {AccountDetailsWalletAccountDto}
     * @memberof AccountDetailsWalletDto
     */
    'walletAccount': AccountDetailsWalletAccountDto;
    /**
     * 
     * @type {AccountDetailsWalletAssetDto}
     * @memberof AccountDetailsWalletDto
     */
    'walletAsset': AccountDetailsWalletAssetDto;
    /**
     * 
     * @type {AccountDetailsAssetDto}
     * @memberof AccountDetailsWalletDto
     */
    'asset': AccountDetailsAssetDto;
    /**
     * 
     * @type {Array<AccountDetailsExchangerAssetDto>}
     * @memberof AccountDetailsWalletDto
     */
    'exchangerAssets': Array<AccountDetailsExchangerAssetDto>;
}
/**
 * 
 * @export
 * @interface AddRecommendedWalletAssetsPayloadUserSettingsDto
 */
export interface AddRecommendedWalletAssetsPayloadUserSettingsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddRecommendedWalletAssetsPayloadUserSettingsDto
     */
    'assetIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AssetCurrencyEntityDto
 */
export interface AssetCurrencyEntityDto {
    /**
     * 
     * @type {string}
     * @memberof AssetCurrencyEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string | Null}
     * @memberof AssetCurrencyEntityDto
     */
    'label': string | Null;
    /**
     * 
     * @type {string}
     * @memberof AssetCurrencyEntityDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCurrencyEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCurrencyEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AssetEntityDto
 */
export interface AssetEntityDto {
    /**
     * 
     * @type {string}
     * @memberof AssetEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetEntityDto
     */
    'type': AssetEntityDtoTypeEnum;
    /**
     * 
     * @type {string | Null}
     * @memberof AssetEntityDto
     */
    'image': string | Null;
    /**
     * 
     * @type {number}
     * @memberof AssetEntityDto
     */
    'decimals': number;
    /**
     * 
     * @type {string}
     * @memberof AssetEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AssetEntityDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {AssetCurrencyEntityDto}
     * @memberof AssetEntityDto
     */
    'currency': AssetCurrencyEntityDto;
    /**
     * 
     * @type {AssetNetworkEntityDto}
     * @memberof AssetEntityDto
     */
    'network'?: AssetNetworkEntityDto;
}

export const AssetEntityDtoTypeEnum = {
    CryptoCoin: 'CRYPTO_COIN',
    CryptoToken: 'CRYPTO_TOKEN',
    Fiat: 'FIAT'
} as const;

export type AssetEntityDtoTypeEnum = typeof AssetEntityDtoTypeEnum[keyof typeof AssetEntityDtoTypeEnum];

/**
 * 
 * @export
 * @interface AssetNetworkEntityDto
 */
export interface AssetNetworkEntityDto {
    /**
     * 
     * @type {string}
     * @memberof AssetNetworkEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string | Null}
     * @memberof AssetNetworkEntityDto
     */
    'label': string | Null;
    /**
     * 
     * @type {string}
     * @memberof AssetNetworkEntityDto
     */
    'ticker': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetNetworkEntityDto
     */
    'hasDestinationTag': boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetNetworkEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AssetNetworkEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthorizeCase = {
    Registration: 'REGISTRATION',
    Login: 'LOGIN'
} as const;

export type AuthorizeCase = typeof AuthorizeCase[keyof typeof AuthorizeCase];


/**
 * 
 * @export
 * @interface AuthorizeUserBadRequestResponseUsersDto
 */
export interface AuthorizeUserBadRequestResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeUserBadRequestResponseUsersDto
     */
    'error': AuthorizeUserBadRequestResponseUsersDtoErrorEnum;
}

export const AuthorizeUserBadRequestResponseUsersDtoErrorEnum = {
    BadRequest: 'BAD_REQUEST',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    CaptchaInvalid: 'CAPTCHA_INVALID'
} as const;

export type AuthorizeUserBadRequestResponseUsersDtoErrorEnum = typeof AuthorizeUserBadRequestResponseUsersDtoErrorEnum[keyof typeof AuthorizeUserBadRequestResponseUsersDtoErrorEnum];

/**
 * 
 * @export
 * @interface AuthorizeUserPayloadUsersDto
 */
export interface AuthorizeUserPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeUserPayloadUsersDto
     */
    'email': string;
    /**
     * 
     * @type {GeetestClientValidationResponseDto}
     * @memberof AuthorizeUserPayloadUsersDto
     */
    'captcha': GeetestClientValidationResponseDto;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizeUserPayloadUsersDto
     */
    'noMagicLink'?: boolean;
    /**
     * 
     * @type {AuthorizeCase}
     * @memberof AuthorizeUserPayloadUsersDto
     */
    'mode'?: AuthorizeCase;
}
/**
 * 
 * @export
 * @interface AuthorizeUserResponseUsersDto
 */
export interface AuthorizeUserResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeUserResponseUsersDto
     */
    'authorizeCase': AuthorizeUserResponseUsersDtoAuthorizeCaseEnum;
}

export const AuthorizeUserResponseUsersDtoAuthorizeCaseEnum = {
    Registration: 'REGISTRATION',
    Login: 'LOGIN'
} as const;

export type AuthorizeUserResponseUsersDtoAuthorizeCaseEnum = typeof AuthorizeUserResponseUsersDtoAuthorizeCaseEnum[keyof typeof AuthorizeUserResponseUsersDtoAuthorizeCaseEnum];

/**
 * 
 * @export
 * @interface ChangeEmailBadRequestResponseUsersDto
 */
export interface ChangeEmailBadRequestResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailBadRequestResponseUsersDto
     */
    'error': ChangeEmailBadRequestResponseUsersDtoErrorEnum;
}

export const ChangeEmailBadRequestResponseUsersDtoErrorEnum = {
    NewEmailOtpInvalid: 'NEW_EMAIL_OTP_INVALID',
    EmailIsAlreadyTaken: 'EMAIL_IS_ALREADY_TAKEN',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    ServiceUnavailable: 'SERVICE_UNAVAILABLE'
} as const;

export type ChangeEmailBadRequestResponseUsersDtoErrorEnum = typeof ChangeEmailBadRequestResponseUsersDtoErrorEnum[keyof typeof ChangeEmailBadRequestResponseUsersDtoErrorEnum];

/**
 * 
 * @export
 * @interface ChangeEmailPayloadUsersDto
 */
export interface ChangeEmailPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailPayloadUsersDto
     */
    'emailOtpToken': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailPayloadUsersDto
     */
    'totpToken': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailPayloadUsersDto
     */
    'newEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailPayloadUsersDto
     */
    'newEmailOtpToken': string;
}
/**
 * 
 * @export
 * @interface CreateExchangeBadRequestDto
 */
export interface CreateExchangeBadRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateExchangeBadRequestDto
     */
    'error': CreateExchangeBadRequestDtoErrorEnum;
}

export const CreateExchangeBadRequestDtoErrorEnum = {
    WalletAddressIncorrect: 'WALLET_ADDRESS_INCORRECT',
    WalletAddressIsHighRisk: 'WALLET_ADDRESS_IS_HIGH_RISK',
    IbanNameMismatch: 'IBAN_NAME_MISMATCH',
    LimitExceeded: 'LIMIT_EXCEEDED',
    CantFindAndCreateWalletAccount: 'CANT_FIND_AND_CREATE_WALLET_ACCOUNT',
    PaymentMethodIsDisabled: 'PAYMENT_METHOD_IS_DISABLED',
    IbanSepaUnreachable: 'IBAN_SEPA_UNREACHABLE',
    IbanInvalid: 'IBAN_INVALID',
    CantFindOrCreateWalletAccount: 'CANT_FIND_OR_CREATE_WALLET_ACCOUNT',
    InsufficientBalance: 'INSUFFICIENT_BALANCE'
} as const;

export type CreateExchangeBadRequestDtoErrorEnum = typeof CreateExchangeBadRequestDtoErrorEnum[keyof typeof CreateExchangeBadRequestDtoErrorEnum];

/**
 * 
 * @export
 * @interface CreateSwapTransactionPayloadWalletsDto
 */
export interface CreateSwapTransactionPayloadWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSwapTransactionPayloadWalletsDto
     */
    'fromWalletAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSwapTransactionPayloadWalletsDto
     */
    'toWalletAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSwapTransactionPayloadWalletsDto
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface CreateSwapTransactionResponseWalletsDto
 */
export interface CreateSwapTransactionResponseWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSwapTransactionResponseWalletsDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateWalletAccountPayloadWalletsDto
 */
export interface CreateWalletAccountPayloadWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWalletAccountPayloadWalletsDto
     */
    'walletAssetId': string;
}
/**
 * 
 * @export
 * @interface CreateWalletAccountResponseWalletsDto
 */
export interface CreateWalletAccountResponseWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWalletAccountResponseWalletsDto
     */
    'walletAccountId': string;
}
/**
 * 
 * @export
 * @interface CreateWalletTransactionBadRequestDto
 */
export interface CreateWalletTransactionBadRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionBadRequestDto
     */
    'error': CreateWalletTransactionBadRequestDtoErrorEnum;
}

export const CreateWalletTransactionBadRequestDtoErrorEnum = {
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    WalletAddressIncorrect: 'WALLET_ADDRESS_INCORRECT',
    CantFindOrCreateWalletAccount: 'CANT_FIND_OR_CREATE_WALLET_ACCOUNT',
    WalletAccountNotFound: 'WALLET_ACCOUNT_NOT_FOUND',
    InsufficientBalanceToCoverNetworkFee: 'INSUFFICIENT_BALANCE_TO_COVER_NETWORK_FEE',
    WalletAddressHighRisk: 'WALLET_ADDRESS_HIGH_RISK'
} as const;

export type CreateWalletTransactionBadRequestDtoErrorEnum = typeof CreateWalletTransactionBadRequestDtoErrorEnum[keyof typeof CreateWalletTransactionBadRequestDtoErrorEnum];

/**
 * 
 * @export
 * @interface CreateWalletTransactionPayloadWalletsDto
 */
export interface CreateWalletTransactionPayloadWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'emailOtpToken': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'totpToken': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'walletAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletTransactionPayloadWalletsDto
     */
    'walletFeeLevel'?: CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum;
}

export const CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum = {
    High: 'HIGH',
    Medium: 'MEDIUM',
    Low: 'LOW'
} as const;

export type CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum = typeof CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum[keyof typeof CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum];

/**
 * 
 * @export
 * @interface DeactivateMeFaPayloadUsersDto
 */
export interface DeactivateMeFaPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof DeactivateMeFaPayloadUsersDto
     */
    'emailOtpToken': string;
    /**
     * 
     * @type {string}
     * @memberof DeactivateMeFaPayloadUsersDto
     */
    'totpToken': string;
}
/**
 * 
 * @export
 * @interface EmulatePermissionPoaRequestDto
 */
export interface EmulatePermissionPoaRequestDto {
    /**
     * Town name
     * @type {string}
     * @memberof EmulatePermissionPoaRequestDto
     */
    'town'?: string;
    /**
     * Country in alpha-3
     * @type {string}
     * @memberof EmulatePermissionPoaRequestDto
     */
    'countryAlpha3'?: string;
}
/**
 * 
 * @export
 * @interface EmulateSellPayloadRequestDto
 */
export interface EmulateSellPayloadRequestDto {
    /**
     * Payin address. For KYT testing, it must be the receiving side of the transaction with the provided payin hash
     * @type {string}
     * @memberof EmulateSellPayloadRequestDto
     */
    'payinAddress'?: string;
    /**
     * Hash of the actual transaction, that will be treated as crypto payin to the specified payin address
     * @type {string}
     * @memberof EmulateSellPayloadRequestDto
     */
    'payinHash'?: string;
    /**
     * Amount
     * @type {number}
     * @memberof EmulateSellPayloadRequestDto
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface EmulateSellPayloadResponseDto
 */
export interface EmulateSellPayloadResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof EmulateSellPayloadResponseDto
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmulateSellPayloadResponseDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface EmulateSepaPayinPayloadRequestDto
 */
export interface EmulateSepaPayinPayloadRequestDto {
    /**
     * Payin Amount
     * @type {number}
     * @memberof EmulateSepaPayinPayloadRequestDto
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface EmulateUnlimintPayinPayloadRequestDto
 */
export interface EmulateUnlimintPayinPayloadRequestDto {
    /**
     * Payin Amount
     * @type {number}
     * @memberof EmulateUnlimintPayinPayloadRequestDto
     */
    'amount': number;
    /**
     * Card Holder
     * @type {string}
     * @memberof EmulateUnlimintPayinPayloadRequestDto
     */
    'cardHolder': string;
    /**
     * Masked Card Number
     * @type {string}
     * @memberof EmulateUnlimintPayinPayloadRequestDto
     */
    'maskedPan': string;
}
/**
 * 
 * @export
 * @interface EstimateSwapTransactionBadRequestDto
 */
export interface EstimateSwapTransactionBadRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionBadRequestDto
     */
    'error': EstimateSwapTransactionBadRequestDtoErrorEnum;
}

export const EstimateSwapTransactionBadRequestDtoErrorEnum = {
    InsufficientBalanceinsufficientBalancewalletAddressIncorrectwalletAddressIncorrectcantFindOrCreateWalletAccountcantFindOrCreateWalletAccountwalletAccountNotFoundwalletAccountNotFoundinsufficientBalanceToCoverNetworkFeeinsufficientBalanceToCoverNetworkFeewalletAddressHighRiskwalletAddressHighRisk: '{\"INSUFFICIENT_BALANCE\":\"INSUFFICIENT_BALANCE\",\"WALLET_ADDRESS_INCORRECT\":\"WALLET_ADDRESS_INCORRECT\",\"CANT_FIND_OR_CREATE_WALLET_ACCOUNT\":\"CANT_FIND_OR_CREATE_WALLET_ACCOUNT\",\"WALLET_ACCOUNT_NOT_FOUND\":\"WALLET_ACCOUNT_NOT_FOUND\",\"INSUFFICIENT_BALANCE_TO_COVER_NETWORK_FEE\":\"INSUFFICIENT_BALANCE_TO_COVER_NETWORK_FEE\",\"WALLET_ADDRESS_HIGH_RISK\":\"WALLET_ADDRESS_HIGH_RISK\"}',
    DepositTooSmall: 'deposit_too_small',
    PairIsInactive: 'pair_is_inactive',
    InsufficientBalance: 'INSUFFICIENT_BALANCE'
} as const;

export type EstimateSwapTransactionBadRequestDtoErrorEnum = typeof EstimateSwapTransactionBadRequestDtoErrorEnum[keyof typeof EstimateSwapTransactionBadRequestDtoErrorEnum];

/**
 * 
 * @export
 * @interface EstimateSwapTransactionPayloadWalletsDto
 */
export interface EstimateSwapTransactionPayloadWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionPayloadWalletsDto
     */
    'fromWalletAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionPayloadWalletsDto
     */
    'toWalletAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionPayloadWalletsDto
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface EstimateSwapTransactionResponseWalletsDto
 */
export interface EstimateSwapTransactionResponseWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'networkFee': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'equivalentAmount': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'rate': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'minAmount': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'maxAmount': string;
    /**
     * 
     * @type {boolean}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'isBalanceEnough': boolean;
    /**
     * 
     * @type {string}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'walletBalanceInsufficientionReason'?: EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof EstimateSwapTransactionResponseWalletsDto
     */
    'feeAsset'?: AssetEntityDto;
}

export const EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum = {
    AmountMustBeGreaterThanZero: 'AMOUNT_MUST_BE_GREATER_THAN_ZERO',
    InsufficientBalanceForCoinTransaction: 'INSUFFICIENT_BALANCE_FOR_COIN_TRANSACTION',
    InsufficientTokenBalanceForTokenTransaction: 'INSUFFICIENT_TOKEN_BALANCE_FOR_TOKEN_TRANSACTION',
    InsufficientCoinBalanceForTokenTransaction: 'INSUFFICIENT_COIN_BALANCE_FOR_TOKEN_TRANSACTION',
    CouldNotReceiveNetworkFee: 'COULD_NOT_RECEIVE_NETWORK_FEE',
    Unknown: 'UNKNOWN'
} as const;

export type EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum = typeof EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum[keyof typeof EstimateSwapTransactionResponseWalletsDtoWalletBalanceInsufficientionReasonEnum];

/**
 * 
 * @export
 * @interface EstimateWalletTransactionPayloadWalletsDto
 */
export interface EstimateWalletTransactionPayloadWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof EstimateWalletTransactionPayloadWalletsDto
     */
    'type': EstimateWalletTransactionPayloadWalletsDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EstimateWalletTransactionPayloadWalletsDto
     */
    'fromAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof EstimateWalletTransactionPayloadWalletsDto
     */
    'amount': string;
}

export const EstimateWalletTransactionPayloadWalletsDtoTypeEnum = {
    CryptoToFiat: 'CRYPTO_TO_FIAT',
    FiatToCrypto: 'FIAT_TO_CRYPTO'
} as const;

export type EstimateWalletTransactionPayloadWalletsDtoTypeEnum = typeof EstimateWalletTransactionPayloadWalletsDtoTypeEnum[keyof typeof EstimateWalletTransactionPayloadWalletsDtoTypeEnum];

/**
 * 
 * @export
 * @interface EstimateWalletTransactionResponseWalletsDto
 */
export interface EstimateWalletTransactionResponseWalletsDto {
    /**
     * 
     * @type {WalletEstimationTransactionEntityDto}
     * @memberof EstimateWalletTransactionResponseWalletsDto
     */
    'walletEstimationTransaction': WalletEstimationTransactionEntityDto;
}
/**
 * 
 * @export
 * @interface EventRequestDto
 */
export interface EventRequestDto {
    /**
     * Event name
     * @type {string}
     * @memberof EventRequestDto
     */
    'eventName': EventRequestDtoEventNameEnum;
    /**
     * Client ID
     * @type {string}
     * @memberof EventRequestDto
     */
    'clientId': string;
    /**
     * Meta data object with additional information
     * @type {object}
     * @memberof EventRequestDto
     */
    'metaData'?: object;
}

export const EventRequestDtoEventNameEnum = {
    AuthorizeEmailInputSuccess: 'authorize_email_input_success',
    CaptchaSlideSuccess: 'captcha_slide_success',
    TermsAndConditionsAccepted: 'terms_and_conditions_accepted',
    RegistrationDeclineButtonSuccess: 'registration_decline_button_success',
    KycCancelButtonSuccess: 'kyc_cancel_button_success',
    PoaCancelButtonSuccess: 'poa_cancel_button_success',
    PowCancelButtonSuccess: 'pow_cancel_button_success',
    ChatStart: 'chat_start',
    CurrencyChange: 'currency_change',
    RegistrationAccountCreatedRestrictCountryButton: 'registration_account_created_restrict_country_button'
} as const;

export type EventRequestDtoEventNameEnum = typeof EventRequestDtoEventNameEnum[keyof typeof EventRequestDtoEventNameEnum];

/**
 * 
 * @export
 * @interface ExchangeRecipientDetailsDto
 */
export interface ExchangeRecipientDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRecipientDetailsDto
     */
    'toAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeRecipientDetailsDto
     */
    'useMyWalletAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRecipientDetailsDto
     */
    'destinationTag'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExchangeType = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type ExchangeType = typeof ExchangeType[keyof typeof ExchangeType];


/**
 * 
 * @export
 * @interface ExchangerAssetEntityDto
 */
export interface ExchangerAssetEntityDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerAssetEntityDto
     */
    'isBuy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerAssetEntityDto
     */
    'isSell': boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'type': ExchangerAssetEntityDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangerAssetEntityDto
     */
    'decimals': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'networkLabel'?: string;
    /**
     * 
     * @type {ExchangerAssetNetworkEntityDto}
     * @memberof ExchangerAssetEntityDto
     */
    'network'?: ExchangerAssetNetworkEntityDto;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'assetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'walletAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetEntityDto
     */
    'walletAccountId': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangerAssetEntityDto
     */
    'sortOrder'?: number;
}

export const ExchangerAssetEntityDtoTypeEnum = {
    CryptoCoin: 'CRYPTO_COIN',
    CryptoToken: 'CRYPTO_TOKEN',
    Fiat: 'FIAT'
} as const;

export type ExchangerAssetEntityDtoTypeEnum = typeof ExchangerAssetEntityDtoTypeEnum[keyof typeof ExchangerAssetEntityDtoTypeEnum];

/**
 * 
 * @export
 * @interface ExchangerAssetNetworkEntityDto
 */
export interface ExchangerAssetNetworkEntityDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetNetworkEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetNetworkEntityDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerAssetNetworkEntityDto
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerAssetNetworkEntityDto
     */
    'hasDestinationTag': boolean;
}
/**
 * 
 * @export
 * @interface ExchangerCreateExchangeRequestDto
 */
export interface ExchangerCreateExchangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'fromExchangerAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'toExchangerAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'type': ExchangerCreateExchangeRequestDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'pspType': ExchangerCreateExchangeRequestDtoPspTypeEnum;
    /**
     * 
     * @type {ExchangeRecipientDetailsDto}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'recipientDetails': ExchangeRecipientDetailsDto;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'useMyWallet'?: boolean;
    /**
     * Partner ID
     * @type {string}
     * @memberof ExchangerCreateExchangeRequestDto
     */
    'partnerId'?: string;
}

export const ExchangerCreateExchangeRequestDtoTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type ExchangerCreateExchangeRequestDtoTypeEnum = typeof ExchangerCreateExchangeRequestDtoTypeEnum[keyof typeof ExchangerCreateExchangeRequestDtoTypeEnum];
export const ExchangerCreateExchangeRequestDtoPspTypeEnum = {
    Sepa: 'SEPA',
    Card: 'CARD'
} as const;

export type ExchangerCreateExchangeRequestDtoPspTypeEnum = typeof ExchangerCreateExchangeRequestDtoPspTypeEnum[keyof typeof ExchangerCreateExchangeRequestDtoPspTypeEnum];

/**
 * 
 * @export
 * @interface ExchangerCreateExchangeResponseDto
 */
export interface ExchangerCreateExchangeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerCreateExchangeResponseDto
     */
    'exchangeId'?: string;
}
/**
 * 
 * @export
 * @interface ExchangerEstimateRequestDto
 */
export interface ExchangerEstimateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerEstimateRequestDto
     */
    'fromExchangerAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerEstimateRequestDto
     */
    'toExchangerAssetId': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimateRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExchangerEstimateRequestDto
     */
    'pspTypes': Array<ExchangerEstimateRequestDtoPspTypesEnum>;
}

export const ExchangerEstimateRequestDtoPspTypesEnum = {
    Sepa: 'SEPA',
    Card: 'CARD'
} as const;

export type ExchangerEstimateRequestDtoPspTypesEnum = typeof ExchangerEstimateRequestDtoPspTypesEnum[keyof typeof ExchangerEstimateRequestDtoPspTypesEnum];

/**
 * 
 * @export
 * @interface ExchangerEstimateResponseDto
 */
export interface ExchangerEstimateResponseDto {
    /**
     * 
     * @type {ExchangerEstimationEntityDto}
     * @memberof ExchangerEstimateResponseDto
     */
    'CARD': ExchangerEstimationEntityDto;
    /**
     * 
     * @type {ExchangerEstimationEntityDto}
     * @memberof ExchangerEstimateResponseDto
     */
    'SEPA': ExchangerEstimationEntityDto;
}
/**
 * 
 * @export
 * @interface ExchangerEstimationEntityDto
 */
export interface ExchangerEstimationEntityDto {
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'amountMin': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'amountMax': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'bankFeeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'serviceFeeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'networkFeeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'estimatedAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerEstimationEntityDto
     */
    'estimatedRate': number;
}
/**
 * 
 * @export
 * @interface ExchangerExchangeListResponseDto
 */
export interface ExchangerExchangeListResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ExchangerExchangeListResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerExchangeListResponseDto
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerExchangeListResponseDto
     */
    'limit': number;
    /**
     * 
     * @type {ExchangerWithAssetsPairExchangeResponseDto}
     * @memberof ExchangerExchangeListResponseDto
     */
    'items': ExchangerWithAssetsPairExchangeResponseDto;
}
/**
 * 
 * @export
 * @interface ExchangerExchangeResponseDto
 */
export interface ExchangerExchangeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'status': ExchangerExchangeResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'type': ExchangerExchangeResponseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'fromExchangerAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'toExchangerAssetId': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangerExchangeResponseDto
     */
    'expectedFromAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerExchangeResponseDto
     */
    'fromAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerExchangeResponseDto
     */
    'toAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'pspType': ExchangerExchangeResponseDtoPspTypeEnum;
    /**
     * 
     * @type {PayInEntityDto}
     * @memberof ExchangerExchangeResponseDto
     */
    'payIn': PayInEntityDto;
    /**
     * 
     * @type {PayOutEntityDto}
     * @memberof ExchangerExchangeResponseDto
     */
    'payOut': PayOutEntityDto;
    /**
     * 
     * @type {ExchangerEstimationEntityDto}
     * @memberof ExchangerExchangeResponseDto
     */
    'estimate': ExchangerEstimationEntityDto;
    /**
     * 
     * @type {string}
     * @memberof ExchangerExchangeResponseDto
     */
    'partnerId': string;
}

export const ExchangerExchangeResponseDtoStatusEnum = {
    Created: 'CREATED',
    Pending: 'PENDING',
    Done: 'DONE',
    Blocked: 'BLOCKED',
    Canceled: 'CANCELED'
} as const;

export type ExchangerExchangeResponseDtoStatusEnum = typeof ExchangerExchangeResponseDtoStatusEnum[keyof typeof ExchangerExchangeResponseDtoStatusEnum];
export const ExchangerExchangeResponseDtoTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type ExchangerExchangeResponseDtoTypeEnum = typeof ExchangerExchangeResponseDtoTypeEnum[keyof typeof ExchangerExchangeResponseDtoTypeEnum];
export const ExchangerExchangeResponseDtoPspTypeEnum = {
    Sepa: 'SEPA',
    Card: 'CARD'
} as const;

export type ExchangerExchangeResponseDtoPspTypeEnum = typeof ExchangerExchangeResponseDtoPspTypeEnum[keyof typeof ExchangerExchangeResponseDtoPspTypeEnum];

/**
 * 
 * @export
 * @interface ExchangerGetAssetsResponseDto
 */
export interface ExchangerGetAssetsResponseDto {
    /**
     * 
     * @type {Array<ExchangerAssetEntityDto>}
     * @memberof ExchangerGetAssetsResponseDto
     */
    'assets': Array<ExchangerAssetEntityDto>;
}
/**
 * 
 * @export
 * @interface ExchangerGetLimitsBuyDto
 */
export interface ExchangerGetLimitsBuyDto {
    /**
     * 
     * @type {LimitsResultDto}
     * @memberof ExchangerGetLimitsBuyDto
     */
    'CARD': LimitsResultDto;
    /**
     * 
     * @type {LimitsResultDto}
     * @memberof ExchangerGetLimitsBuyDto
     */
    'SEPA': LimitsResultDto;
}
/**
 * 
 * @export
 * @interface ExchangerGetLimitsResponseDto
 */
export interface ExchangerGetLimitsResponseDto {
    /**
     * 
     * @type {ExchangerGetLimitsBuyDto}
     * @memberof ExchangerGetLimitsResponseDto
     */
    'BUY': ExchangerGetLimitsBuyDto;
    /**
     * 
     * @type {ExchangerGetLimitsSellDto}
     * @memberof ExchangerGetLimitsResponseDto
     */
    'SELL': ExchangerGetLimitsSellDto;
}
/**
 * 
 * @export
 * @interface ExchangerGetLimitsSellDto
 */
export interface ExchangerGetLimitsSellDto {
    /**
     * 
     * @type {LimitsResultDto}
     * @memberof ExchangerGetLimitsSellDto
     */
    'SEPA': LimitsResultDto;
}
/**
 * 
 * @export
 * @interface ExchangerWithAssetsPairExchangeResponseDto
 */
export interface ExchangerWithAssetsPairExchangeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'status': ExchangerWithAssetsPairExchangeResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'type': ExchangerWithAssetsPairExchangeResponseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'fromExchangerAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'toExchangerAssetId': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'expectedFromAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'fromAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'toAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'pspType': ExchangerWithAssetsPairExchangeResponseDtoPspTypeEnum;
    /**
     * 
     * @type {PayInEntityDto}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'payIn': PayInEntityDto;
    /**
     * 
     * @type {PayOutEntityDto}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'payOut': PayOutEntityDto;
    /**
     * 
     * @type {ExchangerEstimationEntityDto}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'estimate': ExchangerEstimationEntityDto;
    /**
     * 
     * @type {string}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'partnerId': string;
    /**
     * 
     * @type {Array<AssetEntityDto>}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'fromAsset': Array<AssetEntityDto>;
    /**
     * 
     * @type {Array<AssetEntityDto>}
     * @memberof ExchangerWithAssetsPairExchangeResponseDto
     */
    'toAsset': Array<AssetEntityDto>;
}

export const ExchangerWithAssetsPairExchangeResponseDtoStatusEnum = {
    Created: 'CREATED',
    Pending: 'PENDING',
    Done: 'DONE',
    Blocked: 'BLOCKED',
    Canceled: 'CANCELED'
} as const;

export type ExchangerWithAssetsPairExchangeResponseDtoStatusEnum = typeof ExchangerWithAssetsPairExchangeResponseDtoStatusEnum[keyof typeof ExchangerWithAssetsPairExchangeResponseDtoStatusEnum];
export const ExchangerWithAssetsPairExchangeResponseDtoTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type ExchangerWithAssetsPairExchangeResponseDtoTypeEnum = typeof ExchangerWithAssetsPairExchangeResponseDtoTypeEnum[keyof typeof ExchangerWithAssetsPairExchangeResponseDtoTypeEnum];
export const ExchangerWithAssetsPairExchangeResponseDtoPspTypeEnum = {
    Sepa: 'SEPA',
    Card: 'CARD'
} as const;

export type ExchangerWithAssetsPairExchangeResponseDtoPspTypeEnum = typeof ExchangerWithAssetsPairExchangeResponseDtoPspTypeEnum[keyof typeof ExchangerWithAssetsPairExchangeResponseDtoPspTypeEnum];

/**
 * 
 * @export
 * @interface ForbiddenExceptionDto
 */
export interface ForbiddenExceptionDto {
    /**
     * 
     * @type {boolean}
     * @memberof ForbiddenExceptionDto
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ForbiddenExceptionDto
     */
    'error': ForbiddenExceptionDtoErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof ForbiddenExceptionDto
     */
    'userPermissionType': ForbiddenExceptionDtoUserPermissionTypeEnum;
    /**
     * 
     * @type {UserPermissionDataEntityDto}
     * @memberof ForbiddenExceptionDto
     */
    'userPermissionData'?: UserPermissionDataEntityDto;
}

export const ForbiddenExceptionDtoErrorEnum = {
    UserBlocked: 'USER_BLOCKED',
    NotPermitted: 'NOT_PERMITTED',
    UserSuspended: 'USER_SUSPENDED'
} as const;

export type ForbiddenExceptionDtoErrorEnum = typeof ForbiddenExceptionDtoErrorEnum[keyof typeof ForbiddenExceptionDtoErrorEnum];
export const ForbiddenExceptionDtoUserPermissionTypeEnum = {
    Kyc: 'KYC',
    Poa: 'POA',
    Liveness: 'LIVENESS',
    Cardverification: 'CARDVERIFICATION'
} as const;

export type ForbiddenExceptionDtoUserPermissionTypeEnum = typeof ForbiddenExceptionDtoUserPermissionTypeEnum[keyof typeof ForbiddenExceptionDtoUserPermissionTypeEnum];

/**
 * 
 * @export
 * @interface GeetestClientValidationResponseDto
 */
export interface GeetestClientValidationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GeetestClientValidationResponseDto
     */
    'captcha_id': string;
    /**
     * 
     * @type {string}
     * @memberof GeetestClientValidationResponseDto
     */
    'captcha_output': string;
    /**
     * 
     * @type {string}
     * @memberof GeetestClientValidationResponseDto
     */
    'gen_time': string;
    /**
     * 
     * @type {string}
     * @memberof GeetestClientValidationResponseDto
     */
    'lot_number': string;
    /**
     * 
     * @type {string}
     * @memberof GeetestClientValidationResponseDto
     */
    'pass_token': string;
}
/**
 * 
 * @export
 * @interface GetClientSystemSettingsResponseDto
 */
export interface GetClientSystemSettingsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetClientSystemSettingsResponseDto
     */
    'exchangerDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientSystemSettingsResponseDto
     */
    'sellFlowDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientSystemSettingsResponseDto
     */
    'swapDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientSystemSettingsResponseDto
     */
    'systemIsUnderMaintenance': boolean;
}
/**
 * 
 * @export
 * @interface GetPartnerSettingsResponseDto
 */
export interface GetPartnerSettingsResponseDto {
    /**
     * 
     * @type {object}
     * @memberof GetPartnerSettingsResponseDto
     */
    'styles': object;
    /**
     * 
     * @type {object}
     * @memberof GetPartnerSettingsResponseDto
     */
    'css': object;
    /**
     * 
     * @type {object}
     * @memberof GetPartnerSettingsResponseDto
     */
    'palette': object;
}
/**
 * 
 * @export
 * @interface GetReferralProgramRulesResponseDto
 */
export interface GetReferralProgramRulesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetReferralProgramRulesResponseDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface GetSwapTransactionResponseWalletsDto
 */
export interface GetSwapTransactionResponseWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'fromAmount': string;
    /**
     * 
     * @type {object}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'toAmount': object;
    /**
     * 
     * @type {string}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'fromWalletAssetId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'toWalletAssetId': string;
    /**
     * 
     * @type {object}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'payOutTxHash': object;
    /**
     * 
     * @type {string}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'status': GetSwapTransactionResponseWalletsDtoStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'networkFee': object;
    /**
     * 
     * @type {object}
     * @memberof GetSwapTransactionResponseWalletsDto
     */
    'rate': object;
}

export const GetSwapTransactionResponseWalletsDtoStatusEnum = {
    Created: 'CREATED',
    Pending: 'PENDING',
    Done: 'DONE',
    Cancelled: 'CANCELLED'
} as const;

export type GetSwapTransactionResponseWalletsDtoStatusEnum = typeof GetSwapTransactionResponseWalletsDtoStatusEnum[keyof typeof GetSwapTransactionResponseWalletsDtoStatusEnum];

/**
 * 
 * @export
 * @interface GetUserProfileDataEntityDto
 */
export interface GetUserProfileDataEntityDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserProfileDataEntityDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfileDataEntityDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfileDataEntityDto
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface GetUserReferralContractsControllerResponseDto
 */
export interface GetUserReferralContractsControllerResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserReferralContractsControllerResponseDto
     */
    'outputCount': number;
    /**
     * 
     * @type {number}
     * @memberof GetUserReferralContractsControllerResponseDto
     */
    'outputOffset': number;
    /**
     * 
     * @type {number}
     * @memberof GetUserReferralContractsControllerResponseDto
     */
    'outputTotalCount': number;
    /**
     * 
     * @type {Array<UserReferralContractEntityDto>}
     * @memberof GetUserReferralContractsControllerResponseDto
     */
    'referralContracts': Array<UserReferralContractEntityDto>;
}
/**
 * 
 * @export
 * @interface GetUserResponseUsersDto
 */
export interface GetUserResponseUsersDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserResponseUsersDto
     */
    'success': boolean;
    /**
     * 
     * @type {UserEntityDto}
     * @memberof GetUserResponseUsersDto
     */
    'user': UserEntityDto;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseUsersDto
     */
    'userRiskLevelType': GetUserResponseUsersDtoUserRiskLevelTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof GetUserResponseUsersDto
     */
    'riskChecks': object;
    /**
     * 
     * @type {PermissionEntityDto}
     * @memberof GetUserResponseUsersDto
     */
    'permissions': PermissionEntityDto;
    /**
     * 
     * @type {GetUserUserSettingsEntityDto}
     * @memberof GetUserResponseUsersDto
     */
    'settings': GetUserUserSettingsEntityDto;
    /**
     * 
     * @type {GetUserProfileDataEntityDto}
     * @memberof GetUserResponseUsersDto
     */
    'profileData': GetUserProfileDataEntityDto;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseUsersDto
     */
    'referralCode': string;
}

export const GetUserResponseUsersDtoUserRiskLevelTypeEnum = {
    None: 'NONE',
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH',
    Blocked: 'BLOCKED',
    Suspended: 'SUSPENDED'
} as const;

export type GetUserResponseUsersDtoUserRiskLevelTypeEnum = typeof GetUserResponseUsersDtoUserRiskLevelTypeEnum[keyof typeof GetUserResponseUsersDtoUserRiskLevelTypeEnum];

/**
 * 
 * @export
 * @interface GetUserRewardsControllerResponseDto
 */
export interface GetUserRewardsControllerResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserRewardsControllerResponseDto
     */
    'outputCount': number;
    /**
     * 
     * @type {number}
     * @memberof GetUserRewardsControllerResponseDto
     */
    'outputOffset': number;
    /**
     * 
     * @type {number}
     * @memberof GetUserRewardsControllerResponseDto
     */
    'outputTotalCount': number;
    /**
     * 
     * @type {Array<UserReferralProgramRewardEntityDto>}
     * @memberof GetUserRewardsControllerResponseDto
     */
    'referralRewards': Array<UserReferralProgramRewardEntityDto>;
    /**
     * 
     * @type {number}
     * @memberof GetUserRewardsControllerResponseDto
     */
    'totalRewardsValue': number;
}
/**
 * 
 * @export
 * @interface GetUserUserSettingsEntityDto
 */
export interface GetUserUserSettingsEntityDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserUserSettingsEntityDto
     */
    'allowedPaymentMethods': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserUserSettingsEntityDto
     */
    'hideBalance': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserUserSettingsEntityDto
     */
    'hiddenWalletAssets': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserUserSettingsEntityDto
     */
    'recommendedWalletAssets': Array<string>;
}
/**
 * 
 * @export
 * @interface GetWalletConfigResponseWalletsDto
 */
export interface GetWalletConfigResponseWalletsDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetWalletConfigResponseWalletsDto
     */
    'success': boolean;
    /**
     * 
     * @type {WalletConfigEntityDto}
     * @memberof GetWalletConfigResponseWalletsDto
     */
    'walletConfig': WalletConfigEntityDto;
}
/**
 * 
 * @export
 * @interface GetWalletDetailsResponseWalletsDto
 */
export interface GetWalletDetailsResponseWalletsDto {
    /**
     * 
     * @type {WalletDetailsEntityDto}
     * @memberof GetWalletDetailsResponseWalletsDto
     */
    'walletDetails': WalletDetailsEntityDto;
}
/**
 * 
 * @export
 * @interface GetWalletHistoryResponseWalletsDto
 */
export interface GetWalletHistoryResponseWalletsDto {
    /**
     * 
     * @type {WalletHistoryEntityDto}
     * @memberof GetWalletHistoryResponseWalletsDto
     */
    'walletHistory': WalletHistoryEntityDto;
}
/**
 * 
 * @export
 * @interface GetWalletSummaryResponseWalletsDto
 */
export interface GetWalletSummaryResponseWalletsDto {
    /**
     * 
     * @type {WalletSummaryEntityDto}
     * @memberof GetWalletSummaryResponseWalletsDto
     */
    'walletSummary': WalletSummaryEntityDto;
}
/**
 * 
 * @export
 * @interface HideAssetsPayloadUserSettingsDto
 */
export interface HideAssetsPayloadUserSettingsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof HideAssetsPayloadUserSettingsDto
     */
    'assetIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof HideAssetsPayloadUserSettingsDto
     */
    'visibility': boolean;
}
/**
 * 
 * @export
 * @interface InitTotp2FaResponseUsersDto
 */
export interface InitTotp2FaResponseUsersDto {
    /**
     * 
     * @type {boolean}
     * @memberof InitTotp2FaResponseUsersDto
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InitTotp2FaResponseUsersDto
     */
    'otpauth': string;
    /**
     * 
     * @type {string}
     * @memberof InitTotp2FaResponseUsersDto
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface LimitsDto
 */
export interface LimitsDto {
    /**
     * 
     * @type {number}
     * @memberof LimitsDto
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsDto
     */
    'onetime': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsDto
     */
    'daily': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsDto
     */
    'monthly': number;
}
/**
 * 
 * @export
 * @interface LimitsResultDto
 */
export interface LimitsResultDto {
    /**
     * 
     * @type {LimitsDto}
     * @memberof LimitsResultDto
     */
    'limits': LimitsDto;
    /**
     * 
     * @type {number}
     * @memberof LimitsResultDto
     */
    'dailyRemains': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsResultDto
     */
    'dailyAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsResultDto
     */
    'monthlyRemains': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsResultDto
     */
    'monthlyAmount': number;
}
/**
 * 
 * @export
 * @interface LogRequestDto
 */
export interface LogRequestDto {
    /**
     * Log level
     * @type {string}
     * @memberof LogRequestDto
     */
    'logLevel': string;
    /**
     * Payload with additional information
     * @type {object}
     * @memberof LogRequestDto
     */
    'payload': object;
}
/**
 * 
 * @export
 * @interface LoginOrRegisterBadRequestResponseDto
 */
export interface LoginOrRegisterBadRequestResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterBadRequestResponseDto
     */
    'error': LoginOrRegisterBadRequestResponseDtoErrorEnum;
}

export const LoginOrRegisterBadRequestResponseDtoErrorEnum = {
    OtpVerifyInvalid: 'OTP_VERIFY_INVALID'
} as const;

export type LoginOrRegisterBadRequestResponseDtoErrorEnum = typeof LoginOrRegisterBadRequestResponseDtoErrorEnum[keyof typeof LoginOrRegisterBadRequestResponseDtoErrorEnum];

/**
 * 
 * @export
 * @interface LoginOrRegisterPayloadUsersDto
 */
export interface LoginOrRegisterPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'deviceUniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'otp'?: string;
    /**
     * Google Analytics Client ID
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'gaClientId': string;
    /**
     * Google Analytics Session ID
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'gaSessionId': string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'country'?: string;
    /**
     * Magic Link Token
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'magicLinkToken'?: string;
    /**
     * Referral code
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'referralCode'?: string;
    /**
     * Partner ID
     * @type {string}
     * @memberof LoginOrRegisterPayloadUsersDto
     */
    'partnerId'?: string;
}
/**
 * 
 * @export
 * @interface LoginOrRegisterResponseUsersDto
 */
export interface LoginOrRegisterResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterResponseUsersDto
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterResponseUsersDto
     */
    'refreshToken': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginOrRegisterResponseUsersDto
     */
    'requiredMfaMethods': Array<LoginOrRegisterResponseUsersDtoRequiredMfaMethodsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof LoginOrRegisterResponseUsersDto
     */
    'blockedCountryRegistration': boolean;
}

export const LoginOrRegisterResponseUsersDtoRequiredMfaMethodsEnum = {
    Totp: 'TOTP'
} as const;

export type LoginOrRegisterResponseUsersDtoRequiredMfaMethodsEnum = typeof LoginOrRegisterResponseUsersDtoRequiredMfaMethodsEnum[keyof typeof LoginOrRegisterResponseUsersDtoRequiredMfaMethodsEnum];

/**
 * 
 * @export
 * @interface LogoutBadRequestResponseDto
 */
export interface LogoutBadRequestResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LogoutBadRequestResponseDto
     */
    'error': LogoutBadRequestResponseDtoErrorEnum;
}

export const LogoutBadRequestResponseDtoErrorEnum = {
    BadRequest: 'BAD_REQUEST',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    CaptchaInvalid: 'CAPTCHA_INVALID'
} as const;

export type LogoutBadRequestResponseDtoErrorEnum = typeof LogoutBadRequestResponseDtoErrorEnum[keyof typeof LogoutBadRequestResponseDtoErrorEnum];

/**
 * 
 * @export
 * @interface MfaLoginBadRequestResponseUsersDto
 */
export interface MfaLoginBadRequestResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof MfaLoginBadRequestResponseUsersDto
     */
    'error': MfaLoginBadRequestResponseUsersDtoErrorEnum;
}

export const MfaLoginBadRequestResponseUsersDtoErrorEnum = {
    TotpVerifyInvalid: 'TOTP_VERIFY_INVALID'
} as const;

export type MfaLoginBadRequestResponseUsersDtoErrorEnum = typeof MfaLoginBadRequestResponseUsersDtoErrorEnum[keyof typeof MfaLoginBadRequestResponseUsersDtoErrorEnum];

/**
 * 
 * @export
 * @interface MfaLoginPayloadUsersDto
 */
export interface MfaLoginPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof MfaLoginPayloadUsersDto
     */
    'deviceUniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof MfaLoginPayloadUsersDto
     */
    'totp': string;
    /**
     * Google Analytics Client ID
     * @type {string}
     * @memberof MfaLoginPayloadUsersDto
     */
    'gaClientId': string;
}
/**
 * 
 * @export
 * @interface MfaLoginResponseUsersDto
 */
export interface MfaLoginResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof MfaLoginResponseUsersDto
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof MfaLoginResponseUsersDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface MobileBiometricLoginInputPayloadUsersDto
 */
export interface MobileBiometricLoginInputPayloadUsersDto {
    /**
     * 
     * @type {object}
     * @memberof MobileBiometricLoginInputPayloadUsersDto
     */
    'action': object;
    /**
     * 
     * @type {string}
     * @memberof MobileBiometricLoginInputPayloadUsersDto
     */
    'nonce': string;
}
/**
 * 
 * @export
 * @interface MobileBiometricLoginPayloadUsersDto
 */
export interface MobileBiometricLoginPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof MobileBiometricLoginPayloadUsersDto
     */
    'deviceId': string;
    /**
     * 
     * @type {MobileBiometricLoginInputPayloadUsersDto}
     * @memberof MobileBiometricLoginPayloadUsersDto
     */
    'payload': MobileBiometricLoginInputPayloadUsersDto;
    /**
     * 
     * @type {string}
     * @memberof MobileBiometricLoginPayloadUsersDto
     */
    'signature': string;
    /**
     * Google Analytics Client ID
     * @type {string}
     * @memberof MobileBiometricLoginPayloadUsersDto
     */
    'gaClientId': string;
}
/**
 * 
 * @export
 * @interface MobileBiometricLoginResponseUsersDto
 */
export interface MobileBiometricLoginResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof MobileBiometricLoginResponseUsersDto
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof MobileBiometricLoginResponseUsersDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface MockPermissionBodyRequestTestDto
 */
export interface MockPermissionBodyRequestTestDto {
    /**
     * 
     * @type {RiskLevelType}
     * @memberof MockPermissionBodyRequestTestDto
     */
    'riskLevelType': RiskLevelType;
    /**
     * Applicant Id used in applicant data
     * @type {string}
     * @memberof MockPermissionBodyRequestTestDto
     */
    'applicantId'?: string;
}
/**
 * 
 * @export
 * @interface PayInEntityDto
 */
export interface PayInEntityDto {
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'status': PayInEntityDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'userDepositAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'destinationTag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'toIban'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'toName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayInEntityDto
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayInEntityDto
     */
    'userPaymentSent'?: boolean;
}

export const PayInEntityDtoStatusEnum = {
    Created: 'CREATED',
    Pending: 'PENDING',
    Done: 'DONE',
    Blocked: 'BLOCKED',
    Canceled: 'CANCELED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;

export type PayInEntityDtoStatusEnum = typeof PayInEntityDtoStatusEnum[keyof typeof PayInEntityDtoStatusEnum];

/**
 * 
 * @export
 * @interface PayOutEntityDto
 */
export interface PayOutEntityDto {
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'status': PayOutEntityDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'walletAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'destinationTag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'txHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'iban'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'toFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayOutEntityDto
     */
    'toLastName'?: string;
}

export const PayOutEntityDtoStatusEnum = {
    Created: 'CREATED',
    Pending: 'PENDING',
    Done: 'DONE',
    Blocked: 'BLOCKED',
    Canceled: 'CANCELED'
} as const;

export type PayOutEntityDtoStatusEnum = typeof PayOutEntityDtoStatusEnum[keyof typeof PayOutEntityDtoStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
    Sepa: 'SEPA',
    Card: 'CARD'
} as const;

export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


/**
 * 
 * @export
 * @interface PermissionEntityDto
 */
export interface PermissionEntityDto {
    /**
     * 
     * @type {UserPermissionCaseEntityDto}
     * @memberof PermissionEntityDto
     */
    'permissionCases': UserPermissionCaseEntityDto;
    /**
     * 
     * @type {Array<UserPermissionDataEntityDto>}
     * @memberof PermissionEntityDto
     */
    'userPermissionData'?: Array<UserPermissionDataEntityDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionEntityDto
     */
    'awaitingKycReward': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionEntityDto
     */
    'hasAcceptedNewTerms': boolean;
}
/**
 * 
 * @export
 * @interface PlaceResponseDto
 */
export interface PlaceResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PlaceResponseDto
     */
    'placeId': string;
    /**
     * 
     * @type {object}
     * @memberof PlaceResponseDto
     */
    'city': object;
    /**
     * 
     * @type {object}
     * @memberof PlaceResponseDto
     */
    'country': object;
}
/**
 * 
 * @export
 * @interface ProcessExchangeRequestDto
 */
export interface ProcessExchangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessExchangeRequestDto
     */
    'exchangeId': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessExchangeRequestDto
     */
    'notes': string;
}
/**
 * 
 * @export
 * @interface RecoveryWalletByUserPayloadServiceDto
 */
export interface RecoveryWalletByUserPayloadServiceDto {
    /**
     * 
     * @type {string}
     * @memberof RecoveryWalletByUserPayloadServiceDto
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenPayloadUsersDto
 */
export interface RefreshTokenPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenPayloadUsersDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenResponseUsersDto
 */
export interface RefreshTokenResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponseUsersDto
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponseUsersDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RegisterDevicePayloadUsersDto
 */
export interface RegisterDevicePayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDevicePayloadUsersDto
     */
    'deviceId': string;
    /**
     * 
     * @type {object}
     * @memberof RegisterDevicePayloadUsersDto
     */
    'os': object;
    /**
     * 
     * @type {string}
     * @memberof RegisterDevicePayloadUsersDto
     */
    'publicKey': string;
    /**
     * 
     * @type {object}
     * @memberof RegisterDevicePayloadUsersDto
     */
    'deviceMetadata': object;
}
/**
 * 
 * @export
 * @interface RegisterDeviceResponseUsersDto
 */
export interface RegisterDeviceResponseUsersDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDeviceResponseUsersDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDeviceResponseUsersDto
     */
    'deviceId': string;
    /**
     * 
     * @type {object}
     * @memberof RegisterDeviceResponseUsersDto
     */
    'os': object;
    /**
     * 
     * @type {string}
     * @memberof RegisterDeviceResponseUsersDto
     */
    'publicKey': string;
    /**
     * 
     * @type {object}
     * @memberof RegisterDeviceResponseUsersDto
     */
    'deviceMetadata': object;
}
/**
 * 
 * @export
 * @interface RequestOtpPayloadUsersDto
 */
export interface RequestOtpPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof RequestOtpPayloadUsersDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface RequestPermissionResponseSecurityDto
 */
export interface RequestPermissionResponseSecurityDto {
    /**
     * 
     * @type {string}
     * @memberof RequestPermissionResponseSecurityDto
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface ResendOtpPayloadUsersDto
 */
export interface ResendOtpPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof ResendOtpPayloadUsersDto
     */
    'email': string;
    /**
     * 
     * @type {GeetestClientValidationResponseDto}
     * @memberof ResendOtpPayloadUsersDto
     */
    'captcha': GeetestClientValidationResponseDto;
}
/**
 * 
 * @export
 * @interface ResumeExchangeRequestDto
 */
export interface ResumeExchangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ResumeExchangeRequestDto
     */
    'exchangeId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RiskCheckType = {
    LivenessCheck: 'LIVENESS_CHECK',
    ProofOfWealthCheck: 'PROOF_OF_WEALTH_CHECK',
    ProofOfFundsCheck: 'PROOF_OF_FUNDS_CHECK',
    PassQuestionnaire: 'PASS_QUESTIONNAIRE',
    CardVerification: 'CARD_VERIFICATION',
    ManualReview: 'MANUAL_REVIEW'
} as const;

export type RiskCheckType = typeof RiskCheckType[keyof typeof RiskCheckType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RiskLevelType = {
    None: 'NONE',
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH',
    Blocked: 'BLOCKED',
    Suspended: 'SUSPENDED'
} as const;

export type RiskLevelType = typeof RiskLevelType[keyof typeof RiskLevelType];


/**
 * 
 * @export
 * @interface SearchPlaceRequestDto
 */
export interface SearchPlaceRequestDto {
    /**
     * Start of the city name
     * @type {string}
     * @memberof SearchPlaceRequestDto
     */
    'cityPrefix': string;
    /**
     * Country name in alpha3 format
     * @type {string}
     * @memberof SearchPlaceRequestDto
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SlackChannel = {
    Notifications: 'NOTIFICATIONS',
    Aml: 'AML',
    DevNotifications: 'DEV_NOTIFICATIONS'
} as const;

export type SlackChannel = typeof SlackChannel[keyof typeof SlackChannel];


/**
 * 
 * @export
 * @interface SlackNotificationPayloadTestDto
 */
export interface SlackNotificationPayloadTestDto {
    /**
     * 
     * @type {string}
     * @memberof SlackNotificationPayloadTestDto
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof SlackNotificationPayloadTestDto
     */
    'data'?: object;
    /**
     * 
     * @type {SlackChannel}
     * @memberof SlackNotificationPayloadTestDto
     */
    'channel': SlackChannel;
}
/**
 * 
 * @export
 * @interface ToggleTotp2FaPayloadUsersDto
 */
export interface ToggleTotp2FaPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof ToggleTotp2FaPayloadUsersDto
     */
    'emailOtpToken': string;
    /**
     * 
     * @type {string}
     * @memberof ToggleTotp2FaPayloadUsersDto
     */
    'totpToken': string;
}
/**
 * 
 * @export
 * @interface UnauthorizedExceptionDto
 */
export interface UnauthorizedExceptionDto {
    /**
     * 
     * @type {boolean}
     * @memberof UnauthorizedExceptionDto
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnauthorizedExceptionDto
     */
    'error': UnauthorizedExceptionDtoErrorEnum;
}

export const UnauthorizedExceptionDtoErrorEnum = {
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    TokenExpired: 'TOKEN_EXPIRED',
    InvalidateSession: 'INVALIDATE_SESSION',
    Unauthorized: 'UNAUTHORIZED'
} as const;

export type UnauthorizedExceptionDtoErrorEnum = typeof UnauthorizedExceptionDtoErrorEnum[keyof typeof UnauthorizedExceptionDtoErrorEnum];

/**
 * 
 * @export
 * @interface UnbindDevicePayloadUsersDto
 */
export interface UnbindDevicePayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof UnbindDevicePayloadUsersDto
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface UpdateGaSessionIdPayloadUsersDto
 */
export interface UpdateGaSessionIdPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGaSessionIdPayloadUsersDto
     */
    'gaSessionId': string;
}
/**
 * 
 * @export
 * @interface UserAccountDetailsDto
 */
export interface UserAccountDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountDetailsDto
     */
    'id': string;
    /**
     * 
     * @type {AccountDetailsWalletDto}
     * @memberof UserAccountDetailsDto
     */
    'walletAccountDetails': AccountDetailsWalletDto;
}
/**
 * 
 * @export
 * @interface UserAccountDto
 */
export interface UserAccountDto {
    /**
     * 
     * @type {object}
     * @memberof UserAccountDto
     */
    'assetImage': object;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'balance': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'type': string;
    /**
     * 
     * @type {object}
     * @memberof UserAccountDto
     */
    'assetCurrencyLabel': object;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'assetCurrencyName': string;
    /**
     * 
     * @type {object}
     * @memberof UserAccountDto
     */
    'assetNetworkLabel': object;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'assetNetworkName': string;
}
/**
 * 
 * @export
 * @interface UserEntityDto
 */
export interface UserEntityDto {
    /**
     * 
     * @type {string}
     * @memberof UserEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntityDto
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntityDto
     */
    'totpEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntityDto
     */
    'totpWasDisabled': boolean;
}
/**
 * 
 * @export
 * @interface UserPaymentSentRequestDto
 */
export interface UserPaymentSentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserPaymentSentRequestDto
     */
    'exchangeId': string;
}
/**
 * 
 * @export
 * @interface UserPaymentSentResponseDto
 */
export interface UserPaymentSentResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserPaymentSentResponseDto
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface UserPermissionCaseEntityDto
 */
export interface UserPermissionCaseEntityDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionCaseEntityDto
     */
    'VERIFIED_KYC': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionCaseEntityDto
     */
    'VERIFIED_POA': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionCaseEntityDto
     */
    'VERIFIED_POA_FOR_HIGH_RISK': boolean;
}
/**
 * 
 * @export
 * @interface UserPermissionDataDuplicateEntityDto
 */
export interface UserPermissionDataDuplicateEntityDto {
    /**
     * 
     * @type {string}
     * @memberof UserPermissionDataDuplicateEntityDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionDataDuplicateEntityDto
     */
    'userEmail': string;
}
/**
 * 
 * @export
 * @interface UserPermissionDataEntityDto
 */
export interface UserPermissionDataEntityDto {
    /**
     * 
     * @type {string}
     * @memberof UserPermissionDataEntityDto
     */
    'type': UserPermissionDataEntityDtoTypeEnum;
    /**
     * 
     * @type {Array<UserPermissionDataEntityDto>}
     * @memberof UserPermissionDataEntityDto
     */
    'userPermissionData'?: Array<UserPermissionDataEntityDto>;
}

export const UserPermissionDataEntityDtoTypeEnum = {
    Duplicate: 'DUPLICATE'
} as const;

export type UserPermissionDataEntityDtoTypeEnum = typeof UserPermissionDataEntityDtoTypeEnum[keyof typeof UserPermissionDataEntityDtoTypeEnum];

/**
 * 
 * @export
 * @interface UserReferralContractEntityDto
 */
export interface UserReferralContractEntityDto {
    /**
     * 
     * @type {string}
     * @memberof UserReferralContractEntityDto
     */
    'referralUserId': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralContractEntityDto
     */
    'referralUserEmail': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralContractEntityDto
     */
    'referralUserCreatedAt': string;
    /**
     * 
     * @type {object}
     * @memberof UserReferralContractEntityDto
     */
    'assignedRewardsAt': object;
    /**
     * 
     * @type {string}
     * @memberof UserReferralContractEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralContractEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralContractEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UserReferralProgramRewardEntityDto
 */
export interface UserReferralProgramRewardEntityDto {
    /**
     * 
     * @type {string}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'referralContractId': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'walletAssetId': string;
    /**
     * 
     * @type {object}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'walletAssetExternalId': object;
    /**
     * 
     * @type {number}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'amount': number;
    /**
     * 
     * @type {object}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'walletTransactionId': object;
    /**
     * 
     * @type {object}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'status': object;
    /**
     * 
     * @type {object}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'accruedAt': object;
    /**
     * 
     * @type {string}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralProgramRewardEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ValidateAddressPayloadWalletsDto
 */
export interface ValidateAddressPayloadWalletsDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateAddressPayloadWalletsDto
     */
    'walletAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ValidateAddressPayloadWalletsDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ValidateAddressPayloadWalletsDto
     */
    'destinationTag'?: string;
}
/**
 * 
 * @export
 * @interface ValidateAddressResponseWalletsDto
 */
export interface ValidateAddressResponseWalletsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateAddressResponseWalletsDto
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface ValidateIbanResponseDto
 */
export interface ValidateIbanResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateIbanResponseDto
     */
    'result'?: ValidateIbanResponseDtoResultEnum;
}

export const ValidateIbanResponseDtoResultEnum = {
    ValidIban: 'VALID_IBAN',
    InvalidIban: 'INVALID_IBAN',
    SepaUnreachableIban: 'SEPA_UNREACHABLE_IBAN'
} as const;

export type ValidateIbanResponseDtoResultEnum = typeof ValidateIbanResponseDtoResultEnum[keyof typeof ValidateIbanResponseDtoResultEnum];

/**
 * 
 * @export
 * @interface ValidateReferralCodePayloadDto
 */
export interface ValidateReferralCodePayloadDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateReferralCodePayloadDto
     */
    'referralCode': string;
}
/**
 * 
 * @export
 * @interface ValidateReferralCodeResponseDto
 */
export interface ValidateReferralCodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateReferralCodeResponseDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface VerifyEmailPayloadUsersDto
 */
export interface VerifyEmailPayloadUsersDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailPayloadUsersDto
     */
    'emailOtpToken': string;
}
/**
 * 
 * @export
 * @interface VerifyEmailResponseUsersDto
 */
export interface VerifyEmailResponseUsersDto {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyEmailResponseUsersDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface WalletAccountEntityDto
 */
export interface WalletAccountEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletAccountEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WalletAccountEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WalletAccountEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface WalletAssetEntityDto
 */
export interface WalletAssetEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletAssetEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WalletAssetEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WalletAssetEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface WalletConfigEntityDto
 */
export interface WalletConfigEntityDto {
    /**
     * 
     * @type {WalletConfigRecostEntityDto}
     * @memberof WalletConfigEntityDto
     */
    'recost': WalletConfigRecostEntityDto;
}
/**
 * 
 * @export
 * @interface WalletConfigRecostEntityDto
 */
export interface WalletConfigRecostEntityDto {
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletConfigRecostEntityDto
     */
    'cryptoAsset': AssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletConfigRecostEntityDto
     */
    'fiatAsset': AssetEntityDto;
}
/**
 * 
 * @export
 * @interface WalletDetailsEntityDto
 */
export interface WalletDetailsEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletDetailsEntityDto
     */
    'cryptoBalance': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDetailsEntityDto
     */
    'fiatBalance': string;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletDetailsEntityDto
     */
    'address': string | Null;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletDetailsEntityDto
     */
    'tag': string | Null;
    /**
     * 
     * @type {WalletAccountEntityDto}
     * @memberof WalletDetailsEntityDto
     */
    'walletAccount': WalletAccountEntityDto;
    /**
     * 
     * @type {WalletAssetEntityDto}
     * @memberof WalletDetailsEntityDto
     */
    'walletAsset': WalletAssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletDetailsEntityDto
     */
    'asset': AssetEntityDto;
    /**
     * 
     * @type {Array<WalletExchangerAssetEntityDto>}
     * @memberof WalletDetailsEntityDto
     */
    'exchangerAssets': Array<WalletExchangerAssetEntityDto>;
}
/**
 * 
 * @export
 * @interface WalletEntityDto
 */
export interface WalletEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WalletEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WalletEntityDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface WalletEstimationTransactionEntityDto
 */
export interface WalletEstimationTransactionEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'equivalentAmount': string;
    /**
     * 
     * @type {string}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'maxCryptoAmount': string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'isBalanceEnough': boolean;
    /**
     * 
     * @type {string}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'walletBalanceInsufficientionReason'?: WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum;
    /**
     * 
     * @type {WalletFeeLevelsEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'walletFeeLevels': WalletFeeLevelsEntityDto;
    /**
     * 
     * @type {WalletAccountEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'walletAccount': WalletAccountEntityDto;
    /**
     * 
     * @type {WalletAssetEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'walletAsset': WalletAssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'asset': AssetEntityDto;
    /**
     * 
     * @type {WalletAccountEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'feeWalletAccount'?: WalletAccountEntityDto;
    /**
     * 
     * @type {WalletAssetEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'feeWalletAsset'?: WalletAssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletEstimationTransactionEntityDto
     */
    'feeAsset'?: AssetEntityDto;
}

export const WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum = {
    AmountMustBeGreaterThanZero: 'AMOUNT_MUST_BE_GREATER_THAN_ZERO',
    InsufficientBalanceForCoinTransaction: 'INSUFFICIENT_BALANCE_FOR_COIN_TRANSACTION',
    InsufficientTokenBalanceForTokenTransaction: 'INSUFFICIENT_TOKEN_BALANCE_FOR_TOKEN_TRANSACTION',
    InsufficientCoinBalanceForTokenTransaction: 'INSUFFICIENT_COIN_BALANCE_FOR_TOKEN_TRANSACTION',
    CouldNotReceiveNetworkFee: 'COULD_NOT_RECEIVE_NETWORK_FEE',
    Unknown: 'UNKNOWN'
} as const;

export type WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum = typeof WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum[keyof typeof WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum];

/**
 * 
 * @export
 * @interface WalletExchangerAssetEntityDto
 */
export interface WalletExchangerAssetEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletExchangerAssetEntityDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletExchangerAssetEntityDto
     */
    'isBuy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WalletExchangerAssetEntityDto
     */
    'isSell': boolean;
}
/**
 * 
 * @export
 * @interface WalletFeeEntityDto
 */
export interface WalletFeeEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletFeeEntityDto
     */
    'networkFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletFeeEntityDto
     */
    'gasPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletFeeEntityDto
     */
    'gasLimit'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletFeeEntityDto
     */
    'feePerByte'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletFeeEntityDto
     */
    'baseFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletFeeEntityDto
     */
    'priorityFee'?: string;
}
/**
 * 
 * @export
 * @interface WalletFeeLevelsEntityDto
 */
export interface WalletFeeLevelsEntityDto {
    /**
     * 
     * @type {WalletFeeEntityDto}
     * @memberof WalletFeeLevelsEntityDto
     */
    'LOW': WalletFeeEntityDto;
    /**
     * 
     * @type {WalletFeeEntityDto}
     * @memberof WalletFeeLevelsEntityDto
     */
    'MEDIUM': WalletFeeEntityDto;
    /**
     * 
     * @type {WalletFeeEntityDto}
     * @memberof WalletFeeLevelsEntityDto
     */
    'HIGH': WalletFeeEntityDto;
}
/**
 * 
 * @export
 * @interface WalletHistoryEntityDto
 */
export interface WalletHistoryEntityDto {
    /**
     * 
     * @type {number}
     * @memberof WalletHistoryEntityDto
     */
    'outputCount': number;
    /**
     * 
     * @type {number}
     * @memberof WalletHistoryEntityDto
     */
    'outputOffset': number;
    /**
     * 
     * @type {number}
     * @memberof WalletHistoryEntityDto
     */
    'outputTotalCount': number;
    /**
     * 
     * @type {Array<WalletOperationEntityDto>}
     * @memberof WalletHistoryEntityDto
     */
    'walletOperations': Array<WalletOperationEntityDto>;
}
/**
 * 
 * @export
 * @interface WalletOperationEntityDto
 */
export interface WalletOperationEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletOperationEntityDto
     */
    'cryptoAmount': string;
    /**
     * 
     * @type {string}
     * @memberof WalletOperationEntityDto
     */
    'fiatAmount': string;
    /**
     * 
     * @type {WalletTransactionEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'walletTransaction': WalletTransactionEntityDto;
    /**
     * 
     * @type {WalletAccountEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'walletAccount': WalletAccountEntityDto;
    /**
     * 
     * @type {WalletAssetEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'walletAsset': WalletAssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'asset': AssetEntityDto;
    /**
     * 
     * @type {WalletAccountEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'feeWalletAccount'?: WalletAccountEntityDto;
    /**
     * 
     * @type {WalletAssetEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'feeWalletAsset'?: WalletAssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletOperationEntityDto
     */
    'feeAsset'?: AssetEntityDto;
}
/**
 * 
 * @export
 * @interface WalletResourceEntityDto
 */
export interface WalletResourceEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletResourceEntityDto
     */
    'cryptoBalance': string;
    /**
     * 
     * @type {string}
     * @memberof WalletResourceEntityDto
     */
    'fiatBalance': string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletResourceEntityDto
     */
    'isCreated': boolean;
    /**
     * 
     * @type {WalletAccountEntityDto}
     * @memberof WalletResourceEntityDto
     */
    'walletAccount'?: WalletAccountEntityDto;
    /**
     * 
     * @type {WalletAssetEntityDto}
     * @memberof WalletResourceEntityDto
     */
    'walletAsset': WalletAssetEntityDto;
    /**
     * 
     * @type {AssetEntityDto}
     * @memberof WalletResourceEntityDto
     */
    'asset': AssetEntityDto;
}
/**
 * 
 * @export
 * @interface WalletSummaryEntityDto
 */
export interface WalletSummaryEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletSummaryEntityDto
     */
    'cryptoTotalBalance': string;
    /**
     * 
     * @type {string}
     * @memberof WalletSummaryEntityDto
     */
    'fiatTotalBalance': string;
    /**
     * 
     * @type {WalletEntityDto}
     * @memberof WalletSummaryEntityDto
     */
    'wallet': WalletEntityDto;
    /**
     * 
     * @type {Array<WalletResourceEntityDto>}
     * @memberof WalletSummaryEntityDto
     */
    'walletResources': Array<WalletResourceEntityDto>;
}
/**
 * 
 * @export
 * @interface WalletTransactionEntityDto
 */
export interface WalletTransactionEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'externalId': string;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletTransactionEntityDto
     */
    'txHash': string | Null;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'type': WalletTransactionEntityDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'status': WalletTransactionEntityDtoStatusEnum;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletTransactionEntityDto
     */
    'subStatus': string | Null;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletTransactionEntityDto
     */
    'fromAddress': string | Null;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletTransactionEntityDto
     */
    'toAddress': string | Null;
    /**
     * 
     * @type {string | Null}
     * @memberof WalletTransactionEntityDto
     */
    'toTag': string | Null;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'networkFee': string;
    /**
     * 
     * @type {number}
     * @memberof WalletTransactionEntityDto
     */
    'confirmationsCount': number;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionEntityDto
     */
    'updatedAt': string;
}

export const WalletTransactionEntityDtoTypeEnum = {
    Deposit: 'DEPOSIT',
    Withdrawal: 'WITHDRAWAL',
    Fee: 'FEE'
} as const;

export type WalletTransactionEntityDtoTypeEnum = typeof WalletTransactionEntityDtoTypeEnum[keyof typeof WalletTransactionEntityDtoTypeEnum];
export const WalletTransactionEntityDtoStatusEnum = {
    Submitted: 'SUBMITTED',
    Queued: 'QUEUED',
    PendingAuthorization: 'PENDING_AUTHORIZATION',
    PendingSignature: 'PENDING_SIGNATURE',
    Broadcasting: 'BROADCASTING',
    Pending3RdPartyManualApproval: 'PENDING_3RD_PARTY_MANUAL_APPROVAL',
    Pending3RdParty: 'PENDING_3RD_PARTY',
    Confirming: 'CONFIRMING',
    PartiallyCompleted: 'PARTIALLY_COMPLETED',
    PendingAmlScreening: 'PENDING_AML_SCREENING',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Rejected: 'REJECTED',
    Blocked: 'BLOCKED',
    Failed: 'FAILED'
} as const;

export type WalletTransactionEntityDtoStatusEnum = typeof WalletTransactionEntityDtoStatusEnum[keyof typeof WalletTransactionEntityDtoStatusEnum];

/**
 * 
 * @export
 * @interface WhitelistCountryEntityDto
 */
export interface WhitelistCountryEntityDto {
    /**
     * 
     * @type {string}
     * @memberof WhitelistCountryEntityDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistCountryEntityDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistCountryEntityDto
     */
    'alpha3': string;
}
/**
 * 
 * @export
 * @interface WhitelistGetCountriesResponseDto
 */
export interface WhitelistGetCountriesResponseDto {
    /**
     * 
     * @type {Array<WhitelistCountryEntityDto>}
     * @memberof WhitelistGetCountriesResponseDto
     */
    'countries': Array<WhitelistCountryEntityDto>;
}
/**
 * 
 * @export
 * @interface WhitelistRegisterBadRequestMarketingDto
 */
export interface WhitelistRegisterBadRequestMarketingDto {
    /**
     * 
     * @type {string}
     * @memberof WhitelistRegisterBadRequestMarketingDto
     */
    'error': WhitelistRegisterBadRequestMarketingDtoErrorEnum;
}

export const WhitelistRegisterBadRequestMarketingDtoErrorEnum = {
    UserAlreadyExists: 'USER_ALREADY_EXISTS',
    OtpVerifyInvalid: 'OTP_VERIFY_INVALID'
} as const;

export type WhitelistRegisterBadRequestMarketingDtoErrorEnum = typeof WhitelistRegisterBadRequestMarketingDtoErrorEnum[keyof typeof WhitelistRegisterBadRequestMarketingDtoErrorEnum];

/**
 * 
 * @export
 * @interface WhitelistRegisterPayloadMarketingDto
 */
export interface WhitelistRegisterPayloadMarketingDto {
    /**
     * 
     * @type {string}
     * @memberof WhitelistRegisterPayloadMarketingDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistRegisterPayloadMarketingDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistRegisterPayloadMarketingDto
     */
    'emailOtpToken': string;
}
/**
 * 
 * @export
 * @interface WhitelistRegisterResponseDto
 */
export interface WhitelistRegisterResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof WhitelistRegisterResponseDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface WhitelistRequestOtpBadRequestMarketingDto
 */
export interface WhitelistRequestOtpBadRequestMarketingDto {
    /**
     * 
     * @type {string}
     * @memberof WhitelistRequestOtpBadRequestMarketingDto
     */
    'error': WhitelistRequestOtpBadRequestMarketingDtoErrorEnum;
}

export const WhitelistRequestOtpBadRequestMarketingDtoErrorEnum = {
    UserAlreadyExists: 'USER_ALREADY_EXISTS',
    BadRequest: 'BAD_REQUEST',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    CaptchaInvalid: 'CAPTCHA_INVALID'
} as const;

export type WhitelistRequestOtpBadRequestMarketingDtoErrorEnum = typeof WhitelistRequestOtpBadRequestMarketingDtoErrorEnum[keyof typeof WhitelistRequestOtpBadRequestMarketingDtoErrorEnum];

/**
 * 
 * @export
 * @interface WhitelistRequestOtpPayloadMarketingDto
 */
export interface WhitelistRequestOtpPayloadMarketingDto {
    /**
     * 
     * @type {string}
     * @memberof WhitelistRequestOtpPayloadMarketingDto
     */
    'email': string;
    /**
     * 
     * @type {GeetestClientValidationResponseDto}
     * @memberof WhitelistRequestOtpPayloadMarketingDto
     */
    'captcha': GeetestClientValidationResponseDto;
}

/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Log GA4 event
         * @param {EventRequestDto} eventRequestDto Event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerLogEvent: async (eventRequestDto: EventRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventRequestDto' is not null or undefined
            assertParamExists('analyticsControllerLogEvent', 'eventRequestDto', eventRequestDto)
            const localVarPath = `/analytics/logEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Log GA4 event
         * @param {EventRequestDto} eventRequestDto Event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsControllerLogEvent(eventRequestDto: EventRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerLogEvent(eventRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Log GA4 event
         * @param {EventRequestDto} eventRequestDto Event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerLogEvent(eventRequestDto: EventRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.analyticsControllerLogEvent(eventRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @summary Log GA4 event
     * @param {EventRequestDto} eventRequestDto Event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public analyticsControllerLogEvent(eventRequestDto: EventRequestDto, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).analyticsControllerLogEvent(eventRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmulateApi - axios parameter creator
 * @export
 */
export const EmulateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId User ID
         * @param {MockPermissionBodyRequestTestDto} mockPermissionBodyRequestTestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerMockKycPermission: async (userId: string, mockPermissionBodyRequestTestDto: MockPermissionBodyRequestTestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('emulateControllerMockKycPermission', 'userId', userId)
            // verify required parameter 'mockPermissionBodyRequestTestDto' is not null or undefined
            assertParamExists('emulateControllerMockKycPermission', 'mockPermissionBodyRequestTestDto', mockPermissionBodyRequestTestDto)
            const localVarPath = `/emulate/sumsub/mockKycPermission/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mockPermissionBodyRequestTestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {EmulatePermissionPoaRequestDto} emulatePermissionPoaRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerMockPoaPermission: async (userId: string, emulatePermissionPoaRequestDto: EmulatePermissionPoaRequestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('emulateControllerMockPoaPermission', 'userId', userId)
            // verify required parameter 'emulatePermissionPoaRequestDto' is not null or undefined
            assertParamExists('emulateControllerMockPoaPermission', 'emulatePermissionPoaRequestDto', emulatePermissionPoaRequestDto)
            const localVarPath = `/emulate/sumsub/mockPoaPermission/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emulatePermissionPoaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} detailsCardId Payin Details Card Id
         * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayinAuthorize: async (detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detailsCardId' is not null or undefined
            assertParamExists('emulateControllerPayinAuthorize', 'detailsCardId', detailsCardId)
            // verify required parameter 'emulateUnlimintPayinPayloadRequestDto' is not null or undefined
            assertParamExists('emulateControllerPayinAuthorize', 'emulateUnlimintPayinPayloadRequestDto', emulateUnlimintPayinPayloadRequestDto)
            const localVarPath = `/emulate/unlimint/payin-authorize/{detailsCardId}`
                .replace(`{${"detailsCardId"}}`, encodeURIComponent(String(detailsCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emulateUnlimintPayinPayloadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} detailsCardId Payin Details Card Id
         * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayinComplete: async (detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detailsCardId' is not null or undefined
            assertParamExists('emulateControllerPayinComplete', 'detailsCardId', detailsCardId)
            // verify required parameter 'emulateUnlimintPayinPayloadRequestDto' is not null or undefined
            assertParamExists('emulateControllerPayinComplete', 'emulateUnlimintPayinPayloadRequestDto', emulateUnlimintPayinPayloadRequestDto)
            const localVarPath = `/emulate/unlimint/payin-complete/{detailsCardId}`
                .replace(`{${"detailsCardId"}}`, encodeURIComponent(String(detailsCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emulateUnlimintPayinPayloadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} detailsSepaId Payin Details Sepa Id or Short Id
         * @param {EmulateSepaPayinPayloadRequestDto} emulateSepaPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayinSepaComplete: async (detailsSepaId: string, emulateSepaPayinPayloadRequestDto: EmulateSepaPayinPayloadRequestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detailsSepaId' is not null or undefined
            assertParamExists('emulateControllerPayinSepaComplete', 'detailsSepaId', detailsSepaId)
            // verify required parameter 'emulateSepaPayinPayloadRequestDto' is not null or undefined
            assertParamExists('emulateControllerPayinSepaComplete', 'emulateSepaPayinPayloadRequestDto', emulateSepaPayinPayloadRequestDto)
            const localVarPath = `/emulate/sepa/payin-complete/{detailsSepaId}`
                .replace(`{${"detailsSepaId"}}`, encodeURIComponent(String(detailsSepaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emulateSepaPayinPayloadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} exchangeId Exchange ID
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayoutCryptoComplete: async (exchangeId: string, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeId' is not null or undefined
            assertParamExists('emulateControllerPayoutCryptoComplete', 'exchangeId', exchangeId)
            const localVarPath = `/emulate/payout-crypto/complete/{exchangeId}`
                .replace(`{${"exchangeId"}}`, encodeURIComponent(String(exchangeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} exchangeId Exchange Id
         * @param {EmulateSellPayloadRequestDto} emulateSellPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerSell: async (exchangeId: string, emulateSellPayloadRequestDto: EmulateSellPayloadRequestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeId' is not null or undefined
            assertParamExists('emulateControllerSell', 'exchangeId', exchangeId)
            // verify required parameter 'emulateSellPayloadRequestDto' is not null or undefined
            assertParamExists('emulateControllerSell', 'emulateSellPayloadRequestDto', emulateSellPayloadRequestDto)
            const localVarPath = `/emulate/sell/{exchangeId}`
                .replace(`{${"exchangeId"}}`, encodeURIComponent(String(exchangeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emulateSellPayloadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmulateApi - functional programming interface
 * @export
 */
export const EmulateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmulateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId User ID
         * @param {MockPermissionBodyRequestTestDto} mockPermissionBodyRequestTestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerMockKycPermission(userId: string, mockPermissionBodyRequestTestDto: MockPermissionBodyRequestTestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerMockKycPermission(userId, mockPermissionBodyRequestTestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {EmulatePermissionPoaRequestDto} emulatePermissionPoaRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerMockPoaPermission(userId: string, emulatePermissionPoaRequestDto: EmulatePermissionPoaRequestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerMockPoaPermission(userId, emulatePermissionPoaRequestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} detailsCardId Payin Details Card Id
         * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerPayinAuthorize(detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerPayinAuthorize(detailsCardId, emulateUnlimintPayinPayloadRequestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} detailsCardId Payin Details Card Id
         * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerPayinComplete(detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerPayinComplete(detailsCardId, emulateUnlimintPayinPayloadRequestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} detailsSepaId Payin Details Sepa Id or Short Id
         * @param {EmulateSepaPayinPayloadRequestDto} emulateSepaPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerPayinSepaComplete(detailsSepaId: string, emulateSepaPayinPayloadRequestDto: EmulateSepaPayinPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerPayinSepaComplete(detailsSepaId, emulateSepaPayinPayloadRequestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} exchangeId Exchange ID
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerPayoutCryptoComplete(exchangeId: string, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerPayoutCryptoComplete(exchangeId, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} exchangeId Exchange Id
         * @param {EmulateSellPayloadRequestDto} emulateSellPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emulateControllerSell(exchangeId: string, emulateSellPayloadRequestDto: EmulateSellPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmulateSellPayloadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emulateControllerSell(exchangeId, emulateSellPayloadRequestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmulateApi - factory interface
 * @export
 */
export const EmulateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmulateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId User ID
         * @param {MockPermissionBodyRequestTestDto} mockPermissionBodyRequestTestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerMockKycPermission(userId: string, mockPermissionBodyRequestTestDto: MockPermissionBodyRequestTestDto, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emulateControllerMockKycPermission(userId, mockPermissionBodyRequestTestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {EmulatePermissionPoaRequestDto} emulatePermissionPoaRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerMockPoaPermission(userId: string, emulatePermissionPoaRequestDto: EmulatePermissionPoaRequestDto, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emulateControllerMockPoaPermission(userId, emulatePermissionPoaRequestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} detailsCardId Payin Details Card Id
         * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayinAuthorize(detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emulateControllerPayinAuthorize(detailsCardId, emulateUnlimintPayinPayloadRequestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} detailsCardId Payin Details Card Id
         * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayinComplete(detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emulateControllerPayinComplete(detailsCardId, emulateUnlimintPayinPayloadRequestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} detailsSepaId Payin Details Sepa Id or Short Id
         * @param {EmulateSepaPayinPayloadRequestDto} emulateSepaPayinPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayinSepaComplete(detailsSepaId: string, emulateSepaPayinPayloadRequestDto: EmulateSepaPayinPayloadRequestDto, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emulateControllerPayinSepaComplete(detailsSepaId, emulateSepaPayinPayloadRequestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} exchangeId Exchange ID
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerPayoutCryptoComplete(exchangeId: string, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emulateControllerPayoutCryptoComplete(exchangeId, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} exchangeId Exchange Id
         * @param {EmulateSellPayloadRequestDto} emulateSellPayloadRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emulateControllerSell(exchangeId: string, emulateSellPayloadRequestDto: EmulateSellPayloadRequestDto, xServiceToken?: string, options?: any): AxiosPromise<EmulateSellPayloadResponseDto> {
            return localVarFp.emulateControllerSell(exchangeId, emulateSellPayloadRequestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmulateApi - object-oriented interface
 * @export
 * @class EmulateApi
 * @extends {BaseAPI}
 */
export class EmulateApi extends BaseAPI {
    /**
     * 
     * @param {string} userId User ID
     * @param {MockPermissionBodyRequestTestDto} mockPermissionBodyRequestTestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerMockKycPermission(userId: string, mockPermissionBodyRequestTestDto: MockPermissionBodyRequestTestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerMockKycPermission(userId, mockPermissionBodyRequestTestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId User ID
     * @param {EmulatePermissionPoaRequestDto} emulatePermissionPoaRequestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerMockPoaPermission(userId: string, emulatePermissionPoaRequestDto: EmulatePermissionPoaRequestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerMockPoaPermission(userId, emulatePermissionPoaRequestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} detailsCardId Payin Details Card Id
     * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerPayinAuthorize(detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerPayinAuthorize(detailsCardId, emulateUnlimintPayinPayloadRequestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} detailsCardId Payin Details Card Id
     * @param {EmulateUnlimintPayinPayloadRequestDto} emulateUnlimintPayinPayloadRequestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerPayinComplete(detailsCardId: string, emulateUnlimintPayinPayloadRequestDto: EmulateUnlimintPayinPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerPayinComplete(detailsCardId, emulateUnlimintPayinPayloadRequestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} detailsSepaId Payin Details Sepa Id or Short Id
     * @param {EmulateSepaPayinPayloadRequestDto} emulateSepaPayinPayloadRequestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerPayinSepaComplete(detailsSepaId: string, emulateSepaPayinPayloadRequestDto: EmulateSepaPayinPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerPayinSepaComplete(detailsSepaId, emulateSepaPayinPayloadRequestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} exchangeId Exchange ID
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerPayoutCryptoComplete(exchangeId: string, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerPayoutCryptoComplete(exchangeId, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} exchangeId Exchange Id
     * @param {EmulateSellPayloadRequestDto} emulateSellPayloadRequestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmulateApi
     */
    public emulateControllerSell(exchangeId: string, emulateSellPayloadRequestDto: EmulateSellPayloadRequestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return EmulateApiFp(this.configuration).emulateControllerSell(exchangeId, emulateSellPayloadRequestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangerApi - axios parameter creator
 * @export
 */
export const ExchangerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ExchangerCreateExchangeRequestDto} exchangerCreateExchangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerCreateExchange: async (exchangerCreateExchangeRequestDto: ExchangerCreateExchangeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangerCreateExchangeRequestDto' is not null or undefined
            assertParamExists('exchangerControllerCreateExchange', 'exchangerCreateExchangeRequestDto', exchangerCreateExchangeRequestDto)
            const localVarPath = `/exchanger/createExchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangerCreateExchangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerEstimateBuyExchange: async (exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangerEstimateRequestDto' is not null or undefined
            assertParamExists('exchangerControllerEstimateBuyExchange', 'exchangerEstimateRequestDto', exchangerEstimateRequestDto)
            const localVarPath = `/exchanger/estimateBuy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangerEstimateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerEstimateSellExchange: async (exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangerEstimateRequestDto' is not null or undefined
            assertParamExists('exchangerControllerEstimateSellExchange', 'exchangerEstimateRequestDto', exchangerEstimateRequestDto)
            const localVarPath = `/exchanger/estimateSell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangerEstimateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetAssets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/exchanger/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetExchangeById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exchangerControllerGetExchangeById', 'id', id)
            const localVarPath = `/exchanger/getExchange/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetExchangesList: async (limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('exchangerControllerGetExchangesList', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('exchangerControllerGetExchangesList', 'offset', offset)
            const localVarPath = `/exchanger/getExchanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetLimits: async (currency?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/exchanger/getLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResumeExchangeRequestDto} resumeExchangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerResumeExchange: async (resumeExchangeRequestDto: ResumeExchangeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resumeExchangeRequestDto' is not null or undefined
            assertParamExists('exchangerControllerResumeExchange', 'resumeExchangeRequestDto', resumeExchangeRequestDto)
            const localVarPath = `/exchanger/resumeExchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resumeExchangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserPaymentSentRequestDto} userPaymentSentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerUserPaymentSent: async (userPaymentSentRequestDto: UserPaymentSentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPaymentSentRequestDto' is not null or undefined
            assertParamExists('exchangerControllerUserPaymentSent', 'userPaymentSentRequestDto', userPaymentSentRequestDto)
            const localVarPath = `/exchanger/userPaymentSent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPaymentSentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerValidateIban: async (iban: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iban' is not null or undefined
            assertParamExists('exchangerControllerValidateIban', 'iban', iban)
            const localVarPath = `/exchanger/validateIban/{iban}`
                .replace(`{${"iban"}}`, encodeURIComponent(String(iban)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangerApi - functional programming interface
 * @export
 */
export const ExchangerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ExchangerCreateExchangeRequestDto} exchangerCreateExchangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerCreateExchange(exchangerCreateExchangeRequestDto: ExchangerCreateExchangeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerCreateExchangeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerCreateExchange(exchangerCreateExchangeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerEstimateBuyExchange(exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerEstimateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerEstimateBuyExchange(exchangerEstimateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerEstimateSellExchange(exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerEstimateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerEstimateSellExchange(exchangerEstimateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerGetAssets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerGetAssetsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerGetAssets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerGetExchangeById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerExchangeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerGetExchangeById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerGetExchangesList(limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerExchangeListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerGetExchangesList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerGetLimits(currency?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerGetLimitsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerGetLimits(currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResumeExchangeRequestDto} resumeExchangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerResumeExchange(resumeExchangeRequestDto: ResumeExchangeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangerExchangeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerResumeExchange(resumeExchangeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserPaymentSentRequestDto} userPaymentSentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerUserPaymentSent(userPaymentSentRequestDto: UserPaymentSentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPaymentSentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerUserPaymentSent(userPaymentSentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangerControllerValidateIban(iban: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateIbanResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangerControllerValidateIban(iban, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangerApi - factory interface
 * @export
 */
export const ExchangerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangerApiFp(configuration)
    return {
        /**
         * 
         * @param {ExchangerCreateExchangeRequestDto} exchangerCreateExchangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerCreateExchange(exchangerCreateExchangeRequestDto: ExchangerCreateExchangeRequestDto, options?: any): AxiosPromise<ExchangerCreateExchangeResponseDto> {
            return localVarFp.exchangerControllerCreateExchange(exchangerCreateExchangeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerEstimateBuyExchange(exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options?: any): AxiosPromise<ExchangerEstimateResponseDto> {
            return localVarFp.exchangerControllerEstimateBuyExchange(exchangerEstimateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerEstimateSellExchange(exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options?: any): AxiosPromise<ExchangerEstimateResponseDto> {
            return localVarFp.exchangerControllerEstimateSellExchange(exchangerEstimateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetAssets(options?: any): AxiosPromise<ExchangerGetAssetsResponseDto> {
            return localVarFp.exchangerControllerGetAssets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetExchangeById(id: string, options?: any): AxiosPromise<ExchangerExchangeResponseDto> {
            return localVarFp.exchangerControllerGetExchangeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetExchangesList(limit: number, offset: number, options?: any): AxiosPromise<ExchangerExchangeListResponseDto> {
            return localVarFp.exchangerControllerGetExchangesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerGetLimits(currency?: string, options?: any): AxiosPromise<ExchangerGetLimitsResponseDto> {
            return localVarFp.exchangerControllerGetLimits(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResumeExchangeRequestDto} resumeExchangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerResumeExchange(resumeExchangeRequestDto: ResumeExchangeRequestDto, options?: any): AxiosPromise<ExchangerExchangeResponseDto> {
            return localVarFp.exchangerControllerResumeExchange(resumeExchangeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserPaymentSentRequestDto} userPaymentSentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerUserPaymentSent(userPaymentSentRequestDto: UserPaymentSentRequestDto, options?: any): AxiosPromise<UserPaymentSentResponseDto> {
            return localVarFp.exchangerControllerUserPaymentSent(userPaymentSentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangerControllerValidateIban(iban: string, options?: any): AxiosPromise<ValidateIbanResponseDto> {
            return localVarFp.exchangerControllerValidateIban(iban, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangerApi - object-oriented interface
 * @export
 * @class ExchangerApi
 * @extends {BaseAPI}
 */
export class ExchangerApi extends BaseAPI {
    /**
     * 
     * @param {ExchangerCreateExchangeRequestDto} exchangerCreateExchangeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerCreateExchange(exchangerCreateExchangeRequestDto: ExchangerCreateExchangeRequestDto, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerCreateExchange(exchangerCreateExchangeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerEstimateBuyExchange(exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerEstimateBuyExchange(exchangerEstimateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangerEstimateRequestDto} exchangerEstimateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerEstimateSellExchange(exchangerEstimateRequestDto: ExchangerEstimateRequestDto, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerEstimateSellExchange(exchangerEstimateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerGetAssets(options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerGetAssets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerGetExchangeById(id: string, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerGetExchangeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerGetExchangesList(limit: number, offset: number, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerGetExchangesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerGetLimits(currency?: string, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerGetLimits(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResumeExchangeRequestDto} resumeExchangeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerResumeExchange(resumeExchangeRequestDto: ResumeExchangeRequestDto, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerResumeExchange(resumeExchangeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserPaymentSentRequestDto} userPaymentSentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerUserPaymentSent(userPaymentSentRequestDto: UserPaymentSentRequestDto, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerUserPaymentSent(userPaymentSentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} iban 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangerApi
     */
    public exchangerControllerValidateIban(iban: string, options?: AxiosRequestConfig) {
        return ExchangerApiFp(this.configuration).exchangerControllerValidateIban(iban, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoControllerGetInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerGetInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoControllerGetInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoControllerGetInfo(options?: any): AxiosPromise<void> {
            return localVarFp.infoControllerGetInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public infoControllerGetInfo(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).infoControllerGetInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get place by google place id
         * @param {string} placeId Place google id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerGetPlaceByPlaceId: async (placeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeId' is not null or undefined
            assertParamExists('locationControllerGetPlaceByPlaceId', 'placeId', placeId)
            const localVarPath = `/location/place-by-place-id/{placeId}`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reverse geocode data
         * @param {number} lat Place latitude
         * @param {number} lng Place longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerGetUserAccounts: async (lat: number, lng: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('locationControllerGetUserAccounts', 'lat', lat)
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('locationControllerGetUserAccounts', 'lng', lng)
            const localVarPath = `/location/reverse-geocode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get place by city name and country(optional)
         * @param {SearchPlaceRequestDto} searchPlaceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerSearchPlace: async (searchPlaceRequestDto: SearchPlaceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPlaceRequestDto' is not null or undefined
            assertParamExists('locationControllerSearchPlace', 'searchPlaceRequestDto', searchPlaceRequestDto)
            const localVarPath = `/location/search-place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPlaceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get place by google place id
         * @param {string} placeId Place google id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerGetPlaceByPlaceId(placeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaceResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerGetPlaceByPlaceId(placeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reverse geocode data
         * @param {number} lat Place latitude
         * @param {number} lng Place longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerGetUserAccounts(lat: number, lng: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaceResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerGetUserAccounts(lat, lng, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get place by city name and country(optional)
         * @param {SearchPlaceRequestDto} searchPlaceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerSearchPlace(searchPlaceRequestDto: SearchPlaceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaceResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerSearchPlace(searchPlaceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get place by google place id
         * @param {string} placeId Place google id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerGetPlaceByPlaceId(placeId: string, options?: any): AxiosPromise<Array<PlaceResponseDto>> {
            return localVarFp.locationControllerGetPlaceByPlaceId(placeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reverse geocode data
         * @param {number} lat Place latitude
         * @param {number} lng Place longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerGetUserAccounts(lat: number, lng: number, options?: any): AxiosPromise<Array<PlaceResponseDto>> {
            return localVarFp.locationControllerGetUserAccounts(lat, lng, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get place by city name and country(optional)
         * @param {SearchPlaceRequestDto} searchPlaceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerSearchPlace(searchPlaceRequestDto: SearchPlaceRequestDto, options?: any): AxiosPromise<Array<PlaceResponseDto>> {
            return localVarFp.locationControllerSearchPlace(searchPlaceRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary Get place by google place id
     * @param {string} placeId Place google id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationControllerGetPlaceByPlaceId(placeId: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).locationControllerGetPlaceByPlaceId(placeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reverse geocode data
     * @param {number} lat Place latitude
     * @param {number} lng Place longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationControllerGetUserAccounts(lat: number, lng: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).locationControllerGetUserAccounts(lat, lng, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get place by city name and country(optional)
     * @param {SearchPlaceRequestDto} searchPlaceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationControllerSearchPlace(searchPlaceRequestDto: SearchPlaceRequestDto, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).locationControllerSearchPlace(searchPlaceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gather frontend logs
         * @param {LogRequestDto} logRequestDto Event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsControllerLogEvent: async (logRequestDto: LogRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logRequestDto' is not null or undefined
            assertParamExists('logsControllerLogEvent', 'logRequestDto', logRequestDto)
            const localVarPath = `/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gather frontend logs
         * @param {LogRequestDto} logRequestDto Event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsControllerLogEvent(logRequestDto: LogRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsControllerLogEvent(logRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gather frontend logs
         * @param {LogRequestDto} logRequestDto Event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsControllerLogEvent(logRequestDto: LogRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.logsControllerLogEvent(logRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * 
     * @summary Gather frontend logs
     * @param {LogRequestDto} logRequestDto Event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public logsControllerLogEvent(logRequestDto: LogRequestDto, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).logsControllerLogEvent(logRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketingApi - axios parameter creator
 * @export
 */
export const MarketingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingControllerGetWhitelistCountries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/marketing/whitelist/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WhitelistRequestOtpPayloadMarketingDto} whitelistRequestOtpPayloadMarketingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingControllerRequestOtp: async (whitelistRequestOtpPayloadMarketingDto: WhitelistRequestOtpPayloadMarketingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whitelistRequestOtpPayloadMarketingDto' is not null or undefined
            assertParamExists('marketingControllerRequestOtp', 'whitelistRequestOtpPayloadMarketingDto', whitelistRequestOtpPayloadMarketingDto)
            const localVarPath = `/marketing/whitelist/requestOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whitelistRequestOtpPayloadMarketingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WhitelistRegisterPayloadMarketingDto} whitelistRegisterPayloadMarketingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingControllerWhitelistRegister: async (whitelistRegisterPayloadMarketingDto: WhitelistRegisterPayloadMarketingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whitelistRegisterPayloadMarketingDto' is not null or undefined
            assertParamExists('marketingControllerWhitelistRegister', 'whitelistRegisterPayloadMarketingDto', whitelistRegisterPayloadMarketingDto)
            const localVarPath = `/marketing/whitelist/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whitelistRegisterPayloadMarketingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingApi - functional programming interface
 * @export
 */
export const MarketingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingControllerGetWhitelistCountries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhitelistGetCountriesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingControllerGetWhitelistCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WhitelistRequestOtpPayloadMarketingDto} whitelistRequestOtpPayloadMarketingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingControllerRequestOtp(whitelistRequestOtpPayloadMarketingDto: WhitelistRequestOtpPayloadMarketingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingControllerRequestOtp(whitelistRequestOtpPayloadMarketingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WhitelistRegisterPayloadMarketingDto} whitelistRegisterPayloadMarketingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingControllerWhitelistRegister(whitelistRegisterPayloadMarketingDto: WhitelistRegisterPayloadMarketingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhitelistRegisterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingControllerWhitelistRegister(whitelistRegisterPayloadMarketingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketingApi - factory interface
 * @export
 */
export const MarketingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingControllerGetWhitelistCountries(options?: any): AxiosPromise<WhitelistGetCountriesResponseDto> {
            return localVarFp.marketingControllerGetWhitelistCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WhitelistRequestOtpPayloadMarketingDto} whitelistRequestOtpPayloadMarketingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingControllerRequestOtp(whitelistRequestOtpPayloadMarketingDto: WhitelistRequestOtpPayloadMarketingDto, options?: any): AxiosPromise<object> {
            return localVarFp.marketingControllerRequestOtp(whitelistRequestOtpPayloadMarketingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WhitelistRegisterPayloadMarketingDto} whitelistRegisterPayloadMarketingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingControllerWhitelistRegister(whitelistRegisterPayloadMarketingDto: WhitelistRegisterPayloadMarketingDto, options?: any): AxiosPromise<WhitelistRegisterResponseDto> {
            return localVarFp.marketingControllerWhitelistRegister(whitelistRegisterPayloadMarketingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketingApi - object-oriented interface
 * @export
 * @class MarketingApi
 * @extends {BaseAPI}
 */
export class MarketingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public marketingControllerGetWhitelistCountries(options?: AxiosRequestConfig) {
        return MarketingApiFp(this.configuration).marketingControllerGetWhitelistCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WhitelistRequestOtpPayloadMarketingDto} whitelistRequestOtpPayloadMarketingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public marketingControllerRequestOtp(whitelistRequestOtpPayloadMarketingDto: WhitelistRequestOtpPayloadMarketingDto, options?: AxiosRequestConfig) {
        return MarketingApiFp(this.configuration).marketingControllerRequestOtp(whitelistRequestOtpPayloadMarketingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WhitelistRegisterPayloadMarketingDto} whitelistRegisterPayloadMarketingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public marketingControllerWhitelistRegister(whitelistRegisterPayloadMarketingDto: WhitelistRegisterPayloadMarketingDto, options?: AxiosRequestConfig) {
        return MarketingApiFp(this.configuration).marketingControllerWhitelistRegister(whitelistRegisterPayloadMarketingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnersApi - axios parameter creator
 * @export
 */
export const PartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} partnerId Partner id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersControllerGetPartnerSettings: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('partnersControllerGetPartnerSettings', 'partnerId', partnerId)
            const localVarPath = `/partners/settings/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} partnerId Partner id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnersControllerGetPartnerSettings(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPartnerSettingsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnersControllerGetPartnerSettings(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} partnerId Partner id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersControllerGetPartnerSettings(partnerId: string, options?: any): AxiosPromise<GetPartnerSettingsResponseDto> {
            return localVarFp.partnersControllerGetPartnerSettings(partnerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     * 
     * @param {string} partnerId Partner id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersControllerGetPartnerSettings(partnerId: string, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).partnersControllerGetPartnerSettings(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferralProgramApi - axios parameter creator
 * @export
 */
export const ReferralProgramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerGetReferralProgramRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/referral-program/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {boolean} [assignedReward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerGetUserReferralContracts: async (outputCount: number, outputOffset: number, assignedReward?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'outputCount' is not null or undefined
            assertParamExists('referralProgramControllerGetUserReferralContracts', 'outputCount', outputCount)
            // verify required parameter 'outputOffset' is not null or undefined
            assertParamExists('referralProgramControllerGetUserReferralContracts', 'outputOffset', outputOffset)
            const localVarPath = `/referral-program/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assignedReward !== undefined) {
                localVarQueryParameter['assignedReward'] = assignedReward;
            }

            if (outputCount !== undefined) {
                localVarQueryParameter['outputCount'] = outputCount;
            }

            if (outputOffset !== undefined) {
                localVarQueryParameter['outputOffset'] = outputOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerGetUserReferralRewards: async (outputCount: number, outputOffset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'outputCount' is not null or undefined
            assertParamExists('referralProgramControllerGetUserReferralRewards', 'outputCount', outputCount)
            // verify required parameter 'outputOffset' is not null or undefined
            assertParamExists('referralProgramControllerGetUserReferralRewards', 'outputOffset', outputOffset)
            const localVarPath = `/referral-program/rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (outputCount !== undefined) {
                localVarQueryParameter['outputCount'] = outputCount;
            }

            if (outputOffset !== undefined) {
                localVarQueryParameter['outputOffset'] = outputOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateReferralCodePayloadDto} validateReferralCodePayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerValidateReferralCode: async (validateReferralCodePayloadDto: ValidateReferralCodePayloadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateReferralCodePayloadDto' is not null or undefined
            assertParamExists('referralProgramControllerValidateReferralCode', 'validateReferralCodePayloadDto', validateReferralCodePayloadDto)
            const localVarPath = `/referral-program/validateReferralCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateReferralCodePayloadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralProgramApi - functional programming interface
 * @export
 */
export const ReferralProgramApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralProgramApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralProgramControllerGetReferralProgramRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReferralProgramRulesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralProgramControllerGetReferralProgramRules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {boolean} [assignedReward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralProgramControllerGetUserReferralContracts(outputCount: number, outputOffset: number, assignedReward?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserReferralContractsControllerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralProgramControllerGetUserReferralContracts(outputCount, outputOffset, assignedReward, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralProgramControllerGetUserReferralRewards(outputCount: number, outputOffset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserRewardsControllerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralProgramControllerGetUserReferralRewards(outputCount, outputOffset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateReferralCodePayloadDto} validateReferralCodePayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralProgramControllerValidateReferralCode(validateReferralCodePayloadDto: ValidateReferralCodePayloadDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateReferralCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralProgramControllerValidateReferralCode(validateReferralCodePayloadDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferralProgramApi - factory interface
 * @export
 */
export const ReferralProgramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralProgramApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerGetReferralProgramRules(options?: any): AxiosPromise<GetReferralProgramRulesResponseDto> {
            return localVarFp.referralProgramControllerGetReferralProgramRules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {boolean} [assignedReward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerGetUserReferralContracts(outputCount: number, outputOffset: number, assignedReward?: boolean, options?: any): AxiosPromise<GetUserReferralContractsControllerResponseDto> {
            return localVarFp.referralProgramControllerGetUserReferralContracts(outputCount, outputOffset, assignedReward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerGetUserReferralRewards(outputCount: number, outputOffset: number, options?: any): AxiosPromise<GetUserRewardsControllerResponseDto> {
            return localVarFp.referralProgramControllerGetUserReferralRewards(outputCount, outputOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateReferralCodePayloadDto} validateReferralCodePayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralProgramControllerValidateReferralCode(validateReferralCodePayloadDto: ValidateReferralCodePayloadDto, options?: any): AxiosPromise<ValidateReferralCodeResponseDto> {
            return localVarFp.referralProgramControllerValidateReferralCode(validateReferralCodePayloadDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralProgramApi - object-oriented interface
 * @export
 * @class ReferralProgramApi
 * @extends {BaseAPI}
 */
export class ReferralProgramApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralProgramApi
     */
    public referralProgramControllerGetReferralProgramRules(options?: AxiosRequestConfig) {
        return ReferralProgramApiFp(this.configuration).referralProgramControllerGetReferralProgramRules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} outputCount 
     * @param {number} outputOffset 
     * @param {boolean} [assignedReward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralProgramApi
     */
    public referralProgramControllerGetUserReferralContracts(outputCount: number, outputOffset: number, assignedReward?: boolean, options?: AxiosRequestConfig) {
        return ReferralProgramApiFp(this.configuration).referralProgramControllerGetUserReferralContracts(outputCount, outputOffset, assignedReward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} outputCount 
     * @param {number} outputOffset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralProgramApi
     */
    public referralProgramControllerGetUserReferralRewards(outputCount: number, outputOffset: number, options?: AxiosRequestConfig) {
        return ReferralProgramApiFp(this.configuration).referralProgramControllerGetUserReferralRewards(outputCount, outputOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateReferralCodePayloadDto} validateReferralCodePayloadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralProgramApi
     */
    public referralProgramControllerValidateReferralCode(validateReferralCodePayloadDto: ValidateReferralCodePayloadDto, options?: AxiosRequestConfig) {
        return ReferralProgramApiFp(this.configuration).referralProgramControllerValidateReferralCode(validateReferralCodePayloadDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionKyc: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/security/requestPermissionKyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionPoa: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/security/requestPermissionPoa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionPow: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/security/requestPermissionPow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'KYC' | 'POA' | 'POW'} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionVerification: async (level: 'KYC' | 'POA' | 'POW', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level' is not null or undefined
            assertParamExists('securityControllerRequestPermissionVerification', 'level', level)
            const localVarPath = `/security/requestPermissionVerification/{level}`
                .replace(`{${"level"}}`, encodeURIComponent(String(level)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async securityControllerRequestPermissionKyc(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPermissionResponseSecurityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityControllerRequestPermissionKyc(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async securityControllerRequestPermissionPoa(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPermissionResponseSecurityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityControllerRequestPermissionPoa(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async securityControllerRequestPermissionPow(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPermissionResponseSecurityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityControllerRequestPermissionPow(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'KYC' | 'POA' | 'POW'} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityControllerRequestPermissionVerification(level: 'KYC' | 'POA' | 'POW', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPermissionResponseSecurityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityControllerRequestPermissionVerification(level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionKyc(options?: any): AxiosPromise<RequestPermissionResponseSecurityDto> {
            return localVarFp.securityControllerRequestPermissionKyc(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionPoa(options?: any): AxiosPromise<RequestPermissionResponseSecurityDto> {
            return localVarFp.securityControllerRequestPermissionPoa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionPow(options?: any): AxiosPromise<RequestPermissionResponseSecurityDto> {
            return localVarFp.securityControllerRequestPermissionPow(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'KYC' | 'POA' | 'POW'} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityControllerRequestPermissionVerification(level: 'KYC' | 'POA' | 'POW', options?: any): AxiosPromise<RequestPermissionResponseSecurityDto> {
            return localVarFp.securityControllerRequestPermissionVerification(level, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityControllerRequestPermissionKyc(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityControllerRequestPermissionKyc(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityControllerRequestPermissionPoa(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityControllerRequestPermissionPoa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityControllerRequestPermissionPow(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityControllerRequestPermissionPow(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'KYC' | 'POA' | 'POW'} level 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public securityControllerRequestPermissionVerification(level: 'KYC' | 'POA' | 'POW', options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).securityControllerRequestPermissionVerification(level, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerBackfillWalletTransactions: async (xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/backfill/walletTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessExchangeRequestDto} processExchangeRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerProcessExchange: async (processExchangeRequestDto: ProcessExchangeRequestDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processExchangeRequestDto' is not null or undefined
            assertParamExists('serviceControllerProcessExchange', 'processExchangeRequestDto', processExchangeRequestDto)
            const localVarPath = `/service/processExchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processExchangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerRecoveryAllWallets: async (xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/recoveryAllWallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecoveryWalletByUserPayloadServiceDto} recoveryWalletByUserPayloadServiceDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerRecoveryWalletByUser: async (recoveryWalletByUserPayloadServiceDto: RecoveryWalletByUserPayloadServiceDto, xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recoveryWalletByUserPayloadServiceDto' is not null or undefined
            assertParamExists('serviceControllerRecoveryWalletByUser', 'recoveryWalletByUserPayloadServiceDto', recoveryWalletByUserPayloadServiceDto)
            const localVarPath = `/service/recoveryWalletByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recoveryWalletByUserPayloadServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerStartMyWorkflowFailure: async (xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/workflow/failure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerStartMyWorkflowSuccess: async (xServiceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/workflow/success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xServiceToken !== undefined && xServiceToken !== null) {
                localVarHeaderParameter['x-service-token'] = String(xServiceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerBackfillWalletTransactions(xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerBackfillWalletTransactions(xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessExchangeRequestDto} processExchangeRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerProcessExchange(processExchangeRequestDto: ProcessExchangeRequestDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerProcessExchange(processExchangeRequestDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerRecoveryAllWallets(xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerRecoveryAllWallets(xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RecoveryWalletByUserPayloadServiceDto} recoveryWalletByUserPayloadServiceDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerRecoveryWalletByUser(recoveryWalletByUserPayloadServiceDto: RecoveryWalletByUserPayloadServiceDto, xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerRecoveryWalletByUser(recoveryWalletByUserPayloadServiceDto, xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerStartMyWorkflowFailure(xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerStartMyWorkflowFailure(xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerStartMyWorkflowSuccess(xServiceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerStartMyWorkflowSuccess(xServiceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerBackfillWalletTransactions(xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerBackfillWalletTransactions(xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessExchangeRequestDto} processExchangeRequestDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerProcessExchange(processExchangeRequestDto: ProcessExchangeRequestDto, xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerProcessExchange(processExchangeRequestDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerRecoveryAllWallets(xServiceToken?: string, options?: any): AxiosPromise<object> {
            return localVarFp.serviceControllerRecoveryAllWallets(xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecoveryWalletByUserPayloadServiceDto} recoveryWalletByUserPayloadServiceDto 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerRecoveryWalletByUser(recoveryWalletByUserPayloadServiceDto: RecoveryWalletByUserPayloadServiceDto, xServiceToken?: string, options?: any): AxiosPromise<object> {
            return localVarFp.serviceControllerRecoveryWalletByUser(recoveryWalletByUserPayloadServiceDto, xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerStartMyWorkflowFailure(xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerStartMyWorkflowFailure(xServiceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xServiceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerStartMyWorkflowSuccess(xServiceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerStartMyWorkflowSuccess(xServiceToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public serviceControllerBackfillWalletTransactions(xServiceToken?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).serviceControllerBackfillWalletTransactions(xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessExchangeRequestDto} processExchangeRequestDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public serviceControllerProcessExchange(processExchangeRequestDto: ProcessExchangeRequestDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).serviceControllerProcessExchange(processExchangeRequestDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public serviceControllerRecoveryAllWallets(xServiceToken?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).serviceControllerRecoveryAllWallets(xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RecoveryWalletByUserPayloadServiceDto} recoveryWalletByUserPayloadServiceDto 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public serviceControllerRecoveryWalletByUser(recoveryWalletByUserPayloadServiceDto: RecoveryWalletByUserPayloadServiceDto, xServiceToken?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).serviceControllerRecoveryWalletByUser(recoveryWalletByUserPayloadServiceDto, xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public serviceControllerStartMyWorkflowFailure(xServiceToken?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).serviceControllerStartMyWorkflowFailure(xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xServiceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public serviceControllerStartMyWorkflowSuccess(xServiceToken?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).serviceControllerStartMyWorkflowSuccess(xServiceToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get system settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsControllerGetSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get system settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsControllerGetSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientSystemSettingsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsControllerGetSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get system settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsControllerGetSettings(options?: any): AxiosPromise<GetClientSystemSettingsResponseDto> {
            return localVarFp.settingsControllerGetSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get system settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingsControllerGetSettings(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingsControllerGetSettings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerGetCurrencies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerListAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerListTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SlackNotificationPayloadTestDto} slackNotificationPayloadTestDto Channel is \&quot;SlackChannel\&quot; enum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerSlackNotification: async (slackNotificationPayloadTestDto: SlackNotificationPayloadTestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slackNotificationPayloadTestDto' is not null or undefined
            assertParamExists('testControllerSlackNotification', 'slackNotificationPayloadTestDto', slackNotificationPayloadTestDto)
            const localVarPath = `/test/slackNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackNotificationPayloadTestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/workflow/instance/MyWorkflow1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/workflow/instance/MyWorkflow2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerGetCurrencies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerGetCurrencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerListAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerListAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerListTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerListTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SlackNotificationPayloadTestDto} slackNotificationPayloadTestDto Channel is \&quot;SlackChannel\&quot; enum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerSlackNotification(slackNotificationPayloadTestDto: SlackNotificationPayloadTestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerSlackNotification(slackNotificationPayloadTestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerStartMyWorkflow1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerStartMyWorkflow1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerStartMyWorkflow2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerStartMyWorkflow2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerGetCurrencies(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerGetCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerListAccounts(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerListAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerListTransactions(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerListTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SlackNotificationPayloadTestDto} slackNotificationPayloadTestDto Channel is \&quot;SlackChannel\&quot; enum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerSlackNotification(slackNotificationPayloadTestDto: SlackNotificationPayloadTestDto, options?: any): AxiosPromise<void> {
            return localVarFp.testControllerSlackNotification(slackNotificationPayloadTestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow1(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerStartMyWorkflow1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow2(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerStartMyWorkflow2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testControllerGetCurrencies(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testControllerGetCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testControllerListAccounts(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testControllerListAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testControllerListTransactions(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testControllerListTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SlackNotificationPayloadTestDto} slackNotificationPayloadTestDto Channel is \&quot;SlackChannel\&quot; enum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testControllerSlackNotification(slackNotificationPayloadTestDto: SlackNotificationPayloadTestDto, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testControllerSlackNotification(slackNotificationPayloadTestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testControllerStartMyWorkflow1(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testControllerStartMyWorkflow1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testControllerStartMyWorkflow2(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testControllerStartMyWorkflow2(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAccountsApi - axios parameter creator
 * @export
 */
export const UserAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountsControllerGetUserAccountDetails: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('userAccountsControllerGetUserAccountDetails', 'accountId', accountId)
            const localVarPath = `/user-accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountsControllerGetUserAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAccountsApi - functional programming interface
 * @export
 */
export const UserAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAccountsControllerGetUserAccountDetails(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAccountsControllerGetUserAccountDetails(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAccountsControllerGetUserAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAccountsControllerGetUserAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAccountsApi - factory interface
 * @export
 */
export const UserAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountsControllerGetUserAccountDetails(accountId: string, options?: any): AxiosPromise<UserAccountDetailsDto> {
            return localVarFp.userAccountsControllerGetUserAccountDetails(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountsControllerGetUserAccounts(options?: any): AxiosPromise<Array<UserAccountDto>> {
            return localVarFp.userAccountsControllerGetUserAccounts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAccountsApi - object-oriented interface
 * @export
 * @class UserAccountsApi
 * @extends {BaseAPI}
 */
export class UserAccountsApi extends BaseAPI {
    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccountsApi
     */
    public userAccountsControllerGetUserAccountDetails(accountId: string, options?: AxiosRequestConfig) {
        return UserAccountsApiFp(this.configuration).userAccountsControllerGetUserAccountDetails(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccountsApi
     */
    public userAccountsControllerGetUserAccounts(options?: AxiosRequestConfig) {
        return UserAccountsApiFp(this.configuration).userAccountsControllerGetUserAccounts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddRecommendedWalletAssetsPayloadUserSettingsDto} addRecommendedWalletAssetsPayloadUserSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsControllerAddRecommendedWalletAssets: async (addRecommendedWalletAssetsPayloadUserSettingsDto: AddRecommendedWalletAssetsPayloadUserSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addRecommendedWalletAssetsPayloadUserSettingsDto' is not null or undefined
            assertParamExists('userSettingsControllerAddRecommendedWalletAssets', 'addRecommendedWalletAssetsPayloadUserSettingsDto', addRecommendedWalletAssetsPayloadUserSettingsDto)
            const localVarPath = `/user-settings/addRecommendedWalletAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRecommendedWalletAssetsPayloadUserSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HideAssetsPayloadUserSettingsDto} hideAssetsPayloadUserSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsControllerHideAssets: async (hideAssetsPayloadUserSettingsDto: HideAssetsPayloadUserSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hideAssetsPayloadUserSettingsDto' is not null or undefined
            assertParamExists('userSettingsControllerHideAssets', 'hideAssetsPayloadUserSettingsDto', hideAssetsPayloadUserSettingsDto)
            const localVarPath = `/user-settings/hideWalletAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hideAssetsPayloadUserSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsControllerToggleHideBalance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-settings/toggleHideBalance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddRecommendedWalletAssetsPayloadUserSettingsDto} addRecommendedWalletAssetsPayloadUserSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsControllerAddRecommendedWalletAssets(addRecommendedWalletAssetsPayloadUserSettingsDto: AddRecommendedWalletAssetsPayloadUserSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsControllerAddRecommendedWalletAssets(addRecommendedWalletAssetsPayloadUserSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HideAssetsPayloadUserSettingsDto} hideAssetsPayloadUserSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsControllerHideAssets(hideAssetsPayloadUserSettingsDto: HideAssetsPayloadUserSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsControllerHideAssets(hideAssetsPayloadUserSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsControllerToggleHideBalance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsControllerToggleHideBalance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {AddRecommendedWalletAssetsPayloadUserSettingsDto} addRecommendedWalletAssetsPayloadUserSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsControllerAddRecommendedWalletAssets(addRecommendedWalletAssetsPayloadUserSettingsDto: AddRecommendedWalletAssetsPayloadUserSettingsDto, options?: any): AxiosPromise<object> {
            return localVarFp.userSettingsControllerAddRecommendedWalletAssets(addRecommendedWalletAssetsPayloadUserSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HideAssetsPayloadUserSettingsDto} hideAssetsPayloadUserSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsControllerHideAssets(hideAssetsPayloadUserSettingsDto: HideAssetsPayloadUserSettingsDto, options?: any): AxiosPromise<object> {
            return localVarFp.userSettingsControllerHideAssets(hideAssetsPayloadUserSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsControllerToggleHideBalance(options?: any): AxiosPromise<object> {
            return localVarFp.userSettingsControllerToggleHideBalance(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
    /**
     * 
     * @param {AddRecommendedWalletAssetsPayloadUserSettingsDto} addRecommendedWalletAssetsPayloadUserSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public userSettingsControllerAddRecommendedWalletAssets(addRecommendedWalletAssetsPayloadUserSettingsDto: AddRecommendedWalletAssetsPayloadUserSettingsDto, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).userSettingsControllerAddRecommendedWalletAssets(addRecommendedWalletAssetsPayloadUserSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HideAssetsPayloadUserSettingsDto} hideAssetsPayloadUserSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public userSettingsControllerHideAssets(hideAssetsPayloadUserSettingsDto: HideAssetsPayloadUserSettingsDto, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).userSettingsControllerHideAssets(hideAssetsPayloadUserSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public userSettingsControllerToggleHideBalance(options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).userSettingsControllerToggleHideBalance(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AcceptNewTermsPayloadUsersDto} acceptNewTermsPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAcceptNewTerms: async (acceptNewTermsPayloadUsersDto: AcceptNewTermsPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptNewTermsPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerAcceptNewTerms', 'acceptNewTermsPayloadUsersDto', acceptNewTermsPayloadUsersDto)
            const localVarPath = `/users/acceptNewTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptNewTermsPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorizeUserPayloadUsersDto} authorizeUserPayloadUsersDto 
         * @param {string} [xAppType] Distinguish between requests coming from a mobile app and a web app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthorizeUser: async (authorizeUserPayloadUsersDto: AuthorizeUserPayloadUsersDto, xAppType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizeUserPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerAuthorizeUser', 'authorizeUserPayloadUsersDto', authorizeUserPayloadUsersDto)
            const localVarPath = `/users/authorizeUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAppType !== undefined && xAppType !== null) {
                localVarHeaderParameter['X-App-Type'] = String(xAppType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizeUserPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeEmailPayloadUsersDto} changeEmailPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeEmail: async (changeEmailPayloadUsersDto: ChangeEmailPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerChangeEmail', 'changeEmailPayloadUsersDto', changeEmailPayloadUsersDto)
            const localVarPath = `/users/changeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeactivateMeFaPayloadUsersDto} deactivateMeFaPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeactivateMe: async (deactivateMeFaPayloadUsersDto: DeactivateMeFaPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deactivateMeFaPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerDeactivateMe', 'deactivateMeFaPayloadUsersDto', deactivateMeFaPayloadUsersDto)
            const localVarPath = `/users/deactivateMe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deactivateMeFaPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGaSessionIdPayloadUsersDto} updateGaSessionIdPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGaSessionId: async (updateGaSessionIdPayloadUsersDto: UpdateGaSessionIdPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGaSessionIdPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerGaSessionId', 'updateGaSessionIdPayloadUsersDto', updateGaSessionIdPayloadUsersDto)
            const localVarPath = `/users/gaSessionId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGaSessionIdPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/getUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInitTotp2Fa: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/initTotp2Fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginOrRegisterPayloadUsersDto} loginOrRegisterPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLoginOrRegister: async (loginOrRegisterPayloadUsersDto: LoginOrRegisterPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginOrRegisterPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerLoginOrRegister', 'loginOrRegisterPayloadUsersDto', loginOrRegisterPayloadUsersDto)
            const localVarPath = `/users/loginOrRegister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginOrRegisterPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MfaLoginPayloadUsersDto} mfaLoginPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMfaLogin: async (mfaLoginPayloadUsersDto: MfaLoginPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mfaLoginPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerMfaLogin', 'mfaLoginPayloadUsersDto', mfaLoginPayloadUsersDto)
            const localVarPath = `/users/mfaLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaLoginPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MobileBiometricLoginPayloadUsersDto} mobileBiometricLoginPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMobileBiometricLogin: async (mobileBiometricLoginPayloadUsersDto: MobileBiometricLoginPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileBiometricLoginPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerMobileBiometricLogin', 'mobileBiometricLoginPayloadUsersDto', mobileBiometricLoginPayloadUsersDto)
            const localVarPath = `/users/mobileBiometricLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mobileBiometricLoginPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenPayloadUsersDto} refreshTokenPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefreshToken: async (refreshTokenPayloadUsersDto: RefreshTokenPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerRefreshToken', 'refreshTokenPayloadUsersDto', refreshTokenPayloadUsersDto)
            const localVarPath = `/users/refreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDevicePayloadUsersDto} registerDevicePayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegisterDevice: async (registerDevicePayloadUsersDto: RegisterDevicePayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDevicePayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerRegisterDevice', 'registerDevicePayloadUsersDto', registerDevicePayloadUsersDto)
            const localVarPath = `/users/registerDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDevicePayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestOtpPayloadUsersDto} requestOtpPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRequestOtp: async (requestOtpPayloadUsersDto: RequestOtpPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestOtpPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerRequestOtp', 'requestOtpPayloadUsersDto', requestOtpPayloadUsersDto)
            const localVarPath = `/users/requestOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestOtpPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendOtpPayloadUsersDto} resendOtpPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendOtp: async (resendOtpPayloadUsersDto: ResendOtpPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendOtpPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerResendOtp', 'resendOtpPayloadUsersDto', resendOtpPayloadUsersDto)
            const localVarPath = `/users/resendOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendOtpPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ToggleTotp2FaPayloadUsersDto} toggleTotp2FaPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerToggleTotp2Fa: async (toggleTotp2FaPayloadUsersDto: ToggleTotp2FaPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toggleTotp2FaPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerToggleTotp2Fa', 'toggleTotp2FaPayloadUsersDto', toggleTotp2FaPayloadUsersDto)
            const localVarPath = `/users/toggleTotp2Fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleTotp2FaPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnbindDevicePayloadUsersDto} unbindDevicePayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUnbindDevice: async (unbindDevicePayloadUsersDto: UnbindDevicePayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unbindDevicePayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerUnbindDevice', 'unbindDevicePayloadUsersDto', unbindDevicePayloadUsersDto)
            const localVarPath = `/users/unbindDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unbindDevicePayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailPayloadUsersDto} verifyEmailPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyEmail: async (verifyEmailPayloadUsersDto: VerifyEmailPayloadUsersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailPayloadUsersDto' is not null or undefined
            assertParamExists('usersControllerVerifyEmail', 'verifyEmailPayloadUsersDto', verifyEmailPayloadUsersDto)
            const localVarPath = `/users/verifyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailPayloadUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AcceptNewTermsPayloadUsersDto} acceptNewTermsPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAcceptNewTerms(acceptNewTermsPayloadUsersDto: AcceptNewTermsPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterDeviceResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAcceptNewTerms(acceptNewTermsPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorizeUserPayloadUsersDto} authorizeUserPayloadUsersDto 
         * @param {string} [xAppType] Distinguish between requests coming from a mobile app and a web app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAuthorizeUser(authorizeUserPayloadUsersDto: AuthorizeUserPayloadUsersDto, xAppType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeUserResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAuthorizeUser(authorizeUserPayloadUsersDto, xAppType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangeEmailPayloadUsersDto} changeEmailPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerChangeEmail(changeEmailPayloadUsersDto: ChangeEmailPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerChangeEmail(changeEmailPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeactivateMeFaPayloadUsersDto} deactivateMeFaPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeactivateMe(deactivateMeFaPayloadUsersDto: DeactivateMeFaPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeactivateMe(deactivateMeFaPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateGaSessionIdPayloadUsersDto} updateGaSessionIdPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGaSessionId(updateGaSessionIdPayloadUsersDto: UpdateGaSessionIdPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGaSessionId(updateGaSessionIdPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerInitTotp2Fa(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitTotp2FaResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerInitTotp2Fa(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginOrRegisterPayloadUsersDto} loginOrRegisterPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLoginOrRegister(loginOrRegisterPayloadUsersDto: LoginOrRegisterPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginOrRegisterResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLoginOrRegister(loginOrRegisterPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MfaLoginPayloadUsersDto} mfaLoginPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerMfaLogin(mfaLoginPayloadUsersDto: MfaLoginPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaLoginResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerMfaLogin(mfaLoginPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MobileBiometricLoginPayloadUsersDto} mobileBiometricLoginPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerMobileBiometricLogin(mobileBiometricLoginPayloadUsersDto: MobileBiometricLoginPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileBiometricLoginResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerMobileBiometricLogin(mobileBiometricLoginPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenPayloadUsersDto} refreshTokenPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRefreshToken(refreshTokenPayloadUsersDto: RefreshTokenPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRefreshToken(refreshTokenPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterDevicePayloadUsersDto} registerDevicePayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRegisterDevice(registerDevicePayloadUsersDto: RegisterDevicePayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterDeviceResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRegisterDevice(registerDevicePayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestOtpPayloadUsersDto} requestOtpPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRequestOtp(requestOtpPayloadUsersDto: RequestOtpPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRequestOtp(requestOtpPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResendOtpPayloadUsersDto} resendOtpPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResendOtp(resendOtpPayloadUsersDto: ResendOtpPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResendOtp(resendOtpPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ToggleTotp2FaPayloadUsersDto} toggleTotp2FaPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerToggleTotp2Fa(toggleTotp2FaPayloadUsersDto: ToggleTotp2FaPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerToggleTotp2Fa(toggleTotp2FaPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnbindDevicePayloadUsersDto} unbindDevicePayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUnbindDevice(unbindDevicePayloadUsersDto: UnbindDevicePayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUnbindDevice(unbindDevicePayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmailPayloadUsersDto} verifyEmailPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerVerifyEmail(verifyEmailPayloadUsersDto: VerifyEmailPayloadUsersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyEmailResponseUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerVerifyEmail(verifyEmailPayloadUsersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {AcceptNewTermsPayloadUsersDto} acceptNewTermsPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAcceptNewTerms(acceptNewTermsPayloadUsersDto: AcceptNewTermsPayloadUsersDto, options?: any): AxiosPromise<RegisterDeviceResponseUsersDto> {
            return localVarFp.usersControllerAcceptNewTerms(acceptNewTermsPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorizeUserPayloadUsersDto} authorizeUserPayloadUsersDto 
         * @param {string} [xAppType] Distinguish between requests coming from a mobile app and a web app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthorizeUser(authorizeUserPayloadUsersDto: AuthorizeUserPayloadUsersDto, xAppType?: string, options?: any): AxiosPromise<AuthorizeUserResponseUsersDto> {
            return localVarFp.usersControllerAuthorizeUser(authorizeUserPayloadUsersDto, xAppType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeEmailPayloadUsersDto} changeEmailPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeEmail(changeEmailPayloadUsersDto: ChangeEmailPayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerChangeEmail(changeEmailPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeactivateMeFaPayloadUsersDto} deactivateMeFaPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeactivateMe(deactivateMeFaPayloadUsersDto: DeactivateMeFaPayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerDeactivateMe(deactivateMeFaPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGaSessionIdPayloadUsersDto} updateGaSessionIdPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGaSessionId(updateGaSessionIdPayloadUsersDto: UpdateGaSessionIdPayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerGaSessionId(updateGaSessionIdPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUser(options?: any): AxiosPromise<GetUserResponseUsersDto> {
            return localVarFp.usersControllerGetUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInitTotp2Fa(options?: any): AxiosPromise<InitTotp2FaResponseUsersDto> {
            return localVarFp.usersControllerInitTotp2Fa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginOrRegisterPayloadUsersDto} loginOrRegisterPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLoginOrRegister(loginOrRegisterPayloadUsersDto: LoginOrRegisterPayloadUsersDto, options?: any): AxiosPromise<LoginOrRegisterResponseUsersDto> {
            return localVarFp.usersControllerLoginOrRegister(loginOrRegisterPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogout(options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MfaLoginPayloadUsersDto} mfaLoginPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMfaLogin(mfaLoginPayloadUsersDto: MfaLoginPayloadUsersDto, options?: any): AxiosPromise<MfaLoginResponseUsersDto> {
            return localVarFp.usersControllerMfaLogin(mfaLoginPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileBiometricLoginPayloadUsersDto} mobileBiometricLoginPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMobileBiometricLogin(mobileBiometricLoginPayloadUsersDto: MobileBiometricLoginPayloadUsersDto, options?: any): AxiosPromise<MobileBiometricLoginResponseUsersDto> {
            return localVarFp.usersControllerMobileBiometricLogin(mobileBiometricLoginPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenPayloadUsersDto} refreshTokenPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefreshToken(refreshTokenPayloadUsersDto: RefreshTokenPayloadUsersDto, options?: any): AxiosPromise<RefreshTokenResponseUsersDto> {
            return localVarFp.usersControllerRefreshToken(refreshTokenPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDevicePayloadUsersDto} registerDevicePayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegisterDevice(registerDevicePayloadUsersDto: RegisterDevicePayloadUsersDto, options?: any): AxiosPromise<RegisterDeviceResponseUsersDto> {
            return localVarFp.usersControllerRegisterDevice(registerDevicePayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestOtpPayloadUsersDto} requestOtpPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRequestOtp(requestOtpPayloadUsersDto: RequestOtpPayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerRequestOtp(requestOtpPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendOtpPayloadUsersDto} resendOtpPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendOtp(resendOtpPayloadUsersDto: ResendOtpPayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerResendOtp(resendOtpPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ToggleTotp2FaPayloadUsersDto} toggleTotp2FaPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerToggleTotp2Fa(toggleTotp2FaPayloadUsersDto: ToggleTotp2FaPayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerToggleTotp2Fa(toggleTotp2FaPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnbindDevicePayloadUsersDto} unbindDevicePayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUnbindDevice(unbindDevicePayloadUsersDto: UnbindDevicePayloadUsersDto, options?: any): AxiosPromise<object> {
            return localVarFp.usersControllerUnbindDevice(unbindDevicePayloadUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailPayloadUsersDto} verifyEmailPayloadUsersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyEmail(verifyEmailPayloadUsersDto: VerifyEmailPayloadUsersDto, options?: any): AxiosPromise<VerifyEmailResponseUsersDto> {
            return localVarFp.usersControllerVerifyEmail(verifyEmailPayloadUsersDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {AcceptNewTermsPayloadUsersDto} acceptNewTermsPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerAcceptNewTerms(acceptNewTermsPayloadUsersDto: AcceptNewTermsPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerAcceptNewTerms(acceptNewTermsPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorizeUserPayloadUsersDto} authorizeUserPayloadUsersDto 
     * @param {string} [xAppType] Distinguish between requests coming from a mobile app and a web app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerAuthorizeUser(authorizeUserPayloadUsersDto: AuthorizeUserPayloadUsersDto, xAppType?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerAuthorizeUser(authorizeUserPayloadUsersDto, xAppType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeEmailPayloadUsersDto} changeEmailPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerChangeEmail(changeEmailPayloadUsersDto: ChangeEmailPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerChangeEmail(changeEmailPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeactivateMeFaPayloadUsersDto} deactivateMeFaPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeactivateMe(deactivateMeFaPayloadUsersDto: DeactivateMeFaPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeactivateMe(deactivateMeFaPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGaSessionIdPayloadUsersDto} updateGaSessionIdPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGaSessionId(updateGaSessionIdPayloadUsersDto: UpdateGaSessionIdPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGaSessionId(updateGaSessionIdPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerInitTotp2Fa(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerInitTotp2Fa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginOrRegisterPayloadUsersDto} loginOrRegisterPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerLoginOrRegister(loginOrRegisterPayloadUsersDto: LoginOrRegisterPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerLoginOrRegister(loginOrRegisterPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerLogout(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MfaLoginPayloadUsersDto} mfaLoginPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerMfaLogin(mfaLoginPayloadUsersDto: MfaLoginPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerMfaLogin(mfaLoginPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileBiometricLoginPayloadUsersDto} mobileBiometricLoginPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerMobileBiometricLogin(mobileBiometricLoginPayloadUsersDto: MobileBiometricLoginPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerMobileBiometricLogin(mobileBiometricLoginPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenPayloadUsersDto} refreshTokenPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRefreshToken(refreshTokenPayloadUsersDto: RefreshTokenPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRefreshToken(refreshTokenPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDevicePayloadUsersDto} registerDevicePayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRegisterDevice(registerDevicePayloadUsersDto: RegisterDevicePayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRegisterDevice(registerDevicePayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestOtpPayloadUsersDto} requestOtpPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRequestOtp(requestOtpPayloadUsersDto: RequestOtpPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRequestOtp(requestOtpPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendOtpPayloadUsersDto} resendOtpPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResendOtp(resendOtpPayloadUsersDto: ResendOtpPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerResendOtp(resendOtpPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ToggleTotp2FaPayloadUsersDto} toggleTotp2FaPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerToggleTotp2Fa(toggleTotp2FaPayloadUsersDto: ToggleTotp2FaPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerToggleTotp2Fa(toggleTotp2FaPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnbindDevicePayloadUsersDto} unbindDevicePayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUnbindDevice(unbindDevicePayloadUsersDto: UnbindDevicePayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUnbindDevice(unbindDevicePayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailPayloadUsersDto} verifyEmailPayloadUsersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerVerifyEmail(verifyEmailPayloadUsersDto: VerifyEmailPayloadUsersDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerVerifyEmail(verifyEmailPayloadUsersDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WalletsApi - axios parameter creator
 * @export
 */
export const WalletsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSwapTransactionPayloadWalletsDto} createSwapTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerCreateSwapTransaction: async (createSwapTransactionPayloadWalletsDto: CreateSwapTransactionPayloadWalletsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSwapTransactionPayloadWalletsDto' is not null or undefined
            assertParamExists('walletsControllerCreateSwapTransaction', 'createSwapTransactionPayloadWalletsDto', createSwapTransactionPayloadWalletsDto)
            const localVarPath = `/wallets/createSwapTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSwapTransactionPayloadWalletsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWalletAccountPayloadWalletsDto} createWalletAccountPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerCreateWalletAccount: async (createWalletAccountPayloadWalletsDto: CreateWalletAccountPayloadWalletsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWalletAccountPayloadWalletsDto' is not null or undefined
            assertParamExists('walletsControllerCreateWalletAccount', 'createWalletAccountPayloadWalletsDto', createWalletAccountPayloadWalletsDto)
            const localVarPath = `/wallets/createWalletAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWalletAccountPayloadWalletsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWalletTransactionPayloadWalletsDto} createWalletTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerCreateWalletTransaction: async (createWalletTransactionPayloadWalletsDto: CreateWalletTransactionPayloadWalletsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWalletTransactionPayloadWalletsDto' is not null or undefined
            assertParamExists('walletsControllerCreateWalletTransaction', 'createWalletTransactionPayloadWalletsDto', createWalletTransactionPayloadWalletsDto)
            const localVarPath = `/wallets/createWalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWalletTransactionPayloadWalletsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EstimateSwapTransactionPayloadWalletsDto} estimateSwapTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerEstimateSwapTransaction: async (estimateSwapTransactionPayloadWalletsDto: EstimateSwapTransactionPayloadWalletsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateSwapTransactionPayloadWalletsDto' is not null or undefined
            assertParamExists('walletsControllerEstimateSwapTransaction', 'estimateSwapTransactionPayloadWalletsDto', estimateSwapTransactionPayloadWalletsDto)
            const localVarPath = `/wallets/estimateSwapTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estimateSwapTransactionPayloadWalletsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EstimateWalletTransactionPayloadWalletsDto} estimateWalletTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerEstimateWalletTransaction: async (estimateWalletTransactionPayloadWalletsDto: EstimateWalletTransactionPayloadWalletsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateWalletTransactionPayloadWalletsDto' is not null or undefined
            assertParamExists('walletsControllerEstimateWalletTransaction', 'estimateWalletTransactionPayloadWalletsDto', estimateWalletTransactionPayloadWalletsDto)
            const localVarPath = `/wallets/estimateWalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estimateWalletTransactionPayloadWalletsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetSwapById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsControllerGetSwapById', 'id', id)
            const localVarPath = `/wallets/getSwap/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wallets/getWalletConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletDetails: async (walletAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAccountId' is not null or undefined
            assertParamExists('walletsControllerGetWalletDetails', 'walletAccountId', walletAccountId)
            const localVarPath = `/wallets/getWalletDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (walletAccountId !== undefined) {
                localVarQueryParameter['walletAccountId'] = walletAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {string} [walletAccountId] 
         * @param {Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>} [walletTransactionTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletHistory: async (outputCount: number, outputOffset: number, walletAccountId?: string, walletTransactionTypes?: Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'outputCount' is not null or undefined
            assertParamExists('walletsControllerGetWalletHistory', 'outputCount', outputCount)
            // verify required parameter 'outputOffset' is not null or undefined
            assertParamExists('walletsControllerGetWalletHistory', 'outputOffset', outputOffset)
            const localVarPath = `/wallets/getWalletHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (walletAccountId !== undefined) {
                localVarQueryParameter['walletAccountId'] = walletAccountId;
            }

            if (walletTransactionTypes) {
                localVarQueryParameter['walletTransactionTypes'] = walletTransactionTypes;
            }

            if (outputCount !== undefined) {
                localVarQueryParameter['outputCount'] = outputCount;
            }

            if (outputOffset !== undefined) {
                localVarQueryParameter['outputOffset'] = outputOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wallets/getWalletSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateAddressPayloadWalletsDto} validateAddressPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerValidateAddress: async (validateAddressPayloadWalletsDto: ValidateAddressPayloadWalletsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateAddressPayloadWalletsDto' is not null or undefined
            assertParamExists('walletsControllerValidateAddress', 'validateAddressPayloadWalletsDto', validateAddressPayloadWalletsDto)
            const localVarPath = `/wallets/validateAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateAddressPayloadWalletsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletsApi - functional programming interface
 * @export
 */
export const WalletsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSwapTransactionPayloadWalletsDto} createSwapTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerCreateSwapTransaction(createSwapTransactionPayloadWalletsDto: CreateSwapTransactionPayloadWalletsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSwapTransactionResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerCreateSwapTransaction(createSwapTransactionPayloadWalletsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWalletAccountPayloadWalletsDto} createWalletAccountPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerCreateWalletAccount(createWalletAccountPayloadWalletsDto: CreateWalletAccountPayloadWalletsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWalletAccountResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerCreateWalletAccount(createWalletAccountPayloadWalletsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWalletTransactionPayloadWalletsDto} createWalletTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerCreateWalletTransaction(createWalletTransactionPayloadWalletsDto: CreateWalletTransactionPayloadWalletsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerCreateWalletTransaction(createWalletTransactionPayloadWalletsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EstimateSwapTransactionPayloadWalletsDto} estimateSwapTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerEstimateSwapTransaction(estimateSwapTransactionPayloadWalletsDto: EstimateSwapTransactionPayloadWalletsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateSwapTransactionResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerEstimateSwapTransaction(estimateSwapTransactionPayloadWalletsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EstimateWalletTransactionPayloadWalletsDto} estimateWalletTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerEstimateWalletTransaction(estimateWalletTransactionPayloadWalletsDto: EstimateWalletTransactionPayloadWalletsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateWalletTransactionResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerEstimateWalletTransaction(estimateWalletTransactionPayloadWalletsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerGetSwapById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSwapTransactionResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetSwapById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerGetWalletConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletConfigResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetWalletConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} walletAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerGetWalletDetails(walletAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletDetailsResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetWalletDetails(walletAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {string} [walletAccountId] 
         * @param {Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>} [walletTransactionTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerGetWalletHistory(outputCount: number, outputOffset: number, walletAccountId?: string, walletTransactionTypes?: Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletHistoryResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetWalletHistory(outputCount, outputOffset, walletAccountId, walletTransactionTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerGetWalletSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletSummaryResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetWalletSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateAddressPayloadWalletsDto} validateAddressPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerValidateAddress(validateAddressPayloadWalletsDto: ValidateAddressPayloadWalletsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateAddressResponseWalletsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerValidateAddress(validateAddressPayloadWalletsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletsApi - factory interface
 * @export
 */
export const WalletsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSwapTransactionPayloadWalletsDto} createSwapTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerCreateSwapTransaction(createSwapTransactionPayloadWalletsDto: CreateSwapTransactionPayloadWalletsDto, options?: any): AxiosPromise<CreateSwapTransactionResponseWalletsDto> {
            return localVarFp.walletsControllerCreateSwapTransaction(createSwapTransactionPayloadWalletsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWalletAccountPayloadWalletsDto} createWalletAccountPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerCreateWalletAccount(createWalletAccountPayloadWalletsDto: CreateWalletAccountPayloadWalletsDto, options?: any): AxiosPromise<CreateWalletAccountResponseWalletsDto> {
            return localVarFp.walletsControllerCreateWalletAccount(createWalletAccountPayloadWalletsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWalletTransactionPayloadWalletsDto} createWalletTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerCreateWalletTransaction(createWalletTransactionPayloadWalletsDto: CreateWalletTransactionPayloadWalletsDto, options?: any): AxiosPromise<object> {
            return localVarFp.walletsControllerCreateWalletTransaction(createWalletTransactionPayloadWalletsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EstimateSwapTransactionPayloadWalletsDto} estimateSwapTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerEstimateSwapTransaction(estimateSwapTransactionPayloadWalletsDto: EstimateSwapTransactionPayloadWalletsDto, options?: any): AxiosPromise<EstimateSwapTransactionResponseWalletsDto> {
            return localVarFp.walletsControllerEstimateSwapTransaction(estimateSwapTransactionPayloadWalletsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EstimateWalletTransactionPayloadWalletsDto} estimateWalletTransactionPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerEstimateWalletTransaction(estimateWalletTransactionPayloadWalletsDto: EstimateWalletTransactionPayloadWalletsDto, options?: any): AxiosPromise<EstimateWalletTransactionResponseWalletsDto> {
            return localVarFp.walletsControllerEstimateWalletTransaction(estimateWalletTransactionPayloadWalletsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetSwapById(id: string, options?: any): AxiosPromise<GetSwapTransactionResponseWalletsDto> {
            return localVarFp.walletsControllerGetSwapById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletConfig(options?: any): AxiosPromise<GetWalletConfigResponseWalletsDto> {
            return localVarFp.walletsControllerGetWalletConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletDetails(walletAccountId: string, options?: any): AxiosPromise<GetWalletDetailsResponseWalletsDto> {
            return localVarFp.walletsControllerGetWalletDetails(walletAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} outputCount 
         * @param {number} outputOffset 
         * @param {string} [walletAccountId] 
         * @param {Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>} [walletTransactionTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletHistory(outputCount: number, outputOffset: number, walletAccountId?: string, walletTransactionTypes?: Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>, options?: any): AxiosPromise<GetWalletHistoryResponseWalletsDto> {
            return localVarFp.walletsControllerGetWalletHistory(outputCount, outputOffset, walletAccountId, walletTransactionTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetWalletSummary(options?: any): AxiosPromise<GetWalletSummaryResponseWalletsDto> {
            return localVarFp.walletsControllerGetWalletSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateAddressPayloadWalletsDto} validateAddressPayloadWalletsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerValidateAddress(validateAddressPayloadWalletsDto: ValidateAddressPayloadWalletsDto, options?: any): AxiosPromise<ValidateAddressResponseWalletsDto> {
            return localVarFp.walletsControllerValidateAddress(validateAddressPayloadWalletsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletsApi - object-oriented interface
 * @export
 * @class WalletsApi
 * @extends {BaseAPI}
 */
export class WalletsApi extends BaseAPI {
    /**
     * 
     * @param {CreateSwapTransactionPayloadWalletsDto} createSwapTransactionPayloadWalletsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerCreateSwapTransaction(createSwapTransactionPayloadWalletsDto: CreateSwapTransactionPayloadWalletsDto, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerCreateSwapTransaction(createSwapTransactionPayloadWalletsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWalletAccountPayloadWalletsDto} createWalletAccountPayloadWalletsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerCreateWalletAccount(createWalletAccountPayloadWalletsDto: CreateWalletAccountPayloadWalletsDto, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerCreateWalletAccount(createWalletAccountPayloadWalletsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWalletTransactionPayloadWalletsDto} createWalletTransactionPayloadWalletsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerCreateWalletTransaction(createWalletTransactionPayloadWalletsDto: CreateWalletTransactionPayloadWalletsDto, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerCreateWalletTransaction(createWalletTransactionPayloadWalletsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EstimateSwapTransactionPayloadWalletsDto} estimateSwapTransactionPayloadWalletsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerEstimateSwapTransaction(estimateSwapTransactionPayloadWalletsDto: EstimateSwapTransactionPayloadWalletsDto, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerEstimateSwapTransaction(estimateSwapTransactionPayloadWalletsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EstimateWalletTransactionPayloadWalletsDto} estimateWalletTransactionPayloadWalletsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerEstimateWalletTransaction(estimateWalletTransactionPayloadWalletsDto: EstimateWalletTransactionPayloadWalletsDto, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerEstimateWalletTransaction(estimateWalletTransactionPayloadWalletsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerGetSwapById(id: string, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerGetSwapById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerGetWalletConfig(options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerGetWalletConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerGetWalletDetails(walletAccountId: string, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerGetWalletDetails(walletAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} outputCount 
     * @param {number} outputOffset 
     * @param {string} [walletAccountId] 
     * @param {Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>} [walletTransactionTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerGetWalletHistory(outputCount: number, outputOffset: number, walletAccountId?: string, walletTransactionTypes?: Array<'DEPOSIT' | 'WITHDRAWAL' | 'FEE'>, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerGetWalletHistory(outputCount, outputOffset, walletAccountId, walletTransactionTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerGetWalletSummary(options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerGetWalletSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateAddressPayloadWalletsDto} validateAddressPayloadWalletsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    public walletsControllerValidateAddress(validateAddressPayloadWalletsDto: ValidateAddressPayloadWalletsDto, options?: AxiosRequestConfig) {
        return WalletsApiFp(this.configuration).walletsControllerValidateAddress(validateAddressPayloadWalletsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowApi - axios parameter creator
 * @export
 */
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/workflow/instance/MyWorkflow1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/workflow/instance/MyWorkflow2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerStartMyWorkflow1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerStartMyWorkflow1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testControllerStartMyWorkflow2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testControllerStartMyWorkflow2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow1(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerStartMyWorkflow1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testControllerStartMyWorkflow2(options?: any): AxiosPromise<void> {
            return localVarFp.testControllerStartMyWorkflow2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public testControllerStartMyWorkflow1(options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).testControllerStartMyWorkflow1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public testControllerStartMyWorkflow2(options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).testControllerStartMyWorkflow2(options).then((request) => request(this.axios, this.basePath));
    }
}


