import { ITextProps, Text } from 'native-base';
import React from 'react';

const SectionTitle = ({ children, ...rest }: ITextProps) => (
  <Text userSelect="none" fontSize="2xl" color="white" {...rest}>
    {children}
  </Text>
);

export default SectionTitle;
