/* eslint-disable @typescript-eslint/no-shadow */
import { Box } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useBuyFlowStore, useSellFlowStore } from '@cryptowallet/frontend/stores';
import { Tab, Tabs } from '@cryptowallet/frontend/ui';

import BuyFlow from './BuyFlow';
import SellFlow from './SellFlow';

const ExchangeComponents = {
  [commonConstants.ExchangeTypeEnum.Buy]: BuyFlow,
  [commonConstants.ExchangeTypeEnum.Sell]: SellFlow,
};

const storeSelector = state => ({
  fromCurrency: state.fromCurrency,
  setFromCurrency: state.setFromCurrency,
  toCurrency: state.toCurrency,
  setToCurrency: state.setToCurrency,
  stepIndex: state.stepIndex,
  setAmount: state.setAmount,
  reset: state.reset,
});

const Exchange = ({ exchangeType, setExchangeType }) => {
  const buyStore = useBuyFlowStore(storeSelector, shallow);
  const sellStore = useSellFlowStore(storeSelector, shallow);

  const { stepIndex: buyFlowStepIndex } = buyStore;
  const { stepIndex: sellFlowStepIndex } = sellStore;

  const activeFlowStepIndex =
    exchangeType === commonConstants.ExchangeTypeEnum.Sell ? sellFlowStepIndex : buyFlowStepIndex;
  const Component = ExchangeComponents[exchangeType];

  return (
    <Box
      maxW={{ base: '100%', xl: '588px' }}
      mr={{ base: '0', xl: '24px' }}
      w={{ base: '100%', xl: '588px' }}
      h="fit-content"
      background="background.400"
      borderWidth="1px"
      borderColor="gray.100"
      borderRadius="10px"
      zIndex="1">
      {activeFlowStepIndex === 0 && (
        <Box px="24px" py="32px" pb="0" mb="20px">
          <Tabs>
            {Object.values(commonConstants.ExchangeTypeEnum).map(title => (
              <Tab key={title} title={title} isActive={exchangeType === title} onPress={() => setExchangeType(title)} />
            ))}
          </Tabs>
        </Box>
      )}
      <Component />
    </Box>
  );
};

export default Exchange;
