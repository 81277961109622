/* eslint-disable sonarjs/no-duplicate-string */
import { HStack, Image, Text, VStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';

import SecurityBlockContainer from './components/SecurityBlockContainer';
import SecurityBlockDescriptionText from './components/SecurityBlockDescriptionText';
import SecurityBlockSubTitle from './components/SecurityBlockSubTitle';
import SecurityBlockSuccess from './components/SecurityBlockSuccess';
import SecurityBlockTitle from './components/SecurityBlockTitle';
import SecurityLevel from './components/SecurityLevel';
import SecurityRiskCheckList from './components/SecurityRiskCheckList';

const Security = () => {
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();
  const { kycPoaHighRiskVerified, awaitingKycReward, VERIFIED_KYC, VERIFIED_POA_FOR_HIGH_RISK, riskChecks } =
    useUserStatus();

  const onPressStartRequiredVerification = () => navigate('/account/security/verification');

  return (
    <VStack flexGrow="1">
      <TabHeader onGoBack={isDesktop ? null : () => navigate('/account')}>Security actions</TabHeader>
      <TabContentLayout>
        {!kycPoaHighRiskVerified && <SecurityBlockTitle mb="32px">Verification</SecurityBlockTitle>}
        <SecurityLevel
          containerProps={{ mb: '40px' }}
          onPressStart={onPressStartRequiredVerification}
          title="Verification level 1"
          completed={VERIFIED_KYC && VERIFIED_POA_FOR_HIGH_RISK}
          showLimitsBtn={VERIFIED_KYC && VERIFIED_POA_FOR_HIGH_RISK}
          requires={[
            {
              text: 'Government-issued ID',
              icon: { name: '/security/gov-id' },
            },
            {
              text: 'Liveness check',
              icon: { name: '/security/liveness' },
            },
          ]}
          unblocks={[
            {
              text: 'Buy and sell crypto',
              icon: { name: 'checked-icon', h: '12px', w: '12px' },
            },
            {
              text: 'Wallet operations',
              icon: { name: 'checked-icon', h: '12px', w: '12px' },
            },
            {
              text: 'Deposit and withdrawal',
              icon: { name: 'checked-icon', h: '12px', w: '12px' },
            },
          ]}
        />
        {/* <SecurityLevel
          onPressStart={onPressStartPoa}
          disabled={!VERIFIED_KYC}
          completed={VERIFIED_POA}
          showLimitsBtn={VERIFIED_KYC && VERIFIED_POA}
          title="Verification level 2"
          requires={[
            {
              text: 'Proof of address',
              icon: { name: '/security/poa' },
            },
          ]}
          unblocks={[
            {
              text: 'IBAN',
              icon: { name: 'checked-icon', h: '12px', w: '12px' },
            },
            {
              text: 'Crypto card',
              icon: { name: 'checked-icon', h: '12px', w: '12px' },
            },
          ]}
        /> */}
        {awaitingKycReward && VERIFIED_POA_FOR_HIGH_RISK && (
          <SecurityBlockContainer mt="32px">
            <HStack alignItems="center">
              <Image
                source={{
                  uri: `/assets/images/spend-token.svg`,
                }}
                alt="Spend token logo"
                w="64px"
                h="64px"
                mr="32px"
              />
              <VStack flex="1" justifyContent="space-between" alignItems="flex-start">
                <SecurityBlockSubTitle>Congratulations!</SecurityBlockSubTitle>
                <SecurityBlockDescriptionText mt="8px">
                  Your KYC verification is completed and 50 SPEND tokens will be added directly to your wallet after the
                  public token sale. Welcome to unlocking crypto’s full potential with CryptoWallet.com!
                </SecurityBlockDescriptionText>
              </VStack>
            </HStack>
          </SecurityBlockContainer>
        )}
        <SecurityBlockTitle mb="4px" mt="32px">
          Checks
        </SecurityBlockTitle>
        <Text mb="32px" fontSize="lg" color="white" fontWeight="100">
          Here you will find checks required to reactivate account{' '}
        </Text>
        {!Object.keys(riskChecks).length && <SecurityBlockSuccess>No security action required!</SecurityBlockSuccess>}
        {!!Object.keys(riskChecks).length && <SecurityRiskCheckList riskChecks={riskChecks} />}
      </TabContentLayout>
    </VStack>
  );
};

export default Security;
