import React from 'react';

const NavigationLogoutIcon = ({ color }) => (
  <svg width="21" height="20" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 11L22 11M22 11L18 7M22 11L18 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0 3.02722C0 2.6304 0.260248 2.28449 0.63122 2.18825L8.96455 0.0262467C9.49051 -0.110207 10 0.302612 10 0.865225V21.1348C10 21.6974 9.49051 22.1102 8.96455 21.9738L0.63122 19.8118C0.260247 19.7155 0 19.3696 0 18.9728V3.02722Z"
      fill={color}
    />
  </svg>
);

export default NavigationLogoutIcon;
