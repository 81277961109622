/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query';
import { HStack } from 'native-base';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useGA } from '@cryptowallet/frontend/ga';
import { Icon, Spinner, useAlerts, useAuth } from '@cryptowallet/frontend/ui';
import { getDeviceId } from '@cryptowallet/frontend/utils';
import {
  EventRequestDtoEventNameEnum,
  userApi,
  VerifyOtpResponseUsersDtoRequiredMfaMethodsEnum,
} from '@cryptowallet/web/api-client';
import CenterElement from '@cryptowallet/web/components/CenterElement';
import RoundedBox from '@cryptowallet/web/components/layout/RoundedBox';
import LinkPurple from '@cryptowallet/web/components/Links/LinkPurple';
import { setQueryString } from '@cryptowallet/web/utils/url';

import EmailVerificationForm from './components/EmailVerificationForm';
import LoginForm from './components/LoginForm';
import MultifactorVerificationForm from './components/MultifactorVerificationForm';
import Terms from './components/Terms';
import { AuthStep, useAuthFlow } from './useAuthFlow';

export type OnVerifyAuthStepFn = (
  refreshToken: string,
  accessToken: string,
  requiredMfaMethods?: VerifyOtpResponseUsersDtoRequiredMfaMethodsEnum[],
  blockedCountryRegistration?: boolean,
) => void;

export default function Home(): JSX.Element {
  const { onApiError, createAlert } = useAlerts();
  const { setTokens } = useAuth();
  const ga = useGA();

  const [urlParams] = useSearchParams();
  const isMagicLinkAuth = urlParams.has('magic_token');

  const {
    step,
    email,
    setEmail,
    termsAccepted,
    setTermsAccepted,
    reset,
    temporaryToken,
    setTemporaryToken,
    setCountry,
    country,
    referralCode,
    setReferralCode,
  } = useAuthFlow({
    email: isMagicLinkAuth ? ' ' : undefined, // we need to skip email step in case of magic link
    termsAccepted: isMagicLinkAuth && urlParams.get('is_registration') !== 'true',
    temporaryToken: '',
    country: '',
  });

  const onVerifyAuthStep: OnVerifyAuthStepFn = async (
    refreshToken,
    accessToken,
    requiredMfaMethods = [],
    blockedCountryRegistration = false,
  ) => {
    if (blockedCountryRegistration) {
      window.localStorage?.setItem(commonConstants.PersistKeys.ShowBlockedByCountryScreen, 'true');
    }
    if (requiredMfaMethods.length) {
      setTemporaryToken(accessToken);
      setTokens({
        accessToken: '',
        refreshToken,
      });
    } else {
      setTokens({
        accessToken,
        refreshToken,
      });
    }
  };

  const magicLinkValidationEnabled = isMagicLinkAuth && termsAccepted;
  const { isLoading } = useQuery(
    ['magicLinkVerify'],
    async () => {
      const { gaClientId, gaSessionId } = await ga.getParams();

      try {
        const { data } = await userApi.usersControllerLoginOrRegister({
          deviceUniqueId: getDeviceId(),
          magicLinkToken: urlParams.get('magic_token'),
          gaClientId,
          gaSessionId,
          country,
        });
        onVerifyAuthStep(data.refreshToken, data.accessToken, data.requiredMfaMethods, data.blockedCountryRegistration);
      } catch (err) {
        onApiError({ title: 'Magic link cannot be verified' });
        // reset to the normal login flow
        reset();
      } finally {
        // In any case we don't need to keep magic link token in the url any more
        setQueryString('');
      }
    },
    {
      enabled: magicLinkValidationEnabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const onAcceptTerms = (country: string, referralCode: string) => {
    ga.sendEvent(EventRequestDtoEventNameEnum.TermsAndConditionsAccepted, { email, customer_source: 'btc' });
    setTermsAccepted(true);
    setCountry(country);
    setReferralCode(referralCode);
  };

  const onDeclineTerms = () => {
    ga.sendEvent(EventRequestDtoEventNameEnum.RegistrationDeclineButtonSuccess, { email, customer_source: 'btc' });
    reset();
  };

  const onPressVerySmartQuestion = () => createAlert('WhyINeedCwAccountAlert');

  if (isLoading && magicLinkValidationEnabled) {
    return (
      <CenterElement>
        <Spinner />
      </CenterElement>
    );
  }

  return (
    <CenterElement containerProps={{ w: '100%' }}>
      <>
        <RoundedBox
          background="background.400"
          borderRadius="10px"
          overflow="visible"
          w={{ base: '100%', lg: '588' }}
          maxW="calc(100vw - 20px)">
          {step === AuthStep.EMAIL && (
            <LoginForm
              onSubmitSuccess={(newEmail, isNewUser) => {
                if (!isNewUser) {
                  setTermsAccepted(true);
                }
                setEmail(newEmail);
              }}
            />
          )}
          {step === AuthStep.TERMS && (
            <Terms onAccept={onAcceptTerms} onDecline={onDeclineTerms} referralCode={referralCode} />
          )}
          {step === AuthStep.OTP && (
            <EmailVerificationForm
              referralCode={referralCode}
              country={country}
              email={email}
              onVerify={onVerifyAuthStep}
              onEmailChangeRequest={reset}
            />
          )}
          {step === AuthStep.TWOFACTOR && (
            <MultifactorVerificationForm
              onVerify={onVerifyAuthStep}
              onEmailChangeRequest={reset}
              temporaryToken={temporaryToken}
            />
          )}
        </RoundedBox>
        {step === AuthStep.EMAIL && (
          <HStack mt="22px" alignItems="center">
            <Icon name="info" w="16px" h="16px" />
            <LinkPurple
              onPress={onPressVerySmartQuestion}
              text="Why do I need to open an account at CryptoWallet.com?"
              ml="6px"
              flex="1"
              _text={{ fontSize: 'lg', whiteSpace: 'pre-wrap' }}
            />
          </HStack>
        )}
      </>
    </CenterElement>
  );
}
