/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { UnauthorizedExceptionDtoErrorEnum, UsersApiFactory } from '@cryptowallet/frontend/client';
import { deleteCookie, getEnv } from '@cryptowallet/frontend/utils';

import { tokenService } from '../services/token';

const env = getEnv();

const createAxiosInstance = (): AxiosInstance => {
  const hewInstance = axios.create({
    baseURL: env.NX_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  hewInstance.interceptors.request.use(
    async config => {
      if (await tokenService.hasToken()) {
        try {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${await tokenService.getToken()}`;
        } catch (e) {
          console.error(e);
        }
      }

      return config;
    },
    error => Promise.reject(error),
  );

  return hewInstance;
};

export const axiosInstance: AxiosInstance = createAxiosInstance();

const userApi = UsersApiFactory(undefined, '', axiosInstance);

const logout = () => {
  deleteCookie('user_id');
  tokenService.removeTokens();
  window?.location?.replace('/');
};

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response && error.response.status === 401) {
    switch (error.response.data.error) {
      case UnauthorizedExceptionDtoErrorEnum.TokenExpired: {
        try {
          const refreshToken = tokenService.getRefreshToken() as string;
          if (!refreshToken) {
            throw new Error('Refresh token not found');
          }
          const {
            data: { accessToken: newAccessToken, refreshToken: newRefreshToken },
          } = await userApi.usersControllerRefreshToken({ refreshToken });

          tokenService.setTokens(newAccessToken, newRefreshToken);
          return await axios.request({
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${newAccessToken}`,
            },
          });
        } catch {
          logout();
        }
        break;
      }
      case UnauthorizedExceptionDtoErrorEnum.UnprocessableEntity:
      case UnauthorizedExceptionDtoErrorEnum.Unauthorized:
      case UnauthorizedExceptionDtoErrorEnum.InvalidateSession: {
        if (window?.onSessionExpire) {
          tokenService.removeTokens();
          window.onSessionExpire();
        } else {
          logout();
        }
        break;
      }
    }
  }
  // eslint-disable-next-line consistent-return
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(onResponse, onResponseError);
