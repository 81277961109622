import { Box, Text } from 'native-base';
import React from 'react';

import { EventRequestDtoEventNameEnum } from '@cryptowallet/frontend/client';
import { useGA } from '@cryptowallet/frontend/ga';
import { ButtonPurple, useUser } from '@cryptowallet/frontend/ui';

const BlockedByCountryScreen = ({ isCheckout }) => {
  const ga = useGA();
  const { user } = useUser();

  const onPressJoinDiscord = () => {
    ga.sendEvent(EventRequestDtoEventNameEnum.RegistrationAccountCreatedRestrictCountryButton, {
      email: user.email,
      customer_source: isCheckout ? 'btb' : 'btc',
    });
    window.open('https://discord.gg/vgy7GZKw2t', '_blank');
  };

  return (
    <Box flexGrow="1" maxW="100%" alignItems="center" justifyContent="center">
      <Box
        w="588px"
        maxH="100%"
        maxW="100%"
        borderWidth="1px"
        borderColor="gray.100"
        borderRadius="10px"
        overflow="hidden"
        bg="background.400">
        <Box flexGrow="1" px="24px" py="32px" justifyContent="space-between">
          <Box mb="24px">
            <Text fontSize="lg" color="textRegular" textAlign="center">
              Unfortunately, our service is not yet available in your region. We are constantly working to expand, so
              please stay tuned to our social media channels for the latest updates. We appreciate your interest and
              look forward to serving you in the future.
            </Text>
          </Box>
          <ButtonPurple size="lg" w="100%" onPress={onPressJoinDiscord}>
            Join our discord
          </ButtonPurple>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockedByCountryScreen;
