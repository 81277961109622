import { IButtonProps } from 'native-base';
import React from 'react';

import { Button } from './Button';

export const OutlinedPopUpButton = ({ children, _text = {}, ...props }: IButtonProps) => (
  <Button
    variant="unstyled"
    outlineWidth="0"
    color="textRegular"
    borderWidth="1px"
    borderColor="#C5C3C9"
    _text={{
      color: 'textRegular',
      fontSize: 'lg',
      lineHeight: '18px',
      ..._text,
    }}
    _hover={{
      bg: '#C5C3C9',
      _text: {
        color: 'white',
      },
    }}
    _focus={{
      borderColor: 'secondary.400',
    }}
    {...props}>
    {children}
  </Button>
);
