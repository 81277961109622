import React from 'react';
import { Navigate } from 'react-router-dom';

import { RiskCheckType } from '@cryptowallet/frontend/client';
import { RiskCheckStatus, useUserStatus } from '@cryptowallet/frontend/hooks';
import { Pow } from '@cryptowallet/web/components/PermissionCases';

const SecurityVerificationWealth = () => {
  const userStatus = useUserStatus();

  if (!userStatus.kycPoaHighRiskVerified) {
    return <Navigate to="/account/security/verification" replace />;
  }

  if (
    userStatus.riskChecks[RiskCheckType.ProofOfWealthCheck] &&
    [RiskCheckStatus.PENDING, RiskCheckStatus.RETRY].includes(
      userStatus.riskChecks[RiskCheckType.ProofOfWealthCheck].status,
    )
  ) {
    return <Pow />;
  }

  return <Navigate to="/account/security" replace />;
};

export default SecurityVerificationWealth;
