import { useQuery } from '@tanstack/react-query';

import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import { userApi } from '../api/src';

interface IUseTotpSecret {
  isLoading: boolean;
  secret: string;
  otpauth: string;
}

const useTotpSecret = (): IUseTotpSecret => {
  const { isLoading, data: init2otpResponse } = useQuery(
    ['initTotp'],
    () => userApi.usersControllerInitTotp2Fa(),
    cacheRequestOption,
  );

  const { secret = null, otpauth = null } = init2otpResponse?.data ?? {};

  return {
    isLoading,
    secret,
    otpauth,
  };
};

export default useTotpSecret;
