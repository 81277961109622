import { Box, HStack, Image, Text, VStack } from 'native-base';
import QRCodeLib from 'qrcode';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useScreenSize, useSelectedWalletAccount, useWalletConfig } from '@cryptowallet/frontend/hooks';
import { DetailsPanelView, useAccountsStore, useBuyFlowStore, useSellFlowStore } from '@cryptowallet/frontend/stores';
import { ActionSheet, Icon, Pressable, useUserSettings } from '@cryptowallet/frontend/ui';
import CopyBox, { CopyBoxVariant } from '@cryptowallet/web/components/CopyBox';

import AssetList from '../AssetList';

import AssetDetailsBalance from './AssetDetailsBalance';
import AssetOverviewActions from './AssetOverviewActions';

const AssetDetailsOverview = () => {
  const { isBiggerThanTablet, isMobile } = useScreenSize();
  const [assetListModalOpen, setAssetModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [qrImage, setQrImage] = useState('');
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const { isLoading: isWalletConfigLoading, fiatCurrency } = useWalletConfig();
  const { hideBalance } = useUserSettings();
  const navigate = useNavigate();

  const { setDetailsPanelView, selectedWalletAssetId } = useAccountsStore(
    state => ({
      setDetailsPanelView: state.setDetailsPanelView,
      selectedWalletAssetId: state.selectedWalletAssetId,
    }),
    shallow,
  );
  const resetBuyStore = useBuyFlowStore(state => state.reset);
  const { resetSellStore, setUseMyWallet } = useSellFlowStore(
    state => ({ resetSellStore: state.reset, setUseMyWallet: state.setUseMyWallet }),
    shallow,
  );

  useEffect(() => {
    if (selectedWalletAccount?.address) {
      QRCodeLib.toDataURL(selectedWalletAccount?.address).then(setQrImage);
    } else {
      setQrImage('');
    }
  }, [selectedWalletAccount?.address]);

  if (!selectedWalletAccount && selectedWalletAssetId) {
    setDetailsPanelView(DetailsPanelView.GenerateAddress);

    return null;
  }

  if (!selectedWalletAccount) {
    return null;
  }

  const {
    asset: { currency, image, network },
    cryptoBalance,
    fiatBalance,
    address,
    exchangerAssets,
  } = selectedWalletAccount;

  const isBuyExchangerAsset = exchangerAssets.find(item => item.isBuy);
  const isSellExchangerAsset = exchangerAssets.find(item => item.isSell);

  const onPressBuyCrypto = () => {
    resetBuyStore();
    navigate(
      `/dashboard/widgets?exchangeType=Buy&amount=${commonConstants.DEFAULT_BUY_AMOUNT + 1}&toCurrencyId=${
        isBuyExchangerAsset.id
      }`,
    );
  };

  const onPressSellCrypto = () => {
    resetSellStore();
    setUseMyWallet(true);
    navigate(
      `/dashboard/widgets?exchangeType=Sell&amount=${Math.floor(Number(cryptoBalance))}&fromCurrencyId=${
        isSellExchangerAsset.id
      }`,
    );
  };

  const anyActionSheetOpen = qrModalOpen || assetListModalOpen;

  if (isWalletConfigLoading) return null;
  return (
    <VStack
      maxH={anyActionSheetOpen ? '300px' : 'unset'}
      overflow={anyActionSheetOpen ? 'hidden' : 'unset'}
      flexGrow="1"
      pt="40px">
      <Box px="24px" zIndex="1">
        <HStack justifyContent="space-between" mb="32px">
          <HStack>
            <Image
              source={{
                uri: image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
              }}
              alt={currency.label}
              w={{ base: '40px', lg: '80px' }}
              h={{ base: '40px', lg: '80px' }}
              borderRadius={{ base: '40px', lg: '80px' }}
              mr="24px"
            />
            {isBiggerThanTablet && (
              <AssetDetailsBalance
                hideBalance={hideBalance}
                cryptoBalance={cryptoBalance}
                currency={currency}
                fiatCurrency={fiatCurrency}
                fiatBalance={fiatBalance}
                network={network}
              />
            )}
            {!isBiggerThanTablet && (
              <Pressable flexDirection="row" onPress={() => setAssetModalOpen(true)}>
                <AssetDetailsBalance
                  hideBalance={hideBalance}
                  cryptoBalance={cryptoBalance}
                  currency={currency}
                  fiatCurrency={fiatCurrency}
                  fiatBalance={fiatBalance}
                  network={network}
                />
                <Icon
                  name={`chevron-${assetListModalOpen ? 'up' : 'down'}--purple`}
                  ml="16px"
                  mt="9px"
                  h="8px"
                  w="16px"
                />
              </Pressable>
            )}
          </HStack>
          {!isMobile && qrImage && (
            <Image
              source={{
                uri: qrImage,
              }}
              alt="wallet qr address"
              w="94px"
              h="94px"
            />
          )}
        </HStack>
        <Box position="relative" pr={{ base: '80px', md: '0' }}>
          {!!address && (
            <CopyBox
              title={
                <Text color="textLabel" fontSize="lg">
                  Wallet address
                </Text>
              }
              value={address}
              variant={CopyBoxVariant.GRAYBOX}
              ellipsis="middle"
              valueStyles={{ fontSize: 'lg' }}
            />
          )}
          {isMobile && !!address && (
            <Pressable
              onPress={() => {
                setQrModalOpen(true);
              }}
              position="absolute"
              right="0"
              bottom="0"
              h="56px"
              w="56px">
              <Icon name="wallet/open-qr" h="56px" w="56px" />
            </Pressable>
          )}
        </Box>
        <HStack pl="24px" alignItems="center" mt="16px">
          <Icon name="alerts/alert-warning--orange" position="absolute" top="4px" left="0" h="16px" w="16px" />
          <Text fontSize="lg" color="textRegular">
            Please pay close attention to the blockchain network you’re working with. Sending funds in the wrong network
            will lead to permanent loss of your money.
          </Text>
        </HStack>
        <AssetOverviewActions
          onPressBuyCrypto={onPressBuyCrypto}
          onPressSellCrypto={onPressSellCrypto}
          onPressWithdrawCrypto={() => setDetailsPanelView(DetailsPanelView.Withdraw)}
          onPressSwapCrypto={() => setDetailsPanelView(DetailsPanelView.Swap)}
        />
      </Box>
      <ActionSheet isOpen={qrModalOpen} onClose={() => setQrModalOpen(false)}>
        <Box h="500px" w="100%" alignItems="center" justifyContent="center">
          {qrImage && (
            <Image
              source={{
                uri: qrImage,
              }}
              alt="wallet qr address"
              w="256px"
              h="256px"
            />
          )}
        </Box>
      </ActionSheet>
      <ActionSheet isOpen={assetListModalOpen} onClose={() => setAssetModalOpen(false)}>
        <Box h="560px" w="100%">
          <AssetList isActionSheet={true} containerProps={{ pb: '160px' }} />
        </Box>
      </ActionSheet>
    </VStack>
  );
};

export default AssetDetailsOverview;
