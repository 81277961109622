import { Box, Text } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { SimpleText } from '@cryptowallet/web/components/Table/CellRenderers';

const ReferralCustomSimpleText = ({ data, columnDef }) => {
  const { isMobile } = useScreenSize();

  if (!isMobile) {
    return <SimpleText data={data} columnDef={columnDef} />;
  }

  return (
    <Box py="8px">
      <Text mb="8px" fontSize="lg" lineHeight="16px" color="textLabel">
        {columnDef.headerName}
      </Text>
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        fontSize="lg"
        lineHeight="16px"
        color="textRegular"
        maxWidth="100">
        {data}
      </Text>
    </Box>
  );
};

export default ReferralCustomSimpleText;
