import { Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useChangeEmailStore } from '@cryptowallet/frontend/stores';
import { FormGuards, IFormGuardType, useUser } from '@cryptowallet/frontend/ui';
import { maskEmail } from '@cryptowallet/frontend/utils';
import { VerifyEmailPayloadUsersDto } from '@cryptowallet/web/api-client';
import { TabContentLayout } from '@cryptowallet/web/components/layout';
import { IStepComponentProps } from '@cryptowallet/web/components/Stepper';

const VerifyCurrentEmail = ({ onStepCompleted }: IStepComponentProps) => {
  const { user } = useUser();

  const { setOldEmailOtpToken, verifyCurrentEmailIsInvalid, setVerifyCurrentEmailIsInvalid } = useChangeEmailStore(
    state => ({
      setOldEmailOtpToken: state.setOldEmailOtpToken,
      verifyCurrentEmailIsInvalid: state.verifyCurrentEmailIsInvalid,
      setVerifyCurrentEmailIsInvalid: state.setVerifyCurrentEmailIsInvalid,
    }),
    shallow,
  );

  const { control, handleSubmit, setError } = useForm<VerifyEmailPayloadUsersDto>({
    defaultValues: {
      emailOtpToken: '',
    },
  });

  const onSubmit = async (formData: VerifyEmailPayloadUsersDto) => {
    setOldEmailOtpToken(formData.emailOtpToken);
    onStepCompleted();
  };

  useEffect(() => {
    if (verifyCurrentEmailIsInvalid) {
      setVerifyCurrentEmailIsInvalid(false);
      setError('emailOtpToken', {
        type: 'custom',
        message: 'Old email code is invalid or expired',
      });
    }
  }, [verifyCurrentEmailIsInvalid]);

  return (
    <TabContentLayout>
      <Text fontSize="lg">We’ve sent a code to your current email: {maskEmail(user.email)}</Text>
      <FormGuards
        activeGuards={[{ type: IFormGuardType.OTP }]}
        control={control}
        onSubmitInput={handleSubmit(onSubmit)}
        autoSubmit={true}
      />
    </TabContentLayout>
  );
};

export default VerifyCurrentEmail;
