import { Box, HStack, Image, Text } from 'native-base';
import React from 'react';

const TrxDetailsItem = ({ image, label, amount, ticker }) => (
  <HStack mb="2px" alignItems="center">
    <Box borderWidth="1px" borderColor="white:alpha.10" borderRadius="50%">
      <Image
        source={{
          uri: image,
        }}
        alt={label}
        w="20px"
        h="20px"
        opacity="0.5"
        borderRadius="50%"
        borderWidth="1px"
        borderColor="transparent"
      />
    </Box>
    <Text ml="6px" fontSize="lg" fontWeight="500" color="textRegular" lineHeight="18px">
      {amount} {ticker}
    </Text>
  </HStack>
);

export default TrxDetailsItem;
