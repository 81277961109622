/* eslint-disable no-underscore-dangle */
import { IButtonProps } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '../../context/PartnersSettingsContext';

import { Button } from './Button';

export const ButtonPurple = React.forwardRef<HTMLElement, IButtonProps>(
  ({ children, _text = {}, ...props }, forwardedRef) => {
    const partnerStyles = usePartnerStyles('ButtonPurple');

    return (
      <Button
        ref={forwardedRef}
        variant="unstyled"
        outlineWidth="0"
        bg="primary.400"
        borderWidth="1px"
        borderColor="transparent"
        _text={{
          color: 'white',
          fontSize: 'lg',
          lineHeight: '18px',
          ..._text,
        }}
        _hover={{
          bg: 'primary.300',
        }}
        _focus={{
          borderColor: 'secondary.400',
        }}
        _disabled={{
          _text: {
            color: 'textDisabled',
          },
          bg: 'gray.100',
          opacity: 1,
        }}
        {...props}
        {...partnerStyles}>
        {children}
      </Button>
    );
  },
);
