import { useMutation } from '@tanstack/react-query';
import { Box, useMediaQuery } from 'native-base';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useExchanges } from '@cryptowallet/frontend/hooks';
import { useBuyFlowStore, useSellFlowStore } from '@cryptowallet/frontend/stores';
import { Spinner, useAlerts } from '@cryptowallet/frontend/ui';
import {
  exchangerApi,
  ExchangerExchangeResponseDtoStatusEnum,
  ExchangerExchangeResponseDtoTypeEnum,
} from '@cryptowallet/web/api-client';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import { PrimaryButton } from '@cryptowallet/web/components/Buttons';
import Table, { IColumnDef, TableType } from '@cryptowallet/web/components/Table';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

import BuySellPromo from './components/BuySellPromo';
import RecentActivityTableDetails from './components/RecentActivityTableDetails';
import { columnDefs } from './config';

interface IRecentActivityProps {
  tableType: TableType;
  onOpenRecentActivity?: () => void;
  isWidget: boolean;
  setExchangeType: (exchangeType: commonConstants.ExchangeTypeEnum) => void;
}

const wrapperStyles = {
  [TableType.FULL]: {
    w: '1200px',
  },
  [TableType.SMALL]: {
    w: 'unset',
  },
};

const storeSelector = state => state.setExchangeId;

const RecentActivity = ({ tableType, onOpenRecentActivity, isWidget, setExchangeType }: IRecentActivityProps) => {
  const { showErrorToaster } = useAlerts();
  const navigate = useNavigate();
  const buySetExchangeId = useBuyFlowStore(storeSelector);
  const sellSetExchangeId = useSellFlowStore(storeSelector);
  const [isLargeScreen] = useMediaQuery({ minWidth: 1668 });

  const {
    transactions,
    isLoading: isExchangesListLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useExchanges(isLargeScreen ? 16 : 8);
  const [selectedExchange, setSelectedExchange] = useState('');

  const resumeExchange = useMutation((id: string) =>
    exchangerApi.exchangerControllerResumeExchange({ exchangeId: id }),
  );

  const onPressBack = () => navigate(-1);

  const onSelectRow = id => setSelectedExchange(prev => (prev === id ? '' : id));

  const onCellClick = async (col: IColumnDef, data: any, rowId: string) => {
    try {
      if (
        col.id === 'status' &&
        (data === ExchangerExchangeResponseDtoStatusEnum.Created ||
          data === ExchangerExchangeResponseDtoStatusEnum.Pending) // resume exchange
      ) {
        const { data: exchange } = await resumeExchange.mutateAsync(rowId);
        const exchangeType =
          exchange.type === ExchangerExchangeResponseDtoTypeEnum.Buy
            ? commonConstants.ExchangeTypeEnum.Buy
            : commonConstants.ExchangeTypeEnum.Sell;
        const setExchangeId =
          exchangeType === commonConstants.ExchangeTypeEnum.Buy ? buySetExchangeId : sellSetExchangeId;

        if (isWidget) {
          setExchangeType(exchangeType);
          setExchangeId(exchange.id);
          onSelectRow(rowId);
        } else {
          navigate(`/dashboard/widgets?exchangeType=${exchangeType}&exchangeId=${exchange.id}`);
        }
      }
    } catch (err) {
      console.error(err);
      showErrorToaster({ title: `Can't resume transaction now, please try again later` });
    }
  };

  const showSpinner = isExchangesListLoading && isFetching;

  return (
    <Box
      position="relative"
      // maxH={isWidget ? '1264px' : 'unset'}
      maxW={isWidget ? '588px' : '1200px'}
      flex="1"
      h="fit-content"
      background="background.400"
      borderWidth="1px"
      borderColor="gray.100"
      borderRadius="10px"
      zIndex="1"
      pb={isWidget ? '80px' : '0'}
      {...wrapperStyles[tableType]}>
      <AuthHeader mb="0" zIndex="1">
        <FormHeading
          onPressBack={!isWidget && onPressBack}
          text="Recent Activity"
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          flexDirection={{ base: 'column', md: 'row' }}></FormHeading>
      </AuthHeader>
      {showSpinner && <Spinner h="200px" />}
      {!showSpinner && (
        <>
          <Table
            columnDefs={columnDefs}
            rowData={isWidget ? transactions.slice(0, 5) : transactions}
            type={tableType}
            onCellClick={onCellClick}
            hasMore={hasNextPage}
            isFetching={isFetching}
            onSelectRow={onSelectRow}
            selectedRow={selectedExchange}
            MasterDetailsComponent={RecentActivityTableDetails}
            {...(isWidget
              ? {}
              : {
                  loadMore: fetchNextPage,
                })}
          />
          {isWidget && (
            <>
              {!!transactions.length && (
                <PrimaryButton
                  onPress={onOpenRecentActivity}
                  position="absolute"
                  left="0"
                  bottom="0"
                  w="100%"
                  size="lg">
                  See all
                </PrimaryButton>
              )}
              {!transactions.length && <BuySellPromo />}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default RecentActivity;
