import { Box, Flex } from 'native-base';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { OverlaySpinner } from '@cryptowallet/frontend/ui';
import CenterElement from '@cryptowallet/web/components/CenterElement';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';
import Table, { TableType } from '@cryptowallet/web/components/Table';
import TagGroup from '@cryptowallet/web/components/TagGroup';

import ReferralUserMasterDetails from '../components/ReferralUserMasterDetails';
import useReferralContracts from '../hooks/useReferralContracts';

import { columnDefs } from './config';
import InviteFriendsPlaceholder from './InviteFriendsPlaceholder';

const statusFilters = [
  { label: 'all users', value: [undefined] },
  { label: 'successful', value: [true] },
  { label: 'pending', value: [false] },
];

const ReferralInvitedUsers = () => {
  const [assignedRewardFilter, setAssignedRewardFilter] = useState<Array<boolean | undefined>>(statusFilters[0].value);
  const { isDesktop, isMobile } = useScreenSize();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState('');

  const {
    isFetching: isReferralContractsLoading,
    referralContracts,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useReferralContracts(assignedRewardFilter[0]);

  if (isReferralContractsLoading) {
    return (
      <CenterElement>
        <OverlaySpinner />
      </CenterElement>
    );
  }

  const showUsersTable = !!referralContracts.length || assignedRewardFilter[0] !== undefined;

  return (
    <Flex flexGrow="1">
      <TabHeader onGoBack={isDesktop ? null : () => navigate('/referral')}>Invited users</TabHeader>
      <TabContentLayout px={showUsersTable ? '0' : '32px'} pt={showUsersTable ? '0' : '32px'}>
        {!showUsersTable && <InviteFriendsPlaceholder />}
        {showUsersTable && (
          <>
            <TagGroup
              tags={statusFilters}
              selected={assignedRewardFilter}
              onChange={val => setAssignedRewardFilter(val.value)}
              containerProps={{
                pt: '40px',
                px: '32px',
                pb: {
                  base: '24px',
                  md: '0',
                },
              }}
            />
            <Box px="6px">
              {!referralContracts.length && <InviteFriendsPlaceholder showIcon={false} />}
              {!!referralContracts.length && (
                <Table
                  columnDefs={columnDefs}
                  rowData={referralContracts}
                  type={isMobile ? TableType.SMALL : TableType.FULL}
                  containerProps={{ zIndex: -1 }}
                  loadMore={fetchNextPage}
                  hasMore={hasNextPage}
                  isFetching={isFetching}
                  {...(isMobile
                    ? {
                        onSelectRow: id => setSelectedRow(prev => (prev === id ? '' : id)),
                        selectedRow,
                        MasterDetailsComponent: ReferralUserMasterDetails,
                      }
                    : {})}
                />
              )}
            </Box>
          </>
        )}
      </TabContentLayout>
    </Flex>
  );
};

export default ReferralInvitedUsers;
