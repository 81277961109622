import { ITokenService } from '../token';

const storage = localStorage;

const KEYS: {
  ACCESS: string;
  REFRESH: string;
} = {
  ACCESS: '__access_token',
  REFRESH: '__refresh_token',
};

export const tokenService: ITokenService = {
  getToken() {
    return storage.getItem(KEYS.ACCESS) || null;
  },

  hasToken() {
    return !!storage.getItem(KEYS.ACCESS);
  },

  getRefreshToken() {
    return storage.getItem(KEYS.REFRESH);
  },

  hasRefreshToken() {
    return storage.getItem(KEYS.REFRESH) !== undefined;
  },

  setTokens(accessToken: string, refreshToken: string): void {
    storage.setItem(KEYS.ACCESS, accessToken);
    storage.setItem(KEYS.REFRESH, refreshToken);
  },

  removeTokens() {
    storage.removeItem(KEYS.ACCESS);
    storage.removeItem(KEYS.REFRESH);
  },
};
