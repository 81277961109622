import { Box, Center, HStack, Text } from 'native-base';

import { ButtonPurple, ButtonSecondary, Icon, usePartnerStyles } from '@cryptowallet/frontend/ui';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

export interface FlowCanceledProps {
  permissionType: string;
  content: () => JSX.Element;
  restart: () => void;
}

const loginBtnText = 'Continue verification';
const FlowCanceled = ({ permissionType, content, restart }: FlowCanceledProps) => {
  const partnerStyles = usePartnerStyles('FlowCanceled');

  return (
    <Center flexGrow="1">
      <Box
        w={{ base: '100%', lg: '520px' }}
        flexGrow={{ base: '1', lg: 'unset' }}
        maxW="100%"
        bg="background.400"
        borderRadius="10px"
        {...partnerStyles}>
        <AuthHeader>
          <FormHeading
            text={`${permissionType} Canceled`}
            display="flex"
            justifyContent="center"
            textProps={{ textAlign: 'center', w: '100%' }}
          />
        </AuthHeader>
        <Box flexGrow="1" p="24px" justifyContent={{ base: 'space-between', lg: 'flex-start' }}>
          <Box alignItems="center">
            <Icon name="kyc-error" w="90px" h="90px" />
            <Text mt="40px" fontSize="md" textAlign="center">
              {content()}
            </Text>
          </Box>
          <HStack mt="32px" alignItems="center">
            <Box w="50%" pr={{ base: '10px', md: '20px' }}>
              <ButtonSecondary
                onPress={() => window.open('https://support.cryptowallet.com/support/home', '_blank')}
                _text={{ fontSize: 'lg', color: 'textRegular' }}
                w="100%"
                h="50px">
                Contact support
              </ButtonSecondary>
            </Box>
            <Box w="50%" pl={{ base: '10px', md: '20px' }}>
              <ButtonPurple w="100%" onPress={restart} isLoadingText={loginBtnText} _text={{ fontSize: 'lg' }} h="50px">
                {loginBtnText}
              </ButtonPurple>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Center>
  );
};

export default FlowCanceled;
