import { Box, Text, VStack } from 'native-base';
import React from 'react';

import { SellExchangeResult, useSellExchange } from '@cryptowallet/frontend/hooks';
import { useSellFlowStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, Icon, OverlaySpinner } from '@cryptowallet/frontend/ui';

import DiscordPromo from '../DiscordPromo';

import SellPaymentInfo from './SellPaymentInfo';
import SellTransactionDetails from './SellTransactionDetails';

const data = {
  [SellExchangeResult.SUCCESS]: {
    title: 'Done',
    description: 'Your exchange completed',
  },
  [SellExchangeResult.ERROR]: {
    title: 'Transaction failed',
    description: 'Something went wrong',
  },
};

const SellCompleted = () => {
  const { result, isLoading } = useSellExchange();
  const startNewExchange = useSellFlowStore(state => state.reset);

  if (isLoading) {
    return (
      <Box h="1000px">
        <OverlaySpinner />
      </Box>
    );
  }

  const { title, description } = data[result];

  return (
    <VStack>
      <VStack mt="32px" alignItems="center" pb="24px" borderBottomWidth="1px" borderBottomColor="gray.100">
        <Icon name={result} h="80px" w="80px" />
        <Text color="white" mt="26px" fontSize="2xl">
          {title}
        </Text>
        <Text color="textRegular" mt="6px" fontSize="lg">
          {description}
        </Text>
      </VStack>
      <VStack px="24px" mt="24px">
        <SellPaymentInfo sellCompleted={true} containerProps={{ mb: '0' }} />
        <SellTransactionDetails sellCompleted={true} />
      </VStack>
      <DiscordPromo />
      <BottomPurpleButton h="80px" size="lg" onPress={startNewExchange}>
        Start new exchange
      </BottomPurpleButton>
    </VStack>
  );
};

export default SellCompleted;
