import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import CenterElement from '@cryptowallet/web/components/CenterElement';
import { RoundedBox } from '@cryptowallet/web/components/layout';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import CountryForm from './components/CountryForm';
import EmailForm from './components/EmailForm';
import OtpVerificationForm from './components/OtpVerificationForm';
import WhitelistRegistrationResult from './components/WhitelistRegistrationResult';
import useStore, { WhitelistRegisterResult } from './store';

const steps: IStep[] = [
  {
    label: 'Whitelist Verification',
    Component: CountryForm,
  },
  {
    label: `Whitelist verification`,
    description: 'Enter your email and press Continue',
    Component: EmailForm,
  },
  {
    label: 'Email Verification',
    Component: OtpVerificationForm,
  },
];

const Whitelist = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const flowIsCompleted = useStore(state => state.flowIsCompleted);

  const { label } = steps[stepIndex];

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  return (
    <CenterElement>
      <RoundedBox background="background.400" borderRadius="10px" overflow="visible" maxW="100vw">
        {!flowIsCompleted && (
          <Stepper
            header={label}
            headerProps={{
              mb: 0,
            }}
            steps={steps}
            canGoToPrevStep={false}
            hideProgressBar={true}
            stepperState={[stepIndex, setStepIndex]}
          />
        )}
        {flowIsCompleted && <WhitelistRegistrationResult result={WhitelistRegisterResult.DUPLICATE} />}
      </RoundedBox>
    </CenterElement>
  );
};

export default Whitelist;
