import { IPressableProps } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Pressable } from '@cryptowallet/frontend/ui';

interface ICustomLinkProps extends IPressableProps {
  href?: string;
  disabled?: boolean;
  onNavigate?: () => void;
  parentNavigate?: () => void;
}

const NavigationLink = ({
  children,
  href,
  onPress,
  disabled,
  onNavigate,
  parentNavigate,
  ...rest
}: ICustomLinkProps) => {
  // eslint-disable-next-line
  const navigate = parentNavigate || useNavigate();

  const handlePress = params => {
    if (onPress) {
      onPress(params);
    } else {
      navigate(href);
    }

    if (onNavigate) {
      onNavigate();
    }
  };

  return (
    <Pressable onPress={handlePress} {...rest}>
      {({ isPressed, isHovered, isFocused }) =>
        typeof children !== 'function'
          ? children
          : children({
              isPressed,
              isHovered,
              isFocused,
            })
      }
    </Pressable>
  );
};

export default NavigationLink;
