import { Box, HStack, Text } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { useAlerts, usePartnerSettingsData } from '@cryptowallet/frontend/ui';

import PrimaryLink from './Links/PrimaryLink';

const Footer = (): JSX.Element => {
  const { isBiggerThanTablet, isMobile } = useScreenSize();
  const { enabled: isPartnerSettingsEnabled } = usePartnerSettingsData();
  const { createAlert } = useAlerts();

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent={{ base: 'center', lg: 'space-between' }}
      alignItems="center"
      flexDirection={{ base: 'column', lg: 'row' }}
      px="32px"
      py="32px"
      pr={{ base: '32px', lg: '100px' }}
      bg="background.400"
      height="80px"
      zIndex="-1">
      {isBiggerThanTablet && (
        <HStack>
          <PrimaryLink
            isExternal={true}
            href="https://cryptowallet.com/app/uploads/2024/06/Updated_Terms_of_Use_18_06.2024.pdf"
            mr="24px"
            text="Terms & Conditions"
            {...(isPartnerSettingsEnabled
              ? {
                  onPress: () => createAlert('TermsAlert'),
                }
              : {})}
          />
          <PrimaryLink
            isExternal={true}
            href="https://cryptowallet.com/app/uploads/2024/06/Privacy_policy_upd_18_06_2024.pdf"
            mr="24px"
            text="Privacy Policy"
            {...(isPartnerSettingsEnabled
              ? {
                  onPress: () => createAlert('PrivacyPolicyAlert'),
                }
              : {})}
          />
          <PrimaryLink
            isExternal={true}
            href="https://cryptowallet.com/terms/cookie-policy/"
            mr="24px"
            text="Cookie Policy"
            {...(isPartnerSettingsEnabled
              ? {
                  onPress: () => createAlert('CookiesPolicyAlert'),
                }
              : {})}
          />
        </HStack>
      )}
      <HStack
        w={{ base: '100%', lg: 'unset' }}
        justifyContent={{ base: 'center', md: 'space-between', xl: 'unset' }}
        alignItems="center">
        {!isMobile && (
          <Text fontSize="md" textAlign="center" mr="24px">
            Romana Dmowskiego 3 / 9, 50-203 Wrocław, Polska
          </Text>
        )}
        <Text fontSize="md" textAlign="center">
          Estx Sp. z o.o. – {new Date().getFullYear()}
        </Text>
      </HStack>
    </Box>
  );
};

export default Footer;
