import useWalletSummary from './useWalletSummary';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { useMemo } from 'react';
import { WalletResourceEntityDto } from '@cryptowallet/frontend/client';

const useSelectedWalletResource = (): WalletResourceEntityDto => {
  const { allWalletResources } = useWalletSummary();
  const selectedWalletAssetId = useAccountsStore(state => state.selectedWalletAssetId);

  const selectedWalletResource = useMemo(
    () => allWalletResources.find(item => item.walletAsset.id === selectedWalletAssetId),
    [allWalletResources, selectedWalletAssetId],
  );

  return selectedWalletResource;
};

export default useSelectedWalletResource;
