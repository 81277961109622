import React from 'react';
import { Controller } from 'react-hook-form';

import { LabeledInput } from '../../../index';

import { IFormGuardComponentProps } from '.';

const TotpFormGuard = ({ control, onSubmit, name, minLength }: IFormGuardComponentProps) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: '2FA code is required',
      minLength: {
        value: minLength,
        message: '2FA code must contain 6 digits',
      },
    }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <LabeledInput
        keyboardType="numeric"
        label="Enter your 2FA code"
        labelTextProps={{
          color: 'textLabel',
          fontSize: 'lg',
        }}
        onSubmit={onSubmit}
        placeholder="Enter code here"
        onChangeText={val => onChange(val.replace(/\D/g, '').substring(0, 6))}
        value={value}
        error={error}
        h="56px"
        fontSize="lg"
      />
    )}
  />
);

export default TotpFormGuard;
