import { IColumnDef, TableType } from '@cryptowallet/web/components/Table';
import { ColumnType } from '@cryptowallet/web/components/Table/Row';
import ReferralCustomCDate from '@cryptowallet/web/pages/Referral/components/ReferralCustomCDate';
import ReferralCustomSimpleText from '@cryptowallet/web/pages/Referral/components/ReferralCustomSimpleText';

import RewardsCustomCopyText from '../Settings/components/RewardsCustomCopyBox';

export const getRewardClientStatus = data => (data.status === 'PAID' ? 'Paid' : 'Waiting for payout');
// @todo : Remove on the introduction of general rewards instead of referral rewards
export const getRewardClientType = data => (data.amount === 50 ? 'Welcome bonus' : 'Referral bonus');

export const columnDefs: IColumnDef[] = [
  // {
  //   id: 'id',
  //   headerName: 'Reward ID',
  //   field: 'id',
  //   type: ColumnType.CUSTOM,
  //   cellRenderer: RewardsCustomCopyText,
  //   containerStyles: {
  //     [TableType.FULL]: {
  //       w: '28%',
  //       mr: '24px',
  //     },
  //     [TableType.SMALL]: {
  //       w: '50%',
  //       mr: '24px',
  //     },
  //   },
  // },
  {
    id: 'bonus',
    headerName: 'Bonus',
    field: 'amount',
    getValue: data => `${data.amount} ${data.walletAssetExternalId}`,
    type: ColumnType.CUSTOM,
    cellRenderer: ReferralCustomSimpleText,
    containerStyles: {
      [TableType.FULL]: {
        w: '33%',
      },
      [TableType.SMALL]: {
        w: '50%',
      },
    },
  },
  {
    id: 'type',
    headerName: 'Type',
    field: 'type',
    getValue: getRewardClientType,
    type: ColumnType.SIMPLE_TEXT,
    cellRenderer: ReferralCustomSimpleText,
    hidden: {
      type: TableType.SMALL,
    },
    containerStyles: {
      [TableType.FULL]: {
        w: '33%',
      },
      [TableType.SMALL]: {
        w: '50%',
      },
    },
  },
  {
    id: 'accrued_date',
    headerName: 'Date',
    field: 'accruedAt',
    type: ColumnType.DATE,
    cellRenderer: ReferralCustomCDate,
    containerStyles: {
      [TableType.FULL]: {
        w: '33%',
      },
      [TableType.SMALL]: {
        w: '50%',
      },
    },
  },
  // {
  //   id: 'status',
  //   headerName: 'Status',
  //   field: 'status',
  //   getValue: getRewardClientStatus,
  //   type: ColumnType.SIMPLE_TEXT,
  //   hidden: {
  //     type: [TableType.SMALL],
  //   },
  //   containerStyles: {
  //     [TableType.FULL]: {
  //       w: '25%',
  //     },
  //   },
  // },
];
