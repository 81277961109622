import omit from 'lodash/omit';
import { Box, Popover, useDisclose, useToken, VStack } from 'native-base';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { Pressable, PreventFocusFirstElementOnModal } from '@cryptowallet/frontend/ui';
import NavigationHelpIcon from '@cryptowallet/web/icons/NavigationHelpIcon';
import NavigationInviteFriendIcon from '@cryptowallet/web/icons/NavigationInviteFriendIcon';
import NavigationLogoutIcon from '@cryptowallet/web/icons/NavigationLogoutIcon';
import NavigationReferralInvitedUsersIcon from '@cryptowallet/web/icons/NavigationReferralInvitedUsersIcon';
import NavigationSettingsIcon from '@cryptowallet/web/icons/NavigationSettingsIcon';
import NavigationSettingsSecurityIcon from '@cryptowallet/web/icons/NavigationSettingsSecurityIcon';
import NavigationSupportIcon from '@cryptowallet/web/icons/NavigationSupportIcon';

import NavigationItem, { NavigationItemType } from '../NavigationItem';

const AccontNavigation = ({ navigate, setShowLogoutConfirmModal }) => {
  const { isOpen, onClose, onOpen } = useDisclose();
  const [textLabelColor] = useToken('colors', ['textLabel']);
  const { kycPoaHighRiskVerified } = useUserStatus();
  const { isMobile } = useScreenSize();
  const location = useLocation();

  const elements = useMemo(
    () => [
      {
        id: 'settings',
        title: 'Settings',
        icon: () => <NavigationSettingsIcon color={textLabelColor} />,
        type: NavigationItemType.ACTION,
        // eslint-disable-next-line
        href: isMobile ? '/account' : kycPoaHighRiskVerified ? '/account/2fa' : '/account/security',
        basePathName: '/account',
      },
      {
        id: 'security',
        title: 'Security center',
        icon: () => <NavigationSettingsSecurityIcon color={textLabelColor} />,
        type: NavigationItemType.LINK,
        href: '/account/security',
        hasNotification: !kycPoaHighRiskVerified,
      },
      {
        id: 'invite',
        title: 'Invite a friend',
        icon: () => <NavigationInviteFriendIcon color={textLabelColor} />,
        type: NavigationItemType.LINK,
        href: '/referral/overview',
      },
      {
        id: 'help',
        title: 'Help',
        icon: () => <NavigationHelpIcon color={textLabelColor} />,
        type: NavigationItemType.ACTION,
        onPress: () => window.open('https://support.cryptowallet.com/support/home', '_blank'),
      },
      {
        id: 'support',
        title: 'Support',
        icon: () => <NavigationSupportIcon color={textLabelColor} />,
        type: NavigationItemType.ACTION,
        onPress: () => {
          const chatElem = document.querySelector('#mobile-chat-container') as HTMLElement;
          chatElem.click();
        },
      },
      {
        id: 'logout',
        title: 'Log out',
        icon: () => <NavigationLogoutIcon color={textLabelColor} />,
        type: NavigationItemType.ACTION,
        onPress: () => setShowLogoutConfirmModal(true),
      },
    ],
    [kycPoaHighRiskVerified, isMobile, setShowLogoutConfirmModal],
  );

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      trigger={triggerProps => (
        <Box {...omit(triggerProps, ['onPress'])}>
          <Pressable
            height="40px"
            width="40px"
            borderColor="transparent"
            borderRadius="50%"
            borderWidth="1px"
            onPress={onOpen}
            alignItems="center"
            justifyContent="center"
            bg={isOpen ? 'rgba(255, 255, 255, 0.2)' : 'transparent'}
            outlineWidth={0}
            _focus={{
              borderColor: 'secondary.400',
            }}
            _hover={{
              bg: 'rgba(255, 255, 255, 0.2)',
            }}>
            <NavigationReferralInvitedUsersIcon size="22" color={textLabelColor} />
            {!kycPoaHighRiskVerified && (
              <Box position="absolute" top="5px" right="6px" h="6px" w="6px" borderRadius="6px" bg="error.400" />
            )}
          </Pressable>
        </Box>
      )}>
      <Popover.Content borderColor="gray.100" bg="#262629" w="306">
        <Popover.Arrow borderColor="gray.100" bg="#262629" />
        <Popover.Body p="13px">
          <PreventFocusFirstElementOnModal />
          <VStack>
            {elements.map(item => (
              <NavigationItem
                key={item.id}
                active={location.pathname.indexOf(item.basePathName || item.href) !== -1}
                onNavigate={onClose}
                navigate={navigate}
                settingsView={true}
                {...item}
              />
            ))}
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

export default AccontNavigation;
