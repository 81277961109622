import { Box } from 'native-base';

import { useBuyExchange } from '@cryptowallet/frontend/hooks';
import { useBuyFlowStore } from '@cryptowallet/frontend/stores';
import { Spinner } from '@cryptowallet/frontend/ui';
import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/web/api-client';

import CardPayment from './Card';
import SepaPayment from './Sepa';

const PAYMENT_COMPONENTS = {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: SepaPayment,
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: CardPayment,
};

const Payment = (): JSX.Element => {
  const paymentMethod = useBuyFlowStore(state => state.paymentMethod);

  const { isLoading, data: exchangeResponse } = useBuyExchange();

  const PaymentComponent = PAYMENT_COMPONENTS[paymentMethod];

  return (
    <Box mt="24px">
      {isLoading ? <Spinner height="400px" /> : <PaymentComponent exchange={exchangeResponse.data} />}
    </Box>
  );
};

export default Payment;
