import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Box, FlatList, StyledProps, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useScreenSize, useWalletConfig, useWalletSummary } from '@cryptowallet/frontend/hooks';
import { DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { Input, useUserSettings } from '@cryptowallet/frontend/ui';
import { WalletResourceEntityDto } from '@cryptowallet/web/api-client';

import AssetListItem from './AssetListItem';

gsap.registerPlugin(ScrollToPlugin);

const filterableColumns = ['label', 'ticker'];

const filterWalletResource = ({ asset: { currency } }, filterValue) =>
  filterableColumns.some(column => currency[column].toLowerCase().includes(filterValue.toLowerCase()));

const AssetList = ({
  isActionSheet,
  containerProps = {},
}: {
  showToaster?: any;
  isActionSheet?: boolean;
  containerProps?: StyledProps;
}) => {
  const assetListContainerRef = useRef();
  const { isBiggerThanTablet } = useScreenSize();
  const { hideBalance, hiddenWalletAssets } = useUserSettings();

  const {
    selectedWalletAccountId,
    setSelectedWalletAccountId,
    setSelectedWalletAssetId,
    setDetailsPanelView,
    selectedWalletAssetId,
  } = useAccountsStore(
    state => ({
      setBalanceListView: state.setBalanceListView,
      selectedWalletAccountId: state.selectedWalletAccountId,
      setSelectedWalletAccountId: state.setSelectedWalletAccountId,
      setSelectedWalletAssetId: state.setSelectedWalletAssetId,
      setDetailsPanelView: state.setDetailsPanelView,
      selectedWalletAssetId: state.selectedWalletAssetId,
    }),
    shallow,
  );
  const [ready, setReady] = useState(false);
  const { existingWalletResources, recommendedWalletResources } = useWalletSummary();
  const { fiatCurrency } = useWalletConfig();
  const [filterValue, setFilterValue] = useState('');

  const filteredAssets = useMemo(() => {
    const filteredExistingWalletResources = existingWalletResources.filter(item =>
      filterWalletResource(item, filterValue),
    );

    const filteredRecommendedWalletResources = recommendedWalletResources.filter(item =>
      filterWalletResource(item, filterValue),
    );

    return [...filteredExistingWalletResources, ...filteredRecommendedWalletResources].filter(
      ({ asset }) => !hiddenWalletAssets.includes(asset.id),
    );
  }, [filterValue, existingWalletResources, hiddenWalletAssets, recommendedWalletResources]);

  const onSelectAsset = (item: WalletResourceEntityDto) => {
    if (item.isCreated) {
      setSelectedWalletAccountId(item.walletAccount.id);
      setDetailsPanelView(DetailsPanelView.Overview);
    } else {
      setSelectedWalletAssetId(item.walletAsset.id);
      setDetailsPanelView(DetailsPanelView.GenerateAddress);
    }
  };

  useEffect(() => {
    if (isActionSheet) {
      setTimeout(() => {
        setReady(true);
      }, 200); // 200 - actionsheet animation duration
    } else {
      setReady(true);
    }
  }, [isActionSheet]);

  return (
    <VStack h="100%">
      <Input
        onChangeText={v => setFilterValue(v)}
        value={filterValue}
        placeholder="Search"
        mt="16px"
        mb="24px"
        _invalid={{
          // need to ignore parent invalid state
          borderColor: 'transparent',
        }}
        minH="56px"
        fontSize="16px"
        size="lg"
        autoFocus={isBiggerThanTablet || isActionSheet}
      />
      <Box ref={assetListContainerRef} overflowY="auto" h="100%" {...containerProps}>
        {ready && (
          <FlatList
            data={filteredAssets}
            renderItem={({ item }) => (
              <AssetListItem
                item={item}
                fiatCurrency={fiatCurrency}
                onSelectAsset={() => {
                  gsap.to(window, {
                    scrollTo: { y: 0 },
                    duration: isBiggerThanTablet ? 0.8 : 0,
                  });
                  onSelectAsset(item);
                }}
                hideBalance={hideBalance}
                selected={
                  selectedWalletAssetId === item.walletAsset.id || selectedWalletAccountId === item.walletAccount?.id
                }
              />
            )}
          />
        )}
      </Box>
    </VStack>
  );
};

export default AssetList;
