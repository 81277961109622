import { HStack, Modal, Text, VStack } from 'native-base';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { BottomPurpleButton, CloseButton, Icon, ModalBase } from '../../../index';

export const RegistrationCompleteAlert = ({ onClose }) => {
  const navigate = useNavigate();
  const setup2faRef = useRef();

  return (
    <ModalBase blurredBg={true} initialFocusRef={setup2faRef} onClose={onClose}>
      <Modal.Content borderRadius="10px" w="588px" maxW="100vw">
        <VStack borderTopLeftRadius="10px" borderTopRightRadius="10px" border="none" bg="background.400">
          <Modal.Header borderColor="transparent" py="26px" px="32px">
            <HStack justifyContent="space-between" alignItems="center">
              <HStack alignItems="center">
                <Icon name="alerts/alert-success--green" h="20px" w="20px" mr="8px" />
                <Text color="white" fontSize="20px">
                  Registration Complete
                </Text>
              </HStack>
              <CloseButton iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }} onPress={onClose} />
            </HStack>
          </Modal.Header>
          <Modal.Body p="0">
            <VStack p="32px">
              <Text fontSize="lg" color="textRegular">
                Congratulations! Now you’re ready to unlock your crypto’s full potential. We strongly recommend setting
                up 2FA to secure your account.
              </Text>
              <HStack
                mt="16px"
                py="18px"
                px="16px"
                borderWidth="1px"
                borderColor="secondary.400:alpha.50"
                borderRadius="5px">
                <Icon name="alerts/alert-warning--orange" h="20px" w="20px" mr="8px" />
                <Text fontSize="lg" color="white">
                  Low account security level: set up 2-Factor Authentication
                </Text>
              </HStack>
            </VStack>
          </Modal.Body>
        </VStack>
        <BottomPurpleButton
          ref={setup2faRef}
          onPress={() => {
            onClose();
            navigate('/account/2fa');
          }}
          _text={{ fontSize: 'lg', color: 'white' }}>
          Set up 2fa
        </BottomPurpleButton>
      </Modal.Content>
    </ModalBase>
  );
};
