import { HStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AccontNavigation from './AccontNavigation';

const TopNavigation = ({ setShowLogoutConfirmModal }) => {
  const navigate = useNavigate();

  return (
    <HStack>
      <AccontNavigation navigate={navigate} setShowLogoutConfirmModal={setShowLogoutConfirmModal} />
    </HStack>
  );
};

export default TopNavigation;
