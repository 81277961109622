import { Box, StyledProps, Text } from 'native-base';
import React from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

import { Pressable } from '../../index';

const isWeb = getPlatform() === 'web';

interface ISwitchProps extends StyledProps {
  isChecked: boolean;
  onToggle: () => void;
  label?: string;
  isDisabled?: boolean;
}

// NativeBase switch allows u to customize only switch colors

const Switch = ({ isChecked, onToggle, label, isDisabled = false, ...rest }: ISwitchProps) => (
  <Pressable
    onPress={isDisabled ? () => {} : onToggle}
    flexDirection="row"
    alignItems="center"
    outlineWidth="0"
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    {...rest}>
    {({ isFocused }) => {
      let bg = isChecked ? 'primary.400' : 'gray.200';
      let borderColor = isFocused ? 'secondary.400' : 'background.300';

      if (isWeb) {
        bg = isChecked ? 'primary.400' : 'gray.200';
        borderColor = isFocused ? 'secondary.400' : 'transparent';
      }

      if (isDisabled) {
        bg = 'transparent';
        borderColor = isWeb ? 'gray.100' : borderColor;
      }

      return (
        <>
          <Box
            p="4px"
            bg={bg}
            w="56px"
            borderRadius="42px"
            borderWidth="1px"
            borderColor={borderColor}
            alignItems={isChecked ? 'flex-end' : 'flex-start'}>
            <Box h="20px" w="20px" borderRadius={isWeb ? '50%' : '50'} bg={isDisabled ? 'gray.100' : 'white'} />
          </Box>
          {label && (
            <Text
              w={isWeb ? undefined : '80%'}
              ml={isWeb ? '13px' : '9px'}
              fontSize="lg"
              color="textRegular"
              flexWrap="wrap">
              {label}
            </Text>
          )}
        </>
      );
    }}
  </Pressable>
);

export default Switch;
