import { Box } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '@cryptowallet/frontend/ui';

const StepLine = ({ active }) => {
  const partnerStyles = usePartnerStyles('StepLine');

  return (
    <Box
      height="3px"
      m="2px"
      background={active ? 'primary.400' : 'gray.200'}
      {...(partnerStyles.bg
        ? {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            background: active ? partnerStyles.bg.active : partnerStyles.bg.normal,
          }
        : {})}
    />
  );
};

export default StepLine;
