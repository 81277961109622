import { StyledProps, VStack } from 'native-base';
import React, { ReactNode, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'; //  on mobile - flatlist from nativebase or react-native

import { Spinner } from '@cryptowallet/frontend/ui';

import { ICopyTextColumnDef } from './CellRenderers/CopyText';
import {
  IColoredTextColumnDef,
  IDateColumnDef,
  ISimpleTextColumnDef,
  ISingleSelectColumnDef,
  ITrxDetailsColumnDef,
} from './CellRenderers';
import Headers from './Headers';
import Row from './Row';

export enum TableType {
  SMALL = 'small',
  FULL = 'full',
}

interface BaseColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: unknown;
  getValue?: (data: any, col: IColumnDef) => string;
  cellRenderer?: ReactNode;
  sortIndex?: {
    [key in TableType]?: number;
  };
  containerStyles?: {
    [key in TableType]?: StyledProps;
  };
  headerContainerStyles?: {
    [key in TableType]?: StyledProps;
  };
  hideHeader?: boolean;
  hidden?: {
    type?: TableType[];
  };
}

export type IColumnDef = BaseColumnDef &
  (
    | IColoredTextColumnDef
    | ISingleSelectColumnDef
    | ITrxDetailsColumnDef
    | IDateColumnDef
    | ISimpleTextColumnDef
    | ICopyTextColumnDef
  );

export interface IRow {
  id: string;
  [key: string]: any;
}

export interface IRowRendererProps {
  data: any;
  columnDef?: IColoredTextColumnDef | ISingleSelectColumnDef;
}

export interface IMasterDetailsComponentProps {
  tableType: TableType;
  data: any;
}

interface ITableProps {
  columnDefs: IColumnDef[];
  rowData: IRow[];
  type?: TableType;
  onSelectRow?: (id: string) => void;
  selectedRow?: string;
  onCellClick?: (col: IColumnDef, data: any, rowId?: string) => void;
  MasterDetailsComponent?: (props: IMasterDetailsComponentProps) => JSX.Element;
  containerProps?: StyledProps;
  loadMore?: () => void;
  hasMore?: boolean;
  isFetching?: boolean;
}

const getDefaultIndex = (arr, id) => arr.findIndex(item => item.id === id);

const Table = ({
  columnDefs = [],
  rowData = [],
  type = TableType.SMALL,
  onSelectRow,
  selectedRow,
  onCellClick,
  MasterDetailsComponent,
  containerProps = {},
  loadMore,
  hasMore,
  isFetching = false,
}: ITableProps) => {
  const columns = useMemo<IColumnDef[]>(
    () =>
      columnDefs
        .filter(col => {
          if (col.hidden) {
            return !(col.hidden.type || []).includes(type);
          }

          return true;
        })
        .sort((a, b) => {
          const aSortIndex = a.sortIndex && a.sortIndex[type] ? a.sortIndex[type] : getDefaultIndex(columnDefs, a.id);
          const bSortIndex = b.sortIndex && b.sortIndex[type] ? b.sortIndex[type] : getDefaultIndex(columnDefs, b.id);

          return aSortIndex - bSortIndex;
        }),
    [columnDefs, type],
  );

  const rows = () => (
    <>
      {rowData.map((row, index) => {
        const nextRowSelected = rowData[index + 1]?.id === selectedRow;
        return (
          <Row
            key={row.id}
            tableType={type}
            data={row}
            columns={columns}
            lastElement={index === rowData.length - 1}
            onSelectRow={onSelectRow}
            selected={selectedRow === row.id}
            nextRowSelected={nextRowSelected}
            onCellClick={onCellClick}
            MasterDetailsComponent={MasterDetailsComponent}
          />
        );
      })}
    </>
  );

  return (
    <VStack px={type === TableType.FULL ? '13px' : { base: '9px', lg: '11px' }} {...containerProps}>
      {type === TableType.FULL && <Headers type={type} columns={columns} />}
      {loadMore && (
        <InfiniteScroll
          dataLength={rowData.length}
          next={loadMore}
          hasMore={hasMore}
          loader={isFetching ? <Spinner height="100px" /> : null}>
          {rows()}
        </InfiniteScroll>
      )}
      {!loadMore && rows()}
    </VStack>
  );
};

export default Table;
