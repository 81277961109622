import { useAlerts, useUser } from '@cryptowallet/frontend/ui';

import Authentication, { AuthenticationProps } from './steps/Authentication';
import FlowCanceled, { FlowCanceledProps } from './steps/Canceled';
import { getNewAccessToken, initIframe } from './initVerificationIframe';
import PermissionFlow from './PermissionFlow';

const permissionType = 'POA';

const AuthenticationComponent = (props: AuthenticationProps) => {
  const { user } = useUser();
  const { createAlert } = useAlerts();

  return (
    <Authentication
      getNewAccessToken={getNewAccessToken}
      initIframe={initIframe}
      header="Please confirm your address to proceed"
      permissionType={permissionType}
      onComplete={() => {
        if (!user.totpEnabled) {
          createAlert('RegistrationCompleteAlert');
        }
      }}
      {...props}
    />
  );
};

const FlowCanceledComponent = (props: FlowCanceledProps) => (
  <FlowCanceled
    permissionType={permissionType}
    content={() => (
      <>
        Sorry, you can&#39;t use our platform without verifying your address. It is the requirement of regulatory
        authorities. To continue the POA procedure, please log in with your email.
      </>
    )}
    {...props}
  />
);

export function Poa({ authenticationProps }: { authenticationProps?: Partial<AuthenticationProps> }): JSX.Element {
  return (
    <PermissionFlow
      AuthenticationComponent={AuthenticationComponent}
      FlowCanceledComponent={FlowCanceledComponent}
      authenticationProps={authenticationProps}
    />
  );
}
