import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types';
import React, { forwardRef } from 'react';

import { Button, usePartnerStyles } from '../../../index';

const bottomPurpleButtonStyles: IButtonProps = {
  _text: {
    color: 'white',
    fontSize: 'lg',
  },
  borderRadius: '0',
  bg: 'primary.400',
  pt: '28px',
  pb: '28px',
  outlineWidth: '0',
  borderWidth: '1px',
  borderColor: 'transparent',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  _hover: {
    bg: 'primary.300',
  },
  _focus: {
    borderColor: 'secondary.400',
  },
  _loading: {
    opacity: 1,
  },
  _spinner: {
    color: 'secondary.400',
  },
  _disabled: {
    opacity: 1,
    _text: {
      color: 'textDisabled',
    },
    // eslint-disable-next-line sonarjs/no-duplicate-string
    bg: 'gray.100',
    _hover: {
      bg: 'gray.100',
    },
    _focus: {
      bg: 'gray.100',
      _hover: {
        bg: 'gray.100',
      },
    },
  },
};

export const BottomPurpleButton = forwardRef<HTMLElement, IButtonProps>(({ children, ...props }, forwardedRef) => {
  const partnerStyles = usePartnerStyles('BottomPurpleButton');

  return (
    <Button ref={forwardedRef} h="80px" variant="unstyled" {...bottomPurpleButtonStyles} {...props} {...partnerStyles}>
      {children}
    </Button>
  );
});
