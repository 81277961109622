import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { Poa } from '@cryptowallet/web/components/PermissionCases';

const SecurityVerificationPoa = () => {
  const userStatus = useUserStatus();

  if (!userStatus.VERIFIED_KYC) {
    return <Navigate to="/account/security/verification" replace />;
  }

  if (!userStatus.VERIFIED_POA) {
    return <Poa />;
  }

  return <Navigate to="/account/security" replace />;
};

export default SecurityVerificationPoa;
