import { HStack, Text, VStack } from 'native-base';
import React from 'react';

import { CurrencyNetwork } from '@cryptowallet/frontend/ui';
import { getNetworkData, maskNumber, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';

const AssetDetailsBalance = ({ hideBalance, cryptoBalance, currency, fiatBalance, fiatCurrency, network }) => {
  const { color: networkColor } = getNetworkData(network?.ticker);
  return (
    <VStack>
      <Text fontSize="40px" fontFamily="Uniform Pro Con" lineHeight="40px" color="white">
        {hideBalance ? maskNumber(8) : trimCryptoAmountLength(cryptoBalance, 9)} {currency.ticker}
      </Text>
      <HStack alignItems="center">
        <Text fontSize="20px" lineHeight="20px" color="textLabel">
          {fiatCurrency.symbol} {hideBalance ? maskNumber(2) : trimCryptoAmountLength(fiatBalance, 2)}
        </Text>
        {network && <CurrencyNetwork ml="8px" color={networkColor} label={network.label} />}
      </HStack>
    </VStack>
  );
};

export default AssetDetailsBalance;
