import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePersistState } from '@cryptowallet/frontend/hooks';

export enum AuthStep {
  EMAIL,
  OTP,
  TERMS,
  TWOFACTOR,
}

interface AuthState {
  email: string;
  termsAccepted: boolean;
  temporaryToken: string;
  country: string;
}

const defaultState: AuthState = {
  email: '',
  termsAccepted: false,
  temporaryToken: '',
  country: '',
};

export const useAuthFlow = (initialState: AuthState = defaultState) => {
  const [urlParams] = useSearchParams();
  const urlReferralCode = urlParams.get('referralCode');

  const [state, setState] = useState<AuthState>(initialState);
  const setEmail = (email: string): void => setState(oldState => ({ ...oldState, email }));
  const setTermsAccepted = (termsAccepted: boolean): void => setState(oldState => ({ ...oldState, termsAccepted }));
  const setTemporaryToken = (temporaryToken: string): void => setState(oldState => ({ ...oldState, temporaryToken }));
  const setCountry = (country: string) => setState(oldState => ({ ...oldState, country }));
  const reset = (): void => setState(initialState);

  const [referralCode, setReferralCode] = usePersistState('', 'referralCode');

  const { email, termsAccepted, temporaryToken, country } = state;

  const step: AuthStep = useMemo(() => {
    if (email && termsAccepted && temporaryToken) {
      return AuthStep.TWOFACTOR;
    }
    if (email && termsAccepted) {
      return AuthStep.OTP;
    }
    if (email) {
      return AuthStep.TERMS;
    }

    return AuthStep.EMAIL;
  }, [email, termsAccepted, temporaryToken]);

  useEffect(() => {
    if (urlReferralCode) {
      setReferralCode(urlReferralCode);
    }
  }, [urlReferralCode]);

  return {
    email,
    step,
    setEmail,
    reset,
    termsAccepted,
    setTermsAccepted,
    temporaryToken,
    setTemporaryToken,
    setCountry,
    country,
    referralCode,
    setReferralCode,
  };
};
