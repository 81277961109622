import { useQueryClient } from '@tanstack/react-query';
import { Box, Text } from 'native-base';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useGA } from '@cryptowallet/frontend/ga';
import { ButtonPurple, LabeledInput, useAlerts } from '@cryptowallet/frontend/ui';
import {
  AuthorizeUserResponseUsersDtoAuthorizeCaseEnum,
  EventRequestDtoEventNameEnum,
  userApi,
} from '@cryptowallet/web/api-client';
import { useResetPersistStates } from '@cryptowallet/web/hooks/useResetPersistStates';
import { CaptchaResult, showCaptcha } from '@cryptowallet/web/utils/captcha';

import AuthHeader from '../../../components/AuthHeader';

import FormHeading from './FormHeading';

const LoginForm = ({
  onSubmitSuccess,
  ...props
}: WithNativeBase<{
  onSubmitSuccess: (email: string, isNewUser: boolean) => void;
}>): JSX.Element => {
  const queryClient = useQueryClient();
  const { onApiError } = useAlerts();
  const { resetPersistStates } = useResetPersistStates();
  const [isAuthorizeUserPending, setIsAuthorizeUserPending] = useState<boolean>(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  });

  const ga = useGA();

  const onSubmit = async ({ email }) => {
    if (isAuthorizeUserPending) return;
    ga.sendEvent(EventRequestDtoEventNameEnum.AuthorizeEmailInputSuccess, { email, customer_source: 'btc' });
    setIsAuthorizeUserPending(true);
    let captchaRes;
    try {
      (document.activeElement as HTMLElement)?.blur();
      captchaRes = await showCaptcha();

      if (captchaRes === CaptchaResult.Closed) {
        setIsAuthorizeUserPending(false);
        return;
      }
    } catch (e) {
      return;
    }
    ga.sendEvent(EventRequestDtoEventNameEnum.CaptchaSlideSuccess, { email, customer_source: 'btc' });
    try {
      const { data } = await userApi.usersControllerAuthorizeUser({ email, captcha: captchaRes });
      const isRegistration = data.authorizeCase === AuthorizeUserResponseUsersDtoAuthorizeCaseEnum.Registration;

      resetPersistStates();
      onSubmitSuccess(email, isRegistration);
      queryClient.removeQueries();
    } catch (err) {
      onApiError(err);
    }
    setIsAuthorizeUserPending(false);
  };

  return (
    <Box {...props}>
      <AuthHeader>
        <FormHeading text="Let's Start" />
        <Text mt="4px" fontSize="lg">
          To login or register, please enter your email and press Continue.
        </Text>
      </AuthHeader>
      <form>
        <Box p="32px" pt="24px">
          <Controller
            control={control}
            rules={{
              required: 'Please enter your email',
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,5}$/,
                message: 'Incorrect email. Please try again',
              },
            }}
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <LabeledInput
                error={error}
                onChangeText={onChange}
                onSubmit={handleSubmit(onSubmit)}
                {...field}
                label="Email"
                labelTextProps={{
                  color: 'textLabel',
                  fontSize: 'lg',
                }}
                placeholder="example@email.com"
                fontSize="lg"
              />
            )}
            name="email"
          />
          <ButtonPurple
            h="50px"
            mt="11px"
            isDisabled={isAuthorizeUserPending}
            size="lg"
            onPress={handleSubmit(onSubmit)}>
            Continue
          </ButtonPurple>
        </Box>
      </form>
    </Box>
  );
};

export default LoginForm;
