import { Text } from 'native-base';
import React from 'react';

const SecurityBlockSubTitle = ({ children, ...rest }) => (
  <Text fontWeight="500" color="white" fontSize="2xl" {...rest}>
    {children}
  </Text>
);

export default SecurityBlockSubTitle;
