import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetUserResponseUsersDtoUserRiskLevelTypeEnum, RiskCheckType } from '@cryptowallet/frontend/client';
import { RiskCheckStatus, useUserStatus } from '@cryptowallet/frontend/hooks';

import { NotificationAlertButtonType, NotificationAlertType } from '../../../components/Alerts';
import { useUser } from '../../AuthContext';
import { IAlertToShow } from '../index.web';

const riskCheckPaths = {
  [RiskCheckType.ProofOfWealthCheck]: '/account/security/verification-pow',
};

const data = {
  headerMessage: 'Your account was suspended',
  text: 'Please proceed with required checks in the Security Center to unsuspend your account.',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useSuspendedAlert = setChangableAlerts => {
  const user = useUser();
  const userStatus = useUserStatus();
  const navigate = useNavigate();

  useEffect(() => {
    const [checkIdToShow] =
      Object.entries(userStatus.riskChecks).find(
        ([key, value]) =>
          [RiskCheckStatus.PENDING, RiskCheckStatus.RETRY].includes(value.status as never) &&
          window.localStorage?.getItem(`suspended-alert-${key}`) !== 'true',
      ) || [];

    const id = checkIdToShow ? `suspended-alert-${checkIdToShow}` : null;

    const show =
      user &&
      userStatus.riskChecks &&
      userStatus.isLoggedIn &&
      userStatus.kycPoaHighRiskVerified &&
      user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Suspended &&
      !!id;

    if (show) {
      const { headerMessage, text } = data;
      const path = riskCheckPaths[checkIdToShow as never];

      setChangableAlerts((prev: IAlertToShow[]) => [
        ...prev.filter(item => item.id !== id),
        {
          id,
          name: 'NotificationAlert',
          data: {
            type: NotificationAlertType.WARNING,
            headerMessage,
            text,
            buttons: [
              {
                title: 'Go to security center',
                onPress: () => navigate('/account/security'),
                type: NotificationAlertButtonType.ButtonSecondary,
              },
              ...(path
                ? [
                    {
                      title: 'Start check',
                      onPress: () => navigate(path),
                      type: NotificationAlertButtonType.ButtonPurple,
                      buttonProps: {
                        _text: {
                          fontSize: 'sm',
                        },
                        ml: '16px',
                      },
                    },
                  ]
                : []),
            ],
          },
          onClose: () => window.localStorage?.setItem(id, 'true'),
        },
      ]);
    } else {
      setChangableAlerts((prev: IAlertToShow[]) =>
        prev.filter(item => item.id.toString().indexOf('suspended-alert') === -1),
      );
    }
  }, [user, userStatus.isLoggedIn, userStatus.kycPoaHighRiskVerified, navigate]);
};

export default useSuspendedAlert;
