import React from 'react';

const NavigationAccountsIcon = ({ color }) => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.732233 1.28643C0 2.01867 0 3.19718 0 5.5542V10.5542C0 12.9112 0 14.0897 0.732233 14.822C1.46447 15.5542 2.64298 15.5542 5 15.5542H15C17.357 15.5542 18.5355 15.5542 19.2678 14.822C20 14.0897 20 12.9112 20 10.5542V5.5542C20 3.19718 20 2.01867 19.2678 1.28643C18.5355 0.554199 17.357 0.554199 15 0.554199H5C2.64298 0.554199 1.46447 0.554199 0.732233 1.28643ZM5 10.5542C6.38071 10.5542 7.5 9.43491 7.5 8.0542C7.5 6.67349 6.38071 5.5542 5 5.5542C3.61929 5.5542 2.5 6.67349 2.5 8.0542C2.5 9.43491 3.61929 10.5542 5 10.5542ZM10 6.1792C10 5.83402 10.2798 5.5542 10.625 5.5542H16.875C17.2202 5.5542 17.5 5.83402 17.5 6.1792C17.5 6.52438 17.2202 6.8042 16.875 6.8042H10.625C10.2798 6.8042 10 6.52438 10 6.1792ZM10.625 9.3042C10.2798 9.3042 10 9.58402 10 9.9292C10 10.2744 10.2798 10.5542 10.625 10.5542H16.875C17.2202 10.5542 17.5 10.2744 17.5 9.9292C17.5 9.58402 17.2202 9.3042 16.875 9.3042H10.625Z"
      fill={color}
    />
  </svg>
);

export default NavigationAccountsIcon;
