import { Box, HStack } from 'native-base';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { Button, headerHeight, Icon, useUser } from '@cryptowallet/frontend/ui';

import { GetUserResponseUsersDtoUserRiskLevelTypeEnum } from '../api-client';

import { excludeNavigationPathnames } from './layout/AppLayout';
import { BackTextButton } from './Buttons';
import Logo from './Logo';
import TopNavigation from './TopNavigation';

const Header = ({ mobileMenuOpen, toggleMobileMenu, setShowLogoutConfirmModal }): JSX.Element => {
  const location = useLocation();
  const { isMobile } = useScreenSize();
  const user = useUser();
  const userStatus = useUserStatus();

  const isBlocked = user && user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Blocked;

  const showMobileMenuBtn =
    !isBlocked &&
    isMobile &&
    userStatus.isLoggedIn &&
    !excludeNavigationPathnames.includes(location.pathname.split('/')[1]);

  const showTopNavigation =
    !isBlocked &&
    !isMobile &&
    userStatus.isLoggedIn &&
    !excludeNavigationPathnames.includes(location.pathname.split('/')[1]);

  const menuBtnProps = mobileMenuOpen
    ? {
        name: 'mobile-close',
        h: '21px',
        w: '21px',
      }
    : {
        name: 'hamburger',
        h: '10px',
        w: '36px',
      };

  return (
    <Box
      position="fixed"
      left="0"
      top="0"
      h={headerHeight}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      px={{ base: '24px', xl: '32px' }}
      background="background.400"
      zIndex="2">
      <HStack alignItems="center">
        <Logo mb="0" ml="0" />
      </HStack>
      {location.pathname === '/' && (
        <BackTextButton fontSize="lg" onPress={() => window.open('https://cryptowallet.com/')}>
          Back to cryptowallet.com
        </BackTextButton>
      )}
      {showMobileMenuBtn && (
        <Button p="0" outlineWidth="0" borderWidth="0" variant="unstyled" onPress={toggleMobileMenu} h="21px" w="29px">
          <Icon {...menuBtnProps} />
        </Button>
      )}
      {showTopNavigation && <TopNavigation setShowLogoutConfirmModal={setShowLogoutConfirmModal} />}
    </Box>
  );
};

export default Header;
