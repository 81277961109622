import { useQuery } from '@tanstack/react-query';
import { Box, Divider, HStack, IBoxProps, Text, VStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { Icon, OverlaySpinner } from '@cryptowallet/frontend/ui';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import { ExchangeType, PaymentMethod } from '@cryptowallet/web/api-client';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';

import BlockedLimitsPlaceholder from './BlockedLimitsPlaceholder';

const Row = ({ title, value }) => (
  <HStack>
    <Text fontSize="md" w="50%" color="white">
      {title}
    </Text>
    <Text fontSize="md" w="50%" color="white">
      {value}
    </Text>
  </HStack>
);

const LimitProgress = ({
  title,
  used,
  remains,
  limit,
  containerProps = {},
}: {
  title: string;
  used: number;
  remains: number;
  limit: number;
  containerProps?: IBoxProps;
}) => (
  <Box w={{ base: '100%', md: '50%' }} {...containerProps}>
    <Text mb="6px" color="white" fontSize="20px">
      {title}
    </Text>
    <HStack justifyContent="space-between" alignItems="center">
      <Text fontSize="md" color="textRegular">
        Used {commonConstants.FIAT_TICKERS_TO_SYMBOLS.eur}
        {used.toFixed(2)}
      </Text>
      <Text fontSize="md" color="white">
        Left {commonConstants.FIAT_TICKERS_TO_SYMBOLS.eur}
        {remains.toFixed(2)}
      </Text>
    </HStack>
    <Box mt="6px" overflow="hidden" bg="gray.200" h="8px" w="100%" borderRadius="10px">
      <Box h="100%" bg="primary.400" w={used ? `${(used / limit) * 100}%` : '0%'} borderRadius="10px" />
    </Box>
  </Box>
);

const Limits = () => {
  const navigate = useNavigate();
  const { isDesktop } = useScreenSize();
  const { VERIFIED_KYC, VERIFIED_POA_FOR_HIGH_RISK, kycPoaHighRiskVerified } = useUserStatus();

  const { isFetching, data: response } = useQuery(['limits'], () => exchangerApi.exchangerControllerGetLimits(), {
    ...cacheRequestOption,
    enabled: kycPoaHighRiskVerified,
  });

  const showBlockedScreen = !kycPoaHighRiskVerified;

  if (isFetching) {
    return <OverlaySpinner />;
  }

  const { data } = response || {};

  const totalLimit = data
    ? data[ExchangeType.Buy][PaymentMethod.Card].limits.monthly +
      data[ExchangeType.Buy][PaymentMethod.Sepa].limits.monthly +
      data[ExchangeType.Sell][PaymentMethod.Sepa].limits.monthly
    : 0;

  return (
    <VStack flexGrow="1">
      <TabHeader onGoBack={isDesktop ? null : () => navigate('/account')}>Verification Level</TabHeader>
      <TabContentLayout>
        {showBlockedScreen && <BlockedLimitsPlaceholder />}
        {!showBlockedScreen && (
          <>
            <Box p="32px" w="348px" maxW="100%" borderRadius="10px" borderColor="gray.100" bg="gray.100">
              <HStack alignItems="center">
                <Text color="white" fontSize="2xl" mr="16px">
                  Level 1
                </Text>
                <Icon name="check-icon" h="16px" w="16px" />
              </HStack>
              <Text fontSize="lg" color="white" my="16px">
                This is a basic level. You got it when pass registration
              </Text>
              <Row title="KYC" value={VERIFIED_KYC ? 'Yes' : 'No'} />
              <Row title="Address" value={VERIFIED_POA_FOR_HIGH_RISK ? 'Yes' : 'No'} />
              <Row
                title="Limit"
                value={`${commonConstants.FIAT_TICKERS_TO_SYMBOLS.eur} ${Number(totalLimit).toFixed(2)}`}
              />
            </Box>
            <Text fontWeight="500" color="white" fontSize="2xl" mt="40px" mb="16px">
              SEPA limits
            </Text>
            <HStack flexWrap="wrap">
              <LimitProgress
                title="Your monthly buy limit"
                used={Number(data[ExchangeType.Buy][PaymentMethod.Sepa].monthlyAmount)}
                remains={Number(data[ExchangeType.Buy][PaymentMethod.Sepa].monthlyRemains)}
                limit={Number(data[ExchangeType.Buy][PaymentMethod.Sepa].limits.monthly)}
                containerProps={{ pr: { base: '0', md: '40px' } }}
              />
              <LimitProgress
                title="Your monthly sell limit"
                used={Number(data[ExchangeType.Sell][PaymentMethod.Sepa].monthlyAmount)}
                remains={Number(data[ExchangeType.Sell][PaymentMethod.Sepa].monthlyRemains)}
                limit={Number(data[ExchangeType.Sell][PaymentMethod.Sepa].limits.monthly)}
                containerProps={{ pl: { base: '0', md: '40px' }, mt: { base: '40px', md: '0' } }}
              />
            </HStack>
            <Divider orientation="horizontal" bg="gray.100" my="40px" />
            <Text fontWeight="500" color="white" fontSize="2xl" mb="16px">
              Card limits
            </Text>
            <HStack mb="40px">
              <LimitProgress
                title="Your monthly buy limit"
                used={Number(data[ExchangeType.Buy][PaymentMethod.Card].monthlyAmount)}
                remains={Number(data[ExchangeType.Buy][PaymentMethod.Card].monthlyRemains)}
                limit={Number(data[ExchangeType.Buy][PaymentMethod.Card].limits.monthly)}
                containerProps={{ pr: { base: '0', md: '40px' } }}
              />
            </HStack>
          </>
        )}
      </TabContentLayout>
    </VStack>
  );
};

export default Limits;
