import { shallow } from 'zustand/shallow';
import React from 'react';

import { GetSwapTransactionResponseWalletsDtoStatusEnum } from '@cryptowallet/frontend/client';
import { DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { OverlaySpinner } from '@cryptowallet/frontend/ui';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import AssetSwapAmount from './AssetSwapAmount';
import AssetSwapProcessing from './AssetSwapProcessing';
import useSwap from './useSwap';

const steps: IStep[] = [
  {
    label: 'Amount',
    Component: AssetSwapAmount,
  },
  {
    label: 'Exchange',
    Component: AssetSwapProcessing,
  },
];

const AssetSwap = () => {
  const { isLoading, swap } = useSwap();
  const [setDetailsPanelView, setSwapData] = useAccountsStore(
    state => [state.setDetailsPanelView, state.setSwapData],
    shallow,
  );

  const onClose = () => setDetailsPanelView(DetailsPanelView.Overview);

  if (isLoading) {
    <OverlaySpinner />;
  }

  const completed = (
    [
      GetSwapTransactionResponseWalletsDtoStatusEnum.Cancelled,
      GetSwapTransactionResponseWalletsDtoStatusEnum.Done,
    ] as GetSwapTransactionResponseWalletsDtoStatusEnum[]
  ).includes(swap?.status);

  return (
    <Stepper
      header="Swap"
      steps={steps}
      onClose={onClose}
      stepperState={[swap ? 1 : 0, () => {}]}
      hideProgressBar={completed}
      canGoToPrevStep={!completed}
      onGoToPrevStep={() => setSwapData({ swapId: '' })}
    />
  );
};

export default AssetSwap;
