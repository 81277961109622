import { Box, IBoxProps } from 'native-base';

const RoundedBox = ({ children, ...rest }: IBoxProps) => (
  <Box
    borderColor="gray.100"
    borderWidth="1px"
    background="background.400"
    borderRadius="10px"
    overflow="hidden"
    {...rest}>
    {children}
  </Box>
);

export default RoundedBox;
