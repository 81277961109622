import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const CrossErrorIcon = ({ fill = '#161026' }) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.28827 4.28827C3.90391 4.67263 3.90391 5.29581 4.28827 5.68017L6.60811 8.00001L4.28829 10.3198C3.90393 10.7042 3.90393 11.3274 4.28829 11.7117C4.67265 12.0961 5.29582 12.0961 5.68019 11.7117L8 9.39191L10.3198 11.7117C10.7042 12.0961 11.3273 12.0961 11.7117 11.7117C12.0961 11.3274 12.0961 10.7042 11.7117 10.3198L9.39189 8.00001L11.7117 5.68017C12.0961 5.29581 12.0961 4.67263 11.7117 4.28827C11.3274 3.90391 10.7042 3.90391 10.3198 4.28827L8 6.60811L5.68017 4.28827C5.2958 3.90391 4.67263 3.90391 4.28827 4.28827Z"
      fill={fill}
    />
  </Svg>
);
