import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Divider, VStack } from 'native-base';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useSelectedWalletAccount, useWalletConfig } from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, FormGuards, IFormGuardType, useAlerts } from '@cryptowallet/frontend/ui';
import { processFormGuardsApiErrors } from '@cryptowallet/frontend/utils';
import { walletsApi } from '@cryptowallet/web/api-client';

import WithdrawPaymentInfo from './WithdrawPaymentInfo';
import WithdrawTransactionDetails from './WithdrawTransactionDetails';

interface IFormProps {
  totpToken: string;
  emailOtpToken: string;
}

const AssetWithdrawConfirm = ({ onStepCompleted }) => {
  const queryClient = useQueryClient();
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const { isLoading: walletConfigLoading } = useWalletConfig();
  const withdrawData = useAccountsStore(state => state.withdrawData);
  const { amount, destinationAddress: address, destinationTag: tag, walletFeeLevel } = withdrawData;
  const { onApiError } = useAlerts();

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      totpToken: '',
      emailOtpToken: '',
    },
  });

  const { isLoading, mutateAsync: createWalletTransactionMutateAsync } = useMutation(
    ({ totpToken, emailOtpToken }: IFormProps) =>
      walletsApi.walletsControllerCreateWalletTransaction({
        totpToken,
        emailOtpToken,
        walletAccountId: selectedWalletAccount.walletAccount.id,
        amount,
        address,
        tag,
        walletFeeLevel,
      }),
  );

  const onSubmit = async formData => {
    try {
      await createWalletTransactionMutateAsync(formData);

      queryClient.refetchQueries(['walletHistory']);
      onStepCompleted();
      return;
    } catch (error) {
      processFormGuardsApiErrors(error, setError, () => onApiError(error));
    }
  };

  const isReady = !walletConfigLoading;

  if (!isReady) return null;

  return (
    <>
      <VStack pt="26px" px="19px">
        <FormGuards
          activeGuards={[{ type: IFormGuardType.OTP }, { type: IFormGuardType.TOTP }]}
          control={control}
          onSubmitInput={handleSubmit(onSubmit)}
        />
        <WithdrawPaymentInfo />
        <Divider mt="32px" orientation="horizontal" bg="gray.100" />
        <WithdrawTransactionDetails />
      </VStack>
      <BottomPurpleButton isDisabled={isLoading} onPress={handleSubmit(onSubmit)} size="lg">
        Confirm
      </BottomPurpleButton>
    </>
  );
};

export default AssetWithdrawConfirm;
