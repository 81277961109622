import { IButtonProps, Text } from 'native-base';
import React from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

import Icon, { IconProps } from '../../components/Icon';
import { usePartnerSettingsData } from '../../context/PartnersSettingsContext';
import { CrossErrorIcon } from '../../icons/CrossErrorIcon';

import { Button } from './Button';

const isWeb = getPlatform() === 'web';

export enum CloseButtonType {
  Default,
  Filled,
}

interface ICloseButtonProps extends IButtonProps {
  iconProps?: IconProps;
  type?: CloseButtonType;
}

const webIconName = {
  [CloseButtonType.Default]: 'cross',
  [CloseButtonType.Filled]: 'cross--filled',
};

const CloseButton = ({ iconProps = {}, type = CloseButtonType.Default, ...props }: ICloseButtonProps) => {
  const { data } = usePartnerSettingsData();

  return (
    <Button
      p="4px"
      outlineWidth="0"
      borderWidth="1px"
      borderColor="transparent"
      borderRadius={isWeb ? '50%' : '50'}
      _focus={{
        borderColor: 'secondary.400',
      }}
      variant="unstyled"
      {...props}
      {...(data.styles.CloseButton || {})}>
      {!isWeb && ' '}
      {isWeb ? <Icon name={webIconName[type]} h="8px" w="8px" {...iconProps} /> : <CrossErrorIcon fill="#8E8A99" />}
    </Button>
  );
};

export default CloseButton;
