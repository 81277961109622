import React from 'react';

const PlusIcon = ({ color }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7.99994L8 7.99994M8 7.99994L15 7.99994M8 7.99994L8 0.999939M8 7.99994L8 14.9999"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default PlusIcon;
