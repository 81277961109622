import { useQueryClient } from '@tanstack/react-query';
import { Box, Flex, Text } from 'native-base';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetUserResponseUsersDtoUserRiskLevelTypeEnum } from '@cryptowallet/frontend/client';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { ButtonPurple, NotificationAlertType, useAlerts, useUser } from '@cryptowallet/frontend/ui';
import { maskEmail } from '@cryptowallet/frontend/utils';
import ErrorBorderedLink from '@cryptowallet/web/components/ErrorBorderedLink';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import ConfirmNewEmail from './components/ConfirmNewEmail';
import EnterNewEmail from './components/EnterNewEmail';
import VerifyCurrentEmail from './components/VerifyCurrentEmail';

const steps: IStep[] = [
  {
    label: 'Confirm access',
    Component: VerifyCurrentEmail,
  },
  {
    label: 'New email',
    Component: EnterNewEmail,
  },
  {
    label: 'Confirm new email',
    Component: ConfirmNewEmail,
  },
];

const ChangeEmail = () => {
  const queryClient = useQueryClient();
  const { createAlert, destroyAlert } = useAlerts();
  const [successNotificationId, setSuccessNotificationId] = useState('');
  const [flowIsActive, setFlowIsActive] = useState(false);
  const user = useUser();
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();

  const { totpEnabled } = user.user;
  const isSuspended = user && user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Suspended;

  const canChangeEmail = totpEnabled && !isSuspended;

  return (
    <Flex flexGrow="1">
      {flowIsActive && (
        <Stepper
          initialStep={0}
          steps={steps}
          onFlowComplete={() => {
            queryClient.invalidateQueries(['user']);
            setFlowIsActive(false);
            const id = createAlert('NotificationAlert', {
              type: NotificationAlertType.SUCCESS,
              headerMessage: 'Your email was successfully changed! Please use your new email for next login.',
            });
            setSuccessNotificationId(id);
          }}
          header="Change Email"
        />
      )}
      {!flowIsActive && (
        <>
          <TabHeader onGoBack={isDesktop ? null : () => navigate('/account')}>Change Email </TabHeader>
          <TabContentLayout>
            <Box>
              <Text fontSize="lg">Email is your only way to access Cryptowallet system, please keep it secured!</Text>
            </Box>
            <Flex
              mt="22px"
              p="19px"
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
              borderWidth="1px"
              borderRadius="10px"
              borderColor="gray.100">
              <Text color="white" fontSize="lg" fontWeight="600">
                {maskEmail(user.user.email)}
              </Text>
              <ButtonPurple
                size="xs"
                _text={{ fontSize: 'md' }}
                isDisabled={!canChangeEmail}
                onPress={() => {
                  destroyAlert(successNotificationId);
                  setFlowIsActive(true);
                }}>
                Change
              </ButtonPurple>
            </Flex>
            {!totpEnabled && (
              <ErrorBorderedLink
                message="To change your email, please set up 2FA first."
                linkProps={{ text: 'Enable 2FA', href: '/account/2fa' }}
              />
            )}
            {isSuspended && (
              <ErrorBorderedLink
                message="Your account was suspended. To change your email, please proceed with required checks in the Security Center to unsuspend your account."
                linkProps={{ text: 'Start check', href: '/account/security/verification-pow' }}
              />
            )}
          </TabContentLayout>
        </>
      )}
    </Flex>
  );
};

export default ChangeEmail;
