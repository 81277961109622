/* eslint-disable no-underscore-dangle */
import { IButtonProps } from 'native-base';
import React from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

import { Button } from './Button';

const isWeb = getPlatform() === 'web';

interface ITabButtonProps extends IButtonProps {
  active: boolean;
}

export const TabButton = ({ children, _text = {}, active, ...props }: ITabButtonProps) => (
  <Button
    variant="unstyled"
    outlineWidth="0"
    borderRadius="0"
    borderBottomWidth="2px"
    borderBottomColor={active ? 'primary.400' : 'transparent'}
    _text={{ ..._text, fontSize: isWeb ? '20px' : 'xl', color: 'white' }}
    _focus={{ _text: { color: 'secondary.400' } }}
    p="0"
    pb="2px"
    w={isWeb ? 'fit-content' : undefined}
    {...props}>
    {children}
  </Button>
);
