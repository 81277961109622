import {
  AnalyticsApiFactory,
  ExchangerApiFactory,
  MarketingApiFactory,
  PartnersApiFactory,
  ReferralProgramApiFactory,
  SecurityApiFactory,
  SettingsApiFactory,
  UsersApiFactory,
  UserSettingsApiFactory,
  WalletsApiFactory,
} from '@cryptowallet/frontend/client';

import { axiosInstance } from '../network/client';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions
export * from './token';

export const userApi = UsersApiFactory(undefined, '', axiosInstance);
export const exchangerApi = ExchangerApiFactory(undefined, '', axiosInstance);
export const securityApi = SecurityApiFactory(undefined, '', axiosInstance);
export const marketingApi = MarketingApiFactory(undefined, '', axiosInstance);
export const walletsApi = WalletsApiFactory(undefined, '', axiosInstance);
export const userSettingsApi = UserSettingsApiFactory(undefined, '', axiosInstance);
export const analyticsApi = AnalyticsApiFactory(undefined, '', axiosInstance);
export const referralProgramApi = ReferralProgramApiFactory(undefined, '', axiosInstance);
export const partnersApi = PartnersApiFactory(undefined, '', axiosInstance);
export const settingsApi = SettingsApiFactory(undefined, '', axiosInstance);
