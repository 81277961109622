import { Box, HStack, Text } from 'native-base';
import React, { useMemo } from 'react';

import { ButtonPurple } from '@cryptowallet/frontend/ui';

import { ColumnType } from '../Row';

export enum SingleSelectOptionType {
  Circle,
  Button,
}

interface ISingleSelectOption {
  id: string;
  value: string;
  title: string;
  color: string;
  type: SingleSelectOptionType;
}

export interface ISingleSelectColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: ColumnType.SINGLE_SELECT;
  options: ISingleSelectOption[];
}

interface ISingleSelectProps {
  data: any;
  columnDef: ISingleSelectColumnDef;
  onCellClick?: (data, columnDef, rowId) => void;
  rowId: string;
}

const CircleOption = ({ color, title }) => (
  <>
    <Box h="12px" w="12px" bg={color} borderRadius="50%" mr="5px" />
    <Text fontSize="lg" fontWeight="500" color="textRegular">
      {title}
    </Text>
  </>
);

const ButtonOption = ({ color, title, onPress }) => (
  <ButtonPurple
    onPress={onPress}
    _text={{ fontSize: 'sm' }}
    userSelect="none"
    bg={color}
    size="md"
    py="4px"
    px="12px"
    minW="64px">
    {title}
  </ButtonPurple>
);

const OptionContent = {
  [SingleSelectOptionType.Circle]: CircleOption,
  [SingleSelectOptionType.Button]: ButtonOption,
};

export const SingleSelect = ({ data, columnDef, onCellClick, rowId }: ISingleSelectProps) => {
  const option = useMemo<ISingleSelectOption>(
    () => columnDef.options.find(item => item.value && item.value === data),
    [data, columnDef],
  );

  if (!option) {
    return null;
  }

  const Component = OptionContent[option.type];

  return (
    <HStack alignItems="center">
      <Component
        color={option.color}
        title={option.title}
        onPress={() => onCellClick && onCellClick(columnDef, data, rowId)}
      />
    </HStack>
  );
};
