/* eslint-disable @typescript-eslint/no-shadow */
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { referralProgramApi } from '@cryptowallet/frontend/api';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';

const useReferralContracts = (assignedReward = undefined, outputCount = 10) => {
  const {
    data: response,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['ref-contracts-paginated', assignedReward],
    ({ pageParam }) =>
      referralProgramApi.referralProgramControllerGetUserReferralContracts(
        outputCount,
        pageParam?.offset || 0,
        assignedReward,
      ),
    {
      getNextPageParam: lastPage => {
        if (!lastPage) {
          return { offset: 0 };
        }
        const { outputCount, outputOffset, outputTotalCount } = lastPage.data as never;
        if (outputCount + outputOffset >= outputTotalCount) {
          return undefined;
        }

        return { offset: outputCount + outputOffset };
      },
      ...cacheRequestOption,
    },
  );

  const referralContracts = useMemo(() => {
    if (response) {
      return response.pages.reduce((prev, page) => [...prev, page?.data.referralContracts], []).flat();
    }

    return [];
  }, [response]);

  return {
    referralContracts,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};

export default useReferralContracts;
