/* eslint-disable no-param-reassign */
import { Box } from 'native-base';
import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { axiosInstance } from '@cryptowallet/frontend/api';
import { useScreenSize } from '@cryptowallet/frontend/hooks';

import { layoutBodyWrapperStyles, ToasterType, useAlerts } from '../../../index';

export const headerHeight = {
  base: '68px',
  md: '72px',
  lg: '86px',
};

const commonErrorInterceptorExcludedRoutes = ['/users/gaSessionId'];

export const AlertsContainer = ({ layoutLeftPadding, children }) => {
  const ref = useRef<Element>();
  const { relativeAlertsLength, setAlertsRect, showToaster } = useAlerts();
  const { isBiggerThanTablet, isMobile, isTablet } = useScreenSize();

  const commonErrorInterceptor = error => {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 429: {
          const requestUrl = error.response.config.url;

          if (!commonErrorInterceptorExcludedRoutes.includes(requestUrl)) {
            showToaster(ToasterType.ERROR, 'Too many attempts. Please try again later');
          }
          break;
        }
      }
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  };

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(null, commonErrorInterceptor);

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, []);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      const clientRect = ref.current.getBoundingClientRect();
      setAlertsRect(clientRect);
    }
  }, [relativeAlertsLength, ref, setAlertsRect, isBiggerThanTablet, isMobile, isTablet]);

  return (
    <Box
      ref={ref}
      position="fixed"
      top={headerHeight}
      left="0"
      w="100%"
      pt={{ base: '10px', md: '12px', lg: '28px' }}
      pb={{ base: '12px', lg: '28px' }}
      pl={layoutLeftPadding}
      bg="black"
      zIndex="1">
      <Box px={layoutBodyWrapperStyles.px}>{children}</Box>
    </Box>
  );
};
