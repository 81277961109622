import { Box, Image, Text, VStack } from 'native-base';
import QRCodeLib from 'qrcode';
import { useEffect, useState } from 'react';

import { useScreenSize, useTotpSecret } from '@cryptowallet/frontend/hooks';
import { BottomPurpleButton, Spinner } from '@cryptowallet/frontend/ui';
import CopyBox, { CopyBoxVariant } from '@cryptowallet/web/components/CopyBox';

import { StepComponentProps } from './IStepProps';
import StepLayout from './Layout';

const QRScan = ({ onStepCompleted }: StepComponentProps) => {
  const { isMobile } = useScreenSize();
  const [image, setImage] = useState('');
  const { isLoading, secret, otpauth } = useTotpSecret();

  useEffect(() => {
    if (!otpauth) {
      return;
    }

    QRCodeLib.toDataURL(otpauth).then(setImage);
  }, [otpauth]);

  return (
    <StepLayout
      body={
        isLoading ? (
          <Spinner />
        ) : (
          <VStack>
            <Box position="relative" pl={isMobile ? '0' : '226px'} minH="200px" alignItems="center">
              {image && (
                <Image
                  position={isMobile ? 'relative' : 'absolute'}
                  top="0"
                  left="0"
                  source={{ uri: image }}
                  alt="qrcode"
                  mb={isMobile ? '24px' : '0'}
                  w="200px"
                  h="200px"
                />
              )}
              <Box width="full">
                <Text fontSize="lg">Scan this QR code in the Google Authenticator app.</Text>
                <Text> </Text>
                <Text fontSize="lg">
                  If you are unable to scan the QR code, please enter this code manually into the app.
                </Text>
              </Box>
            </Box>

            <Box mt="32px">
              <CopyBox
                title={
                  <Text color="textLabel" fontSize="lg">
                    Manual code
                  </Text>
                }
                value={secret}
                variant={CopyBoxVariant.GRAYBOX}
                valueStyles={{ fontSize: 'lg' }}
              />
            </Box>
          </VStack>
        )
      }
      stepButton={
        !isLoading && (
          <BottomPurpleButton size="lg" onPress={onStepCompleted}>
            Next
          </BottomPurpleButton>
        )
      }
    />
  );
};

export default QRScan;
