import { ClientError } from './client-error.interface';
import { CreateWalletTransactionBadRequestDtoErrorEnum } from '../client/src';

export const WalletErrors: ClientError = {
  [CreateWalletTransactionBadRequestDtoErrorEnum.InsufficientBalance]: {
    title: 'Insufficient balance in network fee wallet account',
  },
  [CreateWalletTransactionBadRequestDtoErrorEnum.WalletAddressIncorrect]: {
    title: 'Wallet address is incorrect',
  },
};
