import { Center, Spinner as NativeBaseSpinner } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '../context/PartnersSettingsContext';

const Spinner = ({ ...props }) => {
  const partnerStyles = usePartnerStyles('Spinner');

  return (
    <Center {...props}>
      <NativeBaseSpinner color="secondary.400" {...partnerStyles} />
    </Center>
  );
};

export default Spinner;
