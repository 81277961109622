import React from 'react';

import LinkBase, { ILinkBaseProps } from './LinkBase';

export type ILinkErrorProps = Omit<ILinkBaseProps, 'textColor'>;

export const LinkError = (props: ILinkErrorProps) => (
  <LinkBase
    {...props}
    textColor={{
      default: 'error.400',
      hoverFocus: 'secondary.400',
    }}
  />
);
