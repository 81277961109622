export const setCookie = (name, value) => {
  window.document.cookie = `${name}=${encodeURIComponent(value)};path=/`;
};

export const getCookie = name => {
  const cookieValue = window.document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookieValue ? decodeURIComponent(cookieValue.pop()) : '';
};

// expired date to remove cookie
export const deleteCookie = name => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};
