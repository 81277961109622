import { Text, useMediaQuery, VStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { ButtonPurple } from '@cryptowallet/frontend/ui';

const BlockedReferralPlaceholder = () => {
  const navigate = useNavigate();
  const { kycPoaHighRiskVerified } = useUserStatus();
  const { isBiggerThanTablet, isMobile } = useScreenSize();
  const [isLargeLaptop] = useMediaQuery({ minWidth: 1500 });

  return (
    <VStack position="relatives" flexGrow="1" justifyContent="center" alignItems="center">
      <>
        <img
          src="/assets/images/ref-for-rewards-banner--dark.png"
          width="100%"
          height={isBiggerThanTablet ? undefined : 320}
          style={{
            objectFit: isBiggerThanTablet ? 'unset' : 'cover',
            objectPosition: isMobile ? 'left' : 'unset',
            borderRadius: '10px',
            minHeight: 200,
          }}
          alt="Pass kyc to start referral program"
        />
        <VStack
          position="absolute"
          top={isLargeLaptop ? '100px' : '32px'}
          left={isLargeLaptop ? '100px' : '32px'}
          w={isLargeLaptop ? '368px' : { base: '70%', lg: '368px' }}>
          <Text lineHeight="36px" userSelect="none" fontSize={{ base: '24px', xl: '30px' }} color="white">
            You need to pass KYC to access the Cryptowallet referral program.
          </Text>
          {kycPoaHighRiskVerified && (
            <ButtonPurple mt="20px" h="50px" w="160px" onPress={() => navigate('/dashboard/widgets')}>
              Make a transaction
            </ButtonPurple>
          )}
          {!kycPoaHighRiskVerified && (
            <ButtonPurple mt="20px" h="50px" w="130px" onPress={() => navigate('/account/security/verification')}>
              Start KYC
            </ButtonPurple>
          )}
        </VStack>
      </>
    </VStack>
  );
};

export default BlockedReferralPlaceholder;
