import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/frontend/client';

export interface IPaymentMethodButton {
  description: string;
  type: ExchangerCreateExchangeRequestDtoPspTypeEnum;
  icon: {
    active: string;
    default: string;
  };
}

export const paymentMethodsInfo: Record<ExchangerCreateExchangeRequestDtoPspTypeEnum, IPaymentMethodButton> = {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: {
    description: 'Within 15 min',
    type: ExchangerCreateExchangeRequestDtoPspTypeEnum.Card,
    icon: {
      active: 'paymentmethods/card--active',
      default: 'paymentmethods/card--default',
    },
  },
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: {
    description: 'Within 24h',
    type: ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa,
    icon: {
      active: 'paymentmethods/sepa--active',
      default: 'paymentmethods/sepa--default',
    },
  },
};
