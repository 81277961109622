import { Box, Text, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { BuyExchangeResult, useBuyExchange } from '@cryptowallet/frontend/hooks';
import { useBuyFlowStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, Icon } from '@cryptowallet/frontend/ui';

import DiscordPromo from '../DiscordPromo';

import BuyTransactionDetails from './BuyTransactionDetails';
import PaymentInfo from './PaymentInfo';

const PaymentCompleted = () => {
  const { result, isLoading } = useBuyExchange();
  const [reset, setStepIndex] = useBuyFlowStore(state => [state.reset, state.setStepIndex], shallow);

  const startNewExchange = () => {
    reset();
    setStepIndex(0);
  };

  return (
    <VStack>
      <VStack mt="32px" alignItems="center" pb="24px" borderBottomWidth="1px" borderBottomColor="gray.100">
        <Box w="80px" h="80px">
          {!isLoading && <Icon name={result} h="80px" w="80px" />}
        </Box>
        <Text color="white" mt="24px" fontSize="2xl">
          {result === BuyExchangeResult.SUCCESS ? 'Done' : 'Transaction failed'}
        </Text>
      </VStack>
      <VStack px="24px" mt="30px">
        <PaymentInfo fullData={true} mb="0" />
        <BuyTransactionDetails fullData={true} />
      </VStack>
      <DiscordPromo />
      <BottomPurpleButton h="80px" size="lg" onPress={startNewExchange}>
        Start new exchange
      </BottomPurpleButton>
    </VStack>
  );
};

export default PaymentCompleted;
