import { Box, HStack, Text } from 'native-base';
import React from 'react';

import { useUserSettings } from '@cryptowallet/frontend/ui';
import { maskNumber, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import CopyBox from '@cryptowallet/web/components/CopyBox';
import { TableType } from '@cryptowallet/web/components/Table';

const WithdrawDetailsContent = ({ tableType, data, fiatCurrency }) => {
  const { hideBalance } = useUserSettings();

  return (
    <HStack flexWrap="wrap">
      <Box
        w={tableType === TableType.FULL ? '35%' : '100%'}
        mb="20px"
        mr={tableType === TableType.FULL ? '100px' : '0'}>
        <Text color="textLabel" fontSize="lg">
          Amount in {fiatCurrency.ticker}
        </Text>
        <Text color="textRegular" fontSize="lg">
          {hideBalance ? maskNumber(2) : trimCryptoAmountLength(data.fiatAmount, 2)} {fiatCurrency.ticker}
        </Text>
      </Box>
      <Box w={tableType === TableType.FULL ? '45%' : '100%'} mb="20px">
        {data.walletTransaction.fromAddress && (
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                From
              </Text>
            }
            value={data.walletTransaction.fromAddress}
            valueStyles={{ fontSize: 'lg' }}
            ellipsis="middle"
          />
        )}
        {!data.walletTransaction.fromAddress && (
          <>
            <Text color="textLabel" fontSize="lg">
              From
            </Text>
            <Text color="textRegular" fontSize="lg">
              -
            </Text>
          </>
        )}
      </Box>
      <Box
        w={tableType === TableType.FULL ? '35%' : '100%'}
        mb="20px"
        mr={tableType === TableType.FULL ? '100px' : '0'}>
        <Text color="textLabel" fontSize="lg">
          Network fee
        </Text>
        <Text color="textRegular" fontSize="lg">
          {(hideBalance ? maskNumber(8) : trimCryptoAmountLength(data.walletTransaction.networkFee, 9)) || '-'}{' '}
          {data.feeAsset.currency.ticker}
        </Text>
      </Box>
      <Box w={tableType === TableType.FULL ? '45%' : '100%'} mb="20px">
        <CopyBox
          title={
            <Text color="textLabel" fontSize="lg">
              To
            </Text>
          }
          value={data.walletTransaction.toAddress}
          valueStyles={{ fontSize: 'lg' }}
          ellipsis="middle"
        />
      </Box>
      <Box
        w={tableType === TableType.FULL ? '35%' : '100%'}
        mb="20px"
        mr={tableType === TableType.FULL ? '100px' : '0'}>
        <Text color="textLabel" fontSize="lg">
          Confirmation
        </Text>
        <Text color="textRegular" fontSize="lg">
          {data.walletTransaction.confirmationsCount || '-'}
        </Text>
      </Box>
      <Box w={tableType === TableType.FULL ? '45%' : '100%'} mb="20px">
        {data.walletTransaction.txHash && (
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                Hash
              </Text>
            }
            value={data.walletTransaction.txHash}
            valueStyles={{ fontSize: 'lg' }}
            ellipsis="middle"
          />
        )}
        {!data.walletTransaction.txHash && (
          <>
            <Text color="textLabel" fontSize="lg">
              Hash
            </Text>
            <Text color="textRegular" fontSize="lg">
              -
            </Text>
          </>
        )}
      </Box>
      {data.walletTransaction.toTag && (
        <Box
          w={tableType === TableType.FULL ? '35%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Memo/note/nonce
          </Text>
          <Text color="textRegular" fontSize="lg">
            {data.walletTransaction.toTag}
          </Text>
        </Box>
      )}
      <Box
        w={tableType === TableType.FULL ? '35%' : '100%'}
        mb="20px"
        mr={tableType === TableType.FULL ? '100px' : '0'}>
        <Text color="textLabel" fontSize="lg">
          Time
        </Text>
        <Text color="textRegular" fontSize="lg">
          {new Date(data.walletTransaction.createdAt).toLocaleTimeString()}
        </Text>
      </Box>
    </HStack>
  );
};

export default WithdrawDetailsContent;
