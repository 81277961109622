import { Box, HStack } from 'native-base';
import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types';

import { Pressable } from '@cryptowallet/frontend/ui';
import LeftRoundedChevronIcon from '@cryptowallet/web/icons/LeftRoundedChevronIcon';

export const BackTextButton = ({ children, ...props }: IButtonProps) => (
  <Pressable
    outlineWidth="0"
    p="0"
    bg="transparent"
    color="white"
    fontSize="md"
    _hover={{
      bg: 'transparent',
      color: 'primary.300',
    }}
    _focus={{
      color: 'secondary.400',
    }}
    _disabled={{
      bg: 'transparent',
      color: 'textDisabled',
    }}
    {...props}>
    {({ isHovered, isFocused }) => {
      let backBtnColor = '#ffffff80';

      if (props.isDisabled) {
        backBtnColor = '#241F33';
      } else if (isHovered) {
        backBtnColor = '#7252C7';
      } else if (isFocused) {
        backBtnColor = '#FFCB8F';
      }

      return (
        <HStack alignItems="center">
          <Box mr="12px">
            <LeftRoundedChevronIcon color={backBtnColor} width="7px" height="12px" />
          </Box>
          {children}
        </HStack>
      );
    }}
  </Pressable>
);
