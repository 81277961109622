export const networksData = {
  BSC: {
    color: '#EAB300',
  },
  ETH: {
    color: '#777DF7',
  },
  BNB: {
    color: '#EAB300',
  },
  TRX: {
    color: '#FF060A',
  },
  MATIC: {
    color: '#8247E5',
  },
  NEO: {
    color: '#00C4B3',
  },
  SOL: {
    color: '#9946FF',
  },
  THETA: {
    color: '#0AC18E',
  },
  NANO: {
    color: '#278DC1',
  },
  CHZ: {
    color: '#CD0124',
  },
  FTM: {
    color: '#13B5EC',
  },
  LUNC: {
    color: '#FFD83D',
  },
  RON: {
    color: '#4B98FF',
  },
  STAKING: {
    color: '#6F7390',
  },
  AVAXC: {
    color: '#E84142',
  },
  ARBITRUM: {
    color: '#2A8BD1',
  },
  OPTIMISM: {
    color: '#FF0420',
  },
  NEO3: {
    color: '#00C4B3',
  },
  KAVA: {
    color: '#ED4E33',
  },
  EOS: {
    color: '#0F4FE3',
  },
  KDA2: {
    color: '#E6017A',
  },
  ONT: {
    color: '#4B98FF',
  },
  RSK: {
    color: '#00B520',
  },
  XTZ: {
    color: '#2C7DF7',
  },
  EGLD: {
    color: '#0037C1',
  },
  VET: {
    color: '#6E66ED',
  },
  WAX: {
    color: '#ED4E33',
  },
};
