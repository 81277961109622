import { Box } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { ExchangerExchangeResponseDtoTypeEnum } from '@cryptowallet/web/api-client';
import { IMasterDetailsComponentProps, TableType } from '@cryptowallet/web/components/Table';

import BuyDetailsContent from './BuyDetailsContent';
import SellDetailsContent from './SellDetailsContent';

const content = {
  [ExchangerExchangeResponseDtoTypeEnum.Buy]: {
    Component: BuyDetailsContent,
    containerHeight: '338px',
  },
  [ExchangerExchangeResponseDtoTypeEnum.Sell]: {
    Component: SellDetailsContent,
    containerHeight: '270px',
  },
};
const RecentActivityTableDetails = ({ tableType, data }: IMasterDetailsComponentProps) => {
  const { isBiggerThanTablet } = useScreenSize();
  const { Component } = content[data?.type] || { Component: () => null, containerHeight: '270px' };

  return (
    <Box py="24px" px={tableType === TableType.SMALL && !isBiggerThanTablet ? '24px' : '0'}>
      <Component tableType={tableType} data={data} />
    </Box>
  );
};

export default RecentActivityTableDetails;
