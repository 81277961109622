/* eslint-disable @typescript-eslint/no-shadow */
import { Box, FormControl, IFormControlProps, Spinner, Text } from 'native-base';
import React, { useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { ExchangerAssetEntityDto } from '@cryptowallet/frontend/client';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { getPlatform } from '@cryptowallet/frontend/utils';

import { Input, usePartnerStyles, useWidget } from '../../../../index';
import ErrorMessage, { IValidate } from '../../ErrorMessage';
import Pressable from '../../Pressable';
import CurrencyDropdownButton from '../CurrencyDropdownButton';
import CurrencyDropdownList from '../CurrencyDropdownList';

const isWeb = getPlatform() === 'web';

const CryptoCurrencyInput = ({
  value = '',
  currencies,
  selectedCurrency,
  isLoading = false,
  controlProps = {},
  isDisabled,
  onAmountChange,
  onCurrencyChange,
  withIcon,
  label = '',
  placeholder,
  validate,
  showNetwork = false,
  openCurrencyList,
  isOpen,
  name,
  isModalView = false,
  control,
  h = '56px',
  setMaxValue,
  bg = {
    default: 'gray.200',
    active: 'gray.400',
  },
  ...rest
}: {
  value?: string;
  controlProps?: IFormControlProps;
  currencies: Array<ExchangerAssetEntityDto>;
  selectedCurrency: ExchangerAssetEntityDto;
  isLoading?: boolean;
  isDisabled: boolean;
  onAmountChange: (value: string) => void;
  onCurrencyChange?: (value: ExchangerAssetEntityDto) => void;
  withIcon: boolean;
  label?: string;
  placeholder: string;
  validate?: IValidate;
  showNetwork?: boolean;
  openCurrencyList: (name: string) => void;
  isOpen: boolean;
  name: string;
  isModalView?: boolean;
  control: Control;
  h?: string;
  bg?: {
    default: string;
    active: string;
  };
  setMaxValue: () => void;
}) => {
  const partnerStyles = usePartnerStyles('CryptoCurrencyInput');

  const [focused, setFocused] = useState(false);

  const { isMobile } = useScreenSize();
  const { isWidget } = useWidget();

  const validateObj = useMemo(() => {
    const result = {};

    if (validate) {
      Object.entries(validate).forEach(item => {
        const [key, value] = item;
        result[key] = value.rule;
      });
    }

    return result;
  }, [validate]);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue: value,
    control,
    rules: {
      validate: validateObj,
    },
  });

  const onSelectCurrency = (currency: ExchangerAssetEntityDto) => {
    openCurrencyList(name); // trigger close
    onCurrencyChange(currency);
  };

  const onChangeInput = newAmount => {
    field.onChange(newAmount);
    onAmountChange(newAmount);
  };

  const showBorder = isOpen || focused;
  const defaultBorderColor = showBorder ? 'secondary.400' : 'transparent';
  const borderColor = error ? 'error.400' : defaultBorderColor;

  return (
    <FormControl isInvalid={!!error} mb={isWeb ? undefined : '24px'} {...controlProps}>
      <FormControl.Label>
        <Text fontFamily={isWeb ? 'body' : 'TT Chocolates'} fontSize={isWeb ? 'lg' : 'md'} color="textLabel">
          {label}
        </Text>
      </FormControl.Label>
      <Box position="relative" h={h} w="100%" borderRadius="10px" zIndex="1">
        <Input
          h="100%"
          overflowX="hidden"
          bg={isOpen ? bg.active : bg.default}
          borderWidth="1"
          borderColor={borderColor}
          borderRadius="10px"
          px="24px"
          pr="182px"
          color="textRegular"
          fontSize={isWeb ? 'lg' : 'md'}
          value={value}
          type="text"
          keyboardType="numeric"
          onBlur={() => {
            setFocused(false);
            field.onBlur();
          }}
          placeholder={isLoading ? '' : placeholder}
          InputLeftElement={isLoading && <Spinner position="absolute" left="13px" color="secondary.400" />}
          onChangeText={onChangeInput}
          variant="unstyled"
          zIndex="1"
          _hover={{
            bg: isOpen ? bg.active : 'gray.300',
          }}
          name={name}
          onFocus={() => {
            setFocused(true);
            if (isModalView && !isOpen) {
              openCurrencyList(name);
            }
          }}
          {...rest}
          {...partnerStyles}
        />
        {!isWeb && setMaxValue && (
          <Pressable
            onPress={setMaxValue}
            position="absolute"
            left="40%"
            top="16px"
            w="49px"
            h="24px"
            zIndex="2"
            borderRadius="50"
            borderWidth="1px"
            borderColor="textRegular"
            justifyContent="center"
            alignItems="center">
            <Text textTransform="uppercase">Max</Text>
          </Pressable>
        )}
        <CurrencyDropdownButton
          showNetwork={showNetwork}
          withIcon={withIcon}
          selectedCurrency={selectedCurrency}
          isOpen={isOpen}
          toggleOpen={() => openCurrencyList(name)}
          containerStyles={{
            width: isWeb ? undefined : '40%',
            py: showBorder ? '1px' : '0', // border should overlap input when not opened || focused
          }}
          setFocused={setFocused}
          bg={bg}
          isDisabled={isDisabled}
          showBorder={showBorder}
        />
        {!isMobile && !isWidget && isOpen && (
          <CurrencyDropdownList
            currencies={currencies}
            onSelectCurrency={onSelectCurrency}
            selectedCurrency={selectedCurrency}
            onClose={() => openCurrencyList(null)}
            showNetwork={showNetwork}
          />
        )}
      </Box>
      {(isWeb || (error && !isWeb)) && (
        <ErrorMessage
          containerProps={isWeb ? { minH: { base: '45px', md: '28px' } } : undefined}
          error={error}
          validate={validate}
          onChangeInput={onChangeInput}
        />
      )}
    </FormControl>
  );
};

export default CryptoCurrencyInput;
