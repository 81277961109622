import { Box, HStack, Image, Text, VStack } from 'native-base';
import QRCodeLib from 'qrcode';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  useCreateWalletAccountMutation,
  useSelectedWalletAccount,
  useSelectedWalletResource,
  useWalletSummary,
} from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import {
  ActionGuard,
  BottomPurpleButton,
  ButtonPurple,
  CryptocurrencyInputsForm,
  CryptoCurrencyInputType,
  ICryptocurrencyInputItem,
  IInputCurrency,
} from '@cryptowallet/frontend/ui';
import CopyBox, { CopyBoxVariant } from '@cryptowallet/web/components/CopyBox';
import WarningText from '@cryptowallet/web/components/WarningText';

const depositGuidelinesList = [
  'Ensure that you are depositing a token that is officially listed and supported on our platform.',
  'You can easily verify if a token is listed by entering its name or ticker symbol in the search panel provided on our website.',
  `Double-check the token's compatibility with our native asset wallet before making any deposits.`,
  'Do not deposit tokens that are not listed or unsupported, as this may result in irreversible loss.',
  'Please be aware that transactions involving crypto wallets linked to high-risk activities such as Gambling, Mixing, P2P services, Mining, Scams, Ransom, and the Darknet may lead to account blocking and loss of funds. We urge you to carefully verify your wallets prior to conducting transactions through your CryptoWallet account. CryptoWallet is not liable for losses incurred due to high-risk services',
];

const DisclaimerText = ({ children, ...rest }) => (
  <Text fontSize="md" color="textRegular" {...rest}>
    {children}
  </Text>
);

const AssetDeposit = ({ onGoBack }) => {
  const { allWalletResources, existingWalletResources } = useWalletSummary();
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const selectedWalletResource = useSelectedWalletResource();

  const { setSelectedWalletAccountId, setSelectedWalletAssetId } = useAccountsStore(state => ({
    setSelectedWalletAccountId: state.setSelectedWalletAccountId,
    setSelectedWalletAssetId: state.setSelectedWalletAssetId,
  }));

  const [qrImage, setQrImage] = useState('');

  const { address } = selectedWalletAccount || {};

  const { isLoading: createWalletAssetLoading, mutateAsync: createWalletAssetMutateAsync } =
    useCreateWalletAccountMutation();

  const onCurrencyChange = useCallback(
    (newCurrency: IInputCurrency) => {
      const { id } = newCurrency;
      const walletAccountExist = existingWalletResources.some(
        item => item.walletAccount && item.walletAccount.id === id,
      );

      if (walletAccountExist) {
        setSelectedWalletAccountId(id);
      } else {
        setSelectedWalletAssetId(id);
      }
    },
    [existingWalletResources, setSelectedWalletAccountId, setSelectedWalletAssetId],
  );

  const onAddAsset = () => createWalletAssetMutateAsync(selectedWalletResource.walletAsset.id);

  const cryptocurrencyInputs = useMemo<ICryptocurrencyInputItem[]>(() => {
    const selectedAsset = (selectedWalletAccount || selectedWalletResource)?.asset;
    const selectedWalletResourceData = allWalletResources.find(item => item.asset.id === selectedAsset.id);

    return selectedAsset
      ? [
          {
            name: 'send',
            label: 'You send',
            currencies: allWalletResources.map(item => ({
              ...item.asset.currency,
              id: (item.walletAccount || item.walletAsset).id, // jwe use walletAssetId if walletAccount not created yet
              isCreated: item.isCreated,
              image: item.asset.image,
              network: item.asset.network,
              type: item.asset.type,
            })),
            selectedCurrency: {
              ...selectedAsset.currency,
              id: selectedWalletResourceData
                ? (selectedWalletResourceData.walletAccount || selectedWalletResourceData.walletAsset).id
                : '',
              image: selectedAsset.image,
              network: selectedAsset.network,
              type: selectedAsset.type,
            },
            onCurrencyChange,
            type: CryptoCurrencyInputType.Select,
            isLoading: createWalletAssetLoading,
            showNetwork: true,
          },
        ]
      : [];
  }, [allWalletResources, selectedWalletAccount, selectedWalletResource, onCurrencyChange, createWalletAssetLoading]);

  const { control } = useForm({
    mode: 'onTouched',
  });

  useEffect(() => {
    if (address) {
      QRCodeLib.toDataURL(address).then(setQrImage);
    } else {
      setQrImage('');
    }
  }, [address]);

  return (
    <VStack>
      <HStack py="40px" px="24px" flexDirection="column" alignItems="center" zIndex="1">
        <Box w="100%" flexGrow="1">
          <CryptocurrencyInputsForm control={control} inputs={cryptocurrencyInputs} />
          {address && (
            <CopyBox
              containerProps={{ mt: '8px' }}
              title={
                <Text color="textLabel" fontSize="lg">
                  Wallet address
                </Text>
              }
              value={address}
              variant={CopyBoxVariant.GRAYBOX}
              ellipsis="middle"
              valueStyles={{ fontSize: 'lg' }}
            />
          )}
        </Box>
        {qrImage && (
          <>
            <Image
              source={{
                uri: qrImage,
              }}
              alt="wallet qr address"
              w="160px"
              h="160px"
              mt="40px"
              zIndex="-1"
            />
            <VStack mt="40px" w="100%">
              <WarningText textProps={{ color: 'textRegular', fontSize: 'md' }} text="Important Notice:" />
              <DisclaimerText mt="16px">
                We value the security of your assets. Please be aware that we only accept deposits of supported and
                listed tokens. Depositing unsupported tokens into the native asset wallet may result in the loss of your
                funds.
              </DisclaimerText>
              <DisclaimerText my="24px">Guidelines for Deposits:</DisclaimerText>
              <VStack>
                {depositGuidelinesList.map((item, index) => (
                  <HStack>
                    <DisclaimerText w="14px">{index + 1}.</DisclaimerText>
                    <DisclaimerText>{item}</DisclaimerText>
                  </HStack>
                ))}
              </VStack>
            </VStack>
          </>
        )}
      </HStack>
      {selectedWalletResource && (
        <Box w="100%" px="24px" pb="40px">
          <ActionGuard>
            {({ openAlert }) => (
              <ButtonPurple
                h="50px"
                py="0"
                px="26px"
                size="lg"
                isDisabled={createWalletAssetLoading}
                onPress={openAlert || onAddAsset}>
                Generate deposit address
              </ButtonPurple>
            )}
          </ActionGuard>
        </Box>
      )}
      {selectedWalletAccount && (
        <BottomPurpleButton onPress={onGoBack} size="lg">
          OK
        </BottomPurpleButton>
      )}
    </VStack>
  );
};

export default AssetDeposit;
