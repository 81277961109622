/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable global-require */
/* eslint-disable sonarjs/no-duplicate-string */

import { extendTheme } from 'native-base';

export const FONTS = {
  heading: 'TT-Chocolates',
  body: 'TT-Chocolates',
  mono: 'TT-Chocolates',
};

export const PALETTE = {
  white: '#fff',
  black: '#000',
  lightBlue: '#8FC9FF',
  textRegular: '#BDB8CC',
  textLabel: '#8E8A99',
  textDisabled: '#504C5C',
  textSuccess: '#00D8C4',
  textGray: '#777385',
  borderLightGray: '#F3F3F4',
  deepBlue: '#008CFF',
  gray: {
    100: 'rgba(255, 255, 255, 0.04)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.12)',
    400: 'rgba(255, 255, 255, 0.16)',
    500: '#262629',
  },
  primary: {
    300: '#7252C7',
    400: '#6652CC',
    500: '#8067FF',
  },
  secondary: {
    400: '#FFCB8F',
  },
  'error.400': '#FF6F71',
  background: {
    400: '#0E0E0F',
    600: '#161026',
    900: '#815CE0',
  },
};

const themeData = {
  colors: PALETTE,
  components: {
    Popover: {
      baseStyle: {
        bg: 'white',
      },
    },
    Slider: {
      sizes: {
        lg: { thumbSize: '40px', sliderSize: '40px' },
      },
    },
    SliderTrack: {
      baseStyle: {
        bg: 'gray.100',
      },
    },
    Text: {
      baseStyle: {
        color: PALETTE.textRegular,
      },
    },
    Heading: {
      baseStyle: {
        color: PALETTE.white,
      },
    },
    Button: {
      baseStyle: {
        rounded: '3xl',
      },
      sizes: {
        xs: {
          py: '6px',
          px: '12px',
          _text: {
            fontSize: 'xs',
          },
        },
        sm: {
          py: '8px',
          px: '16px',
          _text: {
            fontSize: 'xs',
          },
        },
        md: {
          py: '14px',
          px: '24px',
        },
        lg: {
          py: '16px',
          px: '32px',
          _text: {
            lineHeight: '14px',
          },
        },
      },
    },
    Input: {
      baseStyle: {
        _invalid: 'error.400',
      },
    },
    TextArea: {
      baseStyle: {
        _focus: {
          borderColor: 'black',
        },
      },
    },
    FormControlErrorMessage: {
      baseStyle: {
        _text: {
          fontSize: 'md',
          color: 'error.500',
        },
      },
    },
    Checkbox: {
      sizes: {
        md: { _icon: { size: 8 } },
      },
    },
  },
  fonts: FONTS,
  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: 'dark',
  },
  fontSizes: {
    '2xs': 8,
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 18,
    '2xl': 22,
    '3xl': 24,
    '4xl': 34,
    '5xl': 46,
    '6xl': 58,
    '7xl': 70,
    '8xl': 94,
    '9xl': 126,
  },
};

export const createTheme = (additionalThemeAttributes: object = {}) =>
  extendTheme({
    ...themeData,
    ...additionalThemeAttributes,
  });

export const theme = extendTheme(themeData);

export const config = {
  dependencies: {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    'linear-gradient': require('react-native-linear-gradient').default,
  },
};
