import dayjs from 'dayjs';
import { Text, VStack } from 'native-base';
import React from 'react';

const ReferralUserMasterDetails = ({ data }) => {
  const registerDate = data.createdAt ? dayjs(data.createdAt).format('DD.MM.YYYY') : '-';
  const taskCompletedDate = data.assignedRewardsAt ? dayjs(data.assignedRewardsAt).format('DD.MM.YYYY') : '-';

  return (
    <VStack px="24px">
      <VStack py="12px">
        <Text color="textLabel" fontSize="lg">
          Registration date
        </Text>
        <Text color="textRegular" fontSize="lg">
          {registerDate}
        </Text>
      </VStack>
      <VStack py="12px">
        <Text color="textLabel" fontSize="lg">
          Task completed
        </Text>
        <Text color="textRegular" fontSize="lg">
          {taskCompletedDate}
        </Text>
      </VStack>
    </VStack>
  );
};

export default ReferralUserMasterDetails;
