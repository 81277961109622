import { IImageProps, Image } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { Pressable } from '@cryptowallet/frontend/ui';

const Logo = (props: IImageProps) => {
  const { isBiggerThanTablet } = useScreenSize();

  return (
    <Pressable
      onPress={() => window.open('https://cryptowallet.com/')}
      outlineWidth="0"
      borderWidth="1px"
      borderColor="transparent"
      borderRadius="10px"
      p={{ lg: '10px' }}
      ml={{ lg: '-10px' }}
      mr={{ lg: '40px' }}
      _focus={{
        borderColor: 'secondary.400',
      }}>
      <Image
        src={isBiggerThanTablet ? '/assets/images/logo.svg' : '/assets/images/logo--short.svg'}
        width={isBiggerThanTablet ? '225px' : '45px'}
        height={isBiggerThanTablet ? '19px' : '20px'}
        mb={isBiggerThanTablet ? '73px' : '58px'}
        ml={isBiggerThanTablet ? '16px' : '0'}
        alt="cryptowallet.com"
        {...props}
      />
    </Pressable>
  );
};

export default Logo;
