import { Image } from 'native-base';
import React from 'react';

import { Pressable } from '@cryptowallet/frontend/ui';

const DiscordPromo = () => (
  <Pressable
    borderWidth="1px"
    borderColor="transparent"
    outlineWidth="0"
    _focus={{ borderColor: 'secondary.400' }}
    onPress={() => window.open('https://discord.gg/vgy7GZKw2t', '_blank')}>
    <Image source={{ uri: `/assets/images/discord-promo.png` }} height="260px" alt="join us in discord" />
  </Pressable>
);

export default DiscordPromo;
