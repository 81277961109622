import { useQuery } from '@tanstack/react-query';
import { pick } from 'lodash';
import qs from 'qs';
import { create } from 'zustand';
import { createJSONStorage } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import {
  cacheRequestOption,
  getClientError,
  longPollingOptions,
  persist,
  setQueryString,
  defaultRetryPolicy,
} from '@cryptowallet/frontend/utils';

import { useActiveSellPaymentMethods, useDebounce } from '../../hooks';
import {
  EstimateWalletTransactionPayloadWalletsDtoTypeEnum,
  ExchangerAssetEntityDto,
  ExchangerCreateExchangeRequestDtoPspTypeEnum,
  ExchangerEstimateResponseDto,
  WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum,
} from '@cryptowallet/frontend/client';
import { queryStore } from '../query-store';
import { exchangerApi, walletsApi } from '@cryptowallet/frontend/api';

const RATE_UPDATE_TIMEOUT_MS = 10000;

interface IPaymentMethodValidationValues {
  min: number;
  max: number;
}
interface IValidationValues {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]?: IPaymentMethodValidationValues;
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]?: IPaymentMethodValidationValues;
}

export interface SellFlowState {
  stepIndex: number;
  setStepIndex: (stepIndex: number) => void;
  paymentMethod: ExchangerCreateExchangeRequestDtoPspTypeEnum;
  setPaymentMethod: (method: ExchangerCreateExchangeRequestDtoPspTypeEnum) => void;
  fromCurrency: ExchangerAssetEntityDto;
  setFromCurrency: (currency: ExchangerAssetEntityDto) => void;
  fromCurrencyId: string;
  setFromCurrencyId: (fromCurrencyId: string) => void;
  toCurrency: ExchangerAssetEntityDto;
  setToCurrency: (currency: ExchangerAssetEntityDto) => void;
  amount: string;
  setAmount: (amount: string) => void;
  exchangeId: string;
  setExchangeId: (exchangeId: string) => void;
  nextRateUpdateTime: number;
  setNextRateUpdateTime: (newDate: number) => void;
  iban: string;
  setIban: (iban: string) => void;
  aggreeNameMatches: boolean;
  setAgreeNameMatches: (aggreeNameMatches: boolean) => void;
  validationValues: IValidationValues;
  setValidationValues: (validationValues: IValidationValues) => void;
  amountPassed: boolean;
  setAmountPassed: (amountPassed: boolean) => void;
  useMyWallet: boolean;
  setUseMyWallet: (useMyWallet: boolean) => void;
  setExchangeData: ({
    fromCurrency,
    toCurrency,
    paymentMethod,
    amount,
    iban,
  }: {
    fromCurrency: ExchangerAssetEntityDto;
    toCurrency: ExchangerAssetEntityDto;
    paymentMethod: ExchangerCreateExchangeRequestDtoPspTypeEnum;
    amount: string;
    iban: string;
  }) => void;
  resetInitialValidationValues: () => void;
  reset: () => void;
  resetPersistedSlice: () => void;
  resetRegularSlice: () => void;
  initQueryState: (excludeKeys: string[]) => void;
}

const initialPersistState = {
  aggreeNameMatches: false,
  iban: '', // UA213223130000026007233566001
  useMyWallet: false,
};

const initialQueryState = {
  paymentMethod: ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa,
  amount: commonConstants.DEFAULT_SELL_AMOUNT,
  fromCurrencyId: '',
  amountPassed: false,
  exchangeId: '',
};

const initialValidationValues = {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: {
    min: 0.000001,
    max: Number.MAX_SAFE_INTEGER,
  },
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: {
    min: 0.000001,
    max: Number.MAX_SAFE_INTEGER,
  },
};

const initialRegularState = {
  fromCurrency: null,
  toCurrency: null,
  nextRateUpdateTime: null,
  stepIndex: 0,
  validationValues: initialValidationValues,
  estimateEnabled: true,
};

export const getSellFlowQueryFields = () => Object.keys(initialQueryState);

const sellFlowTransformer = (key, value) => {
  const initialStateValue = initialQueryState[key];
  switch (key) {
    case 'amount': {
      const numberValue = Number(value);
      return Number.isNaN(numberValue) ? initialStateValue : numberValue;
    }
    case 'paymentMethod': {
      return value && Object.values(ExchangerCreateExchangeRequestDtoPspTypeEnum).includes(value)
        ? value
        : initialStateValue;
    }
    case 'amountPassed': {
      return value === 'true';
    }
    default: {
      return value;
    }
  }
};

const getExcludedKeys = ({ exchangeId }, queryFields, newValue) => {
  if (exchangeId || newValue.exchangeId) {
    return queryFields.filter(item => item !== 'exchangeId');
  }

  return ['exchangeId'];
};

export const getSellSafeQueryStateExcludeKeys = () => {
  const queryState = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return queryState.exchangeType === commonConstants.ExchangeTypeEnum.Sell ? [] : ['exchangeId'];
};

const useQuerySlice = queryStore(
  (set, get, api, initQueryState, getSafeQueryState) => {
    const safeQueryState = getSafeQueryState ? getSafeQueryState(getSellSafeQueryStateExcludeKeys()) : {};

    return {
      ...initialQueryState,
      ...safeQueryState,
      setFromCurrencyId: fromCurrencyId => set({ fromCurrencyId }),
      setPaymentMethod: paymentMethod => set({ paymentMethod }),
      setAmount: amount => set({ amount }),
      setExchangeId: exchangeId => set({ exchangeId }),
      setAmountPassed: amountPassed => set({ amountPassed }),
      resetQuerySlice: () => {
        setQueryString();
        set(initialQueryState);
      },
      initQueryState,
    };
  },
  {
    queryFields: getSellFlowQueryFields(),
    transformer: sellFlowTransformer,
    getExcludedKeys,
    pathname: '/dashboard/widgets',
  },
);

const usePersistedSlice = persist(
  (set, get) => ({
    ...initialPersistState,
    setIban: iban => set({ iban }),
    setAgreeNameMatches: (aggreeNameMatches: boolean) => set({ aggreeNameMatches }),
    resetPersistedSlice: () => set(initialPersistState),
    setUseMyWallet: useMyWallet => set({ useMyWallet }),
  }),
  {
    name: commonConstants.PersistKeys.DashboardSellFlow,
    storage: createJSONStorage(() => window.localStorage),
    partialize: state => {
      const excludedPersistKeys = [...Object.keys(initialRegularState), ...Object.keys(initialQueryState)];

      return Object.fromEntries(Object.entries(state).filter(([key]) => !excludedPersistKeys.includes(key)));
    },
  },
);

const useRegularSlice = (set, get, api) => ({
  ...initialRegularState,
  resetInitialValidationValues: () => set({ validationValues: initialValidationValues }),
  setStepIndex: stepIndex => set({ stepIndex }),
  setFromCurrency: fromCurrency => set({ fromCurrency }),
  setToCurrency: toCurrency => set({ toCurrency }),
  setNextRateUpdateTime: nextRateUpdateTime => set({ nextRateUpdateTime }),
  setValidationValues: validationValues => set({ validationValues }),
  resetRegularSlice: () => set(initialRegularState),
});

export const useStore = create<SellFlowState>()((set, get, api) => {
  const persistedSlice = usePersistedSlice(set, get, api);
  const querySlice = useQuerySlice(set, get, api);
  const regularSlice = useRegularSlice(set, get, api);

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...persistedSlice,
    ...querySlice,
    ...regularSlice,
    setFromCurrency: fromCurrency => {
      regularSlice.setFromCurrency(fromCurrency);
      querySlice.setFromCurrencyId(fromCurrency.id);
    },
    setExchangeData: ({ fromCurrency, toCurrency, paymentMethod, amount, iban }) => {
      set({
        fromCurrency,
        toCurrency,
      });
      querySlice.setPaymentMethod(paymentMethod);
      querySlice.setAmount(amount);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      persistedSlice.setIban(iban);
    },
    reset: () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      persistedSlice.resetPersistedSlice();
      querySlice.resetQuerySlice();
      regularSlice.resetRegularSlice();
    },
  };
});

export const estimatePlaceholder = Object.freeze({
  isPlaceholder: true,
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: {
    estimatedAmount: '',
    networkFeeAmount: '-',
    serviceFeeAmount: '-',
    bankFeeAmount: '-',
    estimatedRate: '-',
  },
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: {
    estimatedAmount: '',
    networkFeeAmount: '-',
    serviceFeeAmount: '-',
    bankFeeAmount: '-',
    estimatedRate: '-',
  },
});

interface SellFromWalletEstimate {
  walletBalanceInsufficientionReason:
    | WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum
    | string;
  maxCryptoAmount: number;
}

export const useEstimate = (enabled = true, updateTimeMs = RATE_UPDATE_TIMEOUT_MS) => {
  const {
    fromCurrency,
    toCurrency,
    amount,
    setNextRateUpdateTime,
    paymentMethod,
    validationValues,
    setValidationValues,
    exchangeId,
    resetInitialValidationValues,
    useMyWallet,
  } = useStore(
    state =>
      pick(state, [
        'paymentMethod',
        'fromCurrency',
        'toCurrency',
        'amount',
        'setNextRateUpdateTime',
        'paymentMethod',
        'validationValues',
        'setValidationValues',
        'exchangeId',
        'resetInitialValidationValues',
        'useMyWallet',
      ]),
    shallow,
  );

  const activePaymentMethods = useActiveSellPaymentMethods();

  const debouncedAmount = useDebounce(amount, 1000);
  const activeValidationValues = validationValues[paymentMethod];

  const { data: walletEstimateData } = useQuery<SellFromWalletEstimate | null>(
    ['sell-withdraw-estimate', fromCurrency, debouncedAmount, useMyWallet],
    async (): Promise<SellFromWalletEstimate | null> => {
      if (+debouncedAmount === 0 || !useMyWallet) {
        return {
          walletBalanceInsufficientionReason: '',
          maxCryptoAmount: 0,
        };
      }
      const response = await walletsApi.walletsControllerEstimateWalletTransaction({
        type: EstimateWalletTransactionPayloadWalletsDtoTypeEnum.CryptoToFiat,
        fromAssetId: fromCurrency.assetId,
        amount: debouncedAmount.toString(),
      });

      return {
        walletBalanceInsufficientionReason:
          response.data.walletEstimationTransaction.walletBalanceInsufficientionReason,
        maxCryptoAmount: +response.data.walletEstimationTransaction.maxCryptoAmount,
      };
    },
    cacheRequestOption,
  );

  const result = useQuery<ExchangerEstimateResponseDto>(
    ['exchange-estimate-sell', fromCurrency, toCurrency, debouncedAmount, paymentMethod, validationValues, useMyWallet],
    // eslint-disable-next-line consistent-return
    async (): Promise<ExchangerEstimateResponseDto> => {
      try {
        // we do not do any estimation until amount is bigger than MIN_EXCHANGE_AMOUNT. Return the placeholder and proceed with it
        if (!exchangeId && (+amount < activeValidationValues.min || +amount > activeValidationValues.max)) {
          return estimatePlaceholder as never;
        }

        const response = await exchangerApi.exchangerControllerEstimateSellExchange({
          fromExchangerAssetId: fromCurrency.id,
          toExchangerAssetId: toCurrency.id,
          amount: +debouncedAmount,
          pspTypes: activePaymentMethods,
        });

        setNextRateUpdateTime(Date.now() + updateTimeMs);

        return response.data;
      } catch (err) {
        getClientError(err, { title: `Can't estimate transaction, please try again later` });
      }
    },
    {
      ...longPollingOptions(updateTimeMs),
      retry: defaultRetryPolicy,
      enabled: enabled && !!(fromCurrency && toCurrency),
    },
  );

  const { data, isFetching } = result;

  useEffect(() => {
    resetInitialValidationValues();
  }, [fromCurrency, useMyWallet]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!data || data.isPlaceholder || isFetching) {
      return;
    }

    const newValidationValues = Object.entries(data).reduce<IValidationValues>((acc, [method, estimate]) => {
      acc[method] = {
        min: estimate.amountMin,
        max: estimate.amountMax,
      };

      return acc;
    }, {});

    setValidationValues(newValidationValues);
  }, [data, paymentMethod, isFetching]);

  return {
    ...result,
    validationValues: activeValidationValues,
    walletEstimateData,
  };
};
