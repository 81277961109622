import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { GetUserResponseUsersDtoUserRiskLevelTypeEnum } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { usePersistState, useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { ClientErrorPlaceholder, getClientError } from '@cryptowallet/frontend/utils';

import {
  AlertsContainer,
  CookiesPolicyAlert,
  NotificationAlert,
  NotificationAlertButtonType,
  NotificationAlertData,
  NotificationAlertType,
  PrivacyPolicyAlert,
  RegistrationCompleteAlert,
  TermsAlert,
  WhyINeedCwAccountAlert,
  WhyNeedKycAlert,
} from '../../components/Alerts';
import Toaster, { ToasterType } from '../../components/Toaster';
import { useAuth, useUser } from '../AuthContext';

import useSuspendedAlert from './hooks/useSuspendedAlert';

const AlertsContext = createContext(null);

const AlertComponent = {
  RegistrationCompleteAlert,
  NotificationAlert,
  WhyNeedKycAlert,
  WhyINeedCwAccountAlert,
  TermsAlert,
  PrivacyPolicyAlert,
  CookiesPolicyAlert,
};

type AlertData = NotificationAlertData;

export interface IAlertToShow {
  id: number | string;
  name: keyof typeof AlertComponent;
  data?: AlertData;
  onClose?: () => void;
}

let lastAlertId = 0;

const relativeAlertComponentNames = ['NotificationAlert'];

export const AlertsProvider = ({ layoutLeftPadding, hideRelativeAlerts = false, children }) => {
  const user = useUser();
  const { clearTokens } = useAuth();
  const userStatus = useUserStatus();
  const navigate = useNavigate();

  const [twoFaAlertViewed, setTwoFaAlertViewed] = usePersistState(false, commonConstants.PersistKeys.TwoFaAlertViewed);
  const [kycAlertViewed, setKycAlertViewed] = usePersistState(false, commonConstants.PersistKeys.KycAlertViewed);

  const [changableAlerts, setChangableAlerts] = useState<IAlertToShow[]>([]);
  const [alertsRect, setAlertsRect] = useState({ height: '0' });

  useSuspendedAlert(setChangableAlerts);

  const createAlert = useCallback(
    (name: keyof typeof AlertComponent, data = undefined) => {
      // eslint-disable-next-line no-plusplus
      const id = ++lastAlertId;
      setChangableAlerts(prev => [
        ...prev,
        {
          id,
          name,
          data,
        },
      ]);

      return id;
    },
    [setChangableAlerts],
  );

  const destroyAlert = useCallback(
    id => setChangableAlerts(prev => prev.filter(alert => alert.id !== id)),
    [setChangableAlerts],
  );

  const showToaster = useCallback(
    (type, title, description?) =>
      toast(({ toastProps }) => <Toaster {...toastProps} type={type} title={title} description={description} />, {
        autoClose: 10000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: false,
      }),
    [],
  );

  const showErrorToaster = useCallback(
    ({ title, description }: { title?: string; description?: string } = {}) =>
      showToaster(ToasterType.ERROR, title || 'Something went wrong', description),
    [],
  );

  const onApiError = useCallback((err?: AxiosError, clientErrorPlaceholder?: ClientErrorPlaceholder) => {
    if (err?.response?.status === 429) {
      return; // we show already some error in axios interceptor
    }
    const clientError = getClientError(err, clientErrorPlaceholder);

    showErrorToaster(clientError);
  }, []);

  useEffect(() => {
    window.onSessionExpire = debounce(() => {
      clearTokens();
      showErrorToaster({ title: 'Your session has expired.' });
    }, 100);
  }, [showErrorToaster, clearTokens]);

  useEffect(() => {
    const id = 'kyc';
    const showKycAlert =
      user &&
      userStatus.isLoggedIn &&
      !userStatus.VERIFIED_KYC &&
      !kycAlertViewed &&
      user.userRiskLevelType !== GetUserResponseUsersDtoUserRiskLevelTypeEnum.Blocked;

    if (showKycAlert) {
      setChangableAlerts(prev => [
        ...prev.filter(item => item.id !== id),
        {
          id,
          name: 'NotificationAlert',
          data: {
            type: NotificationAlertType.INFO,
            headerMessage: 'Get full access to CryptoWallet.com',
            text: 'Full access includes buying, selling, depositing and withdrawing crypto on the platform, as well as our referral and ambassador programs.',
            buttons: [
              {
                title: 'Why do I need KYC?',
                onPress: () => createAlert('WhyNeedKycAlert'),
                type: NotificationAlertButtonType.ButtonSecondary,
              },
              {
                title: 'Start KYC',
                onPress: () => {
                  navigate('/account/security/verification');
                  setKycAlertViewed(true);
                },
                type: NotificationAlertButtonType.ButtonPurple,
                buttonProps: {
                  _text: {
                    fontSize: 'sm',
                  },
                  ml: '16px',
                },
              },
            ],
          },
          onClose: () => setKycAlertViewed(true),
        },
      ]);
    } else {
      setChangableAlerts(prev => prev.filter(item => item.id !== id));
    }
  }, [user, userStatus.isLoggedIn, userStatus.kycPoaHighRiskVerified, kycAlertViewed, navigate]);

  useEffect(() => {
    const id = '2fa';
    const show2faWarning =
      !user?.user?.totpEnabled &&
      userStatus.isLoggedIn &&
      userStatus.VERIFIED_KYC &&
      userStatus.VERIFIED_POA_FOR_HIGH_RISK &&
      !twoFaAlertViewed;

    if (show2faWarning) {
      setChangableAlerts(prev => [
        ...prev.filter(item => item.id !== id),
        {
          id: '2fa',
          name: 'NotificationAlert',
          data: {
            type: NotificationAlertType.WARNING,
            headerMessage: `Low account security level: It's time to set up 2FA`,
            buttons: [
              {
                title: 'Set up 2FA',
                onPress: () => {
                  navigate('/account/2fa');
                  setTwoFaAlertViewed(true);
                },
                type: NotificationAlertButtonType.ButtonSecondary,
              },
            ],
          },
          onClose: () => setTwoFaAlertViewed(true),
        },
      ]);
    } else {
      setChangableAlerts(prev => prev.filter(item => item.id !== id));
    }
  }, [
    user?.user?.totpEnabled,
    userStatus.isLoggedIn,
    userStatus.VERIFIED_KYC,
    userStatus.VERIFIED_POA_FOR_HIGH_RISK,
    twoFaAlertViewed,
    setTwoFaAlertViewed,
    navigate,
  ]);

  const alertsToShow = useMemo(() => {
    const lastRelativeAlert = [...changableAlerts]
      .reverse()
      .find(item => relativeAlertComponentNames.includes(item.name));
    const lastRelativeAlertIndex = changableAlerts.findIndex(item => item.id === lastRelativeAlert?.id);

    return changableAlerts.map((item, index) => ({
      ...item,
      isLastRelativeAlert: index === lastRelativeAlertIndex,
    }));
  }, [changableAlerts]);

  const value = useMemo(() => {
    const relativeAlerts = alertsToShow.filter(({ name }) => relativeAlertComponentNames.includes(name));

    return {
      createAlert,
      destroyAlert,
      relativeAlertsLength: relativeAlerts.length,
      alertsRect,
      setAlertsRect,
      showToaster,
      showErrorToaster,
      onApiError,
    };
  }, [createAlert, alertsToShow, destroyAlert, alertsRect, setAlertsRect, showToaster, showErrorToaster, onApiError]);

  return (
    <AlertsContext.Provider value={value}>
      {!hideRelativeAlerts && (
        <AlertsContainer layoutLeftPadding={layoutLeftPadding}>
          <>
            {alertsToShow.map(({ id, name, data, onClose, isLastRelativeAlert }) => {
              const Component = AlertComponent[name];

              return (
                <Component
                  key={id}
                  id={id}
                  // eslint-disable-next-line
                  onClose={() => {
                    destroyAlert(id);
                    if (onClose) {
                      onClose();
                    }
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  data={data}
                  isLastRelativeAlert={isLastRelativeAlert}
                />
              );
            })}
          </>
        </AlertsContainer>
      )}
      {hideRelativeAlerts && (
        <>
          {alertsToShow
            .filter(item => !relativeAlertComponentNames.includes(item.name))
            .map(({ id, name, data, onClose }) => {
              const Component = AlertComponent[name];

              return (
                <Component
                  key={id}
                  id={id}
                  // eslint-disable-next-line
                  onClose={() => {
                    destroyAlert(id);
                    if (onClose) {
                      onClose();
                    }
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  data={data}
                />
              );
            })}
        </>
      )}
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlerts = () => useContext(AlertsContext);
