import { Image, StyledProps } from 'native-base';
import React from 'react';

export interface IconProps extends StyledProps {
  name?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
}

const Icon = (props: IconProps): JSX.Element => {
  const { name, width = '24px', height = '24px', ...restProps } = props;

  return (
    <Image source={{ uri: `/assets/icons/${name}.svg` }} width={width} height={height} alt={name} {...restProps} />
  );
};

export default Icon;
