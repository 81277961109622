import { Box, Text, useToken, VStack } from 'native-base';
import React, { useState } from 'react';

import { BoldCircleAddIcon, BoldCircleMinusIcon, Pressable } from '@cryptowallet/frontend/ui';

const Faq = ({ title, list = [] }) => {
  const [openIndex, setOpenIndex] = useState(-1);
  const [gray, white] = useToken('colors', ['gray.400', 'white']);

  if (!list.length) {
    return null;
  }

  return (
    <VStack>
      <Text mt="32px" fontSize="24px" color="white">
        {title}
      </Text>
      <VStack>
        {list.map(({ question, answer }, index) => {
          const open = index === openIndex;
          return (
            <Pressable
              key={index}
              onPress={() => setOpenIndex(prev => (prev === index ? -1 : index))}
              py="16px"
              px="24px"
              bg={open ? 'gray.100' : 'transparent'}
              borderRadius="20px">
              <VStack>
                <Text fontSize="20px" color={open ? 'white' : 'textRegular'}>
                  {question}
                </Text>
                {open && (
                  <Text mt="10px" fontSize="18px" color="white">
                    {answer}
                  </Text>
                )}
                <Box h="24px" w="24px" position="absolute" top="0" right="0">
                  {open && <BoldCircleMinusIcon color={white} />}
                  {!open && <BoldCircleAddIcon color={gray} />}
                </Box>
              </VStack>
            </Pressable>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default Faq;
