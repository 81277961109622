import omit from 'lodash/omit';
import pick from 'lodash/pick';
import qs from 'qs';

import { setQueryString } from '@cryptowallet/frontend/utils';

// some terrible workaround to make widget URL store work without rewriting a lot of shit
let pathname: undefined | string;
/**
 * Do not use until u really need
 */
export const setPathname = (p: string) => {
  pathname = p;
};

export const queryStore = (fn, options?) => (set, get, api) => {
  const getQueryFields = (newValue = {}) => {
    const queryFields = options?.queryFields || [];
    let excludedKeys = [];
    if (options.getExcludedKeys) {
      excludedKeys = options.getExcludedKeys(get(), queryFields, newValue);
    }

    return queryFields.filter(queryField => !excludedKeys.includes(queryField));
  };

  const querySet = newValue => {
    set(newValue);

    const queryFieldsIncludingExcluded = options?.queryFields || []; // including excluded keys
    const queryFields = getQueryFields(newValue);
    const externalParams = omit(
      qs.parse(window?.location.search, { ignoreQueryPrefix: true }),
      queryFieldsIncludingExcluded,
    ); // params not described in store
    const internalParams = pick(get(), queryFields); // params described in store
    const paramState = qs.stringify({ ...externalParams, ...internalParams });

    if (paramState && (window.location.pathname === options.pathname || window.location.pathname === pathname)) {
      setQueryString(`?${paramState}`);
    }
  };

  const getSafeQueryState = (excludeKeys = []) => {
    const queryState = qs.parse(window?.location.search, { ignoreQueryPrefix: true });
    if (options.transformer) {
      // transformer is used for transforming value from query(string) into propper one
      Object.entries(queryState).forEach(([key, value]) => {
        queryState[key] = options.transformer(key, value);
      });
    }
    const queryFields = getQueryFields(queryState);

    return pick(
      queryState,
      queryFields.filter(field => !excludeKeys.includes(field)),
    );
  };

  const initQueryState = excludeKeys => {
    const safeQueryState = getSafeQueryState(excludeKeys);
    set({ ...get(), ...safeQueryState });
  };

  return fn(querySet, get, api, initQueryState, getSafeQueryState);
};
