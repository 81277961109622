import { HStack } from 'native-base';
import React from 'react';

import { Icon } from '@cryptowallet/frontend/ui';

import SecurityBlockContainer from './SecurityBlockContainer';
import SecurityBlockSubTitle from './SecurityBlockSubTitle';

const SecurityBlockSuccess = ({ children }) => (
  <SecurityBlockContainer>
    <HStack justifyContent="space-between" alignItems="center">
      <SecurityBlockSubTitle>{children}</SecurityBlockSubTitle>
      <Icon name="checked-icon" h="16px" w="16px" />
    </HStack>
  </SecurityBlockContainer>
);

export default SecurityBlockSuccess;
