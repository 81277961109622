import { Box, Divider, IDividerProps, Skeleton, Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { useScreenSize, useSellExchange } from '@cryptowallet/frontend/hooks';
import { sellEstimatePlaceholder, useSellEstimate, useSellFlowStore } from '@cryptowallet/frontend/stores';
import { SecondsCountdown, useWidget } from '@cryptowallet/frontend/ui';
import { getCurrencySymbol } from '@cryptowallet/frontend/utils';
import TransactionDetailsCollapseBox from '@cryptowallet/web/components/TransactionDetailsCollapseBox';

interface ISellTransactionDetailsProps {
  dividerProps?: IDividerProps;
  sellCompleted?: boolean;
}

const SellTransactionDetails = ({ dividerProps = {}, sellCompleted = false }: ISellTransactionDetailsProps) => {
  const [toCurrency, fromCurrency, nextRateUpdateTime, paymentMethod] = useSellFlowStore(
    state => [state.toCurrency, state.fromCurrency, state.nextRateUpdateTime, state.paymentMethod],
    shallow,
  );
  const { estimateResult, completed } = useSellExchange();
  const { data } = useSellEstimate(!completed);
  const { isWidget } = useWidget();
  const { isMobile } = useScreenSize();

  const fromCurrencyLabel = getCurrencySymbol(fromCurrency?.ticker);
  const toCurrencyLabel = getCurrencySymbol(toCurrency?.ticker);

  const estimateData = (data || sellEstimatePlaceholder)[paymentMethod];
  const estimate = sellCompleted ? estimateResult : estimateData;
  const isReady = !!toCurrency && !!fromCurrency;

  return (
    <>
      {!isWidget && <Divider my="40px" orientation="horizontal" bg="gray.100" {...dividerProps} />}
      <TransactionDetailsCollapseBox hideHeading={isWidget} mb={{ base: '32px', lg: '40px' }}>
        <Box mt="16px" flexDirection="row" flexWrap="wrap">
          <Box w={isMobile ? '100%' : '50%'} pr={isMobile ? '0' : '24px'} mb="16px">
            <Text color="textLabel" fontSize="lg">
              Bank Fee
            </Text>
            <Skeleton h="21px" isLoaded={isReady}>
              <Text color="textRegular" fontWeight="500" fontSize="lg">
                {estimate?.bankFeeAmount ? `${toCurrencyLabel} ${estimate?.bankFeeAmount}` : '-'}
              </Text>
            </Skeleton>
          </Box>
          <Box w={isMobile ? '100%' : '50%'} mb="16px">
            <Text color="textLabel" fontSize="lg">
              {sellCompleted && 'Rate'}
              {!sellCompleted && (
                <>
                  Rate updates in{' '}
                  <SecondsCountdown expireTime={nextRateUpdateTime} renderer={({ timer }) => <>{timer}</>} />
                </>
              )}
            </Text>
            <Skeleton h="21px" isLoaded={isReady}>
              <Text color="textRegular" fontWeight="500" fontSize="lg">
                {estimate?.estimatedRate ? `${fromCurrencyLabel}/${toCurrencyLabel} ${estimate?.estimatedRate}` : '-'}
              </Text>
            </Skeleton>
          </Box>
          <Box w={isMobile ? '100%' : '50%'} pr={isMobile ? '0' : '24px'}>
            <Text color="textLabel" fontSize="lg">
              Service fee
            </Text>
            <Skeleton h="21px" isLoaded={isReady}>
              <Text color="textRegular" fontWeight="500" fontSize="lg">
                {estimate?.serviceFeeAmount ? `${toCurrencyLabel} ${estimate?.serviceFeeAmount}` : '-'}
              </Text>
            </Skeleton>
          </Box>
        </Box>
      </TransactionDetailsCollapseBox>
    </>
  );
};

export default SellTransactionDetails;
