import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { walletsApi } from '@cryptowallet/frontend/api';
import { AssetCurrencyEntityDto } from '@cryptowallet/frontend/client';
import { cacheRequestOption, getCurrencySymbol } from '@cryptowallet/frontend/utils';

export type WalletConfigCurrency = AssetCurrencyEntityDto & { symbol?: string };

const useWalletConfig = () => {
  const { data: walletConfigResponse, isLoading } = useQuery(
    ['walletConfig'],
    () => walletsApi.walletsControllerGetWalletConfig(),
    cacheRequestOption,
  );

  const recost = walletConfigResponse?.data?.walletConfig?.recost;

  const [fiatCurrency, cryptoCurrency]: WalletConfigCurrency[] = useMemo(() => {
    if (recost) {
      return [
        {
          ...recost.fiatAsset.currency,
          decimals: recost.fiatAsset.decimals,
          symbol: getCurrencySymbol(recost.fiatAsset.currency.ticker.toLowerCase()),
        },
        {
          ...recost.cryptoAsset.currency,
          decimals: recost.cryptoAsset.decimals,
        },
      ];
    }

    return [];
  }, [recost]);

  return {
    isLoading,
    cryptoCurrency,
    fiatCurrency,
  };
};

export default useWalletConfig;
