import { HStack } from 'native-base';
import React from 'react';

import { Icon } from '@cryptowallet/frontend/ui';
import LinkPurple from '@cryptowallet/web/components/Links/LinkPurple';

const RecentActivityMobileBtn = ({ onPress }) => (
  <HStack
    maxW={{ base: '100%', xl: '470px' }}
    mr={{ base: '0', xl: '24px' }}
    w={{ base: '100%', xl: '470px' }}
    bg="background.400"
    borderWidth="1px"
    borderColor="gray.100"
    borderRadius="10px"
    px="24px"
    py="22px"
    pb="37px"
    position="absolute"
    top="0"
    alignItems="center">
    <Icon name="recent-activity/recent-activity-mobile-btn" />
    <LinkPurple _text={{ fontSize: 'lg' }} onPress={onPress} ml="10px" text="Recent activity" />
  </HStack>
);

export default RecentActivityMobileBtn;
