import { Flex } from 'native-base';
import React from 'react';
import { Outlet, Route, useLocation } from 'react-router-dom';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { RoundedBox } from '@cryptowallet/web/components/layout';

import ReferralInvitedUsers from './InvitedUsers';
import ReferralOverview from './Overview';

export const renderRoutes = () => (
  <>
    <Route path="overview" element={<ReferralOverview />} />
    <Route path="invited-users" element={<ReferralInvitedUsers />} />
  </>
);

const Referral = () => {
  const { isDesktop } = useScreenSize();
  const location = useLocation();
  const pageId = location.pathname.split('/')[2];

  const showMobileNav = !pageId;

  return (
    <Flex position="relative" flexDirection="row" flexGrow="1" maxW="1196px">
      {(isDesktop || !showMobileNav) && (
        <RoundedBox display="flex" flexGrow="1" maxW="100%">
          <Outlet />
        </RoundedBox>
      )}
    </Flex>
  );
};

export default Referral;
