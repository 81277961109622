import { createContext, useContext } from 'react';

interface IWidgetContext {
  isWidget: boolean;
}

const WidgetContext = createContext<IWidgetContext>({
  isWidget: false,
});

export const WidgetProvider = ({ children }) => (
  <WidgetContext.Provider value={{ isWidget: true }}>{children}</WidgetContext.Provider>
);

export const useWidget = () => useContext(WidgetContext);
