/* eslint-disable @typescript-eslint/no-shadow */
import { HStack, Text, useToken, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { useToggleHideBalanceMutation, useWalletConfig, useWalletSummary } from '@cryptowallet/frontend/hooks';
import { BalanceListView, DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { ActionGuard, ButtonPurple, OverlaySpinner, Pressable, useUserSettings } from '@cryptowallet/frontend/ui';
import { maskNumber, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import { OutlinedButton } from '@cryptowallet/web/components/Buttons';
import useWindowSize from '@cryptowallet/web/hooks/useWindowSize';
import EyeIcon from '@cryptowallet/web/icons/EyeIcon';

import AssetList from '../AssetList';

const TotalBalance = () => {
  const { isLoading: isWalletConfigLoading, fiatCurrency, cryptoCurrency } = useWalletConfig();
  const {
    isLoading: isWalletSummaryLoading,
    existingWalletResources,
    fiatTotalBalance,
    cryptoTotalBalance,
  } = useWalletSummary();
  const { hideBalance } = useUserSettings();
  const windowSize = useWindowSize();
  const { mutateAsync: toggleHideBalanceMutateAsync } = useToggleHideBalanceMutation();
  const [buttonsContainerRect, setButtonsContainerRect] = useState({ width: 300 });
  const [secondary400, textLabel] = useToken('colors', ['secondary.400', 'textLabel']);
  const buttonsContainerRef = useRef();

  const { setBalanceListView, selectedWalletAccountId, selectedWalletAssetId, setDetailsPanelView } = useAccountsStore(
    state => ({
      setBalanceListView: state.setBalanceListView,
      selectedWalletAccountId: state.selectedWalletAccountId,
      selectedWalletAssetId: state.selectedWalletAssetId,
      setDetailsPanelView: state.setDetailsPanelView,
    }),
    shallow,
  );

  useLayoutEffect(() => {
    if (buttonsContainerRef && buttonsContainerRef.current) {
      setButtonsContainerRect(buttonsContainerRef.current.getBoundingClientRect());
    }
  }, [windowSize, buttonsContainerRef?.current, isWalletConfigLoading, isWalletSummaryLoading]);

  const firstWalletResource = existingWalletResources?.length ? existingWalletResources[0] : null;

  const onDepositPress = () => {
    if (selectedWalletAssetId) {
      setDetailsPanelView(DetailsPanelView.Deposit);
    } else if (firstWalletResource) {
      setDetailsPanelView(DetailsPanelView.Deposit, selectedWalletAccountId || firstWalletResource?.walletAccount?.id);
    }
  };

  const onWithdrawPress = () => {
    if (firstWalletResource) {
      setDetailsPanelView(DetailsPanelView.Withdraw, selectedWalletAccountId || firstWalletResource?.walletAccount.id);
    }
  };

  const onPressManageAccounts = () => setBalanceListView(BalanceListView.Manage);

  const isReady = !isWalletConfigLoading && !isWalletSummaryLoading;

  if (!isReady) return <OverlaySpinner />;

  return (
    <VStack px="16px" pt="40px" pb="24px">
      <VStack px="6px">
        <HStack alignItems="center">
          <Pressable outlineWidth="0" mr="16px" mb="6px" w="24px" h="24px" onPress={toggleHideBalanceMutateAsync}>
            {({ isFocused }) => <EyeIcon size="24" color={isFocused ? secondary400 : textLabel} />}
          </Pressable>
          <Text lineHeight="40px" userSelect="none" fontFamily="Uniform Pro Con" color="white" fontSize="40px">
            {fiatCurrency.symbol} {hideBalance ? maskNumber(2) : trimCryptoAmountLength(fiatTotalBalance, 2)}
          </Text>
        </HStack>
        <Text color="textLabel" fontSize="20px" userSelect="none">
          {hideBalance ? maskNumber(8) : trimCryptoAmountLength(cryptoTotalBalance, 9)} {cryptoCurrency.ticker}
        </Text>
        <HStack
          ref={buttonsContainerRef}
          mt="24px"
          pb="40px"
          justifyContent="space-between"
          borderBottomWidth="1px"
          borderColor="gray.100">
          <ActionGuard>
            {({ openAlert }) => (
              <ButtonPurple
                _text={{ fontSize: 'md' }}
                h="34px"
                w={buttonsContainerRect.width / 2 - 13}
                onPress={openAlert || onDepositPress}>
                Deposit
              </ButtonPurple>
            )}
          </ActionGuard>
          <ActionGuard>
            {({ openAlert }) => (
              <OutlinedButton
                _text={{ fontSize: 'md' }}
                h="34px"
                w={buttonsContainerRect.width / 2 - 13}
                onPress={openAlert || onWithdrawPress}>
                Send
              </OutlinedButton>
            )}
          </ActionGuard>
        </HStack>
        <HStack my="24px" alignItems="center" justifyContent="space-between">
          <Text fontSize="2xl" color="white">
            Accounts
          </Text>
          <OutlinedButton onPress={onPressManageAccounts} h="24px">
            Manage accounts
          </OutlinedButton>
        </HStack>
      </VStack>
      <AssetList />
    </VStack>
  );
};

export default TotalBalance;
