import { ClientErrors } from '../client-errors';

export const processApiErrors = (error, setFormError) => {
  if (error?.response?.data?.message?.forEach) {
    error.response.data.message.forEach(({ property, constraints }) => {
      setFormError(property, {
        type: 'custom',
        message: Object.values(constraints).join('\n'),
      });
    });
  }
};

export const processFormGuardsApiErrors = (error, setFormError, handleUnknownError) => {
  if (error?.response?.data?.error) {
    switch (error.response.data.error) {
      case 'Invalid email otp': {
        setFormError('emailOtpToken', {
          type: 'custom',
          message: 'Incorrect OTP email code. Please try again',
        });
        return;
      }
      case 'Invalid totp': {
        setFormError('totpToken', {
          type: 'custom',
          message: ClientErrors.invalidTotp.title,
        });
        return;
      }
    }
  }

  handleUnknownError();
};
