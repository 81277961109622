import { HStack } from 'native-base';
import React from 'react';

import { WalletTransactionEntityDtoTypeEnum } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { Icon, useUserSettings } from '@cryptowallet/frontend/ui';
import { maskNumber, trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import TrxDetailsItem from '@cryptowallet/web/components/TrxDetailsItem';

const icons = {
  [WalletTransactionEntityDtoTypeEnum.Deposit]: 'recent-activity/deposit-arrow',
  [WalletTransactionEntityDtoTypeEnum.Withdrawal]: 'recent-activity/withdraw-arrow',
};

const WalletTrxDetails = ({ data }) => {
  const {
    cryptoAmount,
    asset: { currency, image },
    walletTransaction: { type },
  } = data;

  const { hideBalance } = useUserSettings();

  return (
    <HStack alignItems="center">
      <Icon w="9px" mr="6px" h="16px" name={icons[type]} />
      <TrxDetailsItem
        image={image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE}
        label={currency.label}
        amount={hideBalance ? maskNumber(8) : trimCryptoAmountLength(cryptoAmount, 9)}
        ticker={currency.ticker}
      />
    </HStack>
  );
};

export default WalletTrxDetails;
