import { useMutation } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { Controller, useForm } from 'react-hook-form';

import { useChangeEmailStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, LabeledInput, useUser } from '@cryptowallet/frontend/ui';
import { processApiErrors } from '@cryptowallet/frontend/utils';
import { userApi } from '@cryptowallet/web/api-client';
import { TabContentLayout } from '@cryptowallet/web/components/layout';
import { IStepComponentProps } from '@cryptowallet/web/components/Stepper';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

interface IFormProps {
  email: string;
}

const EnterNewEmail = ({ onStepCompleted }: IStepComponentProps): JSX.Element => {
  const { user } = useUser();
  const { newEmail, setNewEmail } = useChangeEmailStore(
    state => ({ newEmail: state.newEmail, setNewEmail: state.setNewEmail }),
    shallow,
  );
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm<IFormProps>({
    defaultValues: {
      email: newEmail,
    },
    mode: 'onChange',
  });

  const requestOtpMutation = useMutation(({ email }: IFormProps) => userApi.usersControllerRequestOtp({ email }));

  const onSubmit = async ({ email }) => {
    try {
      await requestOtpMutation.mutateAsync({ email });

      setNewEmail(email);
      onStepCompleted();
    } catch (err) {
      processApiErrors(err, setError);
    }
  };

  return (
    <>
      <TabContentLayout>
        <form>
          <FormHeading mb="26px" text="Enter New Email" />

          <Controller
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: 'Incorrect email. Please try again',
              },
              // eslint-disable-next-line @typescript-eslint/no-shadow
              validate: (newEmail): true | string => {
                if (newEmail === user.email) {
                  return 'You need to use a different email';
                }

                return true;
              },
            }}
            render={({ field: { onChange, ref, ...field }, fieldState: { error, isTouched } }) => (
              <LabeledInput
                error={isTouched && error}
                onChangeText={onChange}
                onSubmit={handleSubmit(onSubmit)}
                {...field}
                label="New email"
                labelTextProps={{
                  color: 'textLabel',
                  fontSize: 'lg',
                }}
                placeholder="example@email.com"
                h="56px"
                fontSize="lg"
              />
            )}
            name="email"
          />
        </form>
      </TabContentLayout>

      <BottomPurpleButton isDisabled={!isValid} size="lg" onPress={handleSubmit(onSubmit)}>
        Confirm
      </BottomPurpleButton>
    </>
  );
};

export default EnterNewEmail;
