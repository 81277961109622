import snsWebSdk from '@sumsub/websdk';

import { securityApi } from '@cryptowallet/web/api-client';

export async function getNewAccessToken(permissionType, onError): Promise<string | undefined> {
  try {
    const { data } = await securityApi.securityControllerRequestPermissionVerification(permissionType);

    return data.accessToken; // get a new token from your backend
  } catch (err) {
    onError(`Something went wrong please try again later`);

    return undefined;
  }
}

export function initIframe(
  permissionType,
  accessToken: string,
  { onSuccess, customI18nMessages = {} }: { onSuccess: () => void; customI18nMessages?: any },
  onError: (message?: string) => void,
) {
  const snsWebSdkInstance = snsWebSdk
    .init(
      accessToken,
      // token update callback, must return Promise
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      () => getNewAccessToken(permissionType, onError),
    )
    .withConf({
      lang: 'en', // language of WebSDK texts and comments (ISO 639-1 format)
      i18n: customI18nMessages, // JSON of custom SDK Translations
      uiConf: {
        // customCss: 'https://url.com/styles.css',
        // URL to css file in case you need change it dynamically from the code
        // the similar setting at Customizations tab will rewrite customCss
        // you may also use to pass string with plain styles `customCssStr:`
      },
    })
    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    // see below what kind of messages WebSDK generates

    .on('idCheck.applicantStatus', (payload: any) => {
      if (payload.reviewStatus === 'completed' && payload.reviewResult.reviewAnswer === 'GREEN') {
        // give some time for webhook to run
        onSuccess();
      }
    })
    .on('idCheck.onError', error => {
      console.log('onError', error);
    })
    .build();

  // you are ready to go:
  // just launch the WebSDK by providing the container element for it
  snsWebSdkInstance.launch('#sumsub-websdk-container');
}
