import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Divider, HStack, Image, Text, VStack } from 'native-base';
import QRCodeLib from 'qrcode';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo, useState } from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { UserPaymentSentRequestDto } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { useScreenSize, useSellExchange } from '@cryptowallet/frontend/hooks';
import { useSellFlowStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, ButtonPurple, SecondsCountdown, useAlerts } from '@cryptowallet/frontend/ui';
import CopyBox from '@cryptowallet/web/components/CopyBox';

import SellPaymentInfo from './SellPaymentInfo';
import SellTransactionDetails from './SellTransactionDetails';

const SellPayment = () => {
  const { isMobile } = useScreenSize();
  const { showErrorToaster } = useAlerts();
  const queryClient = useQueryClient();
  const { fromCurrency, amount } = useSellFlowStore(
    state => ({
      fromCurrency: state.fromCurrency,
      amount: state.amount,
    }),
    shallow,
  );
  const [qrImage, setQrImage] = useState('');
  const { payIn, data: exchangeResponse } = useSellExchange();
  const expireTime = useMemo(() => {
    if (exchangeResponse?.data?.createdAt) {
      const createdAt = new Date(exchangeResponse?.data?.createdAt);
      const expireAt = new Date(createdAt);
      expireAt.setDate(expireAt.getDate() + 1);

      return Number(expireAt);
    }

    return 0;
  }, [exchangeResponse?.data?.createdAt]);

  const mutation = useMutation((data: UserPaymentSentRequestDto) =>
    exchangerApi.exchangerControllerUserPaymentSent(data),
  );

  const onPaymentSent = async () => {
    try {
      const exchangeId = exchangeResponse?.data.id;
      if (exchangeId) {
        await mutation.mutateAsync({ exchangeId });
        queryClient.invalidateQueries(['exchange', exchangeId]);
      }
    } catch (e) {
      showErrorToaster({ title: `Can't update exchange, please try again later` });
    }
  };

  const cryptoDepositAddress = payIn?.userDepositAddress;
  useEffect(() => {
    if (!cryptoDepositAddress) {
      return;
    }
    QRCodeLib.toDataURL(cryptoDepositAddress).then(setQrImage);
  }, [cryptoDepositAddress]);

  return (
    <>
      <Box mt="30px" px="26px">
        <Text fontSize="22px" color="white">
          Please send the funds you want to sell
        </Text>
        <Text mt="8px" mb="32px" fontSize="lg" color="textRegular">
          After you send funds, the exchange will proceed automatically.
        </Text>
        <VStack mb="32px">
          <Box borderWidth={{ base: '0', md: '1px' }} borderColor="gray.100" borderRadius="10px">
            <HStack flexDirection={{ base: 'column', md: 'row' }}>
              <Box
                mb={{ base: '16px', md: '0' }}
                p={{ base: '0', md: '16px' }}
                w={{ base: '100%', md: payIn?.destinationTag ? '50%' : '100%' }}
                borderRightWidth={{ base: '0', md: '1px' }}
                borderRightColor={payIn?.destinationTag ? 'gray.100' : 'transparent'}>
                <CopyBox
                  title={
                    <Text color="textLabel" fontSize="xl">
                      Amount to send
                    </Text>
                  }
                  value={amount}
                  valueStyles={{ fontSize: 'xl' }}>
                  <HStack alignItems="center">
                    <Image
                      source={{
                        uri: fromCurrency.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
                      }}
                      alt={fromCurrency.label}
                      h="20px"
                      w="20px"
                      mr="10px"
                    />
                    <Text color="textRegular" fontSize="xl" lineHeight="22px">
                      {amount} {fromCurrency.ticker}
                    </Text>
                  </HStack>
                </CopyBox>
              </Box>
              {payIn?.destinationTag && (
                <Box mb={{ base: '16px', md: '0' }} p={{ base: '0', md: '16px' }} w={{ base: '100%', md: '50%' }}>
                  <CopyBox
                    title={
                      <Text color="textLabel" fontSize="xl">
                        Destanation tag
                      </Text>
                    }
                    value={payIn.destinationTag}
                    valueStyles={{ fontSize: 'xl' }}
                  />
                </Box>
              )}
            </HStack>
            <Box p={{ base: '0', md: '16px' }} borderTopWidth={{ base: '0', md: '1px' }} borderTopColor="gray.100">
              <CopyBox
                title={
                  <Text color="textLabel" fontSize="xl">
                    Deposit to this address for exchange
                  </Text>
                }
                valueStyles={{ whiteSpace: 'pre-wrap', fontSize: 'xl' }}
                value={cryptoDepositAddress}
              />
            </Box>
          </Box>
        </VStack>
        <HStack
          position="relative"
          p={{ base: '26px', md: '32px' }}
          pl={{ base: '26px', md: '214px' }}
          bg="black"
          borderRadius="10px"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'unset' }}>
          <VStack
            position={{ base: 'relative', md: 'absolute' }}
            top={{ base: 'unset', md: '32px' }}
            left={{ base: 'unset', md: '32px' }}
            w="150px"
            h="210px"
            justifyContent="space-between"
            alignItems={{ base: 'center', md: 'unset' }}>
            <Box w="100%" mr={{ base: '0', md: '32px' }}>
              {qrImage && (
                <Image
                  source={{
                    uri: qrImage,
                  }}
                  alt="wallet qr address"
                  w="150px"
                  h="150px"
                />
              )}
            </Box>
            {expireTime && (
              <SecondsCountdown
                expireTime={expireTime}
                renderer={({ expiresIn }) => {
                  const date = new Date(null);
                  date.setSeconds(Number(expiresIn));
                  const value = date.toISOString().substr(11, 8);

                  return (
                    <HStack mt="16px" borderRadius="10px" w="100%" bg="background.400" py="4px" justifyContent="center">
                      <Text lineHeight="26px" fontSize="20px" color="white">
                        {value}
                      </Text>
                    </HStack>
                  );
                }}
              />
            )}
          </VStack>
          <VStack
            justifyContent="space-between"
            h={{ base: 'unset', md: '210px' }}
            w="100%"
            mt={{ base: '16px', md: '0' }}>
            <Box>
              <Text mb="8px" fontSize="lg" textAlign={{ base: 'center', md: 'left' }}>
                Scan the QR code with your wallet app to quickly send {amount} {fromCurrency.ticker} for exchange.
              </Text>
              <Text fontSize="lg" textAlign={{ base: 'center', md: 'left' }}>
                The code will be active for 24 hours. After that, you will need to restart the Sell operation.
              </Text>
            </Box>
            <ButtonPurple
              mt={{ base: '24px', md: '16px' }}
              h={{ base: '40px', md: '34px' }}
              fontSize={{ base: 'sm', md: 'md' }}
              onPress={() => {
                const a = document.createElement('a');
                a.href = qrImage;
                a.download = 'wallet-qr-adress.png';
                a.click();
              }}>
              Download QR-code
            </ButtonPurple>
          </VStack>
        </HStack>
        {isMobile && <Divider mt="32px" orientation="horizontal" bg="gray.100" />}
        <SellPaymentInfo containerProps={{ mb: '0', mt: '32px' }} />
        <SellTransactionDetails dividerProps={{ mt: { base: '16px', md: '32px' } }} />
      </Box>
      <BottomPurpleButton size="lg" onPress={onPaymentSent}>
        Payment sent
      </BottomPurpleButton>
    </>
  );
};

export default SellPayment;
