import { Box, VStack } from 'native-base';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { RoundedBox, TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';
import NavigationItem, { INavigationItemProps, NavigationItemType } from '@cryptowallet/web/components/NavigationItem';
import NavigationReferralRewardsIcon from '@cryptowallet/web/icons/NavigationReferralRewardsIcon';
import NavigationSettings2faIcon from '@cryptowallet/web/icons/NavigationSettings2faIcon';
import NavigationSettingsChangeEmailIcon from '@cryptowallet/web/icons/NavigationSettingsChangeEmailIcon';
import NavigationSettingsDeactivateIcon from '@cryptowallet/web/icons/NavigationSettingsDeactivateIcon';
import NavigationSettingsLimitsIcon from '@cryptowallet/web/icons/NavigationSettingsLimitsIcon';
import NavigationSettingsSecurityIcon from '@cryptowallet/web/icons/NavigationSettingsSecurityIcon';

const basePath = '/account';

interface INavigationGroup {
  items: INavigationItemProps[];
}

const NavigationGroup = ({ items }: INavigationGroup) => {
  const location = useLocation();

  return (
    <Box mb="13px">
      <Box>
        {items.map(item => (
          <NavigationItem
            defaultTextColor="textRegular"
            key={item.id}
            active={location.pathname.indexOf(item.href) !== -1}
            settingsView={true}
            {...item}
          />
        ))}
      </Box>
    </Box>
  );
};

const Navigation = (): JSX.Element => {
  const { kycPoaHighRiskVerified } = useUserStatus();

  const navItems: INavigationGroup[] = useMemo(
    () =>
      [
        {
          id: 1,
          items: [
            {
              id: 'change-email',
              title: 'Change email',
              icon: ({ color }) => <NavigationSettingsChangeEmailIcon color={color} />,
              type: NavigationItemType.LINK,
              href: '/change-email',
            },
            {
              id: '2fa',
              title: '2-factor authentication',
              icon: ({ color }) => <NavigationSettings2faIcon color={color} />,
              type: NavigationItemType.LINK,
              href: '/2fa',
            },
            {
              id: 'limits',
              title: 'Limits',
              icon: ({ color }) => <NavigationSettingsLimitsIcon color={color} />,
              type: NavigationItemType.LINK,
              href: '/limits',
            },
            {
              id: 'security',
              title: 'Security center',
              icon: ({ color }) => <NavigationSettingsSecurityIcon color={color} />,
              type: NavigationItemType.LINK,
              href: '/security',
              hasNotification: !kycPoaHighRiskVerified,
            },
            {
              id: 'deactivate',
              title: 'Deactivate my account',
              icon: ({ color }) => <NavigationSettingsDeactivateIcon color={color} />,
              type: NavigationItemType.LINK,
              href: '/deactivate',
            },
          ],
        },
      ].map(group => {
        group.items.forEach(item => {
          if (item.href) {
            // eslint-disable-next-line no-param-reassign
            item.href = `${basePath}${item.href}`;
          }
        });

        return group;
      }),
    [kycPoaHighRiskVerified],
  );

  return (
    <RoundedBox position="absolute" top="0" left="0" width={{ base: '100%', xl: '384px' }} h="100%">
      <TabHeader>Account</TabHeader>
      <TabContentLayout p={{ base: '24px', lg: '16px' }} width="100%">
        <VStack>
          {navItems.map(group => (
            <NavigationGroup key={group.id} {...group} />
          ))}
        </VStack>
      </TabContentLayout>
    </RoundedBox>
  );
};

export default Navigation;
