import { IButtonProps } from 'native-base';

import { Button } from '@cryptowallet/frontend/ui';

export const OutlinedButton = ({ children, ...props }: IButtonProps) => (
  <Button
    variant="unstyled"
    outlineWidth="0"
    bg="transparent"
    borderWidth="1px"
    borderColor="gray.300"
    _text={{
      color: 'textRegular',
    }}
    _hover={{
      bg: 'gray.300',
      borderColor: 'transparent',
      _text: {
        color: 'white',
      },
    }}
    _focus={{
      borderColor: 'secondary.400',
    }}
    _disabled={{
      _text: {
        color: 'textDisabled',
      },
      borderColor: 'gray.300',
      opacity: 1,
    }}
    {...props}>
    {children}
  </Button>
);
