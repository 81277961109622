import React from 'react';

const NavigationReferralIcon = ({ color }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75 4.42919C8.75 6.84544 6.79125 8.80419 4.375 8.80419C1.95876 8.80419 1.18742e-06 6.84544 7.64949e-07 4.4292C3.42479e-07 2.01295 1.95875 0.0541989 4.375 0.0541985C6.79125 0.054198 8.75 2.01295 8.75 4.42919Z"
      fill={color}
    />
    <path
      d="M8.75 15.6792C8.75 18.0954 6.79125 20.0542 4.375 20.0542C1.95876 20.0542 3.15443e-06 18.0954 2.73196e-06 15.6792C2.30949e-06 13.2629 1.95876 11.3042 4.375 11.3042C6.79125 11.3042 8.75 13.2629 8.75 15.6792Z"
      fill={color}
    />
    <path
      d="M20 15.6792C20 18.0954 18.0412 20.0542 15.625 20.0542C13.2088 20.0542 11.25 18.0954 11.25 15.6792C11.25 13.2629 13.2088 11.3042 15.625 11.3042C18.0412 11.3042 20 13.2629 20 15.6792Z"
      fill={color}
    />
    <path
      d="M15 0.679196C15 0.334019 15.2798 0.0541965 15.625 0.0541965C15.9702 0.0541964 16.25 0.334018 16.25 0.679196L16.25 8.17919C16.25 8.52437 15.9702 8.80419 15.625 8.80419C15.2798 8.80419 15 8.52437 15 8.17919L15 0.679196Z"
      fill={color}
    />
    <path
      d="M19.375 3.80419C19.7202 3.80419 20 4.08401 20 4.42919C20 4.77437 19.7202 5.05419 19.375 5.05419L11.875 5.05419C11.5298 5.05419 11.25 4.77437 11.25 4.42919C11.25 4.08402 11.5298 3.80419 11.875 3.80419L19.375 3.80419Z"
      fill={color}
    />
  </svg>
);

export default NavigationReferralIcon;
