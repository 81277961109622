import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Divider, HStack, Text, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useState } from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { ExchangerExchangeResponseDto, UserPaymentSentRequestDto } from '@cryptowallet/frontend/client';
import { useAssets } from '@cryptowallet/frontend/hooks';
import { useBuyFlowStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, PressableCheckbox, useAlerts } from '@cryptowallet/frontend/ui';
import { getCurrencySymbol } from '@cryptowallet/frontend/utils';
import CopyBox from '@cryptowallet/web/components/CopyBox';
import SectionTitle from '@cryptowallet/web/components/SectionTitle';

import BuyTransactionDetails from '../BuyTransactionDetails';
import PaymentInfo from '../PaymentInfo';

const importantNotes = [
  'Please use the reference number while performing the bank transfer. Payments without reference will be canceled.',
  'Pay from your personal bank account. Payments from other people’s accounts will be canceled.',
];

const SepaPayment = ({ exchange }: { exchange: ExchangerExchangeResponseDto }) => {
  const { buyFromCurrencies } = useAssets();
  const { showErrorToaster } = useAlerts();
  const queryClient = useQueryClient();
  const { isPersonalAccount, setIsPersonalAccount, exchangeId } = useBuyFlowStore(
    state => ({
      isPersonalAccount: state.isPersonalAccount,
      setIsPersonalAccount: state.setIsPersonalAccount,
      exchangeId: state.exchangeId,
    }),
    shallow,
  );

  const [error, setError] = useState(null);

  const { fromExchangerAssetId: fromCurrencyId, payIn: details, fromAmount, expectedFromAmount } = exchange || {};

  const fromCurrency = buyFromCurrencies.find(item => item.id === fromCurrencyId);
  const symbol = getCurrencySymbol(fromCurrency?.ticker);
  const amount = fromAmount || expectedFromAmount;

  const mutation = useMutation((data: UserPaymentSentRequestDto) =>
    exchangerApi.exchangerControllerUserPaymentSent(data),
  );

  const onSubmit = async () => {
    try {
      if (isPersonalAccount) {
        await mutation.mutateAsync({ exchangeId: exchange.id });
        await queryClient.invalidateQueries(['exchange', exchangeId]);
      } else {
        setError({ message: 'Confirm field is required' });
      }
    } catch (e) {
      showErrorToaster({ title: `Can't update exchange, please try again later` });
    }
  };

  const onChangeIsPersonalAcc = () => {
    if (error) {
      setError(null);
    }
    setIsPersonalAccount(!isPersonalAccount);
  };

  return (
    <VStack>
      <VStack px="24px">
        <SectionTitle fontSize="22px">Perform the Payment</SectionTitle>
        <Text color="textRegular" mt="10px" fontSize="lg">
          In order to make the bank transfer, please manually fill out the bank details below.
        </Text>
      </VStack>
      <Divider my="40px" orientation="horizontal" bg="gray.100" />
      <VStack px="24px">
        <Box>
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                Recipient name
              </Text>
            }
            value={details?.toName}
          />
        </Box>
        <Box mt="20px">
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                Use this reference
              </Text>
            }
            value={details?.reference}
          />
        </Box>
        <Box mt="20px">
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                IBAN account
              </Text>
            }
            value={details?.toIban}
          />
        </Box>
        <Box mt="20px">
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                Amount
              </Text>
            }
            value={`${symbol} ${amount}`}
          />
        </Box>
      </VStack>
      <Divider my="40px" orientation="horizontal" bg="gray.100" />
      <VStack px="24px">
        <SectionTitle fontSize="22px">Important Notes</SectionTitle>
        <VStack>
          {importantNotes.map(text => (
            <HStack key={text} mt="16px">
              <Box h="8px" w="8px" bg="primary.400" mt="8px" mr="8px" borderRadius="8px" />
              <Text color="textRegular" fontSize="lg">
                {text}
              </Text>
            </HStack>
          ))}
          <Box mt="30px">
            <PressableCheckbox error={error} isChecked={isPersonalAccount} onChange={onChangeIsPersonalAcc}>
              <Text fontSize="lg" color="textRegular" lineHeight="32px" userSelect="none" ml="16px">
                I confirm that I’m sending money from my own account
              </Text>
            </PressableCheckbox>
          </Box>
        </VStack>
        <PaymentInfo mb="0" />
        <BuyTransactionDetails />
      </VStack>
      <BottomPurpleButton isDisabled={mutation.isLoading} size="lg" onPress={onSubmit}>
        Payment sent
      </BottomPurpleButton>
    </VStack>
  );
};

export default SepaPayment;
