import { IPressableProps, Pressable as NBPressable } from 'native-base';
import React from 'react';

import { setPreventFocusAttribute } from '@cryptowallet/frontend/utils';

const Pressable = React.forwardRef<HTMLElement, IPressableProps>(({ children, ...rest }, forwardedRef) => (
  <NBPressable
    ref={r => {
      setPreventFocusAttribute(r);
      if (forwardedRef) {
        // eslint-disable-next-line no-param-reassign
        forwardedRef.current = r;
      }
    }}
    cursor="pointer"
    {...rest}>
    {({ isPressed, isHovered, isFocused }) =>
      typeof children !== 'function'
        ? children
        : children({
            isPressed,
            isHovered,
            isFocused,
          })
    }
  </NBPressable>
));

export default Pressable;
