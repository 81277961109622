import { Box, HStack, Text, VStack } from 'native-base';
import React from 'react';

import { BottomPurpleButton, Icon } from '@cryptowallet/frontend/ui';

import useStore, { WhitelistRegisterResult } from '../store';

const data = {
  [WhitelistRegisterResult.SUCCESS]: {
    title: 'Place Reserved',
    description: 'Congratulations and welcome to the Whitelist! The chance to order a CryptoWallet card is now yours!',
    warning:
      'Join our Discord to participate in member-only lotteries with prizes, chat with the team, and most importantly – not to miss our launch!',
  },
  [WhitelistRegisterResult.DUPLICATE]: {
    title: 'You have already reserved your spot',
    description: 'You are on the Whitelist already! You can apply only once.',
    warning:
      'Join our Discord to participate in member-only lotteries with prizes, chat with the team, and most importantly – not to miss our launch!',
  },
};

const WhitelistRegistrationResult = ({ result }: { result: WhitelistRegisterResult }) => {
  const { title, description, warning } = data[result];

  return (
    <Box w="588px" maxW="100vw">
      <VStack
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        borderWidth="1px"
        borderColor="gray.200"
        bg="white"
        borderBottomWidth="0">
        <Box borderColor="gray.200" borderBottomWidth="1px" py="26px" px="32px">
          <HStack justifyContent="space-between" alignItems="center">
            <HStack alignItems="center">
              <Icon name="alerts/alert-success--green" h="20px" w="20px" mr="8px" />
              <Text color="black" fontSize="20px">
                {title}
              </Text>
            </HStack>
          </HStack>
        </Box>
        <Box p="0">
          <VStack p="32px">
            <Text fontSize="lg" color="textGray">
              {description}
            </Text>
            <HStack
              mt="16px"
              py="18px"
              px="16px"
              borderWidth="1px"
              borderColor="secondary.400:alpha.50"
              borderRadius="5px">
              <Icon name="alerts/alert-warning--orange" h="20px" w="20px" mr="8px" />
              <Text fontSize="md" color="black">
                {warning}
              </Text>
            </HStack>
          </VStack>
        </Box>
      </VStack>
      <BottomPurpleButton onPress={() => window.open('https://discord.gg/vgy7GZKw2t', '_blank')}>
        Join Discord
      </BottomPurpleButton>
    </Box>
  );
};

export default WhitelistRegistrationResult;
