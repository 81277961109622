import { Text, VStack } from 'native-base';
import React from 'react';

import { BottomPurpleButton, Icon } from '@cryptowallet/frontend/ui';

import WithdrawPaymentInfo from './WithdrawPaymentInfo';
import WithdrawTransactionDetails from './WithdrawTransactionDetails';

const AssetWithdrawResult = ({ onClose }) => (
  <VStack>
    <VStack px="24px">
      <VStack mt="32px" mb="40px" alignItems="center" pb="24px" borderBottomWidth="1px" borderBottomColor="gray.100">
        <Icon name="success" h="80px" w="80px" />
        <Text color="white" mt="24px" fontSize="2xl">
          Success
        </Text>
        <Text color="textRegular" mt="8px" fontSize="lg">
          Your withdrawal has completed successfully!
        </Text>
      </VStack>
      <WithdrawPaymentInfo />
      <WithdrawTransactionDetails />
    </VStack>
    <BottomPurpleButton onPress={onClose} size="lg">
      Back to Wallet
    </BottomPurpleButton>
  </VStack>
);

export default AssetWithdrawResult;
