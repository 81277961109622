import { Box, HStack, Image, Text, useToken } from 'native-base';
import React from 'react';

import { AssetEntityDtoTypeEnum, WalletResourceEntityDto } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { BoldCircleAddIcon, CurrencyNetwork, Pressable } from '@cryptowallet/frontend/ui';
import { getNetworkData } from '@cryptowallet/frontend/utils';

interface IAddableAssetItemProps {
  item: WalletResourceEntityDto;
  index: number;
  onPress: (currencyId: string) => void;
}

const AddableAssetItem = ({ item, onPress }: IAddableAssetItemProps) => {
  const { currency, network, image, type } = item.asset;
  const [textDisabled, white] = useToken('colors', ['textDisabled', 'white']);

  const { color: networkColor } = getNetworkData(network?.ticker);
  const isCryptoCoin = type === AssetEntityDtoTypeEnum.CryptoCoin;

  return (
    <Pressable mb={{ base: '5px', md: '0' }} outlineWidth="0" onPress={() => onPress(item.walletAsset.id)}>
      {({ isHovered, isFocused }) => (
        <>
          <HStack
            h="45px"
            p="10px"
            borderRadius="10px"
            bg={isHovered || isFocused ? 'gray.300' : 'transparent'}
            alignItems="center"
            justifyContent="space-between">
            <HStack alignItems="center">
              <Image
                source={{
                  uri: image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
                }}
                alt={currency.label}
                h="36px"
                w="36px"
                mr="13px"
                borderRadius="36px"
              />
              <Text
                w={{ base: '230px', md: 'unset' }}
                position={{ base: 'absolute', md: 'relative' }}
                left={{ base: '49px', md: 'unset' }}
                top={{ base: '-4px', md: 'unset' }}
                fontSize="lg"
                color="textLabel">
                {currency.label} ({network.ticker})
              </Text>
            </HStack>
            <Box h="32px" w="32px" mr="3px">
              <BoldCircleAddIcon color={isFocused || isHovered ? white : textDisabled} />
            </Box>
          </HStack>
          {!!network?.label && !isCryptoCoin && (
            <CurrencyNetwork
              position="absolute"
              right={{ base: 'unset', md: '53px' }}
              left={{ base: '58px', md: 'unset' }}
              top={{ base: '22px', md: '14px' }}
              color={networkColor}
              label={network?.label}
            />
          )}
        </>
      )}
    </Pressable>
  );
};

export default AddableAssetItem;
