import { Box, IBoxProps, IPressableProps, StyledProps, Text } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { Icon, Pressable, usePartnerStyles } from '@cryptowallet/frontend/ui';
import { copyToClipboard } from '@cryptowallet/frontend/utils';

export enum CopyBoxVariant {
  DEFAULT = 'default',
  GRAYBOX = 'graybox',
}

interface ICopyBoxProps {
  title?: string | JSX.Element;
  value: string;
  variant?: CopyBoxVariant;
  valueStyles?: StyledProps;
  pressableProps?: IPressableProps;
  containerProps?: IBoxProps;
  ellipsis?: 'end' | 'middle';
  children?: JSX.Element;
}

const ValueMiddleEllipsis = ({ valueStyles, variant, value }) => {
  const endRef = useRef<Element>();

  const [endWidth, setEndWidth] = useState(0);

  const str = value || ''; // value can be null
  const firstPart = str.slice(0, -8);
  const lastPart = str.slice(-8);

  const generalStyles = {
    color: variant === CopyBoxVariant.GRAYBOX ? 'white' : 'textRegular',
    lineHeight: variant === CopyBoxVariant.GRAYBOX ? '16px' : '24px',
    fontSize: variant === CopyBoxVariant.GRAYBOX ? 'md' : 'lg',
    userSelect: 'none',
  };

  useEffect(() => {
    if (endRef?.current) {
      const { width } = endRef.current.getBoundingClientRect();
      setEndWidth(width);
    }
  }, [endRef]);

  return (
    <Box w="calc(100% - 40px)" flexDirection="row">
      <Text
        {...generalStyles}
        {...valueStyles}
        maxW={`calc(100% - ${endWidth}px)`}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis">
        {firstPart}
      </Text>
      <Text ref={endRef} {...generalStyles} {...valueStyles}>
        {lastPart}
      </Text>
    </Box>
  );
};

const ValueEndEllipsis = ({ valueStyles, variant, value }) => (
  <Text
    overflow="hidden"
    color={variant === CopyBoxVariant.GRAYBOX ? 'white' : 'textRegular'}
    lineHeight={variant === CopyBoxVariant.GRAYBOX ? '16px' : '24px'}
    fontSize={variant === CopyBoxVariant.GRAYBOX ? 'md' : 'lg'}
    maxW="100%"
    whiteSpace="nowrap"
    textOverflow="ellipsis"
    {...valueStyles}>
    {value}
  </Text>
);

const ValueComponents = {
  end: ValueEndEllipsis,
  middle: ValueMiddleEllipsis,
};

const CopyBox = ({
  title,
  value,
  variant = CopyBoxVariant.DEFAULT,
  valueStyles = {},
  pressableProps = {},
  containerProps = {},
  ellipsis = 'end',
  children = null,
}: ICopyBoxProps): JSX.Element => {
  const partnerStyles = usePartnerStyles('CopyBox');
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = (): void => {
    copyToClipboard(value);
    setCopied(true);
  };

  useEffect(() => {
    if (!copied) {
      return;
    }

    setTimeout(() => {
      setCopied(false);
    }, commonConstants.NOTIFICATION_TIMEOUT);
  }, [copied]);

  const ValueComponent = ValueComponents[ellipsis];

  return (
    <Box {...containerProps} position="relative">
      {!!title && <Text>{title}</Text>}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={variant === CopyBoxVariant.GRAYBOX ? '6px' : '0'}
        w={variant === CopyBoxVariant.GRAYBOX ? '100%' : 'auto'}
        py={variant === CopyBoxVariant.GRAYBOX ? '18px' : 'unset'}
        px={variant === CopyBoxVariant.GRAYBOX ? '24px' : 'unset'}
        bg={variant === CopyBoxVariant.GRAYBOX ? 'gray.200' : 'transparent'}
        borderRadius={variant === CopyBoxVariant.GRAYBOX ? '10px' : 'unset'}>
        {!!children && children}
        {!children && <ValueComponent variant={variant} value={value} valueStyles={valueStyles} />}
        {value && (
          <Box h="20px" w="40px" position="relative">
            <Pressable
              onPress={onCopy}
              position="absolute"
              top={variant === CopyBoxVariant.GRAYBOX ? '0' : '-10px'}
              right="0"
              h="20px"
              w="20px"
              alignItems="center"
              justifyContent="center"
              outlineWidth="0"
              {...pressableProps}>
              {({ isFocused, isHovered }) => {
                let iconName = partnerStyles.defaultIcon || 'copy--default';

                if (copied) {
                  iconName = 'success-copy';
                } else if (isFocused) {
                  iconName = partnerStyles.focusedIcon || 'copy--focused';
                } else if (isHovered) {
                  iconName = partnerStyles.hoveredIcon || 'copy--hovered';
                }
                const partnerIconProps = partnerStyles.defaultIcon && !copied ? { source: { uri: iconName } } : {};

                return (
                  <>
                    <Icon
                      name={iconName}
                      h={!copied ? '100%' : '8px'}
                      w={!copied ? '100%' : '12px'}
                      {...partnerIconProps}
                    />
                    {copied && (
                      <Text fontSize="12px" color="textSuccess">
                        Copied
                      </Text>
                    )}
                  </>
                );
              }}
            </Pressable>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CopyBox;
