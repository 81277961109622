import { Box, HStack, Modal, Text, VStack } from 'native-base';
import React from 'react';

import { CloseButton, Icon, ModalBase } from '../../../index';

const data = [
  {
    list: [
      'Cookie is a small text file placed on your computer by websites that you visit.',
      'They are widely used in order to make websites work more efficiently, as well as to provide information about functionality and behavior of the users on the site.',
      'Cookie helps you to better interact with a website and helps a website to better interact with you.',
      'Cookies do not contain your personal data.',
      {
        title: '1. CryptoWallet.com uses cookies, tags (together – Cookie) to:',
        subList: [
          'understand how you use website and to provide you with a safe, effective and user-friendly website;',
          'remember your settings, such as preferred language, type of the device, news already shown to you, your preference about Cookie allowance;',
          'keep opened sessions and conversation during your last visit to the website;',
          'identify the popularity of different sections of the website and improve them respectively;',
          'prevent and exclude unauthorized use of the website.',
        ],
      },
      '2. CryptoWallet.com normally does not associate your account with your Cookie, however in exceptional cases we may use this option to prevent or avoid unauthorized use of the website and violation of applicable agreements between you and us. In this case we will treat this information as personal and process as described in the Privacy Policy.',
      '3. CryptoWallet.com uses third party web analytics services that assist us in better understanding of our website use, those services are complied with the prescribed adequacy of security measures. For example, we use Google Analytics service powered by Google LLC (US). This service helps us to improve the effectiveness of our advertising efforts and understand your preferences and interests. It collects information and reports website usage statistics without personally identifying you – we use it for better delivery of our ads. Please check the details about Google Analytics services here: https://policies.google.com/technologies/partner-sites. However, in some cases Google can match information collected through Cookie with your Google account and use it under Google Privacy Policy explained here: https://policies.google.com/privacy.',
      '4. If you do not want to share your Cookie you may restrict it or block it.',
      '4.1. If you prefer not to allow cookies, please use your browser settings, most browsers give you an ability to manage your cookies or provide you with “incognito mode” or similar options.',
      'For more information about how to disable cookies completely or how to amend your cookie settings check https://www.allaboutcookies.org.',
      {
        title:
          '5. CryptoWallet.com also uses similar technology and collect unique identifiers of our visitors of the website, the information we collect by this means includes:',
        subList: [
          'Unique identification numbers, e.g. Internet protocol (IP) address and device ID;',
          'Your login information;',
          'As applicable to your type of device, browser type and version, and browser plug-in types and versions, application release and version, device model, manufacturer (brand), operating system, operating system version, OS library version, time of last seen, enable wifi, radio (EDGE, HSDPA, LTE, WCDMA, none), device screen (width, high, dpi), NFC, telecom operator, Bluetooth enable, Bluetooth version;',
          'Time zone setting, city, region, country and language;',
          'Operating system and platform;',
          'Information about your visit, including the full Uniform Resource Locators (URL) click stream to, through and from our website (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page.',
        ],
      },
      '5.1. We use this information for the same purposes as a Cookie.',
      '6. Please note that CryptoWallet.com may amend this Cookie Policy from time to time at our sole discretion. Therefore, please check this Cookie Policy for updates. If any significant updates with regard to the data processing terms are made here, we will notify you additionally within a reasonable time period via email provided by you.',
    ],
  },
];

export const CookiesPolicyAlert = ({ onClose }: { onClose: () => void }) => (
  <ModalBase blurredBg={true} onClose={onClose}>
    <Modal.Content w="996px" maxW="100vw" overflowY="auto">
      <VStack p="40px" bg="background.400">
        <Modal.Header p="0" borderColor="gray.200" borderBottomWidth="0">
          <HStack justifyContent="space-between" alignItems="center" pr="26px">
            <Text color="white" fontSize="3xl">
              Cookie Policy
            </Text>
            <CloseButton
              position="absolute"
              top="4px"
              right="0"
              iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }}
              onPress={onClose}
            />
          </HStack>
        </Modal.Header>
        <Modal.Body p="0">
          {data.map(({ title, list, subTitle }, i) => (
            <Box key={i}>
              {title && (
                <Text mt="20px" mb="19px" color="white" fontSize="20px">
                  {title}
                </Text>
              )}
              {subTitle && (
                <Text mt="20px" mb="19px" color="white" fontSize="lg">
                  {subTitle}
                </Text>
              )}
              <VStack>
                {list.map((item, subIndex) => {
                  if (typeof item === 'string') {
                    return (
                      <VStack key={subIndex} mb="6px" pl="18px">
                        <Text color="textRegular" fontSize="lg">
                          {item}
                        </Text>
                      </VStack>
                    );
                  }

                  return (
                    <VStack key={subIndex} mb="6px" pl="18px">
                      <Text color="textRegular" fontSize="lg">
                        {item.title}
                      </Text>
                      {item.subList.map((subText, index) => (
                        <VStack key={`${subIndex}-${index}`} mb="6px" pl="36px">
                          <Icon position="absolute" top="4px" left="18px" h="10px" w="12px" name="check-icon-small" />
                          <Text color="textRegular" fontSize="lg">
                            {subText}
                          </Text>
                        </VStack>
                      ))}
                    </VStack>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </Modal.Body>
      </VStack>
    </Modal.Content>
  </ModalBase>
);
