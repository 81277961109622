import { Box, IBoxProps } from 'native-base';

import { BackButton } from '../Buttons';

interface ITabHeaderProps extends IBoxProps {
  onGoBack?: () => void;
}

const TabHeader = ({ children, onGoBack, ...rest }: ITabHeaderProps) => (
  <Box
    p="32px"
    px={{ base: '24px', md: '32px' }}
    alignItems="center"
    flexDirection="row"
    _text={{ color: 'white', fontSize: '2xl' }}
    borderBottomWidth="1px"
    borderBottomColor="gray.100"
    {...rest}>
    {!!onGoBack && <BackButton mr="16px" onPress={onGoBack} />}
    {children}
  </Box>
);

export default TabHeader;
