import { commonConstants } from '@cryptowallet/frontend/constants';
import { useAccountsStore, useBuyFlowStore, useSellFlowStore } from '@cryptowallet/frontend/stores';

const resetSelector = state => state.resetPersistedSlice;

export const useResetPersistStates = () => {
  const resetBuyStore = useBuyFlowStore(resetSelector);
  const resetSellStore = useSellFlowStore(resetSelector);
  const resetAccountsStore = useAccountsStore(state => state.reset);

  const resetPersistStates = () => {
    resetBuyStore();
    resetSellStore();
    resetAccountsStore();
    Object.values(commonConstants.PersistKeys).forEach(key => window.localStorage.removeItem(key));
  };

  return { resetPersistStates };
};
