/* eslint-disable sonarjs/no-duplicate-string */
import { HStack, IBoxProps } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPurple, ButtonSecondary, Icon } from '@cryptowallet/frontend/ui';

import SecurityBlockContainer from './SecurityBlockContainer';
import SecurityBlockSubTitle from './SecurityBlockSubTitle';
import SecuritySubBlock from './SecuritySubBlock';

interface InfoItem {
  text: string;
  icon: { name: string; h?: string; w?: string };
}

interface ISecurityLevelProps {
  onPressStart: () => void;
  title: string;
  containerProps?: IBoxProps;
  requires: InfoItem[];
  unblocks: InfoItem[];
  disabled?: boolean;
  completed?: boolean;
  showLimitsBtn?: boolean;
}

const SecurityLevel = ({
  onPressStart,
  title,
  requires,
  unblocks,
  disabled = false,
  completed = false,
  showLimitsBtn = false,
  containerProps = {},
}: ISecurityLevelProps) => {
  const navigate = useNavigate();

  return (
    <SecurityBlockContainer {...containerProps}>
      <HStack alignItems="center" mb="20px" justifyContent="space-between">
        <SecurityBlockSubTitle>{completed ? `${title}: Completed` : title}</SecurityBlockSubTitle>
        {completed && <Icon name="checked-icon" h="22px" w="22px" />}
        {!completed && (
          <ButtonPurple
            isDisabled={disabled}
            _text={{ fontSize: 'md' }}
            onPress={onPressStart}
            h="30px"
            py="8px"
            px="16px"
            size="lg">
            Start
          </ButtonPurple>
        )}
      </HStack>
      {!completed && (
        <HStack>
          <SecuritySubBlock title="Requires:" list={requires} containerProps={{ w: '50%' }} />
          <SecuritySubBlock title="Unblocks" list={unblocks} containerProps={{ w: '50%' }} />
        </HStack>
      )}
      {showLimitsBtn && (
        <ButtonSecondary w="85px" h="24px" _text={{ fontSize: 'sm' }} onPress={() => navigate('/account/limits')}>
          Go to limits
        </ButtonSecondary>
      )}
    </SecurityBlockContainer>
  );
};

export default SecurityLevel;
