import { useQuery } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';

import { walletsApi } from '@cryptowallet/frontend/api';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';

const useSelectedWalletAccount = () => {
  const { selectedWalletAccountId } = useAccountsStore(
    state => ({
      selectedWalletAccountId: state.selectedWalletAccountId,
      detailsPanelView: state.detailsPanelView,
      setDetailsPanelView: state.setDetailsPanelView,
    }),
    shallow,
  );

  const { isFetching: isFetchingSelectedWalletAccount, data: selectedWalletAccount } = useQuery(
    ['assetDetails', selectedWalletAccountId],
    () => walletsApi.walletsControllerGetWalletDetails(selectedWalletAccountId),
    { ...cacheRequestOption, enabled: !!selectedWalletAccountId },
  );

  return {
    isFetchingSelectedWalletAccount,
    selectedWalletAccount: selectedWalletAccount?.data?.walletDetails || null,
  };
};

export default useSelectedWalletAccount;
