/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query';
import { Box, Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { BottomPurpleButton, Select, Spinner } from '@cryptowallet/frontend/ui';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import { marketingApi } from '@cryptowallet/web/api-client';

import useStore from '../store';

const CountryForm = ({ onStepCompleted }) => {
  const containerRef = useRef();
  const { isMobile } = useScreenSize();
  const [country, setNewCountry] = useStore(state => [state.country, state.setNewCountry], shallow);
  const [countryDropdownHeight, setCountryDropdownHeight] = useState(200); // so dropdown height would be close to end of page, not more

  const { isLoading: isCountriesLoading, data } = useQuery(
    ['whitelistCountries'],
    () => marketingApi.marketingControllerGetWhitelistCountries(),
    cacheRequestOption,
  );

  const countries = useMemo(
    () =>
      [...(data?.data.countries || [])].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }

        return 0;
      }),
    [data?.data?.countries],
  );

  const onApply = ({ country }) => {
    const val = countries.find(item => item.id === country);
    setNewCountry(val.alpha3);
    onStepCompleted();
  };

  const { control, handleSubmit } = useForm({
    mode: 'onTouched',
    defaultValues: {
      country,
    },
  });

  useEffect(() => {
    if (!isMobile && containerRef?.current) {
      const { bottom: containerBottomY } = containerRef.current.getBoundingClientRect();
      const newDropdownHeight = window.innerHeight - 200 - containerBottomY;
      setCountryDropdownHeight(newDropdownHeight < 140 ? 140 : newDropdownHeight);
    }
  }, [containerRef, isMobile]);

  return (
    <Box w="365px" maxW="100vw">
      <Box ref={containerRef} justifyContent="center" h="165px" pt="26px" pb="7px" px="26px" zIndex={1}>
        {isCountriesLoading && <Spinner mb="20px" />}
        {!isCountriesLoading && (
          <>
            <Text mb="26px" fontSize="lg" color="textRegular">
              To start your whitelist verification, choose your country of residence
            </Text>
            <Controller
              control={control}
              rules={{
                validate: (val): boolean | string => !!val || 'Please select country',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  label="Country"
                  options={countries.map(item => ({
                    label: item.name,
                    id: item.id,
                  }))}
                  isLoading={isCountriesLoading}
                  onSelect={onChange}
                  error={error}
                  selected={value}
                  formControlProps={{ zIndex: 1 }}
                  dropdownContainerProps={{
                    maxH: {
                      base: 'calc(100vh - 90px)',
                      md: `${countryDropdownHeight}px`,
                    },
                  }}
                />
              )}
              name="country"
            />
          </>
        )}
      </Box>
      <BottomPurpleButton mt="14px" size="lg" onPress={handleSubmit(onApply)}>
        Apply
      </BottomPurpleButton>
    </Box>
  );
};

export default CountryForm;
