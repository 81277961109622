import { Box, HStack, Image, Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useSellExchange } from '@cryptowallet/frontend/hooks';
import { sellEstimatePlaceholder, useSellEstimate, useSellFlowStore } from '@cryptowallet/frontend/stores';
import CopyBox from '@cryptowallet/web/components/CopyBox';

const SellPaymentInfo = ({ showFullData = true, containerProps = {}, sellCompleted = false }) => {
  const [toCurrency, paymentMethod] = useSellFlowStore(state => [state.toCurrency, state.paymentMethod], shallow);

  const { data: exchangeResponse, estimateResult, completed } = useSellExchange(showFullData);
  const { data } = useSellEstimate(!completed);

  const transactionId = exchangeResponse?.data?.id; // todo alias
  const { iban, toFirstName: firstName, toLastName: lastName } = exchangeResponse?.data?.payOut || {};
  const estimateData = (data || sellEstimatePlaceholder)[paymentMethod];
  const estimate = sellCompleted ? estimateResult : estimateData;
  const isReady = !!toCurrency;

  if (!isReady) return null;

  return (
    <Box
      borderWidth={{ base: '0', md: '1px' }}
      borderColor="gray.100"
      borderRadius="10px"
      mb="32px"
      {...containerProps}>
      <HStack flexDirection={{ base: 'column', md: 'row' }}>
        <Box
          mb={{ base: '16px', md: '0' }}
          p={{ base: '0', md: '18px' }}
          w={{ base: '100%', md: '50%' }}
          borderRightWidth={showFullData ? { base: '0', md: '1px' } : '0'}
          borderRightColor="gray.100">
          <Text color="textLabel" fontSize="xl" lineHeight="20px">
            You will get
          </Text>
          <HStack alignItems="center">
            {estimate?.estimatedAmount ? (
              <>
                <Image
                  source={{
                    uri: toCurrency.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
                  }}
                  alt={toCurrency.label}
                  h="20px"
                  w="20px"
                  mr="10px"
                />
                <Text color="textRegular" fontSize="xl" lineHeight="24px">
                  {estimate?.estimatedAmount?.toString().substring(0, 18)} {toCurrency.ticker}
                </Text>
              </>
            ) : (
              <Text color="textRegular" fontSize="xl" lineHeight="24px">
                -
              </Text>
            )}
          </HStack>
        </Box>
        <Box p={{ base: '0', md: '16px' }} w={{ base: '100%', md: '50%' }}>
          {transactionId && (
            <CopyBox
              title={
                <Text color="textLabel" fontSize="xl">
                  Transaction ID
                </Text>
              }
              value={transactionId}
              valueStyles={{ fontSize: 'xl' }}
            />
          )}
        </Box>
      </HStack>
      {showFullData && (
        <>
          <Box
            my={{ base: '16px', md: '0' }}
            p={{ base: '0', md: '16px' }}
            borderTopWidth={{ base: '0', md: '1px' }}
            borderTopColor="gray.100">
            <Text color="textLabel" fontSize="xl">
              We will sent {toCurrency.ticker} to this IBAN account
            </Text>
            <Text color="textRegular" fontSize="xl">
              {iban}
            </Text>
          </Box>
          <Box p={{ base: '0', md: '16px' }} pt="0">
            <Text color="textLabel" fontSize="xl">
              Account holder’s full name
            </Text>
            <Text color="textRegular" fontSize="xl">
              {firstName} {lastName}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SellPaymentInfo;
