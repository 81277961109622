const KEY_MAP = {
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  ESCAPE: 27,
  BACKSPACE: 8,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
  SPACEBAR: 32,
  TAB: 9,
  ENTER: 13,
};

export default KEY_MAP;
