/* eslint-disable no-param-reassign */
import { Box, Center, HStack, Modal, Text } from 'native-base';
import React, { useRef } from 'react';

import { usePartnerSettingsData } from '../../context/PartnersSettingsContext';
import { ButtonPurple } from '../Buttons/ButtonPurple';
import { ButtonSecondary } from '../Buttons/ButtonSecondary';
import Icon from '../Icon';
import ModalBase from '../ModalBase';

const ConfirmationModal = ({
  onConfirm,
  onCancel,
  children,
  headerMessage = 'Warning!',
  cancelText = 'Cancel',
  confirmText = 'OK',
}: {
  onConfirm: () => void;
  onCancel: () => void;
  children: JSX.Element;
  headerMessage?: string;
  cancelText?: string;
  confirmText?: string;
}) => {
  const initialRef = useRef(null);

  const { data } = usePartnerSettingsData();

  return (
    <ModalBase blurredBg={true} initialFocusRef={initialRef} onClose={onCancel}>
      <Modal.Content bg="background.400" w="384px" maxW="100vw" minH="250px" maxH="500px" borderRadius="10px">
        <Modal.Header p="24px" borderColor="transparent">
          <HStack alignItems="center">
            <Icon name="alerts/alert-warning--orange" h="20px" w="20px" />
            <Text fontFamily="heading" ml="2" fontSize="2xl" color="white">
              {headerMessage}
            </Text>
          </HStack>
        </Modal.Header>
        <Box flexGrow="1" pb="32px" px="24px">
          {children}
          <Box position="absolute" bottom="24px" left="0" flexDir="row" justifyContent="center" w="100%">
            <ButtonSecondary maxW="147px" w="49%" h="34px" onPress={onCancel} _text={{ fontSize: 'md' }}>
              {cancelText}
            </ButtonSecondary>
            <ButtonPurple
              maxW="147px"
              w="49%"
              h="34px"
              ml="4"
              onPress={onConfirm}
              ref={initialRef}
              _text={{ fontSize: 'md' }}
              {...(data.styles.ButtonPurple || {})}>
              {confirmText}
            </ButtonPurple>
          </Box>
        </Box>
      </Modal.Content>
    </ModalBase>
  );
};

export default ConfirmationModal;
