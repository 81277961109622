import { create } from 'zustand';

export enum WhitelistRegisterResult {
  SUCCESS,
  DUPLICATE,
}

export interface IWhitelistFlow {
  country: string;
  setNewCountry: (country: string) => void;
  email: string;
  setNewEmail: (email: string) => void;
  flowIsCompleted: boolean;
  setFlowIsCompleted: (flowIsCompleted: boolean) => void;
}

const useStore = create<IWhitelistFlow>()(set => ({
  country: '',
  setNewCountry: country => set({ country }),
  email: '',
  setNewEmail: email => set({ email }),
  flowIsCompleted: false,
  setFlowIsCompleted: flowIsCompleted => set({ flowIsCompleted }),
}));

export default useStore;
