import { Box, Divider, HStack, Text } from 'native-base';

import { Icon } from '@cryptowallet/frontend/ui';
import { ExchangerExchangeResponseDto } from '@cryptowallet/web/api-client';

import BuyTransactionDetails from '../BuyTransactionDetails';
import PaymentInfo from '../PaymentInfo';

const CardPayment = ({ exchange }: { exchange: ExchangerExchangeResponseDto }) => {
  const { url } = exchange?.payIn || { url: '' };

  return (
    <Box px="24px">
      <PaymentInfo />
      <HStack pl="24px" alignItems="center" mb="20px">
        <Icon name="alerts/alert-warning--orange" position="absolute" top="4px" left="0" h="16px" w="16px" />
        <Text fontSize="lg" color="textRegular">
          Make sure your real name and name on your card match, or your transaction won’t be processed
        </Text>
      </HStack>
      <iframe
        sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"
        height="770px"
        src={url}
        title="payment iframe"
        style={{
          border: 'none',
        }}></iframe>
      <Divider orientation="horizontal" bg="gray.100" mt="40px" />
      <BuyTransactionDetails />
    </Box>
  );
};

export default CardPayment;
