import { Box, Spinner, Text, VStack } from 'native-base';
import React from 'react';

import { SellExchangeResult, useSellExchange } from '@cryptowallet/frontend/hooks';
import { useSellFlowStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, Icon } from '@cryptowallet/frontend/ui';

import SellPaymentInfo from './SellPaymentInfo';
import SellTransactionDetails from './SellTransactionDetails';

const exchangeStatusData = {
  [SellExchangeResult.SENDING_TO_PAYOUT]: {
    message: 'Processing Payment',
    icon: 'sending-exchange--purple',
  },
  [SellExchangeResult.HOLD]: {
    message: 'Pending',
    icon: 'sending-exchange',
  },
};

const SellExchange = () => {
  const startNewExchange = useSellFlowStore(state => state.reset);
  const { result } = useSellExchange();

  const { message, icon } = exchangeStatusData[result] || { message: 'Exchanging', icon: 'sending-exchange--purple' };

  return (
    <Box mt="30px">
      <VStack px="19px">
        <SellPaymentInfo />
        <VStack alignItems="center">
          <Icon name={icon} w="80px" h="84px" />
          <VStack>
            <Text color="white" mt="8px" mb="8px" fontSize="2xl">
              {message}
            </Text>
            <Spinner color="secondary.400" />
          </VStack>
        </VStack>
        <SellTransactionDetails />
      </VStack>
      <BottomPurpleButton size="lg" onPress={startNewExchange}>
        Start new exchange
      </BottomPurpleButton>
    </Box>
  );
};

export default SellExchange;
