import { useQuery } from '@tanstack/react-query';
import { Box, Flex, HStack, Skeleton, Text, useMediaQuery, VStack } from 'native-base';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { referralProgramApi } from '@cryptowallet/frontend/api';
import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import {
  ButtonPurple,
  CloseButton,
  CloseButtonType,
  Icon,
  ModalBase,
  Pressable,
  PreventFocusFirstElementOnModal,
  useUser,
} from '@cryptowallet/frontend/ui';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import CopyBox, { CopyBoxVariant } from '@cryptowallet/web/components/CopyBox';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';
import OrangeGradientText from '@cryptowallet/web/components/OrangeGradientText';
import ProgressBar from '@cryptowallet/web/components/ProgressBar';
import Slider, { SlideBase } from '@cryptowallet/web/components/Slider';

import BlockedReferralPlaceholder from '../components/BlockedReferralPlaceholder';
import ReferralRules from '../Rules';

const clientUrl = window.location.origin;

const SocialButton = ({ onPress, children }) => (
  <Pressable
    borderWidth="1px"
    borderRadius="10px"
    borderColor="gray.100"
    bg="gray.100"
    px={{ base: 'unset', lg: '32px' }}
    w="100%"
    maxW="350px"
    h="76px"
    outlineWidth="0"
    _hover={{
      bg: 'gray.100',
    }}
    _focus={{
      bg: 'gray.100',
      borderColor: 'secondary.400',
    }}
    mb="16px"
    onPress={onPress}>
    <HStack h="100%" justifyContent="center" alignItems="center">
      {children}
    </HStack>
  </Pressable>
);

const Step = ({ text, number }) => (
  <Box
    flex="1"
    h="144px"
    bg="gray.100"
    borderRadius="20px"
    p="22px"
    pt="14px"
    pr="30px"
    flexDirection="row"
    alignItems="center">
    <Text color="primary.400" fontSize="96px" fontFamily="heading" fontWeight="800" lineHeight="46px" mr="24px">
      {number}
    </Text>
    <Text color="white" fontSize="lg" fontWeight="500">
      {text}
    </Text>
  </Box>
);

const inviteText = encodeURIComponent('Cryptowallet invite link');

const steps = [
  'Share your referral link and get people to onboard into CryptoWallet.com',
  'The referred person gets a 250 $SPEND Welcome bonus for passing KYC and doing one transaction',
  'You get a 500 $SPEND as a reward for the referral — as simple as that!',
];

const bonusValues = {
  spend: 500,
  usdt: 20,
};

const ReferralOverview = () => {
  const { userIsReferrer } = useUserStatus();
  const { referralCode } = useUser();
  const { isDesktop } = useScreenSize();
  const [isSmallLaptop] = useMediaQuery({ minWidth: 1280, maxWidth: 1410 });
  const navigate = useNavigate();
  const [progress, setProgress] = useState(30);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const { isFetching: isReferralContractsFetching, data: contractsData } = useQuery(
    ['referral-contracts'],
    () => referralProgramApi.referralProgramControllerGetUserReferralContracts(10, 0),
    {
      ...cacheRequestOption,
      enabled: userIsReferrer,
    },
  );

  const { isFetching: isSuccessfulReferralContractsFetching, data: successfulContractsData } = useQuery(
    ['referral-contracts-success'],
    () => referralProgramApi.referralProgramControllerGetUserReferralContracts(10, 0, true),
    {
      ...cacheRequestOption,
      enabled: userIsReferrer,
    },
  );

  const { isFetching: isReferralRewardsFetching, data: rewardsData } = useQuery(
    ['referral-rewards'],
    () => referralProgramApi.referralProgramControllerGetUserReferralRewards(10, 0),
    {
      ...cacheRequestOption,
      enabled: userIsReferrer,
    },
  );

  const onChangeProgress = newValue => setProgress(newValue);
  const inviteLink = useMemo(() => `${clientUrl}?referralCode=${referralCode}`, [referralCode]);

  const socials = useMemo(
    () => [
      {
        title: 'Telegram',
        icon: {
          name: 'social-icons/telegram',
          h: '16px',
          w: '19px',
          mr: { base: '0', lg: '8px' },
        },
        link: `https://telegram.me/share/url?url=${inviteLink}&text=${inviteText}`,
      },
      {
        title: 'Twitter',
        icon: {
          name: 'social-icons/twitter',
          h: '16px',
          w: '19px',
          mr: { base: '0', lg: '8px' },
        },
        link: `https://twitter.com/intent/tweet?text=${inviteText}&url=${inviteLink}`,
      },
      {
        title: 'Facebook',
        icon: {
          name: 'social-icons/facebook',
          h: '19px',
          w: '8px',
          mr: { base: '0', lg: '8px' },
        },
        link: `https://www.facebook.com/sharer/sharer.php?quote=${inviteText}&u=${inviteLink}`,
      },
      {
        title: 'Whatsapp',
        icon: {
          name: 'social-icons/whatsapp',
          h: '16px',
          w: '16px',
          mr: { base: '0', lg: '8px' },
        },
        link: `https://wa.me/?text=${inviteText}%20${inviteLink}`,
      },
    ],
    [inviteLink],
  );

  return (
    <Flex flexGrow="1">
      <TabHeader>Referrals for Rewards</TabHeader>
      <TabContentLayout>
        {!!referralCode && (
          <HStack flexDirection={{ base: 'column', xl: 'row' }} justifyContent={{ base: 'unset', xl: 'space-between' }}>
            <VStack w={{ base: '100%', xl: '59%' }}>
              <CopyBox
                title={
                  <Text color="textLabel" fontSize="lg">
                    Referral link
                  </Text>
                }
                value={inviteLink}
                variant={CopyBoxVariant.GRAYBOX}
                valueStyles={{ fontSize: 'lg' }}
              />
              <CopyBox
                title={
                  <Text color="textLabel" fontSize="lg">
                    Code
                  </Text>
                }
                value={referralCode}
                variant={CopyBoxVariant.GRAYBOX}
                valueStyles={{ fontSize: 'lg' }}
                containerProps={{ mt: '24px' }}
              />
              <ButtonPurple
                onPress={() => setShareModalOpen(prev => !prev)}
                mt="32px"
                mb={{ base: '32px', xl: '0' }}
                h="50px"
                w="190px">
                Share with a friend
              </ButtonPurple>
            </VStack>
            <HStack w={{ base: '100%', xl: 'calc(41% - 24px)' }}>
              <VStack mr="20px" w="calc(48% - 20px)">
                <Box h="calc(50% - 10px)" bg="gray.100" borderRadius="10px" p="20px" mb="20px">
                  <Text fontWeight="500" fontSize="lg" color="textRegular">
                    Successful registrations
                  </Text>
                  <Skeleton h="45px" isLoaded={!isReferralContractsFetching}>
                    <Text fontSize="30px" color="white">
                      {contractsData?.data.outputTotalCount || '0'}
                    </Text>
                  </Skeleton>
                </Box>
                <Box h="calc(50% - 10px)" bg="gray.100" borderRadius="10px" p="20px">
                  <Text fontWeight="500" w="80%" fontSize="lg" color="textRegular">
                    Successful referrals
                  </Text>
                  <Skeleton h="45px" isLoaded={!isSuccessfulReferralContractsFetching}>
                    <Text fontSize="30px" color="white">
                      {successfulContractsData?.data.outputTotalCount || '0'}
                    </Text>
                  </Skeleton>
                </Box>
              </VStack>
              <Box h="100%" w="52%" bg="gray.100" borderRadius="10px" p="20px">
                <Pressable onPress={() => navigate('/rewards')} flexGrow={1}>
                  <VStack flexGrow={1} justifyContent="space-between">
                    <VStack>
                      <Text fontWeight="500" fontSize="lg" color="textRegular">
                        Total Rewards in $SPEND
                      </Text>
                      <Skeleton h="48px" isLoaded={!isReferralRewardsFetching}>
                        <OrangeGradientText style={{ fontSize: '40px' }}>
                          {rewardsData?.data.totalRewardsValue || '0'}
                        </OrangeGradientText>
                      </Skeleton>
                      <OrangeGradientText style={{ fontSize: '28px' }}>$SPEND</OrangeGradientText>
                    </VStack>
                    <VStack>
                      <Text fontWeight="500" fontSize="lg" color="textRegular">
                        Total Rewards in USDT
                      </Text>
                      <OrangeGradientText style={{ fontSize: '40px' }}>Coming Soon</OrangeGradientText>
                    </VStack>
                  </VStack>
                </Pressable>
              </Box>
            </HStack>
          </HStack>
        )}
        {!referralCode && <BlockedReferralPlaceholder />}
        <Text mt="40px" mb="20px" fontSize="3xl" color="white">
          How can I start earning Referral Rewards?
        </Text>
        {isDesktop && (
          <Flex direction="row" gap="20px" wrap="wrap">
            {steps.map((text, index) => (
              <Step key={index} text={text} number={index + 1} />
            ))}
          </Flex>
        )}
        {!isDesktop && (
          <Slider>
            {steps.map((text, index) => (
              <SlideBase key={index}>
                <Step text={text} number={index + 1} />
              </SlideBase>
            ))}
          </Slider>
        )}
        <Box
          mt="40px"
          p={{ base: '0', xl: isSmallLaptop ? '0' : '32px' }}
          pt={{ base: '24px', xl: isSmallLaptop ? '24px' : '32px' }}
          borderRadius="10px"
          bg={{ base: 'unset', xl: isSmallLaptop ? 'transparent' : 'gray.100' }}>
          <Text fontSize="2xl" color="white">
            Earnings Calculator
          </Text>
          <Box mt="24px" p={{ base: '0', xl: isSmallLaptop ? '0' : '32px' }} borderRadius="10px" bg="background.400">
            <Text fontSize="20px" color="textRegular">
              Number of my referrals
            </Text>
            <Text fontSize="60px" color="white">
              {progress}
            </Text>
            <ProgressBar onChange={onChangeProgress} value={progress} minValue={30} maxValue={2000} />
            <HStack flexDirection={{ base: 'column', xl: 'row' }} justifyContent="space-between" mt="34px">
              <HStack mb={{ base: '32px', xl: '0' }}>
                <Icon h="56px" w="56px" name="/referral/bonus" />
                <VStack ml="16px">
                  <Text color="textRegular" fontSize="lg">
                    Bonus from each of your referral
                  </Text>
                  <Text fontWeight="500" color="white" fontSize="24px">
                    {bonusValues.spend} $SPEND
                  </Text>
                </VStack>
              </HStack>
              <HStack mb={{ base: '32px', xl: '0' }}>
                <Icon h="56px" w="56px" name="/referral/spend" />
                <VStack ml="16px">
                  <Text color="textRegular" fontSize="lg">
                    Potential Income in $SPEND
                  </Text>
                  <Text fontWeight="500" color="white" fontSize="24px">
                    {progress < 20 ? progress * bonusValues.spend : 20 * bonusValues.spend} $SPEND
                  </Text>
                </VStack>
              </HStack>
              <HStack>
                <Icon h="56px" w="56px" name="/referral/tether" />
                <VStack ml="16px">
                  <Text color="textRegular" fontSize="lg">
                    Potential Income in USDT *
                  </Text>
                  <Text fontWeight="500" color="white" fontSize="24px">
                    {progress > 20 ? (progress - 20) * 20 : 0} USDT
                  </Text>
                </VStack>
              </HStack>
            </HStack>
          </Box>
        </Box>
        <ReferralRules />
        <ModalBase blurredBg={true} isOpen={shareModalOpen} onClose={() => setShareModalOpen(false)}>
          <VStack w="414px" maxW="100vw" bg="background.400" p="32px" borderRadius={10}>
            <HStack justifyContent="space-between">
              <Text color="white" fontSize="24px" mb="30px">
                Share with a friend
              </Text>
              <PreventFocusFirstElementOnModal />
              <CloseButton
                onPress={() => setShareModalOpen(false)}
                w="36px"
                h="36px"
                iconProps={{ h: '16px', w: '16px' }}
                type={CloseButtonType.Filled}
              />
            </HStack>
            <VStack>
              {socials.map(({ title, icon, link }, index) => (
                <SocialButton key={index} onPress={() => window.open(link, '_blank')}>
                  <Icon {...icon} />
                  <Text ml="10px" color="white" fontSize="20px">
                    {title}
                  </Text>
                </SocialButton>
              ))}
            </VStack>
          </VStack>
        </ModalBase>
      </TabContentLayout>
    </Flex>
  );
};

export default ReferralOverview;
