import { Box } from 'native-base';
import qs from 'qs';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useRecentActivityHistory, useSelectedWalletAccount, useWalletSummary } from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import {
  CryptocurrencyInputsForm,
  CryptoCurrencyInputType,
  ICryptocurrencyInputItem,
  OverlaySpinner,
} from '@cryptowallet/frontend/ui';
import { WalletTransactionEntityDtoTypeEnum } from '@cryptowallet/web/api-client';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import { PrimaryButton } from '@cryptowallet/web/components/Buttons';
import Table, { TableType } from '@cryptowallet/web/components/Table';
import TagGroup from '@cryptowallet/web/components/TagGroup';
import useInitialScrollTop from '@cryptowallet/web/hooks/useInitialScrollTop';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

import DepositPromo from '../DepositPromo';

import AssetTransactionHistoryDetails from './AssetTransactionHistoryDetails';
import { columnDefs } from './config';

const exchangeTypes = [
  { label: 'all', value: [WalletTransactionEntityDtoTypeEnum.Deposit, WalletTransactionEntityDtoTypeEnum.Withdrawal] },
  { label: 'deposit', value: [WalletTransactionEntityDtoTypeEnum.Deposit] },
  { label: 'withdraw', value: [WalletTransactionEntityDtoTypeEnum.Withdrawal] },
];

interface IAssetTransactionHistoryProps {
  isWidget?: boolean;
  tableType?: TableType;
  onGoBack?: () => void;
  onOpenRecentActivity?: (event, id) => void;
}

const authHeaderWidgetProps = {
  py: '0',
  hideDivider: true,
};

const fullContainerProps = {
  borderRadius: '10px',
  borderWidth: '1px',
  borderColor: 'gray.100',
};

export const AssetTransactionHistory = forwardRef<HTMLElement, IAssetTransactionHistoryProps>(
  ({ isWidget = false, tableType = TableType.SMALL, onGoBack, onOpenRecentActivity }, forwardedRef) => {
    useInitialScrollTop(isWidget);
    const location = useLocation();
    const { existingWalletResources } = useWalletSummary();
    const { selectedWalletAccount, isFetchingSelectedWalletAccount } = useSelectedWalletAccount();
    const setSelectedWalletAccountId = useAccountsStore(state => state.setSelectedWalletAccountId);
    const [selectedExchangeTypes, setSelectedExchangeTypes] = useState(exchangeTypes[0].value);
    const [selectedRow, setSelectedRow] = useState('');

    const { transactions, isLoading, fetchNextPage, hasNextPage, isFetching } = useRecentActivityHistory(
      selectedWalletAccount?.walletAccount?.id,
      selectedExchangeTypes,
      isWidget ? 4 : 10,
    );

    // const onChangeExchangeTypesFilter = ({ value }: { value?: WalletTransactionEntityDtoTypeEnum }) => setSelectedExchangeTypes(prev => (prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]));// for checkbox filtering
    const onChangeExchangeTypesFilter = ({ value }: { value?: any[] }) => setSelectedExchangeTypes(value);

    const onSelectRow = id => setSelectedRow(prev => (prev === id ? '' : id));

    const cryptocurrencyInputs = useMemo<ICryptocurrencyInputItem[]>(
      () => [
        {
          name: 'send',
          currencies: existingWalletResources.map(item => ({
            ...item.asset.currency,
            id: item.walletAccount.id,
            isCreated: item.isCreated,
            image: item.asset.image,
            network: item.asset.network,
          })),
          isLoading: isFetchingSelectedWalletAccount,
          selectedCurrency: selectedWalletAccount
            ? {
                ...selectedWalletAccount.asset.currency,
                image: selectedWalletAccount.asset.image,
                network: selectedWalletAccount.asset.network,
              }
            : null,
          onCurrencyChange: ({ id }) => setSelectedWalletAccountId(id),
          type: CryptoCurrencyInputType.Select,
          placeholder: 'Select currency',
          containerProps: {
            width: '100%',
            maxWidth: { base: '100%', md: '336px' },
            mt: { base: '16px', md: '0' },
            mb: '0',
          },
          showNetwork: true,
        },
      ],
      [
        existingWalletResources,
        selectedWalletAccount?.asset,
        setSelectedWalletAccountId,
        isFetchingSelectedWalletAccount,
      ],
    );

    useEffect(() => {
      if (!isWidget) {
        fetchNextPage();

        if (location.search) {
          const { txId } = qs.parse(location.search.slice(1));
          setSelectedRow(txId as string);
        }
      }
    }, [isWidget, fetchNextPage, location]);

    const { control } = useForm({
      mode: 'onTouched',
    });

    const showDepositPromo = isWidget && !transactions.length && selectedExchangeTypes.length === 2;

    if (isLoading && isFetching) {
      return <OverlaySpinner />;
    }

    if (showDepositPromo) {
      return (
        <Box minH={selectedWalletAccount ? '328px' : '510px'}>
          <DepositPromo />
        </Box>
      );
    }

    return (
      <Box
        bg="background.400"
        borderBottomLeftRadius="10px"
        borderBottomRightRadius="10px"
        w="100%"
        {...(isWidget ? {} : fullContainerProps)}>
        <Box
          px="24px"
          pt={isWidget && selectedWalletAccount ? '40px' : '0'}
          bg="background.400"
          position="relative"
          ref={forwardedRef}
          zIndex="1">
          <AuthHeader
            {...(isWidget && selectedWalletAccount ? authHeaderWidgetProps : {})}
            px="0"
            mb="0"
            dividerProps={{ px: '0' }}
            zIndex="1"
            py="20px">
            <FormHeading
              onPressBack={!isWidget && onGoBack}
              text="History"
              textFontSize="24px"
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent={{ base: 'center', md: 'space-between' }}
              flexDirection={{ base: 'column', md: 'row' }}>
              {!isWidget && <CryptocurrencyInputsForm control={control} inputs={cryptocurrencyInputs} />}
            </FormHeading>
          </AuthHeader>
          <TagGroup
            tags={exchangeTypes}
            selected={selectedExchangeTypes}
            onChange={onChangeExchangeTypesFilter}
            containerProps={{
              pt: '24px',
              pb: isWidget ? '17px' : '20px',
            }}
          />
        </Box>
        <Box position="relative" minH="200px" pb="80px">
          <Table
            columnDefs={columnDefs}
            rowData={isWidget ? transactions.slice(0, 4) : transactions} // as we don't receive hasNext from backend,
            type={tableType} // we prefetch extra page on widget to know if need to show load more btn
            containerProps={{ zIndex: -1 }}
            loadMore={fetchNextPage}
            hasMore={hasNextPage}
            onSelectRow={onSelectRow}
            selectedRow={selectedRow}
            MasterDetailsComponent={AssetTransactionHistoryDetails}
            isFetching={isFetching}
          />
          {isWidget && (
            <PrimaryButton onPress={onOpenRecentActivity} position="absolute" left="0" bottom="0" w="100%" size="lg">
              See all
            </PrimaryButton>
          )}
        </Box>
      </Box>
    );
  },
);

export default AssetTransactionHistory;
