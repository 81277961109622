import { IPressableProps, Text } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Pressable, usePartnerStyles } from '@cryptowallet/frontend/ui';

interface IPrimaryLinkProps extends IPressableProps {
  href?: string;
  text: string;
  isExternal?: boolean;
}

const hoverFocusStyles = {
  textDecorationColor: 'secondary.400',
};

const PrimaryLink = ({ text, href, isExternal = false, fontSize = 'md', ...props }: IPrimaryLinkProps) => {
  // used pressable as native-base link doesn't support focus styling
  const navigate = useNavigate();
  const partnerStyles = usePartnerStyles('PrimaryLink');
  const textPartnerStyles = usePartnerStyles('PrimaryLinkText');

  return (
    <Pressable
      outlineWidth="0"
      textDecorationColor="textRegular"
      textDecoration="underline"
      color="textRegular"
      _hover={hoverFocusStyles}
      _focus={hoverFocusStyles}
      onPress={() => {
        if (href) {
          if (isExternal) {
            window.open(href, '_blank');
          } else {
            navigate(href);
          }
        }
      }}
      {...props}
      {...partnerStyles}>
      {({ isFocused, isHovered }) => (
        <Text
          fontSize={fontSize}
          {...(textPartnerStyles.color
            ? {
                color: isFocused || isHovered ? textPartnerStyles.color.active : textPartnerStyles.color.normal,
              }
            : {
                background: isFocused || isHovered ? 'secondary.400' : 'textRegular',
              })}>
          {text}
        </Text>
      )}
    </Pressable>
  );
};

export default PrimaryLink;
