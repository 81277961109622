const BlockedIcon = () => (
  <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16L93.7817 93.7817" stroke="url(#paint0_linear_766_7900)" strokeWidth="10" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.321 86.1785C115.098 64.7506 112.955 35.1732 93.8909 16.1091C74.8268 -2.95496 45.2494 -5.09823 23.8215 9.67932L31.0397 16.8976C48.4255 5.94269 71.6765 8.03688 86.8198 23.1802C101.963 38.3235 104.057 61.5745 93.1024 78.9602L100.321 86.1785ZM86.1785 100.321L78.9602 93.1024C61.5745 104.057 38.3235 101.963 23.1802 86.8198C8.03689 71.6765 5.94269 48.4255 16.8976 31.0397L9.67932 23.8215C-5.09823 45.2494 -2.95496 74.8268 16.1091 93.8909C35.1732 112.955 64.7506 115.098 86.1785 100.321Z"
      fill="url(#paint1_linear_766_7900)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_766_7900"
        x1="16.3536"
        y1="15.6464"
        x2="94.1353"
        y2="93.4282"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#7252C7" />
        <stop offset="1" stopColor="#7252C7" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_766_7900" x1="92.5" y1="17" x2="11.5" y2="99.5" gradientUnits="userSpaceOnUse">
        <stop offset="0.124319" stopColor="#7252C7" />
        <stop offset="1" stopColor="#7252C7" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default BlockedIcon;
