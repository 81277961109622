import { Box, IBoxProps, StyledProps } from 'native-base';
import { Control } from 'react-hook-form';

import { getPlatform } from '@cryptowallet/frontend/utils';

import { IValidate } from '../ErrorMessage';

import CryptoCurrencyInput from './Inputs/CryptoCurrencyInput';
import CurrencySelect, { IInputCurrency } from './Inputs/CurrencySelect';

const isWeb = getPlatform() === 'web';
export enum CryptoCurrencyInputType {
  AmountWithSelect,
  Select,
}

export interface ICryptocurrencyInputItem {
  name: string;
  label?: string;
  value?: string | number;
  currencies: IInputCurrency[];
  selectedCurrency: IInputCurrency;
  setValue?: (newValue: string) => void;
  onCurrencyChange?: (newCurrency: IInputCurrency) => void;
  validate?: IValidate;
  isLoading?: boolean;
  noInput?: boolean;
  type?: CryptoCurrencyInputType;
  containerProps?: IBoxProps;
  currencyDropdownStyles?: StyledProps;
  paymentMethod?: string;
  isDisabled?: boolean;
}

interface ICryptocurrencyFormContentProps {
  control: Control;
  selectedInputName: string;
  openCurrencyList?: (inputName: string) => void;
  isModalView?: boolean;
  inputs: ICryptocurrencyInputItem[];
}

const CryptoCurrencyInputs = {
  [CryptoCurrencyInputType.AmountWithSelect]: CryptoCurrencyInput,
  [CryptoCurrencyInputType.Select]: CurrencySelect,
};

const CryptocurrencyFormContent = ({
  control,
  selectedInputName,
  openCurrencyList = () => {},
  isModalView = false,
  inputs,
}: ICryptocurrencyFormContentProps) => (
  <>
    {inputs.map(
      (
        { setValue, name, type = CryptoCurrencyInputType.AmountWithSelect, containerProps, isDisabled, ...rest },
        index,
      ) => {
        const Component = CryptoCurrencyInputs[type];
        const isDropdownDisabled = isDisabled || !rest.onCurrencyChange;

        return (
          <Box key={name} zIndex={inputs.length + 1 - index} {...containerProps}>
            <Component
              placeholder="0"
              withIcon={true}
              name={name}
              h={isWeb ? undefined : '56px'}
              onAmountChange={(newAmount: string) => {
                if (setValue && (newAmount === '' || newAmount.match(/^(\d+)([.]\d*)?$/))) {
                  setValue(newAmount);
                }
              }}
              openCurrencyList={openCurrencyList}
              isOpen={name === selectedInputName}
              isModalView={isModalView}
              isDisabled={isDropdownDisabled}
              control={control}
              {...rest}
            />
          </Box>
        );
      },
    )}
  </>
);

export default CryptocurrencyFormContent;
