import dayjs from 'dayjs';
import { Text, VStack } from 'native-base';
import React from 'react';

import { getRewardClientStatus, getRewardClientType } from './config';

const RewardsMasterDetails = ({ data }) => {
  const accruedRewardAt = data.accruedAt ? dayjs(data.accruedAt).format('DD.MM.YYYY') : '-';

  return (
    <VStack px="24px">
      <VStack py="12px">
        <Text color="textLabel" fontSize="lg">
          Type
        </Text>
        <Text color="textRegular" fontSize="lg">
          {getRewardClientType(data)}
        </Text>
      </VStack>
      <VStack py="12px">
        <Text color="textLabel" fontSize="lg">
          Status
        </Text>
        <Text color="textRegular" fontSize="lg">
          {getRewardClientStatus(data)}
        </Text>
      </VStack>
      {/* <VStack py="12px">
        <Text color="textLabel" fontSize="lg">
          Date
        </Text>
        <Text color="textRegular" fontSize="lg">
          {accruedRewardAt}
        </Text>
      </VStack> */}
    </VStack>
  );
};

export default RewardsMasterDetails;
