import { Button } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '../context/PartnersSettingsContext';

const Tabs = (props): JSX.Element => {
  const partnerStyles = usePartnerStyles('Tabs');

  return (
    <Button.Group isAttached borderRadius="10px" borderWidth="1px" borderColor="gray.200" p="1px" {...partnerStyles}>
      {props.children}
    </Button.Group>
  );
};

export default Tabs;
