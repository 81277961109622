/* eslint-disable consistent-return */
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAlerts, ToasterType } from '../ui/src';
import { useAccountsStore } from '../stores';
import { walletsApi } from '../api/src';

const useCreateWalletAccountMutation = () => {
  const { showToaster } = useAlerts();

  const queryClient = useQueryClient();
  const setSelectedWalletAccountId = useAccountsStore(state => state.setSelectedWalletAccountId);

  const mutation = useMutation((walletAssetId: string) =>
    walletsApi.walletsControllerCreateWalletAccount({
      walletAssetId,
    }),
  );

  return {
    ...mutation,
    mutateAsync: async (walletAssetId: string) => {
      try {
        const { data } = await mutation.mutateAsync(walletAssetId);

        queryClient.invalidateQueries(['walletSummary']);
        showToaster(ToasterType.SUCCESS, 'New account successfully created');
        setSelectedWalletAccountId(data.walletAccountId);

        return data;
      } catch (err) {
        showToaster(ToasterType.ERROR, 'Open account failed. Please try again later');
      }
    },
  };
};

export default useCreateWalletAccountMutation;
