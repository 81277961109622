import { Box, HStack } from 'native-base';
import React, { useState } from 'react';

import { TextButton } from './Buttons';
import SectionTitle from './SectionTitle';

const TransactionDetailsCollapseBox = ({
  defaultOpen = true,
  children,
  hideHeading = false,
  sectionTitle = 'Transaction details',
  ...rest
}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Box {...rest}>
      {!hideHeading && (
        <HStack position="relative">
          <SectionTitle>{sectionTitle}</SectionTitle>
          <TextButton position="absolute" top="0" right="0" onPress={() => setOpen(o => !o)}>
            {open ? 'HIDE' : 'SHOW'}
          </TextButton>
        </HStack>
      )}
      {open && children}
    </Box>
  );
};

export default TransactionDetailsCollapseBox;
