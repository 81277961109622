import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/frontend/client';

import { useUserSettings } from '../ui/src';

const paymentMethods = [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa];

export const useActiveSellPaymentMethods = () => {
  const { allowedPaymentMethods } = useUserSettings();
  return paymentMethods.filter(method => allowedPaymentMethods.includes(method));
};
