/* eslint-disable sonarjs/no-duplicate-string */
import {
  ExchangerExchangeResponseDtoStatusEnum,
  ExchangerExchangeResponseDtoTypeEnum,
} from '@cryptowallet/web/api-client';
import { IColumnDef, TableType } from '@cryptowallet/web/components/Table';
import { SingleSelectOptionType } from '@cryptowallet/web/components/Table/CellRenderers';
import { ColumnType } from '@cryptowallet/web/components/Table/Row';

import ExchangerTrxDetails from './components/ExchangerTrxDetails';

export const columnDefs: IColumnDef[] = [
  {
    id: 'type',
    headerName: 'Type',
    field: 'type',
    type: ColumnType.COLORED_TEXT,
    hideHeader: true,
    options: [
      {
        id: 'buy',
        value: ExchangerExchangeResponseDtoTypeEnum.Buy,
        title: 'Buy',
        color: 'textSuccess',
      },
      {
        id: 'sell',
        value: ExchangerExchangeResponseDtoTypeEnum.Sell,
        title: 'Sell',
        color: 'error.400',
      },
    ],
    containerStyles: {
      [TableType.FULL]: {
        w: '40px',
      },
      [TableType.SMALL]: {
        w: '50%',
        mb: '24px',
        h: '21px',
      },
    },
  },
  {
    id: 'trx_details',
    headerName: 'Transaction details',
    field: 'details',
    getValue: data => data,
    type: ColumnType.CUSTOM,
    cellRenderer: ExchangerTrxDetails,
    sortIndex: {
      [TableType.SMALL]: 2,
    },
    headerContainerStyles: {
      [TableType.FULL]: {
        mr: '40px',
      },
    },
    containerStyles: {
      [TableType.FULL]: {
        w: {
          base: 'calc(30% + 60px)',
          lg: 'calc(22% + 60px)',
          xl: 'calc(20% + 60px)',
        },
      },
      [TableType.SMALL]: {
        w: { base: '60%', md: '66%' },
      },
    },
  },
  {
    id: 'date',
    headerName: 'Date',
    field: 'createdAt',
    type: ColumnType.DATE,
    sortIndex: {
      [TableType.SMALL]: 1,
    },
    containerStyles: {
      [TableType.FULL]: {
        w: '12%',
      },
      [TableType.SMALL]: {
        w: '50%',
        h: '21px',
        alignItems: 'flex-end',
        mb: '24px',
      },
    },
  },
  {
    id: 'payout_details',
    headerName: 'Payout details',
    field: 'details',
    getValue: (data, col): string | undefined => {
      if (data.type === ExchangerExchangeResponseDtoTypeEnum.Buy) {
        return data.payOut.walletAddress;
      }
      if (data.type === ExchangerExchangeResponseDtoTypeEnum.Sell) {
        return data.payOut.iban;
      }
      return undefined;
    },
    type: ColumnType.COPY_TEXT,
    hidden: {
      type: [TableType.SMALL],
    },
    containerStyles: {
      [TableType.FULL]: {
        w: {
          base: '40%',
          lg: '35%',
        },
        mr: {
          base: '0',
          xl: '80px',
        },
      },
    },
  },
  {
    id: 'status',
    headerName: 'Status',
    field: 'status',
    type: ColumnType.SINGLE_SELECT,
    options: [
      {
        id: 'blocked',
        value: ExchangerExchangeResponseDtoStatusEnum.Blocked,
        title: 'Pending',
        color: 'secondary.400',
        type: SingleSelectOptionType.Circle,
      },
      {
        id: 'canceled',
        value: ExchangerExchangeResponseDtoStatusEnum.Canceled,
        title: 'Failed',
        color: 'error.400',
        type: SingleSelectOptionType.Circle,
      },
      {
        id: 'created',
        value: ExchangerExchangeResponseDtoStatusEnum.Created,
        title: 'Resume',
        color: 'primary.400',
        type: SingleSelectOptionType.Button,
      },
      {
        id: 'pending',
        value: ExchangerExchangeResponseDtoStatusEnum.Pending,
        title: 'Resume',
        color: 'primary.400',
        type: SingleSelectOptionType.Button,
      },
      {
        id: 'finished',
        value: ExchangerExchangeResponseDtoStatusEnum.Done,
        title: 'Finished',
        color: 'textSuccess',
        type: SingleSelectOptionType.Circle,
      },
    ],
    containerStyles: {
      [TableType.FULL]: {
        w: {
          base: '7%',
          lg: '10%',
          xl: '7%',
        },
        flexGrow: '1',
        alignItems: 'flex-start',
      },
      [TableType.SMALL]: {
        ml: 'auto',
      },
    },
  },
];
