import { useQuery } from '@tanstack/react-query';

import { walletsApi } from '@cryptowallet/frontend/api';
import {
  EstimateWalletTransactionPayloadWalletsDtoTypeEnum,
  EstimateWalletTransactionResponseWalletsDto,
} from '@cryptowallet/frontend/client';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';

import useDebounce from './useDebounce';
import useSelectedWalletAccount from './useSelectedWalletAccount';

export const useWithdrawEstimate = () => {
  const withdrawData = useAccountsStore(state => state.withdrawData);
  const { selectedWalletAccount } = useSelectedWalletAccount();

  const { amount, walletFeeLevel } = withdrawData;

  const debouncedAmount = useDebounce(amount, 1000);

  const { data, isLoading } = useQuery<EstimateWalletTransactionResponseWalletsDto | null>(
    ['withdraw-estimate', selectedWalletAccount?.asset?.id, debouncedAmount],
    async (): Promise<EstimateWalletTransactionResponseWalletsDto | null> => {
      if (+amount === 0) {
        return null;
      }

      const response = await walletsApi.walletsControllerEstimateWalletTransaction({
        type: EstimateWalletTransactionPayloadWalletsDtoTypeEnum.CryptoToFiat,
        fromAssetId: selectedWalletAccount?.asset?.id,
        amount,
      });

      return response.data;
    },
    cacheRequestOption,
  );

  const {
    walletFeeLevels,
    equivalentAmount,
    feeAsset,
    maxCryptoAmount,
    isBalanceEnough,
    walletBalanceInsufficientionReason,
  } = data?.walletEstimationTransaction || {};

  return {
    isLoading,
    networkFee: walletFeeLevels ? Number(walletFeeLevels[walletFeeLevel].networkFee) : '',
    fiatAmount: Number(equivalentAmount) || 0,
    feeAsset,
    validationValues: {
      max: maxCryptoAmount || 0,
    },
    isBalanceEnough,
    walletBalanceInsufficientionReason,
  };
};
