import React from 'react';

const NavigationSettingsSecurityIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8.41666C1 5.21907 1 3.62028 1.37752 3.08241C1.75503 2.54454 3.25832 2.02996 6.26491 1.00079L6.83772 0.804717C8.40498 0.268239 9.18861 0 10 0C10.8114 0 11.595 0.268239 13.1623 0.804717L13.7351 1.00079C16.7417 2.02996 18.245 2.54454 18.6225 3.08241C19 3.62028 19 5.21907 19 8.41666V9.99136C19 15.6294 14.761 18.3655 12.1014 19.5273C11.38 19.8424 11.0193 20 10 20C8.98074 20 8.62001 19.8424 7.89856 19.5273C5.23896 18.3655 1 15.6294 1 9.99136V8.41666ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM10 15C14 15 14 14.1046 14 13C14 11.8954 12.2091 11 10 11C7.79086 11 6 11.8954 6 13C6 14.1046 6 15 10 15Z"
      fill={color}
    />
  </svg>
);

export default NavigationSettingsSecurityIcon;
