import './styles.css';

import React from 'react';

const OrangeGradientText = ({ style = {}, children }) => (
  <span className="gradient-text" style={style}>
    {children}
  </span>
);

export default OrangeGradientText;
