import { Box, Slider } from 'native-base';
import React from 'react';

const ProgressBar = ({ minValue = 0, maxValue = 100, onChange, step = 1, value = 0 }) => (
  <Box p="1px" borderWidth="1px" borderRadius="20px" borderColor="rgba(255, 255, 255, 0.05)">
    <Slider size="lg" onChange={onChange} minValue={minValue} maxValue={maxValue} step={step} value={value}>
      <Slider.Track size="24px" borderRadius="20px">
        <Slider.FilledTrack
          bg={{
            linearGradient: {
              colors: ['#008CFF', '#E880F1'],
              start: [0, 0],
              end: [0, 0],
            },
          }}
        />
      </Slider.Track>
      <Slider.Thumb
        bg={{
          linearGradient: {
            colors: ['#F2EFFB', '#7A709A'],
            start: [0, 0],
            end: [0, 1],
          },
        }}
        h="34px"
        w="34px"
      />
    </Slider>
  </Box>
);

export default ProgressBar;
