/* eslint-disable no-param-reassign */
import { IModalProps, Modal } from 'native-base';
import React from 'react';

import { useBlockWindowScroll } from '@cryptowallet/frontend/hooks';
import { getPlatform } from '@cryptowallet/frontend/utils';

const isWeb = getPlatform() === 'web';

interface IModalBaseProps extends IModalProps {
  blurredBg?: boolean;
}

const ModalBase = ({ children, blurredBg = false, isOpen = true, ...rest }: IModalBaseProps) => {
  useBlockWindowScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      h={isWeb ? '100vh' : '100%'}
      ref={ref => {
        if (!ref) {
          return;
        }

        if (blurredBg) {
          ref.style.background = 'rgba(0, 0, 0, 0.80)';

          setTimeout(() => {
            ref.style.backdropFilter = 'blur(20px)';
          }, 300);
        }

        if (ref.parentNode) {
          // make 100vh and fixed to prevent scrolling modal when big content
          ref.parentNode.style.height = '100vh';
          ref.parentNode.style.position = 'fixed';
        }
      }}
      {...rest}>
      {children}
    </Modal>
  );
};

export default ModalBase;
