import { Box, HStack, Text } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RiskCheckType } from '@cryptowallet/frontend/client';
import { RiskCheckMap, RiskCheckStatus } from '@cryptowallet/frontend/hooks';
import { ButtonPurple, Icon } from '@cryptowallet/frontend/ui';

import SecurityBlockContainer from './SecurityBlockContainer';

interface ISecurityRiskCheckListProps {
  riskChecks: RiskCheckMap;
}

const data = {
  [RiskCheckType.ProofOfWealthCheck]: {
    title: 'Additional verification',
    decription: 'You are required to submit additional documents!',
    pathname: '/account/security/verification-pow',
  },
};

const SecurityRiskCheckList = ({ riskChecks }: ISecurityRiskCheckListProps) => {
  const navigate = useNavigate();

  return (
    <>
      {Object.entries(riskChecks).map(([key, { status, message }]) => {
        const checkData = data[key];

        if (!checkData) {
          return null;
        }

        return (
          <SecurityBlockContainer key={key}>
            <HStack alignItems="center" justifyContent="space-between">
              <Box w="calc(100% - 140px)">
                <HStack alignItems="center">
                  <Icon name="alerts/alert-warning--orange" h="16px" w="16px" mr="8px" />
                  <Text fontSize="2xl" color="white">
                    {checkData.title}
                  </Text>
                </HStack>
                <Text fontSize="lg" color="white">
                  {checkData.decription}
                </Text>
                {message && (
                  <Text fontSize="lg" color="error.400">
                    {message}
                  </Text>
                )}
              </Box>
              {status === RiskCheckStatus.IN_REVIEW && (
                <Box
                  h="30px"
                  px="16px"
                  borderRadius="24px"
                  borderColor="secondary.400"
                  borderWidth="1px"
                  justifyContent="center">
                  <Text userSelect="none" color="secondary.400" fontSize="md">
                    Pending
                  </Text>
                </Box>
              )}
              {(status === RiskCheckStatus.PENDING || status === RiskCheckStatus.RETRY) && (
                <ButtonPurple
                  _text={{ fontSize: 'md' }}
                  onPress={() => navigate(checkData.pathname)}
                  h="30px"
                  py="8px"
                  px="16px"
                  size="lg">
                  {status === RiskCheckStatus.PENDING ? 'Start' : 'Retry'}
                </ButtonPurple>
              )}
              {status === RiskCheckStatus.REJECTED && (
                <ButtonPurple
                  _text={{ fontSize: 'md' }}
                  onPress={() => window.open('https://support.cryptowallet.com/support/home', '_blank')}
                  h="30px"
                  py="8px"
                  px="16px"
                  size="lg">
                  Contact support
                </ButtonPurple>
              )}
            </HStack>
          </SecurityBlockContainer>
        );
      })}
    </>
  );
};

export default SecurityRiskCheckList;
