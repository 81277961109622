import { Text } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '@cryptowallet/frontend/ui';

const LinkPurpleText = ({ children, isFocused, isHovered, ...rest }) => {
  const partnerStyles = usePartnerStyles('LinkPurpleText');

  return (
    <Text
      color={isFocused || isHovered ? 'background.900' : 'primary.300'}
      fontSize="sm"
      {...rest}
      {...(partnerStyles.color
        ? {
            color: isFocused || isHovered ? partnerStyles.color.active : partnerStyles.color.normal,
          }
        : {})}>
      {children}
    </Text>
  );
};

export default LinkPurpleText;
