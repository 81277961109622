import { Heading, HStack, IHeadingProps, ITextProps, Text } from 'native-base';
import React from 'react';

import { BackButton } from '@cryptowallet/web/components/Buttons';

interface IFormHeadingProps extends IHeadingProps {
  text: string;
  onPressBack?: () => void;
  textFontSize?: string;
  textProps?: ITextProps;
}

const FormHeading = ({
  text,
  onPressBack,
  children,
  textFontSize = '3xl',
  textProps = {},
  ...props
}: IFormHeadingProps) => {
  const showBackBtn = !!onPressBack;
  return (
    <Heading display="flex" alignItems="center" {...props}>
      <HStack
        position="relative"
        pl={showBackBtn ? '46px' : '0'}
        w={children ? 'unset' : '100%'}
        lineHeight="32px"
        alignItems="center">
        {showBackBtn && <BackButton position="absolute" top="0" left="0" onPress={onPressBack} mr="16px" />}
        <Text fontWeight="400" fontSize={textFontSize} {...textProps}>
          {text}
        </Text>
      </HStack>
      {children}
    </Heading>
  );
};

export default FormHeading;
