import { FlatList } from 'native-base';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableFlatList = ({
  // web version
  droppableId = 'droppable',
  data = [],
  renderItem,
  rowHeight = 20,
  onRowDragEnd,
}) => {
  const [rowWidth, setRowWith] = useState(0);
  const [list, setList] = useState(data);

  // const onDragEnd = (result: DropResult) => {
  //   if (!result.destination) {
  //     return;
  //   }
  //   if (result.source.index === result.destination.index) {
  //     return;
  //   }

  //   const newList = reorder(list, result.source.index, result.destination.index);
  //   setList(newList);

  //   onRowDragEnd(newList);
  // };

  useEffect(() => {
    setList(data);
  }, [data]);

  return (
    <FlatList
      data={list}
      renderItem={({ item, index }) =>
        renderItem({
          item,
          index,
        })
      }
    />
  );

  // return (
  //   <DragDropContext onDragEnd={onDragEnd}>
  //     <Droppable
  //       droppableId={droppableId}
  //       mode="virtual"
  //       renderClone={(provided, snapshot, rubric) =>
  //         renderItem({
  //           provided,
  //           isDragging: snapshot.isDragging,
  //           item: list[rubric.source.index],
  //           index: rubric.source.index,
  //         })
  //       }
  //     >
  //       {droppableProvided => (
  //         <FlatList
  //           h={rowHeight * list.length}
  //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //           // @ts-ignore
  //           ref={ref => {
  //             // react-virtualized has no way to get the list's ref that I can so
  //             // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
  //             if (ref) {
  //               const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
  //               if (whatHasMyLifeComeTo instanceof HTMLElement) {
  //                 setRowWith(whatHasMyLifeComeTo.offsetWidth);
  //                 droppableProvided.innerRef(whatHasMyLifeComeTo);
  //               }
  //             }
  //           }}
  //           data={list}
  //           renderItem={({ item, index }) => (
  //             <Draggable draggableId={item.id} index={index} key={item.id}>
  //               {(provided, snapshot) =>
  //                 renderItem({
  //                   provided,
  //                   isDragging: snapshot.isDragging,
  //                   item,
  //                   index,
  //                   style: {
  //                     position: 'absolute',
  //                     top: index * rowHeight,
  //                     height: rowHeight,
  //                     width: rowWidth,
  //                   },
  //                 })
  //               }
  //             </Draggable>
  //           )}
  //         />
  //       )}
  //     </Droppable>
  //   </DragDropContext>
  // );
};

export default DraggableFlatList;
