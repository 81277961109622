import { IPressableProps, Pressable, StyledProps } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePartnerStyles } from '@cryptowallet/frontend/ui';

import LinkPurpleText from './LinkPurpleText';

interface IPrimaryLinkProps extends IPressableProps {
  href?: string;
  text: string;
  isExternal?: boolean;
  _text?: StyledProps;
}

const hoverFocusStyles = {
  textDecorationColor: 'background.900',
};

const LinkPurple = ({ text, href, isExternal, _text = {}, onPress, ...props }: IPrimaryLinkProps) => {
  // used pressable as native-base link doesn't support focus styling
  const navigate = useNavigate();

  const partnerStyles = usePartnerStyles('LinkPurple');

  return (
    <Pressable
      outlineWidth="0"
      textDecorationColor="primary.300"
      textDecoration="underline"
      color="primary.300"
      _hover={hoverFocusStyles}
      _focus={hoverFocusStyles}
      onPress={event => {
        if (href) {
          if (isExternal) {
            window.open(href, '_blank');
          } else {
            navigate(href);
          }
        } else if (onPress) {
          onPress(event);
        }
      }}
      {...props}
      {...partnerStyles}>
      {({ isFocused, isHovered }) => (
        <LinkPurpleText isFocused={isFocused} isHovered={isHovered} {..._text}>
          {text}
        </LinkPurpleText>
      )}
    </Pressable>
  );
};

export default LinkPurple;
