/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box, HStack, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { marketingApi, referralProgramApi } from '@cryptowallet/frontend/api';
import { ButtonPurple, LabeledInput, PressableCheckbox, Select, useAlerts } from '@cryptowallet/frontend/ui';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import LinkPurple from '@cryptowallet/web/components/Links/LinkPurple';
import useInitialScrollTop from '@cryptowallet/web/hooks/useInitialScrollTop';

import FormHeading from './FormHeading';

interface IFormProps {
  terms: boolean;
  country: string;
  referralCode: string;
}

const TermsText = ({ children }) => (
  <Text mx="6px" fontSize="lg" color="textRegular" lineHeight="32px" userSelect="none">
    {children}
  </Text>
);

const Terms = ({
  onAccept,
  onDecline,
  referralCode = '',
}: {
  onAccept: (country: string, referralCode: string) => void;
  onDecline: () => void;
  referralCode: string;
}) => {
  useInitialScrollTop();
  const { showErrorToaster } = useAlerts();
  const { control, handleSubmit, setError, getValues } = useForm<IFormProps>({
    defaultValues: {
      terms: false,
      country: '',
      referralCode,
    },
  });
  const { isLoading: isCountriesLoading, data } = useQuery(
    ['whitelistCountries'],
    () => marketingApi.marketingControllerGetWhitelistCountries(),
    cacheRequestOption,
  );

  const validateReferralCodeMutation = useMutation(({ referralCode }: { referralCode: string }) =>
    referralProgramApi.referralProgramControllerValidateReferralCode({
      referralCode,
    }),
  );

  const countries = useMemo(
    () =>
      [...(data?.data.countries || [])].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }

        return 0;
      }),
    [data?.data?.countries],
  );

  const onSubmit = async () => {
    try {
      const { referralCode } = getValues();
      if (referralCode) {
        const { data } = await validateReferralCodeMutation.mutateAsync({ referralCode });

        if (!data?.success) {
          setError('referralCode', { type: 'custom', message: 'Please check your code and try again.' });
          return;
        }
      }

      handleSubmit(({ country, referralCode }: IFormProps) => {
        const val = countries.find(item => item.id === country);
        onAccept(val.alpha3, referralCode);
      })();
    } catch (err) {
      showErrorToaster('Unable to validate referral code, please try again.');
    }
  };

  return (
    <Box>
      <AuthHeader>
        <FormHeading text="Registration" onPressBack={onDecline} />
        <Text pl="0" fontSize="lg" mt="6px">
          To register please choose your country and agree to the company policy.
        </Text>
      </AuthHeader>
      <VStack p="32px" pt="24px">
        <Controller
          control={control}
          rules={{
            validate: (val): boolean | string => !!val || 'Please select country',
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              label="Country residence"
              options={countries.map(item => ({
                label: item.name,
                id: item.id,
              }))}
              isLoading={isCountriesLoading}
              onSelect={onChange}
              error={error}
              selected={value}
              formControlProps={{ zIndex: 1 }}
            />
          )}
          name="country"
        />
        <Controller
          control={control}
          render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
            <LabeledInput
              error={error}
              formControlProps={{ mt: '0' }}
              onChangeText={onChange}
              {...field}
              label="Referral Code (Optional)"
              labelTextProps={{
                color: 'textLabel',
                fontSize: 'lg',
              }}
              placeholder="Referral Code (Optional)"
              color="textRegular"
              fontSize="lg"
              h="56px"
            />
          )}
          name="referralCode"
        />
        <Controller
          control={control}
          rules={{
            validate: (val): true | string => val || 'Please confirm that you agree with our terms and conditions ',
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PressableCheckbox mt="24px" error={error} isChecked={value} onChange={() => onChange(!value)}>
              <HStack flexWrap="wrap" flex="1" alignItems="center">
                <TermsText>I agree to the</TermsText>
                <LinkPurple
                  isExternal
                  _text={{ fontSize: 'lg' }}
                  href="https://cryptowallet.com/app/uploads/2024/06/Updated_Terms_of_Use_18_06.2024.pdf"
                  text="terms of service"
                />
                <TermsText>and</TermsText>
                <LinkPurple
                  isExternal
                  _text={{ fontSize: 'lg' }}
                  href="https://cryptowallet.com/app/uploads/2024/06/Privacy_policy_upd_18_06_2024.pdf"
                  text="privacy policy"
                />
              </HStack>
            </PressableCheckbox>
          )}
          name="terms"
        />
        <ButtonPurple w="100%" h="50px" _text={{ fontSize: 'lg' }} onPress={onSubmit}>
          Continue
        </ButtonPurple>
      </VStack>
    </Box>
  );
};

export default Terms;
