import isEqual from 'lodash/isEqual';
import { HStack } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import React from 'react';

import { ButtonPurple } from '@cryptowallet/frontend/ui';

import { OutlinedButton } from '../Buttons';

interface ITag {
  label: string;
  value?: any[];
}

interface ITagProps extends ITag {
  selected: boolean;
  onPress: (tag: ITag) => void;
}

const Tag = ({ label, value, selected, onPress }: ITagProps) => {
  const TagButton = selected ? ButtonPurple : OutlinedButton;

  return (
    <TagButton
      mr="16px"
      py="0"
      px="10px"
      _text={{
        fontSize: '10px',
        color: selected ? 'white' : 'textLabel',
        textTransform: 'uppercase',
        fontWeight: '500',
        lineHeight: '20px',
      }}
      onPress={() => onPress({ label, value })}>
      {label}
    </TagButton>
  );
};

interface ITagGroupProps {
  tags: ITag[];
  selected: any[];
  onChange: (tag: ITag) => void;
  containerProps?: IHStackProps;
}

const TagGroup = ({ tags, selected, onChange, containerProps = {} }: ITagGroupProps) => (
  <HStack flexWrap="wrap" {...containerProps}>
    {tags.map(({ label, value }) => (
      <Tag key={label} label={label} value={value} selected={isEqual(selected, value)} onPress={onChange} />
    ))}
  </HStack>
);

export default TagGroup;
