import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import CenterElement from '../components/CenterElement';
import { RoundedBox } from '../components/layout';

import WhitelistRegistrationResult from './Whitelist/components/WhitelistRegistrationResult';
import { WhitelistRegisterResult } from './Whitelist/store';

const WhitelistSuccess = () => {
  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  return (
    <CenterElement>
      <RoundedBox background="background.400" borderRadius="10px" overflow="visible" maxW="100vw">
        <WhitelistRegistrationResult result={WhitelistRegisterResult.SUCCESS} />
      </RoundedBox>
    </CenterElement>
  );
};

export default WhitelistSuccess;
