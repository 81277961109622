import { Button as NBButton, IButtonProps } from 'native-base';
import React, { forwardRef } from 'react';

import { setPreventFocusAttribute } from '@cryptowallet/frontend/utils';

export const Button = forwardRef<HTMLElement, IButtonProps>(({ children, ...rest }, forwardedRef) => (
  <NBButton
    ref={r => {
      setPreventFocusAttribute(r);
      if (forwardedRef) {
        // eslint-disable-next-line no-param-reassign
        forwardedRef.current = r;
      }
    }}
    {...rest}>
    {children}
  </NBButton>
));
