/* eslint-disable no-nested-ternary */
import { Box, Divider, HStack, Text, VStack } from 'native-base';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { useConfig } from '@cryptowallet/frontend/ui';

import NavigationAccountsIcon from '../icons/NavigationAccountsIcon';
import NavigationBuySellIcon from '../icons/NavigationBuySellIcon';
import NavigationLogoutIcon from '../icons/NavigationLogoutIcon';
import NavigationReferralIcon from '../icons/NavigationReferralIcon';
import NavigationReferralInvitedUsersIcon from '../icons/NavigationReferralInvitedUsersIcon';

import PrimaryLink from './Links/PrimaryLink';
import NavigationItem, { INavigationItemProps, NavigationItemType } from './NavigationItem';

export const navigationWidth = {
  base: '100vw',
  md: '72px',
  lg: '306px',
};

const Navigation = ({ closeMobileMenu, setShowLogoutConfirmModal }): JSX.Element => {
  const location = useLocation();
  const { isMobile, isBiggerThanTablet } = useScreenSize();
  const { kycPoaHighRiskVerified } = useUserStatus();

  const { exchangerDisabled } = useConfig();

  const elements = useMemo<INavigationItemProps[]>(
    () =>
      [
        {
          id: 'accounts',
          title: 'Wallet',
          icon: ({ color }) => <NavigationAccountsIcon color={color} />,
          type: NavigationItemType.LINK,
          href: '/wallet/widgets',
          basePathName: '/wallet',
        },
        {
          id: 'buy/sell',
          title: 'Buy / Sell',
          icon: ({ color }) => <NavigationBuySellIcon color={color} />,
          type: NavigationItemType.LINK,
          href: '/dashboard/widgets',
          basePathName: '/dashboard',
        },
        // {
        //   id: 'rewards',
        //   title: 'Rewards',
        //   icon: ({ color }) => <NavigationReferralRewardsIcon color={color} />,
        //   type: NavigationItemType.LINK,
        //   href: '/rewards',
        //   basePathName: '/rewards',
        // },
        {
          id: 'referral',
          title: 'Referral',
          icon: ({ color }) => <NavigationReferralIcon color={color} />,
          type: NavigationItemType.ACTION,
          href: '/referral/overview',
          basePathName: '/referral',
        },
        {
          id: 'settings',
          title: 'Account',
          icon: ({ color }) => <NavigationReferralInvitedUsersIcon color={color} />,
          type: NavigationItemType.ACTION,
          href: isMobile ? '/account' : kycPoaHighRiskVerified ? '/account/2fa' : '/account/security',
          basePathName: '/account',
          hasNotification: !kycPoaHighRiskVerified,
        },
        {
          id: 'logout',
          title: 'Log out',
          icon: ({ color }) => <NavigationLogoutIcon color={color} />,
          type: NavigationItemType.ACTION,
          onPress: () => setShowLogoutConfirmModal(true),
        },
      ]
        .filter(item => isMobile || ['accounts', 'buy/sell', 'rewards', 'referral'].includes(item.id))
        .filter(item => !exchangerDisabled || item.id !== 'buy/sell'),
    [setShowLogoutConfirmModal, kycPoaHighRiskVerified, isMobile],
  );

  useEffect(() => {
    if (!isBiggerThanTablet) {
      const focusedElement = document.activeElement;

      if (focusedElement?.blur) {
        focusedElement.blur();
      }
    }
  }, [isBiggerThanTablet]);

  return (
    <Box
      position="fixed"
      left="0"
      top="0"
      h="100vh"
      pt={{
        base: '68px',
        md: '27px',
        xl: '34px',
      }}
      w={navigationWidth}
      borderRightColor="white:alpha.10"
      borderRightWidth="1px"
      zIndex={1}>
      <Divider h="12px" background="black" orientation="horizontal" />
      <VStack
        w="100%"
        h="100%"
        px={{
          base: '24px',
          md: '16px',
        }}
        pt={{
          base: '64px',
          md: '0',
        }}
        background="background.400">
        <VStack pt={{ md: '40px', lg: '67px' }}>
          {elements.map(item => (
            <NavigationItem
              key={item.id}
              active={location.pathname.indexOf(item.basePathName || item.href) !== -1}
              onNavigate={closeMobileMenu}
              {...item}
            />
          ))}
        </VStack>
      </VStack>
      {isMobile && (
        <Box position="fixed" bottom="70px" left="0" w="100%">
          <HStack justifyContent="center" alignItems="center" w="100%">
            <PrimaryLink
              isExternal={true}
              href="https://cryptowallet.com/app/uploads/2024/06/Updated_Terms_of_Use_18_06.2024.pdf"
              mr="5"
              text="Terms & Conditions"
            />
            <PrimaryLink
              isExternal={true}
              href="https://cryptowallet.com/app/uploads/2024/06/Privacy_policy_upd_18_06_2024.pdf"
              mr="5"
              text="Privacy Policy"
            />
            <PrimaryLink
              isExternal={true}
              href="https://cryptowallet.com/terms/cookie-policy/"
              mr="5"
              text="Cookie Policy"
            />
          </HStack>
          <Text fontSize="12px" textAlign="center" mr="24px">
            Romana Dmowskiego 3 / 9, 50-203 Wrocław, Polska
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default Navigation;
