import React from 'react';

const NavigationHelpIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20ZM8.82061 9.79165L8.14104 13.7058C8.11085 13.8834 8.09573 14.0565 8.09573 14.225C8.09573 15.0447 8.49845 15.4545 9.30384 15.4545C9.6109 15.4545 9.90789 15.3726 10.1948 15.2086C10.4868 15.0401 10.7183 14.8557 10.8894 14.6553C11.0656 14.455 11.2317 14.2227 11.3878 13.9586C11.5439 13.6899 11.6445 13.5054 11.6898 13.4053C11.7351 13.3005 11.7679 13.2163 11.788 13.1525L11.3425 12.9407L11.1839 13.2208C11.0933 13.4075 10.9725 13.5942 10.8215 13.781C10.6705 13.9677 10.5447 14.061 10.444 14.061C10.303 14.061 10.2326 13.9677 10.2326 13.781V13.7058L10.9121 9.79165C10.9423 9.61404 10.9574 9.44097 10.9574 9.27252C10.9574 8.45276 10.5597 8.04294 9.76443 8.04294C9.45737 8.04294 9.15785 8.12716 8.86591 8.29568C8.57899 8.46419 8.34742 8.64863 8.17125 8.84899C7.99508 9.04935 7.82895 9.28389 7.6729 9.55256C7.52189 9.81673 7.42121 10.0012 7.37088 10.1059C7.32558 10.2061 7.29287 10.288 7.27273 10.3518L7.72577 10.5636L7.87678 10.2835C7.97241 10.0968 8.09071 9.91006 8.23165 9.72334C8.37763 9.53663 8.50095 9.44325 8.60163 9.44325C8.74761 9.44325 8.82061 9.53663 8.82061 9.72334V9.79165ZM11.8182 5.73403C11.8182 5.38796 11.6974 5.10332 11.4557 4.88015C11.2141 4.65704 10.897 4.54545 10.5044 4.54545C10.1168 4.54545 9.79967 4.65704 9.553 4.88015C9.31139 5.10332 9.19056 5.38796 9.19056 5.73403C9.19056 6.08016 9.31139 6.3648 9.553 6.58791C9.79967 6.81108 10.1168 6.92266 10.5044 6.92266C10.897 6.92266 11.2141 6.81108 11.4557 6.58791C11.6974 6.3648 11.8182 6.08016 11.8182 5.73403Z"
      fill={color}
    />
  </svg>
);

export default NavigationHelpIcon;
