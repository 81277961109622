/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Box, HStack, Text } from 'native-base';
import React from 'react';

import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import {
  exchangerApi,
  ExchangerEstimateResponseDto,
  ExchangerExchangeResponseDto,
  ExchangerExchangeResponseDtoPspTypeEnum,
} from '@cryptowallet/web/api-client';
import CopyBox from '@cryptowallet/web/components/CopyBox';
import { TableType } from '@cryptowallet/web/components/Table';

const SellDetailsContent = ({ tableType, data }: { tableType: TableType; data: ExchangerExchangeResponseDto }) => {
  const { fromAsset } = data as never;

  const estimate = useQuery<ExchangerEstimateResponseDto>(
    [
      'sell-details-estimate',
      data?.fromExchangerAssetId,
      data?.toExchangerAssetId,
      data?.fromAmount,
      data?.expectedFromAmount,
      data?.pspType,
    ],
    async (): Promise<ExchangerEstimateResponseDto> => {
      try {
        if (data?.fromAmount && data?.pspType) {
          return;
        }

        const response = await exchangerApi.exchangerControllerEstimateSellExchange({
          fromExchangerAssetId: data?.fromExchangerAssetId,
          toExchangerAssetId: data?.toExchangerAssetId,
          amount: Number(data.fromAmount || data.expectedFromAmount),
          pspTypes: [data.pspType],
        });

        // eslint-disable-next-line consistent-return
        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
    { ...cacheRequestOption, enabled: true },
  );

  const serviceFee =
    data.estimate?.serviceFeeAmount ||
    (estimate?.data && estimate?.data[data.pspType]?.serviceFeeAmount) ||
    (estimate && estimate.isLoading ? 'loading...' : 'unknown');

  return (
    <Box>
      <HStack flexWrap="wrap">
        <Box
          w={tableType === TableType.FULL ? '32%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            You send
          </Text>
          <Text color="textRegular" fontSize="lg">
            {Number(data.fromAmount || data.expectedFromAmount).toFixed(fromAsset?.decimals)}{' '}
            {fromAsset?.currency.internalTicker}
          </Text>
        </Box>
        <Box w={tableType === TableType.FULL ? '35%' : '100%'} mb="20px">
          <CopyBox
            title={
              <Text color="textLabel" fontSize="lg">
                Transaction ID
              </Text>
            }
            value={data.id} // todo alias
            valueStyles={{ fontSize: 'lg' }}
          />
        </Box>
        <Box
          w={tableType === TableType.FULL ? '32%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Rate
          </Text>
          <Text color="textRegular" fontSize="lg">
            {data.estimate?.estimatedRate || 'unknown'}
          </Text>
        </Box>
        <Box
          w={tableType === TableType.FULL ? '35%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Time
          </Text>
          <Text color="textRegular" fontSize="lg">
            {dayjs(data.createdAt).format('HH:mm:ss')}
          </Text>
        </Box>
        <Box
          w={tableType === TableType.FULL ? '32%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Service fee*
          </Text>
          <Text color="textRegular" fontSize="lg">
            {serviceFee}
          </Text>
        </Box>
        <Box
          w={tableType === TableType.FULL ? '35%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Payment
          </Text>
          <Text color="textRegular" fontSize="lg">
            {data.pspType === ExchangerExchangeResponseDtoPspTypeEnum.Card ? 'Card' : 'Sepa'}
          </Text>
        </Box>
        <Box
          w={tableType === TableType.FULL ? '32%' : '100%'}
          mb="20px"
          mr={tableType === TableType.FULL ? '100px' : '0'}>
          <Text color="textLabel" fontSize="lg">
            Destination tag
          </Text>
          <Text fontWeight="500" color="textRegular" fontSize="lg">
            {data?.payIn?.destinationTag}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default SellDetailsContent;
