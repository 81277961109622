import { Box, HStack, Modal, Text, VStack } from 'native-base';
import React from 'react';

import { CloseButton, Icon, ModalBase } from '../../../index';

const data = [
  {
    title: '1. GENERAL CONDITIONS',
    list: [
      '1.1. The terms and conditions outlined in this document apply to the services and products offered by CW LAB OÜ, a private limited company incorporated in the Republic of Estonia under registration number 14674618, with a registered office at F. R. Kreutzwaldi tn 24 // Tartu mnt 43, Tallinn, Harju maakond, 10128, Estonia („CryptoWallet.com“,“we”,“us”,“our”) and any natural person or legal entity (“user”,“client”,“you”,“your”). ',
      '1.2. Terms of Use govern your access to and use of www.cryptowallet.com (“Website”) and include that of any associated mobile applications (“Applications”). This access includes any analytics, content, services (incl. Digital Assets Wallet), functionality, and media offered by CryptoWallet.com, all of which are referred to herein also as “Products”.',
      '1.3. Please read through these Terms of Use carefully before using any CryptoWallet.com Website, Applications and Products. By using CryptoWallet.com Website, Applications and Products or by clicking to agree or accept the Terms of Use outlined herein, you are agreeing to accept these binding Terms of Use. This also applies to our Privacy Policy, Cookies Policy, and KYC/AML policy and other related Policies. If you do not agree to these Terms of Use or Related Policies, you should not access or otherwise use the CryptoWallet.com Website, Applications and Products.',
      '1.4. You should be aware that your country of residence may impose certain unique restrictions to the Products provided by CryptoWallet.com, and you are responsible for complying with rules and regulations as applicable to your activities with CryptoWallet.com. Use of the Website, Applications and Products is available to those who are 18 years old and older in Estonia, or of legal age in their own country of residence and the user states, that his legal age complies with our and his country requirements.',
      '1.5. The information available on our Website, Applications and Products is for general information purposes only. While we aim to provide accurate and useful information, we do not accept any responsibility for the use of this information, and we cannot warrant its accuracy or usefulness. Use of this information is carried out at your own risk, and we do not accept any liability from consequences users might incur from using the information, such as through loss of funds while selling, exchanging or other trading operations. ',
      '1.6. We reserve the right to update or change the format and content on our Website, Applications and Products without notifying users. This content and format are accepted “as is” and should be used at your own risk. The content on our Website and other Products may include content related to or provided by third parties. We do not accept responsibility for the information and views presented in third-party content. We are not liable to users or third-party content providers for the accuracy or nature of third-party content.The content on our Website and any other Products is not intended to be used as advice of any kind, nor is it intended to be used to inform or guide user selling, exchanging, and trading of the Digital Assets. We do not accept liability for any financial losses incurred while trading with CryptoWallet.com, or for damages of any kind incurred because of anyone using the CryptoWallet.com Website, Applications, Products in any way, including through relying on the information or tools offered by CryptoWallet.com. Trading and investing are at your own risk, and we offer no warranties or implied warranties to the fullest extent permitted by law.',
      '1.7. We cannot and do not offer a guarantee that the content of the CryptoWallet.com Website, Applications and Products is free from viruses, malware, or other harmful software, and it is your responsibility to check for malware and viruses and implement appropriate IT security when using our Website, Applications or Products.',
      '1.8. CryptoWallet.com may revise and update these Terms of Use at our sole discretion, and any changes made are effective immediately. You are expected to check this page from time to time to monitor for any changes, and any changes made will be binding to you and your use of CryptoWallet.com Website, Applications Products. ',
      '1.9. CryptoWallet.com reserves the right to make changes to the Website, or withdraw the Website, and to amend or withdraw any Applications and Products that we offer at our sole discretion. We do not guarantee that our CryptoWallet.com Website, Applications and Products will exist in perpetuity with uninterrupted service, and we will not be liable if our CryptoWallet.com Website, Applications and Products becomes unavailable for any period or at any time.',
      '1.10. To access the Website, you may need to provide registration details, documents, or other information. It is your responsibility to ensure that all this information is valid, current, and correct. Your use of our Website is conditional on you providing correct information and adhering to our Terms of Use and Privacy Policy.',
      '1.11. Apple Applications. If you’re using our CryptoWallet.com Website, Applications and Products via an Apple application, you must do so on a device owned or controlled by you using the Apple iPhone OS. Apple has no obligation to provide you with support or maintenance services regarding this application. Please contact CryptoWallet.com with any questions regarding maintenance or support, rather than contacting Apple. Apple is a third-party beneficiary under the Terms of Use outlined in this document and reserves the right to enforce these terms against you, unless stated otherwise in the Terms of Use. Apple will not be responsible or liable for any third-party claim that your possession or use of the Apple application infringes on intellectual property rights. You state by agreeing with the Terms of Use that you are not in any country subject to US government embargo or that has been determined by the US to be a “terrorist supporting country,” and further agree that you are not on any US government list of prohibited or restricted parties. If your application doesn’t conform to the warranty applying to it, you may in this instance notify Apple and Apple may refund the purchase price of the application. To the fullest extent permitted by law, Apple does not give or enter into any warranty or condition regarding the application and is not liable for claims, losses, or other expenses related to your use of the application or the use of others.',
      '1.12. Android Applications. If you’re using an Android application to access our Website any other Products, this application can only be accessed and used on a device owned or controlled by you. If the Product that you access, and use is an Android Application: By using the application, you are aware that Google has no obligation to provide support or maintenance services regarding the application. For queries about support and maintenance, contact CryptoWallet.com rather than Google. Google is a third-party beneficiary under the Terms of Use outlined in this document and reserves the right to enforce these terms against you, unless stated otherwise in the Terms of Use. Google will not be responsible or liable for any third-party claim that your possession or use of the Google application infringes on intellectual property rights. You state by agreeing with the Terms of Use that you are not in any country subject to US government embargo or that has been determined by the US to be a “terrorist supporting country,” and further agree that you are not on any US government list of prohibited or restricted parties. If your application doesn’t conform to the warranty applying to it, you may in this instance notify Google and Google may refund the purchase price of the application. To the fullest extent permitted by law, Google does not give or enter into any warranty or condition regarding the application and is not liable for claims, losses, or other expenses related to your use of the application or the use of others.',
    ],
  },
  {
    title: '2. SCOPE OF SERVICE',
    subtitle: '2.1. CryptoWallet.com client can use the Website, Application, Products to:',
    list: [
      {
        title: '2.1. CryptoWallet.com client can use the Website, Application, Products to:',
        subList: [
          'open an Client account, herein referred to as the “Account” or “Client Account”, and use the Products (e.g.Wallet) provided by CryptoWallet.com;',
          'buy, sell, and exchange digital assets for other digital assets;',
          'store Digital Assets with a respective third-party service provider;',
          'perform financial operations using the account such as making transactions;',
          'transfer Digital Assets to other CryptoWallet.com users or third-parties',
        ],
      },
      '2.2. Your use of CryptoWallet.com is subject to local laws and regulations, and you are responsible for complying with these laws and regulations. The users have non-exclusive, personal, non-transferable, non-sublicensable and limited rights to use CryptoWallet.com Website any other Products in accordance with the Terms of Use.',
    ],
  },
  {
    title: '3. PROVISION OF SERVICES',
    list: [
      '3.1. User has to open the Client Account with CryptoWallet.com to use the Services and other Products provided.',
      '3.2. CryptoWallet.com has the right to approve or reject applications based on internal policies, and we are not obliged to disclose the reasons for our decision regarding an application.',
      '3.3. The Client should provide all the information and documents needed to open an account. We may request additional information after the account has been opened and approved, and the Client must provide this information to keep using his Client Account.',
      '3.4. In case of change of information and/or documents the Client must inform CryptoWallet.com immediately. We may withhold or stop access to the Services and the Account, if a Client refuses to provide additional information when asked.',
      '3.5. Transaction limits and fees are set at the sole discretion of CryptoWallet.com and are subject to change. The client must inform us at once if he does not agree to these changes, in case he continues to use our Services, that means the Client accepts the changes.',
      '3.6. Client acknowledges and accepts that any losses incurred from losing access to CryptoWallet.com Services is not the responsibility of the CryptoWallet.com, and that the CryptoWallet.com is not liable for these losses.',
    ],
  },
  {
    title: '4. TRANSACTION OPERATIONS',
    subTitle:
      'IMPORTANT: Trading operations with Digital Assets involve a high degree of risk. Values of Digital Assets are subject to fluctuation and there is a significant time lag between placement of your Order and Delivery of Digital Assets to your Account.',
    list: [
      '4.1. The term “transaction operation” herein refers to the sale/exchange/withdrawal of the Digital Assets for or another Digital Assets or fiat currency or vice versa unless stated otherwise.',
      '4.2. Request. The Client independently provides the instructions regarding the transaction type, amount, wallet information and object (crypto/fiat), and initiates the process of the transaction operation. CryptoWallet.com executes the transaction after the client confirms the transaction operation conditions and submitted instructions.NOTE! CryptoWallet.com is not responsible for the information, the activities, or the results of instructions provided by the Client.',
      {
        title:
          '4.3 The Client can see the information regarding the initiated transaction in CryptoWallet.com transaction system, which includes:',
        subList: [
          'requested amount of the subject of the operation;',
          'transaction fees;',
          'wallets or accounts information in accordance with Client instructions;',
          'other fees related to the transaction (e.g. blockchain fee);',
          'rates and time limit, when they are applicable;',
          'the total amount of fiat currency/or crypto currency, which will be deducted from the Client',
        ],
      },
      '4.3. Confirmation and Execution. The initiated transaction will be executed and transferred to Client after CryptoWallet.com has received the confirmation from Client. Confirmation means accepting the initiated transaction and instructions provided by Client and placing the order by the client. Confirmation of transaction is final and will be processed in accordance with Client instructions during the transaction initialization. Confirming the transaction will authorize the CryptoWallet.com to charge commissions and fees from the Client’s account, and if there is an insufficient amount of fiat currency to pay these fees and commissions, the transaction will not go through.',
      '4.4. Transfer. Initiated transaction transfer will be processed immediately, but due to the nature of Blockchain technology, the transaction time might take up to 72 hours. ',
      '4.5. Limitations. Limitations may be applied by CryptoWallet.com on either a single transaction, or on a daily/monthly/yearly transaction volume per Client Account. ',
      '4.6. Fees. By accepting the terms and agreement provided in these Terms of Use, the client agrees to pay CryptoWallet.com a fee for each initiated transaction. Blockchain fees will be automatically calculated in accordance with the actual and required fees for processing blockchain transactions and it’s not charged by CryptoWallet.com. Our fees are subject to change without notice, and Client is obliged to pay these fees when charged. Client acknowledges and agrees with CryptoWallet.com Fees and that CryptoWallet.com may withdraw funds of any amount from the Client Account to cover such fees.',
      '4.7. Fraud attempts. If CryptoWallet.com suspects fraud on the part of any Client, we may suspend or terminate the Client Account and reverse payouts. We also reserve the right to contact law enforcement agencies, financial regulators, and third parties regarding any suspected fraud. CryptoWallet.com is not liable for any fraud or illegal activity carried out by Client.',
    ],
  },
  {
    title: '5. STORAGE FEE',
    list: [
      '5.1. Client acknowledges and agrees that CryptoWallet.com might apply the storage fee for inactive accounts.',
      '5.2. If a Client Account has become the subject of storage fee, charging the storage fee will stop during the next business day after you start transacting with the CryptoWallet.com Services. Storage fee will be charged to store your balances if you don’t transact using CryptoWallet.com Services for six (6) months up to twelve (12) months. Transacting includes withdrawal, deposit, exchange, or transfer.  ',
      '5.3. The Client will be informed about the applicable storage fee and its amount after his account will be inactive for a period of 6 months. ',
    ],
  },
  {
    title: '6. REFUND, CANCELATION AND CHARGEBACK ',
    list: [
      '6.1. The client acknowledges and agrees that All transactions are final. Refunds, cancellations, or chargebacks are not applicable for all kinds of transactions. Even if the transaction becomes a subject of technical delay, chargeback will not be applicable and CryptoWallet.com will process and transfer the Digital Assets in accordance with client instructions accepted during the transaction initialization, it means that client will anyway receive the requested amount and type of the Digital Assets.',
      '6.2. CryptoWallet.com processes all digital asset transactions according to the instructions and information provided by the client and does not guarantee the identity of any recipient. Client acknowledges that he is responsible for ensuring the accuracy of any instructions or information submitted to CryptoWallet.com and that any errors may result in the irreversible loss of his stored digital asset.',
      '6.3. CryptoWallet.com does not accept liability or responsibility for any funds lost due to errors made by the client when entering this information. It is possible to permanently lose or destroy certain digital assets by sending them to an incompatible address.',
    ],
  },
  {
    title: '7. TERMINATION ',
    list: [
      '7.1. Either CryptoWallet.com or our client can terminate the relationship between us at any time and with no reason given, provided there are no outstanding settlements or pending transactions. ',
      '7.2. CryptoWallet.com can terminate client access to the Website, Application and Products at the sole discretion and the Client will not be entitled to a remedy or alternative for discontinued services.',
      {
        title:
          '7.3. Among other reasons not listed, CryptoWallet.com may also suspend or terminate your account for any or all the following reasons: ',
        subList: [
          'unlawful or unauthorized attempt to access the Website, Applications and Products',
          'breach of security features limiting use access to or protecting content',
          'violations of this Terms of Use;',
          'due to the request of legal authorities or financial regulators, provided that CryptoWallet.com deems the request to be legitimate and compelling acting in its sole discretion. ',
          'unusual, unauthorized or fraudulent activity in your Account',
          'accounts that have been inactive for a period of 6 months may be canceled, deactivated, terminated along with unconfirmed accounts. CryptoWallet.com is not liable for any issues resulting in terminating a relationship or deactivating an account.',
          'CryptoWallet.com reserves the right to change, suspend, or discontinue all or any part or aspect of the services at any time without notice to the client or Website visitors.',
        ],
      },
    ],
  },
  {
    title: '8. WARRANTIES & ACKNOWLEDGMENT ',
    list: [
      '8.1. Client (and/ or representative of the Client, when the Client is a legal person) states the following: ',
      '8.1.1. Client is legally an adult and has read and understood these Terms of Use, and has full capacity to enter into these Terms of Use and use Services lawfully;',
      '8.1.2. Client adheres to relevant legal and regulations and rules based on their country of residence;',
      '8.1.3. Client is solely responsible for lawful handling of taxation as outlined in applicable tax regulations;',
      '8.1.4. Client is authorized to use CryptoWallet.com and is doing so personally;',
      '8.1.5. Client maintains information such as personal data, financial details, and information regarding their activities on CryptoWallet.com confidential; The data the client provides when registering for CryptoWallet.com is accurate, complete and current',
      '8.1.6. Client ensures a level of security appropriate to the risk in respect of the personal data and securing authorization credentials; ',
      '8.1.7. The client is aware that funds held on the Client account are not considered as a deposit, and that CryptoWallet.com services are not associated with banking or financial institution services which require a specific license.',
    ],
  },
  {
    title: '9. RISK WARNING',
    list: [
      '9.1. The client is responsible for any losses related to the sale, purchase, or exchange of Digital Assets. ',
      '9.2. There are risks inherent to the trade of Digital Assets such as market volatility, liquidity risk, and others. The client is solely responsible for all legal risks associated with these services. ',
      '9.3. The client is responsible for any losses related to the sale, purchase, or exchange of Digital Assets. ',
      '9.4. Prices are based on an aggregate provided by CryptoWallet.com. ',
      '9.5. CryptoWallet.com is not liable for technical delays on the CryptoWallet.com Website, Applications and Products we offer, whether these delays affect price or other aspects of service.',
      '9.6. The time it takes to transfer Digital Assets is subject to market conditions and the mining fee attached to the transaction.',
      '9.7. In the event of unauthorized use of services, CryptoWallet.com neither bears any responsibility nor covers any losses.',
      '9.8. Client must provide accurate information when completing information requests to avoid delays and mistakes in processing transactions and payouts. This includes ensuring that spelling is correct.',
      '9.9. CryptoWallet.com can change Terms of Use without notice and the client is expected to monitor the Terms of Use for changes. If major changes are made CryptoWallet.com will notify clients of said changes and these changes are then effective within 10 days. ',
    ],
  },
  {
    title: '10. INTELLECTUAL PROPERTY ',
    list: [
      '10.1. Business name. CryptoWallet.com logo, name, and related logos, names, slogans, designs, and media are the intellectual property of CryptoWallet.com or affiliates. Unauthorized use is not allowed. None of the contents of the CryptoWallet.com Website, Applications and Products grant license or rights to use these media in any way without express written permission upon application. ',
      {
        title:
          '10.2. Intellectual Property Rights. Use of our Website, Services and the Products is permitted for your personal, non- commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website and any other Products. Exceptions can be made in cases where:',
        subList: [
          'Copies of our IP materials are temporarily stored by your computer in RAM;',
          'Files are automatically cached by your browser.',
          'Media has been printed for personal use such as leaving a single copy of a web page printed in your residence for non-commercial use.',
          'Applications that enable downloading will store information on your device as per Terms of Use.',
        ],
      },
      {
        title: '10.3. Do not:',
        subList: [
          'Alter materials on the website;',
          'Make unauthorized copies of materials on the website;',
          'Delete or make changes to any copyright materials created by CryptoWallet.com;',
          'Access or use the website for commercial or unauthorized purposes;',
          'Contact help@cryptowallet.app to request permission to use CryptoWallet.com IP for any purpose.',
        ],
      },
      '10.4. Breaking any of the terms outlined regarding IP will immediately revoke your right to use the Website and any other Products associated. You must cease all action regarding unauthorized use of IP at once upon realizing the breach of Terms of Services and destroy or return any copies of materials as instructed by CryptoWallet.com. Breach of these Terms of Use may violate copyright, trademark and other laws. ',
    ],
  },
  {
    title: '11. PROHIBITED USE',
    list: [
      '11.1. The Website, Services and any other Products can only be used for lawful purposes.',
      '11.2. Do not use the Website and any other Products in such a way that violates local or international laws or regulations, including any laws regarding the export of data or software to and from the EU or other countries. ',
      '11.3. Do not use the Website and any other Products for the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise. ',
      '11.4. Do not use the Website and any other Products to send or receive or otherwise handle materials l which do not comply with these Terms of Use. ',
      '11.5. Do not use the Website and any other Products to send advertising or promotional material without our prior written consent.',
      '11.6. Do not use the Website and any other Products to impersonate CryptoWallet.com staff or any other party that you do not represent. ',
      '11.7. Do not use the Website and any other Products to attempt to harm CryptoWallet.com users or otherwise restrict or inhibit anyone’s use or enjoyment of the Website and any other Products.',
      '11.8. Do not use the Website and any other Products to republish, redistribute or re-transmit any data from any of our communications, analytics, and other Products without our permission; ',
      '11.9. Do not use the Website and any other Products for copy or store any of our Products other than for your own personal non-commercial use. Unauthorized or commercial use is forbidden.',
      '11.10. Do not use the Website, Applications and Products to store or host any Products (including pages of a Website) on a server or other storage device connected to a network or create a database by systematically downloading and storing any data from the Website or the Product;',
      '11.11. Do not use the Website and any other Products to remove or change any content of any Product or attempt to circumvent security or interfere with the proper working of the Product or any servers on which it is hosted; ',
      '11.12. Do not create links to the Website from any other website without our prior written consent unless you are the webmaster of the other site and you are aware that the link is not misleading, fairly indicates the destination, contains no statement implying a CryptoWallet.com endorsement, and links only to the CryptoWallet.com website. Do not replicate CryptoWallet.com IP as a Meta tag. This is permitted only in the event that the website does not contain unlawful, threatening, libelous, pornographic, or offensive content.',
      '11.13. Do not use automated means to access the website for any reason.',
      '11.14. Do not create any financial product or service based on our products or services. ',
      '11.15. Do not attempt to interfere with the proper working of the website. ',
      '11.16. Do not use the Products or Website in any way not expressly permitted by these Terms of Use of the Website and any other Products must be carried out in a lawful manner and not in such a way that could damage our reputation or those of our affiliates or partners.',
      '11.17. Any permissions or rights given to you under these Terms of Use will terminate immediately in the event that you breach or fail to comply with any of these Terms of Use. ',
      '11.18. To use the Website and any other Products in any way that is not expressly permitted by these Terms of Use, you will need a separate license from us. Please contact us via support@CryptoWallet.com.',
    ],
  },
  {
    title: '12. USER INFORMATION',
    list: [
      '12.1. Any information we collect is subject to our Privacy Policy. When you use the Website, Services, and any other Products you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. ',
    ],
  },
  {
    title: '13. ACCURACY OF WEB CONTENT',
    list: [
      '13.1. Content may be updated occasionally, but this does not imply that the content is always up to date or complete. Material on our CryptoWallet.com Website, Applications and Products may be out of date, and we are not obligated or required to update it.',
    ],
  },
  {
    title: '14. CONFIDENTIAL INFORMATION',
    list: [
      '14.1. When you use or access the Website, Services or any Products, data may be transmitted over an open network in such a way that communications could be intercepted by third parties. This is not our responsibility or liability, and we cannot guarantee the confidentiality or security of any communication or data transmitted to us through the Website.',
    ],
  },
  {
    title: '15. LINKS FOR THE WEBSITE',
    list: [
      '15.1. If CryptoWallet.com Website, Applications and Products contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to any Website, you do so entirely at your own risk and subject to the terms and conditions of use for such website. We reserve the right to withdraw linking permission without notice.',
    ],
  },
  {
    title: '16. THIRD PARTY SERVICE',
    list: [
      '16.1. Third party vendors and partners may use the CryptoWallet.com Website, Applications and Products, and may contribute to the production and development of both. Some of these third parties may require users to comply with additional terms and conditions. This page identifies third- party terms and conditions that are required by such third-party vendors and business partners as they apply to the features set forth below, and these conditions are subject to change at the third party’s discretion. ',
    ],
  },
  {
    title: '17. DISCLAIMERS OF WARRANTIES',
    list: [
      '17.1. As stated earlier, we cannot guarantee that the Website, Applications and Products will be free from malware, viruses, or harmful code, and your IT security is your responsibility. We are not liable for losses or costs caused by a distributed denial-of-service attack or other technologically harmful material that may infect your computer systems or equipment, or data or other other proprietary material due to your use of the website. Your use of the Website is at your own risk, and its content is provided on an “as is” basis with no warranties (implied or otherwise).',
      '17.2. Neither the CryptoWallet.com nor any person associated with the CryptoWallet.com makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Products. ',
      '17.3. We cannot guarantee, nor can anyone associated with CryptoWallet.com, that any of the content or services will be accurate, reliable, free of errors, uninterrupted, or in service in perpetuity.',
      '17.4. CryptoWallet.com disclaims warranties of any kind, including express, limited, and statutory warranties to the fullest extent permitted by law.',
    ],
  },
  {
    title: '18. LIMITATION ON LIABILITY',
    list: [
      '18.1. Neither the CryptoWallet.com nor its affiliates, service providers, employees, associates, licensors, officers, or directors will be held liable for damages arising from the use or inability to use our CryptoWallet.com Website, Applications and Products, or any associated sites or products. This includes, but is not limited to, loss of revenue, personal injury, emotional injury or distress, loss of goodwill, reputational damage, loss of profits, breach of contract, and loss of anticipated earnings.',
      '18.2. The CryptoWallet.com will not be liable for any loss or damage arising from any use of your account by you or by any third party, including unauthorized use and cyber-attacks.',
    ],
  },
  {
    title: '19. INDEMNIFICATION',
    list: [
      '19.1. You agree to defend, indemnify and hold harmless the CryptoWallet.com, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of any Website, Services or any other Products, including, but not limited to, any use of any Website’s content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from CryptoWallet.com.',
    ],
  },
  {
    title: '20. COMPLAINTS AND DISPUTE RESOLUTION',
    list: [
      '20.1. For questions, feedback, or complaints, contact us via the support ticket system on https://cryptowallet.com/ or via email at help@cryptowallet.app.',
      '20.2. For complaints, please outline the cause of the complaint, your desired outcome, and any other relevant information. CryptoWallet.com will consider your complaint without prejudice, and your complaint will be handled in a reasonable amount of time. You agree to use the complaints process before alternative solutions such as legal action. You agree to use the complaints procedure before filing claims with a bank in case of stolen cards or other issues. Failure to comply with these provisions may be used as evidence of your fraudulent conduct, bad faith, or unwillingness to settle the issue.',
    ],
  },
  {
    title: '21. MISCELLANEOUS ',
    list: [
      '21.1. These Terms of Use are binding for both us and the client. The client may not assign any of their rights or obligations outlined under these Terms of Use to a third party unless authorized to do so in writing by CryptoWallet.com.',
      '21.2. The client accepts that we may transfer and/or assign rights and obligations listed in these Terms of Use at the sole discretion of CryptoWallet.com. ',
      '21.3. If any of the provisions in these Terms of Use are deemed illegal, invalid, or unenforceable, we will enforce the provision only to the extent that this is legally possible, and this does not impact the legality or enforceability, or validity of any other agreements outlined in the Terms of Use.',
      '21.4. Where a discrepancy between various translations occurs, the English translation of the Terms of Use shall be taken to be the prevailing version. ',
      '21.5. All section titles in these Terms of Use exist to allow you to navigate it more easily and are solely for your convenience. The section titles do not have any legal or contractual significance.’',
    ],
  },
  {
    title: '22. GEOGRAPHIC RESTRICTIONS',
    list: [
      '22.1. CryptoWallet.com is owned by a limited CryptoWallet.com based in Estonia and makes no claims that the Website, Applications and Product of its content is accessible or appropriate outside of Estonia. Access may not be legal by certain persons or in certain countries. Access to the CryptoWallet.com from other regions is done at your own initiative and you must comply with your own local laws.',
    ],
  },
  {
    title: '23. JURISDICTION',
    list: [
      '23.1. Any legal claim or dispute regarding the use of the Website or other Products and these Terms of Use shall be governed by the internal laws of the Republic of Estonia without giving effect to any choice or conflict of law provision or rule (whether of Estonia or any other jurisdiction). ',
      '23.2. Any lawsuit or legal action as mentioned above will be instituted exclusively in the courts of the Republic of Estonia. CryptoWallet.com reserves the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country.',
      '23.3. In agreeing to these Terms of Use, you waive all objections to the exercise of jurisdiction over you by such courts and to venues in such courts. Exceptions may be made if the prevailing legislation relevant to the dispute or claim states otherwise.',
    ],
  },
  {
    title: '24. CONTACT DETAILS',
    list: [
      '24.1. If you have any queries related to these Terms of Use, please feel free to get in touch with our support staff at help@cryptowallet.app.',
    ],
  },
];

export const TermsAlert = ({ onClose }: { onClose: () => void }) => (
  <ModalBase blurredBg={true} onClose={onClose}>
    <Modal.Content w="996px" maxW="100vw" overflowY="auto">
      <VStack p="40px" bg="background.400">
        <Modal.Header p="0" borderColor="gray.200" borderBottomWidth="0">
          <HStack justifyContent="space-between" alignItems="center" pr="26px">
            <Text color="white" fontSize="3xl">
              Terms and Conditions
            </Text>
            <CloseButton
              position="absolute"
              top="4px"
              right="0"
              iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }}
              onPress={onClose}
            />
          </HStack>
        </Modal.Header>
        <Modal.Body p="0">
          {data.map(({ title, list, subTitle }, index) => (
            <Box key={index}>
              <Text mt="20px" mb="19px" color="white" fontSize="20px">
                {title}
              </Text>
              {subTitle && (
                <Text mt="20px" mb="19px" color="white" fontSize="lg">
                  {subTitle}
                </Text>
              )}
              <VStack>
                {list.map((item, subIndex) => {
                  if (typeof item === 'string') {
                    return (
                      <VStack key={subIndex} mb="6px" pl="18px">
                        <Text color="textRegular" fontSize="lg">
                          {item}
                        </Text>
                      </VStack>
                    );
                  }

                  return (
                    <VStack key={subIndex} mb="6px" pl="18px">
                      <Text color="textRegular" fontSize="lg">
                        {item.title}
                      </Text>
                      {item.subList.map((subText, i) => (
                        <VStack key={`${subIndex}-${i}`} mb="6px" pl="36px">
                          <Icon position="absolute" top="4px" left="18px" h="10px" w="12px" name="check-icon-small" />
                          <Text color="textRegular" fontSize="lg">
                            {subText}
                          </Text>
                        </VStack>
                      ))}
                    </VStack>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </Modal.Body>
      </VStack>
    </Modal.Content>
  </ModalBase>
);
