import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import qs from 'qs';
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { partnersApi } from '@cryptowallet/frontend/api';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { cacheRequestOption, getPlatform, setQueryString } from '@cryptowallet/frontend/utils';

const PartnersSettingsContext = createContext({});
const isWeb = getPlatform() === 'web';

const partnersStorage = () =>
  isWeb
    ? window.localStorage.getItem(commonConstants.PersistKeys.partnerId)
    : AsyncStorage.getItem(commonConstants.PersistKeys.partnerId);

export const usePartnerSettingsData = () => {
  const queryData = qs.parse(window?.location?.search?.slice(1));
  const storagePartnerId = isWeb ? window.localStorage.getItem(commonConstants.PersistKeys.partnerId) : '';

  const partnerId = queryData.partnerId || storagePartnerId || '';

  const enabled = !!partnerId;

  const { data: response, isLoading } = useQuery(
    ['partnerSettings', partnerId],
    async () => {
      try {
        return await partnersApi.partnersControllerGetPartnerSettings(partnerId as string);
      } catch (error) {
        window.localStorage.removeItem(commonConstants.PersistKeys.partnerId);
        setQueryString('');

        throw error;
      }
    },
    {
      ...cacheRequestOption,
      enabled,
    },
  );

  const data = useMemo(
    () => ({
      styles: response?.data.styles || {},
      css: response?.data.css || null,
      palette: response?.data.palette || {},
    }),
    [response?.data],
  );

  return {
    data,
    enabled,
    isLoading,
    partnerId,
  };
};

interface IComponentPartnerStyles {
  [key: string]: string | IComponentPartnerStyles;
}

interface IPartnersSettingsProviderProps {
  children: ReactNode;
  isLoading: boolean;
  enabled: boolean;
  data: {
    styles: IComponentPartnerStyles | null;
    palette: Record<string, string> | null;
    css: string | null;
  };
}

export const PartnersSettingsProvider = ({ children, isLoading, enabled, data }: IPartnersSettingsProviderProps) => (
  <PartnersSettingsContext.Provider value={data}>
    {isLoading && enabled ? null : children}
  </PartnersSettingsContext.Provider>
);

export const usePartnerStyles = (selector: string): IComponentPartnerStyles => {
  const { styles } = useContext(PartnersSettingsContext || createContext({}));

  return get(styles, selector) || {};
};
