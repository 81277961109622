import dayjs from 'dayjs';
import { Text } from 'native-base';
import React from 'react';

import { ColumnType } from '../Row';

export interface IDateColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: ColumnType.DATE;
}

interface IDateProps {
  data: any;
  columnDef: IDateColumnDef;
}

export const CDate = ({ data }: IDateProps) => {
  const value = data ? dayjs(data).format('DD.MM.YYYY') : '-';

  return (
    <Text fontSize="lg" fontWeight="500" color="textRegular">
      {value}
    </Text>
  );
};
