import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';
import qs from 'qs';
import { create } from 'zustand';
import { createJSONStorage } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';

import { useActiveBuyPaymentMethods, useDebounce } from '../../hooks';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { longPollingOptions, persist, getEnv, setQueryString, defaultRetryPolicy } from '@cryptowallet/frontend/utils';
import {
  ExchangerAssetEntityDto,
  ExchangerCreateExchangeRequestDtoPspTypeEnum,
  ExchangerEstimateResponseDto,
} from '../../client/src';
import { exchangerApi } from '../../api/src';
import { queryStore } from '../query-store';

const env = getEnv();

const DEFAULT_WALLET_ADDRESS = env.NX_DEFAULT_WALLET_ADDRESS;
const RATE_UPDATE_TIMEOUT_MS = 10000;
interface IPaymentMethodValidationValues {
  min: number;
  max: number;
}

interface IValidationValues {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]?: IPaymentMethodValidationValues;
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]?: IPaymentMethodValidationValues;
}

export interface BuyFlowState {
  stepIndex: number;
  setStepIndex: (stepIndex: number) => void;
  paymentMethod: ExchangerCreateExchangeRequestDtoPspTypeEnum;
  setPaymentMethod: (method: ExchangerCreateExchangeRequestDtoPspTypeEnum) => void;
  fromCurrency: ExchangerAssetEntityDto;
  fromCurrencyId: string;
  setFromCurrency: (currency: ExchangerAssetEntityDto) => void;
  setFromCurrencyId: (fromCurrencyId: string) => void;
  toCurrency: ExchangerAssetEntityDto;
  setToCurrency: (currency: ExchangerAssetEntityDto) => void;
  toCurrencyId: string;
  setToCurrencyId: (toCurrencyId: string) => void;
  amount: string;
  setAmount: (amount: string) => void;
  validationValues: IValidationValues;
  setValidationValues: (validationValues: IValidationValues) => void;
  walletAddress: string;
  setWalletAddress: (walletAddress: string) => void;
  destinationTag: string;
  setDestinationTag: (destinationTag: string) => void;
  exchangeId: string;
  setExchangeId: (exchangeId: string) => void;
  nextRateUpdateTime: number;
  setNextRateUpdateTime: (newDate: number) => void;
  useMyWalletAddress: boolean;
  setUseMyWalletAddress: (useMyWalletAddress: boolean) => void;
  isPersonalAccount: boolean;
  setIsPersonalAccount: (isPersonalAccount: boolean) => void;
  setExchangeData: ({
    fromCurrency,
    toCurrency,
    paymentMethod,
    amount,
    walletAddress,
  }: {
    fromCurrency: ExchangerAssetEntityDto;
    toCurrency: ExchangerAssetEntityDto;
    paymentMethod: ExchangerCreateExchangeRequestDtoPspTypeEnum;
    amount: string;
    walletAddress: string;
  }) => void;
  resetValidationValues: () => void;
  reset: () => void;
  resetPersistedSlice: () => void;
  resetRegularSlice: () => void;
  initQueryState: (excludeKeys: string[]) => void;
}

const initialPersistState = {
  useMyWalletAddress: false,
  walletAddress: DEFAULT_WALLET_ADDRESS,
  destinationTag: '',
  isPersonalAccount: false,
};

const initialQueryState = {
  paymentMethod: ExchangerCreateExchangeRequestDtoPspTypeEnum.Card,
  fromCurrencyId: '',
  toCurrencyId: '',
  amount: commonConstants.DEFAULT_BUY_AMOUNT,
  exchangeId: '',
};

const initialValidationValues = {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: {
    min: 0.000001,
    max: Number.MAX_SAFE_INTEGER,
  },
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: {
    min: 0.000001,
    max: Number.MAX_SAFE_INTEGER,
  },
};

const initialRegularState = {
  stepIndex: 0,
  fromCurrency: null,
  toCurrency: null,
  nextRateUpdateTime: null,
  validationValues: initialValidationValues,
};

export const getBuyFlowQueryFields = () => Object.keys(initialQueryState);

const buyFlowTransformer = (key, value) => {
  const initialStateValue = initialQueryState[key];
  switch (key) {
    case 'amount': {
      const numberValue = Number(value);
      return Number.isNaN(numberValue) ? initialStateValue : numberValue;
    }
    case 'paymentMethod': {
      return value && Object.values(ExchangerCreateExchangeRequestDtoPspTypeEnum).includes(value)
        ? value
        : initialStateValue;
    }
    default: {
      return value;
    }
  }
};

const getExcludedKeys = ({ exchangeId }, queryFields, newValue) => {
  if (exchangeId || newValue.exchangeId) {
    return queryFields.filter(item => item !== 'exchangeId');
  }

  return ['exchangeId'];
};

const useRegularSlice = (set, get, api) => ({
  ...initialRegularState,
  setStepIndex: stepIndex => set({ stepIndex }),
  setFromCurrency: fromCurrency => set({ fromCurrency }),
  setToCurrency: toCurrency => set({ toCurrency }),
  setNextRateUpdateTime: nextRateUpdateTime => set({ nextRateUpdateTime }),
  setValidationValues: validationValues => set({ validationValues }),
  resetValidationValues: () => set({ validationValues: initialValidationValues }),
  resetRegularSlice: () => set(initialRegularState),
});

const usePersistedSlice = persist(
  (set, get) => ({
    ...initialPersistState,
    setUseMyWalletAddress: useMyWalletAddress => set({ useMyWalletAddress }),
    setWalletAddress: walletAddress => set({ walletAddress }),
    setDestinationTag: destinationTag => set({ destinationTag }),
    setIsPersonalAccount: isPersonalAccount => set({ isPersonalAccount }),
    resetPersistedSlice: () => set(initialPersistState),
  }),
  {
    name: commonConstants.PersistKeys.DashboardBuyFlow,
    storage: createJSONStorage(() => window.localStorage),
    partialize: state => {
      const excludedPersistKeys = [...Object.keys(initialRegularState), ...Object.keys(initialQueryState)];

      return Object.fromEntries(Object.entries(state).filter(([key]) => !excludedPersistKeys.includes(key)));
    },
  },
);

export const getBuySafeQueryStateExcludeKeys = () => {
  const queryState = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return queryState.exchangeType === commonConstants.ExchangeTypeEnum.Sell ? ['exchangeId'] : [];
};

const useQuerySlice = queryStore(
  (set, get, api, initQueryState, getSafeQueryState) => {
    const safeQueryState = getSafeQueryState ? getSafeQueryState(getBuySafeQueryStateExcludeKeys()) : {};

    return {
      ...initialQueryState,
      ...safeQueryState,
      setToCurrencyId: toCurrencyId => set({ toCurrencyId }),
      setFromCurrencyId: fromCurrencyId => set({ fromCurrencyId }),
      setPaymentMethod: paymentMethod => set({ paymentMethod }),
      setAmount: amount => set({ amount }),
      setExchangeId: exchangeId => set({ exchangeId }),
      resetQuerySlice: () => {
        setQueryString();
        set(initialQueryState);
      },
      initQueryState,
    };
  },
  {
    queryFields: getBuyFlowQueryFields(),
    transformer: buyFlowTransformer,
    getExcludedKeys,
    pathname: '/dashboard/widgets',
  },
);

export const useStore = create<BuyFlowState>()((set, get, api) => {
  const persistedSlice = usePersistedSlice(set, get, api);
  const querySlice = useQuerySlice(set, get, api);
  const regularSlice = useRegularSlice(set, get, api);

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...persistedSlice,
    ...querySlice,
    ...regularSlice,
    setToCurrency: toCurrency => {
      regularSlice.setToCurrency(toCurrency);
      querySlice.setToCurrencyId(toCurrency.id);
    },
    setFromCurrency: fromCurrency => {
      regularSlice.setFromCurrency(fromCurrency);
      querySlice.setFromCurrencyId(fromCurrency.id);
    },
    setExchangeData: ({ fromCurrency, toCurrency, paymentMethod, amount, walletAddress }) => {
      set({
        fromCurrency,
        toCurrency,
      });
      querySlice.setPaymentMethod(paymentMethod);
      querySlice.setAmount(amount);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      persistedSlice.setWalletAddress(walletAddress);
    },
    reset: () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      persistedSlice.resetPersistedSlice();
      querySlice.resetQuerySlice();
      regularSlice.resetRegularSlice();
    },
  };
});

// if (process.env.NODE_ENV !== 'production') {
//   useStore.subscribe(console.log); // eslint-disable-line no-console
// }

export const estimatePlaceholder = {
  isPlaceholder: true,
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: {
    estimatedAmount: '',
    networkFeeAmount: '-',
    serviceFeeAmount: '-',
    bankFeeAmount: '-',
    estimatedRate: '-',
  },
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: {
    estimatedAmount: '',
    networkFeeAmount: '-',
    serviceFeeAmount: '-',
    bankFeeAmount: '-',
    estimatedRate: '-',
  },
};

export const useEstimate = (enabled = true, updateTimeMs = RATE_UPDATE_TIMEOUT_MS) => {
  const {
    fromCurrency,
    toCurrency,
    amount,
    setNextRateUpdateTime,
    paymentMethod,
    validationValues,
    setValidationValues,
    exchangeId,
    resetValidationValues,
  } = useStore(
    state =>
      pick(state, [
        'paymentMethod',
        'fromCurrency',
        'toCurrency',
        'amount',
        'setNextRateUpdateTime',
        'validationValues',
        'setValidationValues',
        'exchangeId',
        'resetValidationValues',
      ]),
    shallow,
  );

  const activePaymentMethods = useActiveBuyPaymentMethods();

  const debouncedAmount = useDebounce(amount, 1000);

  const activeValidationValues = validationValues[paymentMethod];

  const result = useQuery<ExchangerEstimateResponseDto>(
    ['exchange-estimate-buy', fromCurrency, toCurrency, paymentMethod, debouncedAmount, validationValues],
    async (): Promise<ExchangerEstimateResponseDto> => {
      try {
        // we do not do any estimation until amount is valid
        // except exchangeId already exists, it means amount was already validated

        if (!exchangeId && (+amount < activeValidationValues.min || +amount > activeValidationValues.max)) {
          return estimatePlaceholder as never;
        }
        const response = await exchangerApi.exchangerControllerEstimateBuyExchange({
          fromExchangerAssetId: fromCurrency.id,
          toExchangerAssetId: toCurrency.id,
          amount: +debouncedAmount,
          pspTypes: activePaymentMethods,
        });

        setNextRateUpdateTime(Date.now() + updateTimeMs);

        return response.data;
      } catch (err) {
        console.error(err); // todo

        // @todo do something here
        throw err;
      }
    },
    {
      enabled: enabled && !!(fromCurrency && toCurrency),
      retry: defaultRetryPolicy,
      ...longPollingOptions(updateTimeMs),
    },
  );

  const { data, isFetching } = result;

  useEffect(() => {
    resetValidationValues();
  }, [toCurrency]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!data || data.isPlaceholder || isFetching) {
      return;
    }

    const newValidationValues = Object.entries(data).reduce<IValidationValues>((acc, [method, estimate]) => {
      acc[method] = {
        min: estimate.amountMin,
        max: estimate.amountMax,
      };

      return acc;
    }, {});

    setValidationValues(newValidationValues);
  }, [data, paymentMethod, isFetching]);

  return {
    ...result,
    validationValues: activeValidationValues,
  };
};
