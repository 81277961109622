import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

const useWindowSize = (delay = 300) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    const resize = debounce(handleResize, delay);

    window.addEventListener('resize', resize);
    handleResize();
    return () => window.removeEventListener('resize', resize);
  }, []);

  return windowSize;
};

export default useWindowSize;
