import { Box, Text } from 'native-base';
import React from 'react';

import { useScreenSize, useWalletConfig, useWithdrawEstimate } from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import TransactionDetailsCollapseBox from '@cryptowallet/web/components/TransactionDetailsCollapseBox';

const WithdrawTransactionDetails = () => {
  const { isMobile } = useScreenSize();
  const { networkFee, feeAsset } = useWithdrawEstimate();
  const { walletFeeLevel } = useAccountsStore(state => state.withdrawData);
  const { isLoading: walletConfigLoading } = useWalletConfig();

  const isReady = !walletConfigLoading;

  if (!isReady) return null;

  return (
    <TransactionDetailsCollapseBox my="40px">
      <Box mt="20px" flexDirection="row" flexWrap="wrap">
        <Box w={isMobile ? '100%' : '50%'}>
          <Text color="textLabel" fontSize="lg">
            Network fee ({walletFeeLevel.toLowerCase()})
          </Text>
          <Text color="textRegular" fontSize="lg">
            {trimCryptoAmountLength(networkFee.toString(), 9)} {feeAsset?.currency?.ticker}
          </Text>
        </Box>
        <Box mt={{ base: '16px', lg: '0' }} w={isMobile ? '100%' : '50%'}>
          <Text color="textLabel" fontSize="lg">
            Estimated arrival
          </Text>
          <Text color="textRegular" fontSize="lg">
            5-15 min
          </Text>
        </Box>
      </Box>
    </TransactionDetailsCollapseBox>
  );
};

export default WithdrawTransactionDetails;
