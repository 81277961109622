import React from 'react';

const NavigationInviteFriendIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7108 4.99999C13.7108 7.76141 11.4786 9.99999 8.72507 9.99999C5.97152 9.99999 3.73932 7.76142 3.73932 5C3.73932 2.23857 5.97151 -1.04409e-06 8.72507 -1.52554e-06C11.4786 -2.00699e-06 13.7108 2.23857 13.7108 4.99999Z"
      fill={color}
    />
    <path
      d="M5.74132 11.25C1.6918 11.9007 2.20149e-06 12.5911 2.60231e-06 14.8835C3.09638e-06 17.7092 3.49691e-06 20 8.72508 20C18.6966 20 17.4501 17.7092 17.4501 14.8835C17.4501 12.591 15.7584 11.9007 11.7088 11.25C10.8769 11.8177 9.84402 12.154 8.72507 12.154C7.60613 12.154 6.57327 11.8177 5.74132 11.25Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 5C17.8766 5 18.1818 5.30526 18.1818 5.68182L18.1818 6.81819L19.3182 6.81819C19.6947 6.81819 20 7.12345 20 7.50001C20 7.87657 19.6947 8.18183 19.3182 8.18183L18.1818 8.18183L18.1818 9.31818C18.1818 9.69473 17.8766 9.99999 17.5 9.99999C17.1234 9.99999 16.8182 9.69473 16.8182 9.31818L16.8182 8.18183L15.6818 8.18183C15.3053 8.18183 15 7.87657 15 7.50001C15 7.12345 15.3053 6.81819 15.6818 6.81819L16.8182 6.81819L16.8182 5.68182C16.8182 5.30526 17.1234 5 17.5 5Z"
      fill={color}
    />
  </svg>
);

export default NavigationInviteFriendIcon;
