import React, { useState } from 'react';

import { AuthenticationProps } from './steps/Authentication';

enum FlowState {
  kyc,
  canceled,
}

interface PermissionFlowProps {
  AuthenticationComponent: React.FC<{ onCancel: () => void }>;
  FlowCanceledComponent: React.FC<{ restart: () => void }>;
  authenticationProps?: Partial<AuthenticationProps>;
}

const PermissionFlow = ({
  AuthenticationComponent,
  FlowCanceledComponent,
  authenticationProps = {},
}: PermissionFlowProps): JSX.Element => {
  const [kycState, setKycState] = useState<FlowState>(FlowState.kyc);

  const restart = () => setKycState(FlowState.kyc);

  return (
    <>
      {kycState === FlowState.kyc && (
        <AuthenticationComponent onCancel={() => setKycState(FlowState.canceled)} {...authenticationProps} />
      )}
      {kycState === FlowState.canceled && <FlowCanceledComponent restart={restart} />}
    </>
  );
};

export default PermissionFlow;
