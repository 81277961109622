import { Box, HStack, Text, useToken, VStack } from 'native-base';
import React from 'react';

import { ActionGuard, Pressable, useConfig } from '@cryptowallet/frontend/ui';
import MinusIcon from '@cryptowallet/web/icons/MinusIcon';
import PlusIcon from '@cryptowallet/web/icons/PlusIcon';
import SwapIcon from '@cryptowallet/web/icons/SwapIcon';
import WithdrawArrowUpIcon from '@cryptowallet/web/icons/WithdrawArrowUpIcon';

const AssetDetailsActionButton = ({ title, subtitle, icon, ...rest }) => (
  <Pressable
    borderRadius={{ base: '0', md: '10px' }}
    borderWidth="1px"
    borderColor="transparent"
    borderBottomColor={{ base: 'gray.100', md: 'transparent' }}
    py={{ base: '20px', md: '20px' }}
    px={{ base: '0', md: '20px' }}
    bg="transparent"
    outlineWidth="0"
    _hover={{ bg: 'primary.400' }}
    _focus={{
      borderColor: { base: 'transparent', lg: 'secondary.400' },
      borderBottomColor: { base: 'transparent', lg: 'secondary.400' },
    }}
    cursor={rest.isDisabled ? 'not-allowed' : 'pointer'}
    {...rest}>
    {({ isHovered }) => (
      <HStack>
        {icon(isHovered)}
        <VStack ml="14px">
          <Text fontSize="xl" lineHeight="18px" color={isHovered ? 'white' : 'textRegular'} mb="3px">
            {title}
          </Text>
          <Text fontSize="lg" lineHeight="16px" color={isHovered ? 'white' : 'textDisabled'}>
            {subtitle}
          </Text>
        </VStack>
      </HStack>
    )}
  </Pressable>
);

const AssetOverviewActions = ({
  onPressBuyCrypto,
  onPressSellCrypto,
  onPressWithdrawCrypto,
  onPressSwapCrypto,
}: {
  onPressBuyCrypto?: () => void;
  onPressSellCrypto?: () => void;
  onPressWithdrawCrypto?: () => void;
  onPressSwapCrypto?: () => void;
}) => {
  const { swapDisabled } = useConfig();
  const [textDisabled, white] = useToken('colors', ['textDisabled', 'white']);

  return (
    <HStack
      mt="40px"
      py={{ base: '0', md: '6px' }}
      px={{ base: '24px', md: '6px' }}
      bg="black"
      borderRadius="10px"
      justifyContent="space-between"
      flexDirection={{ base: 'column', md: 'row' }}
      flexWrap="wrap">
      <Box w={{ base: '100%', md: '50%' }}>
        <ActionGuard>
          {({ openAlert }) => (
            <AssetDetailsActionButton
              title="Buy"
              subtitle="Buy crypto"
              w="100%"
              icon={isHovered => <PlusIcon color={isHovered ? white : textDisabled} />}
              onPress={openAlert || onPressBuyCrypto}
              isDisabled={!onPressBuyCrypto}
            />
          )}
        </ActionGuard>
      </Box>
      <Box w={{ base: '100%', md: '50%' }}>
        <ActionGuard>
          {({ openAlert }) => (
            <AssetDetailsActionButton
              title="Sell"
              subtitle="Sell crypto"
              w="100%"
              icon={isHovered => (
                <Box mt="7px">
                  <MinusIcon color={isHovered ? white : textDisabled} />
                </Box>
              )}
              onPress={openAlert || onPressSellCrypto}
              isDisabled={!onPressSellCrypto}
            />
          )}
        </ActionGuard>
      </Box>
      <Box w={{ base: '100%', md: '50%' }}>
        <ActionGuard>
          {({ openAlert }) => (
            <AssetDetailsActionButton
              title="Withdraw"
              subtitle="Send crypto"
              w="100%"
              borderBottomColor="transparent"
              icon={isHovered => <WithdrawArrowUpIcon color={isHovered ? white : textDisabled} />}
              onPress={openAlert || onPressWithdrawCrypto}
              isDisabled={!onPressWithdrawCrypto}
            />
          )}
        </ActionGuard>
      </Box>
      <Box w={{ base: '100%', md: '50%' }}>
        <ActionGuard>
          {({ openAlert }) => (
            <AssetDetailsActionButton
              title="Swap"
              subtitle="Exchange one crypto for another"
              w="100%"
              icon={isHovered => <SwapIcon color={isHovered ? white : textDisabled} />}
              onPress={openAlert || onPressSwapCrypto}
              isDisabled={!onPressSwapCrypto || swapDisabled}
            />
          )}
        </ActionGuard>
      </Box>
    </HStack>
  );
};

export default AssetOverviewActions;
