import { ITextProps, Text } from 'native-base';
import React from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

const isWeb = getPlatform() === 'web';
const isAnroid = getPlatform() === 'android';

interface ICurrencyLabelProps extends ITextProps {
  label: string;
  color: string;
}

const CurrencyNetwork = ({ label, color, ...rest }: ICurrencyLabelProps) => (
  <Text
    fontSize="sm"
    lineHeight={isAnroid ? '13px' : '12px'}
    pt={isWeb ? '3px' : '5px'}
    pb="2px"
    px="6px"
    h="19px"
    fontWeight="100"
    color={color}
    borderWidth="1px"
    borderRadius="4px"
    borderColor={color}
    {...rest}>
    {label}
  </Text>
);

export default CurrencyNetwork;
