import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { ExchangerExchangeListResponseDto } from '@cryptowallet/frontend/client';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import useUserStatus from './useUserStatus';

interface IPaginationProps {
  offset?: number;
  limit?: number;
}
const useExchanges = (lim = 8) => {
  const { kycPoaHighRiskVerified } = useUserStatus();
  const {
    data: response,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
  } = useInfiniteQuery<AxiosResponse<ExchangerExchangeListResponseDto>>(
    ['getExchanges'],
    ({ pageParam }) => exchangerApi.exchangerControllerGetExchangesList(lim, pageParam?.offset || 0),
    {
      getNextPageParam: (lastPage): IPaginationProps => {
        if (!lastPage) {
          return { offset: 0 };
        }
        const { limit, offset, total } = lastPage.data;
        if (limit + offset >= total) {
          return undefined;
        }

        return { offset: limit + offset };
      },
      ...cacheRequestOption,
      enabled: kycPoaHighRiskVerified,
    },
  );

  const transactions = useMemo(() => {
    if (response) {
      return response.pages.reduce((prev, page) => [...prev, page.data.items], []).flat();
    }

    return [];
  }, [response]);

  return {
    transactions,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
  };
};
export default useExchanges;
