import React from 'react';

const NavigationSettings2faIcon = ({ color }) => (
  <svg width="14" height="22" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 24C16 24 16 20.4183 16 16C16 8 12.4183 8 8 8C3.58172 8 0 8 0 16C0 20.4183 0 24 8 24ZM9 15.7324C9.5978 15.3866 10 14.7403 10 14C10 12.8954 9.10457 12 8 12C6.89543 12 6 12.8954 6 14C6 14.7403 6.4022 15.3866 7 15.7324V19C7 19.5523 7.44772 20 8 20C8.55228 20 9 19.5523 9 19V15.7324Z"
      fill={color}
    />
    <path
      d="M8 0C4.68629 0 2 2.68629 2 6V12H4V6C4 3.79086 5.79086 2 8 2C10.2091 2 12 3.79086 12 6V12H14V6C14 2.68629 11.3137 0 8 0Z"
      fill={color}
    />
  </svg>
);

export default NavigationSettings2faIcon;
