/* eslint-disable no-nested-ternary */
import { Box, HStack, Image, Pressable, Text } from 'native-base';
import React, { forwardRef, useCallback } from 'react';
import { SvgUri } from 'react-native-svg';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { getNetworkData, getPlatform } from '@cryptowallet/frontend/utils';

import { usePartnerStyles } from '../../context/PartnersSettingsContext';
import { CheckBoxIcon } from '../../icons/CheckBoxIcon';
import CurrencyNetwork from '../CurrencyNetwork';
import Icon from '../Icon';

import { IInputCurrency } from './Inputs/CurrencySelect';

interface ICurrencyDropdownItemProps {
  currency: IInputCurrency;
  onPress: () => void;
  selected: boolean;
  focused: boolean;
  withIcon: boolean;
  showNetwork: boolean;
  disabled?: boolean;
  last?: boolean;
}

const hoverFocusStyles = {
  bg: 'gray.100',
};

const isWeb = getPlatform() === 'web';

export const CurrencyDropdownItem = forwardRef(
  (
    {
      currency,
      onPress,
      selected,
      focused,
      withIcon,
      showNetwork,
      disabled = false,
      last = false,
    }: ICurrencyDropdownItemProps,
    ref,
  ) => {
    const partnerStyles = usePartnerStyles('CurrencyDropdownItem');
    const isPngPic = currency?.image?.endsWith('png');
    const hstackPartnerStyles = usePartnerStyles('CurrencyDropdownItemHStack');
    const networkTicker = currency?.network?.ticker;
    const networkLabel = currency?.network?.label;
    const { color } = getNetworkData(networkTicker);

    const renderIcon = useCallback(() => {
      if (!withIcon) return null;
      if (isWeb || isPngPic) {
        return (
          <Image
            source={{
              uri: currency.image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
            }}
            alt={currency.label}
            w="36px"
            h="36px"
            mr="13px"
            borderRadius="36px"
          />
        );
      }

      if (!isPngPic && !isWeb) {
        return (
          <Box mr="13px">
            <SvgUri width="36px" height="36px" uri={currency?.image} />
          </Box>
        );
      }

      return null;
    }, [currency, isPngPic, withIcon]);

    return (
      <Pressable
        ref={ref}
        h={isWeb ? '56px' : '58px'}
        justifyContent="center"
        px="13px"
        borderRadius="10px"
        outlineWidth="0"
        _hover={hoverFocusStyles}
        _focus={hoverFocusStyles}
        {...(focused ? hoverFocusStyles : {})}
        focusable={false}
        onPress={onPress}
        opacity={disabled ? 0.5 : 1}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        {...partnerStyles}>
        {({ isHovered, isFocused }) => (
          <HStack
            position="relative"
            alignItems="center"
            borderBottomWidth="0"
            w="100%"
            h="100%"
            {...(hstackPartnerStyles.borderColor
              ? {
                  borderColor:
                    isHovered || isFocused || focused
                      ? hstackPartnerStyles.borderColor.active
                      : hstackPartnerStyles.borderColor.normal,
                }
              : {
                  borderColor: last ? 'transparent' : 'gray.300',
                })}>
            {selected && isWeb && <Icon name="check" position="absolute" right="13px" h="8px" w="12px" />}
            {renderIcon()}
            {showNetwork && (
              <Box flexGrow="1">
                <Box position="relative" w="100%">
                  <Text lineHeight="24px" fontSize="xl" color="white" fontWeight="100">
                    {currency.ticker}
                  </Text>
                  <CurrencyNetwork
                    position="absolute"
                    top={isWeb ? { base: '2px', md: '12px' } : '45%'}
                    right={isWeb ? (selected ? '50px' : '10px') : '0'}
                    color={color}
                    label={networkLabel}
                  />
                </Box>
                <Text lineHeight="18px" fontSize="md" color="textRegular" fontWeight="100">
                  {currency.label}
                </Text>
              </Box>
            )}
            {!showNetwork && (
              <Box flexGrow="1">
                <Text lineHeight="24px" fontSize="xl" color="white" fontWeight="100">
                  {currency.label}
                </Text>
              </Box>
            )}
            {selected && !isWeb && (
              <Box justifySelf="flex-end" ml="12px">
                <CheckBoxIcon width={13} />
              </Box>
            )}
          </HStack>
        )}
      </Pressable>
    );
  },
);

export default CurrencyDropdownItem;
