import { GetUserResponseUsersDtoUserRiskLevelTypeEnum } from '@cryptowallet/frontend/client';
import { commonConstants } from '@cryptowallet/frontend/constants';
import { usePersistState } from '@cryptowallet/frontend/hooks';
import { useAuth, useConfig, useUser } from '@cryptowallet/frontend/ui';

import BlockedIcon from '../../icons/BlockedIcon';
import CenterElement from '../CenterElement';
import InfoScreen from '../InfoScreen';

import AcceptNewTerms from './AcceptNewTerms';
import BlockedByCountryScreen from './BlockedByCountryScreen';

export default function BlockedFilter({
  children,
  isCheckout = false,
}: {
  children: JSX.Element;
  isCheckout?: boolean;
}): JSX.Element {
  const user = useUser();
  const { systemIsUnderMaintenance } = useConfig();

  const { logout } = useAuth();
  const [showBlockedByCountryScreen] = usePersistState(false, commonConstants.PersistKeys.ShowBlockedByCountryScreen);

  if (systemIsUnderMaintenance) {
    return (
      <CenterElement>
        <InfoScreen
          headingText="The system is currently undergoing maintenance and will be available as soon as possible"
          icon={<BlockedIcon />}
          descriptionText="If you have any additional questions, please contact support"
          actionBtnText="Close"
          onActionBtnPress={logout}
        />
      </CenterElement>
    );
  }

  if (user && user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Blocked) {
    if (showBlockedByCountryScreen) {
      return <BlockedByCountryScreen isCheckout={isCheckout} />;
    }

    return (
      <CenterElement>
        <InfoScreen
          headingText="Unfortunately, your account has been suspended."
          icon={<BlockedIcon />}
          descriptionText="Please contact our support for more details"
          actionBtnText="Close"
          onActionBtnPress={logout}
        />
      </CenterElement>
    );
  }

  if (user && !user.permissions.hasAcceptedNewTerms) {
    return <AcceptNewTerms />;
  }

  return children;
}
