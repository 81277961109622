import React from 'react';

import LinkBase, { ILinkBaseProps } from './LinkBase';

export const LinkWarning = (props: Omit<ILinkBaseProps, 'textColor'>) => (
  <LinkBase
    {...props}
    textColor={{
      default: 'secondary.400',
      hoverFocus: 'primary.400',
    }}
  />
);
