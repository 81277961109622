/* eslint-disable no-param-reassign */
import { Actionsheet as NBActionsheet } from 'native-base';
import React from 'react';

import { useBlockWindowScroll } from '@cryptowallet/frontend/hooks';

interface IActionSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element | string | (JSX.Element | string)[];
}

const ActionSheet = ({ isOpen, onClose, children }: IActionSheetProps) => {
  useBlockWindowScroll(isOpen);
  return (
    <NBActionsheet
      ref={ref => {
        if (ref?.parentNode?.parentNode?.parentNode) {
          // make 100vh and fixed to prevent scrolling modal when big content
          ref.parentNode.parentNode.parentNode.style.height = '100vh';
          ref.parentNode.parentNode.parentNode.style.position = 'fixed';
        }
      }}
      bg="gray.300"
      isOpen={isOpen}
      onClose={onClose}>
      <NBActionsheet.Content
        bg="black"
        borderColor="gray.100"
        borderWidth="1px"
        borderTopRightRadius="10px"
        borderTopLeftRadius="10px">
        {children}
      </NBActionsheet.Content>
    </NBActionsheet>
  );
};

export default ActionSheet;
