import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import { shallow } from 'zustand/shallow';
import React, { useEffect } from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { SellExchangeResult, useAssets, useSellExchange } from '@cryptowallet/frontend/hooks';
import { getSellSafeQueryStateExcludeKeys, useSellFlowStore } from '@cryptowallet/frontend/stores';
import { useAlerts, useConfig } from '@cryptowallet/frontend/ui';
import { getEnv, setQueryString } from '@cryptowallet/frontend/utils';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import SellAmount from './SellAmount';
import SellCompleted from './SellCompleted';
import SellExchange from './SellExchange';
import SellPayment from './SellPayment';
import SellPayoutDetails from './SellPayoutDetails';

const steps: IStep[] = [
  {
    label: 'Amount',
    Component: SellAmount,
  },
  {
    label: 'Payout details',
    Component: SellPayoutDetails,
  },
  {
    label: 'Payment',
    Component: SellPayment,
  },
  {
    label: 'Exchange',
    Component: SellExchange,
  },
];

const defaultFiatCurrency = 'eur';
const defaultSellCurrency = {
  ticker: 'trx',
  networkTicker: 'trx',
};

const env = getEnv();

const SellFlow = () => {
  const queryClient = useQueryClient();
  const { showErrorToaster } = useAlerts();
  const {
    stepIndex,
    setStepIndex,
    fromCurrency,
    setFromCurrency,
    fromCurrencyId,
    toCurrency,
    setToCurrency,
    initQueryState,
    exchangeId,
    setExchangeId,
    amountPassed,
    setAmountPassed,
    iban,
    paymentMethod,
    amount,
    setExchangeData,
    reset,
  } = useSellFlowStore(
    state => ({
      stepIndex: state.stepIndex,
      setStepIndex: state.setStepIndex,
      fromCurrency: state.fromCurrency,
      setFromCurrency: state.setFromCurrency,
      fromCurrencyId: state.fromCurrencyId,
      toCurrency: state.toCurrency,
      setToCurrency: state.setToCurrency,
      initQueryState: state.initQueryState,
      exchangeId: state.exchangeId,
      setExchangeId: state.setExchangeId,
      amountPassed: state.amountPassed,
      setAmountPassed: state.setAmountPassed,
      iban: state.iban,
      paymentMethod: state.paymentMethod,
      amount: state.amount,
      setExchangeData: state.setExchangeData,
      reset: state.reset,
    }),
    shallow,
  );
  const { sellFromCurrencies, sellToCurrencies, isLoading } = useAssets();
  const {
    data: exchange,
    result: exchangeResult,
    completed,
    isLoading: isExchangeLoading,
    userPaymentSent,
  } = useSellExchange();
  const { sellFlowDisabled } = useConfig();

  useEffect(() => {
    initQueryState(getSellSafeQueryStateExcludeKeys());
  }, []);

  useEffect(() => {
    if (!fromCurrency && sellFromCurrencies?.length) {
      const urlState = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const { fromCurrencyId: urlFromCurrencyId } = urlState || {};
      const urlCurrency = sellFromCurrencies.find(currency => currency.id === urlFromCurrencyId);
      const noUrlCurrency = sellFromCurrencies.find(
        currency =>
          currency.ticker.toLowerCase() === defaultSellCurrency.ticker &&
          currency.network?.ticker.toLowerCase() === defaultSellCurrency.networkTicker,
      );
      const [firstCurrency] = sellFromCurrencies;
      const defaultCurrency = urlCurrency || noUrlCurrency || firstCurrency;
      setFromCurrency(defaultCurrency);
    }

    if (!toCurrency && sellToCurrencies?.length) {
      const defaultCurrency =
        sellToCurrencies.find(currency => currency.ticker.toLowerCase() === defaultFiatCurrency) || sellToCurrencies[0];
      setToCurrency(defaultCurrency);
    }
  }, [sellFromCurrencies, sellToCurrencies, setFromCurrency, setToCurrency, toCurrency, fromCurrency, fromCurrencyId]);

  useEffect(() => {
    let propperStepIndex;
    if (exchangeId) {
      const exchangeDone = exchangeResult !== SellExchangeResult.EXCHANGING;
      propperStepIndex = userPaymentSent || exchangeDone ? 3 : 2;
    } else {
      propperStepIndex = amountPassed ? 1 : 0;
    }

    if (sellFlowDisabled && (propperStepIndex === 1 || propperStepIndex === 2)) {
      propperStepIndex = 0;
      reset();
      showErrorToaster({ title: 'Sell flow is unavailable at the moment.' });
    }

    if (propperStepIndex !== stepIndex) {
      setStepIndex(propperStepIndex);
      if (propperStepIndex === 3) {
        queryClient.invalidateQueries(['getExchanges']); // update status in recent activity when payin completes
      }
    }
  }, [amountPassed, exchangeResult, exchangeId, userPaymentSent]);

  useEffect(() => {
    if (exchange) {
      // this happens when exchangeId appears and created exchange data loads - refresh page, resume exchange
      const { data: exchangeData } = exchange;
      const createdExchangeData = {
        paymentMethod: exchangeData.pspType,
        amount: (exchangeData.fromAmount || exchangeData.expectedFromAmount).toString(),
        toCurrency: sellToCurrencies.find(item => item.id === exchangeData.toExchangerAssetId),
        fromCurrency: sellFromCurrencies.find(item => item.id === exchangeData.fromExchangerAssetId),
        iban: exchangeData.payOut.iban,
      };

      const storeExchangeData = {
        paymentMethod,
        amount,
        toCurrency,
        fromCurrency,
        iban,
      };

      if (!isEqual(createdExchangeData, storeExchangeData)) {
        setExchangeData(createdExchangeData);
      }
    }
  }, [exchange]);

  useEffect(() => {
    // when come from wallet
    if (fromCurrencyId && fromCurrency?.id !== fromCurrencyId) {
      const urlCurrency = sellFromCurrencies.find(currency => currency.id === fromCurrencyId);
      if (urlCurrency) {
        setFromCurrency(urlCurrency);
      }
    }
  }, [fromCurrencyId]);

  const showExchangeType = stepIndex !== 0;
  const isReady = !isLoading && !!fromCurrency && !!toCurrency && (!exchangeId || !isExchangeLoading);

  if (completed) {
    return <SellCompleted />;
  }

  if (!isReady) return null;

  return (
    <Stepper
      header={showExchangeType && commonConstants.ExchangeTypeEnum.Sell}
      headerProps={{
        hideDivider: true,
        mb: 0,
      }}
      steps={steps}
      canGoToPrevStep={showExchangeType && (stepIndex === 1 || stepIndex === 2)}
      onGoToPrevStep={() => {
        if (stepIndex === 1) {
          setAmountPassed(false);
        } else if (stepIndex === 2) {
          setQueryString('?exchangeType=Sell');
          setExchangeId('');
          setAmountPassed(true);
        }
      }}
      stepperState={[stepIndex, setStepIndex]}
    />
  );
};

export default SellFlow;
