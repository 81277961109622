import { useSearchParams } from 'react-router-dom';

const useSearchParamsState = (
  searchParamName = '',
  defaultValue = '',
  allowedValues: string[] = [],
  excludeFromKeepingParams: string[] = [], // params that shouldn't be saved when this param changes
): [string, (newValue: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(searchParamName);
  const searchParamsState: string =
    searchParam && (!allowedValues.length || allowedValues.includes(searchParam)) ? searchParam : defaultValue;

  const setSearchParamsState = (newState: string) => {
    const paramEntries = [...searchParams.entries()].filter(([key]) => !excludeFromKeepingParams.includes(key));

    const next = {
      ...paramEntries.reduce((o, [key, value]) => ({ ...o, [key]: value }), {}),
      [searchParamName]: newState,
    };
    setSearchParams(next);
  };
  return [searchParamsState, setSearchParamsState];
};

export default useSearchParamsState;
