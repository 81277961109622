import { AxiosError } from 'axios';

export const longPollingOptions = (refetchInterval: number) => ({
  refetchInterval,
  refetchOnMount: true,
  refetchOnWindowFocus: true,
});

export const defaultRetryPolicy = (_failureCount: number, error: AxiosError) => {
  if (error?.response?.status ?? 0 >= 500) {
    return false;
  }

  return _failureCount < 4;
};

export const cacheRequestOption = {
  cacheTime: 1000 * 60 * 60 * 24,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: defaultRetryPolicy,
};

export const defaultLongPollingInterval = 5000;
export const defaultFailureCount = 4;
