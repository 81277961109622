import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../ui/src';
import { GetUserResponseUsersDto, WalletResourceEntityDto } from '../client/src';
import { userSettingsApi } from '../api/src';
import uniqWith from 'lodash/uniqWith';

interface AddRecommendedWalletAssetsMutationPayload {
  walletResources: WalletResourceEntityDto[];
}

interface RecommendedWalletAsset {
  ticker: string;
  networkTicker: string;
}

const useAddRecommendedWalletAssetMutation = () => {
  const { queryKey } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ walletResources }: AddRecommendedWalletAssetsMutationPayload) => {
      const assetIds = walletResources.map(item => item.asset.id);

      return userSettingsApi.userSettingsControllerAddRecommendedWalletAssets({ assetIds });
    },
    onMutate: async ({ walletResources }: AddRecommendedWalletAssetsMutationPayload) => {
      await queryClient.cancelQueries({ queryKey });
      const prevData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old: GetUserResponseUsersDto) => {
        const recommendedWalletAssets = uniqWith(
          [
            ...old.settings.recommendedWalletAssets,
            ...walletResources.map(item => ({
              ticker: item.asset.currency.ticker,
              networkTicker: item.asset.network?.ticker,
            })),
          ],
          (a: RecommendedWalletAsset, b: RecommendedWalletAsset) =>
            a.ticker === b.ticker && a.networkTicker === b.networkTicker,
        );

        return {
          ...old,
          settings: {
            ...old.settings,
            recommendedWalletAssets: recommendedWalletAssets as never,
          },
        };
      });

      return { prevData };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context.prevData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export default useAddRecommendedWalletAssetMutation;
