import { useCallback, useEffect, useState } from 'react';

import {
  EventRequestDtoEventNameEnum,
  GetUserResponseUsersDto,
  GetUserResponseUsersDtoUserRiskLevelTypeEnum,
} from '@cryptowallet/frontend/client';
import { useGA } from '@cryptowallet/frontend/ga';
import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { useUser } from '@cryptowallet/frontend/ui';

import { getCountry } from './utils';

const ChatWidget = () => {
  const ga = useGA();
  const user = useUser();
  const { kycPoaHighRiskVerified } = useUserStatus();

  const [currentUser, setCurrentUser] = useState<GetUserResponseUsersDto | null>(null);

  const initChat = useCallback(() => {
    globalThis.Freshbots.initiateWidget(
      {
        autoInitChat: false,
        getClientParams() {
          return {
            'sn::cstmr::id': globalThis.Freshbots.params?.id, // customer id
            // "xtrnlTcktId":"", // external ticket id
            'sn::auth::id': globalThis.Freshbots.params?.id, // auth id
            // "cstmr::phn":"", // phone
            'cstmr::eml': globalThis.Freshbots.params?.email, // email
            'cstmr::nm': globalThis.Freshbots.params?.name, // name
            'cstmr::xtrInfrmtn:cf_kyc_poa_verified': String(kycPoaHighRiskVerified),
            ...(user
              ? {
                  'cstmr::xtrInfrmtn:cf_user_status':
                    user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Blocked
                      ? 'suspended'
                      : 'active',
                }
              : {}),
            'cstmr::xtrInfrmtn:cf_creation_date': globalThis.Freshbots.params?.createdAt,
            'cstmr::xtrInfrmtn:cf_country': getCountry(Intl.DateTimeFormat().resolvedOptions().timeZone),
          };
        },
      },
      (...args: any[]) => {
        document.querySelector('#mobile-chat-container').addEventListener('click', () => {
          ga.sendEvent(EventRequestDtoEventNameEnum.ChatStart, {
            ...globalThis.Freshbots.params,
            customer_source: 'btc',
          });
        });
      },
      (error: unknown) => {
        console.warn('Freshbots init failed', error);
      },
    );
  }, []);

  useEffect(() => {
    if (globalThis.Freshbots) {
      if (!user && currentUser) {
        globalThis.Freshbots.params = null;
        // globalThis.Freshbots.resetCustomer(false);
        globalThis.Freshbots.resetCustomer(true);
        globalThis.Freshbots.reloadContext();
        globalThis.Freshbots.activate();
        setCurrentUser(null);
      } else if (user && !currentUser) {
        const name = `${user?.profileData?.firstName || ''} ${user?.profileData?.lastName || ''}`;
        globalThis.Freshbots.params = {
          id: user?.user?.id,
          email: user?.user?.email,
          name: name.trim(),
          createdAt: user?.profileData?.createdAt,
        };
        setCurrentUser(user);
      }
    }
  }, [user]);

  useEffect(() => {
    if (globalThis.Freshbots) {
      initChat();
    } else {
      // listen to synthetic event FreshbotsLoaded
      document.addEventListener('FreshbotsLoaded', initChat);
    }
    return () => {
      document.removeEventListener('FreshbotsLoaded', initChat);
    };
  }, []);

  return null;
};

export default ChatWidget;
