import { useMutation } from '@tanstack/react-query';
import { HStack, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useScreenSize, useWalletSummary } from '@cryptowallet/frontend/hooks';
import {
  BottomPurpleButton,
  ButtonPurple,
  ConfirmationModal,
  FormGuards,
  IFormGuardType,
  useAlerts,
  useAuth,
  useUser,
} from '@cryptowallet/frontend/ui';
import { maskEmail, processFormGuardsApiErrors } from '@cryptowallet/frontend/utils';
import { userApi } from '@cryptowallet/web/api-client';
import ErrorBorderedLink from '@cryptowallet/web/components/ErrorBorderedLink';
import { TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';

interface IFormProps {
  totpToken: string;
  emailOtpToken: string;
}

const Deactivate = () => {
  const { isDesktop } = useScreenSize();
  const [flowIsActive, setFlowIsActive] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { fiatTotalBalance } = useWalletSummary();
  const { user } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { showErrorToaster } = useAlerts();

  const { control, handleSubmit, setError, trigger } = useForm({
    defaultValues: {
      totpToken: '',
      emailOtpToken: '',
    },
  });

  const { isLoading, mutateAsync } = useMutation((formProps: IFormProps) =>
    userApi.usersControllerDeactivateMe(formProps),
  );

  const openConfirmationModal = async () => {
    if (isLoading) return;

    const isValid = await trigger();

    if (isValid) {
      setShowConfirmationModal(true);
    }
  };

  const closeConfirmationModal = () => setShowConfirmationModal(false);

  const onSubmit = async formData => {
    closeConfirmationModal();
    try {
      await mutateAsync(formData);

      logout();
      navigate('/', { replace: true });
    } catch (error) {
      processFormGuardsApiErrors(error, setError, showErrorToaster);
    }
  };

  const needWithdraw = Number(fiatTotalBalance) > 0;
  const need2fa = !user.totpEnabled;

  return (
    <VStack flexGrow="1">
      <TabHeader onGoBack={isDesktop ? null : () => navigate('/account')}>Deactivate my account</TabHeader>
      <TabContentLayout>
        {!flowIsActive && (
          <>
            <HStack
              mt="22px"
              p="24px"
              alignItems="center"
              justifyContent="space-between"
              borderWidth="1px"
              borderRadius="10px"
              borderColor="gray.100">
              <Text color="white" fontSize="lg" fontWeight="600">
                {maskEmail(user.email)}
              </Text>
              <ButtonPurple
                py="5px"
                px="12px"
                size="md"
                isDisabled={needWithdraw || need2fa}
                onPress={() => setFlowIsActive(true)}
                _text={{ fontSize: 'md' }}>
                Deactivate
              </ButtonPurple>
            </HStack>
            {needWithdraw && (
              <ErrorBorderedLink
                message="Please withdraw all your funds from your accounts before deactivating."
                linkProps={{ text: 'Withdraw your funds', href: '/wallet/widgets' }}
              />
            )}
            {need2fa && (
              <ErrorBorderedLink
                message="Error: to deactivate your account enabled 2FA is required."
                linkProps={{ text: 'Enable 2FA', href: '/account/2fa' }}
              />
            )}
          </>
        )}
        {flowIsActive && (
          <FormGuards
            activeGuards={[{ type: IFormGuardType.OTP }, { type: IFormGuardType.TOTP }]}
            control={control}
            onSubmitInput={openConfirmationModal}
          />
        )}
      </TabContentLayout>
      {flowIsActive && (
        <BottomPurpleButton onPress={openConfirmationModal} size="lg">
          Deactivate
        </BottomPurpleButton>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          cancelText="Cancel"
          confirmText="Deactivate"
          onCancel={closeConfirmationModal}
          onConfirm={handleSubmit(onSubmit)}>
          <Text fontSize="lg" color="white" textAlign="center">
            Are you sure you want to deactivate your account? This action can’t be reversed.
          </Text>
        </ConfirmationModal>
      )}
    </VStack>
  );
};

export default Deactivate;
