import { Box, FormControl, StyledProps, Text } from 'native-base';
import React from 'react';

import { ExchangerAssetEntityDto } from '@cryptowallet/frontend/client';
import { useScreenSize } from '@cryptowallet/frontend/hooks';

import CurrencyDropdownButton from '../CurrencyDropdownButton';
import CurrencyDropdownList from '../CurrencyDropdownList';

export interface IInputCurrency extends ExchangerAssetEntityDto {
  isCreated?: boolean;
  disabled?: boolean;
}

interface ICurrencySelectProps {
  label?: string;
  h?: string;
  showNetwork?: boolean;
  withIcon?: boolean;
  selectedCurrency: IInputCurrency;
  currencies: Array<IInputCurrency>;
  onCurrencyChange?: (value: IInputCurrency) => void;
  isOpen: boolean;
  openCurrencyList: (name: string) => void;
  name: string;
  isLoading?: boolean;
  bg?: {
    default: string;
    active: string;
  };
  placeholder?: string;
  currencyDropdownStyles?: StyledProps;
}

const CurrencySelect = ({
  label,
  h = '56px',
  showNetwork = false,
  withIcon = true,
  selectedCurrency,
  currencies,
  onCurrencyChange,
  isOpen,
  openCurrencyList,
  name,
  isLoading = false,
  bg = {
    default: 'gray.200',
    active: 'gray.400',
  },
  placeholder,
  currencyDropdownStyles = {
    top: '60px',
    pt: '0',
  },
}: ICurrencySelectProps) => {
  const { isMobile } = useScreenSize();

  const onSelectCurrency = (currency: IInputCurrency) => {
    openCurrencyList(name); // trigger close
    onCurrencyChange(currency);
  };

  return (
    <FormControl>
      {label && (
        <FormControl.Label mb="5px">
          <Text fontFamily="body" fontSize="lg" color="textLabel">
            {label}
          </Text>
        </FormControl.Label>
      )}
      <Box position="relative" h={h} w="100%" borderRadius="10px">
        <CurrencyDropdownButton
          showNetwork={showNetwork}
          withIcon={withIcon}
          selectedCurrency={selectedCurrency}
          isOpen={isOpen}
          toggleOpen={() => openCurrencyList(name)}
          noInput
          bg={bg}
          isDisabled={!onCurrencyChange}
          isLoading={isLoading}
          placeholder={placeholder}
        />
        {!isMobile && isOpen && (
          <CurrencyDropdownList
            currencies={currencies}
            onSelectCurrency={onSelectCurrency}
            selectedCurrency={selectedCurrency}
            containerStyles={currencyDropdownStyles}
            withIcon={withIcon}
            showNetwork={showNetwork}
          />
        )}
      </Box>
    </FormControl>
  );
};

export default CurrencySelect;
