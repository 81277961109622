import React from 'react';

const WithdrawArrowUpIcon = ({ color }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00001 16.9999L8.00001 0.999939M8.00001 0.999939L15 7.39994M8.00001 0.999939L1 7.39994"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WithdrawArrowUpIcon;
