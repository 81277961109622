import React from 'react';

import { Button } from '../../../index';

export const ButtonUnderlinedText = ({ children, color, fontSize = 'sm', ...rest }) => (
  <Button
    variant="unstyled"
    p="0"
    _text={{
      color,
      fontSize,
    }}
    textDecoration="underline"
    textDecorationColor={color}
    {...rest}>
    {children}
  </Button>
);
