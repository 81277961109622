import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Center, Heading, HStack, Text, VStack } from 'native-base';
import { Controller, useForm } from 'react-hook-form';

import { userApi } from '@cryptowallet/frontend/api';
import { AcceptNewTermsPayloadUsersDto, GetUserResponseUsersDto } from '@cryptowallet/frontend/client';
import { ButtonPurple, PressableCheckbox, Spinner, useAlerts, useAuth } from '@cryptowallet/frontend/ui';

import CenterElement from '../CenterElement';
import LinkPurple from '../Links/LinkPurple';

const TermsText = ({ children }) => (
  <Text mx="6px" fontSize="lg" color="textRegular" lineHeight="32px" userSelect="none">
    {children}
  </Text>
);

const AcceptNewTerms = () => {
  const { onApiError } = useAlerts();
  const { queryKey } = useAuth();
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm<AcceptNewTermsPayloadUsersDto>({
    defaultValues: {
      acceptNewTerms: false,
      acceptNewPrivacyPolicy: false,
      acceptTransferOfPersonalData: false,
    },
  });

  const { mutateAsync, isLoading } = useMutation((values: AcceptNewTermsPayloadUsersDto) =>
    userApi.usersControllerAcceptNewTerms(values),
  );

  const onSubmit = async values => {
    try {
      await mutateAsync(values);
      queryClient.setQueryData(queryKey, (old: GetUserResponseUsersDto) => ({
        ...old,
        permissions: {
          ...old.permissions,
          hasAcceptedNewTerms: true,
        },
      }));
    } catch (err) {
      onApiError(err);
    }
  };

  if (isLoading) {
    return (
      <CenterElement>
        <Spinner />
      </CenterElement>
    );
  }

  return (
    <CenterElement>
      <Box background="background.400" borderRadius="10px" overflow="hidden" maxW="100vw" w="xl">
        <Box p="6" borderBottomWidth="1px" borderBottomColor="primary.400" mb="4">
          <Heading fontSize="xl" fontWeight="500" textAlign="center">
            Change in our Terms of Service
          </Heading>
        </Box>
        <Center>
          <Center p="6">
            <Text marginTop="5" fontWeight="400" fontSize="sm" textAlign="center">
              We are informing you that Cryptowallet has relocated from Estonia to Poland. To continue using our
              service, please accept the new Terms of Service and agree to the transfer of your data and funds from the
              Estonian entity to the Polish entity.
            </Text>
          </Center>
          <VStack p="32px" pt="12px">
            <Controller
              control={control}
              rules={{
                validate: (val): true | string => val || 'Please confirm that you agree with our terms and conditions ',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PressableCheckbox mt="12px" error={error} isChecked={value} onChange={() => onChange(!value)}>
                  <HStack flexWrap="wrap" flex="1" alignItems="center">
                    <TermsText>
                      I confirm that I have read and agree to the updated{' '}
                      <LinkPurple
                        isExternal
                        _text={{ fontSize: 'lg' }}
                        href="https://cryptowallet.com/app/uploads/2024/06/Updated_Terms_of_Use_18_06.2024.pdf"
                        text="Terms of Use"
                      />
                    </TermsText>
                  </HStack>
                </PressableCheckbox>
              )}
              name="acceptNewTerms"
            />
            <Controller
              control={control}
              rules={{
                validate: (val): true | string => val || 'Please confirm that you agree with our privacy policy ',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PressableCheckbox mt="12px" error={error} isChecked={value} onChange={() => onChange(!value)}>
                  <HStack flexWrap="wrap" flex="1" alignItems="center">
                    <TermsText>
                      I confirm that I have read and agree to the updated{' '}
                      <LinkPurple
                        isExternal
                        _text={{ fontSize: 'lg' }}
                        href="https://cryptowallet.com/app/uploads/2024/06/Privacy_policy_upd_18_06_2024.pdf"
                        text="Privacy Policy"
                      />
                    </TermsText>
                  </HStack>
                </PressableCheckbox>
              )}
              name="acceptNewPrivacyPolicy"
            />
            <Controller
              control={control}
              rules={{
                validate: (val): true | string =>
                  val || 'Please confirm that you agree with transfer of your personal data ',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PressableCheckbox mt="12px" error={error} isChecked={value} onChange={() => onChange(!value)}>
                  <HStack flexWrap="wrap" flex="1" alignItems="center">
                    <TermsText>
                      I confirm that I have been informed about and have read the Notice regarding the{' '}
                      <LinkPurple
                        display="inline"
                        isExternal
                        _text={{ fontSize: 'lg' }}
                        href="https://cryptowallet.com/app/uploads/2024/06/Personal-data-and-Funds-Transfer-Notice.pdf"
                        text="Transfer of my Personal Data and Available Funds"
                      />{' '}
                      on my CryptoWallet account to Estx sp. z o.o., and I give my explicit consent to these actions
                    </TermsText>
                  </HStack>
                </PressableCheckbox>
              )}
              name="acceptTransferOfPersonalData"
            />
          </VStack>
        </Center>
        <ButtonPurple w="100%" h="50px" onPress={handleSubmit(onSubmit)}>
          Continue
        </ButtonPurple>
      </Box>
    </CenterElement>
  );
};

export default AcceptNewTerms;
