import { Box, FormControl } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  EventRequestDtoEventNameEnum,
  ExchangerCreateExchangeRequestDtoPspTypeEnum,
  ExchangeType,
  WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum,
} from '@cryptowallet/frontend/client';
import { paymentMethodsInfo } from '@cryptowallet/frontend/constants';
import { useGA } from '@cryptowallet/frontend/ga';
import { useActiveSellPaymentMethods, useAssets, useUserStatus } from '@cryptowallet/frontend/hooks';
import { useSellEstimate, useSellFlowStore } from '@cryptowallet/frontend/stores';
import {
  ActionGuard,
  BottomPurpleButton,
  CryptocurrencyInputsForm,
  ErrorMessage,
  ErrorMessageType,
  ICryptocurrencyInputItem,
  IInputCurrency,
  IValidate,
  Switch,
  useAlerts,
  useConfig,
  useUser,
} from '@cryptowallet/frontend/ui';

import ExchangeLimits from '../ExchangeLimits';
import PaymentMethods, { IPaymentMethodButton } from '../PaymentMethods';

import SellTransactionDetails from './SellTransactionDetails';

export const paymentMethods: IPaymentMethodButton[] = [
  {
    description: 'Within 24h',
    type: ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa,
    icon: {
      active: 'paymentmethods/sepa--active',
      default: 'paymentmethods/sepa--default',
    },
  },
];

const getInsufficientBalanceErrorMsg = ({ fixValue, assetLabel, isWalletBalanceEnoughForMinTransaction }) => {
  if (isWalletBalanceEnoughForMinTransaction) {
    return `You have only %${fixValue}% ${assetLabel} available on your wallet`;
  }

  return `Not enough balance. Please add ${assetLabel}`;
};

const walletEstimateErrorsMap = {
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.Unknown]: () => 'Unknown error',
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.CouldNotReceiveNetworkFee]: () =>
    `Something went wrong. Please try again`,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.InsufficientTokenBalanceForTokenTransaction]:
    getInsufficientBalanceErrorMsg,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.InsufficientBalanceForCoinTransaction]:
    getInsufficientBalanceErrorMsg,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.InsufficientCoinBalanceForTokenTransaction]:
    ({ feeAssetLabel }) => `Please top up your ${feeAssetLabel} wallet to cover network fees for this transaction.`,
};

const SellAmount = () => {
  const { kycPoaHighRiskVerified } = useUserStatus();
  const [useMyWalletDisabled, setUseMyWalletDisabled] = useState(false);
  const { sellFromCurrencies, sellToCurrencies } = useAssets();
  const { isLoading: estimateIsLoading, data: estimateData, validationValues, walletEstimateData } = useSellEstimate();
  const [error, setError] = useState('');
  const { showErrorToaster } = useAlerts();
  const ga = useGA();
  const { user } = useUser();
  const { sellFlowDisabled } = useConfig();

  const {
    paymentMethod,
    setPaymentMethod,
    fromCurrency,
    setFromCurrency,
    toCurrency,
    amount,
    setAmount,
    setAmountPassed,
    useMyWallet,
    setUseMyWallet,
  } = useSellFlowStore(
    state => ({
      paymentMethod: state.paymentMethod,
      setPaymentMethod: state.setPaymentMethod,
      fromCurrency: state.fromCurrency,
      setFromCurrency: state.setFromCurrency,
      toCurrency: state.toCurrency,
      amount: state.amount,
      setAmount: state.setAmount,
      setAmountPassed: state.setAmountPassed,
      useMyWallet: state.useMyWallet,
      setUseMyWallet: state.setUseMyWallet,
    }),
    shallow,
  );

  const activePaymentMethods = useActiveSellPaymentMethods();
  useEffect(() => {
    if (!activePaymentMethods.find(method => method === paymentMethod)) {
      setPaymentMethod(activePaymentMethods[0]);
    }
  }, []);

  const activeEstimate = estimateData?.[paymentMethod];

  const showWalletError = useMyWallet && walletEstimateData && walletEstimateData.walletBalanceInsufficientionReason;
  const fixValue = showWalletError
    ? Math.min(validationValues?.max, walletEstimateData.maxCryptoAmount)
    : validationValues?.max;

  const validate: IValidate = useMemo(
    () => ({
      moreThan: {
        rule: value =>
          +value >= validationValues?.min ||
          `The deposit amount is too small. Please enter an amount greater than %${validationValues?.min}%`,
        fixValue: validationValues?.min,
      },
      lessThan: {
        rule: value => {
          if (showWalletError) {
            return walletEstimateErrorsMap[walletEstimateData.walletBalanceInsufficientionReason]({
              feeAssetLabel: fromCurrency.network?.label,
              assetLabel: fromCurrency.label,
              fixValue,
              isWalletBalanceEnoughForMinTransaction: walletEstimateData.maxCryptoAmount > validationValues?.min,
            });
          }

          return (
            +value <= validationValues?.max ||
            `The deposit amount is too big. Please enter an amount less than %${validationValues?.max}%`
          );
        },
        fixValue,
      },
    }),
    [validationValues?.min, validationValues?.max, walletEstimateData, fromCurrency, showWalletError, fixValue],
  );

  const cryptocurrencyInputs: ICryptocurrencyInputItem[] = useMemo(
    () => [
      // inputs are required to be interrelated - after changing one input second recalculates,
      {
        // implement it after backend updates
        name: 'fromAmount',
        label: 'You send',
        value: amount,
        currencies: sellFromCurrencies.filter(item =>
          useMyWallet ? item.walletAssetId && item.walletAccountId : true,
        ),
        selectedCurrency: fromCurrency,
        onCurrencyChange: (newCurrency: IInputCurrency) => {
          if (!newCurrency.disabled) {
            ga.sendEvent(EventRequestDtoEventNameEnum.CurrencyChange, {
              email: user.email,
              custom_id: newCurrency.id,
              custom_network: newCurrency.network?.label,
              currency_choosen: newCurrency.ticker,
              custom_type: newCurrency.type,
              customer_source: 'btc',
            });
            setFromCurrency(newCurrency);
          }
        },
        setValue: setAmount,
        validate,
        showNetwork: true,
      },
      {
        name: 'toAmount',
        label: 'You get',
        value: activeEstimate?.estimatedAmount || '',
        currencies: sellToCurrencies,
        selectedCurrency: toCurrency,
        isLoading: estimateIsLoading,
      },
    ],
    [
      amount,
      sellFromCurrencies,
      fromCurrency,
      setAmount,
      activeEstimate?.estimatedAmount,
      sellToCurrencies,
      toCurrency,
      setFromCurrency,
      estimateIsLoading,
      validate,
    ],
  );

  const { formState, trigger, ...formRest } = useForm({
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    if (sellFlowDisabled) {
      showErrorToaster({ title: 'Sell flow is unavailable at the moment.' });
      return;
    }
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    setAmountPassed(true);
  };

  const onToggleUseMyWallet = () => {
    if (useMyWalletDisabled) return;

    const newUseMyWallet = !useMyWallet;
    setUseMyWallet(newUseMyWallet);
    setError('');

    if (newUseMyWallet && fromCurrency && (!fromCurrency.walletAccountId || !fromCurrency.walletAssetId)) {
      setUseMyWalletDisabled(true);

      setTimeout(() => {
        const message = fromCurrency.walletAssetId
          ? 'Sorry this asset is unavailable yet. You can create it in your wallet.'
          : 'Sorry this asset is unavailable to sell using internal wallet.';
        setUseMyWalletDisabled(false);
        setUseMyWallet(false);
        setError(message);
      }, 600);
    }
  };

  useEffect(() => {
    setError('');
  }, [fromCurrency]);

  useEffect(() => {
    // different payment methods have different min/max amounts.
    // We need to trigger validation again if payment method changed
    trigger();
  }, [paymentMethod, validate]);

  return (
    <Box mt="30px">
      <Box px="24px" zIndex="1">
        <CryptocurrencyInputsForm control={formRest.control} inputs={cryptocurrencyInputs} />
        <FormControl isInvalid={!!error}>
          <Switch
            px="10px"
            onToggle={onToggleUseMyWallet}
            isChecked={kycPoaHighRiskVerified && useMyWallet}
            isDisabled={!kycPoaHighRiskVerified}
            label={
              useMyWallet
                ? 'Turn off to sell from your external wallet address'
                : 'Turn on to sell from your CryptoWallet.com wallet address'
            }
          />
          <div className={error ? 'shake-error' : ''}>
            <ErrorMessage
              error={{
                type: 'custom',
                message: error,
              }}
              type={ErrorMessageType.ERROR}
            />
          </div>
        </FormControl>
        <PaymentMethods
          title="Payout method"
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          list={activePaymentMethods.map(method => ({
            ...paymentMethodsInfo[method],
            estimateValue: `You get ${estimateData?.[method]?.estimatedAmount?.toString() || ''} ${toCurrency.ticker}`,
          }))}
          containerProps={{ mt: '0' }}
        />
        <ExchangeLimits
          exchangeType={ExchangeType.Sell}
          paymentMethod={paymentMethod}
          currencyTicker={toCurrency.ticker}
        />
        <SellTransactionDetails />
      </Box>
      <ActionGuard>
        {({ openAlert }) => (
          <BottomPurpleButton size="lg" onPress={openAlert || handleSubmit}>
            Continue
          </BottomPurpleButton>
        )}
      </ActionGuard>
    </Box>
  );
};

export default SellAmount;
