import React from 'react';

const CircleAddIcon = ({ color, ...rest }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="20" cy="20.0001" r="19.5" stroke={color} />
    <line x1="20" y1="13.0001" x2="20" y2="27.0001" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <line x1="27" y1="20.0001" x2="13" y2="20.0001" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default CircleAddIcon;
