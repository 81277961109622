import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import Authentication, { AuthenticationProps } from './steps/Authentication';
import FlowCanceled, { FlowCanceledProps } from './steps/Canceled';
import { getNewAccessToken, initIframe } from './initVerificationIframe';
import PermissionFlow from './PermissionFlow';

const permissionType = 'POW';

const AuthenticationComponent = (props: AuthenticationProps) => {
  const queryClient = useQueryClient();

  useEffect(
    () => () => {
      queryClient.invalidateQueries(['user']);
    },
    [],
  );

  return (
    <Authentication
      getNewAccessToken={getNewAccessToken}
      initIframe={initIframe}
      header="Submit additional information"
      permissionType={permissionType}
      {...props}
    />
  );
};

const FlowCanceledComponent = (props: FlowCanceledProps) => (
  <FlowCanceled
    permissionType={permissionType}
    content={() => (
      <>
        Unfortunately, you can&#39;t use our platform without verifying your identity. Complete the KYC procedure to get
        access to all CryptoWallet features. Just log in to your account with your email address.
      </>
    )}
    {...props}
  />
);

export function Pow({ authenticationProps }: { authenticationProps?: Partial<AuthenticationProps> }): JSX.Element {
  return (
    <PermissionFlow
      AuthenticationComponent={AuthenticationComponent}
      FlowCanceledComponent={FlowCanceledComponent}
      authenticationProps={authenticationProps}
    />
  );
}
