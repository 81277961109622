/* eslint-disable sonarjs/no-duplicate-string */
import { IInputProps, Input as NBInput } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '../../../context/PartnersSettingsContext';

const Input = (props: IInputProps) => {
  const partnerStyles = usePartnerStyles('Input');

  return (
    <NBInput
      variant="unstyled"
      h="56px"
      px="24px"
      bg="gray.200"
      borderRadius="10px"
      color="textRegular"
      borderWidth="1px"
      borderColor="transparent"
      _focus={{
        borderColor: 'secondary.400',
        _hover: {
          borderColor: 'secondary.400',
        },
      }}
      _hover={{
        bg: 'gray.300',
      }}
      _disabled={{
        opacity: 1,
        background: 'transparent',
        borderColor: 'gray.100',
        placeholderTextColor: 'textDisabled',
        color: 'textDisabled',
        cursor: 'not-allowed',
      }}
      _invalid={{
        borderColor: 'error.400',
        _hover: {
          borderColor: 'error.400',
          _focus: {
            borderColor: 'error.400',
          },
        },
        _focus: {
          borderColor: 'error.400',
        },
      }}
      {...partnerStyles}
      {...props}
    />
  );
};

export default Input;
