export const NOTIFICATION_TIMEOUT = 2000;

export const MIN_EXCHANGE_AMOUNT = 20;
export const BASE_MIN_WITHDRAW_AMOUNT = 0.00001;

export const DEFAULT_BUY_AMOUNT = 125;
export const DEFAULT_SELL_AMOUNT = 3000;

export const CURRENCY_PLACEHOLDER_IMAGE = 'https://changenow.io/images/sprite/currencies/btc.svg';

export const FIAT_TICKERS_TO_SYMBOLS = {
  usd: '$',
  eur: '€',
} as const;

export enum ExchangeTypeEnum {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum PersistKeys {
  DashboardBuyFlow = 'dashboard-buy-flow',
  DashboardSellFlow = 'dashboard-sell-flow',
  TwoFaAlertViewed = '2faAlertViewed',
  KycAlertViewed = 'KycAlertViewed',
  KycMobileBannerViewed = 'KycMobileBannerViewed',
  ReferralMobileBannerViewed = 'ReferralMobileBannerViewed',
  ShowBlockedByCountryScreen = 'ShowBlockedByCountryScreen', // else we show regular blocked screen
  partnerId = 'partnerId',
}
