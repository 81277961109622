import { create } from 'zustand';
import { CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum } from '../../client/src';
import { persist, getEnv } from '@cryptowallet/frontend/utils';
import { createJSONStorage } from 'zustand/middleware';

export enum BalanceListView {
  Balance,
  Manage,
  Add,
}

export enum DetailsPanelView {
  Overview,
  Deposit,
  Withdraw,
  GenerateAddress,
  Swap,
}

interface IWithdrawData {
  amount: string;
  destinationAddress: string;
  destinationTag: string;
  walletFeeLevel: CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum;
}

interface ISwapData {
  amount: string;
  toSelectedWalletAssetId: string;
  swapId: string;
}

const env = getEnv();

const DEFAULT_WALLET_ADDRESS = env.NX_DEFAULT_WALLET_ADDRESS;

export interface AccountsState {
  selectedWalletAssetId: string;
  setSelectedWalletAssetId: (selectedWalletAssetId: string) => void;
  selectedWalletAccountId: string;
  setSelectedWalletAccountId: (selectedWalletAccountId: string) => void;
  balanceListView: BalanceListView;
  setBalanceListView: (balanceListView: BalanceListView) => void;
  detailsPanelView: DetailsPanelView;
  setDetailsPanelView: (detailsPanelView: DetailsPanelView, selectedWalletAccountId?: string) => void;
  withdrawData: IWithdrawData;
  setWithdrawData: (params: Partial<IWithdrawData>) => void;
  swapData: ISwapData;
  setSwapData: (params: Partial<ISwapData>) => void;
  reset: () => void;
  resetSwapData: () => void;
}

const defaultWithdrawData = {
  amount: '',
  destinationAddress: DEFAULT_WALLET_ADDRESS,
  destinationTag: '',
  walletFeeLevel: CreateWalletTransactionPayloadWalletsDtoWalletFeeLevelEnum.Medium,
};

const defaultSwapData = {
  amount: '',
  toSelectedWalletAssetId: '',
  swapId: '',
};

const initialState = {
  selectedWalletAssetId: '',
  selectedWalletAccountId: '',
  balanceListView: BalanceListView.Balance,
  detailsPanelView: DetailsPanelView.Overview,
  withdrawData: defaultWithdrawData,
  swapData: defaultSwapData,
};

export const useStore = create<AccountsState>()(
  persist(
    set => {
      const resetWithdrawData = () =>
        set(prev => ({
          ...prev,
          withdrawData: defaultWithdrawData,
        }));

      return {
        ...initialState,
        setSelectedWalletAssetId: selectedWalletAssetId => {
          set({ selectedWalletAccountId: '', selectedWalletAssetId });
          resetWithdrawData();
        },
        setSelectedWalletAccountId: selectedWalletAccountId => {
          set({ selectedWalletAccountId, selectedWalletAssetId: '' });
          resetWithdrawData();
        },
        setBalanceListView: balanceListView => set({ balanceListView }),
        setDetailsPanelView: (detailsPanelView, selectedWalletAccountId) => {
          set(prev => {
            if (
              detailsPanelView === DetailsPanelView.Withdraw &&
              selectedWalletAccountId &&
              selectedWalletAccountId !== prev.selectedWalletAccountId
            ) {
              // we do reset when changing to Withdraw form and changing selectedWalletAccountId
              resetWithdrawData();
            }
            return {
              detailsPanelView,
              selectedWalletAccountId: selectedWalletAccountId || prev.selectedWalletAccountId,
              ...(selectedWalletAccountId ? { selectedWalletAssetId: '' } : {}),
            };
          });
        },
        setWithdrawData: params => set(prev => ({ ...prev, withdrawData: { ...prev.withdrawData, ...params } })),
        setSwapData: params => set(prev => ({ ...prev, swapData: { ...prev.swapData, ...params } })),
        resetWithdrawData,
        resetSwapData: () => set(prev => ({ ...prev, swapData: defaultSwapData })),
        reset: () => set(initialState),
      };
    },
    {
      name: 'accounts',
      storage: createJSONStorage(() => window?.localStorage),
    },
  ),
);

// if (process.env.NODE_ENV !== 'production') {
//   useStore.subscribe(console.log); // eslint-disable-line no-console
// }
