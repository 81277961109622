import { useMutation } from '@tanstack/react-query';
import { Box, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { userApi } from '@cryptowallet/frontend/api';
import { getPlatform } from '@cryptowallet/frontend/utils';

import { GhostButton, LabeledInput, SecondsCountdown, useAlerts, useUser } from '../../../index';

import { IFormGuardComponentProps } from '.';

const isWeb = getPlatform() === 'web';

const resendTimeoutMS = 60000;

const OtpFormGuard = ({ control, onSubmit, name, minLength, requestCodeOnMount }: IFormGuardComponentProps) => {
  const user = useUser();
  const { showErrorToaster } = useAlerts();
  const [resendTime, setResendTime] = useState(Date.now() + resendTimeoutMS);

  const requestOtpMutation = useMutation(() => userApi.usersControllerRequestOtp({ email: user?.user?.email ?? '' }));
  const handleResendClick = () => {
    try {
      setResendTime(Date.now() + resendTimeoutMS);
      requestOtpMutation.mutate();
    } catch (err) {
      showErrorToaster({ title: `Can't resend code, please try again later` });
    }
  };

  useEffect(() => {
    try {
      if (requestCodeOnMount) {
        requestOtpMutation.mutate();
      }
    } catch (err) {
      showErrorToaster({ title: `Can't send OTP code, please try again later` });
    }
  }, []);

  return (
    <Box position="relative" h={isWeb ? undefined : '100px'}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: 'Verification code is required',
          minLength: {
            value: minLength,
            message: 'Verification code must contain 6 digits',
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LabeledInput
            keyboardType="numeric"
            label="Email verification code"
            labelTextProps={{
              color: 'textLabel',
              fontSize: 'lg',
            }}
            onSubmit={onSubmit}
            placeholder="Enter code here"
            onChangeText={val => onChange(val.replace(/\D/g, '').substring(0, 6))}
            value={value}
            error={error}
            h="56px"
            fontSize="lg"
          />
        )}
      />
      <Box position="absolute" right="19px" bottom={isWeb ? '43px' : undefined} top={isWeb ? undefined : '48px'}>
        <SecondsCountdown
          expireTime={resendTime}
          renderer={({ timer, isCompleted }) => {
            if (isCompleted) {
              return (
                <GhostButton _text={{ fontSize: 'lg' }} onPress={handleResendClick}>
                  Resend
                </GhostButton>
              );
            }

            return (
              <Text fontSize="lg" mt="0">
                Resend in {timer}
              </Text>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default OtpFormGuard;
