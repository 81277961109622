import { Box, Divider, IBoxProps, IDividerProps } from 'native-base';

export type AuthHeaderProps = IBoxProps & {
  children: JSX.Element | string | (JSX.Element | string)[];
  fullWidth?: boolean;
  hideDivider?: boolean;
  dividerProps?: IDividerProps;
};

const AuthHeader = ({ children, hideDivider = false, dividerProps = {}, ...props }: AuthHeaderProps): JSX.Element => (
  <Box py="32px" px={{ base: '24px', lg: '32px' }} mb="4" position="relative" {...props}>
    {children}
    {!hideDivider && (
      <Box position="absolute" bottom="0" left="0" px="20px" w="100%" {...dividerProps}>
        <Divider orientation="horizontal" bg="gray.100" />
      </Box>
    )}
  </Box>
);

export default AuthHeader;
