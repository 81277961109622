import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types';

import { Button, usePartnerStyles } from '@cryptowallet/frontend/ui';

export const PrimaryButton = ({ children, ...props }: IButtonProps) => {
  const partnerStyles = usePartnerStyles('PrimaryButton');

  return (
    <Button
      outlineWidth="0"
      borderRadius="0"
      borderWidth="1px"
      borderColor="transparent"
      borderTopWidth="1px"
      borderTopColor="gray.100"
      borderBottomLeftRadius="10px"
      borderBottomRightRadius="10px"
      h="80px"
      _text={{
        color: 'primary.300',
        fontSize: 'lg',
      }}
      _focus={{
        borderColor: 'secondary.400',
        borderTopColor: 'secondary.400',
      }}
      _hover={{
        bg: 'primary.300',
        _text: {
          color: 'white',
        },
      }}
      _disabled={{
        opacity: 1,
        _text: {
          color: 'textDisabled',
        },
      }}
      variant="unstyled"
      {...props}
      {...partnerStyles}>
      {children}
    </Button>
  );
};
