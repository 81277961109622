import { Box, HStack, Modal, Text, VStack } from 'native-base';
import React from 'react';

import { CloseButton, Icon, ModalBase } from '../../../index';

const data = [
  {
    title: '1. GENERAL CONDITIONS',
    list: [
      'CW LAB OÜ, referred to herein also as “CryptoWallet.com” or “we” is committed to protecting user (also referred as “you”) privacy as outlined below. This document, along with the Terms of Use, outlines different ways that CryptoWallet.com will interact with user data and was written in accordance with the Personal Data Protection Act 15.01.2019 in the Republic of Estonia and General Data Protection Regulation (EU) 2016/679. CryptoWallet.com performs the actions with data from those who visit or use our website https://cryptowallet.com, applications and other products.',
      'User acknowledges and gives consent to this data collection and to our Privacy Policy as a whole by using our Website, Services and Products.',
    ],
  },
  {
    title: 'PERSONAL INFORMATION: GATHERING AND USE',
    list: [
      'We collect the following categories of personal data:',
      'Information necessary for compliance “Know your customer” procedure: your name, nationality, citizenship, home address, email address, personal ID code; copies of identity and other documents, selfie photo, date of birth etc;',
      'Information necessary for compliance with Anti-Money Laundering Regulations and Procedures : financial data, ownership and source of funds and/or wealth, transaction data and the nature of using the service, e.g. transaction sums, transaction history, counter-parties, bank account, and card number(s) and account holders and other information which we may request in order to provide our services and comply with regulations.',
      'Data that identifies you such as your IP address, language, country, browser type, and version, time zone setting, browser plug-in types, some location information about where you might be, operating system and version, page response times, download errors, how long you stay on web pages, what you do on those pages, how often, and other actions;',
      'Other personal data you share with us or personal data that we may legally obtain from some other sources.',
      {
        title: 'Personal data may be used to:',
        subList: [
          'Authorize user to open an account with CryptoWallet.com;',
          'Respond to user queries or complaints;',
          'Contact user;',
          'Process and facilitate financial transactions and services;',
          'Maintain account security through measures such as 2FA;',
          'Analyze user behavior and trends on our website to improve its performance;',
          'Comply with regulations such as for anti-money laundering and fraud prevention purposes;',
          'Anonymized market research that allows us to improve our services and optimize our performance for user experience. The user may opt for their data to be non-anonymized.',
          'Inform user of products and offers that may interest him, including from third-party organizations if user has agreed to allow for this type of information to be sent to him;',
          'If we want to sell our business, or our CryptoWallet.com, we can disclose data to the potential buyer;',
        ],
      },
      'Personal data may be processed by third parties to supply services to you on our behalf. These may include payment processing, search engine facilities, advertising and marketing.',
    ],
  },
  {
    title: 'LEGAL GROUND FOR COLLECTING',
    list: [
      'Consent the freely given, informed, and unambiguous indication of your wishes to the processing of your personal data for a specific purpose which signifies agreement to the processing of personal data.',
      'Contract- if data necessary for us to perform a contract or terms of use to which you are a party or in order to take steps at your request prior to entering into the contract or terms and conditions.',
      'Legal obligations- if data is necessary for compliance with a legal obligation to which CryptoWallet.com is a subject; Legitimate Interests- a legal ground for the processing of your personal data when it is based on our legitimate interests or the legitimate interests of a third party, provided that those interests are not outweighed by your rights and interests and those interests have a specific purpose, they are necessary, and they are balanced.',
    ],
  },
  {
    title: 'WHO ELSE WILL SEE USER DATA',
    list: [
      'CryptoWallet.com uses personal information gathered from our users in accordance with legal regulations. We may distribute personal information to third parties such as affiliates, financial institutions, representatives, legal staff, partners, and contractors. Users acknowledge and agree that data storage, collecting and processing might be performed with third party service providers.',
      'Information may also be shared with law enforcement or regulatory bodies in accordance with local laws.',
      'Third parties are required to protect your personal information and use it only for the purposes stated, such as performing a service for our CryptoWallet.com. All third parties are bound to comply with our privacy policy, with the exception of law enforcement and regulatory bodies.',
      'Partners and Other Third Parties. CryptoWallet.com is a controller responsible for processing and obligated to legally keep and use your personal information. Partners may include CryptoWallet.com representatives or affiliate parties. Payment processors, financial institutions, and services responsible for verifying your identification for security purposes are examples of other possible data processors involved in our personal data processing system. CryptoWallet.com uses Sum&Substance: (https://sumsub.com) for KYC/AML verification; Twilio: (https://www.twilio.com) for email authentication; Clear Junction (https://clearjunction.com) for fiat transactions, Chainalisys (https://www.chainalysis.com) for transaction monitoring, Maxmind (https://www.maxmind.com) for services providing fraud-preventing and risk modeling tools; Freshdesk (https://freshdesk.com) for customer support, advertising companies (e.g. Google AdWords) for marketing purposes, social media and website analytics companies.',
      'CryptoWallet.com websites and applications may link to other sites and online third-party tools and resources.',
      'Our privacy policy does not apply to these third-party entities, but to our websites and applications only.',
      'Third-party sites and resources are not under our control, and we accept no liability for the use of these sites and resources including losses, technical failures, damages, or any other undesirable outcomes. Users are advised to familiarize themselves with the terms of use and privacy policies for third party sites and resources. Please read their privacy policies before you submit any data to them',
    ],
  },
  {
    title: 'LEGAL RIGHTS OF USERS',
    list: [
      'CryptoWallet.com users may request access to their personal data which we store. You may request that we make accurate adjustments to your data, or that we erase your data. You have the right to object to us processing your data or to change your mind regarding the permissions we have to interact with your data. You can contact us at support@cryptowallet.com for any of the above. In some cases, we may charge a fee to make these adjustments to data or agreements with specific customers, depending on the nature of the request.',
    ],
  },
  {
    title: 'SECURITY',
    list: [
      'Crypto Wallet protects user data using encryption as well as restricting access to authorized employees and takes all possible steps within our means to protect user data. We use Secure Sockets Layered (SSL) encryption among other features, such as firewalls, virus detection, and others. The user has a possibility to activate 2FA authentication and use it as an additional security measure for authentication.',
      'Data submission is at users own risk and they are responsible for their own data. We work with affiliate organizations and partners, and they may receive your data overseas depending on our own operations.',
    ],
  },
  {
    title: 'RETENTION AND STORAGE OF DATA',
    list: [
      'CryptoWallet.com creates and keeps information records regarding customer accounts, activities, and transactions. These records may be stored digitally or on paper, and in most cases, digital methods are used. These records are necessary to allow us to offer our services and handle user queries, as well as for the purposes of legal compliance. If you terminate your account in CryptoWallet.com, we will mark your account in our database as “Terminated”, but we will keep your account information in our database for at least five years. This is necessary to prevent fraud and prevent people who try to commit fraud from being able to avoid their detection by merely terminating their account and opening another one. We keep data for this period to comply with international and local laws and regulations around money laundering.',
      'We may keep data for over ten years in some cases for tax, bookkeeping and regulatory purposes.',
      'CryptoWallet.com will not attempt to collect sensitive data not necessary for our operations, and we will not process information belonging to minors under the age of 18.',
    ],
  },
  {
    title: 'FRAUD, PHISHING AND EMAIL SCAMS',
    list: [
      'Please be notified that CryptoWallet.com is not in any partnership with entities who represent themselves as customer support agents, providing customer support services via email and/or social media and promising to help solve your issues for money. If you discovered what you believe is a fraud, phishing, or scam which impersonates CryptoWallet.com, please contact us as soon as possible',
    ],
  },
  {
    title: 'CHANGES AND QUERIES',
    list: [
      'This Privacy Policy is subject to change at the sole discretion of CryptoWallet.com. Users will be informed of any major changes to the policy, and we encourage users to check the policy from time to time for minor changes as they see fit. Changes will typically be due to operational, legal, or regulatory circumstances.',
      'To make an inquiry about any of the information listed within this privacy policy, please contact help@cryptowallet.app',
    ],
  },
];

export const PrivacyPolicyAlert = ({ onClose }: { onClose: () => void }) => (
  <ModalBase blurredBg={true} onClose={onClose}>
    <Modal.Content w="996px" maxW="100vw" overflowY="auto">
      <VStack p="40px" bg="background.400">
        <Modal.Header p="0" borderColor="gray.200" borderBottomWidth="0">
          <HStack justifyContent="space-between" alignItems="center" pr="26px">
            <Text color="white" fontSize="3xl">
              Privacy Policy
            </Text>
            <CloseButton
              position="absolute"
              top="4px"
              right="0"
              iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }}
              onPress={onClose}
            />
          </HStack>
        </Modal.Header>
        <Modal.Body p="0">
          {data.map(({ title, list, subTitle }, index) => (
            <Box key={index}>
              {title && (
                <Text mt="20px" mb="19px" color="white" fontSize="20px">
                  {title}
                </Text>
              )}
              {subTitle && (
                <Text mt="20px" mb="19px" color="white" fontSize="lg">
                  {subTitle}
                </Text>
              )}
              <VStack>
                {list.map((item, subIndex) => {
                  if (typeof item === 'string') {
                    return (
                      <VStack key={subIndex} mb="6px" pl="18px">
                        <Text color="textRegular" fontSize="lg">
                          {item}
                        </Text>
                      </VStack>
                    );
                  }

                  return (
                    <VStack key={subIndex} mb="6px" pl="18px">
                      <Text color="textRegular" fontSize="lg">
                        {item.title}
                      </Text>
                      {item.subList.map((subText, i) => (
                        <VStack key={`${subIndex}-${i}`} mb="6px" pl="36px">
                          <Icon position="absolute" top="4px" left="18px" h="10px" w="12px" name="check-icon-small" />
                          <Text color="textRegular" fontSize="lg">
                            {subText}
                          </Text>
                        </VStack>
                      ))}
                    </VStack>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </Modal.Body>
      </VStack>
    </Modal.Content>
  </ModalBase>
);
