import { Box } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BottomPurpleButton, LabeledInput, useAlerts } from '@cryptowallet/frontend/ui';
import { marketingApi, WhitelistRequestOtpBadRequestMarketingDtoErrorEnum } from '@cryptowallet/web/api-client';
import { CaptchaResult, showCaptcha } from '@cryptowallet/web/utils/captcha';

import useStore, { WhitelistRegisterResult } from '../store';

const EmailForm = ({ onStepCompleted }) => {
  const { onApiError } = useAlerts();
  const [pending, setPending] = useState(false);
  const [setNewEmail, setFlowIsCompleted] = useStore(state => [state.setNewEmail, state.setFlowIsCompleted], shallow);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const onSubmit = async ({ email }) => {
    if (!isValid || pending) return;

    setPending(true);
    let captchaRes;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.activeElement?.blur();
      captchaRes = await showCaptcha();

      if (captchaRes === CaptchaResult.Closed) {
        return;
      }
    } catch (e) {
      return;
    }

    try {
      await marketingApi.marketingControllerRequestOtp({ email, captcha: captchaRes });

      setNewEmail(email);
      onStepCompleted();
    } catch (err) {
      if (err.response.data.error === WhitelistRequestOtpBadRequestMarketingDtoErrorEnum.UserAlreadyExists) {
        setFlowIsCompleted(true, WhitelistRegisterResult.DUPLICATE);
      } else {
        onApiError(err);
      }
    }
    setPending(false);
  };

  return (
    <Box w="476px" maxW="100vw">
      <form>
        <Box p="20px" pb="10px">
          <Controller
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: 'Email is incorrect',
              },
            }}
            render={({ field: { onChange, ref, ...field }, fieldState: { error, isTouched } }) => (
              <LabeledInput
                error={isTouched && error}
                onChangeText={onChange}
                onSubmit={handleSubmit(onSubmit)}
                {...field}
                label="Email"
                labelTextProps={{
                  color: 'textLabel',
                  fontSize: 'lg',
                }}
                placeholder="example@email.com"
                h="56px"
                fontSize="lg"
              />
            )}
            name="email"
          />
        </Box>
        <BottomPurpleButton isDisabled={!isValid} size="lg" onPress={handleSubmit(onSubmit)}>
          Continue
        </BottomPurpleButton>
      </form>
    </Box>
  );
};

export default EmailForm;
