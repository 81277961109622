import { HStack, Text, VStack } from 'native-base';
import React from 'react';

import StepLine from './StepLine';

interface IStepsProgressBarProps {
  steps: string[];
  activeStep: number;
}

const StepsProgressBar = ({ steps, activeStep }: IStepsProgressBarProps): JSX.Element => (
  <HStack>
    {steps.map((step, index) => (
      <VStack key={`${step}-${index}`} flex="1">
        <StepLine active={activeStep >= index} />
        {activeStep === index && (
          <Text fontSize="md" color="textLabel" textAlign="center" mt="4px">
            {step}
          </Text>
        )}
      </VStack>
    ))}
  </HStack>
);

export default StepsProgressBar;
