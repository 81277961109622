import qs from 'qs';
import { Navigate, useLocation } from 'react-router-dom';

import { useUserStatus } from '@cryptowallet/frontend/hooks';

const ProtectedRoute = ({ children }) => {
  const userStatus = useUserStatus();
  const location = useLocation();

  if (!userStatus.isLoggedIn) {
    const search = qs.parse(location.search.slice(1));

    return <Navigate to="/" replace state={{ redirectUrl: location.pathname, search }} />;
  }

  return children;
};

export default ProtectedRoute;
