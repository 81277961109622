import { Box, IBoxProps } from 'native-base';

import { useScreenSize } from '@cryptowallet/frontend/hooks';

export default function CenterElement({
  children,
  containerProps = {},
}: {
  children: JSX.Element;
  containerProps?: IBoxProps;
}): JSX.Element {
  const { isBiggerThanTablet } = useScreenSize();

  return (
    <Box
      alignItems="center"
      justifyContent={isBiggerThanTablet ? 'center' : 'flex-start'}
      display="flex"
      flexGrow="1"
      flexDirection={{ base: 'column', lg: 'row' }}
      maxW="100%">
      <Box maxW="100%" display="flex" alignItems="center" {...containerProps}>
        {children}
      </Box>
    </Box>
  );
}
