/* eslint-disable @typescript-eslint/no-shadow */
import { Box } from 'native-base';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { BalanceListView, useAccountsStore } from '@cryptowallet/frontend/stores';
import AuthHeader from '@cryptowallet/web/components/AuthHeader';
import useInitialScrollTop from '@cryptowallet/web/hooks/useInitialScrollTop';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

import ManageAssets from '../ManageAssets';

import TotalBalance from './TotalBalance';

type ViewData = {
  [key in BalanceListView]?: {
    label: string;
    Component: React.FC;
  };
};

const views: ViewData = {
  [BalanceListView.Balance]: {
    label: 'Total Balance',
    Component: TotalBalance,
  },
  [BalanceListView.Manage]: {
    label: 'Manage Accounts',
    Component: ManageAssets,
  },
  [BalanceListView.Add]: {
    label: 'Add Account',
    Component: ManageAssets,
  },
};

const BalanceList = () => {
  const { isBiggerThanTablet, isMobile } = useScreenSize();
  useInitialScrollTop(!isMobile);
  const { view, setBalanceListView } = useAccountsStore(
    state => ({
      view: state.balanceListView,
      setBalanceListView: state.setBalanceListView,
    }),
    shallow,
  );

  const openBalanceView = () => setBalanceListView(BalanceListView.Balance);

  const { Component, label } = views[view];

  return (
    <Box
      maxW={{ base: '100%', xl: '486px' }}
      w={{ base: '100%', xl: '40%' }}
      h="100%"
      minH="60vh"
      background="background.400"
      borderWidth="1px"
      borderColor="gray.100"
      borderRadius="10px"
      zIndex="1"
      mr={isBiggerThanTablet ? '24px' : '0'}>
      <AuthHeader mb="0">
        <FormHeading onPressBack={view !== BalanceListView.Balance && openBalanceView} text={label} />
      </AuthHeader>
      <Component />
    </Box>
  );
};

export default BalanceList;
