import './styles/globals.css';
// In future we may add pages lazy load and load this script exactly for login page
import './utils/geetest';
import 'react-toastify/dist/ReactToastify.css';

import { View } from 'native-base';
import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppConfigProvider, AuthProvider } from '@cryptowallet/frontend/ui';

import ChatWidget from '../ChatWidget';

import BlockedFilter from './components/BlockedFilter';
import { AppLayout } from './components/layout';
import ExchangerRoute from './components/routes/ExchangerRoute';
import ProtectedRoute from './components/routes/ProtectedRoute';
import PublicRoute from './components/routes/PublicRoute';
import Accounts, { renderRoutes as renderAccountsRoutes } from './pages/Accounts';
import Dashboard, { renderRoutes as renderDashboardRoutes } from './pages/Dashboard';
import Home from './pages/Home';
import Referral, { renderRoutes as renderReferralRoutes } from './pages/Referral';
import Rewards from './pages/Rewards';
import Settings, { renderRoutes as renderSettingsRoutes } from './pages/Settings';
import Whitelist from './pages/Whitelist';
import WhitelistSuccess from './pages/WhitelistSuccess';

export function App() {
  useEffect(() => {
    ReactPixel.init('741547251332363');
  }, []);

  return (
    <BrowserRouter>
      <View w="100%" minH="100vh" my="auto" bg="black">
        <ToastContainer />
        <AuthProvider>
          <AppConfigProvider>
            <ChatWidget />
            <AppLayout>
              <BlockedFilter>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PublicRoute>
                        <Home />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/wallet"
                    element={
                      <ProtectedRoute>
                        <Accounts />
                      </ProtectedRoute>
                    }>
                    {renderAccountsRoutes()}
                  </Route>
                  <Route
                    path="/whitelist"
                    element={
                      <PublicRoute loggedUserVisible={true}>
                        <Whitelist />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/whitelist/success"
                    element={
                      <PublicRoute loggedUserVisible={true}>
                        <WhitelistSuccess />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute>
                        <ExchangerRoute>
                          <Dashboard />
                        </ExchangerRoute>
                      </ProtectedRoute>
                    }>
                    {renderDashboardRoutes()}
                  </Route>
                  <Route
                    path="/rewards"
                    element={
                      <ProtectedRoute>
                        <Rewards />
                      </ProtectedRoute>
                    }></Route>
                  <Route
                    path="/account"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }>
                    {renderSettingsRoutes()}
                  </Route>
                  <Route
                    path="/referral"
                    element={
                      <ProtectedRoute>
                        <Referral />
                      </ProtectedRoute>
                    }>
                    {renderReferralRoutes()}
                  </Route>
                </Routes>
              </BlockedFilter>
            </AppLayout>
          </AppConfigProvider>
        </AuthProvider>
      </View>
    </BrowserRouter>
  );
}

export default App;
