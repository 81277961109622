import { Box, FormControl, HStack, IFormControlProps } from 'native-base';
import { InterfacePressableProps } from 'native-base/lib/typescript/components/primitives/Pressable/types';
import React from 'react';
import { FieldError } from 'react-hook-form';

import { getPlatform } from '@cryptowallet/frontend/utils';

import { CheckedIcon, usePartnerStyles } from '../../index';

import ErrorMessage, { ErrorMessageType } from './ErrorMessage';
import Pressable from './Pressable';

const isWeb = getPlatform() === 'web';
interface IPressableCheckboxProps extends InterfacePressableProps {
  isChecked: boolean;
  onChange: () => void;
  error?: FieldError;
  formControlProps?: IFormControlProps;
  hideError?: boolean;
}

// created this component to be consistent with design
// because native-base not supports styling their checkbox on focus..

const PressableCheckbox = ({
  children,
  isChecked,
  onChange,
  error,
  formControlProps = {},
  hideError = false,
  ...rest
}: IPressableCheckboxProps) => {
  const partnerStyles = usePartnerStyles('PressableCheckbox');

  const isInvalid = !!error;

  return (
    <FormControl isInvalid={isInvalid} {...formControlProps}>
      <Pressable accessibilityRole="checkbox" onPress={onChange} outlineWidth="0" {...rest}>
        {({ isFocused, isHovered }) => {
          const usePartnerStyle = !!partnerStyles.borderColor;

          let borderColor = usePartnerStyle ? partnerStyles.borderColor.normal : 'gray.200';
          if (isHovered) {
            borderColor = usePartnerStyle ? partnerStyles.borderColor.active : 'primary.400';
          } else if (isFocused) {
            borderColor = 'secondary.400';
          } else if (isInvalid) {
            borderColor = 'error.400';
          }

          return (
            <HStack position="relative" pl="32px" alignItems="center">
              <Box
                position="absolute"
                top="0"
                left="0"
                h="32px"
                w="32px"
                borderRadius="10px"
                borderWidth={isFocused ? '2px' : '1px'}
                borderColor={borderColor}
                justifyContent="center">
                {isChecked && <CheckedIcon />}
              </Box>
              {children}
            </HStack>
          );
        }}
      </Pressable>
      {!hideError && <ErrorMessage type={ErrorMessageType.ERROR} error={error} />}
    </FormControl>
  );
};

export default PressableCheckbox;
