import React from 'react';

const NavigationSettingsDeactivateIcon = ({ color }) => (
  <svg width="13" height="14" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88848 12.8615C5.28766 12.8615 3.48954 10.6509 3.48954 7.99928V7.95519C3.48954 5.30362 5.33136 3.13847 7.88848 3.13847C9.40602 3.13847 10.5979 3.80028 11.7676 4.8843L13.8919 2.38608C12.4829 0.971875 10.7713 0 7.9101 0C3.25127 0 0 3.6014 0 7.99928V8.04337C0 12.4858 3.31612 16 7.78039 16C10.706 16 12.4397 14.939 14 13.2373L11.8762 11.0496C10.6839 12.1542 9.62221 12.8615 7.88848 12.8615Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41545 8.0808C6.41545 8.83894 7.01842 9.45379 7.76192 9.45379C8.50542 9.45379 9.10839 8.83894 9.10839 8.0808C9.10839 7.32267 8.50542 6.70781 7.76192 6.70781C7.01842 6.70781 6.41545 7.32267 6.41545 8.0808Z"
      fill={color}
    />
  </svg>
);

export default NavigationSettingsDeactivateIcon;
