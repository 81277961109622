import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/frontend/client';

import { useUserSettings } from '@cryptowallet/frontend/ui';

const paymentMethods = [
  ExchangerCreateExchangeRequestDtoPspTypeEnum.Card,
  ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa,
];

export const useActiveBuyPaymentMethods = () => {
  const { allowedPaymentMethods } = useUserSettings();
  return paymentMethods.filter(method => allowedPaymentMethods.includes(method));
};
