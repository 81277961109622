import { Box, HStack, StyledProps, Text } from 'native-base';
import React from 'react';

import { useAssets, useBuyExchange } from '@cryptowallet/frontend/hooks';
import { useBuyEstimate } from '@cryptowallet/frontend/stores';
import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/web/api-client';
import CopyBox from '@cryptowallet/web/components/CopyBox';

interface PaymentInfoProps extends StyledProps {
  fullData?: boolean;
}

const roughlyEstimate = {
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Card]: 'in 5-30 minutes',
  [ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa]: 'up to 24h',
};

const PaymentInfo = (props?: PaymentInfoProps): JSX.Element => {
  const { buyToCurrencies } = useAssets();
  const { data: exchangeResponse, estimateResult, completed } = useBuyExchange();
  const { data: estimateData } = useBuyEstimate(!completed);

  const { id: transactionId, toExchangerAssetId: toCurrencyId, pspType: paymentMethod } = exchangeResponse?.data || {};
  const { txHash, walletAddress, destinationTag } = exchangeResponse?.data?.payOut || {
    walletAddress: '',
    destinationTag: '',
  };

  const toCurrency = buyToCurrencies.find(item => item.id === toCurrencyId);
  const ticker = toCurrency?.ticker?.toUpperCase() || '';
  const estimate = props.fullData || !estimateData ? estimateResult : estimateData[paymentMethod];

  return (
    <Box borderWidth="1px" borderColor="gray.100" borderRadius="10px" mb="32px" {...props}>
      <HStack>
        <Box p="16px" w="50%" borderRightWidth="1px" borderRightColor="gray.100">
          <Text color="textLabel" fontSize="xl">
            You will get
          </Text>
          <Text color="textRegular" fontSize="xl">
            {estimate?.estimatedAmount?.toString().substring(0, 18)} {ticker}
          </Text>
        </Box>
        <Box p="16px" w="50%">
          {transactionId && (
            <CopyBox
              title={
                <Text lineHeight="24px" color="textLabel" fontSize="xl">
                  Transaction ID
                </Text>
              }
              value={transactionId}
              valueStyles={{ fontSize: 'xl' }}
            />
          )}
        </Box>
      </HStack>
      <Box p="16px" borderTopWidth="1px" borderTopColor="gray.100">
        <Text color="textLabel" fontSize="xl">
          We will send {ticker} to this address {roughlyEstimate[paymentMethod]}
        </Text>
        <Text color="textRegular" fontSize="xl">
          {walletAddress}
        </Text>
      </Box>
      {destinationTag && (
        <Box p="16px" borderTopWidth="1px" borderTopColor="gray.100">
          <Text color="textLabel" fontSize="xl">
            Destination tag
          </Text>
          <Text color="textRegular" fontSize="xl">
            {destinationTag}
          </Text>
        </Box>
      )}
      {props.fullData && txHash && (
        <Box p="16px" borderTopWidth="1px" borderTopColor="gray.100">
          <Text color="textLabel" fontSize="xl">
            Transaction hash
          </Text>
          <Text color="textRegular" fontSize="xl">
            {txHash}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default PaymentInfo;
