import ActionGuard from './lib/components/ActionGuard';
import ActionSheet from './lib/components/ActionSheet';
import {
  AlertsContainer,
  headerHeight,
  NotificationAlert,
  NotificationAlertData,
  NotificationAlertType,
  RegistrationCompleteAlert,
} from './lib/components/Alerts';
import { BottomPurpleButton } from './lib/components/Buttons/BottomPurpleButton';
import { Button } from './lib/components/Buttons/Button';
import { ButtonFaded } from './lib/components/Buttons/ButtonFaded';
import { ButtonPurple } from './lib/components/Buttons/ButtonPurple';
import { ButtonSecondary } from './lib/components/Buttons/ButtonSecondary';
import { ButtonUnderlinedText } from './lib/components/Buttons/ButtonUnderlinedText';
import CloseButton, { CloseButtonType } from './lib/components/Buttons/CloseButton';
import { GhostButton } from './lib/components/Buttons/GhostButton';
import { OutlinedPopUpButton } from './lib/components/Buttons/OutlinedPopUpButton';
import { TabButton } from './lib/components/Buttons/TabButton';
import CheckedIcon from './lib/components/CheckedIcon';
import ConfirmationModal from './lib/components/ConfirmationModal';
import CryptocurrencyInputsForm from './lib/components/CryptocurrencyInputsForm';
import {
  CryptoCurrencyInputType,
  ICryptocurrencyInputItem,
} from './lib/components/CryptocurrencyInputsForm/CryptocurrencyFormContent';
import { IInputCurrency } from './lib/components/CryptocurrencyInputsForm/Inputs/CurrencySelect';
import CurrencyNetwork from './lib/components/CurrencyNetwork';
import ErrorMessage, { ErrorMessageType, IValidate } from './lib/components/ErrorMessage';
import FormGuards, { IFormGuardComponentProps, IFormGuardType } from './lib/components/FormGuards';
import OtpFormGuard from './lib/components/FormGuards/OtpFormGuard';
import TotpFormGuard from './lib/components/FormGuards/TotpFormGuard';
import Icon, { IconProps } from './lib/components/Icon';
import { InfoList } from './lib/components/InfoList';
import Input from './lib/components/Input/Input';
import LabeledInput from './lib/components/Input/LabeledInput';
import LabeledMultilineInput from './lib/components/LabeledMultilineInput';
import LayoutBodyWrapper, { layoutBodyWrapperStyles } from './lib/components/layout/AppLayout/LayoutBodyWrapper';
import ModalBase from './lib/components/ModalBase';
import OverlaySpinner from './lib/components/OverlaySpinner';
import Pressable from './lib/components/Pressable';
import PressableCheckbox from './lib/components/PressableCheckbox';
import PreventFocusFirstElementOnModal from './lib/components/PreventFocusFirstElementOnModal';
import SecondsCountdown from './lib/components/SecondsCountdown';
import Select from './lib/components/Select';
import Spinner from './lib/components/Spinner';
import Switch from './lib/components/Switch';
import Tab from './lib/components/Tab';
import Tabs from './lib/components/Tabs';
import Toaster, { ToasterType } from './lib/components/Toaster';
import WalletAddressInput from './lib/components/WalletAddressInput';
import { AlertsProvider, useAlerts } from './lib/context/Alerts';
import { AuthProvider, useAuth, useUser, useUserSettings } from './lib/context/AuthContext';
import { CheckoutProvider, useCheckout } from './lib/context/CheckoutContext';
import { AppConfigProvider, useConfig } from './lib/context/ConfigContext';
import {
  PartnersSettingsProvider,
  usePartnerSettingsData,
  usePartnerStyles,
} from './lib/context/PartnersSettingsContext';
import { useWidget, WidgetProvider } from './lib/context/WidgetContext';
import BoldCircleAddIcon from './lib/icons/BoldCircleAddIcon';
import BoldCircleMinusIcon from './lib/icons/BoldCircleMinusIcon';
import { CardIcon } from './lib/icons/CardIcon';
import { CheckBoxIcon } from './lib/icons/CheckBoxIcon';
import { ChevronDownIcon } from './lib/icons/ChevronDownIcon';
import CircleAddIcon from './lib/icons/CircleAddIcon';
import { CrossErrorIcon } from './lib/icons/CrossErrorIcon';
import { SepaIcon } from './lib/icons/SepaIcon';
import * as theme from './lib/theme';

export {
  ActionGuard,
  ActionSheet,
  AlertsContainer,
  AlertsProvider,
  AppConfigProvider,
  AuthProvider,
  BoldCircleAddIcon,
  BoldCircleMinusIcon,
  BottomPurpleButton,
  Button,
  ButtonFaded,
  ButtonPurple,
  ButtonSecondary,
  ButtonUnderlinedText,
  CardIcon,
  CheckBoxIcon,
  CheckedIcon,
  CheckoutProvider,
  ChevronDownIcon,
  CircleAddIcon,
  CloseButton,
  CloseButtonType,
  ConfirmationModal,
  CrossErrorIcon,
  CryptocurrencyInputsForm,
  CryptoCurrencyInputType,
  CurrencyNetwork,
  ErrorMessage,
  ErrorMessageType,
  FormGuards,
  GhostButton,
  headerHeight,
  Icon,
  IFormGuardType,
  InfoList,
  Input,
  LabeledInput,
  LabeledMultilineInput,
  LayoutBodyWrapper,
  layoutBodyWrapperStyles,
  ModalBase,
  NotificationAlert,
  NotificationAlertType,
  OtpFormGuard,
  OutlinedPopUpButton,
  OverlaySpinner,
  PartnersSettingsProvider,
  Pressable,
  PressableCheckbox,
  PreventFocusFirstElementOnModal,
  RegistrationCompleteAlert,
  SecondsCountdown,
  Select,
  SepaIcon,
  Spinner,
  Switch,
  Tab,
  TabButton,
  Tabs,
  theme,
  Toaster,
  ToasterType,
  TotpFormGuard,
  useAlerts,
  useAuth,
  useCheckout,
  useConfig,
  usePartnerSettingsData,
  usePartnerStyles,
  useUser,
  useUserSettings,
  useWidget,
  WalletAddressInput,
  WidgetProvider,
};

export type {
  IconProps,
  ICryptocurrencyInputItem,
  IFormGuardComponentProps,
  IInputCurrency,
  IValidate,
  NotificationAlertData,
};
