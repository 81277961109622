import { Skeleton, Text, VStack } from 'native-base';
import React from 'react';

const DetailsBox = ({ title, value, isReady = true, containerProps = {}, textsProps = {} }) => (
  <VStack {...containerProps}>
    <Text fontSize="lg" color="textLabel" {...textsProps}>
      {title}
    </Text>
    <Skeleton isLoaded={isReady} h="24px">
      <Text fontSize="lg" color="textRegular" {...textsProps}>
        {value}
      </Text>
    </Skeleton>
  </VStack>
);

export default DetailsBox;
