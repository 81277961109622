import { HStack } from 'native-base';
import React from 'react';
import { Outlet, Route, useNavigate } from 'react-router-dom';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { TableType } from '@cryptowallet/web/components/Table';

import Exchange from './components/Exchange';
import RecentActivity from './components/RecentActivity';
import RecentActivityMobileBtn from './components/RecentActivity/components/RecentActivityMobileBtn';
import useDashboard from './components/RecentActivity/hooks/useDashboard';

const Widgets = () => {
  const navigate = useNavigate();
  const { isDesktop } = useScreenSize();
  const { showOpenRecentActivityMobileBtn, exchangeType, setExchangeType } = useDashboard();

  const onOpenRecentActivity = () => navigate('/dashboard/recent-activity');

  return (
    <>
      {showOpenRecentActivityMobileBtn && <RecentActivityMobileBtn onPress={onOpenRecentActivity} />}
      <Exchange exchangeType={exchangeType} setExchangeType={setExchangeType} />
      {isDesktop && (
        <RecentActivity
          onOpenRecentActivity={onOpenRecentActivity}
          setExchangeType={setExchangeType as never}
          tableType={TableType.SMALL}
          isWidget={true}
        />
      )}
    </>
  );
};

const RecentActivityPage = () => {
  const { setExchangeType } = useDashboard();
  const { isBiggerThanTablet } = useScreenSize();

  return (
    <RecentActivity
      setExchangeType={setExchangeType as never}
      tableType={isBiggerThanTablet ? TableType.FULL : TableType.SMALL}
      isWidget={false}
    />
  );
};

export const renderRoutes = () => (
  <>
    <Route path="widgets" element={<Widgets />} />
    <Route path="recent-activity" element={<RecentActivityPage />} />
  </>
);

const Dashboard = () => {
  const { showOpenRecentActivityMobileBtn } = useDashboard();

  return (
    <HStack
      position="relative"
      maxW="100vw"
      overflow="hidden"
      flexWrap="wrap"
      pt={showOpenRecentActivityMobileBtn ? '70px' : '0'}>
      <Outlet />
    </HStack>
  );
};

export default Dashboard;
