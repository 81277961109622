import './style.css';

import { Box, HStack, Text } from 'native-base';
import React from 'react';

import Icon from '../Icon';
import Pressable from '../Pressable';
import ProgressCircle from '../ProgressCircle';

export enum ToasterType {
  SUCCESS,
  WARNING,
  ERROR,
}

interface IToasterProps {
  type: ToasterType;
  title: string;
  description?: string;
  closeToast?: () => void;
  autoClose?: number | false;
}

const toasterData = {
  [ToasterType.WARNING]: {
    icon: {
      name: 'alerts/alert-warning--orange',
    },
  },
  [ToasterType.SUCCESS]: {
    icon: {
      name: 'alerts/alert-success--green',
    },
  },
  [ToasterType.ERROR]: {
    icon: {
      name: 'alerts/alert-error--red',
    },
  },
};

const Toaster = ({ type, title, description, closeToast, autoClose }: IToasterProps) => {
  const { icon } = toasterData[type];

  return (
    <Box
      position="relative"
      p="16px"
      flexGrow="1"
      bg="background.400"
      borderColor="textRegular:alpha.10"
      borderWidth="1px"
      borderRadius="10px"
      overflow="hidden">
      <HStack pl="24px" pr="28px">
        <Icon {...icon} position="absolute" top="2px" left="0" h="16px" w="16px" />
        <Box w="100%">
          <Text fontSize="md" color="white" userSelect="none">
            {title}
          </Text>
          <Text fontSize="sm" color="white" flexWrap="wrap" w="100%" pr="66px" userSelect="none">
            {description}
          </Text>
        </Box>
      </HStack>
      <Box position="absolute" top="14px" right="14px">
        <Pressable position="relative" onPress={closeToast}>
          <ProgressCircle timer={autoClose as number} />
          <Icon position="absolute" top="8px" left="8px" name="cross-gray" h="8px" w="8px" />
        </Pressable>
      </Box>
    </Box>
  );
};

export default Toaster;
