import { Box, Text } from 'native-base';

import { useTotpSecret } from '@cryptowallet/frontend/hooks';
import { BottomPurpleButton } from '@cryptowallet/frontend/ui';
import CopyBox, { CopyBoxVariant } from '@cryptowallet/web/components/CopyBox';

import { StepComponentProps } from './IStepProps';
import StepLayout from './Layout';

const BackUpWarning = ({ onStepCompleted }: StepComponentProps) => {
  const { secret } = useTotpSecret();
  return (
    <StepLayout
      body={
        <Box>
          <Text fontSize="lg">Please save this Key on paper.</Text>
          <Text fontSize="lg"> </Text>
          <Text fontSize="lg">This Key will allow you to recover your Google Authenticator in case of phone loss.</Text>
          <Text fontSize="lg"> </Text>
          <Text fontSize="lg">
            Resetting your Google Authentication requires opening a support ticket and takes at least 7 days to process.
          </Text>
          <Box mt="40px">
            <CopyBox
              title={
                <Text color="textLabel" fontSize="lg">
                  Code
                </Text>
              }
              value={secret}
              variant={CopyBoxVariant.GRAYBOX}
              valueStyles={{ fontSize: 'lg' }}
            />
          </Box>
        </Box>
      }
      stepButton={
        <BottomPurpleButton size="lg" onPress={onStepCompleted}>
          Next
        </BottomPurpleButton>
      }
    />
  );
};

export default BackUpWarning;
