/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { tokenService, userApi } from '@cryptowallet/frontend/api';
import {
  ExchangerCreateExchangeRequestDtoPspTypeEnum,
  GetUserResponseUsersDto,
  GetUserUserSettingsEntityDto,
} from '@cryptowallet/frontend/client';
import { useGA } from '@cryptowallet/frontend/ga';
import { cacheRequestOption, deleteCookie, setCookie } from '@cryptowallet/frontend/utils';

import OverlaySpinner from '../../components/OverlaySpinner';

interface IAuthProvider {
  user: GetUserResponseUsersDto;
  setTokens: ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => void;
  logout: () => void;
  isFetching: boolean;
  queryKey: any[];
  clearTokens: () => void;
}

const UserContext = createContext<IAuthProvider | null>(null);

export const AuthProvider = ({ children }) => {
  const ga = useGA();
  const [accessToken, setAccessToken] = useState(tokenService.getToken() || '');
  const [, setRefreshToken] = useState(tokenService.getRefreshToken());

  const { mutateAsync: updateGaSessionId } = useMutation((gaSessionId: string) =>
    userApi.usersControllerGaSessionId({ gaSessionId }),
  );

  const setTokens = useCallback(
    (tokens: { accessToken: string; refreshToken: string }) => {
      // due to react18 state updates batching we may store and set tokens separately
      tokenService.setTokens(tokens.accessToken, tokens.refreshToken);
      setAccessToken(tokens.accessToken);
      setRefreshToken(tokens.refreshToken);
    },
    [setAccessToken, setRefreshToken],
  );

  const clearTokens = useCallback(async () => {
    tokenService.removeTokens();
    setAccessToken('');
    setRefreshToken('');
  }, [setAccessToken, setRefreshToken]);

  const logout = useCallback(async () => {
    const token = tokenService.getToken();
    deleteCookie('user_id');
    clearTokens();

    userApi
      .usersControllerLogout({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(() => console.error('Could not logout session'));
  }, [clearTokens]);

  const queryKey = ['user', accessToken];
  const enabled = !!accessToken;
  const {
    isLoading,
    isFetching,
    data: user,
  } = useQuery(
    queryKey,
    async () => {
      if (!accessToken) {
        return null;
      }

      const { data } = await userApi.usersControllerGetUser();

      return data;
    },
    {
      enabled,
      ...cacheRequestOption,
    },
  );

  const value = useMemo(
    () => ({
      user,
      setTokens,
      logout,
      isFetching,
      queryKey,
      clearTokens,
    }),
    [user, setTokens, logout, isFetching, clearTokens],
  );

  useEffect(() => {
    if (user) {
      setCookie('user_id', user.user.id);
    }
  }, [user, accessToken]);

  useEffect(() => {
    let lastGaSessionId: string;
    let interval: string;
    let failTries = 0;
    if (user) {
      // @ts-ignore
      interval = setInterval(async () => {
        const gaSessionId = await ga.getSessionId();

        if (gaSessionId && gaSessionId !== lastGaSessionId) {
          lastGaSessionId = gaSessionId;
          await updateGaSessionId(gaSessionId);
        } else if (!gaSessionId) {
          failTries += 1;
          if (failTries >= 4) {
            clearInterval(interval);
          }
        }
      }, 1000);
    }
    // @ts-ignore
    if (!user && interval) {
      // @ts-ignore
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [user]);

  return (
    <UserContext.Provider value={value}>{isLoading && enabled ? <OverlaySpinner /> : children}</UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);

const paymentMethods = [
  ExchangerCreateExchangeRequestDtoPspTypeEnum.Card,
  ExchangerCreateExchangeRequestDtoPspTypeEnum.Sepa,
];

const settingsPlaceholder: GetUserUserSettingsEntityDto = {
  allowedPaymentMethods: paymentMethods,
  hiddenWalletAssets: [],
  hideBalance: false,
};

const authPlaceholder = { user: { settings: settingsPlaceholder } };

export const useUser = (): GetUserResponseUsersDto | null => {
  const { user } = (useAuth() || authPlaceholder) as never;

  return user;
};

export const useUserSettings = (): GetUserUserSettingsEntityDto => {
  const { settings } = (useUser() as never) || { settings: settingsPlaceholder };

  return settings;
};
