import { matchRoutes, useLocation } from 'react-router-dom';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import {
  getBuyFlowQueryFields,
  getSellFlowQueryFields,
  useBuyFlowStore,
  useSellFlowStore,
} from '@cryptowallet/frontend/stores';
import useSearchParamsState from '@cryptowallet/web/hooks/useSearchParamsState';

const useDashboard = () => {
  const { isDesktop } = useScreenSize();
  const location = useLocation();
  const isWidgetPage = Boolean(matchRoutes([{ path: '/dashboard/widgets' }], location));
  const buyFlowStepIndex: number = useBuyFlowStore(state => state.stepIndex);
  const sellFlowStepIndex: number = useSellFlowStore(state => state.stepIndex);
  const [exchangeType, setExchangeType] = useSearchParamsState(
    'exchangeType',
    commonConstants.ExchangeTypeEnum.Buy,
    [commonConstants.ExchangeTypeEnum.Buy, commonConstants.ExchangeTypeEnum.Sell],
    [...getBuyFlowQueryFields(), ...getSellFlowQueryFields()],
  );

  const activeFlowStepIndex =
    exchangeType === commonConstants.ExchangeTypeEnum.Sell ? sellFlowStepIndex : buyFlowStepIndex;
  const showOpenRecentActivityMobileBtn = isWidgetPage && !isDesktop && activeFlowStepIndex === 0;

  return {
    activeFlowStepIndex,
    showOpenRecentActivityMobileBtn,
    exchangeType,
    setExchangeType,
  };
};

export default useDashboard;
