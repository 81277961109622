import { Box, Center, Spinner } from 'native-base';
import React from 'react';

import { usePartnerStyles } from '../context/PartnersSettingsContext';

const OverlaySpinner = ({ ...props }) => {
  const partnerStyles = usePartnerStyles('OverlaySpinner');

  return (
    <Box
      justifyContent="center"
      zIndex="100"
      display="flex"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      {...props}>
      <Center height="100%">
        <Spinner accessibilityLabel="Loading" color="secondary.400" {...partnerStyles} />
      </Center>
    </Box>
  );
};

export default OverlaySpinner;
