import './index.css';

import React from 'react';
import SlickSlider from 'react-slick';

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  variableWidth: true,
  adaptiveHeight: true,
  customPaging: () => <div className="slider-dot"></div>,
};

export const SlideBase = ({ children, width = 316, height = 160, padding = '8px' }) => (
  <div style={{ width }}>
    <div style={{ width: '100%', height, padding }}>{children}</div>
  </div>
);

const Slider = ({ children, settings = defaultSettings }) => <SlickSlider {...settings}>{children}</SlickSlider>;

export default Slider;
