import { useMutation } from '@tanstack/react-query';
import { Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ClientErrors } from '@cryptowallet/frontend/client-errors';
import { useChangeEmailStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, FormGuards, IFormGuardType, useAlerts, useUser } from '@cryptowallet/frontend/ui';
import { maskEmail, processFormGuardsApiErrors } from '@cryptowallet/frontend/utils';
import { ChangeEmailBadRequestResponseUsersDtoErrorEnum, userApi } from '@cryptowallet/web/api-client';
import TabContentLayout from '@cryptowallet/web/components/layout/TabContentLayout';

interface IFormProps {
  totpToken: string;
  emailOtpToken: string;
}

interface IToggleTotpProps {
  onStepCompleted: () => void;
  setStepIndex: (stepIndex: number) => void;
}

const ConfirmNewEmail = ({ onStepCompleted, setStepIndex }: IToggleTotpProps) => {
  const { user } = useUser();
  const { newEmail, oldEmailOtpToken, setVerifyCurrentEmailIsInvalid } = useChangeEmailStore(
    state => ({
      newEmail: state.newEmail,
      oldEmailOtpToken: state.oldEmailOtpToken,
      setVerifyCurrentEmailIsInvalid: state.setVerifyCurrentEmailIsInvalid,
    }),
    shallow,
  );
  const [formError, setFormError] = useState(null);
  const { showErrorToaster } = useAlerts();

  const { control, handleSubmit, setError } = useForm<IFormProps>({
    defaultValues: {
      totpToken: '',
      emailOtpToken: '',
    },
  });

  const changeEmailMutation = useMutation(({ totpToken, emailOtpToken }: IFormProps) =>
    userApi.usersControllerChangeEmail({
      totpToken,
      emailOtpToken: oldEmailOtpToken,
      newEmailOtpToken: emailOtpToken,
      newEmail,
    }),
  );

  const verify = async (formData: IFormProps) => {
    try {
      await changeEmailMutation.mutateAsync(formData);

      onStepCompleted();
    } catch (error) {
      switch (error?.response?.data?.error) {
        case 'Invalid email otp': {
          setStepIndex(0);
          setVerifyCurrentEmailIsInvalid(true);
          return;
        }
        case ChangeEmailBadRequestResponseUsersDtoErrorEnum.NewEmailOtpInvalid: {
          setError('emailOtpToken', {
            type: 'custom',
            message: ClientErrors.invalidOtp.title,
          });
          return;
        }
        case ChangeEmailBadRequestResponseUsersDtoErrorEnum.EmailIsAlreadyTaken: {
          setFormError(`Email ${newEmail} is already taken`);
          break;
        }
      }
      processFormGuardsApiErrors(error, setError, showErrorToaster);
    }
  };

  const { totpEnabled } = user;

  return (
    <>
      <TabContentLayout>
        <Text mb="26px" fontSize="lg">
          We send code to your new email: {maskEmail(newEmail)}
        </Text>
        {formError && (
          <Text mb="26px" color="error.400" fontSize="lg">
            {formError}
          </Text>
        )}
        <FormGuards
          activeGuards={[{ type: IFormGuardType.OTP, requestCodeOnMount: false }, { type: IFormGuardType.TOTP }]}
          control={control}
          onSubmitInput={handleSubmit(verify)}
        />
      </TabContentLayout>
      <BottomPurpleButton isDisable={changeEmailMutation.isLoading} size="lg" onPress={handleSubmit(verify)}>
        {totpEnabled && 'Confirm'}
        {!totpEnabled && 'Submit'}
      </BottomPurpleButton>
    </>
  );
};

export default ConfirmNewEmail;
