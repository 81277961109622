import omit from 'lodash/omit';
import { Box, Popover, Text, useDisclose } from 'native-base';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetUserResponseUsersDtoUserRiskLevelTypeEnum, RiskCheckType } from '@cryptowallet/frontend/client';
import { RiskCheckStatus, useUserStatus } from '@cryptowallet/frontend/hooks';

import { useUser } from '../../../index';
import { ButtonPurple } from '../Buttons/ButtonPurple';
import CloseButton from '../Buttons/CloseButton';

const riskCheckPaths = {
  [RiskCheckType.ProofOfWealthCheck]: '/account/security/verification-pow',
};

const ActionGuard = ({ children }: { children: (params: { openAlert?: () => void }) => JSX.Element }): JSX.Element => {
  const { kycPoaHighRiskVerified, riskChecks } = useUserStatus();
  const user = useUser();
  const { isOpen, onClose, onOpen } = useDisclose();
  const navigate = useNavigate();

  const issueData = useMemo(() => {
    if (!kycPoaHighRiskVerified) {
      return {
        actionPath: '/account/security/verification',
        actionTitle: 'Verify',
        message: 'Your account is not yet verified. Verify your account below to gain access to all wallet features!',
      };
    }

    const checkRequired = user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Suspended;

    const [checkIdToPass] =
      Object.entries(riskChecks).find(([_, value]) =>
        [RiskCheckStatus.PENDING, RiskCheckStatus.RETRY].includes(value.status as never),
      ) || [];

    if (checkRequired) {
      const actionPath = riskCheckPaths[checkIdToPass as never] || '/account/security';

      return checkIdToPass
        ? {
            actionPath,
            actionTitle: 'Start check',
            message:
              'Your account was suspended. Please proceed with required checks in the Security Center to unsuspend your account.',
          }
        : {
            message:
              'Your account was suspended. Please proceed with required checks in the Security Center to unsuspend your account.', // case when account is still suspended but under review now
          };
    }

    return null;
  }, [riskChecks, kycPoaHighRiskVerified]);

  if (!issueData) return children({});

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      trigger={triggerProps => (
        <Box {...omit(triggerProps, ['onPress'])}>
          {children({
            openAlert: onOpen,
          })}
        </Box>
      )}>
      <Popover.Content borderColor="gray.100" bg="gray.500" w="250px">
        <Popover.Arrow borderColor="gray.100" bg="gray.500" />
        <CloseButton
          h="14px"
          w="14px"
          position="absolute"
          top="13px"
          right="13px"
          iconProps={{ name: 'close-button--gray' }}
          zIndex="1"
          onPress={onClose}
        />
        <Popover.Body p="13px" pr="40px">
          <Text fontSize="md" color="white">
            {issueData.message}
          </Text>
        </Popover.Body>
        <Popover.Footer bg="gray.500" p="13px" pt="7px">
          {issueData.actionPath && issueData.actionTitle && (
            <ButtonPurple onPress={() => navigate(issueData.actionPath)} h="19px" w="100%">
              {issueData.actionTitle}
            </ButtonPurple>
          )}
        </Popover.Footer>
      </Popover.Content>
    </Popover>
  );
};

export default ActionGuard;
