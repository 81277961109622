const maskPart = (part: string) => {
  const { length } = part;
  if (length > 8) {
    return part.substr(0, 2) + part.substr(2, length - 2).replace(/\w/g, '*') + part.substr(-2, 2);
  }
  if (length > 4) {
    return part.substr(0, 1) + part.substr(1, length - 1).replace(/\w/g, '*') + part.substr(-1, 1);
  }
  return part.substr(0, 1) + part.substr(1, length - 1).replace(/\w/g, '*');
};

export const maskEmail = (emailAddress: string) =>
  emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, (_m, p1, p2, p3) => `${maskPart(p1)}@${maskPart(p2)}${p3}`);
