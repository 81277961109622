import React, { createContext, useContext } from 'react';

import { GAFacade } from './gaFacade';
import { GaEvents } from './gaFacade.types';

const GAContext = createContext<GAFacade<GaEvents> | undefined>(undefined);

export const GAProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  // GAFacade resolve (between GAFacadeWeb and GAFacadeNative)
  // based on the platform configs: metro.config.js or webpack.config.js
  const gaFacade = new GAFacade<GaEvents>();

  return <GAContext.Provider value={gaFacade}>{children}</GAContext.Provider>;
};

export const useGA = (): GAFacade<GaEvents> => {
  const gaFacade = useContext(GAContext);
  if (!gaFacade) {
    throw new Error('useGA must be used within a GAProvider');
  }
  return gaFacade;
};
