/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { useMemo } from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { ExchangerCreateExchangeRequestDtoPspTypeEnum } from '@cryptowallet/frontend/client';
import { buyEstimatePlaceholder, useBuyFlowStore } from '@cryptowallet/frontend/stores';

// copy-pasted from exchange.aggregate.ts. We will not need this when api client will be patched
export enum ExchangeStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  DONE = 'DONE',
  BLOCKED = 'BLOCKED',
  CANCELED = 'CANCELED',
}

export enum PaymentStatus {
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  DONE = 'DONE',
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
}

export enum ExchangeResult {
  EXCHANGING = 'exchanging',
  SENDING_TO_PAYOUT = 'sending to payout',
  ERROR = 'error',
  SUCCESS = 'success',
  HOLD = 'hold',
  CREATED = 'created',
}

const failExchangeStatuses = [ExchangeStatus.CANCELED];

export const buyExchangeStatusesToComplete = [...failExchangeStatuses, ExchangeStatus.DONE];

export const buyPayInStatusesToComplete = [
  PaymentStatus.COMPLETED,
  PaymentStatus.DONE,
  PaymentStatus.ACCEPTED,
  PaymentStatus.PENDING,
  PaymentStatus.DECLINED,
];

const useBuyExchange = () => {
  const [exchangeId, paymentMethod, reset] = useBuyFlowStore(
    state => [state.exchangeId, state.paymentMethod, state.reset],
    shallow,
  );

  const {
    isLoading,
    data: exchangeResponse,
    ...rest
  } = useQuery(
    ['exchange', exchangeId],
    async () => {
      try {
        const result = await exchangerApi.exchangerControllerGetExchangeById(exchangeId);

        return result;
      } catch (err) {
        if (err?.response?.status === 404) {
          reset();

          return;
        }

        throw err;
      }
    },
    {
      refetchInterval: exchangeResponse => {
        const exchangeStatus: ExchangeStatus = exchangeResponse?.data?.status as ExchangeStatus;
        const completed = buyExchangeStatusesToComplete.includes(exchangeStatus as ExchangeStatus);
        return completed ? false : 5000;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!exchangeId,
      retry: (_failureCount: number) => {
        if (_failureCount > 2) {
          reset();
        }

        return true;
      },
    },
  );

  const exchangeStatus = exchangeResponse?.data.status;
  const payInDetails: any = exchangeResponse?.data?.payIn;
  const userPaymentSent = Boolean(payInDetails?.userPaymentSent);
  const paymentStatus = payInDetails?.status;
  const estimateResult = exchangeResponse?.data.estimate || buyEstimatePlaceholder[paymentMethod];
  const completed = buyExchangeStatusesToComplete.includes(exchangeStatus as ExchangeStatus);

  const result = useMemo<string>(() => {
    if (failExchangeStatuses.includes(exchangeStatus as ExchangeStatus)) {
      return ExchangeResult.ERROR;
    }
    if (exchangeStatus === ExchangeStatus.CREATED) {
      return ExchangeResult.CREATED;
    }
    if (exchangeStatus === ExchangeStatus.DONE) {
      return ExchangeResult.SUCCESS;
    }
    if (exchangeStatus === ExchangeStatus.BLOCKED) {
      return ExchangeResult.HOLD;
    }
    if (buyPayInStatusesToComplete.includes(paymentStatus)) {
      return ExchangeResult.SENDING_TO_PAYOUT;
    }

    return ExchangeResult.EXCHANGING;
  }, [exchangeStatus, paymentStatus]);

  return {
    isLoading,
    data: exchangeResponse,
    estimateResult,
    completed,
    payInCompleted: [...buyPayInStatusesToComplete, PaymentStatus.BLOCKED].includes(paymentStatus),
    userPaymentSent,
    result,
    ...rest,
  };
};

export default useBuyExchange;
