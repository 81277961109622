import { useQueryClient } from '@tanstack/react-query';
import { Box, IBoxProps, Text } from 'native-base';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EventRequestDtoEventNameEnum } from '@cryptowallet/frontend/client';
import { useGA } from '@cryptowallet/frontend/ga';
import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { ConfirmationModal, OverlaySpinner, useAlerts, usePartnerStyles } from '@cryptowallet/frontend/ui';
import { PrimaryButton } from '@cryptowallet/web/components/Buttons';
import FormHeading from '@cryptowallet/web/pages/Home/components/FormHeading';

import AuthHeader from '../../AuthHeader';

export interface AuthenticationProps {
  header: string;
  permissionType: 'KYC' | 'POW' | 'POA';
  onCancel: () => void;
  initIframe: (
    permissionType: string,
    accessToken: string,
    { onSuccess }: { onSuccess: () => void },
    showToaster: () => void,
  ) => void;
  getNewAccessToken: (permissionType, showToaster: () => void) => Promise<string>;
  onComplete?: () => void;
  containerProps?: IBoxProps;
  onPressBack?: () => void;
  customerSource?: 'btb' | 'btc';
}

// NOTE: Rename to "Verification?"
const Authentication = ({
  header,
  permissionType,
  onCancel,
  getNewAccessToken,
  initIframe,
  onComplete = () => {},
  containerProps = {},
  onPressBack,
  customerSource = 'btc',
}: AuthenticationProps) => {
  const partnerStyles = usePartnerStyles('Authentication');
  const navigate = useNavigate();
  const { kycPoaHighRiskVerified } = useUserStatus();
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState<boolean>(false);
  const [authenticationPassed, setAuthenticationPassed] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { showErrorToaster } = useAlerts();
  const ga = useGA();

  const handelCancel = () => {
    setShowCancelConfirmationModal(false);
    const levelNameToGaEvent: Record<AuthenticationProps['permissionType'], EventRequestDtoEventNameEnum> = {
      KYC: EventRequestDtoEventNameEnum.KycCancelButtonSuccess,
      POA: EventRequestDtoEventNameEnum.PoaCancelButtonSuccess,
      POW: EventRequestDtoEventNameEnum.PowCancelButtonSuccess,
    };

    ga.sendEvent(levelNameToGaEvent[permissionType], {
      customer_source: customerSource,
    });
    onCancel();
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getNewAccessToken(permissionType, showErrorToaster);

      initIframe(
        permissionType,
        accessToken,
        {
          onSuccess: () => setAuthenticationPassed(true),
        },
        showErrorToaster,
      );
    })();
  }, []);

  useEffect(() => {
    let intervalId;
    if (authenticationPassed) {
      // We don't know when SumSub webhook reach the server and user info is updated.
      // So we are triggering user refetching until this component is unmounted
      intervalId = setInterval(() => queryClient.invalidateQueries(['user']), 2000);
    }

    return () => {
      clearInterval(intervalId);
      if (authenticationPassed && kycPoaHighRiskVerified) {
        onComplete();
      }
    };
  }, [authenticationPassed, kycPoaHighRiskVerified]);

  return (
    <Box
      borderRadius="10px"
      bg="background.400"
      flexGrow="1"
      maxW="100%"
      w="100%"
      {...containerProps}
      {...partnerStyles}>
      <AuthHeader py="30px" mb="0" w="100%">
        <FormHeading
          onPressBack={onPressBack || (() => navigate('/account/security'))}
          text={header}
          display="flex"
          justifyContent="center"
        />
      </AuthHeader>
      <Box px="19px" maxW="full" flexGrow="1">
        {authenticationPassed && <OverlaySpinner />}
        <div style={{ width: '100%', maxWidth: '100%', height: '100%' }} id="sumsub-websdk-container"></div>
        {showCancelConfirmationModal && (
          <ConfirmationModal
            cancelText={`Cancel ${permissionType}`}
            confirmText={`Back to ${permissionType}`}
            onCancel={handelCancel}
            onConfirm={() => setShowCancelConfirmationModal(false)}>
            <Text fontSize="md" color="white" textAlign="center">
              If you cancel the {permissionType} procedure, you won&#39;t be able to use CryptoWallet services.
            </Text>
          </ConfirmationModal>
        )}
      </Box>
      {!authenticationPassed && (
        <PrimaryButton size="lg" h="80px" w="100%" onPress={() => setShowCancelConfirmationModal(true)}>
          Refuse to pass
        </PrimaryButton>
      )}
    </Box>
  );
};

export default Authentication;
