import React from 'react';

const NavigationBuySellIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.5" stroke={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM9.54048 9.53999C9.79432 9.28615 10.2059 9.28615 10.4597 9.53999C10.7136 9.79383 10.7136 10.2054 10.4597 10.4592L8.91934 11.9996L10.4597 13.54C10.7136 13.7938 10.7136 14.2054 10.4597 14.4592C10.2059 14.7131 9.79432 14.7131 9.54048 14.4592L7.54048 12.4592C7.28664 12.2054 7.28664 11.7938 7.54048 11.54L9.54048 9.53999ZM14.4597 9.53999C14.2059 9.28615 13.7943 9.28615 13.5405 9.53999C13.2866 9.79383 13.2866 10.2054 13.5405 10.4592L15.0809 11.9996L13.5405 13.54C13.2866 13.7938 13.2866 14.2054 13.5405 14.4592C13.7943 14.7131 14.2059 14.7131 14.4597 14.4592L16.4597 12.4592C16.7136 12.2054 16.7136 11.7938 16.4597 11.54L14.4597 9.53999Z"
      fill={color}
    />
  </svg>
);

export default NavigationBuySellIcon;
