import { useQuery } from '@tanstack/react-query';

import { walletsApi } from '@cryptowallet/frontend/api';
import {
  EstimateSwapTransactionBadRequestDtoErrorEnum,
  EstimateSwapTransactionResponseWalletsDto,
} from '@cryptowallet/frontend/client';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { cacheRequestOption, defaultFailureCount } from '@cryptowallet/frontend/utils';

import useDebounce from './useDebounce';
import useSelectedWalletAccount from './useSelectedWalletAccount';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

const estimatePlaceholder = { networkFee: '-', equivalentAmount: '-', rate: '-' };

export const useSwapEstimate = () => {
  const swapData = useAccountsStore(state => state.swapData);
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const [depositTooSmall, setDepositTooSmall] = useState(false);
  const [pairIsInactive, setPairIsInactive] = useState(false);

  const { amount, toSelectedWalletAssetId } = swapData;

  const debouncedAmount = useDebounce(amount, 1000);

  const { data, isFetching } = useQuery<EstimateSwapTransactionResponseWalletsDto | null>(
    ['swap-estimate', selectedWalletAccount?.walletAsset?.id, debouncedAmount, toSelectedWalletAssetId],
    async (): Promise<EstimateSwapTransactionResponseWalletsDto | null> => {
      if (+amount === 0) {
        return null;
      }

      const response = await walletsApi.walletsControllerEstimateSwapTransaction({
        fromWalletAssetId: selectedWalletAccount?.walletAsset?.id,
        toWalletAssetId: toSelectedWalletAssetId,
        amount,
      });

      return response.data;
    },
    {
      ...cacheRequestOption,
      retry: (_failureCount: number, error: AxiosError) => {
        if (error?.response?.data?.error === EstimateSwapTransactionBadRequestDtoErrorEnum.DepositTooSmall) {
          setDepositTooSmall(true);

          return false;
        }

        if (error?.response?.data?.error === EstimateSwapTransactionBadRequestDtoErrorEnum.PairIsInactive) {
          setPairIsInactive(true);

          return false;
        }
        if (_failureCount > defaultFailureCount) {
          return false;
        }

        return true;
      },
    },
  );

  useEffect(() => {
    setDepositTooSmall(false);
  }, [selectedWalletAccount?.walletAsset?.id, debouncedAmount, toSelectedWalletAssetId]);

  useEffect(() => {
    setPairIsInactive(false);
  }, [selectedWalletAccount?.walletAsset?.id, toSelectedWalletAssetId]);

  const {
    networkFee,
    equivalentAmount,
    rate,
    minAmount,
    maxAmount,
    isBalanceEnough,
    walletBalanceInsufficientionReason,
    feeAsset,
  } = (data || estimatePlaceholder) as EstimateSwapTransactionResponseWalletsDto;

  return {
    isFetching,
    networkFee,
    equivalentAmount,
    rate,
    feeAsset,
    pairIsInactive,
    validationValues: {
      minAmount,
      maxAmount,
      isBalanceEnough,
      walletBalanceInsufficientionReason,
      depositTooSmall,
    },
  };
};
