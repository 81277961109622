import { Text } from 'native-base';
import React from 'react';

import { getPlatform } from '@cryptowallet/frontend/utils';

import { Button, usePartnerStyles } from '../../index';

const isWeb = getPlatform() === 'web';
const getTextColor = (isActive: boolean | undefined) => {
  if (isWeb) {
    return 'inherit';
  }
  if (isActive) {
    return 'white';
  }
  return 'textRegular';
};

interface ITabProps {
  title: string;
  isActive?: boolean;
  onPress: () => void;
}
const Tab = ({ title, isActive, onPress }: ITabProps): JSX.Element => {
  const partnerStyles = usePartnerStyles('Tab');
  const textColor = getTextColor(isActive);

  return (
    <Button
      padding={!isWeb ? '0' : undefined}
      onPress={onPress}
      variant="unstyled"
      outlineWidth="0"
      flexGrow="1"
      height="36px"
      borderRadius="8px"
      borderColor="transparent"
      borderWidth="1px"
      color={isActive ? 'white' : 'textRegular'}
      _hover={{
        color: 'white',
      }}
      {...(partnerStyles.bg
        ? {
            background: isActive ? partnerStyles.bg.active : partnerStyles.bg.normal,
            // eslint-disable-next-line
            _focus: partnerStyles._focus,
          }
        : {
            background: isActive ? 'primary.400' : 'transparent',
            _focus: {
              borderColor: 'secondary.400',
            },
          })}>
      <Text fontFamily="body" fontWeight="500" fontSize={isWeb ? 'md' : 'lg'} color={textColor}>
        {title}
      </Text>
    </Button>
  );
};

Tab.defaultProps = {
  isActive: false,
};

export default Tab;
