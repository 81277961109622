import { createContext, useContext } from 'react';

interface ICheckoutContext {
  isCheckout: boolean;
}

const CheckoutContext = createContext<ICheckoutContext>({
  isCheckout: false,
});

export const CheckoutProvider = ({ children }) => (
  <CheckoutContext.Provider value={{ isCheckout: true }}>{children}</CheckoutContext.Provider>
);

export const useCheckout = () => useContext(CheckoutContext);
