import { ClientError } from './client-error.interface';

export const GuardsErrors: ClientError = {
  invalidOtp: {
    title: 'Incorrect OTP email code. Please try again',
  },
  invalidTotp: {
    title: 'Incorrect 2FA code. Please try again',
  },
};
