import { Flex } from 'native-base';

import { useScreenSize } from '@cryptowallet/frontend/hooks';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import ToggleTotp from '../ToggleTotp';

import BackUpWarning from './BackUpWarning';
import DownloadApp from './DownloadApp';
import QRScan from './QRScan';

const steps: IStep[] = [
  {
    label: 'Download App',
    Component: DownloadApp,
  },
  {
    label: 'Scan QR Code',
    Component: QRScan,
  },
  {
    label: 'Backup Key',
    Component: BackUpWarning,
  },
  {
    label: 'Enabled Authenticator',
    Component: ToggleTotp,
  },
];

interface ISetupTotpProps {
  onFlowCompleted: () => void;
  totpWasDisabled: boolean;
  openMethodsList: () => void;
}

const SetupTotp = ({ onFlowCompleted, totpWasDisabled, openMethodsList }: ISetupTotpProps) => {
  const { isDesktop } = useScreenSize();

  return (
    <Flex flexGrow="1">
      <Stepper
        header="Setup 2FA"
        steps={steps}
        onFlowComplete={onFlowCompleted}
        initialStep={totpWasDisabled ? 3 : 0}
        canGoToPrevStep={!totpWasDisabled}
        onClose={isDesktop ? null : openMethodsList}
      />
    </Flex>
  );
};

export default SetupTotp;
