import { networksData } from '../constants/src/networksData';
import { FIAT_TICKERS_TO_SYMBOLS } from '../constants/src/common';

export const copyToClipboard = (value: string): void => {
  navigator.clipboard.writeText(value);
};

export const getCurrencySymbol = (ticker = ''): string => FIAT_TICKERS_TO_SYMBOLS[ticker] ?? ticker.toUpperCase();

const maxCryptoAmountLength = 13;
export const trimCryptoAmountLength = (amount: string | number, maxDecimalLength = 8): string => {
  if (typeof amount === 'number') {
    // eslint-disable-next-line no-param-reassign
    amount = amount.toString();
  }
  if (!amount) {
    return amount;
  }
  const integerPartLength = amount.indexOf('.');
  if (integerPartLength === -1) {
    // if there is no decimal part, return as is
    return amount;
  }

  // magic number "1" here is for the '.'
  const numberOfDecimalPlaces = Math.min(maxCryptoAmountLength - integerPartLength - 1, maxDecimalLength);
  return amount.substring(0, integerPartLength + 1 + numberOfDecimalPlaces);
};

export const maskNumber = decimals => new Array(decimals).fill('*').join('');

export const getNetworkData = networkTicker => networksData[networkTicker] || { color: '#62688F' };
