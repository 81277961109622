import React from 'react';

const SwapIcon = ({ color }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04996 0.248568C7.46497 0.612963 7.506 1.2448 7.14161 1.65981L4.20879 5.00001H15C15.5523 5.00001 16 5.44773 16 6.00001C16 6.5523 15.5523 7.00001 15 7.00001H1.99999C1.6073 7.00001 1.25096 6.77018 1.089 6.41245C0.927041 6.05471 0.989447 5.6353 1.24854 5.34022L5.63872 0.340218C6.00311 -0.0747942 6.63495 -0.115827 7.04996 0.248568Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 9.44771 0.447715 9 1 9H14C14.3927 9 14.749 9.22983 14.911 9.58757C15.0729 9.9453 15.0105 10.3647 14.7514 10.6598L10.3613 15.6598C9.99687 16.0748 9.36504 16.1158 8.95003 15.7514C8.53502 15.387 8.49398 14.7552 8.85838 14.3402L11.7912 11H1C0.447715 11 0 10.5523 0 10Z"
      fill={color}
    />
  </svg>
);

export default SwapIcon;
