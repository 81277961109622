import { HStack, Text } from 'native-base';

import { Icon } from '@cryptowallet/frontend/ui';

import { ILinkErrorProps, LinkError } from './Links';

interface IErrorBorderedLinkProps {
  message: string;
  linkProps: ILinkErrorProps;
}

const ErrorBorderedLink = ({ message, linkProps }: IErrorBorderedLinkProps) => (
  <HStack mt="16px" alignItems="center" borderWidth="1px" p="13px" borderRadius="10px" borderColor="error.400">
    <Icon width="16px" height="16px" name="error-cross" mr="16px" />
    <Text fontSize="lg">{message} </Text>
    <LinkError {...linkProps} _text={{ fontSize: 'lg' }} />
  </HStack>
);

export default ErrorBorderedLink;
