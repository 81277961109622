import React from 'react';

const EyeIcon = ({ color = '#8E8A99', size = '16' }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.845 7.095C14.7919 5.88 11.4326 3 8 3C4.56738 3 1.20808 5.88643 0.154968 7.095C-0.0516559 7.33286 -0.0516559 7.66714 0.154968 7.905C1.20808 9.12 4.56738 12 8 12C11.4326 12 14.7919 9.11357 15.845 7.905C16.0517 7.66714 16.0517 7.33286 15.845 7.095ZM8 10.0393C6.54697 10.0393 5.36721 8.90143 5.36721 7.5C5.36721 6.09857 6.54697 4.96071 8 4.96071C9.45303 4.96071 10.6328 6.09857 10.6328 7.5C10.6328 8.90143 9.45303 10.0393 8 10.0393Z"
      fill={color}
    />
  </svg>
);

export default EyeIcon;
