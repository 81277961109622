import { useQuery } from '@tanstack/react-query';

import { walletsApi } from '@cryptowallet/frontend/api';
import { GetSwapTransactionResponseWalletsDtoStatusEnum } from '@cryptowallet/frontend/client';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import { defaultLongPollingInterval } from '@cryptowallet/frontend/utils';

const useSwap = () => {
  const [swapId, reset] = useAccountsStore(state => [state.swapData.swapId, state.reset]);

  const { isLoading, data: swapResponse } = useQuery(
    ['swap', swapId],
    async () => {
      try {
        return await walletsApi.walletsControllerGetSwapById(swapId);
      } catch (err) {
        if (err?.response?.status === 404) {
          reset();

          return undefined;
        }

        throw err;
      }
    },
    {
      refetchInterval: response => {
        const completed = (
          [
            GetSwapTransactionResponseWalletsDtoStatusEnum.Cancelled,
            GetSwapTransactionResponseWalletsDtoStatusEnum.Done,
          ] as GetSwapTransactionResponseWalletsDtoStatusEnum[]
        ).includes(response?.data?.status);

        return completed ? false : defaultLongPollingInterval;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!swapId,
      retry: (_failureCount: number) => {
        if (_failureCount > 2) {
          reset();
        }

        return true;
      },
    },
  );

  return {
    isLoading,
    swap: swapResponse?.data || null,
  };
};

export default useSwap;
