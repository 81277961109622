import { Box, Center, Heading, Text } from 'native-base';
import React from 'react';

import { GhostButton } from '@cryptowallet/frontend/ui';

interface InfoScreenProps {
  headingText: string;
  icon: JSX.Element;
  descriptionText: string;
  actionBtnText: string;
  onActionBtnPress?: () => void;
}

export default function InfoScreen({
  headingText,
  icon,
  descriptionText,
  actionBtnText,
  onActionBtnPress,
}: InfoScreenProps): JSX.Element {
  return (
    <Box background="background.400" borderRadius="10px" overflow="hidden" maxW="100vw" w="lg">
      <Box>
        <Box p="6" borderBottomWidth="1px" borderBottomColor="primary.400" mb="4">
          <Heading fontSize="xl" fontWeight="500" textAlign="center">
            {headingText}
          </Heading>
        </Box>
        <Center maxW="560px" maxH="458px">
          <Center p="6">
            {icon}
            <Text marginTop="5" fontWeight="400" fontSize="sm" textAlign="center">
              {descriptionText}
            </Text>
          </Center>
          <GhostButton p="6" marginTop="40px" onPress={onActionBtnPress}>
            {actionBtnText}
          </GhostButton>
        </Center>
      </Box>
    </Box>
  );
}
