import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import qs from 'qs';
import { shallow } from 'zustand/shallow';
import React, { useEffect } from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import { useAssets, useBuyExchange } from '@cryptowallet/frontend/hooks';
import { BuyFlowState, getBuySafeQueryStateExcludeKeys, useBuyFlowStore } from '@cryptowallet/frontend/stores';
import { setQueryString } from '@cryptowallet/frontend/utils';
import Stepper, { IStep } from '@cryptowallet/web/components/Stepper';

import CreateExchange from './CreateExchange';
import ExchangeProcessing from './ExchangeProcessing';
import Payment from './Payment';
import PaymentCompleted from './PaymentCompleted';

const steps: IStep[] = [
  {
    label: 'Amount',
    Component: CreateExchange,
  },
  {
    label: 'Payment',
    Component: Payment,
  },
  {
    label: 'Exchange',
    Component: ExchangeProcessing,
  },
];

const defaultFiatCurrency = 'eur';
const defaultBuyCurrency = {
  ticker: 'trx',
  networkTicker: 'trx',
};

const storeKeys = [
  'toCurrencyId',
  'fromCurrency',
  'toCurrency',
  'setFromCurrency',
  'setToCurrency',
  'stepIndex',
  'setStepIndex',
  'initQueryState',
  'exchangeId',
  'paymentMethod',
  'setExchangeData',
  'amount',
  'walletAddress',
  'setExchangeId',
] as const;

const BuyFlow = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { buyFromCurrencies, buyToCurrencies, isLoading } = useAssets();
  const { data: exchange, completed, payInCompleted, isLoading: isExchangeLoading, userPaymentSent } = useBuyExchange();
  const {
    toCurrencyId,
    fromCurrency,
    toCurrency,
    setFromCurrency,
    setToCurrency,
    stepIndex,
    setStepIndex,
    initQueryState,
    exchangeId,
    paymentMethod,
    setExchangeData,
    amount,
    walletAddress,
    setExchangeId,
  }: Pick<BuyFlowState, typeof storeKeys[number]> = useBuyFlowStore(state => pick(state, storeKeys), shallow);

  useEffect(() => {
    initQueryState(getBuySafeQueryStateExcludeKeys());
  }, []);

  useEffect(() => {
    if (!fromCurrency && buyFromCurrencies?.length) {
      const urlState = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const { fromCurrencyId: urlFromCurrencyId } = urlState || {};
      const urlCurrency = buyFromCurrencies.find(currency => currency.id === urlFromCurrencyId);
      const noUrlCurrency = buyFromCurrencies.find(currency => currency.ticker.toLowerCase() === defaultFiatCurrency);
      const [firstCurrency] = buyFromCurrencies;

      const defaultCurrency = urlCurrency || noUrlCurrency || firstCurrency;
      setFromCurrency(defaultCurrency);
    }

    if (!toCurrency && buyToCurrencies?.length) {
      const urlState = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const { toCurrencyId: urlToCurrencyId } = urlState || {};
      const urlCurrency = buyToCurrencies.find(currency => currency.id === urlToCurrencyId);
      const noUrlCurrency = buyToCurrencies.find(
        currency =>
          currency.ticker.toLowerCase() === defaultBuyCurrency.ticker &&
          currency.network?.ticker.toLowerCase() === defaultBuyCurrency.networkTicker,
      );
      const [firstCurrency] = buyToCurrencies;

      const defaultCurrency = urlCurrency || noUrlCurrency || firstCurrency;
      setToCurrency(defaultCurrency);
    }
  }, [
    buyFromCurrencies,
    buyToCurrencies,
    setFromCurrency,
    setToCurrency,
    fromCurrency,
    toCurrency,
    toCurrencyId,
    exchangeId,
  ]);

  useEffect(() => {
    if (!exchangeId) {
      return;
    }

    const propperStepIndex = payInCompleted || userPaymentSent ? 2 : 1;
    if (stepIndex === propperStepIndex) {
      return;
    }

    setStepIndex(propperStepIndex);

    if (propperStepIndex === 2) {
      queryClient.invalidateQueries(['getExchanges']); // update status in recent activity when payin completes
    }
  }, [payInCompleted, userPaymentSent, exchangeId]);

  useEffect(() => {
    if (exchange) {
      // this happens when exchangeId appears and created exchange data loads - refresh page, resume exchange
      const { data: exchangeData } = exchange;

      const createdExchangeData = {
        paymentMethod: exchangeData.pspType,
        amount: (exchangeData.fromAmount || exchangeData.expectedFromAmount).toString(),
        toCurrency: buyToCurrencies.find(item => item.id === exchangeData.toExchangerAssetId),
        fromCurrency: buyFromCurrencies.find(item => item.id === exchangeData.fromExchangerAssetId),
        walletAddress: exchangeData.payOut.walletAddress,
      };

      const storeExchangeData = {
        paymentMethod,
        amount,
        toCurrency,
        fromCurrency,
        walletAddress,
      };

      if (!isEqual(createdExchangeData, storeExchangeData)) {
        setExchangeData(createdExchangeData);
      }
    }
  }, [exchange]);

  useEffect(() => {
    // when come from wallet
    if (toCurrencyId && toCurrency?.id !== toCurrencyId) {
      const urlCurrency = buyToCurrencies.find(currency => currency.id === toCurrencyId);
      if (urlCurrency) {
        setToCurrency(urlCurrency);
      }
    }
  }, [toCurrencyId]);

  const isReady: boolean = !isLoading && (!exchangeId || !isExchangeLoading) && !!fromCurrency && !!toCurrency;
  const showExchangeType = stepIndex === 1 || stepIndex === 2;

  if (completed) {
    return <PaymentCompleted />;
  }

  if (!isReady) {
    return null;
  }

  return (
    <Stepper
      header={showExchangeType && commonConstants.ExchangeTypeEnum.Buy}
      headerProps={{
        hideDivider: true,
        mb: 0,
      }}
      steps={steps}
      canGoToPrevStep={showExchangeType && stepIndex === 1}
      stepperState={[stepIndex, setStepIndex]}
      onGoToPrevStep={() => {
        setQueryString();
        setExchangeId('');
      }}
    />
  );
};

export default BuyFlow;
