import { Box, Flex, Text } from 'native-base';
import React, { forwardRef, useState } from 'react';

import StepsProgressBar from '@cryptowallet/web/components/StepsProgressBar';

import FormHeading from '../pages/Home/components/FormHeading';

import AuthHeader, { AuthHeaderProps } from './AuthHeader';

export interface IStepComponentProps {
  onStepCompleted: () => void;
  setStepIndex: (stepIndex: number) => void;
  ref?: any;
}

export interface IStep {
  label: string;
  description?: string;
  Component: React.FC<IStepComponentProps>;
}

export interface IStepperProps {
  steps: IStep[];
  onFlowComplete?: () => void;
  header?: string;
  headerProps?: Partial<AuthHeaderProps>;
  canGoToPrevStep?: boolean;
  initialStep?: number;
  onClose?: () => void;
  stepperState?: [number, (stepIndex: number) => void];
  hideProgressBar?: boolean;
  onGoToPrevStep?: () => void;
  componentProps?: any;
}

const Stepper = forwardRef(
  (
    {
      steps,
      onFlowComplete,
      header,
      headerProps = {},
      canGoToPrevStep = true,
      initialStep = 0,
      onClose,
      stepperState,
      hideProgressBar = false,
      onGoToPrevStep,
      componentProps = {},
    }: IStepperProps,
    ref,
  ) => {
    const internalState = useState(initialStep);
    const [stepIndex, setStepIndex] = stepperState || internalState;

    const { Component } = steps[stepIndex];

    const stepNames = steps.map(step => step.label);

    const goToNextStep = () => {
      const newStepIndex = stepIndex + 1;
      if (newStepIndex >= steps.length && onFlowComplete) {
        onFlowComplete();
        return;
      }
      setStepIndex(stepIndex + 1);
    };

    const goToPrevStep = () => {
      if (onGoToPrevStep) {
        onGoToPrevStep();
      }
      if (stepIndex <= 0) {
        if (onClose) {
          onClose();
        }

        return;
      }

      setStepIndex(stepIndex - 1);
    };

    const showBackButton = (stepIndex > 0 || onClose) && canGoToPrevStep;

    return (
      <>
        {!!(header || showBackButton) && (
          <AuthHeader display="flex" alignItems="flex-start" borderBottomColor="transparent" {...headerProps}>
            <FormHeading
              onPressBack={showBackButton && goToPrevStep}
              text={header}
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent={{ base: 'center', md: 'space-between' }}
              flexDirection={{ base: 'column', md: 'row' }}
            />
          </AuthHeader>
        )}
        {!hideProgressBar && (
          <Box px="24px" py="0">
            <StepsProgressBar steps={stepNames} activeStep={stepIndex} />
          </Box>
        )}
        <Flex mt="6px" flexGrow="1">
          <Component ref={ref} onStepCompleted={goToNextStep} setStepIndex={setStepIndex} {...componentProps} />
        </Flex>
      </>
    );
  },
);

export default Stepper;
