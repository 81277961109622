import { Box, HStack, Text, useMediaQuery, VStack } from 'native-base';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import { Icon, OverlaySpinner, Pressable, useUser } from '@cryptowallet/frontend/ui';
import { OutlinedButton } from '@cryptowallet/web/components/Buttons';
import CenterElement from '@cryptowallet/web/components/CenterElement';
import { RoundedBox, TabContentLayout, TabHeader } from '@cryptowallet/web/components/layout';
import OrangeGradientText from '@cryptowallet/web/components/OrangeGradientText';
import Table, { TableType } from '@cryptowallet/web/components/Table';
import useRewards from '@cryptowallet/web/pages/Referral/hooks/useRewards';

import { columnDefs } from './config';
import RewardsMasterDetails from './RewardsMasterDetails';
import RewardsPlaceholder from './RewardsPlaceholder';

const verificationLink = '/account/security/verification';

const BgImage = ({ src }) => (
  <img
    src={src}
    width="100%"
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      objectFit: 'cover',
      zIndex: -1,
    }}
  />
);

const ActionBox = ({ children, title, reward, icon, bg, ...rest }) => (
  <HStack
    position="relative"
    p="24px"
    pr="80px"
    h={{ base: '146px' }}
    w={{ base: '100%', md: 'calc(50% - 5px)' }}
    mb={{ base: '24px', md: '0' }}
    bg="gray.100"
    overflow="hidden"
    borderRadius="10px"
    {...rest}>
    <BgImage src={bg} />
    <VStack maxW="150px" w="75%">
      <Text fontSize="lg" color="white">
        {title}
      </Text>
      <Text mt="2px" fontSize="md" fontWeight="bold" color="secondary.400">
        {reward}
      </Text>
      {children}
    </VStack>
    <Icon name={icon} position="absolute" right="24px" top="24px" h="56px" w="56px" />
  </HStack>
);

const ActionCompletedLabel = ({ withIcon = false, title, ...rest }) => (
  <HStack borderRadius="88px" bg="gray.200" alignItems="center" h="24px" w="90px" justifyContent="center" {...rest}>
    {withIcon && <Icon mr="4px" name="checked-icon" h="12px" w="12px" />}
    <Text color="#00D8C4" fontSize="sm">
      {title}
    </Text>
  </HStack>
);

const Rewards = () => {
  const { isDesktop, isMobile } = useScreenSize();
  const [isLargeLaptop] = useMediaQuery({ minWidth: 1600 });
  const navigate = useNavigate();
  const { kycPoaHighRiskVerified } = useUserStatus();
  const { referralCode } = useUser();

  const [selectedRow, setSelectedRow] = useState('');

  const {
    isFetching: isRewardsFetching,
    referralRewards,
    totalRewardsValue,
    hasNextPage,
    fetchNextPage,
  } = useRewards();

  if (isRewardsFetching) {
    return (
      <CenterElement>
        <OverlaySpinner />
      </CenterElement>
    );
  }

  const hasWelcomeBonus = referralRewards.find(item => item.amount === 50);
  const hasRegisterByRefLinkBonus = referralRewards.find(item => item.amount === 250);
  const showTable = kycPoaHighRiskVerified && !!referralRewards.length;

  return (
    <HStack display="flex" flexGrow="1" maxW="1600px" flexDirection={isLargeLaptop ? 'row' : 'column'}>
      <RoundedBox
        order={isLargeLaptop ? 1 : 2}
        display="flex"
        w={isLargeLaptop ? '40%' : '100%'}
        mr={{ xl: '24px' }}
        mt={isLargeLaptop ? '0' : '24px'}>
        {isLargeLaptop && <TabHeader onGoBack={isDesktop ? null : () => navigate('/account')}>Rewards</TabHeader>}
        <TabContentLayout px={showTable ? '0' : '32px'} pt={showTable ? '0' : '32px'}>
          {!showTable && <RewardsPlaceholder />}
          {showTable && (
            <Box px="6px">
              <Table
                columnDefs={columnDefs}
                rowData={referralRewards}
                type={isMobile ? TableType.SMALL : TableType.FULL}
                containerProps={{ zIndex: -1 }}
                loadMore={fetchNextPage}
                hasMore={hasNextPage}
                isFetching={isRewardsFetching}
                {...(isMobile
                  ? {
                      onSelectRow: id => setSelectedRow(prev => (prev === id ? '' : id)),
                      selectedRow,
                      MasterDetailsComponent: RewardsMasterDetails,
                    }
                  : {})}
              />
            </Box>
          )}
        </TabContentLayout>
      </RoundedBox>
      <RoundedBox order={isLargeLaptop ? 2 : 1} display="flex" w={isLargeLaptop ? 'calc(60% - 24px)' : '100%'}>
        {!isLargeLaptop && <TabHeader>Rewards</TabHeader>}
        <VStack p={{ base: '24px', xl: '32px' }} w="100%">
          <VStack
            borderRadius="10px"
            overflow="hidden"
            w="100%"
            h={{ base: '146px', xl: '200px' }}
            pl={{ base: '24px', md: '50%' }}
            pt="24px"
            justifyContent={{ base: 'flex-start', xl: 'center' }}>
            <BgImage
              src={isMobile ? '/assets/images/rewards-zealy-bg.svg' : '/assets/images/total-rewards-banner.png'}
            />
            <Text mb="12px" fontSize="lg" color="white" fontWeight="500">
              Total Rewards
            </Text>
            <HStack alignItems="flex-end">
              <OrangeGradientText style={{ fontSize: '40px', lineHeight: '40px' }}>
                {totalRewardsValue}
              </OrangeGradientText>
              <OrangeGradientText style={{ fontSize: '28px', lineHeight: '36px', marginLeft: 6 }}>
                $SPEND
              </OrangeGradientText>
            </HStack>
          </VStack>
          <HStack
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            mt={{ base: '24px', lg: '40px' }}>
            <ActionBox
              title="KYC application passed"
              reward="50 $SPEND"
              icon="rewards/kyc"
              bg="/assets/images/rewards-kyc-bg.svg">
              {hasWelcomeBonus && <ActionCompletedLabel mt="12px" withIcon={true} title="Passed" />}
              {!hasWelcomeBonus && (
                <OutlinedButton onPress={() => navigate(verificationLink)} w="112px" mt="12px" h="24px">
                  Pass KYC
                </OutlinedButton>
              )}
            </ActionBox>
            <ActionBox
              title="Refer a friend to CryptoWallet.com"
              reward="500 $SPEND"
              icon="rewards/refer"
              bg="/assets/images/rewards-refer-bg.svg">
              {kycPoaHighRiskVerified && (
                <OutlinedButton onPress={() => navigate('/referral/overview')} w="112px" mt="12px" h="24px">
                  Invite a friend
                </OutlinedButton>
              )}
              {!kycPoaHighRiskVerified && (
                <OutlinedButton onPress={() => navigate(verificationLink)} w="180px" mt="12px" h="24px">
                  With one transaction and KYC
                </OutlinedButton>
              )}
            </ActionBox>
          </HStack>
          <HStack flexDirection={{ base: 'column', md: 'row' }} justifyContent="space-between">
            <ActionBox
              title="Register via referral link"
              reward="250 $SPEND"
              mt="10px"
              icon="rewards/register"
              bg="/assets/images/rewards-register-bg.svg">
              {hasRegisterByRefLinkBonus && <ActionCompletedLabel mt="12px" withIcon={true} title="Received" />}
              {!hasRegisterByRefLinkBonus && !referralCode && (
                <OutlinedButton onPress={() => navigate(verificationLink)} w="180px" mt="12px" h="24px">
                  With one transaction and KYC
                </OutlinedButton>
              )}
            </ActionBox>
            <ActionBox
              title="Join our Zealy campaign"
              reward="10 000+ $SPEND"
              mt="10px"
              icon="rewards/zealy"
              mb="0"
              bg="/assets/images/rewards-zealy-bg.svg">
              <Pressable onPress={() => window.open('https://zealy.io/c/cryptowalletcom/', '_blank')}>
                <ActionCompletedLabel mt="12px" title="Participate" />
              </Pressable>
            </ActionBox>
          </HStack>
        </VStack>
      </RoundedBox>
    </HStack>
  );
};

export default Rewards;
