import { Modal } from 'native-base';
import React, { useMemo } from 'react';

import { useBlockWindowScroll } from '@cryptowallet/frontend/hooks';
import { getPlatform } from '@cryptowallet/frontend/utils';

import { useWidget } from '../../../index';
import CloseButton from '../Buttons/CloseButton';
import ModalBase from '../ModalBase';

import CryptocurrencyFormContent from './CryptocurrencyFormContent';
import CurrencyDropdownList from './CurrencyDropdownList';

const isWeb = getPlatform() === 'web';

const CryptocurrencyFormModalMobile = ({ control, onClose, selectedInputName, openCurrencyList, inputs }) => {
  useBlockWindowScroll(true);
  const { currencies, selectedCurrency, onCurrencyChange, showNetwork, withIcon } = useMemo(
    () => inputs.find(({ name }) => name === selectedInputName),
    [inputs, selectedInputName],
  );
  const { isWidget } = useWidget();

  const getPaddingTop = () => {
    if (!isWeb) {
      return '0px';
    }
    return isWidget ? '24px' : `${inputs.length * 133 + 65}px`;
  };

  return (
    <ModalBase onClose={onClose}>
      <Modal.Content
        maxH={isWeb ? 'unset' : '65%'}
        maxW={isWeb ? 'unset' : '100%'}
        h="100%"
        w="100%"
        pt={isWeb ? '64px' : undefined}
        px={isWeb ? '24px' : undefined}
        borderRadius="0"
        bg={isWeb ? 'black' : 'background.600'}
        position={isWeb ? undefined : 'absolute'}
        bottom={isWeb ? undefined : '0'}>
        <CloseButton
          iconProps={{ name: 'close-button--gray', h: '16px', w: '16px' }}
          onPress={onClose}
          top={isWeb ? '8px' : '-10px'}
          right="19px"
          position="absolute"
          zIndex="1"
        />
        {!isWidget && isWeb && (
          <CryptocurrencyFormContent
            control={control}
            openCurrencyList={openCurrencyList}
            selectedInputName={selectedInputName}
            inputs={inputs}
            isModalView={true}
          />
        )}
        {!!onCurrencyChange && (
          <CurrencyDropdownList
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            onSelectCurrency={newCurrency => {
              onCurrencyChange(newCurrency);
              if (isWidget || !isWeb) {
                onClose();
              }
            }}
            showNetwork={showNetwork}
            withIcon={withIcon}
            containerStyles={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              top: isWeb ? 'unset' : 0,
              maxH: '100%',
              h: '100%',
              pt: getPaddingTop(),
              borderRadius: 10,
            }}
          />
        )}
      </Modal.Content>
    </ModalBase>
  );
};

export default CryptocurrencyFormModalMobile;
