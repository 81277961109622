import { IButtonProps } from 'native-base';

import { Button } from '@cryptowallet/frontend/ui';

const darkBlurColor = 'buttonBlurredGray.600';
const ButtonBlurredDarkFocusedStyle = { bg: `${darkBlurColor}:alpha.30` };
export const ButtonBlurredDark = ({ children, ...props }: IButtonProps) => (
  <Button
    bg={`${darkBlurColor}:alpha.10`}
    _hover={ButtonBlurredDarkFocusedStyle}
    _pressed={{ bg: `${darkBlurColor}:alpha.40` }}
    _focus={ButtonBlurredDarkFocusedStyle}
    {...props}>
    {children}
  </Button>
);
