import { Text } from 'native-base';
import React from 'react';

const SecurityBlockDescriptionText = ({ children, ...rest }) => (
  <Text fontWeight="100" color="white" fontSize="lg" {...rest}>
    {children}
  </Text>
);

export default SecurityBlockDescriptionText;
