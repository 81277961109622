import { Box, HStack, Text, VStack } from 'native-base';
import React from 'react';

import { Icon } from '@cryptowallet/frontend/ui';

const SecuritySubBlock = ({ title, list = [], containerProps = {} }) => (
  <Box {...containerProps}>
    <Text mb="8px" fontSize="lg" color="white" lineHeight="32px">
      {title}
    </Text>
    <VStack>
      {list.map(({ text, icon }, index) => (
        <HStack key={index} mb="4px" alignItems="center">
          {icon && <Icon w="20px" h="20px" {...icon} />}
          <Text ml="6px" fontSize="md" color="textRegular">
            {text}
          </Text>
        </HStack>
      ))}
    </VStack>
  </Box>
);

export default SecuritySubBlock;
