import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { walletsApi } from '@cryptowallet/frontend/api';
import { WalletResourceEntityDto } from '@cryptowallet/frontend/client';
import { cacheRequestOption } from '@cryptowallet/frontend/utils';
import { useUserSettings } from '@cryptowallet/frontend/ui';

interface WalletSummaryData {
  allWalletResources: WalletResourceEntityDto[];
  existingWalletResources: WalletResourceEntityDto[];
  walletResourcesToAdd: WalletResourceEntityDto[];
  recommendedWalletResources: WalletResourceEntityDto[];
  swapWalletResources: WalletResourceEntityDto[];
  fiatTotalBalance: string;
  cryptoTotalBalance: string;
}

const walletSummaryPlaceholder = {
  walletResources: [],
  fiatTotalBalance: '0',
  cryptoTotalBalance: '0',
};

const useWalletSummary = () => {
  const { recommendedWalletAssets } = useUserSettings();
  const queryClient = useQueryClient();
  const { isLoading, data, isFetching } = useQuery({
    // @ts-ignore
    queryKey: ['walletSummary'],
    queryFn: () => walletsApi.walletsControllerGetWalletSummary(),
    ...cacheRequestOption,
  });

  const walletSummary = data?.data?.walletSummary || walletSummaryPlaceholder;

  const result = useMemo<WalletSummaryData>(() => {
    const walletResourcesToAdd = walletSummary.walletResources.filter(item => !item.isCreated);

    return {
      allWalletResources: walletSummary.walletResources,
      existingWalletResources: walletSummary.walletResources.filter(item => item.isCreated),
      swapWalletResources: walletSummary.walletResources.filter(item => item.walletAsset.isSwapEnabled),
      walletResourcesToAdd,
      recommendedWalletResources: (recommendedWalletAssets || [])
        .map(item =>
          walletResourcesToAdd.find(
            wr => wr.asset.currency.ticker === item.ticker && wr.asset.network?.ticker === item.networkTicker,
          ),
        )
        .filter(i => i),
      fiatTotalBalance: walletSummary.fiatTotalBalance,
      cryptoTotalBalance: walletSummary.cryptoTotalBalance,
    };
  }, [walletSummary, recommendedWalletAssets]);

  return {
    isLoading,
    isFetching,
    invalidate: () => queryClient.invalidateQueries(['walletSummary']),
    ...result,
  };
};

export default useWalletSummary;
