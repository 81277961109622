import React, { useEffect, useMemo, useState } from 'react';

const ProgressCircle = ({ color = 'rgba(189, 184, 204, 50%)', size = 24, strokeWidth = 1, timer = 10000 }) => {
  const [progress, setProgress] = useState(1);
  const [animation, setAnimation] = useState(null);
  const [startTime, setStartTime] = useState(0);

  const [r, circumference] = useMemo(() => {
    const newR = size / 2 - size / 6 / 2;
    return [newR, 2 * Math.PI * newR];
  }, [size]);

  const animateProgress = t => {
    const timestamp = Math.round(performance.timing.navigationStart + t);
    const currentTime = timestamp - startTime;
    const progressPercent = currentTime / timer;

    if (currentTime < timer) {
      setAnimation(window.requestAnimationFrame(animateProgress));
    }

    setProgress(circumference * progressPercent);
  };

  const startAnimation = () => {
    const now = Date.now();

    setStartTime(now);
  };

  const stopAnimation = () => window.cancelAnimationFrame(animation);

  useEffect(() => {
    if (startTime) {
      setAnimation(window.requestAnimationFrame(animateProgress));
    }
  }, [startTime]);

  useEffect(() => {
    startAnimation();

    return stopAnimation;
  }, []);

  return (
    <svg width={size} height={size}>
      <circle
        style={{
          transition: 'stroke 500ms ease-in-out',
        }}
        width={size}
        height={size}
        cx={size / 2}
        cy={size / 2}
        r={r}
        strokeWidth={strokeWidth}
        stroke={color}
        fill="none"
        strokeLinecap="round"
        strokeDasharray={circumference}
        transform={`rotate(-90, ${size / 2}, ${size / 2})`}
        strokeDashoffset={progress}
      />
    </svg>
  );
};

export default ProgressCircle;
