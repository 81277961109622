import { Text } from 'native-base';
import React from 'react';

import { ColumnType } from '../Row';

export interface ISimpleTextColumnDef {
  id: string;
  headerName: string;
  field: string;
  type: ColumnType.SIMPLE_TEXT;
}

interface ISimpleTextProps {
  data: any;
  columnDef: ISimpleTextColumnDef;
}

export const SimpleText = ({ data }: ISimpleTextProps) => (
  <Text fontSize="lg" fontWeight="500" color="textRegular">
    {data}
  </Text>
);
