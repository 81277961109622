import { useMutation } from '@tanstack/react-query';

import { useAlerts } from '@cryptowallet/frontend/ui';
import { userApi } from '@cryptowallet/web/api-client';

import Authentication, { AuthenticationProps } from './steps/Authentication';
import FlowCanceled, { FlowCanceledProps } from './steps/Canceled';
import { getNewAccessToken, initIframe } from './initVerificationIframe';
import PermissionFlow from './PermissionFlow';

const permissionType = 'KYC';

const AuthenticationComponent = (props: AuthenticationProps) => {
  const { createAlert, showErrorToaster } = useAlerts();
  const { mutateAsync: getUser } = useMutation(() => userApi.usersControllerGetUser());

  return (
    <Authentication
      getNewAccessToken={getNewAccessToken}
      initIframe={initIframe}
      header="Confirm your identity"
      permissionType={permissionType}
      onComplete={async () => {
        try {
          const { data } = await getUser();
          const shouldShowPoa = !data.permissions.permissionCases.VERIFIED_POA_FOR_HIGH_RISK;

          if (!shouldShowPoa && !data.user.totpEnabled) {
            createAlert('RegistrationCompleteAlert');
          }
        } catch (err) {
          showErrorToaster();
        }
      }}
      {...props}
    />
  );
};

const FlowCanceledComponent = (props: FlowCanceledProps) => (
  <FlowCanceled
    permissionType={permissionType}
    content={() => (
      <>
        Unfortunately, you can&#39;t use our platform without verifying your identity. Complete the KYC procedure to get
        access to all CryptoWallet features. Just log in to your account with your email address.
      </>
    )}
    {...props}
  />
);

export function Kyc({ authenticationProps }: { authenticationProps?: Partial<AuthenticationProps> }): JSX.Element {
  return (
    <PermissionFlow
      AuthenticationComponent={AuthenticationComponent}
      FlowCanceledComponent={FlowCanceledComponent}
      authenticationProps={authenticationProps}
    />
  );
}
