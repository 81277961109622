import { useBreakpointValue, useMediaQuery } from 'native-base';
import { useWidget } from '../ui/src';

type ScreenSizes = {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isBiggerThanTablet: boolean;
};
export const useScreenSize = (): ScreenSizes => {
  const { isWidget } = useWidget();

  const size = useBreakpointValue({
    base: 'base',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
  });
  const [isWidgetMobile] = useMediaQuery({ maxWidth: 466 });

  return {
    isMobile: isWidget ? isWidgetMobile : size === 'base',
    isTablet: size === 'md',
    isLaptop: size === 'lg',
    isDesktop: size === 'xl',
    isBiggerThanTablet: ['lg', 'xl'].includes(size),
  };
};
