import { Box, HStack } from 'native-base';
import React from 'react';

import { Pressable } from '@cryptowallet/frontend/ui';

import { CopyText } from './CellRenderers/CopyText';
import { CDate, ColoredText, SimpleText, SingleSelect } from './CellRenderers';
import { IColumnDef, IMasterDetailsComponentProps, TableType } from '.';

export enum ColumnType {
  COLORED_TEXT = 'colored-text',
  DATE = 'date',
  SIMPLE_TEXT = 'simple-text',
  SINGLE_SELECT = 'single-select',
  CUSTOM = 'custom',
  COPY_TEXT = 'copy-text',
}

const CellRenderers = {
  [ColumnType.COLORED_TEXT]: ColoredText,
  [ColumnType.SINGLE_SELECT]: SingleSelect,
  [ColumnType.DATE]: CDate,
  [ColumnType.SIMPLE_TEXT]: SimpleText,
  [ColumnType.COPY_TEXT]: CopyText,
};

interface IRowProps {
  tableType: TableType;
  data: any;
  columns: IColumnDef[];
  lastElement: boolean;
  onSelectRow?: (id: string) => void;
  selected: boolean;
  onCellClick?: (col: IColumnDef, row: any) => void;
  MasterDetailsComponent?: (props: IMasterDetailsComponentProps) => JSX.Element;
  nextRowSelected: boolean;
}

const rowContainerStyles = (selected, tableType) => ({
  px: { base: '0', lg: '13px' },
  mb: '4px',
  bg: selected ? 'black' : 'transparent',
  borderWidth: '1px',
  borderLeftWidth: tableType === TableType.FULL ? '1px' : '0',
  borderRightWidth: tableType === TableType.FULL ? '1px' : '0',
  borderColor: selected ? 'gray.100' : 'transparent',
  borderRadius: '10px',
  outlineWidth: '0',
  _focus: {
    borderColor: 'secondary.400',
    borderLeftWidth: '1px',
  },
});

const RowContainer = ({ onSelectRow, id, selected, tableType, children }) => {
  if (onSelectRow) {
    return (
      <Pressable {...rowContainerStyles(selected, tableType)} onPress={() => onSelectRow(id)}>
        {children}
      </Pressable>
    );
  }

  return <Box {...rowContainerStyles(selected, tableType)}>{children}</Box>;
};

const Row = ({
  tableType,
  data,
  columns,
  lastElement,
  onSelectRow,
  selected,
  onCellClick,
  MasterDetailsComponent,
  nextRowSelected,
}: IRowProps) => (
  <RowContainer id={data.id} onSelectRow={onSelectRow} selected={selected} tableType={tableType}>
    {nextRowSelected}
    <HStack
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="center"
      borderBottomWidth={lastElement ? '0' : '1px'}
      borderBottomColor={nextRowSelected ? 'transparent' : 'gray.100'}
      pt={tableType === TableType.SMALL ? { base: '16px', lg: '32px' } : '22px'}
      pb={tableType === TableType.SMALL ? '16px' : '22px'}
      px={{ base: '24px', lg: '0' }}>
      {columns.map(col => {
        const Component = col.cellRenderer || CellRenderers[col.type];
        const containerStyles = (col.containerStyles && col.containerStyles[tableType]) || {};
        return (
          <Box key={col.id} {...containerStyles}>
            <Component
              key={col.id}
              data={col.getValue ? col.getValue(data, col) : data[col.field]}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columnDef={col}
              onCellClick={onCellClick}
              rowId={data.id}
            />
          </Box>
        );
      })}
    </HStack>
    {selected && !!MasterDetailsComponent && <MasterDetailsComponent tableType={tableType} data={data} />}
  </RowContainer>
);

export default Row;
