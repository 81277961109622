import React, { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';

import OtpFormGuard from './OtpFormGuard';
import TotpFormGuard from './TotpFormGuard';

export enum IFormGuardType {
  OTP = 'otp',
  TOTP = 'totp',
}

export interface IFormGuardComponentProps {
  control: Control<any>;
  onSubmit: () => void;
  name: string;
  minLength: number;
  requestCodeOnMount: boolean;
}

type IFormGuards = {
  [key in IFormGuardType]?: {
    Component: React.FC<IFormGuardComponentProps>;
    name: string;
    minLength: number;
  };
};

const guards: IFormGuards = {
  [IFormGuardType.OTP]: {
    Component: OtpFormGuard,
    name: 'emailOtpToken',
    minLength: 6,
  },
  [IFormGuardType.TOTP]: {
    Component: TotpFormGuard,
    name: 'totpToken',
    minLength: 6,
  },
};

interface IActiveGuard {
  type: IFormGuardType;
  requestCodeOnMount?: boolean;
}

interface IFormGuardsProps {
  activeGuards: IActiveGuard[];
  control: Control<any>;
  onSubmitInput: () => void;
  autoSubmit?: boolean;
}

const FormGuards = ({ activeGuards, control, onSubmitInput, autoSubmit = false }: IFormGuardsProps) => {
  const inputValues = useWatch({
    control,
    name: activeGuards.map(({ type }) => guards[type].name),
  });

  useEffect(() => {
    if (autoSubmit) {
      const allFilled = inputValues.every((value, index) => {
        const { minLength } = guards[activeGuards[index].type];

        return value.length >= minLength;
      });

      if (allFilled) {
        onSubmitInput();
      }
    }
  }, [inputValues, autoSubmit]);

  return (
    <>
      {activeGuards.map(({ type, requestCodeOnMount = true }) => {
        const { Component, ...rest } = guards[type];

        return (
          <Component
            key={type}
            control={control}
            onSubmit={onSubmitInput}
            requestCodeOnMount={requestCodeOnMount}
            {...rest}
          />
        );
      })}
    </>
  );
};

export default FormGuards;
