import { Text, VStack } from 'native-base';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPurple, Icon } from '@cryptowallet/frontend/ui';

interface IInviteFriendsPlaceholderProps {
  showIcon?: boolean;
}

const InviteFriendsPlaceholder = ({ showIcon = true }: IInviteFriendsPlaceholderProps) => {
  const navigate = useNavigate();

  return (
    <VStack flexGrow="1" justifyContent="center" alignItems="center">
      <>
        {showIcon && <Icon name="InviteFriendsPlaceholderIcon" h="86px" w="86px" />}
        <Text lineHeight="18px" mt={showIcon ? '16px' : '36px'} mb="40px" color="white" fontSize="xl">
          No referrals
        </Text>
        <ButtonPurple h="50" px="32px" onPress={() => navigate('/referral/overview')}>
          Invite friends
        </ButtonPurple>
      </>
    </VStack>
  );
};

export default InviteFriendsPlaceholder;
