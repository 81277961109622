import { useQuery } from '@tanstack/react-query';
import { Box, Text, VStack } from 'native-base';
import React from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { useUserStatus } from '@cryptowallet/frontend/hooks';
import { OverlaySpinner } from '@cryptowallet/frontend/ui';
import { cacheRequestOption, getCurrencySymbol } from '@cryptowallet/frontend/utils';

const ExchangeLimits = ({ exchangeType, paymentMethod, currencyTicker }) => {
  const { kycPoaHighRiskVerified } = useUserStatus();
  const { isLoading, data: response } = useQuery(
    ['limits'],
    () => exchangerApi.exchangerControllerGetLimits(currencyTicker),
    {
      ...cacheRequestOption,
      refetchOnMount: true,
      enabled: kycPoaHighRiskVerified,
    },
  );

  const currencySymbol = getCurrencySymbol(currencyTicker.toLowerCase());

  const limits = response?.data[exchangeType][paymentMethod];

  const isReady = !isLoading && limits;

  if (!kycPoaHighRiskVerified) {
    return null;
  }

  return (
    <VStack h="72px" mt="40px" w="100%">
      {!isReady && <OverlaySpinner />}
      {isReady && (
        <>
          <Text color="white" fontSize="2xl">
            Left {currencySymbol}
            {Number(limits.monthlyRemains).toFixed(2)}
          </Text>
          <Text mt="2px" mb="8px" fontSize="md" color="textRegular">
            Used {currencySymbol}
            {Number(limits.monthlyAmount).toFixed(2)}
          </Text>
          <Box overflow="hidden" bg="gray.200" h="8px" w="75%" borderRadius="10px">
            <Box
              h="100%"
              bg="primary.400"
              w={limits.monthlyAmount ? `${(limits.monthlyAmount / limits.limits.monthly) * 100}%` : '0%'}
              borderRadius="10px"
            />
          </Box>
        </>
      )}
    </VStack>
  );
};

export default ExchangeLimits;
