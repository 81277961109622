import { IButtonProps } from 'native-base';
import React from 'react';

import { Button } from './Button';

export const ButtonFaded = React.forwardRef<HTMLElement, IButtonProps>(
  ({ children, _text = {}, ...props }, forwardedRef) => (
    <Button
      ref={forwardedRef}
      variant="unstyled"
      outlineWidth="0"
      bg="primary.400"
      borderWidth="1px"
      borderColor="textDisabled"
      _text={{
        color: 'textRegular',
        ..._text,
      }}
      {...props}>
      {children}
    </Button>
  ),
);
