/* eslint-disable @typescript-eslint/no-shadow */
import { Box, FormControl, Text, VStack } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AssetCurrencyEntityDto } from '@cryptowallet/frontend/client';
import {
  useScreenSize,
  useSelectedWalletAccount,
  useWalletConfig,
  useWalletSummary,
  useWithdrawEstimate,
} from '@cryptowallet/frontend/hooks';
import { useAccountsStore } from '@cryptowallet/frontend/stores';
import {
  BottomPurpleButton,
  CryptocurrencyInputsForm,
  ErrorMessage,
  LabeledInput,
  useAlerts,
  useUser,
  WalletAddressInput,
} from '@cryptowallet/frontend/ui';
import { trimCryptoAmountLength } from '@cryptowallet/frontend/utils';
import {
  WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum,
  walletsApi,
} from '@cryptowallet/web/api-client';
import { IStepComponentProps } from '@cryptowallet/web/components/Stepper';

const errorMessagesMap = {
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.CouldNotReceiveNetworkFee]: () =>
    `Something went wrong. Please try again`,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.InsufficientBalanceForCoinTransaction]: ({
    max,
    selectedCurrencyTicker,
  }) => `Not enough balance to perform transaction. You can send no more than %${max}% ${selectedCurrencyTicker}`,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.InsufficientCoinBalanceForTokenTransaction]:
    ({ feeAssetLabel }) => `Please top up your ${feeAssetLabel} wallet to cover network fees for this transaction.`,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.InsufficientTokenBalanceForTokenTransaction]:
    ({ max }) => `Not enough balance. You can send %${max}%`,
  [WalletEstimationTransactionEntityDtoWalletBalanceInsufficientionReasonEnum.Unknown]: () => 'Unknown error',
};

const AssetWithdrawAmount = ({ onStepCompleted }: IStepComponentProps) => {
  const navigate = useNavigate();
  const {
    user: { totpEnabled },
  } = useUser();
  const { isMobile } = useScreenSize();
  const { existingWalletResources } = useWalletSummary();
  const { selectedWalletAccount } = useSelectedWalletAccount();
  const { showErrorToaster } = useAlerts();
  const { isLoading: isWalletConfigLoading, fiatCurrency } = useWalletConfig();
  const {
    networkFee,
    fiatAmount,
    validationValues,
    walletBalanceInsufficientionReason,
    feeAsset,
    isLoading: isEstimatePending,
  } = useWithdrawEstimate();
  const { withdrawData, setWithdrawData, setSelectedWalletAccountId, selectedWalletAccountId } = useAccountsStore(
    state => ({
      withdrawData: state.withdrawData,
      setWithdrawData: state.setWithdrawData,
      setSelectedWalletAccountId: state.setSelectedWalletAccountId,
      selectedWalletAccountId: state.selectedWalletAccountId,
    }),
    shallow,
  );

  const { amount, destinationAddress, destinationTag } = withdrawData;

  const cryptocurrencyInputs = useMemo(
    () =>
      selectedWalletAccount
        ? [
            {
              name: 'send',
              label: 'You send',
              value: amount,
              currencies: existingWalletResources.map(item => ({
                ...item.asset.currency,
                id: item.walletAccount.id,
                image: item.asset.image,
                network: item.asset.network,
                type: item.asset.type,
              })),
              selectedCurrency: {
                ...selectedWalletAccount.asset.currency,
                id: selectedWalletAccount.walletAccount.id,
                image: selectedWalletAccount?.asset.image,
                network: selectedWalletAccount?.asset.network,
                type: selectedWalletAccount?.asset.type,
              },
              showNetwork: true,
              setValue: amount => setWithdrawData({ amount }),
              onCurrencyChange: (newCurrency: AssetCurrencyEntityDto) => setSelectedWalletAccountId(newCurrency.id),
              validate: {
                moreThan: {
                  rule: value =>
                    +value > 0 ||
                    `Please enter the amount of ${selectedWalletAccount.asset.currency.ticker} you’d like to send.`,
                },
                lessThan: {
                  rule: () => {
                    if (walletBalanceInsufficientionReason) {
                      const feeAssetLabel = feeAsset?.currency?.label;
                      return errorMessagesMap[walletBalanceInsufficientionReason]({
                        max: validationValues?.max,
                        feeAssetLabel,
                        selectedCurrencyTicker: selectedWalletAccount.asset.currency.ticker,
                      });
                    }

                    return true;
                  },
                  fixValue: validationValues?.max.toString(),
                },
              },
            },
          ]
        : [],
    [
      amount,
      existingWalletResources,
      selectedWalletAccount,
      setWithdrawData,
      setSelectedWalletAccountId,
      feeAsset,
      validationValues?.max,
      walletBalanceInsufficientionReason,
    ],
  );

  const { trigger, control, setError } = useForm({
    mode: 'onTouched',
  });

  const onSend = async () => {
    try {
      const isValidForm = await trigger();
      const {
        data: { result: isValidWalletAddress },
      } = await walletsApi.walletsControllerValidateAddress({
        walletAccountId: selectedWalletAccountId,
        address: destinationAddress,
        destinationTag,
      });

      if (!isValidWalletAddress) {
        setError('walletAddress', { type: 'custom', message: 'Incorrect wallet address. Please try again' });
        return;
      }

      if (isValidForm) onStepCompleted();
    } catch (err) {
      showErrorToaster();
    }
  };

  useEffect(() => {
    trigger(); // trigger validation in case if user blured input before estimate happend
  }, [validationValues, trigger]);

  const isReady = !isWalletConfigLoading;
  const isSendDisabled = isEstimatePending || !totpEnabled;

  if (!isReady) return null;

  return (
    <Box pt="32px">
      <VStack px="24px" zIndex="1">
        <CryptocurrencyInputsForm control={control} inputs={cryptocurrencyInputs} />
        <Box flexDirection={{ base: 'column', xl: 'row' }}>
          <Box mt={{ base: '16px', lg: '0' }} w={isMobile ? '100%' : '50%'}>
            <Text color="textLabel" fontSize="lg">
              Network fee
            </Text>
            <Text color="textRegular" fontSize="lg">
              {networkFee ? trimCryptoAmountLength(networkFee.toString(), 9) : '-'} {feeAsset?.currency?.ticker}
            </Text>
          </Box>
        </Box>
        <LabeledInput
          formControlProps={{ mt: '24px' }}
          value={Number(fiatAmount).toFixed(2)}
          label={`Equivalent in ${fiatCurrency.ticker}`}
          labelTextProps={{
            color: 'textLabel',
            fontSize: 'lg',
          }}
          fontSize="lg"
          h="56px"
          _disabled={{
            opacity: 1,
            background: 'gray.200',
            borderColor: 'transparent',
            color: 'textRegular',
          }}
          isDisabled={true}
        />
        <WalletAddressInput
          control={control}
          walletAddress={destinationAddress}
          setWalletAddress={destinationAddress => setWithdrawData({ destinationAddress })}
          toCurrency={selectedWalletAccount.asset.currency}
          label="Send to address"
        />
        {selectedWalletAccount.asset.network?.hasDestinationTag && (
          <Controller
            control={control}
            defaultValue={destinationTag}
            render={({ field: { onChange, ref, ...field }, fieldState: { error, isTouched } }) => (
              <LabeledInput
                error={isTouched && error}
                formControlProps={{ mt: '0' }}
                value={destinationTag}
                onChangeText={destinationTag => {
                  onChange(destinationTag);
                  setWithdrawData({ destinationTag });
                }}
                {...field}
                label="Memo"
                labelTextProps={{
                  color: 'textLabel',
                  fontSize: 'lg',
                }}
                placeholder="Nonce/memo"
                color="textRegular"
                fontSize="lg"
                h="56px"
                hideError={true}
              />
            )}
            name="memo"
          />
        )}
        <FormControl mb="6px" isInvalid={true}>
          <ErrorMessage
            error={
              totpEnabled
                ? null
                : {
                    type: 'custom',
                    message:
                      'Your account is unsecured! To send your funds you need to setup 2FA. %Press here to set up 2FA%',
                  }
            }
            onPressFixValue={() => navigate('/account/2fa')}
          />
        </FormControl>
      </VStack>
      <BottomPurpleButton isDisabled={isSendDisabled} onPress={onSend} size="lg">
        Send
      </BottomPurpleButton>
    </Box>
  );
};

export default AssetWithdrawAmount;
