import { Box, HStack, Skeleton } from 'native-base';
import React from 'react';
import { RFValue } from 'react-native-responsive-fontsize';

const isLgMax = RFValue(16) > 18;

export const InfoList = ({ list, renderItem, isMobile = false, isReady = true }) => {
  const bulletMarginTop = isLgMax ? 7 : 6;
  if (isMobile) {
    return (
      <Box>
        {list.map((data, index) => (
          <HStack key={index} mb="12px">
            <Box h="4px" w="4px" mt={`${bulletMarginTop}px`} borderRadius="2px" bg="#E880F1" mr="8px" />
            <Box flexGrow="1">{renderItem(data)}</Box>
          </HStack>
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {list.map((data, index) => (
        <Box key={index} mb="8px">
          <Skeleton h="20px" isLoaded={isReady}>
            <HStack w="100%" key={index} position="relative" pl="16px" alignItems="center">
              <Box position="absolute" top="5px" left="0" h="8px" w="8px" borderRadius="50%" bg="#E880F1" />
              <Box w="100%" maxW="100%">
                {renderItem(data)}
              </Box>
            </HStack>
          </Skeleton>
        </Box>
      ))}
    </Box>
  );
};
