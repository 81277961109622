import { Flex, Text } from 'native-base';
import React, { useState } from 'react';

import { ConfirmationModal } from '@cryptowallet/frontend/ui';
import { BackButton } from '@cryptowallet/web/components/Buttons';
import { TabHeader } from '@cryptowallet/web/components/layout';

import ToggleTotp from './ToggleTotp';

interface IDisableTotpProps {
  onGoBack: () => void;
  onFlowCompleted: () => void;
  onTotpEnableClick: () => void;
}

const DisableTotp = ({ onGoBack, onFlowCompleted, onTotpEnableClick }: IDisableTotpProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  return (
    <Flex flexGrow="1">
      <TabHeader display="flex" flexDirection="row" alignItems="center">
        <BackButton mr="16px" onPress={onGoBack} />
        Disable Google Authenticator
      </TabHeader>
      <Flex mt="8px" flexGrow="1">
        <ToggleTotp
          onStepCompleted={() => {
            setShowConfirmationModal(true);
            onFlowCompleted();
          }}
        />
      </Flex>
      {showConfirmationModal && (
        <ConfirmationModal
          headerMessage="2FA is disabled!"
          cancelText="Back to settings"
          confirmText="Turn on 2FA"
          onCancel={onGoBack}
          onConfirm={onTotpEnableClick}>
          <Text fontSize="md" color="white" textAlign="center">
            Your account is not secured! Due to security reasons withdrawal is disabled. To enable withdrawal please
            turn on 2FA.
          </Text>
        </ConfirmationModal>
      )}
    </Flex>
  );
};

export default DisableTotp;
