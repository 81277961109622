import { useMutation } from '@tanstack/react-query';
import { Box, Center, Flex, Text } from 'native-base';
import { shallow } from 'zustand/shallow';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientErrors } from '@cryptowallet/frontend/client-errors';
import { useScreenSize } from '@cryptowallet/frontend/hooks';
import { BottomPurpleButton, GhostButton, SecondsCountdown, useAlerts } from '@cryptowallet/frontend/ui';
import { marketingApi, WhitelistRegisterBadRequestMarketingDtoErrorEnum } from '@cryptowallet/web/api-client';
import OtpInput from '@cryptowallet/web/components/OtpInput';
import { CaptchaResult, showCaptcha } from '@cryptowallet/web/utils/captcha';

import useStore from '../store';

const resendTimeoutMS = 60000;

const OtpVerificationForm = () => {
  const [email, country, setFlowIsCompleted] = useStore(
    state => [state.email, state.country, state.setFlowIsCompleted],
    shallow,
  );
  const navigate = useNavigate();
  const { isBiggerThanTablet } = useScreenSize();
  const [resendTime, setResendTime] = useState(Date.now() + resendTimeoutMS);
  const { showErrorToaster } = useAlerts();
  const [otp, _setOtp] = useState(''); // eslint-disable-line
  const [error, setError] = useState(null);

  const registerWhitelistUserMutation = useMutation(() =>
    marketingApi.marketingControllerWhitelistRegister({
      email,
      emailOtpToken: otp,
      country,
    }),
  );

  const handleResendClick = async () => {
    let captchaRes;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.activeElement?.blur();
      captchaRes = await showCaptcha();

      if (captchaRes === CaptchaResult.Closed) {
        return;
      }
    } catch (e) {
      return;
    }

    try {
      setResendTime(Date.now() + resendTimeoutMS);
      marketingApi.marketingControllerRequestOtp({ email, captcha: captchaRes });
    } catch (err) {
      showErrorToaster({ title: `Can't resend code, please try again later` });
    }
  };

  const setOtp = val => {
    if (error) {
      setError(null);
    }
    _setOtp(val.replace(/\D/g, '').substring(0, 6));
  };

  const handlePaste = async () => {
    const clipboardVal = await navigator.clipboard.readText();
    const numericVal = clipboardVal.replace(/\D/g, '');
    if (numericVal) {
      setOtp(numericVal);
    }
  };

  const register = async () => {
    try {
      await registerWhitelistUserMutation.mutateAsync();

      navigate('/whitelist/success');
    } catch (err) {
      if (err.response.data.error === WhitelistRegisterBadRequestMarketingDtoErrorEnum.UserAlreadyExists) {
        setFlowIsCompleted(true);
      } else if (err.response.data.error === WhitelistRegisterBadRequestMarketingDtoErrorEnum.OtpVerifyInvalid) {
        setError(ClientErrors.invalidOtp.title);
      } else {
        showErrorToaster();
      }
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      register();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <Box w="476px" maxW="100vw">
      <Box p="19px" pb="13px">
        <Text fontSize="md" mb="42px">
          Please check your email. The 6-digit verification code was sent to {email}. Pay attention that the code is
          valid for 10 minutes.
        </Text>
        <Center>
          <Box position="relative">
            <OtpInput
              autoFocus={isBiggerThanTablet}
              invalid={!!error}
              value={otp}
              onChange={setOtp}
              disabled={registerWhitelistUserMutation.isLoading}
            />
            {error && (
              <Text position="absolute" bottom="-20px" left="0" color="error.400">
                {error}
              </Text>
            )}
          </Box>
          <Flex h="54px" justifyContent="center">
            <SecondsCountdown
              expireTime={resendTime}
              renderer={({ timer, isCompleted }) => {
                if (isCompleted) {
                  return (
                    <GhostButton _text={{ fontSize: 'md' }} onPress={handleResendClick}>
                      Resend
                    </GhostButton>
                  );
                }
                return (
                  <Text fontSize="md" mt="0">
                    Resend in {timer}
                  </Text>
                );
              }}
            />
          </Flex>
        </Center>
      </Box>
      <BottomPurpleButton
        isDisabled={registerWhitelistUserMutation.isLoading}
        onPress={handlePaste}
        size="lg"
        isLoadingText="Paste Code">
        Paste Code
      </BottomPurpleButton>
    </Box>
  );
};

export default OtpVerificationForm;
