import { Box, HStack, Image, Text, VStack } from 'native-base';
import React from 'react';

import { commonConstants } from '@cryptowallet/frontend/constants';
import {
  useCreateWalletAccountMutation,
  useSelectedWalletResource,
  useWalletConfig,
} from '@cryptowallet/frontend/hooks';
import { DetailsPanelView, useAccountsStore } from '@cryptowallet/frontend/stores';
import { ActionGuard, ButtonPurple, Icon, OverlaySpinner, useUserSettings } from '@cryptowallet/frontend/ui';

import AssetDetailsBalance from './AssetDetailsBalance';
import AssetOverviewActions from './AssetOverviewActions';

const AssetGenerateAddress = () => {
  const { hideBalance } = useUserSettings();
  const selectedWalletResource = useSelectedWalletResource();
  const { isLoading: isWalletConfigLoading, fiatCurrency } = useWalletConfig();
  const { isLoading: createWalletAssetLoading, mutateAsync: createWalletAssetMutateAsync } =
    useCreateWalletAccountMutation();
  const setDetailsPanelView = useAccountsStore(state => state.setDetailsPanelView);

  const onAddAsset = async () => {
    await createWalletAssetMutateAsync(selectedWalletResource.walletAsset.id);
    setDetailsPanelView(DetailsPanelView.Overview);
  };

  if (isWalletConfigLoading || !selectedWalletResource || createWalletAssetLoading) {
    return (
      <Box h="500px">
        <OverlaySpinner />
      </Box>
    );
  }

  const {
    asset: { currency, image, network },
  } = selectedWalletResource;

  return (
    <VStack flexGrow="1" pt="40px">
      <Box px="24px" zIndex="1">
        <HStack justifyContent="space-between" mb="26px">
          <HStack>
            <Image
              source={{
                uri: image || commonConstants.CURRENCY_PLACEHOLDER_IMAGE,
              }}
              alt={currency.label}
              w={{ base: '40px', lg: '80px' }}
              h={{ base: '40px', lg: '80px' }}
              borderRadius={{ base: '40px', lg: '80px' }}
              mr="24px"
            />
            <AssetDetailsBalance
              hideBalance={hideBalance}
              cryptoBalance="0"
              currency={currency}
              fiatCurrency={fiatCurrency}
              fiatBalance="0"
              network={network}
            />
          </HStack>
        </HStack>
        <Box maxW="240px">
          <ActionGuard>
            {({ openAlert }) => (
              <ButtonPurple
                h="50px"
                py="0"
                mt="12px"
                px="32px"
                size="lg"
                isDisabled={createWalletAssetLoading}
                onPress={openAlert || onAddAsset}>
                Generate deposit address
              </ButtonPurple>
            )}
          </ActionGuard>
        </Box>
        <HStack pl="24px" alignItems="center" mt="16px">
          <Icon name="alerts/alert-warning--orange" position="absolute" top="4px" left="0" h="16px" w="16px" />
          <Text fontSize="lg" color="textRegular">
            You need to generate wallet address to start using it.
          </Text>
        </HStack>
        <AssetOverviewActions />
        <Box h={{ base: '328px', lg: '563px' }} justifyContent="center">
          <Box alignItems="center">
            <Icon name="list-gold" w="64px" h="72px" />
            <Text mt="40px" fontSize="xl" color="white" fontWeight="500">
              Asset wallet
            </Text>
            <Text mt="40px" fontSize="lg" color="textRegular">
              You need to generate wallet address to start using it.
            </Text>
          </Box>
        </Box>
      </Box>
    </VStack>
  );
};

export default AssetGenerateAddress;
