import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollToPlugin);

const useInitialScrollTop = (disabled = false) => {
  useEffect(() => {
    if (!disabled) {
      gsap.to(window, {
        scrollTo: { y: 0 },
        duration: 0,
      });
    }
  }, [disabled]);
};

export default useInitialScrollTop;
