import { Box, HStack, Text } from 'native-base';
import React from 'react';

import { IColumnDef, TableType } from '.';

interface IHeadersProps {
  type: TableType;
  columns: IColumnDef[];
}

const Headers = ({ type, columns }: IHeadersProps) => (
  <HStack px="13px" py="19px" pt="32px" borderBottomWidth="1px" borderBottomColor="gray.100">
    {columns.map(col => {
      if (col.hideHeader) return null;
      const containerStyles = col.containerStyles && col.containerStyles[type];
      const headerContainerStyles = col.headerContainerStyles && col.headerContainerStyles[type];
      return (
        <Box key={col.id} {...containerStyles} {...headerContainerStyles}>
          <Text fontSize="xl" fontWeight="400" color="white">
            {col.headerName}
          </Text>
        </Box>
      );
    })}
  </HStack>
);

export default Headers;
