import { Text } from 'native-base';
import React from 'react';

const SecurityBlockTitle = ({ children, ...rest }) => (
  <Text fontWeight="500" color="white" fontSize="3xl" {...rest}>
    {children}
  </Text>
);

export default SecurityBlockTitle;
