import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NativeBaseProvider } from 'native-base';
import React from 'react';
import * as ReactDOM from 'react-dom/client';

import { GAProvider } from '@cryptowallet/frontend/ga';
import { theme } from '@cryptowallet/frontend/ui';

import App from './app';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <NativeBaseProvider config={theme.config} theme={theme.theme}>
      <GAProvider>
        <App />
      </GAProvider>
    </NativeBaseProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);
