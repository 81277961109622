import { Box, HStack, StyledProps, Text, useToken } from 'native-base';
import React from 'react';

import { useScreenSize } from '@cryptowallet/frontend/hooks';

import NavigationLink from './Links/NavigationLink';

export enum NavigationItemType {
  LINK = 'link',
  ACTION = 'action',
}
export interface INavigationItemProps {
  id: string;
  title: string;
  icon: ({ color, width, height }: StyledProps) => JSX.Element;
  type: NavigationItemType;
  active?: boolean;
  href?: string;
  basePathName?: string;
  onPress?: () => void;
  disabled?: boolean;
  defaultTextColor?: string;
  activeTextColor?: string;
  onNavigate?: () => void;
  settingsView?: boolean;
  hasNotification?: boolean;
  navigate?: () => void;
}

const NavigationItem = ({
  onPress,
  href,
  icon,
  active,
  disabled,
  title,
  defaultTextColor = 'textRegular',
  activeTextColor = 'textRegular',
  onNavigate,
  settingsView = false,
  hasNotification = false,
  navigate,
}: INavigationItemProps): JSX.Element => {
  const { isBiggerThanTablet, isTablet } = useScreenSize();
  const [primary400, textLabel] = useToken('colors', ['primary.400', 'textLabel']);

  const textColor = active ? activeTextColor : defaultTextColor;

  return (
    <NavigationLink
      onPress={onPress}
      href={href}
      outlineWidth="0"
      height={{
        base: '24px',
        md: '54px',
      }}
      mb={{
        base: '39px',
        md: '0',
      }}
      p={isBiggerThanTablet ? '16px' : '0'}
      borderRadius="10px"
      borderWidth="1px"
      borderColor="transparent"
      _hover={
        isBiggerThanTablet
          ? {
              bg: 'gray.200',
            }
          : {}
      }
      _focus={
        isBiggerThanTablet
          ? {
              borderColor: 'secondary.400',
            }
          : {}
      }
      disabled={disabled}
      justifyContent={isBiggerThanTablet ? 'unset' : 'center'}
      parentNavigate={navigate}
      onNavigate={onNavigate}>
      {() => {
        const iconColor = active ? primary400 : textLabel;

        return (
          <HStack
            alignItems="center"
            // eslint-disable-next-line sonarjs/no-duplicate-string
            justifyContent={{ base: 'flex-start', md: settingsView ? 'flex-start' : 'center', lg: 'flex-start' }}>
            <Box w="20px" h="20px" justifyContent="center" alignItems="center">
              {icon({ color: iconColor })}
            </Box>
            {(!isTablet || settingsView) && (
              <Text
                fontSize={{ base: 'xl', md: 'lg' }}
                fontWeight="500"
                ml={{ base: '16px', md: '8px' }}
                color={disabled ? 'textDisabled' : textColor}>
                {title}
              </Text>
            )}
            {hasNotification && <Box ml="6px" h="6px" w="6px" borderRadius="6px" bg="error.400" />}
          </HStack>
        );
      }}
    </NavigationLink>
  );
};

export default NavigationItem;
