import { Box, HStack, Text, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';

import { GetUserResponseUsersDtoUserRiskLevelTypeEnum } from '@cryptowallet/frontend/client';
import { useScreenSize, useUserStatus } from '@cryptowallet/frontend/hooks';
import {
  AlertsProvider,
  ConfirmationModal,
  headerHeight,
  LayoutBodyWrapper,
  Pressable,
  useAuth,
  useUser,
} from '@cryptowallet/frontend/ui';
import { useResetPersistStates } from '@cryptowallet/web/hooks/useResetPersistStates';

import Footer from '../../Footer';
import Header from '../../Header';
import Navigation, { navigationWidth } from '../../Navigation';

export const excludeNavigationPathnames = ['whitelist'];

const linkHoverFocusStyles = {
  textDecorationColor: 'background.900',
};

const AppLayout = ({ children }) => {
  const { isMobile } = useScreenSize();
  const userStatus = useUserStatus();
  const user = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { resetPersistStates } = useResetPersistStates();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);

  const isBlocked = user && user.userRiskLevelType === GetUserResponseUsersDtoUserRiskLevelTypeEnum.Blocked;
  const showNavigation =
    userStatus.isLoggedIn && !excludeNavigationPathnames.includes(location.pathname.split('/')[1]) && !isBlocked;

  const onMouseDown = e => {
    // prevents focus event on click
    if (e.target?.closest('[data-prevent-focus="true"]')) {
      e.preventDefault();
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: process.env.NODE_ENV === 'development' ? () => {} : logout,
  });

  useEffect(() => {
    if (isMobile && mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'unset';
  }, [mobileMenuOpen, isMobile]);

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);

    return () => document.removeEventListener('mousedown', onMouseDown);
  }, []);

  const layoutLeftPadding = showNavigation && !isMobile ? navigationWidth : '0';

  return (
    <Box flexGrow="1">
      {showNavigation && (!isMobile || mobileMenuOpen) && (
        <Navigation
          closeMobileMenu={() => setMobileMenuOpen(false)}
          setShowLogoutConfirmModal={setShowLogoutConfirmModal}
        />
      )}
      <Header
        mobileMenuOpen={mobileMenuOpen}
        toggleMobileMenu={() => setMobileMenuOpen(prev => !prev)}
        setShowLogoutConfirmModal={setShowLogoutConfirmModal}
      />
      <VStack minH="100vh" w="100vw" pt={headerHeight} pl={layoutLeftPadding}>
        <AlertsProvider layoutLeftPadding={layoutLeftPadding}>
          <LayoutBodyWrapper>{children}</LayoutBodyWrapper>
          <Footer />
        </AlertsProvider>
      </VStack>
      {showLogoutConfirmModal && (
        <ConfirmationModal
          headerMessage="Logging out"
          cancelText={`Cancel`}
          confirmText={`Log out`}
          onCancel={() => setShowLogoutConfirmModal(false)}
          onConfirm={() => {
            logout();
            navigate('/', { replace: true });
            resetPersistStates();
            setShowLogoutConfirmModal(false);
          }}>
          <Text fontSize="lg" color="white" textAlign="center">
            Don’t forget to check out our referral program and Discord for crypto giveaways!
          </Text>
          <HStack mt="20px" mb="45px" flexWrap="wrap" justifyContent="center">
            <Text fontSize="lg" color="white" textAlign="center">
              You can find info for both at:
            </Text>
            <Pressable
              outlineWidth="0"
              textDecorationColor="primary.300"
              textDecoration="underline"
              color="primary.300"
              _hover={linkHoverFocusStyles}
              _focus={linkHoverFocusStyles}
              onPress={() => {
                window.open('https://discord.gg/vgy7GZKw2t', '_blank');
              }}>
              {({ isFocused, isHovered }) => (
                <Text color={isFocused || isHovered ? 'background.900' : 'primary.300'} fontSize="lg">
                  Join the CryptoWallet.com Discord Server!
                </Text>
              )}
            </Pressable>
          </HStack>
        </ConfirmationModal>
      )}
    </Box>
  );
};

export default AppLayout;
