import { useQuery } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { useMemo } from 'react';

import { exchangerApi } from '@cryptowallet/frontend/api';
import { sellEstimatePlaceholder, useSellFlowStore } from '@cryptowallet/frontend/stores';

import { ExchangeStatus, PaymentStatus } from './useBuyExchange';

export enum ExchangeResult {
  EXCHANGING = 'exchanging',
  SENDING_TO_PAYOUT = 'sending to payout',
  ERROR = 'error',
  SUCCESS = 'success',
  HOLD = 'hold',
}

const failExchangeStatuses = [ExchangeStatus.CANCELED];

const exchangeStatusesToComplete = [...failExchangeStatuses, ExchangeStatus.DONE];

export const sellPayInStatusesToComplete = [PaymentStatus.COMPLETED, PaymentStatus.DONE, PaymentStatus.ACCEPTED];

const useSellExchange = (enable = true) => {
  const [exchangeId, reset] = useSellFlowStore(state => [state.exchangeId, state.reset], shallow);
  let completed = false;

  const enabled = enable && !!exchangeId;

  const {
    isLoading,
    data: exchangeResponse,
    ...rest
  } = useQuery(
    ['exchange', exchangeId],
    async () => {
      try {
        const result = await exchangerApi.exchangerControllerGetExchangeById(exchangeId);

        return result;
      } catch (err) {
        if (err?.response?.status === 404) {
          reset();

          return;
        }

        throw err;
      }
    },
    {
      refetchInterval: exchangeResponse => {
        const exchangeStatus: ExchangeStatus = exchangeResponse?.data?.status as ExchangeStatus;
        const completed = exchangeStatusesToComplete.includes(exchangeStatus as ExchangeStatus);

        return completed ? false : 5000;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled,
      retry: (_failureCount: number) => {
        if (_failureCount > 2) {
          reset();
        }

        return true;
      },
    },
  );

  const exchangeStatus: ExchangeStatus = exchangeResponse?.data?.status as ExchangeStatus;
  const payInDetails: any = exchangeResponse?.data?.payIn;
  const userPaymentSent = Boolean(payInDetails?.userPaymentSent);
  const paymentStatus = payInDetails?.status;
  const estimateResult = exchangeResponse?.data.estimate || sellEstimatePlaceholder;
  completed = exchangeStatusesToComplete.includes(exchangeStatus as ExchangeStatus);

  const result = useMemo<string>(() => {
    if (failExchangeStatuses.includes(exchangeStatus as ExchangeStatus)) {
      return ExchangeResult.ERROR;
    }
    if (exchangeStatus === ExchangeStatus.DONE) {
      return ExchangeResult.SUCCESS;
    }
    if (sellPayInStatusesToComplete.includes(paymentStatus)) {
      return ExchangeResult.SENDING_TO_PAYOUT;
    }
    if (exchangeStatus === ExchangeStatus.BLOCKED) {
      return ExchangeResult.HOLD;
    }

    return ExchangeResult.EXCHANGING;
  }, [exchangeStatus, paymentStatus]);

  return {
    isLoading,
    payIn: payInDetails,
    userPaymentSent,
    data: exchangeResponse,
    estimateResult,
    completed,
    result,
    ...rest,
  };
};

export default useSellExchange;
