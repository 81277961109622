/* eslint-disable sonarjs/no-duplicate-string */
import { Box, Spinner, Text, VStack } from 'native-base';
import React from 'react';

import { BuyExchangeResult, useBuyExchange } from '@cryptowallet/frontend/hooks';
import { useBuyFlowStore } from '@cryptowallet/frontend/stores';
import { BottomPurpleButton, Icon } from '@cryptowallet/frontend/ui';

import BuyTransactionDetails from './BuyTransactionDetails';
import PaymentInfo from './PaymentInfo';

const exchangeStatusData = {
  [BuyExchangeResult.EXCHANGING]: {
    message: 'Exchanging',
    icon: 'sending-exchange--purple',
  },
  [BuyExchangeResult.SENDING_TO_PAYOUT]: {
    message: 'Processing Payment',
    icon: 'sending-exchange',
  },
  [BuyExchangeResult.HOLD]: {
    message: 'Pending',
    icon: 'sending-exchange',
  },
};

const ExchangeProcessing = (): JSX.Element => {
  const { result } = useBuyExchange();

  const startNewExchange = useBuyFlowStore(state => state.reset);

  const { message, icon } = exchangeStatusData[result] || { message: 'Confirmation', icon: 'sending-exchange' };

  return (
    <Box mt="24px">
      <Box px="24px">
        <PaymentInfo />
        <VStack mb="12px" alignItems="center">
          <Icon name={icon} w="80px" h="84px" />
          <VStack>
            <Text color="white" mt="8px" mb="8px" fontSize="2xl">
              {message}
            </Text>
            <Spinner color="secondary.400" />
          </VStack>
        </VStack>
      </Box>
      <Box px="24px" mt="24px" borderTopWidth="1px" borderTopColor="gray.100">
        <BuyTransactionDetails />
      </Box>
      <BottomPurpleButton size="lg" onPress={startNewExchange}>
        Start new exchange
      </BottomPurpleButton>
    </Box>
  );
};

export default ExchangeProcessing;
